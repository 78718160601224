import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddUsages from "./AddUsages";

const Usages = () => {
  const [usages, setUsages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUsage, setSelectedUsage] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleShow = (edit = false, usage = null) => {
    setEditMode(edit);
    setSelectedUsage(usage);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedUsage(null);
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/parametre/type/USAGE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        setUsages(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Erreur lors de la récupération des usages.");
      });
  }, [showModal]);

  const handleDelete = (parametreId) => {
    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer cet usage ?",
      icon: <ExclamationCircleOutlined />,
      content: "Cette action est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk: () => {
        axios
          .delete(`${API_URL}/api/parametre/${parametreId}`, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          })
          .then(() => {
            setUsages((prev) =>
              prev.filter((usage) => usage.parametreId !== parametreId)
            );
            message.success("Usage supprimé avec succès.");
          })
          .catch((err) => {
            console.log(err);
            message.error("Erreur lors de la suppression de l'usage.");
          });
      },
    });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu="TARIFICATION" />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow()}
              >
                Ajouter de nouveaux usages
              </Button>
              <div className="card">
                <h5 className="card-header">Paramétrage des usages</h5>
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Catégorie</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {usages.map((usa) => (
                        <tr key={usa.parametreId}>
                          <td>{usa.libelleParametre}</td>
                          <td>{usa?.categorie?.nom}</td>
                          <td>
                            <Space size="middle">
                              <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={() => handleShow(true, usa)}
                              >
                                Modifier
                              </Button>
                              <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => handleDelete(usa.parametreId)}
                              >
                                Supprimer
                              </Button>
                            </Space>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <AddUsages
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedUsages={selectedUsage} // Passage correct de la prop pour le composant AddUsages
      />

      <div className="layout-overlay layout-menu-toggle"></div>
      <div className="drag-target"></div>
    </div>
  );
};

export default Usages;
