// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {

    /* First page */
    .page-1 {
        page-break-after: always;
    }

    /* Second page in landscape */
    .page-2 {
        /* Switch orientation for the second page */
        size: landscape;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/production/components/styles/MICStyles.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf;QACI,wBAAwB;IAC5B;;IAEA,6BAA6B;IAC7B;QACI,2CAA2C;QAC3C,eAAe;IACnB;AACJ","sourcesContent":["@media print {\n\n    /* First page */\n    .page-1 {\n        page-break-after: always;\n    }\n\n    /* Second page in landscape */\n    .page-2 {\n        /* Switch orientation for the second page */\n        size: landscape;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
