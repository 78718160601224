import { ConfigProvider, DatePicker, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";

import frFR from "antd/locale/fr_FR";
import axios from "axios";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL, LOGGED_USER, TOKEN } from "../../../../variables/constants";
import { frenchDate } from "../../../../variables/functions";

const ReportEffet = (props) => {
    const [modalReportEffet, setModalReportEffet] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [contrat, setContrat] = useState("");
    const [dateEcheance, setDateEcheance] = useState("");
    const [dateEffet, setDateEffet] = useState("");

    useEffect(() => {
        setModalReportEffet(props.open);
        setContrat(props.contrat);
        console.log("PCCC", props.contrat);
    }, [props.open, props.contrat]);

    const navigate = useNavigate();

    const handleReportEffetSubmit = () => {
        setLoading(true);
        contrat.dateEffet = dateEffet;
        contrat.updatedBy = LOGGED_USER.codeId;

        axios
            .put(`${API_URL}/api/contrat/report-effet`, JSON.stringify(contrat), {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                contrat.codeId = res.data.codeId;
                setContrat(res.data);
                setLoading(false);
                setModalReportEffet(false);
                props.update(false);
                navigate(`/details-contrat/${res.data.codeId}`);
                window.location.reload();
                toast("Report d'effet effectué!");
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors du report d'effet");
            });
    };

    const getDateEcheance = (date, dateString) => {
        console.log(date, dateString);
        setDateEffet(date);
        console.log(date.add(12, "M").format("YYYY-MM-DD HH:mm:ss"));
        date && setDateEcheance(date.add(12, "M").format("YYYY-MM-DD HH:mm:ss"));
        // sessionStorage.setItem("effet", date.toISOString());
    };

    const handleCancel = () => {
        setModalReportEffet(false);
        props.update(false);
    };

    return (
        <Modal
            title="REPORT D'EFFET"
            centered
            confirmLoading={loading}
            open={modalReportEffet}
            onOk={() => handleReportEffetSubmit()}
            onCancel={() => handleCancel()}
        >
            <div className="container" justify="center">
                <div className="grid-item" xs={12} sm={12} md={12} lg={10}>
                    <div>
                        Date Effet du présent contrat:{" "}
                        <Tag color="blue">
                            <b>{frenchDate(props?.contrat?.dateEffet)}</b>
                        </Tag>
                    </div>
                </div>
                <br />
                <br />
                <div className="grid-item" xs={12} sm={12} md={12} lg={10}>
                    <div>
                        Date Echéance du présent contrat:{" "}
                        <Tag color="blue">
                            <b>{frenchDate(props?.contrat?.dateEcheance)}</b>
                        </Tag>
                    </div>
                </div>
                <br />
                <br />
                <div className="grid-item" xs={12} sm={12} md={12} lg={10}>
                    <ConfigProvider locale={frFR}>
                        <DatePicker
                            onChange={getDateEcheance}
                            placeholder="Entrer la nouvelle date effet"
                            showTime={true}
                            showNow={true}
                            showToday={true}
                            defaultValue={dayjs()}
                        />
                    </ConfigProvider>
                </div>
                <br />
                <br />
                <br />
                <div className="grid-item" xs={12} sm={12} md={12} lg={10}>
                    <div>
                        Nouvelle Date Echéance:{" "}
                        <Tag color="green">
                            {/* <b>{showDateWithTime(dateEcheance)}</b> */}
                            <b>{dateEcheance}</b>
                        </Tag>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ReportEffet.propTypes = {
    open: PropTypes.bool,
    update: PropTypes.func,
};

export default ReportEffet;