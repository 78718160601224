/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

import {Input} from "antd";


export default function CustomInput(props) {
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        white,
        inputRootCustomClasses,
        success,
        helperText,
        rtlActive,
    } = props;


    let newInputProps = {
        maxLength:
            inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
        minLength:
            inputProps && inputProps.minLength ? inputProps.minLength : undefined,
        step: inputProps && inputProps.step ? inputProps.step : undefined,
    };
    return (
        <div {...formControlProps} className={''}>
            {labelText !== undefined ? (
                <label
                    className={''}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </label>
            ) : null}
            <Input
                id={id}
                {...inputProps}
                onChange={props.onChange}
                value={props.value}
                inputProps={newInputProps}
            />
            {helperText !== undefined ? (
                <div id={id + "-text"} className={''}>
                    {helperText}
                </div>
            ) : null}
        </div>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node,
    rtlActive: PropTypes.bool,
};
