import { jwtDecode } from "jwt-decode";
import { LOGGED_USER, TOKEN } from "../variables/constants";

const isAuthenticated = () => {
  const token = TOKEN;

  if (token !== "null.null.null") {
    const { exp } = jwtDecode(token);

    if (exp * 1000 > new Date().getTime()) {
      return true;
    }
    return false;
  }
  return false;
};

const isActive = () => {
  if (LOGGED_USER.active) {
    return true;
  }
  return false;
};

export default isAuthenticated;
export { isActive };
