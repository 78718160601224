import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import { API_URL, TOKEN } from '../../../variables/constants';
import AddTypeAttestation from './AddTypeAttestation';

const TypesAttestations = () => {
    const [types, setTypes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedTypeAttestation, setSelectedTypeAttestation] = useState(null);

    const handleShow = (edit = false, type = null) => {
        setShowModal(true);
        setEditMode(edit);
        setSelectedTypeAttestation(type);
    };

    const handleClose = () => {
        setShowModal(false);
        setEditMode(false);
        setSelectedTypeAttestation(null);
    };

    // Fetch list of cities
    useEffect(() => {
        axios
            .get(`${API_URL}/api/parametre/type/TYPE_ATTESTATION`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => setTypes(res.data))
            .catch((err) => console.error("Error fetching types:", err));
    }, [showModal]);

    // Handle delete action with confirmation
    const handleDeleteType = (typeId) => {
        Modal.confirm({
            title: "Êtes-vous sûr de vouloir supprimer cet type ?",
            content: "Cette action est irréversible",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk: () => {
                axios
                    .delete(`${API_URL}/api/parametre/${typeId}`, {
                        headers: { Authorization: `Bearer ${TOKEN}` },
                    })
                    .then(() => {
                        setTypes((prevTypes) =>
                            prevTypes.filter((type) => type.parametreId !== typeId)
                        );
                        toast.success("Type supprimée avec succès.");
                    })
                    .catch((err) => {
                        console.error("Error deleting type:", err);
                        toast.error("Erreur lors de la suppression de la type.");
                    });
            },
        });
    };

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                style={{ marginBottom: 16 }}
                                onClick={() => handleShow()}
                            >
                                Ajouter un nouvel type d'attestation
                            </Button>
                            <div className="card">
                                <h5 className="card-header">Paramétrage de types d'attestation</h5>
                                <div className="table-responsive text-nowrap">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {types?.map((type, id) => (
                                                <tr key={id}>
                                                    <td>{type.libelleParametre}</td>
                                                    <td>
                                                        <Dropdown
                                                            overlay={
                                                                <Menu>
                                                                    <Menu.Item
                                                                        icon={<EditOutlined />}
                                                                        onClick={() => handleShow(true, type)}
                                                                    >
                                                                        Modifier
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        icon={<DeleteOutlined />}
                                                                        danger
                                                                        onClick={() => handleDeleteType(type.parametreId)}
                                                                    >
                                                                        Supprimer
                                                                    </Menu.Item>
                                                                </Menu>
                                                            }
                                                            trigger={["click"]}
                                                        >
                                                            <Button type="text" icon={<PlusOutlined />} />
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <AddTypeAttestation
                show={showModal}
                handleClose={handleClose}
                editMode={editMode}
                selectedTypeAttestation={selectedTypeAttestation}
            />
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    );
}

export default TypesAttestations