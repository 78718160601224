import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal, Dropdown, Menu } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddVille from "./AddVille";
import { toast } from "react-toastify";

const Villes = () => {
  const [villes, setVilles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedVille, setSelectedVille] = useState(null);

  const handleShow = (edit = false, ville = null) => {
    setShowModal(true);
    setEditMode(edit);
    setSelectedVille(ville);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedVille(null);
  };

  // Fetch list of cities
  useEffect(() => {
    axios
      .get(`${API_URL}/api/parametre/type/VILLE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => setVilles(res.data))
      .catch((err) => console.error("Error fetching cities:", err));
  }, [showModal]);

  // Handle delete action with confirmation
  const handleDeleteVille = (villeId) => {
    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette ville ?",
      content: "Cette action est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk: () => {
        axios
          .delete(`${API_URL}/api/parametre/${villeId}`, {
            headers: { Authorization: `Bearer ${TOKEN}` },
          })
          .then(() => {
            setVilles((prevVilles) =>
              prevVilles.filter((ville) => ville.parametreId !== villeId)
            );
            toast.success("Ville supprimée avec succès.");
          })
          .catch((err) => {
            console.error("Error deleting ville:", err);
            toast.error("Erreur lors de la suppression de la ville.");
          });
      },
    });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu="AUTRES_PARAMETRES" />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow()}
              >
                Ajouter une nouvelle ville
              </Button>
              <div className="card">
                <h5 className="card-header">Paramétrage de villes</h5>
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {villes?.map((ville, id) => (
                        <tr key={id}>
                          <td>{ville.libelleParametre}</td>
                          <td>
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    icon={<EditOutlined />}
                                    onClick={() => handleShow(true, ville)}
                                  >
                                    Modifier
                                  </Menu.Item>
                                  <Menu.Item
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => handleDeleteVille(ville.parametreId)}
                                  >
                                    Supprimer
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={["click"]}
                            >
                              <Button type="text" icon={<PlusOutlined />} />
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <AddVille
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedVille={selectedVille}
      />
    </div>
  );
};

export default Villes;
