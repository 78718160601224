import { ConfigProvider, DatePicker, Select, Spin, Table, Tag } from 'antd'
import frFR from "antd/locale/fr_FR"
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import Footer from '../../components/Footer'
import NavBar from '../../components/NavBar'
import SideBar from '../../components/SideBar'
import { API_URL, TOKEN } from '../../variables/constants'
import { frenchDate, frenchDateSimple, isBetweenOrEqualDate, isEqualDate } from '../../variables/functions'
import PdfBordereau from './components/PdfBordereau'
const { RangePicker } = DatePicker;

const Bordereaux = () => {
    const [commissions, setCommissions] = useState([]);
    const [allCommissions, setAllCommissions] = useState([]);
    const [loadingContrats, setLoadingContrats] = useState(true);
    const [categories, setCategories] = useState([]);
    const [intermediaires, setIntermediaires] = useState([]);
    // const [allIntermediaires, setAllIntermediaires] = useState([]);
    const [courtiers, setCourtiers] = useState([]);
    // const [selectedIntermediaire, setSelectedIntermediaire] = useState("");
    const [selectedCategorie, setSelectedCategorie] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [hidden, setHidden] = useState(false);

    const printReference = useRef();

    const commissionColumns = [
        {
            title: "N° Police",
            dataIndex: "numeroPolice",
            key: "1",
        },
        {
            title: "Souscripteur",
            dataIndex: "souscripteur",
            key: "2",
        },
        {
            title: "Montant Prime",
            dataIndex: "primeNette",
            key: "4",
            render: (_, record) => (
                <>
                    {record.data.montant ? new Intl.NumberFormat("en-DE").format(
                        record.data.montant
                    ) : 0}
                </>
            )
        },
        {
            title: "Commission",
            dataIndex: "commission",
            key: "5",
            render: (_, record) => (
                <>
                    {record.data.commission ? new Intl.NumberFormat("en-DE").format(
                        record.data.commission
                    ) : 0}
                </>
            )
        },
        {
            title: "Statut reglement",
            dataIndex: "regle",
            key: "regle",
            render: (_, record) => (
                <>
                    {record.data.commissionPaye ? (
                        <Tag color="success" key={_}>
                            REGLE
                        </Tag>
                    ) : (
                        <Tag color="blue" key={_}>
                            NON REGLE
                        </Tag>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        let allInt = [];
        axios
            .get(`${API_URL}/api/contrat`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let coms = [];
                let comKeys = [];
                res.data.forEach((contrat) => {
                    if (contrat.reglements && contrat.reglements.length) {
                        contrat.reglements.forEach((reg) => {
                            reg.montant < 0 && !reg.commissionPaye && comKeys.push(reg.codeId);
                            coms.push({
                                key: reg.codeId,
                                codeId: reg.codeId,
                                id: reg.codeId,
                                numeroPolice: contrat.numeroPoliceP,
                                souscripteur: contrat?.souscripteur?.prenom
                                    ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
                                    : `${contrat?.souscripteur?.nom}`,
                                organisme: contrat.organisme.raisonSociale,
                                dateEffet: contrat?.dateEffet
                                    ? `${frenchDate(contrat?.dateEffet)}`
                                    : "",
                                dateEcheance: contrat?.dateEcheance
                                    ? `${frenchDateSimple(contrat?.dateEcheance)} `
                                    : "",
                                regle: reg.dateReglement
                                    ? "REGLE"
                                    : "NON REGLE",
                                data: reg,
                                contrat: contrat
                            });
                        });
                    }
                });
                console.log("Comms: ", coms);
                setCommissions(coms);
                setAllCommissions(coms);
                setLoadingContrats(false);
            })
            .catch((err) => {
                console.log(err);
            });


        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let cats = [];
                res.data.filter(e => !e.codeCategorie.startsWith("VT")).forEach((cat) => {
                    cats.push(cat.codeCategorie);
                });
                setSelectedCategorie(cats);
                setCategories(res.data.filter(e => !e.codeCategorie.startsWith("VT")));
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/intermediaire`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("Res; ", res.data);
                res.data.forEach((int) => {
                    allInt.push(int.codeIntermediaire);
                });
                setIntermediaires(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/agence/courtiers`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("Agreed courtiers: ", res.data);
                res.data.forEach((ag) => {
                    allInt.push(ag.codeAgence);
                });
                setCourtiers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setTimeout(() => {
            setSelectedAgence(allInt);
        }, 1000);
    }, []);

    const handleAgenceChange = (agencies) => {
        let filRegs = [];
        agencies.forEach((value) => {
            let inter = intermediaires.find(el => el.codeIntermediaire === value);
            if (inter) {
                // setSelectedIntermediaire(inter);
                filRegs = allCommissions.filter(el => el?.contrat?.intermediaire?.codeIntermediaire === value);
            } else {
                // let ag = courtiers.find(el => el.codeAgence === value);
                filRegs = allCommissions.filter(el => el?.contrat?.courtier?.codeAgence === value);
                // setSelectedIntermediaire(ag);
            }
        });

        setSelectedAgence(agencies);
        setCommissions(filRegs);
        console.log("Commissions: ", filRegs);
        selectedCategorie && categorieFilteringOn(selectedCategorie);
        selectedDate && dateFilteringOn("ok", selectedDate);
    };

    const handleAgenceChangeOn = (agencies) => {
        let filRegs = [];
        agencies.forEach((value) => {
            let inter = intermediaires.find(el => el.codeIntermediaire === value);
            if (inter) {
                // setSelectedIntermediaire(inter);
                filRegs = commissions.filter(el => el?.contrat?.intermediaire?.codeIntermediaire === value);
            } else {
                // let ag = courtiers.find(el => el.codeAgence === value);
                filRegs = commissions.filter(el => el?.contrat?.courtier?.codeAgence === value);
                // setSelectedIntermediaire(ag);
            }
        });

        setSelectedAgence(agencies);
        setCommissions(filRegs);
    };

    const dateFiltering = (date, dateString) => {
        setSelectedDate(dateString);
        let filRegs = [];
        if (dateString[0] === dateString[1]) {
            allCommissions.forEach((dt) => {
                isEqualDate(dateString[0], dt.data.createdAt) && filRegs.push(dt);
            });
            setCommissions(filRegs);
        } else {
            allCommissions.forEach((dt) => {
                isBetweenOrEqualDate(dt.data.createdAt, dateString[0], dateString[1]) && filRegs.push(dt);
            });
            setCommissions(filRegs);
        }

        if (!dateString[0].length && !dateString[1].length) {
            setCommissions(allCommissions);
        }

        selectedCategorie && categorieFilteringOn(selectedCategorie);
        selectedAgence && handleAgenceChangeOn(selectedAgence);
    }

    const dateFilteringOn = (date, dateString) => {
        setSelectedDate(dateString);
        let filRegs = [];
        if (dateString[0] === dateString[1]) {
            commissions.forEach((dt) => {
                isEqualDate(dateString[0], dt.data.createdAt) && filRegs.push(dt);
            });
            setCommissions(filRegs);
        } else {
            commissions.forEach((dt) => {
                isBetweenOrEqualDate(dt.data.createdAt, dateString[0], dateString[1]) && filRegs.push(dt);
            });
            setCommissions(filRegs);
        }

        if (!dateString[0]?.length && !dateString[1]?.length) {
            setCommissions(commissions);
        }
    }


    const categorieFiltering = (sCategories) => {
        setSelectedCategorie(sCategories);
        let filRegs = [];
        sCategories.forEach((categorie) => {
            if (categorie) {
                filRegs = allCommissions.filter(el => el?.contrat?.engins[0]?.categorie?.codeCategorie === categorie);

            }
        });
        setCommissions(filRegs);
        selectedAgence && handleAgenceChangeOn(selectedAgence);
        selectedDate && dateFilteringOn("ok", selectedDate);
    }

    const categorieFilteringOn = (sCategories) => {
        setSelectedCategorie(sCategories);
        let filRegs = [];
        sCategories.forEach((categorie) => {
            if (categorie) {
                filRegs = commissions.filter(el => el?.contrat?.engins[0]?.categorie?.codeCategorie === categorie);
            }
        });
        setCommissions(filRegs);
    }

    const printBordereau = () => {
        console.log("Printing");
        setHidden(true);
        handlePrint();
    }

    const handlePrint = useReactToPrint({
        content: () => printReference.current,
    });

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"COURTIERS"} />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            {/* Your main content here */}
                            <div class="row g-6 mb-6">
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Filtrer suivant la Période</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <RangePicker onChange={dateFiltering} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="align-items-start justify-content-between">
                                                <div class="content">
                                                    <span class="text-heading">Filtrer suivant la Catégorie</span>
                                                    <div class="align-items-center my-1">
                                                        <Select
                                                            mode="multiple"
                                                            placeholder="Sélectionner la catégorie......"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            onChange={categorieFiltering}
                                                            options={categories.map((cat) => ({
                                                                value: cat.codeCategorie,
                                                                label: cat.nom
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Filtrer suivant l'Intermédiaire</span>
                                                    <div class="align-items-center my-1">
                                                        <Select
                                                            mode="multiple"
                                                            placeholder="Sélectionner l'intermédiaire......"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            onChange={handleAgenceChange}
                                                            options={[
                                                                {
                                                                    label: <span>Intermédiaire</span>,
                                                                    title: 'intermediaire',
                                                                    options: intermediaires?.map((a, id) => ({
                                                                        label: a.personne.nom,
                                                                        value: a.codeIntermediaire,
                                                                        key: id,
                                                                    }))
                                                                },
                                                                {
                                                                    label: <span>Courtier</span>,
                                                                    title: 'courtier',
                                                                    options: courtiers?.map((c, id) => ({
                                                                        label: c.nom,
                                                                        value: c.codeAgence,
                                                                        key: id,
                                                                    }))
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">-</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        -
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-4">
                                <div class="card-datatable table-responsive">
                                    <ConfigProvider locale={frFR}>
                                        <Spin spinning={loadingContrats}>
                                            <Table
                                                columns={commissionColumns}
                                                dataSource={commissions}
                                            />
                                        </Spin>
                                    </ConfigProvider>
                                </div>
                                {/* <FloatButton style={{ color: "yellowgreen" }} onClick={() => console.log('onClick')} /> */}
                            </div>
                            {hidden && <PdfBordereau ref={printReference} allCommissions={commissions} allCategories={selectedCategorie} allIntermediaires={selectedAgence} />}
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>
            <div class="buy-now">
                <button onClick={() => printBordereau()} class="btn btn-primary btn-buy-now">
                    Imprimer
                </button>
            </div>
            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    )
}

export default Bordereaux