import React from "react";

const Footer = () => {
  return (
    <footer class="content-footer footer bg-footer-theme">
      <div class="container-xxl">
        <div class="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
          <div class="text-body">© OSSANASUR 2024</div>
          <div class="d-none d-lg-inline-block">
            <a href="/" class="footer-link me-4">
              Documentation
            </a>

            <a
              href="https://ossanasur.com/support/"
              class="footer-link d-none d-sm-inline-block"
            >
              Support
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
