import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Menu, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { rightsService } from "../../services/rightsService";
import { API_URL, TOKEN } from "../../variables/constants";
import axiosInstance from "../../variables/api/axiosInstance";

const Profils = () => {
  const [profils, setProfils] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchData = (params = {}) => {
    setLoading(true);
    axiosInstance
      .get(`/api/profil`)
      .then((res) => {
        setPagination({
          ...params.pagination,
          total: res.data?.totalElements,
        });
        setProfils(res.data.content);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData({pagination, filteredInfo})
  }, [currentPage, pageSize]);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setPagination(pagination);
  };

  const columns = [
    {
      title: "Intitulé",
      dataIndex: "nom",
      key: "nom",
    },
    {
      title: "Organisme",
      dataIndex: "organisme",
      key: "organisme",
      render: (org) => <Badge color="blue" text={org?.raisonSociale} />,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              {rightsService("HAC056") && <Menu.Item key="1">
                <a href={`/modifier-profil/${record.codeId}`}></a> Modifier
              </Menu.Item>}
              {rightsService("HAC058") && <Menu.Item key="2">Supprimer</Menu.Item>}
            </Menu>
          }
        >
          <Button icon={<DownOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"PROFILS"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
              {rightsService("HAC057") && <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => {
                  navigate("/ajouter-profil");
                }}
              >
                Créer un nouveau profil
              </Button>}
              <div class="card">
                <Table
                  columns={columns}
                  dataSource={profils}
                  onChange={handleChange}
                  rowKey="codeId"
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.commentaire}</p>
                    ),
                  }}
                  pagination={pagination}
                  loading={loading}
                />
              </div>
              <Footer />
            </div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>
      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default Profils;
