import React, { useState, useEffect } from "react";
import { Switch } from "antd";
import axios from "axios";
import { API_URL, TOKEN } from "../../variables/constants";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const AddSousGarantie = ({ show, handleClose, editMode, garantie, selectedSousGarantie }) => {
  const [obligatoire, setObligatoire] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nom: "",
    commentaire: "",
    trancheMin: 0,
    trancheMax: 0,
    primeNette: 0,
    tauxPCent: 0,
    tauxPMille: 0,
    garantie: garantie,
  });

  useEffect(() => {
    console.log("Valeur de selectedSousGarantie avant récupération :", selectedSousGarantie);

    if (editMode && selectedSousGarantie?.codeId) {
      axios
        .get(`${API_URL}/api/sous-garantie/codeId/${selectedSousGarantie.codeId}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("Données récupérées pour modification :", res.data);
          setFormData({
            ...res.data,
            trancheMin: res.data.tranche ? res.data.tranche[0] : 0,
            trancheMax: res.data.tranche ? res.data.tranche[1] : 0,
            garantie: res.data.garantie || garantie,
          });
          setObligatoire(res.data.obligatoire || false);
        })
        .catch((err) => {
          console.error("Erreur lors de la récupération de la sous-garantie :", err);
          toast.error("Erreur lors de la récupération des données de la sous-garantie.");
        });
    } else {
      console.log("Mode création: initialisation du formulaire.");
      setFormData({
        nom: "",
        commentaire: "",
        obligatoire: false,
        trancheMin: 0,
        trancheMax: 0,
        primeNette: 0,
        tauxPCent: 0,
        tauxPMille: 0,
        garantie: garantie,
      });
      setObligatoire(false);
    }
  }, [editMode, selectedSousGarantie, garantie]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Changement dans ${name}: ${value}`);
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const sousGarantieData = {
      ...formData,
      tranche: formData.trancheMin || formData.trancheMax ? [formData.trancheMin, formData.trancheMax] : [],
      obligatoire,
    };

    console.log("Données soumises pour mise à jour :", sousGarantieData);

    const request = editMode
      ? axios.put(`${API_URL}/api/sous-garantie`, sousGarantieData, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      : axios.post(`${API_URL}/api/sous-garantie`, sousGarantieData, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      });

    request
      .then((res) => {
        console.log("Réponse de l'API :", res.data);
        toast.success(editMode ? "Sous-garantie modifiée avec succès!" : "Sous-garantie ajoutée avec succès!");
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        console.error("Erreur lors de la soumission de la sous-garantie :", err.response || err);
        toast.error("Erreur lors de la soumission de la sous-garantie!");
      })
      .finally(() => {
        setLoading(false);
        console.log("Requête API terminée.");
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button type="button" className="btn-close btn-primary" onClick={handleClose} aria-label="Close"></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la" : "Ajouter une"} sous-garantie
          </h4>
          <p className="address-subtitle">
            {editMode ? "Modifier la" : "Ajouter une nouvelle"} garantie à une catégorie
          </p>
        </div>
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-6">
            <label className="form-label" htmlFor="nom">
              Libellé de la sous-garantie
            </label>
            <input
              type="text"
              id="nom"
              onChange={handleChange}
              className="form-control"
              placeholder="Libellé de la sous-garantie"
              name="nom"
              value={formData.nom || ""}
              required
            />
          </div>
          <div className="col-6">
            <label className="form-label" htmlFor="primeNette">
              Prime nette
            </label>
            <input
              type="number"
              id="primeNette"
              onChange={handleChange}
              className="form-control"
              placeholder="Prime nette"
              name="primeNette"
              value={formData.primeNette || 0}
              required
            />
          </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-6">
              <label className="form-label" htmlFor="tauxPCent">
                Taux (en %)
              </label>
              <input
                type="number"
                id="tauxPCent"
                onChange={handleChange}
                className="form-control"
                placeholder="Taux en pourcentage"
                name="tauxPCent"
                value={formData.tauxPCent || 0}
              />
            </div>
            <div className="col-6 col-md-6">
              <label className="form-label" htmlFor="tauxPMille">
                Taux (en ‰)
              </label>
              <input
                type="number"
                id="tauxPMille"
                onChange={handleChange}
                className="form-control"
                placeholder="Taux en pour mille"
                name="tauxPMille"
                value={formData.tauxPMille || 0}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-6">
            <label className="form-label" htmlFor="trancheMin">
              Tranche minimale
            </label>
            <input
              type="number"
              id="trancheMin"
              onChange={handleChange}
              className="form-control"
              placeholder="Tranche minimale"
              name="trancheMin"
              value={formData.trancheMin || 0}
            />
          </div>
          <div className="col-6">
            <label className="form-label" htmlFor="trancheMax">
              Tranche maximale
            </label>
            <input
              type="number"
              id="trancheMax"
              onChange={handleChange}
              className="form-control"
              placeholder="Tranche maximale"
              name="trancheMax"
              value={formData.trancheMax || 0}
            />
          </div>
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="commentaire">
              Description
            </label>
            <textarea
              id="commentaire"
              onChange={handleChange}
              className="form-control"
              placeholder="Description de la sous-garantie"
              name="commentaire"
              value={formData.commentaire || ""}
            ></textarea>
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="obligatoire">
              Garantie obligatoire ?
            </label>
            <br />
            <Switch
              checkedChildren="OUI"
              unCheckedChildren="NON"
              onChange={setObligatoire}
              checked={obligatoire}
            />
          </div>
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-primary me-3" disabled={loading}>
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button type="button" className="btn btn-label-secondary" onClick={handleClose}>
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddSousGarantie;
