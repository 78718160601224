import {frenchDate} from "../../../variables/functions";
import entete from '../../../assets/img/entete.jpg'
import entfoot from '../../../assets/img/footer.png'
import {Image} from "antd";
import PropTypes from "prop-types";

const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },

    epais: {
        border: "1px solid #063970",
        margin: "0px 0px 0px 0px",
    },

    textLeft: {
        textAlign: "left",
    },

    textRight: {
        textAlign: "right",
    },

    textCenter: {
        textAlign: "center",
    },

    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornment: {
        position: "relative",
    },
};


const AttestationDeclarationSinistre = (props) => {
    return (
        <div
            className="container"
            justify="center"
        >
            <div
                className="container"
            >
                <div className="grid-item">
                    <Image src={entete}/>
                    <div testimonial>
                        <div>
                            <div className="grid-item">
                                <hr></hr>
                                <div
                                    className="container"
                                    justify="center"
                                >
                                    <div className="grid-item">
                                        <h5>ATTESTATION DE DECLARATION DE SINISTRE</h5>
                                    </div>
                                </div>
                                <hr></hr>
                                <br/>
                                <br/>
                                <div
                                    className="container"
                                    justify="center"
                                >
                                    <div className="grid-item">
                                        <h7>
                                            <b>Le POOL TPVM-VT GIE atteste que le vehicule :</b>
                                        </h7>
                                    </div>
                                    <div className="grid-item">
                                    </div>
                                    <br/>
                                    <br/>
                                    <div
                                        className="container"
                                        justify="center"
                                    >
                                        <table>
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>N° Immatriculation: </b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td>
                                                    <h7>{props?.sinistre?.engin?.immatriculation}</h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>N° Chassis :</b> &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td>
                                                    <h7>{props?.sinistre?.engin?.numeroChassis}</h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>Marque : </b> &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td>
                                                    <h7>{props?.sinistre?.engin?.marque}</h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>Catégorie :</b> &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td>
                                                    <h7>
                                                        {props?.sinistre?.engin?.categorie?.nom.toUpperCase()}
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>Appartenant à: </b> &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td>
                                                    <h7>
                                                        {props?.sinistre?.engin?.personne?.prenom
                                                            ? `${props?.sinistre?.engin?.personne?.nom} ${props?.sinistre?.engin?.personne?.prenom} `
                                                            : `${props?.sinistre?.engin?.personne?.nom}`}
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>

                                    </div>
                                    <br/>
                                    <div
                                        className="container"
                                        justify="center"
                                    >
                                        <div className="grid-item">
                                            <h7>
                                                <b>est assuré sous la police d&apos;assurance :</b>
                                            </h7>
                                        </div>
                                        <div className="grid-item">
                                        </div>
                                        <br/>
                                        <div
                                            className="container"
                                            justify="center"
                                        >
                                            <table>
                                                <tr>
                                                    <td>
                                                        <h7>
                                                            <b>N° Police: </b>
                                                            &nbsp; &nbsp;
                                                        </h7>
                                                    </td>
                                                    <td>
                                                        <h7>
                                                            <b>
                                                                {props?.sinistre?.contrat?.numeroPoliceM
                                                                    ? props?.sinistre?.contrat?.numeroPoliceM
                                                                    : props?.sinistre?.contrat?.numeroPoliceP}
                                                            </b>
                                                        </h7>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h7>
                                                            <b>Effet :</b> &nbsp; &nbsp;
                                                        </h7>
                                                    </td>
                                                    <td>
                                                        <h7>
                                                            {frenchDate(props?.sinistre?.contrat?.dateEffet)}
                                                        </h7>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h7>
                                                            <b>Echéance : </b> &nbsp; &nbsp;
                                                        </h7>
                                                    </td>
                                                    <td>
                                                        <h7>
                                                            {frenchDate(props?.sinistre?.contrat?.dateEcheance)}
                                                        </h7>
                                                    </td>
                                                </tr>
                                            </table>

                                        </div>
                                        <br/>
                                        <div
                                            className="container"
                                            justify="center"
                                        >
                                            <div
                                                xs={12}
                                                sm={12}
                                                md={12}
                                            >
                                                <h7>
                                                    <p>
                                                        Le sinistre survenu le{" "}
                                                        <b>{frenchDate(props?.sinistre?.dateSinistre)} </b>,
                                                        déclaré le{" "}
                                                        <b>{frenchDate(props?.sinistre?.createdAt)} </b> est
                                                        enrégisté sous le numéro{" "}
                                                        <b>{props?.sinistre?.numSinistre} </b>
                                                    </p>
                                                    <p>
                                                        En foi de quoi, la présente attestation est délivré pour
                                                        servir et valoir ce que de droit, en particulier au
                                                        retrait des pièces et / ou du véhicule auprès des agents
                                                        ayant effectué le constat.
                                                    </p>
                                                    <p>
                                                        <b>
                                                            Cette attestation ne présente qu&apos;un caractère
                                                            administratif et ne constitue en rien une quelconque
                                                            reconnaissance de responsabilité totale comme
                                                            partielle du POOL TPVM-VT GIE.
                                                        </b>
                                                    </p>
                                                    <br/>
                                                    <p>
                                                        <b>
                                                            NB: Cette prise de déclaration de sinistre vaut
                                                            demande de rapport d&apos;accident ou du PV de
                                                            constat.
                                                        </b>
                                                    </p>
                                                    <p>
                                                        Entité ayant fait le constat :{" "}
                                                        {props?.sinistre?.entiteConstat?.nom.toUpperCase()}
                                                    </p>
                                                </h7>
                                                <br/> <br/>
                                            </div>
                                        </div>
                                        <div
                                            className="container"
                                            justify="center"
                                        >
                                            <table>
                                                <tr>
                                                    <td>
                                                        <h7>
                                                            Fait à lomé le{" "}
                                                            {frenchDate(props?.sinistre?.dateDeclaration)}
                                                        </h7>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <h7>
                                                            <b>Pour la société</b>
                                                        </h7>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Image src={entfoot}/>
                </div>
            </div>
        </div>
    );
};

AttestationDeclarationSinistre.propTypes = {
    sinistre: PropTypes.object,
};

export default AttestationDeclarationSinistre;
