import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API_URL, TOKEN } from '../../../variables/constants';

const AddTypeAttestation = ({ show, handleClose, editMode, selectedTypeAttestation }) => {
    const [loading, setLoading] = useState(false);
    const [nom, setNom] = useState("");

    // Populate form fields when in edit mode
    useEffect(() => {
        if (editMode && selectedTypeAttestation) {
            setNom(selectedTypeAttestation.libelleParametre || "");
        } else {
            setNom("");
        }
    }, [editMode, selectedTypeAttestation]);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const typeAttestationData = {
            ...selectedTypeAttestation,
            libelleParametre: nom,
            typeParametre: "TYPE_ATTESTATION",
        };

        const request = editMode
            ? axios.put(`${API_URL}/api/parametre`, JSON.stringify(typeAttestationData), {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            })
            : axios.post(`${API_URL}/api/parametre`, JSON.stringify(typeAttestationData), {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            });

        request
            .then((res) => {
                toast.success(editMode ? "Type d'attestation modifié avec succès" : "Type d'attestation ajouté avec succès");
                setNom("");
                handleClose();
            })
            .catch((err) => {
                console.error("Error:", err);
                toast.error(editMode ? "Erreur lors de la modification" : "Erreur lors de l'ajout");
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Body>
                <button
                    type="button"
                    className="btn-close btn-primary"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">
                        {editMode ? "Modifier le" : "Ajouter un"} type d'attestation
                    </h4>
                    <p className="address-subtitle">
                        {editMode ? "Modifier le" : "Ajouter un nouvel"} type d'attestation
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <label className="form-label" htmlFor="modalAddressFirstName">
                            Nom
                        </label>
                        <input
                            type="text"
                            id="modalAddressFirstName"
                            onChange={(e) => setNom(e.target.value)}
                            className="form-control"
                            placeholder="Libelle du type d'attestation"
                            name="nom"
                            value={nom}
                        />
                    </div>
                    <div className="col-12 text-center">
                        <button
                            type="submit"
                            className="btn btn-primary me-3"
                            disabled={loading}
                        >
                            {loading && (
                                <span
                                    className="spinner-grow me-1"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            {loading ? `Enregistrement...` : `Enregistrer`}
                        </button>
                        <button
                            type="reset"
                            className="btn btn-label-secondary"
                            onClick={handleClose}
                        >
                            Annuler
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddTypeAttestation