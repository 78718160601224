import {
    ExclamationCircleFilled,
    PlusOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {
    Badge,
    Button,
    Dropdown,
    Menu,
    Modal,
    Table,
    Tooltip
} from "antd";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import {rightsService} from "../../services/rightsService";
import {API_URL, TOKEN} from "../../variables/constants";
import axiosInstance from "../../variables/api/axiosInstance";

const {confirm} = Modal;
// import moment from "moment";

const Utilisateurs = () => {
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});

    const [activeUtilisateurs, setActiveUtilisateurs] = useState(0);
    const [pendingUsers, setPendingUsers] = useState(0);
    const [nonActiveUsers, setNonActiveUsers] = useState(0);

    const navigate = useNavigate();

    const columns = [
        {
            title: "Nom & Prénom(s)",
            dataIndex: "name",
            key: "name",
            render: (name, record) => (
                <div className="d-flex align-items-center">
                    <div className="avatar-wrapper">
                        <div className="avatar avatar-sm me-3">
                            {record.photo_url ? (
                                <img
                                    src={`path/to/assets/img/avatars/${Math.floor(Math.random() * 11) + 1
                                    }.png`}
                                    alt="Avatar"
                                    className="rounded-circle"
                                />
                            ) : (
                                <span className="avatar-initial rounded-circle bg-label-success">
                  {name
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <a
                            href={`/details-utilisateur/${record.codeId}`}
                            className="text-heading text-truncate"
                        >
                            {name}
                        </a>
                        <br/>
                        <small className="text-truncate">{record.username}</small>
                    </div>
                </div>
            ),
        },
        {
            title: "Adresse mail",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Fonction",
            dataIndex: "fonction",
            key: "fonction",
        },
        {
            title: "Profil",
            dataIndex: "profil",
            key: "profil",
            render: (role, record) => (
                <Tooltip title={rightsService("HAC056") ? "Cliquez pour modifier le profil" : ""}>
          <span onClick={() => rightsService("HAC056") && navigate(`/modifier-profil/${record.data.profil.codeId}`)}>
            <Badge color={"blue"} text={role}/>
          </span>
                </Tooltip>
            ),
        },
        {
            title: "Actions",
            key: "action",
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="1">
                                <a href={`/details-utilisateur/${record.codeId}`}>Détails</a>
                            </Menu.Item>
                            {rightsService("HPE039") ? <Menu.Item
                                key="2"
                                onClick={() =>
                                    showActivateConfirm(record.codeId, record.active)
                                }
                            >
                                {record.active ? "Désactiver" : "Activer"}{" "}
                            </Menu.Item> : null}
                            {rightsService("HPE041") ? <Menu.Item key="3">
                                <a href={`/details-utilisateur/${record.codeId}`}>Modifier</a>
                            </Menu.Item> : null}
                            {rightsService("HPE041") ?
                                <Menu.Item key="4" onClick={() => showReinitialisationConfirm(record.email)}>
                                    Réinitialisation
                                </Menu.Item> : null}
                            {rightsService("HPE042") ?
                                <Menu.Item key="5"
                                           onClick={() => showDeletionConfirm(record.codeId)}>Supprimer</Menu.Item> : null}
                        </Menu>
                    }
                >
                    <Button icon={<SettingOutlined/>}/>
                </Dropdown>
            ),
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
        setPagination(pagination);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const fetchData = (params = {}) => {
        setLoading(true);
        axiosInstance
            .get(`/api/utilisateur`, {
                params: {
                    page: params.pagination.current - 1,
                    size: params.pagination.pageSize,
                    ...params.filters,
                },
            })
            .then((res) => {
                console.log(res.data);
                let usr = [];
                res.data?.content?.forEach((user, index) => {
                    usr.push({
                        id: index + 1,
                        nom: user.personne?.nom,
                        prenom: user.personne?.prenom,
                        name: `${user.personne?.nom} ${user.personne?.prenom}`,
                        username: user.username,
                        fonction: user.personne?.fonction,
                        email: user?.email,
                        profil: user.profil?.nom,
                        active: user.active,
                        data: user,
                        codeId: user.codeId,
                    });
                });
                setPagination({
                    ...params.pagination,
                    total: res.data?.totalElements,
                  });
                setUtilisateurs(usr);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchData({pagination, filteredInfo});
    }, [currentPage, pageSize]);

    const showActivateConfirm = (utilisateurCodeId, status) => {
        confirm({
            title: status
                ? "Etes-vous sûr de vouloir désactiver cet utilisateur?"
                : "Etes-vous sûr de vouloir activer cet utilisateur?",
            icon: <ExclamationCircleFilled/>,
            content: "Cette action sera historisée et donc est irréversible",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                activateUtilisateur(utilisateurCodeId, status);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const showDeletionConfirm = (utilisateurCodeId) => {
        confirm({
            title: "Etes-vous sûr de vouloir de supprimer cet utilisateur?",
            icon: <ExclamationCircleFilled/>,
            content: "Cette action sera historisée et donc est irréversible",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                deleteUtilisateur(utilisateurCodeId);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const showReinitialisationConfirm = (email) => {
        confirm({
            title: "Etes-vous sûr de vouloir de réinitialiser ce compte utilisateur?",
            icon: <ExclamationCircleFilled/>,
            content: "Cette action sera historisée et donc est irréversible",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                reinitUtilisateur(email);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const activateUtilisateur = (utilisateurCodeId, status) => {
        axios
            .get(`${API_URL}/api/utilisateur/activate/${utilisateurCodeId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                window.location.reload();
                toast(
                    status
                        ? "Utilisateur désactivé avec succès"
                        : "Utilisateur activé avec succès"
                );
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors de l'activation");
            });
    };

    const deleteUtilisateur = (utilisateurCodeId) => {
        axios
            .delete(`${API_URL}/api/utilisateur/${utilisateurCodeId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                window.location.reload();
                toast(
                    "Utilisateur supprimé avec succès"
                );
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors de la suppression");
            });
    };

    const reinitUtilisateur = (email) => {
        axiosInstance.get(`/api/utilisateur/reinitialisation/${email}`)
            .then((res) => {
                console.log(res.data);
                window.location.reload();
                toast(
                    "Utilisateur réinitialisé avec succès"
                );
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors de la réinitialisation");
            });
    };

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"UTILISATEURS"}/>

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar/>

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}

                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row g-6 mb-6">
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Utilisateurs</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h4 class="mb-0 me-2">{utilisateurs?.length}</h4>
                                                        {/* <p class="text-success mb-0">(+29%)</p> */}
                                                    </div>
                                                    <small class="mb-0">Total utilisateurs</small>
                                                </div>
                                                <div class="avatar">
                          <span class="avatar-initial rounded bg-label-primary">
                            <i class="bx bx-group bx-lg"></i>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                          <span class="text-heading">
                            Utilisateurs suspendus
                          </span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h4 class="mb-0 me-2">{nonActiveUsers}</h4>
                                                        {/* <p class="text-success mb-0">(+18%)</p> */}
                                                    </div>
                                                    <small class="mb-0">
                                                        Analyses de la semaine dernière
                                                    </small>
                                                </div>
                                                <div class="avatar">
                          <span class="avatar-initial rounded bg-label-danger">
                            <i class="bx bx-user-plus bx-lg"></i>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Utilisateurs actifs</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h4 class="mb-0 me-2">{activeUtilisateurs}</h4>
                                                        {/* <p class="text-danger mb-0">(-14%)</p> */}
                                                    </div>
                                                    <small class="mb-0">
                                                        Analyses de la semaine dernière
                                                    </small>
                                                </div>
                                                <div class="avatar">
                          <span class="avatar-initial rounded bg-label-success">
                            <i class="bx bx-user-check bx-lg"></i>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                          <span class="text-heading">
                            Utilisateurs en attente
                          </span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h4 class="mb-0 me-2">{pendingUsers}</h4>
                                                        {/* <p class="text-success mb-0">(+42%)</p> */}
                                                    </div>
                                                    <small class="mb-0">
                                                        Analyses de la semaine dernière
                                                    </small>
                                                </div>
                                                <div class="avatar">
                          <span class="avatar-initial rounded bg-label-warning">
                            <i class="bx bx-user-voice bx-lg"></i>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Users List Table --> */}
                            {rightsService("HPE038") ? <Button
                                type="primary"
                                icon={<PlusOutlined/>}
                                style={{marginBottom: 16}}
                                onClick={() => navigate("/ajouter-utilisateur")}
                            >
                                Créer un nouvel utilisateur
                            </Button> : null}

                            <div class="card">
                                <Table
                                    columns={columns}
                                    dataSource={utilisateurs}
                                    onChange={handleChange}
                                    rowKey="utilisateurId"
                                    pagination={pagination}
                                    loading={loading}
                                />

                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    );
};

export default Utilisateurs;
