import {
  CheckCircleOutlined,
  CreditCardFilled,
  ExclamationCircleFilled,
  FilePdfOutlined,
  MoneyCollectOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  Select,
  Switch,
  Tabs,
} from "antd";
import axios from "axios";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { rightsService } from "../../../services/rightsService";
import { API_URL, LOGGED_USER, TOKEN } from "../../../variables/constants";
import { frenchDate } from "../../../variables/functions";
import AttestationMoto from "../components/AttestationMoto";
import AttestationMotoToPrint from "../components/AttestationMotoToPrint";
import DTRFFormat from "../components/DTRFFormat";
import DTRFFormatProposition from "../components/DTRFFormatProposition";
import RecuCurrentFormat from "../components/RecuCurrentFormat";
import AugmentationDeGarantie from "./avenantsModalComponents/AugmentationDeGarantie";
import ChangementDeVehicule from "./avenantsModalComponents/ChangementDeVehicule";
import DiminutionDeGarantie from "./avenantsModalComponents/DiminutionDeGarantie";
import Incorporation from "./avenantsModalComponents/Incorporation";
import Prorogation from "./avenantsModalComponents/Prorogation";
import Renouvellement from "./avenantsModalComponents/Renouvellement";
import ReportEffet from "./avenantsModalComponents/ReportEffet";
import TransfertGarantie from "./avenantsModalComponents/TransfertDeGaranties";
const { confirm } = Modal;
const { Option } = Select;

const DetailsContrat = () => {
  const [contrat, setContrat] = useState();
  const [activeTab, setActiveTab] = useState("home");
  const [tabId, setTabId] = useState("1");
  const [regle, setRegle] = useState(false);
  const [reglements, setReglements] = useState([]);
  const [reglement, setReglement] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAttOpen, setModalAttOpen] = useState(false);
  const [printingAttestation, setPrintingAttestation] = useState(false);
  const [modalModePaiement, setModalModePaiement] = useState(false);
  const [modePaiementForm] = Form.useForm();
  const [modePaiement, setModePaiement] = useState("");
  const [numeroPaiement, setNumeroPaiement] = useState("P");
  const [banques, setBanques] = useState([]);
  const [banque, setBanque] = useState("BANQUE");
  const [avenantMode, setAvenantMode] = useState(false);
  const [reglementCodeId, setReglementCodeId] = useState("");
  const [codeAvenant, setCodeAvenant] = useState("");
  const [montantAPayer, setMontantAPayer] = useState(0);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState("");
  const [numeroAttestation, setNumeroAttestation] = useState("");
  const [modalIncorporation, setModalIncorporation] = useState(false);
  const [modalAugmentation, setModalAugmentation] = useState(false);
  const [modalDiminution, setModalDiminution] = useState(false);
  const [modalProrogation, setModalProrogation] = useState(false);
  const [modalRemiseEnVigueur, setModalRemiseEnVigueur] = useState(false);
  const [modalReportEffet, setModalReportEffet] = useState(false);
  const [modalTransfertGarantie, setModalTransfertGarantie] = useState(false);
  const [modalChangementVehicule, setModalChangementVehicule] = useState(false);
  const [modalRenouvellement, setModalRenouvellement] = useState(false);
  const [havingRistourne, setHavingRistourne] = useState(false);
  const [useCompensationRistourne, setUseCompensationRistourne] = useState(false);
  const [attestationDamaged, setAttestationDamaged] = useState(false);

  const param = useParams();
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const onTabsChange = (key) => {
    // console.log(key);
    if (key === "3") {
      setModalAttOpen(true);
    }
    setTabId(key);
  };

  const loadPaymentModal = (rg) => {
    setReglement(rg);
    reglementWarningWithConfirmAndCancelMessage();
  };

  const updateIncorporationModal = (value) => {
    setModalIncorporation(value);
  };

  const updateAugmentationModal = (value) => {
    setModalAugmentation(value);
  };

  const updateDiminutionModal = (value) => {
    setModalDiminution(value);
  };

  const updateProrogationModal = (value) => {
    setModalProrogation(value);
  };

  const updateChangementVehiculeModal = (value) => {
    setModalChangementVehicule(value);
  };

  const updateReportEffetModal = (value) => {
    setModalReportEffet(value);
  };

  const updateTransfertGarantieModal = (value) => {
    setModalTransfertGarantie(value);
  };

  const updateRenouvellementModal = (value) => {
    setModalRenouvellement(value);
  };

  const printReference = useRef();
  const printAttestation = createRef();
  const printedAttestation = createRef();
  const printRecu = createRef();

  const handlePrint = useReactToPrint({
    content: () => printReference.current,
  });

  const handleRecu = useReactToPrint({
    content: () => printRecu.current,
  });

  const exportToPdf = () => {
    handlePrint();
    // axios
    //   .get(
    //     `${API_URL}/api/contrat/impression/codeId/${contrat.codeId}/${LOGGED_USER.codeId}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${TOKEN}`,
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //         "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
    //         "Access-Control-Allow-Headers":
    //           "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     // console.log(res.data);
    //     setContrat(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const exportRecuToPdf = () => {
    handleRecu();
  };

  const exportAttestationToPdf = useReactToPrint({
    content: () => printedAttestation.current,
    onBeforeGetContent: () => setPrintingAttestation(false),
  });

  const reglementWarningWithConfirmAndCancelMessage = () => {
    setModalModePaiement(true);
  };

  const alertReglement = () => {
    // console.log(numeroPaiement);
    confirm({
      title: "Etes-vous sûr de vouloir régler ce contrat?",
      icon: <ExclamationCircleFilled />,
      content: "Toutes vos actions sont historisées!",
      okText: "Oui régler",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        console.log("OK");
        reglementContrat();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const reglementContrat = () => {
    // console.log("MP: ", modePaiement);
    if (avenantMode) {
      console.log("Mode avenant");
      if (useCompensationRistourne) {
        if (modePaiement === "ESPECES") {
          axios
            .get(
              `${API_URL}/api/reglement/compensation/${param.contratId}/${reglementCodeId}`,
              {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
              }
            )
            .then((res) => {
              window.location.reload();
              toast("Règlement effectué avec succès");
              // console.log(res.data);
              res.data = null;
            })
            .catch((err) => {
              console.log(err);
              toast("Erreur lors du règlement");
            });
        } else {
          axios
            .get(
              `${API_URL}/api/reglement/compensation/${param.contratId}/${reglementCodeId}/${modePaiement}/${numeroPaiement}/${banque}`,
              {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
              }
            )
            .then((res) => {
              window.location.reload();
              toast("Règlement effectué avec succès");
              // console.log(res.data);
              res.data = null;
            })
            .catch((err) => {
              console.log(err);
              toast("Erreur lors du règlement");
            });
        }
      } else {
        if (modePaiement === "RISTOURNE") {
          axios
            .get(
              `${API_URL}/api/reglement/ristourne/${param.contratId}/${reglementCodeId}/`,
              {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
              }
            )
            .then((res) => {
              window.location.reload();
              toast("Ristourne enregistrée avec succès");
              // console.log(res.data);
              res.data = null;
            })
            .catch((err) => {
              console.log(err);
              toast("Erreur lors du règlement");
            });
        } else {
          axios
            .get(
              `${API_URL}/api/reglement/${param.contratId}/${reglementCodeId}/${LOGGED_USER.codeId}/${modePaiement}/${numeroPaiement}/${banque}/${montantAPayer}/${codeAvenant}`,
              {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
              }
            )
            .then((res) => {
              window.location.reload();
              toast("Règlement effectué avec succès");
              // console.log(res.data);
              res.data = null;
            })
            .catch((err) => {
              console.log(err);
              toast("Erreur lors du règlement");
            });
        }
      }

    } else {
      if (useCompensationRistourne) {
        if (modePaiement === "ESPECES") {
          axios
            .get(
              `${API_URL}/api/reglement/compensation/especes/contrat/${param.contratId}`,
              {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, PUT, POST, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
              }
            )
            .then((res) => {
              window.location.reload();
              toast("Règlement effectué avec succès!");
              // console.log(res.data);
              res.data = null;
            })
            .catch((err) => {
              console.log(err);
              toast("Erreur lors du règlement");
            });
        } else if (modePaiement === "BANQUE" || modePaiement === "MOBILE_MONEY") {
          axios
            .get(
              `${API_URL}/api/reglement/compensation/contrat/${param.contratId}/${modePaiement}/${numeroPaiement}/${banque}`,
              {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, PUT, POST, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
              }
            )
            .then((res) => {
              window.location.reload();
              toast("Règlement effectué avec succès!");
              // console.log(res.data);
              res.data = null;
            })
            .catch((err) => {
              console.log(err);
              toast("Erreur lors du règlement");
            });
        }
      } else if (modePaiement === "ESPECES") {
        // console.log("grumbling");
        axios
          .get(
            `${API_URL}/api/reglement/especes/contrat/codeId/${param.contratId}/${LOGGED_USER.codeId}`,
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            }
          )
          .then((res) => {
            window.location.reload();
            toast("Règlement effectué avec succès");
            // console.log(res.data);
            res.data = null;
          })
          .catch((err) => {
            console.log(err);
            toast("Erreur lors du règlement");
          });
      } else if (modePaiement === "BANQUE" || modePaiement === "MOBILE_MONEY") {
        axios
          .get(
            `${API_URL}/api/reglement/contrat/codeId/${param.contratId}/${LOGGED_USER.codeId}/${modePaiement}/${numeroPaiement}/${banque}`,
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            }
          )
          .then((res) => {
            window.location.reload();
            toast("Règlement effectué avec succès!");
            // console.log(res.data);
            res.data = null;
          })
          .catch((err) => {
            console.log(err);
            toast("Erreur lors du règlement");
          });
      }
    }
  };

  const loadModal = (rg) => {
    setReglement(rg);
    setTimeout(() => {
      setModalOpen(true);
    }, 100);
  };

  useEffect(() => {
    if (param.contratId) {
      axios
        .get(`${API_URL}/api/contrat/codeId/${param.contratId}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        })
        .then((res) => {
          console.log(res.data);
          setContrat(res.data);
          axios
            .get(`${API_URL}/api/reglement/police/${res.data.numeroPoliceP}`, {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            })
            .then((resp) => {
              // console.log("Reglll: ", resp.data);
              setReglements(resp.data);
            })
            .catch((err) => {
              console.log(err);
            });

          axios
            .get(`${API_URL}/api/reglement/check-ristourne/${res.data.souscripteur.numeroAssureP}`, {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            })
            .then((resp) => {
              console.log("Having ristourne: ", resp.data);
              setHavingRistourne(resp.data);
            })
            .catch((err) => {
              console.log(err);
            });
          res.data.engins.forEach((e) => {
            if (e.carteGrise) {
              // console.log(e.carteGrise);
              fetch(
                `${API_URL}/api/engin/carte-grise/${e.carteGrise.split("//")[2]
                }`,
                {
                  headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                      "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                  },
                }
              )
                .then(async (response) => {
                  console.log("The response", response);

                  // convert byte to file
                  const imageBytes = await response.arrayBuffer();
                  console.log("images byte: ", imageBytes);
                  var blob = new Blob([imageBytes], {
                    type: "application/pdf",
                  });
                  console.log("Blob: ", blob);
                  var imageUrl = URL.createObjectURL(blob);
                  console.log("Image URL: ", imageUrl);
                  setPdfPreviewUrl(imageUrl);
                  var file = new File([blob], e.carteGrise.split("//")[2], {
                    type: "application/pdf",
                  });
                  console.log("File: ", file);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
          // console.log("Contrat: ", contrat);
          res.data.reglements && res.data.reglements.length && res.data.reglements[0].modePaiement && setRegle(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    axios
      .get(`${API_URL}/api/parametre/type/BANQUE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setBanques(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [param.contratId]);

  const attestationPrinting = (engin) => {
    // console.log("update printing");
    axios
      .get(
        `${API_URL}/api/engin/attestation/${engin.codeId}/${numeroAttestation}/SIMPLE`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status === 226) {
          toast("Ce numéro d'attestation n'existe pas ou est déjà utilisé");
        } else {
          console.log("Export data");
          exportAttestationToPdf();
          onTabsChange("1");
          setModalAttOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const attestationDamagedPrinting = (engin) => {
    // console.log("update printing");
    setAttestationDamaged(true);

  };

  const printingDamagedAttestation = (engin) => {
    axios
      .get(
        `${API_URL}/api/engin/attestation/damaged/${engin.codeId}/${numeroAttestation}/SIMPLE`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status === 226) {
          toast("Ce numéro d'attestation n'existe pas ou est déjà utilisé");
        } else {
          console.log("Export data");
          exportAttestationToPdf();
          onTabsChange("1");
          setModalAttOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const attestationNonPrintedPrinting = () => {
    // console.log("update printing");
    console.log("Export data");
    exportAttestationToPdf();
    onTabsChange("1");
    setModalAttOpen(false);
  };

  const showContractValidationConfirm = () => {
    confirm({
      title: "Etes-vous sûr de vouloir valider ce contrat?",
      icon: <ExclamationCircleFilled />,
      content: "Toutes vos actions sont historisées!",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        console.log("OK");
        validateContrat();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const validateContrat = () => {
    // console.log("update validating");
    axios
      .get(`${API_URL}/api/contrat/valider/${contrat.codeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        toast("Contrat validé avec succès");
        navigate(`/details-contrat/${res.data.codeId}`, { replace: true });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors de la validation du contrat");
      });
  };

  const suspensionWarningWithConfirmAndCancelMessage = () => {
    if (contrat.suspendu) {
      confirm({
        title: "Information",
        icon: <ExclamationCircleFilled />,
        content: "Ce contrat est déjà suspendu",
        okText: "OK",
        okType: "success",
        cancelText: "Annuler",
        onOk() {
          console.log("OK");
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      confirm({
        title: "Êtes-vous sûr de vouloir suspendre ce contrat?",
        icon: <ExclamationCircleFilled />,
        content: "Toutes vos actions sont historisées",
        okText: "Oui, suspendre",
        okType: "danger",
        cancelText: "Annuler",
        onOk() {
          successSuspension();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  const resiliationWarningWithConfirmAndCancelMessage = () => {
    if (contrat.resilie) {
      confirm({
        title: "Information",
        icon: <ExclamationCircleFilled />,
        content: "Ce contrat est déjà résilié",
        okText: "OK",
        okType: "success",
        cancelText: "Annuler",
        onOk() {
          console.log("OK");
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      confirm({
        title: "Êtes-vous sûr de vouloir resilier ce contrat?",
        icon: <ExclamationCircleFilled />,
        content: "Toutes vos actions sont historisées",
        okText: "Oui!",
        okType: "danger",
        cancelText: "Annuler",
        onOk() {
          successResiliation();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  const remiseEnVigueurWarningWithConfirmAndCancelMessage = () => {
    if (contrat.suspendu) {
      confirm({
        title: "Êtes-vous sûr de vouloir remettre en vigueur ce contrat?",
        icon: <ExclamationCircleFilled />,
        content: "Toutes vos actions sont historisées",
        okText: "Oui, résilier",
        okType: "danger",
        cancelText: "Annuler",
        onOk() {
          successRemiseEnVigueur();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      confirm({
        title: "Information",
        icon: <ExclamationCircleFilled />,
        content: "Ce contrat n'est pas suspendu et ne peut donc faire objet de l'avenant de remise en vigueur",
        okText: "OK",
        okType: "success",
        cancelText: "Annuler",
        onOk() {
          console.log("OK");
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  const successSuspension = () => {
    // console.log(contrat);
    setContrat(contrat);
    axios
      .get(
        `${API_URL}/api/contrat/suspendu/codeId/${param.contratId}/${LOGGED_USER.codeId}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setContrat(res.data);
        message.success("Contrat suspendu!");
        navigate(`/details-contrat/${res.data.codeId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const successResiliation = () => {
    // console.log(contrat);
    axios
      .get(
        `${API_URL}/api/contrat/resiliation/codeId/${param.contratId}/${LOGGED_USER.codeId}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setContrat(res.data);
        message.success("Contrat résilié!");
        navigate(`/details-contrat/${res.data.codeId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const successRemiseEnVigueur = () => {
    // console.log(contrat);
    axios
      .get(
        `${API_URL}/api/contrat/remise-en-vigueur/codeId/${param.contratId}/${LOGGED_USER.codeId}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setContrat(res.data);
        message.success("Contrat remis en vigueur!");
        navigate(`/details-contrat/${res.data.codeId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"PRODUCTION"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              {/* <!-- Tabs --> */}
              <h5 class="py-4 my-6">Détails du contrat</h5>

              <div class="row">
                <div className="col-12">
                  {/* <h6 className="text-muted">Filled Pills</h6> */}
                  <div className="nav-align-top mb-6">
                    <ul className="nav nav-pills mb-4 nav-fill" role="tablist">
                      <li className="nav-item mb-1 mb-sm-0">
                        <button
                          type="button"
                          className={`nav-link ${activeTab === "home" ? "active" : ""
                            }`}
                          role="tab"
                          onClick={() => handleTabChange("home")}
                        >
                          <span className="d-none d-sm-block">
                            <i className="tf-icons bx bx-detail bx-sm me-1_5 align-text-bottom"></i>
                            Détails
                          </span>
                          <i className="bx bx-home bx-sm d-sm-none"></i>
                        </button>
                      </li>
                      <li className="nav-item mb-1 mb-sm-0">
                        <button
                          type="button"
                          className={`nav-link ${activeTab === "profile" ? "active" : ""
                            }`}
                          role="tab"
                          onClick={() =>
                            contrat.statutContrat !== "EN_PROPOSITION" &&
                            handleTabChange("profile")
                          }
                        >
                          <span className="d-none d-sm-block">
                            <i className="tf-icons bx bx-user bx-sm me-1_5 align-text-bottom"></i>
                            Avenants
                          </span>
                          <i className="bx bx-user bx-sm d-sm-none"></i>
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className={`tab-pane fade ${activeTab === "home" ? "show active" : ""
                          }`}
                        role="tabpanel"
                      >
                        <div>
                          <Tabs
                            onChange={onTabsChange}
                            type="card"
                            items={new Array(4).fill(null).map((_, i) => {
                              const id = String(i + 1);
                              const lb =
                                i === 0
                                  ? `Contrat`
                                  : i === 1
                                    ? `Mouvements`
                                    : i === 2
                                      ? `Attestation`
                                      : `Carte Grise`;
                              const cd =
                                i === 0 ? (
                                  <>
                                    <div className="card-body">
                                      <div
                                        className="container"
                                        justify="center"
                                      >
                                        <div className="grid-item">
                                          <div
                                            id="myContrat"
                                          >
                                            {contrat &&
                                              (contrat.statutContrat ===
                                                "EN_PROPOSITION" ? (
                                                <DTRFFormatProposition
                                                  contrat={contrat}
                                                  ref={printReference}
                                                />
                                              ) : (
                                                <DTRFFormat contrat={contrat} ref={printReference} />
                                              ))}
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                      <br />
                                      {contrat?.statutContrat ===
                                        "EN_PROPOSITION" ? (
                                        <div class="row">
                                          <div class="col-sm-6">
                                            <Button
                                              onClick={() => {
                                                showContractValidationConfirm();
                                              }}
                                              type="primary"
                                              style={{
                                                background: "green",
                                              }}
                                              icon={<CheckCircleOutlined />}
                                              disabled={!rightsService("HCO003")}
                                            >
                                              VALIDER
                                              <br />
                                              <br />
                                              <br />
                                            </Button><br /><br />
                                          </div>
                                          <div class="col-sm-6">
                                            <Button
                                              onClick={() => {
                                                exportToPdf();
                                              }}
                                              type="primary"
                                              style={{
                                                background: "blue",
                                              }}
                                              icon={<FilePdfOutlined />}
                                              disabled={!rightsService("HLA044")}
                                            >
                                              Imprimer la proposition
                                              <br />
                                              <br />
                                              <br />
                                            </Button>
                                          </div>

                                        </div>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            regle
                                              ? exportToPdf()
                                              : reglementWarningWithConfirmAndCancelMessage();
                                          }}
                                          type="primary"
                                          style={{
                                            background: "green",
                                          }}
                                          disabled={(regle && !rightsService("HCO006")) || (!regle && !rightsService("HLA037"))}
                                          icon={
                                            regle ? (
                                              <FilePdfOutlined />
                                            ) : (
                                              <MoneyCollectOutlined />
                                            )
                                          }
                                        >
                                          {regle ? "Générer PDF" : "Régler"}
                                          <br />
                                          <br />
                                          <br />
                                        </Button>
                                      )}
                                      <Modal
                                        title="Mode de paiement"
                                        centered
                                        open={modalModePaiement}
                                        onOk={() => {
                                          alertReglement();
                                        }}
                                        onCancel={() => {
                                          setModalModePaiement(false);
                                        }}
                                      >
                                        <>
                                          <div
                                            className="container"
                                            justify="center"
                                          >
                                            <div className="grid-item" lg={12}>
                                              <Form
                                                form={modePaiementForm}
                                                layout="vertical"
                                                name="userForm"
                                              >
                                                {havingRistourne ?
                                                  <Form.Item
                                                    name="useCompensation"
                                                    label="Utiliser la compensation?"
                                                    rules={[
                                                      {
                                                        required: true,
                                                      },
                                                    ]}
                                                  >
                                                    <Switch
                                                      checkedChildren="OUI"
                                                      unCheckedChildren="NON"
                                                      onChange={(checked) => setUseCompensationRistourne(checked)}
                                                      checked={useCompensationRistourne}
                                                    />
                                                  </Form.Item> : null}
                                                <Form.Item
                                                  name="modePaiement"
                                                  label="Mode de paiement"
                                                  rules={[
                                                    {
                                                      required: true,
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    onChange={(value) => {
                                                      console.log(value);
                                                      setModePaiement(value);
                                                    }}
                                                    placeholder="Selectionnez votre mode de paiement"
                                                  >
                                                    <Option value="ESPECES">
                                                      {" "}
                                                      ESPECES{" "}
                                                    </Option>
                                                    <Option value="BANQUE">
                                                      BANQUE (Chèque)
                                                    </Option>
                                                    <Option value="MOBILE_MONEY">
                                                      TMONEY / FLOOZ
                                                    </Option>
                                                    {montantAPayer < 0 &&
                                                      <Option value="RISTOURNE">
                                                        RISTOURNE
                                                      </Option>}
                                                    {/* {havingRistourne &&
                                                      <Option value="COMPENSATION">
                                                        COMPENSATION
                                                      </Option>
                                                    } */}
                                                  </Select>
                                                </Form.Item>
                                                {modePaiement.includes("BANQUE") ||
                                                  modePaiement.includes("MOBILE_MONEY")
                                                  ? (
                                                    <Form.Item
                                                      name="numeroPaiement"
                                                      label={
                                                        modePaiement.includes("BANQUE")
                                                          ? "Numero de chèque"
                                                          : modePaiement.includes("MOBILE_MONEY")
                                                            ? "Numero FLOOZ / TMONEY"
                                                            : ""
                                                      }
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "Veuillez renseigner le champ!",
                                                        },
                                                      ]}
                                                    >
                                                      <Input
                                                        placeholder={
                                                          modePaiement.includes("BANQUE")
                                                            ? "Le numero de chèque"
                                                            : modePaiement.includes("MOBILE_MONEY")
                                                              ? "Le numero de téléphone MOOV (FLOOZ) / TOGOCOM (TMONEY)"
                                                              : ""
                                                        }
                                                        onChange={(value) => {
                                                          console.log(
                                                            value.target.value
                                                          );
                                                          setNumeroPaiement(
                                                            value.target.value
                                                          );
                                                        }}
                                                      />
                                                    </Form.Item>
                                                  ) : null}
                                                {modePaiement.includes("BANQUE") ? (
                                                  <Form.Item
                                                    name="banque"
                                                    label="Banque"
                                                    rules={[
                                                      {
                                                        required: true,
                                                      },
                                                    ]}
                                                  >
                                                    <Select
                                                      onChange={(value) => {
                                                        console.log(value);
                                                        setBanque(value);
                                                      }}
                                                      placeholder="Selectionnez la banque"
                                                    >
                                                      {banques.map((b) => (
                                                        <Option
                                                          key={b.parametreId}
                                                          value={
                                                            b.libelleParametre
                                                          }
                                                        >
                                                          {b.libelleParametre}
                                                        </Option>
                                                      ))}
                                                    </Select>
                                                  </Form.Item>
                                                ) : null}
                                              </Form>
                                            </div>
                                          </div>
                                        </>
                                      </Modal>
                                    </div>
                                  </>
                                ) : i === 1 ? (
                                  <div className="card-body">
                                    <div className="card-body" justify="center">
                                      <div className="grid-item">
                                        {reglements?.length ? (
                                          <List
                                            itemLayout="horizontal"
                                            dataSource={reglements}
                                            renderItem={(item) => (
                                              <List.Item
                                                actions={[
                                                  item.montantPaye || item.modePaiement ||
                                                    item?.avenant?.code === "AN" ? (
                                                    <Button
                                                      type="primary"
                                                      icon={<ZoomInOutlined />}
                                                      onClick={() =>
                                                        loadModal(item)
                                                      }
                                                      key={item.codeId}
                                                    >
                                                      Prévisualiser
                                                      <br />
                                                      <br />
                                                      <br />
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      type="primary"
                                                      icon={
                                                        <CreditCardFilled />
                                                      }
                                                      style={{
                                                        backgroundColor: "red",
                                                      }}
                                                      disabled={!rightsService("HLA037")}
                                                      onClick={() => {
                                                        setAvenantMode(true);
                                                        setReglementCodeId(
                                                          item.codeId
                                                        );
                                                        setMontantAPayer(
                                                          item.montant
                                                        );
                                                        setCodeAvenant(
                                                          item?.avenant?.code
                                                        );
                                                        loadPaymentModal(item);
                                                      }}
                                                      key={item.reglementId}
                                                    ></Button>
                                                  ),
                                                ]}
                                              >
                                                <List.Item.Meta
                                                  avatar={
                                                    <Avatar
                                                      src={`https://ui-avatars.com/api/?name=${item?.avenant?.code
                                                        ? item?.avenant?.code
                                                        : contrat?.avenant
                                                          ?.code
                                                        }`}
                                                    />
                                                  }
                                                  title={
                                                    <a href="/#">
                                                      {item?.avenant?.nom
                                                        ? item?.avenant?.nom
                                                        : contrat?.avenant?.nom}
                                                    </a>
                                                  }
                                                  description={
                                                    <>
                                                      <p>
                                                        {item.montantPaye ||
                                                          item?.avenant?.code ===
                                                          "AN"
                                                          ? "Montant payé"
                                                          : "Montant à payer"}
                                                        :{" "}
                                                        {`${new Intl.NumberFormat(
                                                          "en-DE"
                                                        ).format(
                                                          Math.trunc(
                                                            item?.montant
                                                          )
                                                        )}`}{" "}
                                                        FCFA
                                                      </p>
                                                      <p>
                                                        {item.montantPaye ||
                                                          item?.avenant?.code ===
                                                          "AN"
                                                          ? ` Payé le:
                                                  ${frenchDate(
                                                            item?.dateReglement
                                                          )}`
                                                          : ""}
                                                      </p>
                                                    </>
                                                  }
                                                />
                                              </List.Item>
                                            )}
                                          />
                                        ) : (
                                          "Aucun mouvement effectué sur ce contrat..."
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : i === 2 ? (
                                  <div className="card-body">
                                    {modalAttOpen ? (
                                      <Modal
                                        title="Attestation"
                                        centered
                                        open={modalAttOpen}
                                        onOk={() => {
                                          // window.location.reload();
                                          setModalAttOpen(false);
                                          onTabsChange("1");
                                        }}
                                        onCancel={() => {
                                          // window.location.reload();
                                          onTabsChange("1");
                                          setModalAttOpen(false);
                                        }}
                                        width={1200}
                                        zIndex={2000}
                                      >
                                        {contrat.engins[0]?.categorie
                                          ?.codeCategorie === "M" ? (
                                          contrat.engins.map((eng, id) => (
                                            <div key={id}>
                                              <div className="container" justify="center" >
                                                <div
                                                  className="grid-item"
                                                  lg={12}
                                                >
                                                  <div>
                                                    <AttestationMoto
                                                      ref={printAttestation}
                                                      contrat={contrat}
                                                      engin={eng}
                                                      printing={
                                                        printingAttestation
                                                      }
                                                    />
                                                    <div
                                                      style={{ display: "none" }}
                                                    >
                                                      <AttestationMotoToPrint
                                                        ref={printedAttestation}
                                                        contrat={contrat}
                                                        engin={eng}
                                                      />
                                                    </div>

                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                  </div>
                                                </div>
                                              </div>
                                              {!eng.numeroAttestation || attestationDamaged ? <>
                                                <InputNumber
                                                  style={{
                                                    width: 200,
                                                  }}
                                                  placeholder="Numéro d'attestation"
                                                  value={numeroAttestation}
                                                  onChange={(v) => {
                                                    setNumeroAttestation(v);
                                                    console.log(v);
                                                  }}
                                                />
                                                <br />
                                                <br />
                                                <Button
                                                  onClick={() => {
                                                    console.log(
                                                      "nA: ",
                                                      numeroAttestation
                                                    );
                                                    if (
                                                      numeroAttestation.length < 4
                                                    ) {
                                                      toast.error(
                                                        "Veuillez renseigner un numéro d'attestation valide"
                                                      );
                                                    } else {
                                                      attestationDamaged ? printingDamagedAttestation(eng) : attestationPrinting(eng);
                                                    }
                                                  }}
                                                  type="primary"
                                                  style={{
                                                    background: "green",
                                                  }}
                                                  disabled={!regle || !rightsService("HCO007")}
                                                >
                                                  Imprimer
                                                </Button>
                                              </> :
                                                <>
                                                  <Button
                                                    onClick={() => {
                                                      attestationNonPrintedPrinting(eng);
                                                    }}
                                                    type="primary"
                                                    style={{
                                                      background: "gray",
                                                      color: "white"
                                                    }}
                                                    disabled={!regle || !rightsService("HCO007")}
                                                  >
                                                    Impression non effectuée
                                                  </Button>

                                                  <Button
                                                    onClick={() => {

                                                      attestationDamagedPrinting(eng);

                                                    }}
                                                    type="primary"
                                                    style={{
                                                      background: "orange",
                                                      color: "white"
                                                    }}
                                                    disabled={!regle || !rightsService("HCO007")}
                                                  >
                                                    Attestation bourrée / endommagée
                                                  </Button>
                                                </>}
                                            </div>))
                                        ) : (
                                          <div>
                                            Attestation non disponible pour l
                                            {"'"}
                                            instant
                                          </div>
                                        )}
                                      </Modal>
                                    ) : null}
                                  </div>
                                ) : (
                                  <>
                                    <div className="card-body">
                                      <div
                                        className="container"
                                        justify="center"
                                      >
                                        <div className="grid-item">
                                          {contrat?.engins[0]?.carteGrise ? (
                                            <div
                                              className="mt4"
                                              style={{ height: "750px" }}
                                            >
                                              <embed
                                                src={pdfPreviewUrl}
                                                type="application/pdf"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              // width={100}
                                              />
                                              <br />
                                              <br />
                                            </div>
                                          ) : (
                                            "Pas de carte grise jointe au contrat"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                    <br />
                                  </>
                                );
                              return {
                                label: lb,
                                key: id,
                                children: cd,
                              };
                            })}
                            activeKey={tabId}
                          />
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${activeTab === "profile" ? "show active" : ""
                          }`}
                        role="tabpanel"
                      >
                        <div>
                          <div class="card">
                            <div class="card-header">
                            </div>

                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">
                                        Affaire Nouvelle
                                      </h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Etablir un nouveau contrat pour le même
                                        assuré
                                      </p>
                                      <button class="btn btn-primary btn-initialize">
                                        Ajouter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {contrat?.engins[0].categorie?.codeCategorie !== "TPV" ? <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">Incorporation</h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Ajouter un ou de nouvel(nouveaux)
                                        engin(s) à la flotte
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => setModalIncorporation(true)}>
                                        Incorporer
                                      </button>
                                    </div>
                                  </div>
                                </div> : null}
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements" >
                                      <h5 class="mb-0 me-2">
                                        Augmentation de garantie
                                      </h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Souscrire à une garantie supplémentaire,
                                        non obligatoire sur le contrat en cours
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => setModalAugmentation(true)}>
                                        Augmenter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">
                                        Diminution de garantie
                                      </h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Enlever des garanties supplémentaires
                                        sur le contrat en cours
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => setModalDiminution(true)}>
                                        Diminuer
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">Prorogation</h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Proroger le contrat suspendu sur la
                                        période restante
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => setModalProrogation(true)}>
                                        Proroger
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">Renouvellement</h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Renouveller le contrat à échéance
                                      </p>
                                      <span class="badge bg-primary">
                                        Doit renouveler avant le{" "}
                                        {contrat?.dateEcheance.split("T")[0]}
                                      </span>
                                      <button class="btn btn-primary btn-initialize" onClick={() => setModalRenouvellement(true)}>
                                        Renouveller
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4" >
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">Suspension</h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Suspendre l'effet du contrat en cours
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => suspensionWarningWithConfirmAndCancelMessage()}>
                                        Suspendre
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">
                                        Remise en vigueur
                                      </h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Remettre en vigueur l'effet du contrat
                                        en cours
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => remiseEnVigueurWarningWithConfirmAndCancelMessage()}>
                                        Remettre en vigueur
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">Report Effet</h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Reporter l'effet du contrat sur une date
                                        ultérieure
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => setModalReportEffet(true)}>
                                        Reporter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">Résiliation</h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Résilier du contrat en cours
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => resiliationWarningWithConfirmAndCancelMessage()}>
                                        Résilier
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">
                                        Transfert de garantie
                                      </h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Transférer des garanties supplémentaires
                                        d'un engin à un autre du même assuré
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => setModalTransfertGarantie(true)}>
                                        Transférer
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">
                                        Changement de véhicule
                                      </h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Changer le véhicule assuré par un autre
                                        nouveau véhicule
                                      </p>
                                      <button class="btn btn-primary btn-initialize" onClick={() => setModalChangementVehicule(true)}>
                                        Changer
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="card mb-6">
                                    <div class="card-header header-elements">
                                      <h5 class="mb-0 me-2">
                                        Annulation de contrat
                                      </h5>
                                    </div>
                                    <div class="card-body">
                                      <p class="card-text">
                                        Annuler le contrat en cours d'effet
                                      </p>
                                      <button class="btn btn-danger btn-initialize">
                                        Annuler
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Incorporation
                                open={modalIncorporation}
                                update={updateIncorporationModal}
                                contrat={contrat}
                              />
                              <AugmentationDeGarantie
                                open={modalAugmentation}
                                update={updateAugmentationModal}
                                contrat={contrat}
                              />
                              <DiminutionDeGarantie
                                open={modalDiminution}
                                update={updateDiminutionModal}
                                contrat={contrat}
                              />
                              <Prorogation
                                open={modalProrogation}
                                update={updateProrogationModal}
                                contrat={contrat}
                              />
                              <Modal
                                title="REMISE EN VIGUEUR"
                                centered
                                open={modalRemiseEnVigueur}
                                onOk={() => setModalRemiseEnVigueur(false)}
                                onCancel={() => setModalRemiseEnVigueur(false)}
                              ></Modal>
                              <ReportEffet
                                open={modalReportEffet}
                                update={updateReportEffetModal}
                                contrat={contrat}
                              />
                              <TransfertGarantie
                                open={modalTransfertGarantie}
                                update={updateTransfertGarantieModal}
                                contrat={contrat}
                              />
                              <ChangementDeVehicule
                                open={modalChangementVehicule}
                                update={updateChangementVehiculeModal}
                                contrat={contrat}
                              />
                              <Renouvellement
                                open={modalRenouvellement}
                                update={updateRenouvellementModal}
                                contrat={contrat}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${activeTab === "messages" ? "show active" : ""
                          }`}
                        role="tabpanel"
                      >
                        <p>
                          -
                        </p>
                        <p className="mb-0">
                          -
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {reglement ? (
                <div className="container">
                  <Modal
                    title={
                      reglement?.avenant?.nom
                        ? reglement?.avenant?.nom
                        : contrat?.avenant?.nom
                    }
                    centered
                    open={modalOpen}
                    onOk={() => {
                      setModalOpen(false);
                    }}
                    onCancel={() => {
                      setModalOpen(false);
                    }}
                    width={1000}
                    zIndex={2000}
                  >
                    <div className="excel-table-wrapper">
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "135vh", margin: 0, padding: 0 }}>
                        <RecuCurrentFormat
                          ref={printRecu}
                          contrat={contrat}
                          reglement={reglement}
                        />
                      </div>
                    </div>
                    <Button
                      onClick={() => {
                        exportRecuToPdf();
                      }}
                      type="primary"
                      style={{
                        background: "green",
                      }}
                      disabled={!rightsService("HCO008")}
                      icon={<FilePdfOutlined />}
                    >
                      {" "}
                      Générer PDF
                      <br />
                      <br />
                      <br />
                    </Button>
                  </Modal>
                </div>
              ) : null}
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default DetailsContrat;