import { CreditCardFilled, ExclamationCircleFilled, FilePdfFilled, SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Form, Input, Modal, Select, Space, Spin, Table, Tag } from 'antd';
import frFR from "antd/locale/fr_FR";
import axios from 'axios';
import React, { createRef, useEffect, useRef, useState } from 'react';
import Highlighter from "react-highlight-words";
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import { API_URL, TOKEN } from '../../variables/constants';
import { frenchDate } from '../../variables/functions';
import RecuDeVersementDePrimeEnCompagnie from '../production/components/RecuDeVersementDePrimeEnCompagnie';
const { confirm } = Modal;
const { Option } = Select;
const { Search } = Input;

const VersementsEnCompagnie = () => {
    const [datas, setDatas] = useState([]);
    const [allDatas, setAllDatas] = useState([]);
    const [dataSum, setDataSum] = useState(0);
    const [todayDatas, setTodayDatas] = useState([]);
    const [selecting, setSelecting] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [todayDataSum, setTodayDataSum] = useState(0);
    const [monthDatas, setMonthDatas] = useState([]);
    const [monthDataSum, setMonthDataSum] = useState(0);
    const [nonCashedDatas, setNonCashedDatas] = useState([]);
    const [nonCashedDataSum, setNonCashedDataSum] = useState(0);
    const [loadingData, setLoadingData] = useState(true);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [modalModePaiement, setModalModePaiement] = useState(false);
    const [contractToPay, setContractToPay] = useState([]);
    const [numeroPaiement, setNumeroPaiement] = useState("P");
    const [modePaiement, setModePaiement] = useState("");
    const [modePaiementForm] = Form.useForm();
    const [banques, setBanques] = useState([]);
    const [banque, setBanque] = useState("BANQUE");
    const [reglement, setReglement] = useState("");
    const [hidden, setHidden] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [havingRistourne, setHavingRistourne] = useState(false);

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    const navigate = useNavigate();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const onSelectChange = (newSelectedRowKeys, rows) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        console.log("SelectedRows", rows);
        setSelecting(true);
        setSelectedRows(rows);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Rechercher ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Rechercher...
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filtrer
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    useEffect(() => {
        axios
            .get(`${API_URL}/api/contrat`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("Res: ", res.data);
                let regs = [];
                let todayRegs = [];
                let monthRegs = [];
                let nonRegs = [];
                res.data.forEach((contrat, id) => {
                    if (contrat.reglements) {
                        contrat.reglements.forEach((reg, jd) => {
                            reg.modePaiement && regs.push({
                                key: `${reg.codeId}${jd}${id}`,
                                nom: `${contrat?.souscripteur?.nom} ${contrat?.souscripteur?.prenom ? contrat?.souscripteur?.prenom : ""}`,
                                modeReglement: reg.modePaiement ? reg.modePaiement : "-",
                                dateReglement: reg.modePaiement ? reg.dateReglement : "-",
                                montant: reg.montant,
                                numeroPaiement: reg.numeroPaiement,
                                reference: reg.reference,
                                createdAt: reg.createdAt,
                                reglement: reg,
                                avenant: reg?.avenant?.code ? reg?.avenant?.code : "AN",
                                contrat: contrat,
                                numeroPolice: contrat.numeroPoliceP
                            });

                            !reg.modePaiement && nonRegs.push({
                                key: `${reg.codeId}${jd}${id}`,
                                nom: `${contrat?.souscripteur?.nom} ${contrat?.souscripteur?.prenom ? contrat?.souscripteur?.prenom : ""}`,
                                modeReglement: reg.modePaiement ? reg.modePaiement : "-",
                                dateReglement: reg.modePaiement ? reg.dateReglement : "-",
                                montant: reg.montant,
                                numeroPaiement: reg.numeroPaiement,
                                reference: reg.reference,
                                createdAt: reg.createdAt,
                                reglement: reg,
                                avenant: reg?.avenant?.code ? reg?.avenant?.code : "AN",
                                contrat: contrat,
                                numeroPolice: contrat.numeroPoliceP
                            });
                        });
                    }
                });
                // console.log("Regsss: ", regs);
                setDataSum(regs.reduce((pn, encaissement) => pn + encaissement.montant, 0));
                setDatas(regs.reverse());
                setAllDatas(regs);

                setTodayDataSum(todayRegs.reduce((pn, encaissement) => pn + encaissement.montant, 0));
                setTodayDatas(todayRegs);

                setMonthDataSum(monthRegs.reduce((pn, encaissement) => pn + encaissement.montant, 0));
                setMonthDatas(monthRegs);

                setNonCashedDataSum(nonRegs.reduce((pn, encaissement) => pn + encaissement.montant, 0));
                setNonCashedDatas(nonRegs);

                setLoadingData(false);
            })
            .catch((err) => {
                console.log(err);
                setLoadingData(false);
            });

        axios
            .get(`${API_URL}/api/parametre/type/BANQUE`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setBanques(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [TOKEN]);

    const encaisserContrat = (id) => {
        axios
            .get(`${API_URL}/api/reglement/check-ristourne/${id.contrat.souscripteur.numeroAssureP}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((resp) => {
                console.log("Having ristourne: ", resp.data);
                setHavingRistourne(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setModalModePaiement(true);
        setContractToPay(id);
    };

    const columns = [
        {
            title: "Assuré",
            dataIndex: "nom",
            key: "nom",
            ...getColumnSearchProps("nom")
        },
        {
            title: "Numéro Police",
            dataIndex: "numeroPolice",
            key: "numeroPolice",
            ...getColumnSearchProps("numeroPolice"),
        },
        {
            title: "Avenant",
            dataIndex: "avenant",
            key: "avenant",
            ...getColumnSearchProps("avenant"),
        },
        {
            title: "Montant",
            dataIndex: "montant",
            key: "montant",
            render: (_, record) => (
                <>{new Intl.NumberFormat("en-DE").format(
                    Math.trunc(record.montant)
                )} </>
            ),
        },
        {
            title: "Mode de paiement",
            dataIndex: "modeReglement",
            key: "modeReglement",
            render: (_, record) => (
                <>{record.modeReglement === "ESPECE" ? <Tag color='blue'>ESPECES</Tag> : record.modeReglement === "RISTOURNE" ? <Tag color='green'>RISTOURNE</Tag> : record.modeReglement && record.modeReglement !== "-" ? <Tag color='yellow'>{record.modeReglement}</Tag> : null} </>
            ),
            filters: [
                {
                    text: "ESPECES",
                    value: "ESPECE",
                },
                {
                    text: "RISTOURNE",
                    value: "RISTOURNE",
                },
                {
                    text: "BANQUE",
                    value: "BANQUE",
                },
                {
                    text: "MOBILE MONEY",
                    value: "MOBILE_MONEY",
                },
                {
                    text: "COMPENSATION",
                    value: "COMPENSATION",
                },
                {
                    text: "NON PAYE",
                    value: "-",
                },
            ],
            filteredValue: filteredInfo.modeReglement || null,
            onFilter: (value, record) => record.modeReglement === value,
            sorter: (a, b) => a.modeReglement.length - b.modeReglement.length,
            sortOrder: sortedInfo.columnKey === "modeReglement" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: "Date règlement",
            dataIndex: "dateReglement",
            key: "dateReglement",
            render: (_, record) => (
                <>{record.reglement.modePaiement ? frenchDate(record.dateReglement) : "-"} </>
            ),
        },
        {
            title: "Date versement",
            dataIndex: "dateVersementCompagnie",
            key: "dateVersementCompagnie",
            render: (_, record) => (
                <>{frenchDate(record.reglement.dateVersementCompagnie)} </>
            ),
        },
        // {
        //     title: "Actions",
        //     dataIndex: "actions",
        //     key: "7",
        //     render: (_, record) => (
        //         <>
        //             {rightsService("HLA066") && record.numeroPolice && !record.reglement.versementCompagnie && (
        //                 <button
        //                     type="button"
        //                     class="btn btn-success btn-icon rounded-pill"
        //                     onClick={() => encaisserContrat(record)}
        //                 >
        //                     <i class="bx bxs-credit-card"></i>
        //                 </button>
        //             )}
        //             {rightsService("HLA067") && record.numeroPolice && record.reglement.versementCompagnie &&
        //                 <button type="button" class="btn btn-success btn-icon rounded-pill"
        //                     onClick={() => {
        //                         setReglement(record);
        //                         setModalOpen(true);
        //                     }}
        //                 >
        //                     <i class="bx bxs-file-pdf"></i>
        //                 </button>}
        //         </>
        //     ),
        // },
    ];

    const alertReglement = (contractToPay) => {
        // console.log(numeroPaiement);
        if (contractToPay.length > 1) {
            reglementContrat(contractToPay);
        } else {
            confirm({
                title: "Etes-vous sûr de vouloir régler ce contrat?",
                icon: <ExclamationCircleFilled />,
                content: "Toutes vos actions sont historisées!",
                okText: "Oui régler",
                okType: "danger",
                cancelText: "Annuler",
                onOk() {
                    console.log("OK");
                    reglementContrat(contractToPay);
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        }

    };

    const reglementContrat = (data) => {
        let regs = [];
        data.forEach(element => {
            regs.push(element.reglement);
        });
        axios
            .post(
                `${API_URL}/api/reglement/versements-en-compagnie/${modePaiement}/${numeroPaiement}/${banque}/`, JSON.stringify(regs),
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            )
            .then((res) => {
                window.location.reload();
                toast("Versements effectués avec succès");
                // console.log(res.data);
                res.data = null;
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors des versements");
            });

    };

    const apporteurFiltering = (e) => {
        console.log("Apporteur: ", e.target.value);
        const value = e.target.value;
        if (value?.trim()) {
            let filRegs = [];
            filRegs = allDatas.filter((dt) => (dt.contrat.intermediaire !== null && dt.contrat.intermediaire.personne.nom.trim().toLowerCase().startsWith(value.trim().toLowerCase())) ||
                (dt.contrat.intermediaire !== null && dt.contrat.intermediaire?.personne?.prenom != null && dt.contrat.intermediaire?.personne?.prenom.trim().toLowerCase().startsWith(value.trim().toLowerCase())) ||
                (dt.contrat.intermediaire !== null && dt.contrat.intermediaire.codeIntermediaire.trim().toLowerCase().startsWith(value.trim().toLowerCase())) ||
                (dt.contrat.courtier !== null && dt.contrat.courtier.nom.trim().toLowerCase().startsWith(value.trim().toLowerCase())) ||
                (dt.contrat.courtier !== null && dt.contrat.courtier.codeAgence.trim().toLowerCase().startsWith(value.trim().toLowerCase())));
            // allDatas.forEach(dt => {
            //     dt.contrat.intermediaire !== null && dt.contrat.intermediaire.personne.nom.trim().toLowerCase().startsWith(value.trim().toLowerCase()) && filRegs.push(dt);
            // });
            setDatas(filRegs);
        } else {
            setDatas(allDatas);
        }

    }

    const encaisserVersements = (cts) => {
        console.log(cts);
        if (cts.length) {
            setModalModePaiement(true);
            setContractToPay(cts);
        } else {
            toast("Aucun règlement sélectionné!");
        }
    };

    const exportToPdfs = (contrats) => {
        console.log(contrats);
    };

    const MyFooter = () => {
        let ctrt = selectedRows.filter((ctr) => ctr.reglement.versementCompagnie);
        let nvCtrt = selectedRows.filter((ctr) => !ctr.reglement.versementCompagnie);
        // console.log("R: ", ctrt);
        // console.log("NR: ", nrCtrts);
        return (
            <div class="row">
                {!ctrt.length ? (
                    <div class="col-sm-4">
                        <Button
                            onClick={() => {
                                encaisserVersements(nvCtrt);
                            }}
                            color="success"
                            icon={<CreditCardFilled />}
                            style={{
                                color: "red",
                            }}
                            title="Encaissement"
                        >
                            Encaisser
                        </Button>
                    </div>
                ) : (
                    <>
                        <div class="col-sm-4">
                            <Button
                                onClick={() => {
                                    // exportToPdfs(ctrt);
                                    setHidden(true);
                                    exportRecuToPdf();
                                }}
                                color="primary"
                                style={{ color: "green" }}
                                icon={<FilePdfFilled />}
                            >
                                Imprimer le reçu
                            </Button>
                        </div>
                    </>
                )}
            </div>
        );
    };


    const printRecu = createRef();

    const exportRecuToPdf = () => {
        handleRecu();
    };

    const handleRecu = useReactToPrint({
        content: () => printRecu.current,
    });

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"CAISSE"} />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row g-6 mb-6">
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Versements à effectuer en compagnie</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h5 class="mb-0 me-2">{todayDatas?.length} ({new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(todayDataSum)
                                                        )} FCFA)</h5>
                                                        {/* <p class="text-success mb-0">(+29%)</p> */}
                                                    </div>
                                                    <small class="mb-0">du jour</small>
                                                </div>
                                                <div class="avatar">
                                                    <span class="avatar-initial rounded bg-label-primary">
                                                        <i class="bx bx-group bx-lg"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Versements à effectuer en compagnie</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h5 class="mb-0 me-2">{monthDatas?.length} ({new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(monthDataSum)
                                                        )} FCFA)</h5>
                                                        {/* <p class="text-success mb-0">(+18%)</p> */}
                                                    </div>
                                                    <small class="mb-0">
                                                        du mois
                                                    </small>
                                                </div>
                                                <div class="avatar">
                                                    <span class="avatar-initial rounded bg-label-danger">
                                                        <i class="bx bx-user-plus bx-lg"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Versements à effectuer en compagnie</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h5 class="mb-0 me-2">{datas?.length} ({new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(dataSum)
                                                        )} FCFA)</h5>
                                                        {/* <p class="text-danger mb-0">(-14%)</p> */}
                                                    </div>
                                                    <small class="mb-0">
                                                        total
                                                    </small>
                                                </div>
                                                <div class="avatar">
                                                    <span class="avatar-initial rounded bg-label-success">
                                                        <i class="bx bx-user-check bx-lg"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Non encaissés</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h5 class="mb-0 me-2">{nonCashedDatas?.length} ({new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(nonCashedDataSum)
                                                        )} FCFA)</h5>
                                                        {/* <p class="text-success mb-0">(+42%)</p> */}
                                                    </div>
                                                    <small class="mb-0">
                                                        Total
                                                    </small>
                                                </div>
                                                <div class="avatar">
                                                    <span class="avatar-initial rounded bg-label-warning">
                                                        <i class="bx bx-user-voice bx-lg"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="container">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6">
                                                    <Search
                                                        placeholder="Rechercher par rapport à l'apporteur..."
                                                        onSearch={(value) => {
                                                            apporteurFiltering(value);
                                                        }}
                                                        onChange={(value) => apporteurFiltering(value)}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ConfigProvider locale={frFR}>
                                    <Spin spinning={loadingData}>
                                        <Table
                                            rowSelection={rowSelection}
                                            columns={columns}
                                            dataSource={datas}
                                            onChange={handleChange}
                                            footer={() => <MyFooter />}
                                        />
                                    </Spin>
                                </ConfigProvider>
                            </div>
                            {hidden ? (
                                <div className="container">
                                    <div className="excel-table-wrapper">
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "135vh", margin: 0, padding: 0 }}>
                                            <RecuDeVersementDePrimeEnCompagnie
                                                ref={printRecu}
                                                contrats={selectedRows}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <Modal
                title="Mode de paiement"
                centered
                open={modalModePaiement}
                onOk={() => {
                    alertReglement(contractToPay);
                }}
                onCancel={() => {
                    setModalModePaiement(false);
                }}
            >
                <>
                    <div className="container" justify="center">
                        <div className="grid-item" lg={12}>
                            <Form
                                form={modePaiementForm}
                                layout="vertical"
                                name="userForm"
                            >
                                <Form.Item
                                    name="modePaiement"
                                    label="Mode de paiement"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={(value) => {
                                            console.log(value);
                                            setModePaiement(value);
                                        }}
                                        placeholder="Selectionnez votre mode de paiement"
                                    >
                                        <Option value="ESPECES"> ESPECES </Option>
                                        <Option value="BANQUE">BANQUE (Chèque)</Option>
                                        <Option value="MOBILE_MONEY">TMONEY / FLOOZ</Option>
                                    </Select>
                                </Form.Item>
                                {modePaiement === "BANQUE" ||
                                    modePaiement === "MOBILE_MONEY" ? (
                                    <Form.Item
                                        name="numeroPaiement"
                                        label={
                                            modePaiement === "BANQUE"
                                                ? "Numero de chèque"
                                                : modePaiement === "MOBILE_MONEY"
                                                    ? "Numero FLOOZ / TMONEY"
                                                    : ""
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Veuillez renseigner le champ!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={
                                                modePaiement === "BANQUE"
                                                    ? "Le numero de chèque"
                                                    : modePaiement === "MOBILE_MONEY"
                                                        ? "Le numero de téléphone MOOV (FLOOZ) / TOGOCOM (TMONEY)"
                                                        : ""
                                            }
                                            onChange={(value) => {
                                                console.log(value.target.value);
                                                setNumeroPaiement(value.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                ) : null}
                                {modePaiement === "BANQUE" ? (
                                    <Form.Item
                                        name="banque"
                                        label="Banque"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange={(value) => {
                                                console.log(value);
                                                setBanque(value);
                                            }}
                                            placeholder="Selectionnez la banque"
                                        >
                                            {banques.map((b) => (
                                                <Option
                                                    key={b.parametreId}
                                                    value={b.libelleParametre}
                                                >
                                                    {b.libelleParametre}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : null}
                            </Form>
                        </div>
                    </div>
                </>
            </Modal>
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    );
}

export default VersementsEnCompagnie