import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import {
  ExclamationCircleFilled,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import AddSousGarantie from "./AddSousGarantie";
import { API_URL, TOKEN } from "../../variables/constants";

const { confirm } = Modal;

const SousGaranties = () => {
  const [sousGaranties, setSousGaranties] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedSousGarantie, setSelectedSousGarantie] = useState(null);
  const [garantie, setGarantie] = useState("");
  const param = useParams();
  const navigate = useNavigate();

  // Affiche le modal pour ajouter ou éditer une sous-garantie
  const handleShow = (edit = false, sousGarantie = null) => {
    setEditMode(edit);
    setSelectedSousGarantie(sousGarantie);
    setShowModal(true);
  };

  // Ferme le modal et réinitialise les états
  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedSousGarantie(null);
  };

  useEffect(() => {
    fetchSousGaranties();
    fetchGarantie();
  }, [param.garantieId]);

  // Récupère les sous-garanties depuis l'API
  const fetchSousGaranties = () => {
    axios
      .get(`${API_URL}/api/sous-garantie/garantie/all/${param.garantieId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((response) => {
        console.log("Sous-garanties récupérées :", response.data);
        setSousGaranties(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des sous-garanties", error);
        toast.error("Erreur lors de la récupération des sous-garanties.");
      });
  };

  const fetchGarantie = () => {
    axios
      .get(`${API_URL}/api/garantie/codeId/${param.garantieId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((response) => {
        console.log("Garantie récupérée :", response.data);
        setGarantie(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération de la garantie", error);
        toast.error("Erreur lors de la récupération de la garantie.");
      });
  };

  const showDeleteConfirm = (codeId) => {
    console.log("Affichage de la confirmation de suppression pour l'ID :", codeId);
    if (!codeId) {
      toast.error("ID de la sous-garantie manquant !");
      return;
    }
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette sous-garantie ?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteSousGarantie(codeId);
      },
    });
  };

  const deleteSousGarantie = (codeId) => {
    console.log("Tentative de suppression de la sous-garantie avec l'ID :", codeId);
    axios
      .delete(`${API_URL}/api/sous-garantie/code/${codeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("Réponse de la suppression :", res.data);
        toast.success("Sous-garantie supprimée avec succès !");
        fetchSousGaranties();
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression de la sous-garantie", error);
        toast.error("Erreur lors de la suppression de la sous-garantie.");
      });
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
      render: (text, record) =>
        record.tranche[1]
          ? `${record.nom} (${record.tranche[0]} - ${record.tranche[1]} CV)`
          : record.nom,
    },
    {
      title: "Description",
      dataIndex: "commentaire",
      key: "commentaire",
    },
    {
      title: "Obligatoire",
      dataIndex: "obligatoire",
      key: "obligatoire",
      render: (obligatoire) => (
        <Button type={obligatoire ? "success" : "default"}>
          {obligatoire ? "Oui" : "Non"}
        </Button>
      ),
    },
    {
      title: "Taux",
      key: "taux",
      render: (_, record) => (
        <>
          {record.tauxPCent ? `${record.tauxPCent} %` : record.tauxPMille ? `${record.tauxPMille} ‰` : "-"}
        </>
      ),
    },
    {
      title: "Prime nette",
      dataIndex: "primeNette",
      key: "primeNette",
      render: (value) => new Intl.NumberFormat("en-DE").format(value),
    },
    {
      title: "Garantie",
      dataIndex: "garantie",
      key: "garantie",
      render: (garantie) => garantie?.nom || "-",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleShow(true, record)}
            style={{ marginRight: 8 }}
          >
            Modifier
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => showDeleteConfirm(record.codeId)}
          >
            Supprimer
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false)}
              >
                Ajouter une sous-garantie
              </Button>
              <div className="card">
                <Table
                  columns={columns}
                  dataSource={sousGaranties}
                  rowKey="codeId"
                  pagination={{ pageSize: 10 }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.commentaire}</p>
                    ),
                  }}
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {showModal && (
          <AddSousGarantie
            show={showModal}
            handleClose={handleClose}
            editMode={editMode}
            garantie={garantie}
            selectedSousGarantie={selectedSousGarantie}
          />
        )}
      </div>
    </div>
  );
};

export default SousGaranties;
