import React, {useEffect, useRef, useState} from "react";
import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import {CardBody, CardHeader, CardText} from "react-bootstrap";
import {Button, Card, Divider, Input, Modal, Select, Space} from "antd";
import {
    CheckCircleTwoTone,
    CloseOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    PlusOutlined
} from "@ant-design/icons";
import CustomInput from "./components/CustomInput";
import {useNavigate, useParams} from "react-router-dom";
import {API_URL, LOGGED_USER, TOKEN} from "../../variables/constants";
import axios from "axios";
import {verifyLength} from "../../variables/functions";
import {toast} from "react-toastify";

const {confirm} = Modal;


const styles = {
    cardIconTitle: {
        marginTop: "15px",
        marginBottom: "0px",
    },
};


const initEvaluation = {
    libelle: "",
    oldProvision: 0,
    newProvision: 0,
    motif: "",
};

let index = 0;


const EvaluationSinistres = () => {


    // const [categories, setCategories] = useState([]);
    const [evaluation, setEvaluation] = useState(initEvaluation);
    // const [fractionnement, setFractionnement] = useState(initFractionnement);
    const [evaluations, setEvaluations] = useState([]);
    const [data, setData] = useState([]);
    const [oldProvision, setOldProvision] = useState(0);
    const [newProvision, setNewProvision] = useState(0);
    // const [nombre, setNombre] = useState(0);

    // const [categorieSelect, setcategorieSelect] = useState([]);
    // const [categorieSelectState, setcategorieSelectState] = useState("");
    // const [categorieListSelect, setcategorieListSelect] = useState("");
    const [evaLibelle, setevaLibelle] = useState("");
    const [evaLibelleState, setevaLibelleState] = useState("");
    const [motifProvision, setmotifProvision] = useState("");
    const [motifProvisionState, setmotifProvisionState] = useState("");

    //alert
    const [alert, setAlert] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const navigate = useNavigate()
    const param = useParams();
    /** Debut get details sinistre */
    useEffect(() => {
        if (param.id) {
            axios
                .get(`${API_URL}/api/sinistre/${param.id}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    // setContrat(res.data);
                    // setReglements(res.data.reglements);
                    console.log("Contratssssssssssss: ", res.data);
                    // res.data.reglements && res.data.reglements.length && setRegle(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [TOKEN]);
    /** Fin get details sinistre */

    const [items, setItems] = useState(["jack", "lucy"]);
    const [name, setName] = useState("");
    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, name || `New item ${index++}`]);
        setName("");
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const isValidated = () => {
        if (
            evaLibelleState === "success" &&
            motifProvisionState === "success"
            // categorieSelectState === "success"
        ) {
            return true;
        } else {
            if (evaLibelleState !== "success") {
                return false;
            }
            if (motifProvisionState !== "success") {
                return false;
            }
            // if (categorieSelectState !== "success") {
            //   return false;
            // }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validate = isValidated();
        if (validate) {
            if (editMode) {
                evaluation.libelle = evaLibelle;
                evaluation.motif = motifProvision;
                // fractionnement.categorie = JSON.parse(categorieSelect);
                evaluation.oldProvision = oldProvision;
                evaluation.newProvision = newProvision;
                setEvaluation(evaluation);
                axios
                    .put(`${API_URL}/api/evaluation`, JSON.stringify(evaluation), {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    .then((res) => {
                        console.log(res.data);
                        setEditMode(true);
                        successAlert("mis a jour");
                    })
                    .catch((err) => {
                        console.log(err);
                        autoCloseAlert();
                    });
            } else {
                var eva = evaluation;
                eva.libelle = evaLibelle;
                eva.motif = motifProvision;
                eva.oldProvision = oldProvision;
                eva.newProvision = newProvision;
                // frac.nombre = nombre;
                // frac.categorie = JSON.parse(categorieSelect);
                setEvaluation(eva);
                axios
                    .post(`${API_URL}/api/evaluation`, JSON.stringify(evaluation), {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    .then((res) => {
                        console.log(res.data);
                        successAlert("créée");
                    })
                    .catch((err) => {
                        console.log(err);
                        autoCloseAlert();
                    });
            }
        } else {
            toast("Verifier vos entrees");
        }
    };

    // useEffect(() => {
    //   axios
    //     .get(`${API_URL}/api/categorie`, {
    //       headers: {
    //         Authorization: `Bearer ${TOKEN}`,
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //         "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
    //         "Access-Control-Allow-Headers":
    //           "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res.data);
    //       setCategories(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }, [TOKEN]);

    const hideAlert = () => {
        setAlert(null);
        window.location.reload();
    };

    const successAlert = (text) => {
        confirm({
            title: "Succès!",
            icon: <CheckCircleTwoTone/>,
            content: ` Garantie ${text} avec succès!`,
            cancelText: 'Fermer',
            onCancel() {
                console.log('hey')
            }

        })
        /* setAlert(
             <SweetAlert
                 success
                 style={{display: "block", marginTop: "-100px"}}
                 title="Succès!"
                 onConfirm={() => hideAlert()}
                 onCancel={() => hideAlert()}
                 confirmBtnCssClass={sAClasses.button + " " + sAClasses.success}
             >
                 Garantie {text} avec succès!
             </SweetAlert>
         );*/
    };

    const autoCloseAlert = () => {
        toast(" Echec lors de la creation du fractionnement")
    };

    const editEvaluation = (id) => {
        setEditMode(true);
        axios
            .get(`${API_URL}/api/evaluation/${id}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                const eva = evaluation;

                eva.libelle = res.data.libelle;
                setevaLibelle(res.data.libelle);
                setevaLibelleState("success");

                eva.motif = res.data.motif;
                setmotifProvision(res.data.motif);
                setmotifProvisionState("success");

                // eva.nombre = res.data.obligatoire;
                // setNombre(res.data.nombre);

                // frac.categorie = res.data.categorie;
                // setcategorieSelect(JSON.stringify(res.data.categorie));
                // setcategorieSelectState("success");

                eva.oldProvision = res.data.oldProvision;
                setOldProvision(res.data.oldProvision);

                eva.newProvision = res.data.newProvision;
                setNewProvision(res.data.newProvision);

                setEvaluation(res.data);
                setEditMode(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const warningWithConfirmAndCancelMessage = (profil) => {

        confirm({
            title: "Êtes-vous sûr de vouloir supprimer?",
            icon: <ExclamationCircleFilled/>,
            content: "  Vous ne serez plus en mesure de retrouver cet enregistrement!",
            okText: "Oui, Supprimer",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                successDelete(profil);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const successDelete = (id) => {
        deleteEvaluation(id);
    };

    const deleteEvaluation = (id) => {
        axios
            .delete(`${API_URL}/api/evaluation/${id}/${LOGGED_USER.codeId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                toast('Suppression effectuée avec succès!')
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);

            });
    };

    const cancelDetele = () => {
        toast('Annulation effectuée')
    };

    useEffect(() => {
        const dt = [];
        axios
            .get(`${API_URL}/api/evaluation`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                res.data.map((eva, index) => {
                    dt.push({
                        id: index + 1,
                        libelle: eva.libelle,
                        motif: eva.motif,
                        oldProvision: eva.oldProvision,
                        newProvision: eva.newProvision,
                        // categorie: frac.categorie.nom,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to add a like kind of action */}
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        setEditMode(true);
                                        editEvaluation(eva.evaluationId);
                                    }}
                                    color="info"
                                    className="like"
                                >
                                    <EditOutlined/>
                                </Button>{" "}
                                {/* use this button to add a edit kind of action */}
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        navigate(`/admin/details-evaluation/${eva.evaluationId}`, {replace: true})
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <EditOutlined/>
                                </Button>{" "}
                                {/* use this button to remove the data row */}
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        warningWithConfirmAndCancelMessage(eva.evaluationId);
                                    }}
                                    color="danger"
                                    className="remove"
                                >
                                    <CloseOutlined/>
                                </Button>{" "}
                            </div>
                        ),
                    });
                });
                setData(dt);
                setEvaluations(res.data);
                console.log(evaluations);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [TOKEN]);

    return (
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                <SideBar currentMenu={"SINISTRES"}/>

                {/* <!-- Layout container --> */}
                <div className="layout-page">
                    <NavBar/>

                    {/* <!-- Content wrapper --> */}
                    <div className="content-wrapper">
                        {/* <!-- Content --> */}
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className={'container'}>
                                <div className={'container'}>
                                    {alert}
                                    <div className={'grid-item'} md={4}>
                                        <Card>
                                            <CardHeader color="rose" text>
                                                <CardText color="rose">
                                                    <h4 className={'card-title'}>
                                                        {editMode ? "Modifier le" : "Faire une"} evaluation
                                                    </h4>
                                                </CardText>
                                            </CardHeader>
                                            <form onSubmit={handleSubmit}>
                                                <CardBody>
                                                    <div className={'container'} justify="center">
                                                        <div className={'grid-item'} md={12}>
                                                            <CustomInput
                                                                success={evaLibelleState === "success"}
                                                                error={evaLibelleState === "error"}
                                                                id="libelle"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                labelText={
                                                                    <span>Libellé <small>(Obligatoire)</small></span>
                                                                }
                                                                value={evaLibelle}
                                                                onChange={(event) => {
                                                                    if (verifyLength(event.target.value, 2)) {
                                                                        setevaLibelleState("success");
                                                                        console.log("aloba:", event.target.value);
                                                                    } else {
                                                                        setevaLibelleState("error");
                                                                    }
                                                                    setevaLibelle(event.target.value);
                                                                }}
                                                                inputProps={{
                                                                    type: "text",
                                                                }}
                                                            />

                                                            <CustomInput
                                                                // success={trancheMinState === "success"}
                                                                // error={trancheMinState === "error"}
                                                                id="oldProvision"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                value={oldProvision}
                                                                labelText={
                                                                    <span>
                          Ancienne provision <small>(Obligatoire)</small>
                        </span>
                                                                }
                                                                onChange={(e) => {
                                                                    setOldProvision(e.target.value);
                                                                }}
                                                            />
                                                            <CustomInput
                                                                // success={trancheMinState === "success"}
                                                                // error={trancheMinState === "error"}
                                                                id="newProvision"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                value={newProvision}
                                                                labelText={
                                                                    <span>
                          Nouvelle provision <small>(Obligatoire)</small>
                        </span>
                                                                }
                                                                onChange={(e) => {
                                                                    setNewProvision(e.target.value);
                                                                }}
                                                            />
                                                            {/* <CustomInput
                      success={motifProvisionState === "success"}
                      error={motifProvisionState === "error"}
                      id="description"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      labelText={<span>Motif de la révision</span>}
                      value={motifProvision}
                      onChange={(event) => {
                        // handleChange(event);
                        if (verifyLength(event.target.value, 3)) {
                          if (verifyNumber(event.target.value)) {
                            setmotifProvisionState("error");
                          } else {
                            setmotifProvisionState("success");
                          }
                        } else {
                          setmotifProvisionState("error");
                        }
                        setmotifProvision(event.target.value);
                      }}
                      inputProps={{
                        type: "text",
                        multiline: true,
                        rows: 2,
                      }}
                    /> */}
                                                            <br/>
                                                            <div
                                                                className={'form-control'}
                                                            >
                                                                <Select
                                                                    placeholder="Motif de la revision"
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                    dropdownRender={(menu) => (
                                                                        <>
                                                                            {menu}
                                                                            <Divider
                                                                                style={{
                                                                                    margin: "8px 0",
                                                                                }}
                                                                            />
                                                                            <Space
                                                                                style={{
                                                                                    padding: "0 8px 4px",
                                                                                }}
                                                                            >
                                                                                <Input
                                                                                    placeholder="Ajouter un motif"
                                                                                    ref={inputRef}
                                                                                    value={name}
                                                                                    onChange={onNameChange}
                                                                                />
                                                                                <Button
                                                                                    type="text"
                                                                                    icon={<PlusOutlined/>}
                                                                                    onClick={addItem}
                                                                                >
                                                                                    Ajouter un motif
                                                                                </Button>
                                                                            </Space>
                                                                        </>
                                                                    )}
                                                                    options={items.map((item) => ({
                                                                        label: item,
                                                                        value: item,
                                                                    }))}
                                                                />
                                                            </div>
                                                            <br/>
                                                        </div>
                                                        <br/>

                                                        <Button color="rose" type="submit">
                                                            {editMode ? "Mettre a jour le" : "Ajouter une"} Evaluation
                                                        </Button>
                                                    </div>
                                                </CardBody>
                                            </form>
                                        </Card>
                                    </div>
                                    <div className={'grid-item'}>
                                        <Card>
                                            <CardHeader color="rose" text>
                                                <CardText color="rose">
                                                    <h4 className={'card-title'}>Liste des Evaluations</h4>
                                                </CardText>
                                            </CardHeader>
                                            <div className={'container'} justify="center">
                                                <div className={'grid-item'}>
                                                    <form>
                                                        <CardBody>
                                                            <div className={'container'} justify="center">
                                                            </div>
                                                        </CardBody>
                                                    </form>
                                                </div>
                                            </div>
                                            <CardBody>
                                                {/*  <ReactTable
                                                    columns={[
                                                        {
                                                            Header: "N* Sinistre",
                                                            accessor: "libelle",
                                                        },
                                                        {
                                                            Header: "Provision",
                                                            accessor: "description",
                                                        },
                                                        {
                                                            Header: "Reviseur",
                                                            accessor: "nombre",
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                        },
                                                    ]}
                                                    data={data}
                                                />*/}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div className="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div className="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div className="drag-target"></div>
        </div>
    );
};

export default EvaluationSinistres;
