import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { rightsService } from "../services/rightsService";
import { LOGGED_USER } from "../variables/constants";

const NavBar = () => {
  const navigate = useNavigate();

  const logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("t");
    sessionStorage.removeItem("k");
    sessionStorage.removeItem("n");
    toast("Vous serez déconnecté");
    navigate("/connexion");
    // window.location.reload();
  };

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
        <a className="nav-item nav-link px-0 me-xl-6" href="/#">
          <i className="bx bx-menu bx-md"></i>
        </a>
      </div>

      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        {/* <!-- Search --> */}
        <div className="navbar-nav align-items-center">
          <div className="nav-item d-flex align-items-center">
            <i className="bx bx-search bx-md"></i>
            <input
              type="text"
              className="form-control border-0 shadow-none ps-1 ps-sm-2"
              placeholder="Rechercher..."
              aria-label="Rechercher..."
            />
          </div>
        </div>
        {/* <!-- /Search --> */}

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* <!-- Place this tag where you want the button to render. --> */}

          {/* <!-- User --> */}
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <a
              className="nav-link dropdown-toggle hide-arrow p-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <div className="avatar avatar-sm me-2 avatar-online">
                <span className="avatar-initial rounded-circle bg-label-primary">
                  {LOGGED_USER?.personne?.prenom?.charAt(0)}
                  {LOGGED_USER?.personne?.nom?.charAt(0)}
                </span>
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-sm me-2">
                        <span className="avatar-initial rounded-circle bg-label-secondary">
                          {LOGGED_USER?.personne?.prenom?.charAt(0)}
                          {LOGGED_USER?.personne?.nom?.charAt(0)}
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-0">
                        {LOGGED_USER?.personne?.prenom}{" "}
                        {LOGGED_USER?.personne?.nom}
                      </h6>
                      <small className="text-muted">{LOGGED_USER?.username}</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div className="dropdown-divider my-1"></div>
              </li>
              <li>
                <a className="dropdown-item" href="/mon-profil">
                  <i className="bx bx-user bx-md me-3"></i>
                  <span>Mon profil</span>
                </a>
              </li>
              {rightsService("HME051") || rightsService("HME050") && <li>
                <a className="dropdown-item" href="/ma-compagnie">
                  <i className="bx bx-home bx-md me-3"></i>
                  <span>{LOGGED_USER.profil.organisme.raisonSociale}</span>
                </a>
              </li>}
              {LOGGED_USER?.agence && <li>
                <a className="dropdown-item" href="/mon-agence">
                  <i className="bx bxs-home bx-md me-3"></i>
                  <span>{LOGGED_USER?.agence?.nom}</span>
                </a>
              </li>}
              <li>
                <a className="dropdown-item" href="/#">
                  {" "}
                  <i className="bx bx-cog bx-md me-3"></i>
                  <span>Paramètres</span>{" "}
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/#">
                  <span className="d-flex align-items-center align-middle">
                    <i className="flex-shrink-0 bx bx-bell bx-md me-3"></i>
                    <span className="flex-grow-1 align-middle">Notifications</span>
                    <span className="flex-shrink-0 badge rounded-pill bg-danger">
                      0
                    </span>
                  </span>
                </a>
              </li>
              <li>
                <div className="dropdown-divider my-1"></div>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={(e) => logout(e)}>
                  <i className="bx bx-power-off bx-md me-3"></i>
                  <span>Déconnexion</span>
                </a>
              </li>
            </ul>
          </li>
          {/* <!--/ User --> */}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
