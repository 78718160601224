import React, {useEffect, useRef, useState} from "react";
import SideBar from "../../../components/SideBar";
import NavBar from "../../../components/NavBar";
import {Footer} from "antd/es/layout/layout";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {API_URL, LOGGED_USER, TOKEN} from "../../../variables/constants";
import Details from "./tabsContent/Details";
import Traitements from "./tabsContent/Traitements";
import {toast} from "react-toastify";
import Recours from "./tabsContent/Recours";


const initEvaluation = {
    ancienneProvision: 0, nouvelleProvision: 0, motifRevision: null, reviseur: "", sinistre: "", provisionGaranties: [],
};

const initVictime = {
    descriptionDegatsVictime: "", sinistre: "", personne: {
        nom: "", prenom: "", adresse: "", profession: "", temoin: true,
    },
};


const DetailsSinistre = () => {
    const param = useParams()
    const navigate = useNavigate()
    const [alert, setAlert] = useState();
    const [sinistre, setSinistre] = useState();
    const [captures, setCaptures] = useState([]);
    const [captured, setCaptured] = useState([]);
    const [modalReevaluation, setModalReevaluation] = useState(false);
    const [modalRecoursReevaluation, setModalRecoursReevaluation] = useState(false);
    const [modalVictime, setModalVictime] = useState(false);
    const [tabId, setTabId] = useState("1");
    const [evaluation, setEvaluation] = useState(initEvaluation);
    const [editMode, setEditMode] = useState(false);
    const [evaluations, setEvaluations] = useState([]);
    const [recoursEvaluations, setRecoursEvaluations] = useState([]);
    const [victime, setVictime] = useState(initVictime);
    const [victimes, setVictimes] = useState([]);
    const [modalReglementSinistre, setModalReglementSinistre] = useState(false);
    const [modalEncaissement, setModalEncaissement] = useState(false);
    const [modalAddExpert, setModalAddExpert] = useState(false);

    const [garanties, setGaranties] = useState([]);
    const [selectedGaranties, setSelectedGaranties] = useState([]);
    const [garantieState, setGarantieState] = useState("");
    const [valueSelected, setValueSelected] = useState([]);
    const [provisionGaranties, setProvisionGarantie] = useState([]);
    const [decaissements, setDecaissements] = useState([]);
    const [encaissements, setEncaissements] = useState([]);

    const [ordreDepense, setOrdreDepense] = useState("");
    const [modalOrdreDepense, setModalOrdreDepense] = useState(false);

    const [ficheDemandeReglement, setFicheDemandeReglement] = useState("");
    const [modalFicheDemandeReglement, setModalFicheDemandeReglement] = useState(false);
    const [modalAssignCheque, setModalAssignCheque] = useState(false);
    const [decaissement, setDecaissement] = useState();
    const [experts, setExperts] = useState([]);
    const [expert, setExpert] = useState("");
    const [modalCloture, setModalCloture] = useState(false);
    const [modalSuspension, setModalSuspension] = useState(false);
    const [loadingCloture, setLoadingCloture] = useState(false);
    const [loadingSuspension, setLoadingSuspension] = useState(false);
    const [loadingSinistre, setLoadingSinistre] = useState(false)
    const [items, setItems] = useState(["Nouvelles investigations", "Imprévus", "Clôture", "Nouveau élément dans le dossier", "Forclusion", "Provision insuffisante", "Inventaire",]);

    const [activeTab, setActiveTab] = useState('details')

    // States for evaluation
    const [motifRevisionState, setMotifRevisionState] = useState("");
    // State for victimes
    const [nomState, setNomState] = useState("");
    const [prenomState, setPrenomState] = useState("");
    const [adresseState, setAdresseState] = useState("");
    const [professionState, setProfessionState] = useState("");
    const [descriptionDegatsVictimeState, setDescriptionDegatsVictimeState,] = useState("");
    const [isRecours, setIsRecours] = useState(false);
    const showQuittance = (item) => {
        setQuittance(item);
        setModalQuittance(true);
    };

    const [quittance, setQuittance] = useState();
    const [modalQuittance, setModalQuittance] = useState(false);
    const [montant, setMontant] = useState(0);


    console.log('sinistre ', sinistre)
    useEffect(() => {
        if (param.key) {
            setLoadingSinistre(true)
            axios
                .get(`${API_URL}/api/sinistre/codeId/${param.key}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    setSinistre(res.data);
                    setEvaluations(res.data.evaluations.filter((d) => d.encaissement === false || d.encaissement === null));
                    setRecoursEvaluations(res.data.evaluations.filter((d) => d.encaissement === true));
                    setVictimes(res.data.victimes);
                    setDecaissements(res.data.decaissements.filter((d) => d.encaissement === false || d.encaissement === null));
                    setEncaissements(res.data.decaissements.filter((d) => d.encaissement === true));
                    setExperts(res.data.experts);
                    let m = 0.0;
                    res.data.decaissements.forEach((d) => {
                        if (!d.encaissement) {
                            m += parseFloat(d.montantGlobal);
                        }
                    });
                    setMontant(m);

                    if (res.data?.engin) {
                        axios
                            .get(`${API_URL}/api/garantie/categorie/compulsory/code/${res.data?.engin?.categorie?.codeCategorie}`, {
                                headers: {
                                    Authorization: `Bearer ${TOKEN}`,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                },
                            })
                            .then((response) => {
                                let gs = [];
                                gs = response.data;
                                let g = [];
                                res.data.engin.sousGaranties && res.data.engin.sousGaranties.forEach((sg) => {
                                    gs.push(sg.garantie);
                                    g.push({
                                        value: sg.garantie.codeId, label: sg.nom, garantie: sg.garantie,
                                    });
                                });

                                response.data && response.data.forEach((ga) => {
                                    g.push({
                                        value: ga.codeId, label: ga.nom, garantie: ga,
                                    });
                                });
                                // let g = [];
                                // gs.forEach((s) => {
                                //   g.push({
                                //     value: s.codeId,
                                //     label: s.nom,
                                //     garantie: s,
                                //   });
                                // });
                                console.log("garantiiiiiiiiiiiies:", g);
                                setGaranties(g);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    if (res.data?.captures) {
                        setCaptures(res.data?.captures);
                        // setCaptures(res.data?.captures);
                        // fetch(`${API_URL}/api/sinistre/captures/${res.data.codeId}`, {
                        //   headers: {
                        //     Authorization: `Bearer ${TOKEN}`,
                        //     "Content-Type": "application/json",
                        //     "Access-Control-Allow-Origin": "*",
                        //     "Access-Control-Allow-Methods":
                        //       "GET, PUT, POST, DELETE, OPTIONS",
                        //     "Access-Control-Allow-Headers":
                        //       "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        //   },
                        // })
                        //   .then((response) => {
                        //     console.log("ressss: ", response.body);
                        //     response.json().then((data) => {
                        //       setCaptures(data);
                        //     });
                        //   })
                        //   .catch((err) => {
                        //     console.log(err);
                        //   });

                        axios
                            .get(`${API_URL}/api/sinistre/captures/${res.data.codeId}`, {
                                headers: {
                                    Authorization: `Bearer ${TOKEN}`,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                },
                            })
                            .then((resp) => {
                                console.log("resssss", res.data);
                                // setCaptures(res.data);
                                let cpt = [];
                                resp.data.forEach((c) => {
                                    cpt.push({
                                        uid: c.captureId,
                                        name: c.nom,
                                        status: "done",
                                        url: `data:image/png;base64,${c}`,
                                    });
                                });
                                setCaptured(cpt);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        setLoadingSinistre(false)
                    }
                })
                .catch((err) => {
                    setLoadingSinistre(false)
                    console.log(err);
                });
        }
    }, [TOKEN, param]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const addProvisionFormFields = () => {
        setProvisionGarantie([
            ...provisionGaranties,
            {
                garantie: "",
                provision: "",
            },
        ]);
    };
    const isValidated = () => {
        if (motifRevisionState === "success" && garantieState === "success") {
            return true;
        } else {
            if (motifRevisionState !== "success") {
                return false;
            }
            if (garantieState !== "success") {
                return false;
            }
        }
    };
    const isVictimeValidated = () => {
        if (
            nomState === "success" &&
            prenomState === "success" &&
            adresseState === "success"
        ) {
            return true;
        } else {
            if (nomState !== "success") {
                return false;
            }
            if (prenomState !== "success") {
                return false;
            }
            if (adresseState !== "success") {
                return false;
            }
            if (professionState !== "success") {
                return false;
            }
            if (descriptionDegatsVictimeState !== "success") {
                return false;
            }
        }
    };
    const removeProvisionFormFields = (index) => {
        let newProvisionFormFields = [...provisionGaranties];
        let id = valueSelected.findIndex(index);
        newProvisionFormFields.splice(id, 1);
        setProvisionGarantie(newProvisionFormFields);
    };

    const handleSubmit = (e, rec) => {
        e.preventDefault();
        const validate = isValidated();
        if (validate) {
            if (editMode) {
                evaluation.sinistre = sinistre;
                evaluation.reviseur = LOGGED_USER;
                evaluation.ancienneProvision = sinistre?.evaluation;
                evaluation.encaissement = isRecours;
                evaluation.encaissement = rec;
                axios
                    .post(
                        `${API_URL}/api/provision-garantie/all`,
                        JSON.stringify(provisionGaranties),
                        {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods":
                                    "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        evaluation.provisionGaranties = response.data;
                        axios
                            .put(`${API_URL}/api/evaluation`, JSON.stringify(evaluation), {
                                headers: {
                                    Authorization: `Bearer ${TOKEN}`,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods":
                                        "GET, PUT, POST, DELETE, OPTIONS",
                                    "Access-Control-Allow-Headers":
                                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                },
                            })
                            .then((res) => {
                                console.log(res.data);
                                setEditMode(false);
                                setModalReevaluation(false);
                                setEvaluation(initEvaluation);
                                toast("mis à jour");
                            })
                            .catch((err) => {
                                console.log(err);

                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                evaluation.sinistre = sinistre;
                evaluation.reviseur = LOGGED_USER;
                evaluation.ancienneProvision = sinistre?.evaluation;
                evaluation.encaissement = isRecours;
                evaluation.encaissement = rec;
                axios
                    .post(
                        `${API_URL}/api/provision-garantie/all`,
                        JSON.stringify(provisionGaranties),
                        {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods":
                                    "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        evaluation.provisionGaranties = response.data;
                        console.log("evaluation", evaluation);
                        axios
                            .post(`${API_URL}/api/evaluation`, JSON.stringify(evaluation), {
                                headers: {
                                    Authorization: `Bearer ${TOKEN}`,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods":
                                        "GET, PUT, POST, DELETE, OPTIONS",
                                    "Access-Control-Allow-Headers":
                                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                },
                            })
                            .then((res) => {
                                console.log(res.data);
                                setModalReevaluation(false);
                                setModalRecoursReevaluation(false);
                                setEvaluation(initEvaluation);
                                toast("créée");
                            })
                            .catch((err) => {
                                console.log(err);

                            });
                    })
                    .catch((err) => {

                        console.log(err);
                    });
            }
        } else {
            toast("Vérifier vos entrées");
        }
    };
    const handleChange = (e, nom) => {
        if (nom) {
            setEvaluation({...evaluation, [nom]: e});
        } else {
            const {name, value} = e.target;
            setEvaluation({...evaluation, [name]: value});
        }
    };

    const [name, setName] = useState("");
    const inputRef = useRef(null);
    const setOpen = (value) => {
        setModalAddExpert(value);
    };
    const setOpenReglement = (value) => {
        setModalReglementSinistre(value);
    };
    const handleSuspensionDossier = () => {
        setLoadingSuspension(true);
        axios
            .get(`${API_URL}/api/sinistre/suspension/${sinistre.codeId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                setLoadingSuspension(false);
                setModalSuspension(false);
                toast.success("Dossier suspendu avec succès!");
                navigate(`/admin/details-sinistre/${res.data.codeId}`)
            })
            .catch((err) => {
                console.log(err);
                setLoadingSuspension(false);
                setModalSuspension(false);
                toast.error("Échec lors de la suspension du dossier!");
            });
    };
    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, name || `New item ${items.length + 1} `]);
        setName("");
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const handleClotureDossier = () => {
        setLoadingCloture(true);
        axios
            .get(`${API_URL}/api/sinistre/cloture/${sinistre.codeId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                setLoadingCloture(false);
                setModalCloture(false);
                toast.success("Dossier clôturé avec succès");
                navigate(`/admin/details-sinistre/${res.data.codeId}`)
            })
            .catch((err) => {
                console.log(err);
                setLoadingCloture(false);
                setModalCloture(false);
                toast.error("Échec lors de la clôture du dossier");
            });
    };
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const showOrdreDepense = (item) => {
        setOrdreDepense(item);
        setModalOrdreDepense(true);
    };
    const editEvaluation = (item) => {
        setEditMode(true);
        setEvaluation(item);
        setProvisionGarantie(item.provisionGaranties);
        const pg = [];
        const pgi = [];
        item.provisionGaranties.forEach((p) => {
            pg.push(p.garantie);
            pgi.push(p.garantie.codeId);
        });
        setValueSelected(pgi);
        setSelectedGaranties(pg);
        setMotifRevisionState("success");
        setGarantieState("success");
        setModalReevaluation(true);
    };
    const setOpenEncaissement = (value) => {
        setModalEncaissement(value);
    };

    const renderContent = () => {
        if (activeTab === 'details') {
            return (<Details montant={montant} setModalFicheDemandeReglement={setModalFicheDemandeReglement}
                             setFicheDemandeReglement={setFicheDemandeReglement} captured={captured} captures={captures}
                             initEvaluation={initEvaluation}
                             decaissements={decaissements} evaluations={evaluations} experts={experts} items={items}
                             handleSubmit={handleSubmit}
                             setDecaissement={setDecaissements} setEvaluation={setEvaluations} sinistre={sinistre}
                             valueSelected={valueSelected}
                             quittance={quittance} setQuittance={setQuittance} setAdresseState={setAdresseState}
                             inputRef={inputRef}
                             setDescriptionDegatsVictimeState={setDescriptionDegatsVictimeState}
                             onNameChange={onNameChange}
                             setGarantieState={setGarantieState} victimes={victimes} loadingSinistre={loadingSinistre}
                             garanties={garanties}
                             setModalAssignCheque={setModalAssignCheque} setModalOrdreDepense={setOrdreDepense}
                             editMode={editMode} addProvisionFormFields={addProvisionFormFields}
                             removeProvisionFormFields={removeProvisionFormFields}
                             setModalQuittance={setModalQuittance} setModalReevaluation={setModalReevaluation}
                             handleChange={handleChange}
                             setModalVictime={setModalVictime} setOrdreDepense={setOrdreDepense}
                             provisionGaranties={provisionGaranties}
                             setMotifRevisionState={setMotifRevisionState} setNomState={setNomState} addItem={addItem}
                             modalReevaluation={modalReevaluation}
                             setPrenomState={setPrenomState} setProfessionState={setProfessionState}
                             setVictime={setVictime} editEvaluation={editEvaluation} name={name}
                             selectedGaranties={selectedGaranties} evaluation={evaluation}
                             setProvisionGarantie={setProvisionGarantie} setSelectedGaranties={setSelectedGaranties}
                             setValueSelected={setValueSelected} setModalAddExpert={setModalAddExpert}
                             setExpert={setExpert} setEditMode={setEditMode}/>)
        } else if (activeTab === 'traitements') {
            return (<Traitements setModalFicheDemandeReglement={setModalFicheDemandeReglement}
                                 setFicheDemandeReglement={setFicheDemandeReglement} modalSuspension={modalSuspension}
                                 loadingSuspension={loadingSuspension}
                                 loadingCloture={loadingCloture} handleClotureDossier={handleClotureDossier}
                                 handleSuspensionDossier={handleSuspensionDossier} modalCloture={modalCloture}
                                 captures={captures} expert={expert} modalAddExpert={modalAddExpert} setOpen={setOpen}
                                 decaissements={decaissements} evaluations={evaluations} experts={experts}
                                 setDecaissement={setDecaissements} setEvaluation={setEvaluations} sinistre={sinistre}
                                 quittance={quittance} setAdresseState={setAdresseState}
                                 setDescriptionDegatsVictimeState={setDescriptionDegatsVictimeState}
                                 setGarantieState={setGarantieState} victimes={victimes}
                                 loadingSinistre={loadingSinistre}
                                 setModalAssignCheque={setModalAssignCheque} setModalOrdreDepense={setOrdreDepense}
                                 setModalQuittance={setModalQuittance} setModalReevaluation={setModalReevaluation}
                                 setModalVictime={setModalVictime} setOrdreDepense={setOrdreDepense}
                                 setMotifRevisionState={setMotifRevisionState} setNomState={setNomState}
                                 setPrenomState={setPrenomState} setProfessionState={setProfessionState}
                                 setVictime={setVictime} provisionGaranties={provisionGaranties}
                                 modalReglementSinistre={modalReglementSinistre}
                                 setProvisionGarantie={setProvisionGarantie} setSelectedGaranties={setSelectedGaranties}
                                 setValueSelected={setValueSelected} setModalAddExpert={setModalAddExpert}
                                 setOpenReglement={setOpenReglement}
                                 setExpert={setExpert} setEditMode={setEditMode} setModalSuspension={setModalSuspension}
                                 inputRef={inputRef} valueSelected={valueSelected} selectedGaranties={selectedGaranties}
                                 decaissement={decaissement} victime={victime} initEvaluation={initEvaluation}
                                 items={items} handleSubmit={handleSubmit} ordreDepense={ordreDepense}
                                 professionState={professionState}
                                 setItems={setItems} editMode={editMode} evaluation={evaluation} setName={setName}
                                 setModalReglementSinistre={setModalReglementSinistre} setModalCloture={setModalCloture}
                                 prenomState={prenomState} modalVictime={modalVictime} modalQuittance={modalQuittance}
                                 modalReevaluation={modalReevaluation} handleChange={handleChange}
                                 modalAssignCheque={modalAssignCheque} modalOrdreDepense={modalOrdreDepense}
                                 descriptionDegatsVictimeState={descriptionDegatsVictimeState} nomState={nomState}
                                 garanties={garanties}
                                 adresseState={adresseState} name={name} ficheDemandeReglement={ficheDemandeReglement}
                                 modalFicheDemandeReglement={modalFicheDemandeReglement}
                                 initVictime={initVictime} addProvisionFormFields={addProvisionFormFields}
                                 isVictimeValidated={isVictimeValidated}
                                 removeProvisionFormFields={removeProvisionFormFields}/>)
        } else if (activeTab === 'recours') {
            return (
                <Recours evaluation={evaluation} sinistre={sinistre} selectedGaranties={selectedGaranties}
                         handleSuspensionDossier={handleSuspensionDossier} setIsRecours={setIsRecours} addItem={addItem}
                         encaissements={encaissements} handleClotureDossier={handleClotureDossier}
                         loadingCloture={loadingCloture} loadingSuspension={loadingSuspension}
                         modalCloture={modalCloture} modalEncaissement={modalEncaissement}
                         modalRecoursReevaluation={modalRecoursReevaluation} modalSuspension={modalSuspension}
                         onNameChange={onNameChange} setModalEncaissement={setModalEncaissement}
                         setModalRecoursReevaluation={setModalRecoursReevaluation}
                         showFicheDemandeReglement={setFicheDemandeReglement} showOrdreDepense={showOrdreDepense}
                         showQuittance={showQuittance} provisionGaranties={provisionGaranties}
                         setModalAssignCheque={setModalAssignCheque} setEvaluation={setEvaluation} garanties={garanties}
                         setProvisionGarantie={setProvisionGarantie} setSelectedGaranties={setSelectedGaranties}
                         setValueSelected={setValueSelected} name={name} handleChange={handleChange}
                         handleSubmit={handleSubmit} removeProvisionFormFields={removeProvisionFormFields}
                         addProvisionFormFields={addProvisionFormFields} setModalCloture={setModalCloture}
                         editMode={editMode} initEvaluation={initEvaluation} items={items} valueSelected={valueSelected}
                         inputRef={inputRef} setModalSuspension={setModalSuspension} setGarantieState={setGarantieState}
                         setMotifRevisionState={setMotifRevisionState} setDecaissement={setDecaissements}
                         montant={montant} editEvaluation={editEvaluation} recoursEvaluations={recoursEvaluations}
                         setOpenEncaissement={setOpenEncaissement} loadingSinistre={loadingSinistre}/>
            )
        }
    }
    return (<div className="layout-wrapper layout-content-navbar  ">
        <div className="layout-container">
            <SideBar currentMenu={'SINISTRES'}/>

            {/* <!-- Layout container --> */}
            <div className="layout-page">
                <NavBar/>

                {/* <!-- Content wrapper --> */}
                <div className="content-wrapper">
                    {/* <!-- Content --> */}
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="nav-align-top">
                                    <ul className="nav nav-pills mb-4 nav-fill" role="tablist">

                                        <li className="nav-item mb-1 mb-sm-0">
                                            <button
                                                type="button"
                                                className={`nav-link ${activeTab === "details" ? "active" : ""}`}
                                                role="tab"
                                                onClick={() => handleTabChange("details")}
                                            >
                          <span className="d-none d-sm-block">
                               <i className="bx bx-sm bx-user me-1_5"></i>
                            Détails
                          </span>
                                                <i className="bx bx-home bx-sm d-sm-none"></i>
                                            </button>
                                        </li>
                                        <li className="nav-item mb-1 mb-sm-0">
                                            <button
                                                type="button"
                                                className={`nav-link ${activeTab === "traitements" ? "active" : ""}`}
                                                role="tab"
                                                onClick={() => handleTabChange("traitements")}
                                            >
                          <span className="d-none d-sm-block">
   <i className="bx bx-sm bx-lock-alt me-1_5"></i>                            TRAITEMENTS
                          </span>
                                                <i className="bx bx-home bx-sm d-sm-none"></i>
                                            </button>
                                        </li>
                                        <li className="nav-item mb-1 mb-sm-0">
                                            <button
                                                type="button"
                                                className={`nav-link ${activeTab === "recours" ? "active" : ""}`}
                                                role="tab"
                                                onClick={() => handleTabChange("recours")}
                                            >
                          <span className="d-none d-sm-block">
                            <i className="bx bx-sm bx-bell me-1_5"></i>
                            RECOURS
                          </span>
                                                <i className="bx bx-home bx-sm d-sm-none"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {renderContent()}

                    </div>

                    {/* <!-- / Content --> */}

                    {/* <!-- Footer --> */}
                    <Footer/>
                    {/* <!-- / Footer --> */}

                    <div className="content-backdrop fade"></div>
                </div>
                {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page --> */}
        </div>

        {/* <!-- Overlay --> */}
        <div className="layout-overlay layout-menu-toggle"></div>

        {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
        <div className="drag-target"></div>
    </div>);
};

export default DetailsSinistre;
