import {Card, List, Space, Tag, Typography} from "antd";

const {Title, Text} = Typography;

const GarantyBasedProvisionsDetails = ({provisions,}) => {
    return (
        <Card
            title="Détails des Provisions"
            bordered={false}
            style={{
                margin: "20px",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            }}
        >
            <List
                grid={{gutter: 16, column: 4}} // Adjust grid layout for horizontal alignment
                dataSource={provisions}
                renderItem={(item) => (
                    <List.Item>
                        <Card
                            hoverable
                            style={{
                                textAlign: "center",
                                borderRadius: "10px",
                                padding: "10px",
                                fontSize: "12px",

                            }}
                            bodyStyle={{padding: "10px"}}
                        >
                            <Space direction="vertical" align="center">
                                <Tag color="blue" style={{fontSize: "12px", padding: "5px 10px"}}>
                                    {new Intl.NumberFormat("en-DE").format(item.provision)} FCFA
                                </Tag>
                                <Text type="primary" style={{fontSize: "16px"}}>
                                    {item.prejudice}
                                </Text>
                            </Space>
                        </Card>
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default GarantyBasedProvisionsDetails
