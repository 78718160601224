import {
  DownOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Space,
  Table,
  Tooltip,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { rightsService } from "../../services/rightsService";
import { API_URL, TOKEN } from "../../variables/constants";
const { confirm } = Modal;
// import moment from "moment";

const Utilisateurs = () => {
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const [activeUtilisateurs, setActiveUtilisateurs] = useState(0);
  const [pendingUsers, setPendingUsers] = useState(0);
  const [nonActiveUsers, setNonActiveUsers] = useState(0);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const navigate = useNavigate();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Réinitialiser
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
  });

  const columns = [
    {
      title: "Nom & Prénom(s)",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <div className="d-flex align-items-center">
          <div className="avatar-wrapper">
            <div className="avatar avatar-sm me-3">
              {record.photo_url ? (
                <img
                  src={`path/to/assets/img/avatars/${Math.floor(Math.random() * 11) + 1
                    }.png`}
                  alt="Avatar"
                  className="rounded-circle"
                />
              ) : (
                <span className="avatar-initial rounded-circle bg-label-success">
                  {name
                    .split(" ")
                    .map((n) => n[0])
                    .join("")}
                </span>
              )}
            </div>
          </div>
          <div>
            <a
              href={`/details-utilisateur/${record.codeId}`}
              className="text-heading text-truncate"
            >
              {name}
            </a>
            <br />
            <small className="text-truncate">{record.username}</small>
          </div>
        </div>
      ),
    },
    {
      title: "Adresse mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Fonction",
      dataIndex: "fonction",
      key: "fonction",
    },
    {
      title: "Profil",
      dataIndex: "profil",
      key: "profil",
      render: (role, record) => (
        <Tooltip title={rightsService("HAC056") ? "Cliquez pour modifier le profil" : ""}>
          <span onClick={() => rightsService("HAC056") && navigate(`/modifier-profil/${record.data.profil.codeId}`)} >
            <Badge color={"blue"} text={role} />
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <a href={`/details-utilisateur/${record.codeId}`}>Détails</a>
              </Menu.Item>
              {rightsService("HPE039") ? <Menu.Item
                key="2"
                onClick={() =>
                  showActivateConfirm(record.codeId, record.active)
                }
              >
                {record.active ? "Désactiver" : "Activer"}{" "}
              </Menu.Item> : null}
              {rightsService("HPE041") ? <Menu.Item key="3">
                <a href={`/details-utilisateur/${record.codeId}`}>Modifier</a>
              </Menu.Item> : null}
              {rightsService("HPE042") ?
                <Menu.Item key="4" onClick={() => showDeletionConfirm(record.codeId)} >Supprimer</Menu.Item> : null}
            </Menu>
          }
        >
          <Button icon={<DownOutlined />} />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    var usr = [];
    axios
      .get(`${API_URL}/api/utilisateur`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        res.data?.forEach((user, index) => {
          usr.push({
            id: index + 1,
            nom: user.personne?.nom,
            prenom: user.personne?.prenom,
            name: `${user.personne?.nom} ${user.personne?.prenom}`,
            username: user.username,
            fonction: user.personne?.fonction,
            email: user?.email,
            profil: user.profil?.nom,
            active: user.active,
            data: user,
            codeId: user.codeId,
          });
        });
        setUtilisateurs(usr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setUtilisateurs([]);
    };
  }, [TOKEN]);

  const showActivateConfirm = (utilisateurCodeId, status) => {
    confirm({
      title: status
        ? "Etes-vous sûr de vouloir désactiver cet utilisateur?"
        : "Etes-vous sûr de vouloir activer cet utilisateur?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action sera historisée et donc est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        console.log("OK");
        activateUtilisateur(utilisateurCodeId, status);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showDeletionConfirm = (utilisateurCodeId) => {
    confirm({
      title: "Etes-vous sûr de vouloir de supprimer cet utilisateur?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action sera historisée et donc est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        console.log("OK");
        deleteUtilisateur(utilisateurCodeId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const activateUtilisateur = (utilisateurCodeId, status) => {
    axios
      .get(`${API_URL}/api/utilisateur/activate/${utilisateurCodeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
        toast(
          status
            ? "Utilisateur désactivé avec succès"
            : "Utilisateur activé avec succès"
        );
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors de l'activation");
      });
  };

  const deleteUtilisateur = (utilisateurCodeId) => {
    axios
      .delete(`${API_URL}/api/utilisateur/${utilisateurCodeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
        toast(
          "Utilisateur supprimé avec succès"
        );
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors de la suppression");
      });
  };

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"UTILISATEURS"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}

            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row g-6 mb-6">
                <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">Utilisateurs</span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{utilisateurs?.length}</h4>
                            {/* <p class="text-success mb-0">(+29%)</p> */}
                          </div>
                          <small class="mb-0">Total utilisateurs</small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-primary">
                            <i class="bx bx-group bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">
                            Utilisateurs suspendus
                          </span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{nonActiveUsers}</h4>
                            {/* <p class="text-success mb-0">(+18%)</p> */}
                          </div>
                          <small class="mb-0">
                            Analyses de la semaine dernière
                          </small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-danger">
                            <i class="bx bx-user-plus bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">Utilisateurs actifs</span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{activeUtilisateurs}</h4>
                            {/* <p class="text-danger mb-0">(-14%)</p> */}
                          </div>
                          <small class="mb-0">
                            Analyses de la semaine dernière
                          </small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-success">
                            <i class="bx bx-user-check bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">
                            Utilisateurs en attente
                          </span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{pendingUsers}</h4>
                            {/* <p class="text-success mb-0">(+42%)</p> */}
                          </div>
                          <small class="mb-0">
                            Analyses de la semaine dernière
                          </small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-warning">
                            <i class="bx bx-user-voice bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Users List Table --> */}
              {rightsService("HPE038") ? <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => navigate("/ajouter-utilisateur")}
              >
                Créer un nouvel utilisateur
              </Button> : null}

              <div class="card">
                <Table
                  columns={columns}
                  dataSource={utilisateurs}
                  onChange={handleChange}
                  rowKey="utilisateurId"
                  pagination={{ pageSize: 10 }}
                  loading={loading}
                />
                {/* <!-- Offcanvas to add new user --> */}
                <div
                  class="offcanvas offcanvas-end"
                  tabindex="-1"
                  id="offcanvasAddUser"
                  aria-labelledby="offcanvasAddUserLabel"
                >
                  <div class="offcanvas-header border-bottom">
                    <h5 id="offcanvasAddUserLabel" class="offcanvas-title">
                      Add User
                    </h5>
                    <button
                      type="button"
                      class="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="offcanvas-body mx-0 flex-grow-0 p-6 h-100">
                    <form
                      class="add-new-user pt-0"
                      id="addNewUserForm"
                      onsubmit="return false"
                    >
                      <div class="mb-6">
                        <label class="form-label" for="add-user-fullname">
                          Full Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="add-user-fullname"
                          placeholder="John Doe"
                          name="userFullname"
                          aria-label="John Doe"
                        />
                      </div>
                      <div class="mb-6">
                        <label class="form-label" for="add-user-email">
                          Email
                        </label>
                        <input
                          type="text"
                          id="add-user-email"
                          class="form-control"
                          placeholder="john.doe@example.com"
                          aria-label="john.doe@example.com"
                          name="userEmail"
                        />
                      </div>
                      <div class="mb-6">
                        <label class="form-label" for="add-user-contact">
                          Contact
                        </label>
                        <input
                          type="text"
                          id="add-user-contact"
                          class="form-control phone-mask"
                          placeholder="+1 (609) 988-44-11"
                          aria-label="john.doe@example.com"
                          name="userContact"
                        />
                      </div>
                      <div class="mb-6">
                        <label class="form-label" for="add-user-company">
                          Company
                        </label>
                        <input
                          type="text"
                          id="add-user-company"
                          class="form-control"
                          placeholder="Web Developer"
                          aria-label="jdoe1"
                          name="companyName"
                        />
                      </div>
                      <div class="mb-6">
                        <label class="form-label" for="country">
                          Country
                        </label>
                        <select id="country" class="select2 form-select">
                          <option value="">Select</option>
                          <option value="Australia">Australia</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Brazil">Brazil</option>
                          <option value="Canada">Canada</option>
                          <option value="China">China</option>
                          <option value="France">France</option>
                          <option value="Germany">Germany</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Japan">Japan</option>
                          <option value="Korea">Korea, Republic of</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Russia">Russian Federation</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                        </select>
                      </div>
                      <div class="mb-6">
                        <label class="form-label" for="user-role">
                          User Role
                        </label>
                        <select id="user-role" class="form-select">
                          <option value="subscriber">Subscriber</option>
                          <option value="editor">Editor</option>
                          <option value="maintainer">Maintainer</option>
                          <option value="author">Author</option>
                          <option value="admin">Admin</option>
                        </select>
                      </div>
                      <div class="mb-6">
                        <label class="form-label" for="user-plan">
                          Select Plan
                        </label>
                        <select id="user-plan" class="form-select">
                          <option value="basic">Basic</option>
                          <option value="enterprise">Enterprise</option>
                          <option value="company">Company</option>
                          <option value="team">Team</option>
                        </select>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-primary me-3 data-submit"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        class="btn btn-label-danger"
                        data-bs-dismiss="offcanvas"
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default Utilisateurs;
