import {UploadOutlined} from "@ant-design/icons";
import {Button, message, Modal, Upload} from "antd";
import React, {useEffect, useState} from "react";

import PropTypes from "prop-types";
import axios from "axios";
import Datetime from "react-datetime";
import {useParams} from "react-router-dom";
import {API_URL, TOKEN} from "../../../variables/constants";
import CustomInput from "./CustomInput";
import {verifyLength, verifyNumber} from "../../../variables/functions";

const styles = {
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center",
    },
    cardCategory: {
        margin: "0",
        color: "#999999",
    },
};

const AssignCheque = (prop) => {
    const [modalDecaissementSinistre, setModalDecaissementSinistre] = useState(
        false
    );
    const [numChequeState, setNumChequeState] = useState("");
    const [numeroCheque, setNumeroCheque] = useState("");
    const [cheque, setCheque] = useState("");
    const [representant, setRepresentant] = useState(null); //
    const [dateEmissionCheque, setDateEmissionCheque] = useState(null); //
    const [cni, setCni] = useState("");
    const [banque, setBanque] = useState(null);
    const [banqueState, setBanqueState] = useState(null);
    const param = useParams();

    const handleDecaissementSubmit = () => {
        let decaissement = prop?.decaissement;
        // console.log("decaissement: ", decaissement);
        if (decaissement) {
            decaissement.numeroCheque = numeroCheque;
            decaissement.cni = cni[0];
            decaissement.cheque = cheque[0];
            decaissement.dateEmissionCheque = dateEmissionCheque.toISOString();
            decaissement.representant = representant;
            decaissement.banque = banque;
            decaissement.sinistralite = prop?.sinistre?.codeId;
            console.log("Decaissment: ", decaissement);
            axios
                .put(`${API_URL}/api/decaissement`, JSON.stringify(decaissement), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        message.success("Décaissement effectué avec succès");
                        setModalDecaissementSinistre(false);
                        setNumeroCheque("");
                        setCheque(null);
                        setCni(null);
                        setNumChequeState("");
                        window.location.reload();
                    } else {
                        message.error("Erreur lors du décaissement");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    message.error("Erreur lors du décaissement");
                });
        } else {
            message.error("Erreur lors du décaissement");
        }
    };

    const customChequeRequest = async ({file, onSuccess}) => {
        const formData = new FormData();
        let extension = file.name.substring(
            file.name.lastIndexOf(".") + 1,
            file.name.length
        );
        let filename = `Cheque_${numeroCheque}.${extension}`;
        formData.append("fichier", file, filename);
        formData.append("codeId", param.id);
        // console.log("name", filename);

        try {
            const response = await axios.post(
                `${API_URL}/api/sinistre/files`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            );

            //   // Handle successful upload
            onSuccess(response.data);
            console.log("cheque", response.data);
            setCheque(response.data);
        } catch (error) {
            // Handle upload error
            console.error(error);
        }
    };

    const props = {
        name: "cheque",
        customRequest: customChequeRequest,
        onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
                setCheque(info.file.originFileObj);
                message.success(`${info.file.name} fichier téléversé avec succès`);
            } else if (info.file.status === "error") {
                message.error(
                    `${info.file.name} erreur lors du téléversement du fichier.`
                );
            }
        },
        progress: {
            strokeColor: {
                "0%": "#108ee9",
                "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    const customCNIRequest = async ({file, onSuccess}) => {
        const formData = new FormData();
        let extension = file.name.substring(
            file.name.lastIndexOf(".") + 1,
            file.name.length
        );
        let filename = `CNI_${numeroCheque}.${extension}`;
        formData.append("fichier", file, filename);
        formData.append("codeId", param.id);
        console.log("name", filename);

        try {
            const response = await axios.post(
                `${API_URL}/api/sinistre/files`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            );

            //   // Handle successful upload
            onSuccess(response.data);
            console.log("cni", response.data);
            setCni(response.data);
        } catch (error) {
            // Handle upload error
            console.error(error);
        }
    };

    const props2 = {
        name: "cni",
        customRequest: customCNIRequest,
        onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
                setCni(info.file.originFileObj);
                message.success(`${info.file.name} fichier téléversé avec succès`);
            } else if (info.file.status === "error") {
                message.error(
                    `${info.file.name} erreur lors du téléversement du fichier.`
                );
            }
        },
        progress: {
            strokeColor: {
                "0%": "#108ee9",
                "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    useEffect(() => {
        console.log(prop.decaissement);
        setModalDecaissementSinistre(prop.open);
    }, [prop.open]);

    // useEffect(() => {
    //   prop.setOpen(modalDecaissementSinistre);
    // }, [modalDecaissementSinistre]);

    return (
        <Modal
            title="Effectuer le décaissement"
            // confirmLoading={loading}
            centered
            open={modalDecaissementSinistre}
            onOk={() => handleDecaissementSubmit()}
            onCancel={() => setModalDecaissementSinistre(false)}
        >
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <CustomInput
                        success={numChequeState === "success"}
                        error={numChequeState === "error"}
                        labelText={
                            <span>
                Numéro de chèque<small>(Obligatoire)</small>
              </span>
                        }
                        id="numeroCheque"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={numeroCheque}
                        onChange={(event) => {
                            if (verifyLength(event.target.value, 5)) {
                                // setmembreRaisonState("success");
                                if (verifyNumber(event.target.value)) {
                                    setNumChequeState("success");
                                } else {
                                    setNumChequeState("error");
                                }
                            } else {
                                setNumChequeState("error");
                            }
                            setNumeroCheque(event.target.value);
                        }}
                        inputProps={{
                            endAdornment: (
                                <div className={styles.inputAdornment} style={{
                                    position: "end"
                                }}

                                ></div>
                            ),
                            name: "numeroCheque",
                            id: "numChequeState",
                        }}
                    />
                </div>
            </div>
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <Datetime
                        value={dateEmissionCheque}
                        onChange={(event) => {
                            setDateEmissionCheque(event);
                        }}
                        timeFormat={false}
                        inputProps={{
                            placeholder: "Date d'émission du chèque",
                        }}
                    />
                </div>
            </div>
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <CustomInput
                        success={banqueState === "success"}
                        error={banqueState === "error"}
                        labelText={
                            <span>
                Banque<small>(Obligatoire)</small>
              </span>
                        }
                        id="banque"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={banque}
                        onChange={(event) => {
                            if (verifyLength(event.target.value, 3)) {
                                setBanqueState("success");
                            } else {
                                setBanqueState("error");
                            }
                            setBanque(event.target.value);
                        }}
                        inputProps={{
                            endAdornment: (
                                <div style={{
                                    position: "end"
                                }}
                                     className={styles.inputAdornment}
                                ></div>
                            ),
                            name: "banque",
                            id: "banqueState",
                        }}
                    />
                </div>
            </div>
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <CustomInput
                        labelText={
                            <span>
                Nom et prénom(s) du repésentant (si différent du bénéficiaire)
              </span>
                        }
                        id="representant"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={representant}
                        onChange={(event) => {
                            setRepresentant(event.target.value);
                        }}
                        inputProps={{
                            endAdornment: (
                                <div style={{
                                    position: "end"

                                }}
                                     className={styles.inputAdornment}
                                ></div>
                            ),
                            name: "represenatant",
                            id: "representantState",
                        }}
                    />
                </div>
            </div>
            <br/>
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <Upload {...props}>
                        <Button icon={<UploadOutlined/>}>
                            Joindre le fichier du chèque
                        </Button>
                    </Upload>
                </div>
            </div>
            <br/>
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <Upload {...props2}>
                        <Button icon={<UploadOutlined/>}>
                            Joindre la CNI endossant le chèque
                        </Button>
                    </Upload>
                </div>
            </div>
        </Modal>
    );
};

AssignCheque.propTypes = {
    open: PropTypes.bool,
    // setOpen: PropTypes.func,
    decaissement: PropTypes.object,
    sinistre: PropTypes.object,
};

export default AssignCheque;
