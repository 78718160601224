import { Col, DatePicker, Layout, Modal, Row, Select, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../../../assets/styles/carousel.css";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import axiosInstance from "../../../variables/api/axiosInstance";
import { LOGGED_USER } from "../../../variables/constants";
import EmissionsNettesCedeesParExerciceEtCategorie from "./components/EmissionsNettesCedeesParExerciceEtCategorie";
import EtatGlobalProductionMensuelDTRFNational from "./components/EtatGlobalProductionMensuelDTRFNational";
const { Content } = Layout;
const { TabPane } = Tabs;

const EtatsProductionGlobal = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategorie, setSelectedCategorie] = useState("");
    // const [files, setFiles] = useState([]);
    // const [selectedFile, setSelectedFile] = useState("");
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState();
    const [compagnies, setCompagnies] = useState([]);
    const [agences, setAgences] = useState([]);
    const [selectedCompagnie, setSelectedCompagnie] = useState("");
    const [selectedAgence, setSelectedAgence] = useState("");
    const [branche, setBranche] = useState("TPVM");
    const [showEmissionNetteCedee, setShowEmissionNetteCedee] = useState(false);
    const [emissions, setEmissions] = useState([]);
    const [showEtatGlobalProductionNationalGUM, setShowEtatGlobalProductionNationalGUM] = useState(false);
    const [joursEGPNGUM, setJoursEGPNGUM] = useState([]);
    const [showEtatGlobalProductionGUM, setShowEtatGlobalProductionGUM] = useState(false);
    const [joursEGPGUM, setJoursEGPGUM] = useState([]);
    // const [showEtatGlobalProductionVT, setShowEtatGlobalProductionVT] = useState(false);

    useEffect(() => {
        axiosInstance
            .get(`/api/categorie`)
            .then((res) => {
                setCategories(res.data.filter(e => !e.codeCategorie.startsWith("VT")));
            })
            .catch((err) => {
                console.log(err);
            });

        axiosInstance
            .get(`/api/organisme/assurance`)
            .then((res) => {
                setCompagnies(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axiosInstance
            .get(`/api/agence/dtrf`)
            .then((res) => {
                setAgences(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const dateFiltering = (_, dateString) => {
        setDate(dateString);
    }

    const categorieFiltering = (_, categ) => {
        setSelectedCategorie(categ.data);
    }

    const compagnieFiltering = (code, comp) => {
        setSelectedCompagnie(comp.data);
    }

    const agenceFiltering = (code, comp) => {
        setSelectedAgence(comp.data);
    }

    const typeFiles = {
        "TPVM": {
            nom: "TPV & MOTO",
            files: [
                {
                    title: "Emission nettes cédées",
                    description: "Etat des émissions nettes cédées de toutes les compagnies sur un exercice donné et par catégorie"
                },
            ]
        },
        "GUM": {
            nom: "GUM",
            files: [
                {
                    title: "Etat global de production",
                    description: "Etat global de production GUM pour tous les bureaux"
                },
                {
                    title: "Etat de production",
                    description: "Etat global de production GUM pour tous"
                }
            ]
        },
        "VT": {
            nom: "VT",
            files: [
                {
                    title: "Etat de production",
                    description: "Etat global de production VT"
                }
            ]
        }
    };

    const setupEmissionNetteCedee = (categorie, exercice) => {
        setLoading(true);
        axiosInstance
            .get(`/api/production/emissions-nettes-cedees/${categorie}/${exercice}-01`)
            .then((res) => {
                console.log("Emissions", res.data);
                setLoading(false);
                setEmissions(res.data);
                setShowEmissionNetteCedee(true);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    const setupEtatGlobalProductionNationalGUM = (exercice) => {
        setLoading(true);
        axiosInstance
            .get(`/api/production/etat-global-production-national-gum/${exercice}-01`)
            .then((res) => {
                console.log("Jours", res.data);
                setLoading(false);
                setJoursEGPNGUM(res.data);
                setShowEtatGlobalProductionNationalGUM(true);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    const setupEtatGlobalProductionGUMByAgence = (exercice, codeAgence) => {
        setLoading(true);
        axiosInstance
            .get(`/api/production/etat-global-production-gum/${exercice}-01/${codeAgence}`)
            .then((res) => {
                console.log("Jours", res.data);
                setLoading(false);
                setJoursEGPGUM(res.data);
                setShowEtatGlobalProductionGUM(true);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    return (
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                <SideBar />

                {/* <!-- Layout container --> */}
                <div className="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div className="content-wrapper">
                        {/* <!-- Content --> */}
                        <div className="container-xxl flex-grow-1 container-p-y">
                            {/* Your main content here */}
                            <div class="card bg-transparent shadow-none my-0 border-0">
                                <div class="card-body row p-0 pb-0 g-0">
                                    <div class="col-12 col-lg-8 card-separator">
                                        <h5 class="mb-2"><span class="h4">Etats de Production</span></h5>
                                    </div>
                                </div>
                            </div>

                            <div class="row g-6 mb-6">
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Filtrer suivant la Période</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <DatePicker placeholder="Sélectionner le mois" onChange={dateFiltering} picker="month" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {branche === "GUM" || branche === "VT" ? null :
                                    <div class="col-sm-6 col-xl-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="align-items-start justify-content-between">
                                                    <div class="content">
                                                        <span class="text-heading">Filtrer suivant la Catégorie</span>
                                                        <div class="align-items-center my-1">
                                                            <Select
                                                                placeholder="Sélectionner la catégorie......"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                onChange={categorieFiltering}
                                                                options={categories.map((cat) => ({
                                                                    value: cat.codeCategorie,
                                                                    label: cat.nom,
                                                                    data: cat
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                {LOGGED_USER?.profil?.organisme?.typeOrganisme === "POOL"
                                    ? branche === "TPVM" ?
                                        <div class="col-sm-6 col-xl-3">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="align-items-start justify-content-between">
                                                        <div class="content">
                                                            <span class="text-heading">Filtrer suivant la Compagnie</span>
                                                            <div class="align-items-center my-1">
                                                                <Select
                                                                    placeholder="Sélectionner la compagnie"
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    onChange={compagnieFiltering}
                                                                    options={compagnies.map((com) => ({
                                                                        value: com.code,
                                                                        label: com.raisonSociale,
                                                                        data: com
                                                                    }))}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        branche === "GUM" ?
                                            <div class="col-sm-6 col-xl-3">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="align-items-start justify-content-between">
                                                            <div class="content">
                                                                <span class="text-heading">Filtrer suivant le bureau de la DTRF</span>
                                                                <div class="align-items-center my-1">
                                                                    <Select
                                                                        placeholder="Sélectionner le bureau"
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        onChange={agenceFiltering}
                                                                        options={agences.map((com) => ({
                                                                            value: com.codeAgence,
                                                                            label: com.nom,
                                                                            data: com
                                                                        }))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    : null}
                            </div>
                            <div class="row g-6 mb-6">
                                <Content style={{ padding: "20px 50px" }}>
                                    <Tabs defaultActiveKey="TPVM" type="card" style={{ marginBottom: "20px" }} onChange={(e) => {
                                        setBranche(e);
                                        console.log(e);
                                    }} >
                                        <TabPane tab="TPV & MOTO" key="TPVM" />
                                        <TabPane tab="GUM" key="GUM" />
                                        <TabPane tab="VT" key="VT" />
                                    </Tabs>

                                    <Row gutter={[16, 16]}>
                                        {typeFiles[branche].files?.map((typeFile, index) => (
                                            <Col xs={24} sm={12} md={8} key={index}>
                                                <div class="card h-100">
                                                    <div class="card-header d-flex align-items-center justify-content-between">
                                                        <h5 class="card-title m-0 me-2">{typeFile.title}</h5>
                                                    </div>
                                                    <div class="card-body">
                                                        <p>{typeFile.description}</p>
                                                        <h6 class="fw-normal mb-3">{""}</h6>
                                                        <div class="col-12 text-center">
                                                            <button
                                                                style={{ backgroundColor: "orange" }}
                                                                type="button" class="btn w-100 d-grid"
                                                                onClick={() => {
                                                                    if (branche.startsWith("TPV")) {
                                                                        if (!selectedCategorie)
                                                                            return toast("Veuillez sélectionner une catégorie");
                                                                        if (!date)
                                                                            return toast("Veuillez sélectionner une période");
                                                                        if (selectedCategorie && date) {
                                                                            setupEmissionNetteCedee(selectedCategorie?.codeCategorie, date)
                                                                        }
                                                                    } else if (typeFile.title === "Etat global de production" && branche.startsWith("GUM")) {
                                                                        if (!date) {
                                                                            return toast("Veuillez sélectionner une période");
                                                                        } else {
                                                                            setupEtatGlobalProductionNationalGUM(date);
                                                                        }
                                                                    } else if (typeFile.title === "Etat de production" && branche.startsWith("GUM")) {
                                                                        if (!date || !selectedAgence) {
                                                                            return !date ? toast("Veuillez sélectionner une période") : toast("Veuillez sélectionner un bureau");
                                                                        } else {
                                                                            setupEtatGlobalProductionGUMByAgence(date, selectedAgence?.codeAgence);
                                                                        }
                                                                    }
                                                                }}

                                                                disabled={loading}
                                                            >
                                                                {loading && (<span
                                                                    class="spinner-grow me-1"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>)}
                                                                <span
                                                                    className="align-middle d-sm-inline-block d-none me-sm-2">
                                                                    {loading ? `Traitement...` : `Visualiser`}
                                                                </span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Content>
                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div className="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <Modal width={1900} open={showEmissionNetteCedee} onCancel={() => { setShowEmissionNetteCedee(false) }} title="Emission nettes cédée" zIndex={10000} >
                {selectedCategorie && date &&
                    <EmissionsNettesCedeesParExerciceEtCategorie
                        emissions={emissions}
                        categorie={selectedCategorie?.codeCategorie === "M" ? "MOTO" : selectedCategorie?.codeCategorie}
                        exercice={date.split("-")[0]}
                    />
                }
            </Modal>

            <Modal width={1200} style={{ marginBottom: "200px" }} open={showEtatGlobalProductionNationalGUM} onCancel={() => { setShowEtatGlobalProductionNationalGUM(false) }} title="ETAT GLOBAL DE PRODUCTION GUM" zIndex={10000} >
                {date &&
                    <EtatGlobalProductionMensuelDTRFNational
                        jours={joursEGPNGUM}
                        mois={date}
                    />
                }
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <br />
            </Modal>

            <Modal
                width="62%"
                open={showEtatGlobalProductionGUM}
                onCancel={() => { setShowEtatGlobalProductionGUM(false) }}
                title="ETAT GLOBAL DE PRODUCTION GUM" zIndex={10000}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    {date &&
                        <EtatGlobalProductionMensuelDTRFNational
                            jours={joursEGPGUM}
                            mois={date}
                            bureau={selectedAgence?.ville}
                        />
                    }
                </Space>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Modal>

            {/* <div class={` modal fade ${showEtatGlobalProductionGUM ? "show" : ""}`} id="addNewAddress" tabindex="-1" style={{ display: showEtatGlobalProductionGUM ? "block" : "none" }} aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="text-center mb-6">
                                <h4 class="address-title mb-2">Add New Address</h4>
                                <p class="address-subtitle">Add new address for express delivery</p>
                            </div>
                            {date &&
                                <EtatGlobalProductionMensuelDTRFNational
                                    jours={joursEGPGUM}
                                    mois={date}
                                    bureau={selectedAgence?.ville}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- Overlay --> */}
            <div className="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div className="drag-target"></div>
        </div>
    );
};

export default EtatsProductionGlobal;
