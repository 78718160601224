import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Footer from '../../../components/Footer'
import NavBar from '../../../components/NavBar'
import SideBar from '../../../components/SideBar'
import { API_URL, TOKEN } from '../../../variables/constants'

const AddCourtier = () => {
    const [formData, setFormData] = useState({
        nom: "",
        codeAgence: "",
        ville: "",
        typeAgence: "",
        responsable: "",
        telephone1: "",
        telephone2: "",
        active: "",
        agrements: [],
    });
    const [villes, setVilles] = useState([]);
    const [activeAgency, setActiveAgency] = useState(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        axios
            .get(`${API_URL}/api/parametre/type/VILLE`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setVilles(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"COURTIERS"} />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="container card">
                                <div class="row my-6">
                                    <div class="col">
                                        <div class="text-center mb-6">
                                            <h4 class="mb-2">
                                                Ajouter une agence de courtage
                                            </h4>
                                            <p>
                                                Renseigner les informations pour créer une nouvelle
                                                agence de courtage
                                            </p>
                                        </div>
                                        <form
                                            id="upgradePlanForm"
                                            class="row g-4"
                                            onsubmit="return false"
                                        >
                                            <div class="col-12">
                                                <label class="form-label" for="codeAgence">
                                                    Code de l'agence
                                                </label>
                                                <input
                                                    type="text"
                                                    id="codeAgence"
                                                    name="codeAgence"
                                                    class="form-control"
                                                    placeholder="Veuillez renseigner le code de l'agence"
                                                    value={formData.codeAgence}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div class="col-12">
                                                <label class="form-label" for="nomAgence">
                                                    Nom de l'agence{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="nomAgence"
                                                    name="nomAgence"
                                                    class="form-control"
                                                    placeholder="Veuillez renseigner le nom de l'agence"
                                                    value={formData.nom}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <label class="form-label" for="villeAgence">
                                                    Ville{" "}
                                                </label>
                                                <select
                                                    id="villeAgence"
                                                    name="villeAgence"
                                                    class="form-control form-select"
                                                    value={formData.ville}
                                                    placeholder="Sélectionnez la ville de l'agence"
                                                    onChange={handleInputChange}
                                                >
                                                    {villes?.map((vil, id) => (
                                                        <option key={id} value={vil.libelleParametre}>
                                                            {vil.libelleParametre}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div class="col-12 col-md-6">
                                                <label class="form-label" for="typeAgence">
                                                    Type agence
                                                </label>
                                                <select
                                                    id="typeAgence"
                                                    name="typeAgence"
                                                    class="form-control form-select"
                                                    value={formData.typeAgence}
                                                    placeholder="Sélectionnez le type d'agence"
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="COURTIER">COURTIER</option>
                                                    <option value="AGENCE_GENERALE">
                                                        AGENCE GENERALE
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-12">
                                                <label class="form-label" for="responsableAgence">
                                                    Nom du responsable{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="responsableAgence"
                                                    name="responsableAgence"
                                                    class="form-control"
                                                    placeholder="Veuillez renseigner le nom du responsable"
                                                    value={formData.responsable}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <label class="form-label" for="telephone1Agence">
                                                    Numéro de téléphone 1
                                                </label>
                                                <div class="input-group">
                                                    <span class="input-group-text">TG (+228)</span>
                                                    <input
                                                        type="tel"
                                                        id="telephone1Agence"
                                                        name="telephone1Agence"
                                                        class="form-control phone-number-mask"
                                                        placeholder="00 00 00 00"
                                                        value={formData.telephone1}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <label class="form-label" for="telephone2Agence">
                                                    Numéro de téléphone 2
                                                </label>
                                                <div class="input-group">
                                                    <span class="input-group-text">TG (+228)</span>
                                                    <input
                                                        type="tel"
                                                        id="telephone2Agence"
                                                        name="telephone2Agence"
                                                        class="form-control phone-number-mask"
                                                        placeholder="00 00 00 00"
                                                        value={formData.telephone2}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-check form-switch my-2 ms-2">
                                                    {activeAgency ? (
                                                        <input
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            id="activeAgency"
                                                            checked
                                                            onChange={() => setActiveAgency(false)}
                                                        />
                                                    ) : (
                                                        <input
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            id="activeAgency"
                                                            onChange={() => setActiveAgency(true)}
                                                        />
                                                    )}
                                                    <label for="activeAgency" class="switch-label">
                                                        Actif?
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center">
                                                <button
                                                    onClick={(e) => handleSubmit(e)}
                                                    class="btn btn-primary me-3"
                                                >
                                                    Enregistrer
                                                </button>
                                                <button
                                                    type="reset"
                                                    class="btn btn-label-secondary"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    )
}

export default AddCourtier