import { CreditCardFilled, ExclamationCircleFilled, FilePdfFilled, SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Input, Modal, Space, Spin, Table, Tag } from 'antd';
import frFR from "antd/locale/fr_FR";
import axios from 'axios';
import React, { createRef, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import { rightsService } from '../../services/rightsService';
import { API_URL, TOKEN } from '../../variables/constants';
import { frenchDate, frenchDateSimple } from '../../variables/functions';
import InvoiceCommissionPayment from '../production/components/InvoiceCommissionPayment';
import MultipleInvoicesCommissions from '../production/components/MultipleInvoicesCommissions';
import EditIntermediaire from './EditIntermediaire';
import SetupIntermediaireUserAccount from './SetupIntermediaireUserAccount';
const { confirm } = Modal;

const DetailsIntermediaire = () => {
    const [intermediaire, setIntermediaire] = useState("");
    const [loading, setLoading] = useState(false);
    const [contrats, setContrats] = useState([]);
    const [loadingContrats, setLoadingContrats] = useState(true);
    const [tauxCommission, setTauxCommission] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [userExists, setUserExists] = useState(false);
    const [intermediaireUserAccount, setIntermediaireUserAccount] = useState("");
    const [profils, setProfils] = useState([]);
    const [commissions, setCommissions] = useState([]);
    const [sumCommission, setSumCommission] = useState(0);
    const [validatedContrats, setValidatedContrats] = useState([]);
    const [editTauxCommissionOpenModal, setEditTauxCommissionOpenModal] = useState(false);
    const [editedTauxCommission, setEditedTauxCommission] = useState(0);
    const [tauxCommissions, setTauxCommissions] = useState([]);

    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [selectedContrat, setSelectedContrat] = useState();
    const [selectedContrats, setSelectedContrats] = useState([]);
    const [selecting, setSelecting] = useState(false);
    const [modalModePaiement, setModalModePaiement] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [hiddenMultpleInvoices, setHiddenMultipleInvoices] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [tcModalOpen, setTCModalOpen] = useState(false);

    const navigate = useNavigate();
    const param = useParams();
    const printInvoiceCommissionRef = createRef();
    const printMultipleInvoicesRef = createRef();

    const handleEditShow = () => {
        setShowEditModal(true);
    }

    const handleShow = () => {
        setShowModal(true);
    }

    const handleEditClose = () => setShowEditModal(false);
    const handleClose = () => setShowModal(false);

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        if (param.codeIntermediaire) {

            var ctrs = [];
            axios
                .get(`${API_URL}/api/intermediaire/codeIntermediaire/${param.codeIntermediaire}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    //   console.log(res.data);
                    axios
                        .get(`${API_URL}/api/profil/organisme/codeOrganisme/${res.data?.agence?.organisme?.code}`, {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        })
                        .then((resp) => {
                            console.log("Response data: ", resp.data);
                            setProfils(resp.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    setIntermediaire(res.data);

                    axios
                        .get(`${API_URL}/api/utilisateur/check/${res.data.personne.email}`, {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        })
                        .then((response) => {
                            setUserExists(response.data);
                            if (response.data) {
                                getIntermediaireUserAccount(res.data.personne.email);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });

            axios
                .get(`${API_URL}/api/contrat/intermediaire/codeIntermediaire/${param.codeIntermediaire}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    //   console.log(res.data);
                    let vCtrs = [];
                    let coms = [];
                    let comKeys = [];
                    let valueCommission = 0;
                    res.data.map((contrat, key) => {
                        if (contrat.reglements && contrat.reglements.length) {
                            contrat.reglements.forEach((reg) => {
                                reg.montant < 0 && !reg.commissionPaye && comKeys.push(reg.codeId);
                                coms.push({
                                    key: reg.codeId,
                                    codeId: reg.codeId,
                                    id: reg.codeId,
                                    numeroPolice: contrat.numeroPoliceP,
                                    souscripteur: contrat?.souscripteur?.prenom
                                        ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
                                        : `${contrat?.souscripteur?.nom}`,
                                    organisme: contrat.organisme.raisonSociale,
                                    dateEffet: contrat?.dateEffet
                                        ? `${frenchDate(contrat?.dateEffet)}`
                                        : "",
                                    dateEcheance: contrat?.dateEcheance
                                        ? `${frenchDateSimple(contrat?.dateEcheance)} `
                                        : "",
                                    regle: reg.dateReglement
                                        ? "REGLE"
                                        : "NON REGLE",
                                    data: reg,
                                    contrat: contrat
                                });
                                valueCommission += reg.commission ? reg.commission : 0;
                            })
                        }

                        contrat.statutContrat === "VALIDE" && vCtrs.push(contrat);

                        ctrs.push({
                            key: contrat.codeId,
                            codeId: contrat.codeId,
                            id: key,
                            numeroPolice: contrat.numeroPoliceP,
                            souscripteur: contrat?.souscripteur?.prenom
                                ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
                                : `${contrat?.souscripteur?.nom}`,
                            organisme: contrat.organisme.raisonSociale,
                            dateEffet: contrat?.dateEffet
                                ? `${frenchDate(contrat?.dateEffet)}`
                                : "",
                            dateEcheance: contrat?.dateEcheance
                                ? `${frenchDateSimple(contrat?.dateEcheance)} `
                                : "",
                            regle: contrat.reglements
                                ? contrat.reglements.length
                                    ? "REGLE"
                                    : "NON REGLE"
                                : "NON REGLE",
                            data: contrat,
                        });
                        // console.log("CTRS: ", ctrs);
                    });

                    let _coms = coms.filter(e => e.data.montant < 0 && !e.data.commissionPaye);
                    console.log("____Commmmss:", _coms);
                    setSelectedContrats(_coms);
                    setSelectedRows(_coms);
                    setSelectedRowKeys(comKeys);
                    setContrats(ctrs);
                    setValidatedContrats(vCtrs);
                    setCommissions(coms);
                    setSumCommission(valueCommission);
                    setLoadingContrats(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("Res.data: ", res.data.filter(e => !e.codeCategorie.startsWith("VT")));
                let tcs = [];
                res.data.filter(e => !e.codeCategorie.startsWith("VT")).forEach((cat) => {
                    tcs.push({
                        taux: 0,
                        categorie: cat.nom,
                        codeCategorie: cat.codeCategorie
                    });
                })
                setTauxCommissions(tcs);
                setCategories(res.data.filter(e => !e.codeCategorie.startsWith("VT")));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [TOKEN, param.codeIntermediaire, reload, showModal, showEditModal]);

    const getIntermediaireUserAccount = (email) => {
        axios
            .get(`${API_URL}/api/utilisateur/session/${email}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((resp) => {
                setIntermediaireUserAccount(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const onSelectChange = (newSelectedRowKeys, rows) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        console.log("SelectedRows", rows);
        setSelecting(true);
        setSelectedRows(rows);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Rechercher ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Rechercher...
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filtrer
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "N° Police",
            dataIndex: "numeroPolice",
            key: "1",
            ...getColumnSearchProps("numeroPolice"),
        },
        {
            title: "Souscripteur",
            dataIndex: "souscripteur",
            key: "2",
            ...getColumnSearchProps("souscripteur"),
        },
        {
            title: "Date Effet",
            dataIndex: "dateEffet",
            key: "4",
        },
        {
            title: "Date Echeance",
            dataIndex: "dateEcheance",
            key: "5",
        },
        {
            title: "Statut reglement",
            dataIndex: "regle",
            key: "regle",
            render: (_, record) => (
                <>
                    {record.regle === "REGLE" ? (
                        <Tag color="success" key={_}>
                            REGLE
                        </Tag>
                    ) : (
                        <Tag color="blue" key={_}>
                            NON REGLE
                        </Tag>
                    )}
                </>
            ),
            filters: [
                {
                    text: "REGLE",
                    value: "REGLE",
                },
                {
                    text: "NON REGLE",
                    value: "NON REGLE",
                },
            ],
            filteredValue: filteredInfo.regle || null,
            onFilter: (value, record) => record.regle === value,
            sorter: (a, b) => a.regle.length - b.regle.length,
            sortOrder: sortedInfo.columnKey === "regle" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "7",
            render: (_, record) => (
                <>
                    {record.regle !== "REGLE" && (
                        <button
                            type="button"
                            class="btn btn-success btn-icon rounded-pill"
                            onClick={() => { }}
                        >
                            <i class="bx bxs-credit-card"></i>
                        </button>
                    )}
                    <button
                        type="button"
                        class="btn btn-primary btn-icon rounded-pill"
                        onClick={() => navigate(`/details-contrat/${record.codeId}`)}
                    >
                        <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <button type="button" class="btn btn-danger btn-icon rounded-pill">
                        <i class="bx bxs-file-pdf"></i>
                    </button>
                </>
            ),
        },
    ];

    const commissionColumns = [
        {
            title: "N° Police",
            dataIndex: "numeroPolice",
            key: "1",
        },
        {
            title: "Souscripteur",
            dataIndex: "souscripteur",
            key: "2",
        },
        {
            title: "Montant Prime",
            dataIndex: "primeNette",
            key: "4",
            render: (_, record) => (
                <>
                    {record.data.montant ? new Intl.NumberFormat("en-DE").format(
                        record.data.montant
                    ) : 0}
                </>
            )
        },
        {
            title: "Commission",
            dataIndex: "commission",
            key: "5",
            render: (_, record) => (
                <>
                    {record.data.commission ? new Intl.NumberFormat("en-DE").format(
                        record.data.commission
                    ) : 0}
                </>
            )
        },
        {
            title: "Statut reglement",
            dataIndex: "regle",
            key: "regle",
            render: (_, record) => (
                <>
                    {record.data.commissionPaye ? (
                        <Tag color="success" key={_}>
                            REGLE
                        </Tag>
                    ) : (
                        <Tag color="blue" key={_}>
                            NON REGLE
                        </Tag>
                    )}
                </>
            ),
            filters: [
                {
                    text: "REGLE",
                    value: "REGLE",
                },
                {
                    text: "NON REGLE",
                    value: "NON REGLE",
                },
            ],
            filteredValue: filteredInfo.regle || null,
            onFilter: (value, record) => record.regle === value,
            sorter: (a, b) => a.regle.length - b.regle.length,
            sortOrder: sortedInfo.columnKey === "regle" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "7",
            render: (_, record) => (
                <>
                    {!record.data.commissionPaye && rightsService("HLA045") ? (
                        <>
                            <button
                                type="button"
                                class="btn btn-success btn-icon rounded-pill"
                                onClick={() => { showCommissionPaymentConfirm(record.data.codeId) }}
                            >
                                <i class="bx bxs-credit-card"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary btn-icon rounded-pill"
                                onClick={() => {
                                    setSelectedContrat(record.contrat);
                                    setEditedTauxCommission(record.data.tauxCommission);
                                    setEditTauxCommissionOpenModal(true);
                                }}
                            >
                                <i class="bx bxs-edit"></i>
                            </button>
                        </>
                    ) : rightsService("HLA046") && <button
                        type="button"
                        class="btn btn-danger btn-icon rounded-pill"
                        onClick={() => { exportInvoiceCommissionPaymentToPdf(record) }}
                    >
                        <i class="bx bxs-file-pdf"></i>
                    </button>}
                </>
            ),
        },
    ];

    const activateIntermediaireConfirm = () => {
        confirm({
            title: `Etes-vous sûr de vouloir ${intermediaire.active ? "désactiver" : "activer"} cet intermédiaire?`,
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées!",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                activateIntermediaire();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const activateIntermediaire = () => {
        axios
            .get(`${API_URL}/api/intermediaire/activity/${intermediaire.codeIntermediaire}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            }).then((res) => {
                console.log(res.data);
                intermediaire.codeIntermediaire ? toast("Intermédiaire désactivé avec succès!") : toast("Intermédiaire activé avec succès!");
                setReload(true);
            }).catch(err => {
                console.log(err);
                intermediaire.codeIntermediaire ? toast("Erreur lors de la désactivation de l'intermédiaire") : toast("Erreur lors de l'activation de l'intermédiaire");
            });
    }

    const encaisserContrats = (cts) => {
        setModalModePaiement(true);
        // setContractsToPay(cts);
    };

    const exportInvoiceCommissionPaymentToPdf = (contrat) => {
        console.log("The entire: ", contrat);
        setSelectedContrat(contrat);
        setHidden(true);
    };

    const exportToPdfs = (contrats) => {
        if (contrats.length === 1) {
            exportInvoiceCommissionPaymentToPdf(contrats[0]);
        } else {
            setSelectedContrats(contrats);
            // console.log("Contracts: ", contrats);
            setHiddenMultipleInvoices(true);
            handleMultipleInvoicesPrint();
        }
    };

    const handlePrint = useReactToPrint({
        content: () => printInvoiceCommissionRef.current,
    });

    const handleMultipleInvoicesPrint = useReactToPrint({
        content: () => printMultipleInvoicesRef.current,
    });

    const MyFooter = () => {
        let ctrt = selectedRows.filter((ctr) => ctr.data.commissionPaye);
        let nCtrt = selectedRows.filter((ctr) => !ctr.data.commissionPaye);
        console.log("R: ", ctrt);
        // console.log("NR: ", nrCtrts);
        return (
            <div class="row">
                {!ctrt ? (
                    <div class="col-sm-4">
                        <Button
                            onClick={() => {
                                encaisserContrats(nCtrt);
                            }}
                            color="success"
                            icon={<CreditCardFilled />}
                            style={{
                                color: "red",
                            }}
                            title="Encaissement"
                        >
                            Encaisser
                        </Button>
                    </div>
                ) : (
                    <>
                        <div class="col-sm-4">
                            <Button
                                onClick={() => {
                                    exportToPdfs(ctrt);
                                }}
                                color="primary"
                                style={{ color: "green" }}
                                icon={<FilePdfFilled />}
                            >
                                Imprimer le reçu
                            </Button>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const showCommissionPaymentConfirm = (contratId) => {
        confirm({
            title: "Etes-vous sûr de vouloir payer cette commission?",
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                commissionPayment(contratId);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const commissionPayment = (contratId) => {
        axios
            .get(`${API_URL}/api/reglement/commission-payment/${contratId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (hidden) {
            handlePrint();
        }
        if (hiddenMultpleInvoices) {
            handleMultipleInvoicesPrint();
        }
    }, [hidden, hiddenMultpleInvoices]);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("TCS: ", JSON.stringify(tauxCommissions));

        // if (tauxCommission) {
        setLoading(true);
        // console.log("MyAgreement: " + JSON.parse(sessionStorage.getItem("myAgreement")));
        // console.log("MyAgreementId: ", myAgreementId);
        // myAgreement.tauxCommission = tauxCommission;
        // console.log("Taux commission" + tauxCommission);
        axios
            .get(`${API_URL}/api/intermediaire/update-tc/${intermediaire.codeIntermediaire}/${selectedCategory.codeCategorie}/${tauxCommission}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // setCourtier(res.data);
                setLoading(false);
                toast("Taux de commission défini avec succès!");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
        // } else {
        //     toast("Veuillez renseigner le taux s'il vous plait!");
        // }
    }

    const onSubmitEditTauxCommission = (e) => {
        e.preventDefault();

        // if (tauxCommission) {
        setLoading(true);
        axios
            .get(`${API_URL}/api/contrat/update-taux-commission/${selectedContrat.codeId}/${editedTauxCommission}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setLoading(false);
                toast("Taux de commission redéfini avec succès!");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"MEMBRES"} />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row">
                                {/* <!-- User Sidebar --> */}
                                <div class="col-xl-4 col-lg-5 order-1 order-md-0">
                                    {/* <!-- User Card --> */}
                                    <div class="card mb-6">
                                        <div class="card-body pt-12">
                                            <div class="user-avatar-section">
                                                <div class=" d-flex align-items-center flex-column">
                                                    {intermediaire?.personne?.photo_url ? (
                                                        <img
                                                            class="img-fluid rounded mb-4"
                                                            src="../../assets/img/avatars/1.png"
                                                            height="120"
                                                            width="120"
                                                            alt="User avatar"
                                                        />
                                                    ) : (
                                                        <div class="avatar avatar-lg me-2">
                                                            <span class="avatar-initial rounded-circle bg-label-secondary">
                                                                {intermediaire?.personne?.prenom?.charAt(0)}
                                                                {intermediaire?.personne?.nom?.charAt(0)}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div class="user-info text-center">
                                                        <h5>
                                                            {intermediaire?.personne?.prenom}{" "}
                                                            {intermediaire?.personne?.nom}
                                                        </h5>
                                                        <span class="badge bg-label-secondary">
                                                            {intermediaire?.profil?.nom}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-around flex-wrap mb-6 gap-0 gap-md-3 gap-lg-4">
                                                <div class="d-flex align-items-center gap-4 me-5">
                                                    <div class="avatar">
                                                        <div class="avatar-initial rounded bg-label-primary">
                                                            <i class="bx bx-user bx-lg"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5 class="mb-0">{validatedContrats.length}</h5>
                                                        <span>Affaires</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center gap-4">
                                                    <div class="avatar">
                                                        <div class="avatar-initial rounded bg-label-primary">
                                                            <i class="bx bx-dollar bx-lg"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5 class="mb-0">{new Intl.NumberFormat("en-DE").format(
                                                            sumCommission
                                                        )} FCFA</h5>
                                                        <span>Commissions</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="pb-4 border-bottom mb-4">Détails</h5>
                                            <div class="info-container">
                                                <ul class="list-unstyled mb-6">
                                                    <li class="mb-2">
                                                        <span class="h6">Email: </span>
                                                        <span>{intermediaire?.personne?.email}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Statut: </span>
                                                        <span>
                                                            {intermediaire?.active ? "Actif" : "Inactif"}
                                                        </span>
                                                    </li>
                                                    {userExists ? <li class="mb-2">
                                                        <span class="h6">Profil: </span>
                                                        <span>{intermediaireUserAccount?.profil?.nom}</span>
                                                    </li> : null}
                                                    <li class="mb-2">
                                                        <span class="h6">Contact: </span>
                                                        <span>
                                                            (+228){" "}
                                                            {intermediaire?.personne?.contact
                                                                ? intermediaire?.personne?.contact[0]
                                                                : "-"}
                                                        </span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Langages: </span>
                                                        <span>Français</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Pays: </span>
                                                        <span>Togo</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">
                                                            Taux de commission:
                                                        </span>
                                                        <span>

                                                            <ul class="mb-2">
                                                                {intermediaire?.tauxCommission ? intermediaire?.tauxCommission?.map(tc => (
                                                                    <li class="mb-2">
                                                                        {tc.categorie.nom} : <span class="h6 me-1">{tc.taux} %</span>
                                                                    </li>
                                                                )) : "Non défini"}
                                                            </ul>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div class="d-flex justify-content-center">
                                                    <a
                                                        href="javascript:;"
                                                        class="btn btn-lg btn-primary me-4"
                                                        onClick={() => handleEditShow()}
                                                    >
                                                        Editer
                                                    </a>
                                                    <a href="javascript:;" class="btn btn-lg btn-warning me-4"
                                                        onClick={() => activateIntermediaireConfirm()}>
                                                        {intermediaire?.active ? "Suspendre" : "Activer"}
                                                    </a>
                                                </div>
                                                <br />
                                                {userExists ? null : <div class="d-flex justify-content-center">
                                                    <a
                                                        href="javascript:;"
                                                        class="btn btn-info me-4"
                                                        onClick={() => { handleShow(); }}
                                                    >
                                                        Associer un compte utilisateur
                                                    </a>
                                                </div>}
                                                <br />
                                                {categories.map((cat, id) => (
                                                    <div className="d-flex justify-content-center" >
                                                        <Button type="primary" style={{ background: "gray", }}
                                                            onClick={() => {
                                                                setSelectedCategory(cat);
                                                                setTCModalOpen(true);
                                                            }}
                                                            key={id}
                                                        >
                                                            Définir le taux de commission - {cat.nom}
                                                        </Button>
                                                        <br />
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- /Plan Card --> */}
                                </div>
                                {/* <!--/ User Sidebar --> */}

                                {/* <!-- User Content --> */}
                                <div class="col-xl-8 col-lg-7 order-0 order-md-1">
                                    {/* <!-- User Pills --> */}
                                    <div class="nav-align-top">
                                        <ul class="nav nav-pills flex-column flex-md-row mb-6">
                                            <li class="nav-item">
                                                <a class={activeTab === 0 ? "nav-link active" : "nav-link"} href="#"
                                                    onClick={() => handleTabChange(0)}
                                                >
                                                    <i class="bx bx-file bx-sm me-1_5"></i>Contrats
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class={activeTab === 1 ? "nav-link active" : "nav-link"} href="#"
                                                    onClick={() => handleTabChange(1)}
                                                >
                                                    <i class="bx bx-credit-card bx-sm me-1_5"></i>Commissions
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={activeTab === 2 ? "nav-link active" : "nav-link"}
                                                    href="#"
                                                    onClick={() => handleTabChange(2)}
                                                >
                                                    <i class="bx bx-bell bx-sm me-1_5"></i>Notifications
                                                </a>
                                            </li>
                                        </ul>
                                    </div>


                                    {/* <!-- Invoice table --> */}
                                    {activeTab === 0 &&
                                        <div class="card mb-4">
                                            <div class="card-datatable table-responsive">
                                                <ConfigProvider locale={frFR}>
                                                    <Spin spinning={loadingContrats}>
                                                        <Table
                                                            columns={columns}
                                                            dataSource={contrats}
                                                            onChange={handleChange}
                                                        />
                                                    </Spin>
                                                </ConfigProvider>
                                            </div>
                                        </div>
                                    }
                                    {/* <!-- /Invoice table --> */}
                                    {activeTab === 1 && (
                                        <>
                                            <div class="card mb-4">
                                                <div class="card-datatable table-responsive">
                                                    <ConfigProvider locale={frFR}>
                                                        <Spin spinning={loadingContrats}>
                                                            {selecting ?
                                                                <Table
                                                                    rowSelection={rowSelection}
                                                                    columns={commissionColumns}
                                                                    dataSource={commissions}
                                                                    onChange={handleChange}
                                                                    footer={() => <MyFooter />}
                                                                />
                                                                :
                                                                <Table
                                                                    rowSelection={rowSelection}
                                                                    columns={commissionColumns}
                                                                    dataSource={commissions}
                                                                    onChange={handleChange}
                                                                />}
                                                        </Spin>
                                                    </ConfigProvider>
                                                </div>
                                            </div>
                                            {hidden &&
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "135vh", margin: 0, padding: 0 }}>
                                                    <InvoiceCommissionPayment ref={printInvoiceCommissionRef} contrat={selectedContrat} />
                                                </div>
                                            }
                                            {hiddenMultpleInvoices && <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "205vh", margin: 0, padding: 0 }}>
                                                <MultipleInvoicesCommissions ref={printMultipleInvoicesRef} contrats={selectedContrats} />
                                            </div>}
                                        </>
                                    )}
                                </div>
                                {/* <!--/ User Content --> */}
                            </div>

                            {/* <!-- /Modal --> */}
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <EditIntermediaire
                show={showEditModal}
                handleClose={handleEditClose}
                intermediaire={intermediaire}
                agence={intermediaire?.agence}
            />
            <SetupIntermediaireUserAccount
                show={showModal}
                handleClose={handleClose}
                intermediaire={intermediaire}
                profils={profils}
            />
            <Modal open={tcModalOpen} loading={loading} onOk={onSubmit} onCancel={handleClose} zIndex={1900} centered>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Taux de commission </h4>
                    <p className="address-subtitle">
                        Définir le taux de commission pour <b>{intermediaire?.personne?.nom}</b>
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="tauxCommission">
                                    Veuillez entre la valeur du taux pour la catégorie <b>{selectedCategory.nom}</b>
                                </label>
                                <input className="form-control" id="tauxCommission" name="tauxCommission"
                                    onChange={(e) => {
                                        setTauxCommission(parseFloat(e.target.value));
                                    }}
                                    placeholder="Entrez le taux de commission en %" type="number"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal open={editTauxCommissionOpenModal} loading={loading} onOk={onSubmitEditTauxCommission} onCancel={() => setEditTauxCommissionOpenModal(false)} zIndex={1900} centered>

                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Taux de commission </h4>
                    <p className="address-subtitle">
                        Recaclculer le taux de commission pour le contrat <b>{selectedContrat?.numeroPoliceP}</b>
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmitEditTauxCommission}>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="tauxCommission">
                                    Veuillez entre la valeur du taux de commission à considérer
                                </label>
                                <input className="form-control" id="tauxCommission" name="tauxCommission" value={editedTauxCommission}
                                    onChange={(e) => {
                                        setEditedTauxCommission(parseFloat(e.target.value));
                                    }}
                                    placeholder="Entrez le taux de commission en %" type="number"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    )
}

export default DetailsIntermediaire