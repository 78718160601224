import {
  CalendarOutlined,
  CreditCardFilled,
  ExclamationCircleFilled,
  FilePdfFilled,
  FileProtectOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { Button, ConfigProvider, DatePicker, Form, Input, Modal, Select, Slider, Space, Spin, Switch, Table, Tag } from "antd";
import frFR from "antd/locale/fr_FR";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { rightsService } from "../../../services/rightsService";
import { API_URL, LOGGED_USER, TOKEN } from "../../../variables/constants";
import { frenchDate, frenchDateSimple } from "../../../variables/functions";
import AttestationMoto from "../components/AttestationMoto";
import AttestationMotoToPrint from "../components/AttestationMotoToPrint";
import DTRFFormat from "../components/DTRFFormat";
import ExpandedRowContent from "../components/ExpandedRowContent";
const { confirm } = Modal;
const { Option } = Select;

const Contrats = () => {
  const [contrats, setContrats] = useState([]);
  const [canceledContract, setCanceledContract] = useState(0);
  const [activeContract, setActiveContract] = useState(0);
  const [devis, setDevis] = useState(0);
  const [data, setData] = useState([]);
  const [selecting, setSelecting] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [selectedContrat, setSelectedContrat] = useState();
  const [selectedContrats, setSelectedContrats] = useState([]);
  const [loadingContrats, setLoadingContrats] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [numAttestationModal, setNumAttestationModal] = useState(false);
  const [printingAttestation, setPrintingAttestation] = useState(false);
  const [numeroAttestations, setNumeroAttestations] = useState([]);
  const [modalModePaiement, setModalModePaiement] = useState(false);
  const [numeroPaiement, setNumeroPaiement] = useState("P");
  const [modePaiement, setModePaiement] = useState("");
  const [contractsToPay, setContractsToPay] = useState([]);
  const [contractToPay, setContractToPay] = useState();
  const [modePaiementForm] = Form.useForm();
  const [banques, setBanques] = useState([]);
  const [banque, setBanque] = useState("BANQUE");
  const [currentContractIndex, setCurrentContractIndex] = useState(0);

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const printRef = useRef();
  const printAttestationRef = useRef();
  const printedAttestationRef = useRef();

  const navigate = useNavigate();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const attestationRefs = useRef(selectedContrats.map(() => React.createRef()));
  const printedAttestationRefs = useRef(selectedContrats.map(() => React.createRef()));

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Rechercher...
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Numero de police",
      dataIndex: "numeroPolice",
      key: "1",
      ...getColumnSearchProps("numeroPolice"),
    },
    {
      title: "Souscripteur",
      dataIndex: "souscripteur",
      key: "2",
      ...getColumnSearchProps("souscripteur"),
    },
    {
      title: "Organisme",
      dataIndex: "organisme",
      key: "3",
      ...getColumnSearchProps("organisme"),
    },
    {
      title: "Date Effet",
      dataIndex: "dateEffet",
      key: "4",
    },
    {
      title: "Date Echeance",
      dataIndex: "dateEcheance",
      key: "5",
    },
    {
      title: "Statut reglement",
      dataIndex: "regle",
      key: "regle",
      render: (_, record) => (
        <>
          {record.regle === "REGLE" ? (
            <Tag color="success" key={_}>
              REGLE
            </Tag>
          ) : (
            <Tag color="blue" key={_}>
              NON REGLE
            </Tag>
          )}
        </>
      ),
      filters: [
        {
          text: "REGLE",
          value: "REGLE",
        },
        {
          text: "NON REGLE",
          value: "NON REGLE",
        },
      ],
      filteredValue: filteredInfo.regle || null,
      onFilter: (value, record) => record.regle === value,
      sorter: (a, b) => a.regle.length - b.regle.length,
      sortOrder: sortedInfo.columnKey === "regle" ? sortedInfo.order : null,
      ellipsis: true,
    },
    Table.EXPAND_COLUMN,
    {
      title: "Actions",
      dataIndex: "actions",
      key: "7",
      render: (_, record) => (
        <>
          {rightsService("HLA037") && record.numeroPolice && record.regle !== "REGLE" && (
            <button
              type="button"
              class="btn btn-success btn-icon rounded-pill"
              onClick={() => encaisserContrat(record.codeId)}
            >
              <i class="bx bxs-credit-card"></i>
            </button>
          )}
          <button
            type="button"
            class="btn btn-primary btn-icon rounded-pill"
            onClick={() => navigate(`/details-contrat/${record.codeId}`)}
          >
            <i class="bx bx-dots-vertical-rounded"></i>
          </button>
          {rightsService("HCO006") && record.numeroPolice &&
            <button type="button" class="btn btn-danger btn-icon rounded-pill">
              <i class="bx bxs-file-pdf"></i>
            </button>}
        </>
      ),
    },
  ];

  const MyFooter = () => {
    let ctrt = selectedRows.find((ctr) => ctr.regle === "NON REGLE");
    let rCtrts = selectedRows.filter((ctr) => ctr.regle === "REGLE");
    let nrCtrts = selectedRows.filter((ctr) => ctr.regle === "NON REGLE");
    // console.log("R: ", rCtrts);
    // console.log("NR: ", nrCtrts);
    return (
      <div class="row">
        {ctrt ? (
          <div class="col-sm-4">
            <Button
              onClick={() => {
                encaisserContrats(nrCtrts);
              }}
              color="success"
              icon={<CreditCardFilled />}
              style={{
                color: "red",
              }}
              title="Encaissement"
            >
              Encaisser
            </Button>
          </div>
        ) : (
          <>
            <div class="col-sm-4">
              <Button
                onClick={() => {
                  exportToPdfs(rCtrts);
                }}
                color="primary"
                style={{ color: "green" }}
                icon={<FilePdfFilled />}
              >
                Imprimer C.P.
              </Button>
            </div>
            <div class="col-sm-4">
              <Button
                onClick={() => {
                  exportAttestations(rCtrts);
                }}
                color="primary"
                style={{ color: "blue" }}
                icon={<FileProtectOutlined />}
              >
                Imprimer Attestation
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const dateFiltering = (date) => {
    // console.log("The dates are: ", date, dateString);
    // dateString = null;
    // console.log("The date I: ", date.toISOString());
    var datas = date
      ? [...contrats].filter(
        (ctrt) =>
          ctrt.createdAt.split("T")[0] === date.toISOString().split("T")[0]
      )
      : contrats;
    // console.log("Datas: ", datas);

    var ctrs = [];
    datas.map((contrat, key) => {
      ctrs.push({
        key: contrat.codeId,
        id: key,
        numeroPolice: contrat.numeroPoliceP,
        souscripteur: contrat?.souscripteur?.prenom
          ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
          : `${contrat?.souscripteur?.nom}`,
        organisme: contrat.organisme.raisonSociale,
        dateEffet: contrat?.dateEffet
          ? `${frenchDate(contrat?.dateEffet)}`
          : ``,
        dateEcheance: contrat?.dateEcheance
          ? `${frenchDate(contrat?.dateEcheance)}`
          : "",
        regle: contrat.reglements
          ? contrat.reglements.length
            ? "REGLE"
            : "NON REGLE"
          : "NON REGLE",
        data: contrat,
        codeId: contrat.codeId,
      });
    });
    setData(ctrs);
  };

  const encaisserContrats = (cts) => {
    setModalModePaiement(true);
    setContractsToPay(cts);
  };

  const encaisserContrat = (id) => {
    setModalModePaiement(true);
    setContractToPay(id);
  };

  useEffect(() => {
    var ctrs = [];
    setContrats(contrats);
    axios
      .get(`${API_URL}/api/contrat/non-printed`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setContrats(res.data);
        console.log("contrats: ", res.data);
        res.data.map((contrat, key) => {
          ctrs.push({
            key: contrat.codeId,
            codeId: contrat.codeId,
            id: key,
            numeroPolice: contrat.numeroPoliceP,
            souscripteur: contrat?.souscripteur?.prenom
              ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
              : `${contrat?.souscripteur?.nom}`,
            organisme: contrat.organisme.raisonSociale,
            dateEffet: contrat?.dateEffet
              ? `${frenchDate(contrat?.dateEffet)}`
              : "",
            dateEcheance: contrat?.dateEcheance
              ? `${frenchDateSimple(contrat?.dateEcheance)} `
              : "",
            regle: contrat.reglements
              ? contrat.reglements.length
                ? "REGLE"
                : "NON REGLE"
              : "NON REGLE",
            data: contrat,
          });
          // console.log("CTRS: ", ctrs);
        });
        let contratDevis = [];
        contratDevis = res.data.filter(
          (c) => c.statutContrat === "EN_PROPOSITION"
        );
        setDevis(contratDevis.length);
        let contratCanceled = [];
        contratCanceled = res.data.filter((c) => c.statutContrat === "ANNULE");
        setCanceledContract(contratCanceled.length);
        setActiveContract(
          res.data.length - contratDevis.length - contratCanceled.length
        );
        ctrs.sort().reverse();
        setData(ctrs);
        setLoadingContrats(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${API_URL}/api/parametre/type/BANQUE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setBanques(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [TOKEN, loading]);

  const alertReglement = (contractToPay) => {
    // console.log(numeroPaiement);
    confirm({
      title: "Etes-vous sûr de vouloir régler ce contrat?",
      icon: <ExclamationCircleFilled />,
      content: "Toutes vos actions sont historisées!",
      okText: "Oui régler",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        console.log("OK");
        reglementContrat(contractToPay);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const reglementContrat = (id) => {
    if (modePaiement === "ESPECES") {
      axios
        .get(
          `${API_URL}/api/reglement/especes/contrat/codeId/${id}/${LOGGED_USER.codeId}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((res) => {
          window.location.reload();
          toast("Reglement effectué avec succès!");
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast("Erreur lors du règlement");
        });
    } else if (modePaiement === "BANQUE" || modePaiement === "MOBILE_MONEY") {
      axios
        .get(
          `${API_URL}/api/reglement/contrat/codeId/${id}/${LOGGED_USER.codeId}/${modePaiement}/${numeroPaiement}/${banque}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((res) => {
          window.location.reload();
          toast("Règlement effectué avec succès!");
          // console.log(res.data);
          res.data = null;
        })
        .catch((err) => {
          console.log(err);
          toast("Erreur lors du règlement");
        });
    }
  };

  const alertReglements = (cts) => {
    // console.log(numeroPaiement);
    confirm({
      title: "Etes-vous sûr de vouloir régler ces contrats?",
      icon: <ExclamationCircleFilled />,
      content: "Toutes vos actions sont historisées!",
      okText: "Oui régler",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        console.log("OK");
        reglementsContrats(cts);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const reglementsContrats = (contrats) => {
    var allContrats = [];
    contrats.forEach((ctrt) => {
      allContrats.push(ctrt.data);
    });
    console.log("Contrats: ", allContrats);
    if (modePaiement === "ESPECES") {
      axios
        .post(
          `${API_URL}/api/reglement/especes/contrats`,
          JSON.stringify(allContrats),
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((res) => {
          // setLoadingContrats(true);
          window.location.reload();
          toast("Règlements effectués avec succès!");
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast("Erreur lors du règlement");
        });
    } else if (modePaiement === "BANQUE" || modePaiement === "MOBILE_MONEY") {
      axios
        .post(
          `${API_URL}/api/reglement/else/${modePaiement}/${numeroPaiement}/${banque}`,
          JSON.stringify(allContrats),
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((res) => {
          // setLoadingContrats(true);
          window.location.reload();
          toast("Règlements effectués avec succès!");
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          toast("Erreur lors du règlement");
        });
    }
  };

  const exportAttestations = (contrats) => {
    // setPrintingAttestation(true);
    setNumAttestationModal(true);
    setSelectedContrats(contrats);
    console.log("Contrats: ", contrats);
    console.log(numeroAttestations);
  };

  const attestationPrinting = () => {
    console.log("update printing");
    selectedContrats.forEach((aContrat, index) => {
      // axios
      //   .get(
      //     `${API_URL}/api/contrat/attestation/${aContrat.data.codeId}/${numeroAttestations[index]}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${TOKEN}`,
      //         "Content-Type": "application/json",
      //     :    "Access-Control-Allow-Origin": "*",
      //         "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      //         "Access-Control-Allow-Headers":
      //           "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     console.log(res.data);
      //     console.log("Export data");
      //     exportAttestationToPdf();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      exportAttestationToPdf();
      console.log(aContrat, " gooddd ", index);
    });
  };

  // const exportAttestationToPdf = useReactToPrint({
  //   content: () => printedAttestationRef.current,
  //   onBeforeGetContent: () => setPrintingAttestation(false),
  //   // onAfterPrint: () => {
  //   //   setCurrentContractIndex((prevIndex) => prevIndex + 1);
  //   //   setPrintingAttestation(true);
  //   // }
  // });

  const exportAttestationToPdf = useReactToPrint({
    content: () => attestationRefs.current[currentContractIndex]?.current,
    onBeforeGetContent: () => setPrintingAttestation(false),
    onAfterPrint: () => {
      // Move to the next contract after the current one is printed
      setCurrentContractIndex((prevIndex) => prevIndex + 1);
    },
  });

  useEffect(() => {
    if (currentContractIndex < selectedContrats.length) {
      // Trigger the print for the next contract
      exportAttestationToPdf();
    } else {
      // Reset once all contracts have been printed
      setCurrentContractIndex(0);
    }
  }, [currentContractIndex]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const exportToPdf = (contrat) => {
    setSelectedContrat(contrat);
    setHidden(true);
    setTimeout(() => {
      handlePrint();
      axios
        .get(
          `${API_URL}/api/contrat/impression/codeId/${contrat.codeId}/${LOGGED_USER.codeId}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [500]);
  };

  const exportToPdfs = (contrats) => {
    setSelectedContrats(contrats);
    // console.log("Contrats: ", contrats);
    setHidden(true);
    setTimeout(() => {
      handlePrint();
      contrats.forEach((ctrt) => {
        axios
          .get(
            `${API_URL}/api/contrat/impression/codeId/${ctrt.codeId}/${LOGGED_USER.codeId}`,
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            }
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }, [500]);
  };

  const onSelectChange = (newSelectedRowKeys, rows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    console.log("SelectedRows", rows);
    setSelectedRows(rows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const dtrfFilesLoad = () => {
    setLoading(true);

    axios
      .get(`${API_URL}/api/contrat/dtrf`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        if (res.data) {
          toast("Contrats de la DTRF chargés avec succès!");
        } else {
          toast("Aucun fichier trouvé!");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Aucun fichier trouvé");
        setLoading(false);
      });
  };

  const pbStyles = {
    pageBreak: {
      pageBreakBefore: "always",
    },
  };

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"PRODUCTION"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row g-6 mb-6">
                <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">Contrats</span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{contrats?.length}</h4>
                            {/* <p class="text-success mb-0">(+29%)</p> */}
                          </div>
                          <small class="mb-0">Total contrats</small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-primary">
                            <i class="bx bx-group bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">Contrats annulés</span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{canceledContract}</h4>
                            {/* <p class="text-success mb-0">(+18%)</p> */}
                          </div>
                          <small class="mb-0">
                            Analyses de la semaine dernière
                          </small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-danger">
                            <i class="bx bx-user-plus bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">Contrats actifs</span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{activeContract}</h4>
                            {/* <p class="text-danger mb-0">(-14%)</p> */}
                          </div>
                          <small class="mb-0">
                            Analyses de la semaine dernière
                          </small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-success">
                            <i class="bx bx-user-check bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">Contrats en attente</span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{devis}</h4>
                            {/* <p class="text-success mb-0">(+42%)</p> */}
                          </div>
                          <small class="mb-0">
                            Analyses de la semaine dernière
                          </small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-warning">
                            <i class="bx bx-user-voice bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Users List Table --> */}
              <div class="row">
                <div class="col-sm-4">
                  {rightsService("HCO022") || rightsService("HCO005") || rightsService("HCO019") ? <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginBottom: 16 }}
                    onClick={() => navigate("/ajouter-contrat")}
                  >
                    Nouveau contrat
                  </Button> : null}
                </div>
                <div class="col-sm-4">
                  {rightsService("HLA036") ? <Button
                    type="primary"
                    style={{
                      background: "green",
                      marginBottom: 16,
                    }}
                    icon={<ReloadOutlined />}
                    loading={loading}
                    onClick={() => {
                      dtrfFilesLoad();
                    }}
                  >
                    Chargement - DTRF
                  </Button> : null}
                </div>
                <div class="col-sm-4">
                  {rightsService("HCO021") ? <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    style={{
                      background: "gray",
                      marginBottom: 16,
                    }}
                    onClick={() => {
                      navigate("/production/port");
                    }}
                  >
                    Chargement - STAC
                  </Button> : null}
                </div>
              </div>

              <div class="card">
                <div class="container">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6">
                          <Space align="center" style={{ marginBottom: 16 }}>
                            Mode sélection:{" "}
                            <Switch
                              checked={selecting}
                              onChange={setSelecting}
                            />
                          </Space>
                        </div>
                        <div class="col-6">
                          <DatePicker
                            onChange={dateFiltering}
                            type="primary"
                            style={{
                              background: "white",
                              color: "white",
                            }}
                            icon={<CalendarOutlined />}
                            placeholder="Filtre par date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ConfigProvider locale={frFR}>
                  <Spin spinning={loadingContrats}>
                    {selecting ? (
                      <Table
                        rowSelection={rowSelection}
                        expandable={{
                          expandedRowRender: (record) => {
                            console.log(record);
                            return (
                              <>
                                <p
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                  {`Ecriture comptable : Emission de contrat - N° Police: ${record.numeroPolice}`}
                                </p>
                                <table
                                  className="table table-bordered"
                                  // border="1"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th colSpan={2}>COMPTES</th>
                                      <th></th>
                                      <th colSpan={2}>MONTANT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>DEBIT</td>
                                      <td>CREDIT</td>
                                      <td>LIBELLE</td>
                                      <td>DEBIT</td>
                                      <td>CREDIT</td>
                                    </tr>
                                    <tr>
                                      <td>412</td>
                                      <td></td>
                                      <td>
                                        Compte de prime en recouvrement du point
                                        de vente
                                      </td>
                                      <td>Prime TTC</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>7022</td>
                                      <td>
                                        Prime nette à ne pas céder du point de
                                        vente
                                      </td>
                                      <td></td>
                                      <td>
                                        Somme des primes ne relevant pas du
                                        mandat du Pool
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>7023</td>
                                      <td>Coût de police du point de vente</td>
                                      <td></td>
                                      <td>Coût de police</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>435</td>
                                      <td>TCA de la compagnie</td>
                                      <td></td>
                                      <td>Taxe</td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>4082</td>
                                      <td>C/C Pool chez la compagnie</td>
                                      <td></td>
                                      <td>
                                        Somme des primes relevant du mandat du
                                        Pool
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            );
                          },
                        }}
                        columns={columns}
                        dataSource={data}
                        onChange={handleChange}
                        footer={() => <MyFooter />}
                      />
                    ) : (
                      <Table
                        columns={columns}
                        expandable={{
                          expandedRowRender: (record) => (
                            <ExpandedRowContent record={record} />
                          ),
                        }}
                        dataSource={data}
                        onChange={handleChange}
                      />
                    )}
                  </Spin>
                </ConfigProvider>
              </div>
              <div class="card" id="myContrat" ref={printRef}>
                {selecting &&
                  hidden &&
                  selectedContrats.map((row, index) => (
                    <DTRFFormat contrat={row.data} key={index} />
                  ))}
              </div>
            </div>
            <Modal
              title="Mode de paiement"
              centered
              open={modalModePaiement}
              onOk={() => {
                selecting
                  ? alertReglements(contractsToPay)
                  : alertReglement(contractToPay);
              }}
              onCancel={() => {
                setModalModePaiement(false);
              }}
            >
              <>
                <div className="container" justify="center">
                  <div className="grid-item" lg={12}>
                    <Form
                      form={modePaiementForm}
                      layout="vertical"
                      name="userForm"
                    >
                      <Form.Item
                        name="modePaiement"
                        label="Mode de paiement"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          onChange={(value) => {
                            console.log(value);
                            setModePaiement(value);
                          }}
                          placeholder="Selectionnez votre mode de paiement"
                        >
                          <Option value="ESPECES"> ESPECES </Option>
                          <Option value="BANQUE">BANQUE (Chèque)</Option>
                          <Option value="MOBILE_MONEY">TMONEY / FLOOZ</Option>
                        </Select>
                      </Form.Item>
                      {modePaiement === "BANQUE" ||
                        modePaiement === "MOBILE_MONEY" ? (
                        <Form.Item
                          name="numeroPaiement"
                          label={
                            modePaiement === "BANQUE"
                              ? "Numero de chèque"
                              : modePaiement === "MOBILE_MONEY"
                                ? "Numero FLOOZ / TMONEY"
                                : ""
                          }
                          rules={[
                            {
                              required: true,
                              message: "Veuillez renseigner le champ!",
                            },
                          ]}
                        >
                          <Input
                            placeholder={
                              modePaiement === "BANQUE"
                                ? "Le numero de chèque"
                                : modePaiement === "MOBILE_MONEY"
                                  ? "Le numero de téléphone MOOV (FLOOZ) / TOGOCOM (TMONEY)"
                                  : ""
                            }
                            onChange={(value) => {
                              console.log(value.target.value);
                              setNumeroPaiement(value.target.value);
                            }}
                          />
                        </Form.Item>
                      ) : null}
                      {modePaiement === "BANQUE" ? (
                        <Form.Item
                          name="banque"
                          label="Banque"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            onChange={(value) => {
                              console.log(value);
                              setBanque(value);
                            }}
                            placeholder="Selectionnez la banque"
                          >
                            {banques.map((b) => (
                              <Option
                                key={b.parametreId}
                                value={b.libelleParametre}
                              >
                                {b.libelleParametre}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : null}
                    </Form>
                  </div>
                </div>
              </>
            </Modal>
            <Modal
              title="Attestation"
              centered
              open={numAttestationModal}
              onOk={() => {
                // window.location.reload();
                setNumAttestationModal(false);
              }}
              onCancel={() => {
                // window.location.reload();
                setNumAttestationModal(false);
              }}
              width={1200}
              zIndex={2000}
            >
              <div className="container" justify="center">
                <div className="grid-item" lg={12}>
                  {selectedContrats.map((row, index) => (
                    <div key={index}>
                      <div>
                        <AttestationMoto
                          ref={attestationRefs.current[index]}
                          contrat={row.data}
                          printing={printingAttestation && currentContractIndex === index}
                        />
                        <div style={{ display: "none" }}>
                          <AttestationMotoToPrint
                            ref={printedAttestationRefs.current[index]}
                            contrat={row.data}
                          />
                        </div>

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                      <div style={pbStyles.pageBreak} className="pageBreak">
                        <hr />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <>
                Veuillez préciser les numéros d&apos;attestations à imprimer
                <br />
                <Slider
                  range
                  className="slider-main-div"
                  min={10000}
                  max={100000}
                  onChange={(v) => {
                    setNumeroAttestations(v);
                    console.log(v);
                  }}
                  defaultValue={[10000, 100000]}
                  value={numeroAttestations}
                />
              </>
              <br />
              <br />
              <Button
                onClick={() => {
                  console.log("nA: ", numeroAttestations);
                  attestationPrinting();
                }}
                type="primary"
                style={{
                  background: "green",
                }}
              >
                Imprimer
              </Button>
            </Modal>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default Contrats;
