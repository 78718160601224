import PropTypes from "prop-types";
import React from "react";
import entete from "../../../assets/img/entete.jpg";
import footer from "../../../assets/img/footer.png";
import { LOGGED_USER } from "../../../variables/constants";
import { frenchDate, frenchDateSimple } from "../../../variables/functions";

const RecuCurrentFormat = React.forwardRef((props, ref) => {
  const columns = [
    {
      title: "Libellé",
      dataIndex: "libelle",
      render: (text) => (
        <div className="text-right">
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: "Valeur",
      dataIndex: "valeur",
    },
  ];
  const datas = [
    {
      libelle: "Prime HT :",
      valeur: `${new Intl.NumberFormat("en-DE").format(
        Math.trunc((props.reglement.montant / (1 + 0.06)) - props.reglement.avenant.accessoires)
      )} FCFA`,
    },
    {
      libelle: "Coût de Police :",
      valeur: `${new Intl.NumberFormat("en-DE").format(
        Math.trunc(props.reglement.avenant.accessoires)
      )} FCFA`,
    },
    {
      libelle: "Taxes :",
      valeur: `${new Intl.NumberFormat("en-DE").format(
        Math.trunc(props.reglement.montant * 0.06)
      )} FCFA`,
    },
    {
      libelle: "Montant total de la prime TTC :",
      valeur: `${new Intl.NumberFormat("en-DE").format(
        Math.trunc(props.reglement.montant)
      )} FCFA`,
    },
  ];

  const datas2 = [
    {
      libelle: `${props.reglement.montant > 0 ? "Montant encaissé :" : "Montant ristourné :"}`,
      valeur: `${new Intl.NumberFormat("en-DE").format(
        Math.trunc(props.reglement.montant)
      )} FCFA`,
    },
    {
      libelle: "Montant restant :",
      valeur: `${new Intl.NumberFormat("en-DE").format(
        Math.trunc(props.reglement.montant - props.reglement.montant)
      )} FCFA`,
    },
    {
      libelle: "Mode paiement :",
      valeur: `${props.reglement.modePaiement}`,
    },
    {
      libelle: "Payé le :",
      valeur: `${frenchDate(props.reglement.dateReglement)}`,
    },
  ];

  return (
    <div class="container d-flex justify-content-center" style={{ width: "100%!important", height: "45vh", marginBottom: "5px", padding: "10px", boxSizing: "border-box", pageBreakInside: "avoid" }}>
      <div class="row w-100">
        <div class="col-12" ref={ref}>
          <img src={entete} alt="Header" class="" style={{
            width: "905px",
            height: "125px"
          }} />
          <div class="card testimonial-card">
            <div class="text-center">
              <h5>Reçu {props.reglement.montant > 0 ? "d'encaissement" : "de paiement"} - ASSURANCE AUTOMOBILE</h5>
            </div>
            <div class="">
              <div class="row">
                <div class="col-12">
                  <table class="table" border={0}>
                    <tr>
                      <td class="text-start">
                        <h7>
                          <b>ASSURE: </b>
                          {props.contrat?.souscripteur?.nom}{" "}
                          {props.contrat?.souscripteur?.prenom}
                        </h7>
                      </td>
                      <td>
                        {/* <div class="border-end"></div> */}
                      </td>
                      <td class="text-end">
                        <h7>
                          <b>Avenant : </b>
                          {props.reglement?.avenant?.nom
                            ? props.reglement?.avenant?.nom
                            : props.contrat?.avenant?.nom}
                        </h7>
                      </td>
                    </tr>
                  </table>
                  <hr class="my-2" />
                  <table class="table">
                    <tr>
                      <td class="text-start">
                        <h7>
                          <b>N° Assuré: </b>
                          {props.contrat?.souscripteur?.numeroAssureM
                            ? props.contrat?.souscripteur?.numeroAssureM
                            : props.contrat?.souscripteur?.numeroAssureP}
                        </h7>
                      </td>
                      <td>
                        {/* <div class="border-end"></div> */}
                      </td>
                      <td class="text-end">
                        <h7>
                          <b>N° de Police : </b>
                          {props.contrat.numeroPoliceM
                            ? props.contrat.numeroPoliceM
                            : props.contrat.numeroPoliceP}
                        </h7>
                      </td>
                    </tr>
                  </table>
                  <hr class="my-2" />
                  <table class="table">
                    <tr>
                      <td class="text-start">
                        <h7>
                          <b>Date effet: </b>
                          {frenchDate(props.contrat.dateEffet)}
                        </h7>
                      </td>
                      <td>
                        {/* <div class="border-end"></div> */}
                      </td>
                      <td class="text-end">
                        <h7>
                          <b>Date échéance : </b>
                          {frenchDateSimple(props.contrat.dateEcheance)}
                        </h7>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <hr class="my-2" />
              {/* <br /> */}
              <div class="row">
                <div class="col-1"></div>
                <div class="col-5">
                  <table>
                    {datas?.map((d, id) => (
                      <tr key={id}>
                        <td class="text-start">
                          <h7>
                            <b>{d.libelle} </b>
                          </h7>
                        </td>
                        <td class="text-end">
                          <h7>
                            {d.valeur}
                          </h7>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <div class="col-6">
                  <table>
                    {datas2?.map((d, id) => (
                      <tr key={id}>
                        <td class="text-start">
                          <h7>
                            <b>{d.libelle} </b>
                          </h7>
                        </td>
                        <td class="text-end">
                          <h7>
                            {d.valeur}
                          </h7>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-12 text-end">
                  <table class="table">
                    <tr>
                      <td>
                        <h7>
                          <b>CAISSIER : </b>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </h7>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h7>
                          <b>{LOGGED_USER.personne.nom}{" "}
                            {LOGGED_USER.personne.prenom}</b><br /></h7>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <img src={footer} alt="Footer" class="img-fluid" />
          <hr className="divider" style={{ borderTop: "1px dashed #000", margin: "10px 0" }} />
          <img src={entete} alt="Header" class="" style={{
            width: "905px",
            height: "125px"
          }} />
          <div class="card testimonial-card">
            <div class="text-center">
              <h5>Reçu {props.reglement.montant > 0 ? "d'encaissement" : "de paiement"} - ASSURANCE AUTOMOBILE</h5>
            </div>
            <div class="">
              <div class="row">
                <div class="col-12">
                  <table class="table" border={0}>
                    <tr>
                      <td class="text-start">
                        <h7>
                          <b>ASSURE: </b>
                          {props.contrat?.souscripteur?.nom}{" "}
                          {props.contrat?.souscripteur?.prenom}
                        </h7>
                      </td>
                      <td>
                        {/* <div class="border-end"></div> */}
                      </td>
                      <td class="text-end">
                        <h7>
                          <b>Avenant : </b>
                          {props.reglement?.avenant?.nom
                            ? props.reglement?.avenant?.nom
                            : props.contrat?.avenant?.nom}
                        </h7>
                      </td>
                    </tr>
                  </table>
                  <hr class="my-2" />
                  <table class="table">
                    <tr>
                      <td class="text-start">
                        <h7>
                          <b>N° Assuré: </b>
                          {props.contrat?.souscripteur?.numeroAssureM
                            ? props.contrat?.souscripteur?.numeroAssureM
                            : props.contrat?.souscripteur?.numeroAssureP}
                        </h7>
                      </td>
                      <td>
                        {/* <div class="border-end"></div> */}
                      </td>
                      <td class="text-end">
                        <h7>
                          <b>N° de Police : </b>
                          {props.contrat.numeroPoliceM
                            ? props.contrat.numeroPoliceM
                            : props.contrat.numeroPoliceP}
                        </h7>
                      </td>
                    </tr>
                  </table>
                  <hr class="my-2" />
                  <table class="table">
                    <tr>
                      <td class="text-start">
                        <h7>
                          <b>Date effet: </b>
                          {frenchDate(props.contrat.dateEffet)}
                        </h7>
                      </td>
                      <td>
                        {/* <div class="border-end"></div> */}
                      </td>
                      <td class="text-end">
                        <h7>
                          <b>Date échéance : </b>
                          {frenchDateSimple(props.contrat.dateEcheance)}
                        </h7>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <hr class="my-2" />
              {/* <br /> */}
              <div class="row">
                <div class="col-1"></div>
                <div class="col-5">
                  <table>
                    {datas?.map((d, id) => (
                      <tr key={id}>
                        <td class="text-start">
                          <h7>
                            <b>{d.libelle} </b>
                          </h7>
                        </td>
                        <td class="text-end">
                          <h7>
                            {d.valeur}
                          </h7>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <div class="col-6">
                  <table>
                    {datas2?.map((d, id) => (
                      <tr key={id}>
                        <td class="text-start">
                          <h7>
                            <b>{d.libelle} </b>
                          </h7>
                        </td>
                        <td class="text-end">
                          <h7>
                            {d.valeur}
                          </h7>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-12 text-end">
                  <table class="table">
                    <tr>
                      <td>
                        <h7>
                          <b>CAISSIER : </b>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </h7>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h7>
                          <b>{LOGGED_USER.personne.nom}{" "}
                            {LOGGED_USER.personne.prenom}</b><br /></h7>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <img src={footer} alt="Footer" class="img-fluid" />
        </div>
      </div>
    </div>
  );
});
RecuCurrentFormat.propTypes = {
  contrat: PropTypes.object,
  reglement: PropTypes.object,
};
export default RecuCurrentFormat;