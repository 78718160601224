import React, {forwardRef, useEffect, useImperativeHandle, useState,} from "react";
import TextArea from "antd/es/input/TextArea";
import {Button, Collapse, Input, Typography} from "antd";
import {useParams} from "react-router-dom";
import {getSinistreById} from "../../../../hooks/useHooks";
import {errorStateChecker} from "../../../../variables/functions";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";

const {Title} = Typography

const InformationsSurVictimes = forwardRef((props, ref) => {
    const {activeTab, setActiveTab} = props

    useImperativeHandle(ref, () => ({
        isValidated: () => {
            setActiveTab(activeTab + 1);
        },
        sendState: () => {
            return {
                victimes: formvalues,
            };
        },
        state: {
            victimes: formvalues,
        },
    }));

    const [errorState, setErrorState] = useState({});
    const [informationsVictime, setInformationsSurVictimes] = useState({});
    //   const [victime, setVictime] = useState(initVictime);
    const [formvalues, setFormvalues] = useState([
        {
            nom: "",
            prenom: "",
            adresse: "",
            profession: "",
            descriptionDegatsVictime: "",
        },
    ]);
    const [data, setData] = useState();

    const addFormFields = () => {
        console.log("Add form fields");
        setFormvalues([
            ...formvalues,
            {
                nom: "",
                prenom: "",
                adresse: "",
                profession: "",
                descriptionDegatsVictime: "",
            },
        ]);
    };

    const removeFormFields = (index) => {
        console.log("Remove form fields");
        var newFormFields = [...formvalues];
        newFormFields.splice(index, 1);
        setFormvalues(newFormFields);
    };

    const param3 = useParams();

    useEffect(() => {
        if (param3.id) {
            getSinistreById(param3.id)
                .then((res) => {
                    let vics = [];
                    res.data.victimes.forEach((victime) => {
                        if (!victime.adversaire) {
                            vics.push({
                                nom: victime.personne.nom,
                                prenom: victime.personne.prenom,
                                adresse: victime.personne.adresse,
                                profession: victime.personne.profession,
                                descriptionDegatsVictime: victime.descriptionDegatsVictime,
                            });
                        }
                    });
                    setFormvalues(vics);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [param3.id]);

    const handleChange = (event, index) => {
        const values = [...formvalues];
        values[index][event.target.name] = event.target.value;
        setFormvalues(values);
    };

    useEffect(() => {
        const dt = [];
        formvalues?.forEach((victime, index) => {
            dt.push({
                key: index + 1,
                label: `Victime ${index + 1}`,
                children: (
                    <>
                        <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="nom"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["nom"] === true ? "red" : null,
                                    }}
                                >
                                    Nom de la victime:
                                </label>
                                <Input
                                    id="nom"
                                    name="nom"
                                    placeholder="Nom de la victime"
                                    status={errorState["nom"] === true ? "error" : null}
                                    value={victime?.nom}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "nom",
                                            informationsVictime["nom"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        console.log(event.target.value);
                                        setInformationsSurVictimes(false);
                                        handleChange(event, index);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="prenom"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["prenom"] === true ? "red" : null,
                                    }}
                                >
                                    Prénoms de la victime:
                                </label>
                                <Input
                                    id="prenom"
                                    name="prenom"
                                    placeholder="Prénoms de la victime"
                                    status={errorState["prenom"] === true ? "error" : null}
                                    value={victime?.prenom}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "prenom",
                                            informationsVictime["prenom"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        handleChange(event, index);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="profession"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["profession"] === true ? "red" : null,
                                    }}
                                >
                                    Profession de la victime:
                                </label>
                                <Input
                                    id="profession"
                                    name="profession"
                                    value={victime?.profession}
                                    placeholder="Profession de la victime"
                                    status={errorState["profession"] === true ? "error" : null}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "profession",
                                            informationsVictime["profession"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        // console.log(event.target.value);
                                        handleChange(event, index);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="adresse"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["adresse"] === true ? "red" : null,
                                    }}
                                >
                                    Adresse de la victime:
                                </label>
                                <Input
                                    id="adresse"
                                    name="adresse"
                                    placeholder="Adresse de la victime"
                                    value={victime?.adresse}
                                    status={errorState["adresse"] === true ? "error" : null}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "adresse",
                                            informationsVictime["adresse"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        // console.log(event.target.value);
                                        handleChange(event, index);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="descriptionDegatsVictime"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["descriptionDegatsVictime"] === true
                                                ? "red"
                                                : null,
                                    }}
                                >
                                    Description des dégâts sur la victime:
                                </label>
                                <TextArea
                                    id="descriptionDegatsVictime"
                                    name="descriptionDegatsVictime"
                                    value={victime?.descriptionDegatsVictime}
                                    placeholder="Description des dommages de la victime"
                                    showCount
                                    maxLength={300}
                                    status={
                                        errorState["descriptionDegatsVictime"] === true
                                            ? "error"
                                            : null
                                    }
                                    style={{height: 120, resize: "none"}}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "descriptionDegatsVictime",
                                            informationsVictime["descriptionDegatsVictime"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        // console.log(event.target.value);
                                        handleChange(event, index);
                                    }}
                                />
                            </div>
                            <br/>
                        </div>
                        <br/>
                        <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
                            <div className={'grid-item'}></div>
                            <div className={'grid-item'}></div>
                            <div className={'grid-item'}>
                                {index ? (
                                    <Button
                                        onClick={() => removeFormFields(index)}
                                        color="danger"
                                        type="button"
                                    >
                                        <CloseOutlined/> {""}
                                        {""} Supprimer
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </>
                ),
            });
        });
        setData(dt);
    }, [formvalues]);
    console.log(data)

    return (
        <>
            {formvalues.length <= 1 ? (
                <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
                    <Title style={{
                        fontSize: '26px'
                    }}> Informations sur la (les) victimes</Title>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="nom"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["nom"] === true ? "red" : null,
                            }}
                        >
                            Nom de la victime:
                        </label>
                        <Input
                            id="nom"
                            name="nom"
                            placeholder="Nom de la victime"
                            value={formvalues[0]?.nom}
                            status={errorState["nom"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "nom",
                                    informationsVictime["nom"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                // console.log(event.target.value);
                                setInformationsSurVictimes(false);
                                handleChange(event, 0);
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="prenom"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["prenom"] === true ? "red" : null,
                            }}
                        >
                            Prénoms de la victime:
                        </label>
                        <Input
                            id="prenom"
                            name="prenom"
                            value={formvalues[0]?.prenom}
                            placeholder="Prénoms de la victime"
                            status={errorState["prenom"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "prenom",
                                    informationsVictime["prenom"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                // console.log(event.target.value);
                                handleChange(event, 0);
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="profession"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["profession"] === true ? "red" : null,
                            }}
                        >
                            Profession de la victime:
                        </label>
                        <Input
                            id="profession"
                            name="profession"
                            value={formvalues[0]?.profession}
                            placeholder="Profession de la victime"
                            status={errorState["profession"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "profession",
                                    informationsVictime["profession"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                // console.log(event.target.value);
                                handleChange(event, 0);
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="adresse"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["adresse"] === true ? "red" : null,
                            }}
                        >
                            Adresse de la victime:
                        </label>
                        <Input
                            id="adresse"
                            name="adresse"
                            value={formvalues[0]?.adresse}
                            placeholder="Adresse de la victime"
                            status={errorState["adresse"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "adresse",
                                    informationsVictime["adresse"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                // console.log(event.target.value);
                                handleChange(event, 0);
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="descriptionDegatsVictime"
                            style={{
                                margin: "30px 0 10px 0",
                                color:
                                    errorState["descriptionDegatsVictime"] === true
                                        ? "red"
                                        : null,
                            }}
                        >
                            Description des dégâts sur la victime:
                        </label>
                        <TextArea
                            id="descriptionDegatsVictime"
                            name="descriptionDegatsVictime"
                            value={formvalues[0]?.descriptionDegatsVictime}
                            placeholder="Description des dommages de la victime"
                            showCount
                            maxLength={300}
                            status={
                                errorState["descriptionDegatsVictime"] === true ? "error" : null
                            }
                            style={{height: 120, resize: "none"}}
                            onBlur={() => {
                                errorStateChecker(
                                    "descriptionDegatsVictime",
                                    informationsVictime["descriptionDegatsVictime"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                // console.log(event.target.value);
                                handleChange(event, 0);
                            }}
                        />
                    </div>
                    <br/>
                </div>
            ) : (
                <>
                    <Collapse items={data} defaultActiveKey={1}/>
                    {/*  <CustomAccordion active={0} collapses={data} />*/}
                </>
            )}

            <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
                <br/>
                <br/>
                <Button onClick={() => addFormFields()} color="primary" type="button">
                    <PlusOutlined/> {""}
                    {""} Ajouter une victime
                </Button>
            </div>
        </>
    );
});
InformationsSurVictimes.displayName = "InformationsSurVictimes";

export default InformationsSurVictimes;
