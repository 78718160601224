import { Modal, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";

import axios from "axios";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL, LOGGED_USER, TOKEN } from "../../../../variables/constants";

const Prorogation = (props) => {
  const [modalProrogation, setModalProrogation] = useState(props.open);
  const [loading, setLoading] = useState(false);
  const [contrat, setContrat] = useState();
  const [dateEcheance, setDateEcheance] = useState();
  const [prorogationTime, setProrogationTime] = useState();
  const [fractionnements, setFractionnements] = useState([]);

  useEffect(() => {
    setModalProrogation(props.open);
    setContrat(props.contrat);
  }, [props.open]);

  const param = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (param.contratId) {
      axios
        .get(
          `${API_URL}/api/taux-fractionnement/fractionnements/codeId/${param.contratId}/1`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          setFractionnements(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [TOKEN, param.id]);

  const handleProrogationSubmit = () => {
    if (contrat?.fractionnement?.nombre === 1) {
      setModalProrogation(false);
    } else {
      setLoading(true);
      contrat.dateEcheance = dateEcheance;
      contrat?.fractionnement?.nombre > 1 &&
        axios
          .put(
            `${API_URL}/api/contrat/prorogation/${LOGGED_USER.codeId}`,
            JSON.stringify(contrat),
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            }
          )
          .then((res) => {
            // console.log(res.data);
            res.data.codeId = contrat.codeId;
            toast("Prorogation effectuée!");
            setLoading(false);
            setModalProrogation(false);
            props.update(false);
            navigate(`/details-contrat/${res.data.codeId}`);
          })
          .catch((err) => {
            console.log(err);
            toast("Erreur lors de la prorogation");
          });
    }
  };

  const showDateWithTime = (date) => {
    var d = date?.split("T")[0];
    var t = date?.split("T")[1];
    return `${d} - ${t}`;
  };
  const handleCancel = () => {
    setModalProrogation(false);
    props.update(false);
  };

  return (
    <Modal
      title="PROROGATION"
      centered
      confirmLoading={loading}
      open={modalProrogation}
      onOk={() => handleProrogationSubmit()}
      onCancel={() => handleCancel()}
      width={900}
      zIndex={1500}
    >
      <div className="row" justify="center">
        <div className="col-12">
          Date Effet:{" "}
          <Tag color="blue">
            <b>{showDateWithTime(contrat?.dateEffet)}</b>
          </Tag>
        </div>
        <br />
        <br />
        <div className="col-12">
          Date Echéance actuelle:{" "}
          <Tag color="blue">
            <b>{showDateWithTime(contrat?.dateEcheance)}</b>
          </Tag>
        </div>
        <br />
        <br />
        {contrat?.fractionnement?.nombre === 1 ? (
          <div> Pas de prorogation possible! </div>
        ) : (
          <>
            <div className="col-12">
              <form>
                <label
                  htmlFor="prorogationTime"
                  className="form-label"
                >
                  Temps de prorogation
                </label>
                <Select
                  value={prorogationTime}
                  onChange={(value) => {
                    setProrogationTime(value);
                    var date = new Date(contrat?.dateEcheance);
                    date.setMonth(
                      date.getMonth() + contrat.fractionnement.moisMin
                    );
                    // console.log("New D: ", date.toISOString());
                    setDateEcheance(date.toISOString().split(".")[0]);
                  }}
                  options={fractionnements ? fractionnements?.map((fract, index) => ({
                    label: `${fract.taux}% - Versement N ${fract.rang}`,
                    value: fract.taux,
                    key: index
                  })) : []}
                  style={{ width: "100%" }}
                />
              </form>
            </div>
            <br />
            <br />
            <br />
            <div className="col-12">
              <div>
                Nouvelle Date Echeance:{" "}
                <Tag color="green">
                  {/* <b>{showDateWithTime(dateEcheance)}</b> */}
                  <b>{dateEcheance && showDateWithTime(dateEcheance)}</b>
                </Tag>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

Prorogation.propTypes = {
  open: PropTypes.bool,
  update: PropTypes.func,
  contrat: PropTypes.object
};

export default Prorogation;