import {LOGGED_USER} from "../../variables/constants";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import React from "react";
import isAuthenticated from "../../services/authService";

const RequireOTP = () => {
    const auth = isAuthenticated();
    console.log(sessionStorage.getItem('q'))
    const hasUserOTP = () => {
        if (!LOGGED_USER?.profil?.organisme?.otpConnexion) return true
        else return sessionStorage.getItem('q') === '1'
    }
    console.log(hasUserOTP())
    console.log(LOGGED_USER?.profil?.organisme?.otpConnexion)
    const location = useLocation();

    return hasUserOTP() && auth ? (
        <Outlet/>
    ) : (
        <Navigate to="/connexion" state={{from: location}} replace/>
    );

}

export default RequireOTP