import { Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API_URL, TOKEN } from '../../../variables/constants';

const AddDetailsReduction = ({ show, handleClose, editMode, selectedReduction }) => {
    const [nom, setNom] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show && selectedReduction) {
            setNom(selectedReduction.nom || "");
            setDescription(selectedReduction.commentaire || "");
        }

    }, [show, selectedReduction]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const reductionData = {
            ...selectedReduction,
            nom,
            description,
        };

        try {
            const request = editMode
                ? await axios.put(`${API_URL}/api/sous-garantie`, reductionData, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                    },
                })
                : await axios.post(`${API_URL}/api/sous-garantie`, reductionData, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                    },
                });

            toast.success(editMode ? "Réduction modifiée avec succès" : "Réduction ajoutée avec succès");
            handleClose();
        } catch (error) {
            console.error("Erreur lors de l'opération :", error);
            toast.error("Erreur lors de l'opération.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={show} onCancel={handleClose} footer={null} centered>
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label htmlFor="nom">Nom</label>
                    <input
                        type="text"
                        id="nom"
                        className="form-control"
                        placeholder="Nom de la réduction"
                        value={nom}
                        onChange={(e) => setNom(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        className="form-control"
                        placeholder="Description de la réduction"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>

                <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? "Enregistrement..." : "Enregistrer"}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary ms-2"
                        onClick={handleClose}
                    >
                        Annuler
                    </button>
                </div>
            </form>
        </Modal>
    )
}

export default AddDetailsReduction