import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import isAuthenticated from "../../services/authService";

const RequireAuth = () => {
  const auth = isAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectWithToast = () => {
    toast("Votre compte est désactivé. Veuillez contacter l'administrateur!");
    navigate("/login");
  };

  return auth ? (
    <Outlet />
  ) : (
    <Navigate to="/connexion" state={{ from: location }} replace />
  );
};

export default RequireAuth;
