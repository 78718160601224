import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const AddCarosserie = ({ show, handleClose, editMode, selectedCarrosserie }) => {
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState("");
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (editMode && selectedCarrosserie) {
      setNom(selectedCarrosserie.libelleParametre || "");
      setSelectedCategorie(selectedCarrosserie.categorie?.categorieId || "");
    } else {
      setNom("");
      setSelectedCategorie("");
    }

    axios
      .get(`${API_URL}/api/categorie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de la récupération des catégories");
      });
  }, [editMode, selectedCarrosserie, show]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const selectedCat = categories.find((cat) => cat.categorieId === parseInt(selectedCategorie));
    const carrosserieData = {
      ...selectedCarrosserie,
      libelleParametre: nom,
      typeParametre: "CAROSSERIE",
      categorie: selectedCat,
    };

    console.log("test", selectedCarrosserie);


    const request = editMode
      ? axios.put(`${API_URL}/api/parametre`, carrosserieData, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      : axios.post(`${API_URL}/api/parametre`, carrosserieData, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      });

    request
      .then((res) => {
        setNom("");
        setSelectedCategorie("");
        toast.success(editMode ? "Carosserie modifiée avec succès" : "Carosserie ajoutée avec succès");
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors de l'enregistrement de la carosserie");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button type="button" className="btn-close btn-primary" onClick={handleClose} aria-label="Close"></button>
        <div className="text-center mb-4">
          <h4 className="address-title mb-2">{editMode ? "Modifier la" : "Ajouter une"} carosserie</h4>
          <p className="address-subtitle">{editMode ? "Modifier la" : "Ajouter une nouvelle"} carosserie</p>
        </div>
        <form className="row g-3" onSubmit={onSubmit}>
          <div className="col-12">
            <label className="form-label" htmlFor="modalAddressFirstName">
              Nom
            </label>
            <input
              type="text"
              id="modalAddressFirstName"
              onChange={(e) => setNom(e.target.value)}
              className="form-control"
              placeholder="Nom de la carosserie"
              name="nom"
              value={nom}
              required
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="modalAddressCountry">
              Catégorie
            </label>
            <select
              id="modalEditUserLanguage"
              name="modalEditUserLanguage"
              className="select2 form-select"
              onChange={(e) => setSelectedCategorie(e.target.value)}
              value={selectedCategorie}
              required
            >
              <option value="">Sélectionner la catégorie</option>
              {categories.map((cat) => (
                <option key={cat.categorieId} value={cat.categorieId}>
                  {cat.nom}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-primary me-3" disabled={loading}>
              {loading && <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true"></span>}
              {loading ? `Enregistrement...` : `Enregistrer`}
            </button>
            <button type="button" className="btn btn-label-secondary" onClick={handleClose}>
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCarosserie;
