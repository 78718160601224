import {Switch} from "antd";
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import axiosInstance from "../../variables/api/axiosInstance";
import {LOGGED_USER} from "../../variables/constants";

const ConfirmOTP = () => {
    const [confirmCode, setConfirmCode] = useState("000000");
    const [loading, setLoading] = useState(false);
    const inputRefs = useRef([]);
    const [useGAuth, setUseGAuth] = useState(false);

    const handleChange = (e, index) => {
        const {value} = e.target;

        // Vérifier si la valeur est un chiffre
        if (!/^\d$/.test(value) && value !== "") {
            return; // Si ce n'est pas un chiffre, ne rien faire
        } else {
            if (value.length === 1 && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }

            // Déplacer vers l'entrée précédente si l'entrée actuelle est vide (fonctionnalité de retour arrière)
            if (value.length === 0 && index > 0) {
                inputRefs.current[index - 1].focus();
            }

            let newStr =
                confirmCode.substring(0, index) +
                e.target.value +
                confirmCode.substring(index + 1);

            setConfirmCode(newStr);
        }


    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const confirmRequest = {
            codeId: useGAuth ? LOGGED_USER?.username : LOGGED_USER?.email,
            confirmCode: confirmCode,
        };

        // console.log(confirmRequest);
        axiosInstance.post(useGAuth ? `/api/2fa/verify` : `/api/confirm-code`, JSON.stringify(confirmRequest))
            .then((res) => {
                console.log("Plus profond: ", res);
                if (res.status === 200) {
                    if (useGAuth) {
                        if (res.data) {
                            sessionStorage.setItem('q', '1')
                            toast("Vous êtes connecté avec succès!");
                            navigate("/");
                        } else {
                            toast("Erreur lors de la validation du code OTP");
                        }
                    } else {
                        sessionStorage.setItem('q', '1')
                        toast("Vous êtes connecté avec succès!");
                        navigate("/");
                    }
                } else {
                    toast("Erreur lors de la validation du code OTP");
                }
                setLoading(false);
            })
            .catch((err) => {
                toast("Erreur lors de la validation du code OTP");
                console.log(err);
                setLoading(false);
            });
    };

    function obfuscateEmail(email) {
        // Split the email address into username and domain parts
        let [username, domain] = email.split("@");

        // Determine the number of visible characters at the end of the username
        let visibleChars = 4;

        // Replace all but the last few characters of the username with asterisks
        let obfuscatedUsername =
            "*".repeat(username.length - visibleChars) +
            username.slice(-visibleChars);

        // Combine the obfuscated username with the domain
        return obfuscatedUsername + "@" + domain;
    }

    return (
        <div class="authentication-wrapper authentication-cover">
            {/* <!-- Logo --> */}
            {/* <a href="/#" class="app-brand auth-cover-brand gap-2">
        <span class="app-brand-text demo text-heading fw-bold">PoolIns</span>
      </a> */}
            {/* <!-- /Logo --> */}
            <div class="authentication-inner row m-0">
                {/* <!-- /Left Text -->  */}
                <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                    <div class="w-100 d-flex justify-content-center">
                        <img
                            src="assets/img/login.jpg"
                            class="img-fluid scaleX-n1-rtl"
                            alt="LoginImage"
                            width="10000"
                            data-app-dark-img="login.jpg"
                            data-app-light-img="login.jpg"
                        />
                    </div>
                </div>
                {/* <!-- /Left Text --> */}

                {/* <!-- Two Steps Verification --> */}
                <div class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-6 p-sm-12">
                    <div class="w-px-400 mx-auto mt-12 mt-5">
                        <h4 class="mb-1">Authentification en deux étapes 💬</h4>
                        <p class="text-start mb-6">
                            Nous avons envoyé un code de vérification sur votre mobile.
                            Saisissez le code de votre mobile ou boîte mail ci-dessous.
                            <span class="fw-medium d-block mt-1 text-heading">
                {JSON.parse(sessionStorage.getItem("user"))
                    ? JSON.parse(sessionStorage.getItem("user"))?.otpPhoneNumber
                        ? `****${JSON.parse(sessionStorage.getItem("user"))
                            ?.otpPhoneNumber.trim()
                            .slice(-3)} / `
                        : null
                    : null}
                                {JSON.parse(sessionStorage.getItem("user"))?.email != null
                                    ? `${obfuscateEmail(
                                        JSON.parse(sessionStorage.getItem("user"))?.email
                                    )}`
                                    : null}
                                {/* ******1234 / ***********ake1881@gmail.com */}
              </span>
                        </p>
                        <p class="mb-0">Tapez votre code de sécurité à 6 chiffres</p>
                        <form id="twoStepsForm" onSubmit={handleSubmit}>
                            <div class="mb-6">
                                <div
                                    class="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                                    <input
                                        type="tel"
                                        class="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                                        maxlength="1"
                                        autofocus=""
                                        onChange={(e) => handleChange(e, 0)}
                                        ref={(el) => (inputRefs.current[0] = el)}
                                    />
                                    <input
                                        type="tel"
                                        class="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                                        maxlength="1"
                                        onChange={(e) => handleChange(e, 1)}
                                        ref={(el) => (inputRefs.current[1] = el)}
                                    />
                                    <input
                                        type="tel"
                                        class="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                                        maxlength="1"
                                        onChange={(e) => handleChange(e, 2)}
                                        ref={(el) => (inputRefs.current[2] = el)}
                                    />
                                    <input
                                        type="tel"
                                        class="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                                        maxlength="1"
                                        onChange={(e) => handleChange(e, 3)}
                                        ref={(el) => (inputRefs.current[3] = el)}
                                    />
                                    <input
                                        type="tel"
                                        class="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                                        maxlength="1"
                                        onChange={(e) => handleChange(e, 4)}
                                        ref={(el) => (inputRefs.current[4] = el)}
                                    />
                                    <input
                                        type="tel"
                                        class="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                                        maxlength="1"
                                        onChange={(e) => handleChange(e, 5)}
                                        ref={(el) => (inputRefs.current[5] = el)}
                                    />
                                </div>
                                {/* <!-- Create a hidden field which is combined by 3 fields above --> */}
                                <input type="hidden" name="otp"/>
                                {LOGGED_USER.gsecret ? <Switch checkedChildren="Utiliser le code Email"
                                                               unCheckedChildren="Utiliser Google Authenticator"
                                                               onChange={() => setUseGAuth(!useGAuth)}
                                                               value={useGAuth}/> : null}
                            </div>
                            <button
                                class="btn btn-primary d-grid w-100 mb-6"
                                disabled={loading}
                            >
                                {loading && (
                                    <span
                                        class="spinner-grow me-1"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                                {loading ? `Confirmation...` : `Confirmer mon identité`}
                            </button>
                            <div class="text-center">
                                Vous n'avez pas reçu le code ?<a href="/connexion">Renvoyer</a>
                            </div>
                        </form>
                    </div>
                </div>
                {/* <!-- /Two Steps Verification --> */}
            </div>
        </div>
    );
};

export default ConfirmOTP;
