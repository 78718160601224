import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { rightsService } from '../../services/rightsService';

const RequireRights = ({ codeHabilitation }) => {
    const hasRight = rightsService(codeHabilitation);
    const location = useLocation();

    return hasRight ? (
        <Outlet />
    ) : (
        <Navigate to="/" state={{ from: location }} replace />
    );
}

export default RequireRights