import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, Progress } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import { rightsService } from '../../../services/rightsService';
import { API_URL, LOGGED_USER, TOKEN } from '../../../variables/constants';
import { frenchDate } from '../../../variables/functions';
const { confirm } = Modal;

const DetailsLotAttestationsDispatched = () => {
    const [lots, setLots] = useState([]);
    const [agences, setAgences] = useState([]);
    const [lotAttestation, setLotAttestation] = useState("");
    const [compagnie, setCompagnie] = useState("");
    const [affectationModalOpen, setAffectationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstAttestation, setFirstAttestation] = useState(0);
    const [lastAttestation, setLastAttestation] = useState(0);
    const [nombre, setNombre] = useState(0);
    const [reload, setReload] = useState(false);
    const [selectedAgence, setSelectedAgence] = useState("");

    const param = useParams();

    useEffect(() => {
        const code = param.codeCompagnie ? param.codeCompagnie : LOGGED_USER.profil.organisme.code;
        const codeAgence = param.codeCompagnie ? param.codeCompagnie : LOGGED_USER.profil.organisme.code;
        axios
            .get(`${API_URL}/api/lot-attestation/${param.lotAttestationId}/`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setLots(res.data.dispatchedLots);
                setLotAttestation(res.data);
                setCompagnie(res.data.compagnie);
                setReload(false);

                axios
                    .get(`${API_URL}/api/agence/organisme/code/${res.data.compagnie.code}`, {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    .then((resp) => {
                        console.log(resp.data);
                        setAgences(resp.data);
                        setSelectedAgence(resp.data[0].codeAgence);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [TOKEN, reload]);

    const onSubmit = () => {
        if (nombre === lastAttestation - firstAttestation + 1) {
            let allWantedAttestations = Array.from({ length: nombre }, (_, i) => firstAttestation + i);
            console.log("AWA: ", allWantedAttestations);
            let absentElement = allWantedAttestations.some(element => !lotAttestation.updatedLot.includes(element));
            console.log("aE: ", absentElement);
            let findElements = allWantedAttestations.filter(element => !lotAttestation.updatedLot.includes(element));
            console.log("FindingElements: ", findElements);
            if (!absentElement) {
                const agency = agences.find(element => element.codeAgence === selectedAgence);

                let lot = {
                    agence: agency,
                    lot: allWantedAttestations,
                    updatedLot: allWantedAttestations,
                }
                console.log("Loooot: ", lot);
                console.log("LooootS: ", JSON.stringify(lot));
                // axios
                //     .post(`${API_URL}/api/dispatched-lot-attestation/lot/${param.lotAttestationId}`, JSON.stringify(lot), {
                //         headers: {
                //             Authorization: `Bearer ${TOKEN}`,
                //             "Content-Type": "application/json",
                //             "Access-Control-Allow-Origin": "*",
                //             "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                //             "Access-Control-Allow-Headers":
                //                 "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                //         },
                //     })
                //     .then((res) => {
                //         console.log("Data Ressst", res);
                //         if (res.status === 226) {
                //             toast("Cet numéro d'attestation existe déjà!");
                //         } else {
                //             setAffectationModalOpen(false);
                //             setReload(true);
                //             toast("Lot d'attestation ajoutée avec succès");
                //         }
                //         setLoading(false);
                //     })
                //     .catch((err) => {
                //         console.log(err);
                //         if (err.response.status === 226) {
                //             toast("Cet numéro d'attestation existe déjà!");
                //         } else {
                //             toast("Erreur lors de l'ajout du lot d'attestation");
                //         }
                //         setLoading(false);
                //     });

                axios
                    .get(`${API_URL}/api/dispatched-lot-attestation/lot/${param.lotAttestationId}/${firstAttestation}/${lastAttestation}/${selectedAgence}`, {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    .then((res) => {
                        console.log("Data Ressst", res);
                        if (res.status === 226) {
                            toast("Cet numéro d'attestation existe déjà!");
                        } else {
                            setAffectationModalOpen(false);
                            setReload(true);
                            toast("Lot d'attestation ajoutée avec succès");
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 226) {
                            toast("Cet numéro d'attestation existe déjà!");
                        } else {
                            toast("Erreur lors de l'ajout du lot d'attestation");
                        }
                        setLoading(false);
                    });
            } else {
                toast("Il existe des attestations déjà dispatchées ou utilisées dans votre sélection");
            }
        } else {
            toast("Veuillez vérifier les valeurs que vous avez saisi!");
        }
    }

    const showReceptionAttestationConfirm = (lotA) => {
        confirm({
            title: "Etes-vous sûr de vouloir valider la réception du lot dispatché?",
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                validationReception(lotA);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const validationReception = (lotA) => {
        axios
            .get(`${API_URL}/api/dispatched-lot-attestation/reception/${lotA}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row g-6 mb-6">
                                <div class="col-lg-12 col-xxl-12">
                                    <div class="card h-100">
                                        <div class="card-header d-flex align-items-center justify-content-between">
                                            <div class="card-title mb-0">
                                                <h5 class="m-0 me-2">Dispatching du lot d'attestation {lotAttestation?.typeAttestation ? lotAttestation?.typeAttestation : ""} de <span style={{ color: "yellowgreen" }}>{lotAttestation?.lot && lotAttestation?.lot[0]} - {lotAttestation?.lot && lotAttestation?.lot[lotAttestation?.lot?.length - 1]}</span>  de {compagnie.raisonSociale}</h5>
                                            </div>
                                            {rightsService("HLA062") ?
                                                <div class="dropdown">
                                                    <button
                                                        type="button" class="btn btn-primary"
                                                        onClick={() => setAffectationModalOpen(true)}
                                                    >
                                                        Effectuer un dispatch
                                                    </button>
                                                </div> : null}
                                        </div>
                                        <div class="card-body">
                                            <ul class="p-0 m-0">
                                                {lots.length ? lots.map((l, id) => (
                                                    <li class="d-flex mb-4 pb-1" key={id}>
                                                        <div class="chart-progress me-4" data-color="success" data-series="83" data-progress_variant="true">
                                                            <Progress type="circle" percent={(100 * ((l.lot.length) - (l.updatedLot.length)) / (l.lot.length)).toFixed(2)} />
                                                        </div>
                                                        <div class="row w-100 align-items-center">
                                                            <div class="col-8">
                                                                <div class="me-2">
                                                                    <h6 class="mb-1 text-success">{l.lot[0]}-{l.lot[l.lot.length - 1]}</h6>
                                                                    <p class="mb-0">  {l.dateReception ? <>Livré le {frenchDate(l.createdAt)} à <u>{l.agence.nom}</u> </> : "Pas encore livré"} </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 text-end">
                                                                {l.dateReception ? <div class="badge bg-label-secondary">{(l.lot.length) - (l.updatedLot.length)}/{l.lot.length}</div> : <button
                                                                    type="button" class="btn btn-secondary"
                                                                    onClick={() => showReceptionAttestationConfirm(l.codeId)}
                                                                >
                                                                    Valider réception
                                                                </button>}
                                                            </div>
                                                        </div>
                                                    </li>
                                                )) : "Aucun dispatching effectué pour l'instant"}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <Modal open={affectationModalOpen} loading={loading} onOk={onSubmit} onCancel={() => setAffectationModalOpen(false)} zIndex={1900} centered>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Nouveau Dispatch d'attestation </h4>
                    <p className="address-subtitle">
                        Affecter un nouveau lot d'attestation à une agence
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-5" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="agence">
                                    Veuillez sélectionner l'agence
                                </label>
                                <select className="form-control" id="agence" value={selectedAgence} name="agence" onChange={(e) => setSelectedAgence(e.target.value)} placeholder="Sélectionner une agence">
                                    {agences?.map((ag, id) => (
                                        <option key={id} value={ag.codeAgence}> {ag.nom} </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="firstAttestation">
                                    Veuillez entrer le numéro de la première attestation
                                </label>
                                <input
                                    className="form-control" id="firstAttestation" value={firstAttestation} name="firstAttestation"
                                    onChange={(e) => {
                                        setFirstAttestation(parseInt(e.target.value));
                                        setLastAttestation(parseInt(e.target.value));
                                    }}
                                    placeholder="Entrez le numéro de la première attestation" type="number" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="lastAttestation">
                                    Le nombre d'attestation
                                </label>
                                <input className="form-control" id="lastAttestation" value={nombre} name="lastAttestation" onChange={(e) => setNombre(parseInt(e.target.value))} placeholder="Entrez le nombre d'attestation" type="number" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="lastAttestation">
                                    Veuillez entrer le numéro de la dernière attestation
                                </label>
                                <input className="form-control" id="lastAttestation" value={lastAttestation} name="lastAttestation" onChange={(e) => setLastAttestation(parseInt(e.target.value))} placeholder="Entrez le numéro de la dernière attestation" type="number" />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div >
    )
}

export default DetailsLotAttestationsDispatched