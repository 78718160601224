import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddEnergie = ({ show, handleClose, editMode, convertEnergieId, selectedEnergie }) => {
  const [essence, setEssence] = useState("");
  const [diesel, setDiesel] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  // Indicateur de chargement des données
  useEffect(() => {
    if (editMode && selectedEnergie) {
      setDiesel(selectedEnergie.diesel);
      setEssence(selectedEnergie.essence);
    }

  }, [editMode, selectedEnergie]);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (editMode) {
      const updatedEnergie = {
        ...selectedEnergie,
        essence: parseInt(essence),
        diesel: parseInt(diesel),
      };

      axios
        .put(`${API_URL}/api/convert-energie`, updatedEnergie, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoading(false);
          toast("Conversion modifiée avec succès");
          handleClose(); // Ferme le modal après succès
          window.location.reload();

        })
        .catch((err) => {
          setLoading(false);
          toast("Erreur lors de la modification de la conversion");
          console.error(err);
        });
    } else {
      const newEnergie = {
        essence: parseInt(essence),
        diesel: parseInt(diesel),
      };

      axios
        .post(`${API_URL}/api/convert-energie`, newEnergie, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoading(false);
          toast("Conversion ajoutée avec succès");
          handleClose();
        })
        .catch((err) => {
          setLoading(false);
          toast("Erreur lors de l'ajout de la conversion");
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (editMode && convertEnergieId) {
      setIsDataLoading(true);
      axios
        .get(`${API_URL}/api/convert-energie/${convertEnergieId}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setEssence(res.data.essence);
          setDiesel(res.data.diesel);
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.error(err);
          toast("Erreur lors de la récupération des données");
          setIsDataLoading(false);
        });
    }
  }, [editMode, convertEnergieId]);

  if (isDataLoading) {
    return <div>Chargement des données...</div>;
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la conversion" : "Ajouter une conversion"}
          </h4>
        </div>
        <form id="addNewEnergyForm" className="row g-3" onSubmit={onSubmit}>
          <div className="col-12">
            <label className="form-label" htmlFor="essenceField">
              Essence
            </label>
            <input
              type="text"
              id="essenceField"
              onChange={(e) => setEssence(e.target.value)}
              className="form-control"
              placeholder="Quantité d'essence"
              name="essence"
              value={essence}
              required
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="dieselField">
              Diesel
            </label>
            <input
              type="text"
              id="dieselField"
              onChange={(e) => setDiesel(e.target.value)}
              className="form-control"
              placeholder="Quantité de diesel"
              name="diesel"
              value={diesel}
              required
            />
          </div>
          <div className="col-12 text-center mt-4">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEnergie;
