import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";
import axios from "axios";

const AddAvenant = ({ show, handleClose, editMode, selectedAvenant }) => {
  const [nom, setNom] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [penalite, setPenalite] = useState("");
  const [accessoires, setAccessoires] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    if (editMode && selectedAvenant) {
      setIsDataLoading(true);
      axios
        .get(`${API_URL}/api/avenant/${selectedAvenant.avenantId}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const { nom, code, description, penalite, accessoires } = res.data;
          setNom(nom);
          setCode(code);
          setDescription(description);
          setPenalite(penalite);
          setAccessoires(accessoires);
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Erreur lors de la récupération des données");
          setIsDataLoading(false);
        });
    }
  }, [editMode, selectedAvenant]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (editMode) {
      const updatedAvenant = {
        ...selectedAvenant,
        nom,
        code,
        description,
        penalite,
        accessoires,
      };

      axios
        .put(`${API_URL}/api/avenant`, updatedAvenant, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          toast.success("Avenant modifié avec succès");
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Erreur lors de la modification de l'avenant");
          setLoading(false);
        });
    } else {
      const newAvenant = {
        nom,
        code,
        description,
        penalite,
        accessoires,
      };

      axios
        .post(`${API_URL}/api/avenant`, JSON.stringify(newAvenant), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          toast.success("Avenant ajouté avec succès");
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Erreur lors de l'ajout de l'avenant");
          setLoading(false);
        });
    }
  };

  if (isDataLoading) {
    return <div>Chargement des données...</div>;
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier l'avenant" : "Ajouter un avenant"}
          </h4>
        </div>
        <form id="addNewAvenantForm" className="row g-12" onSubmit={onSubmit}>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="nomField">
              Nom
            </label>
            <input
              type="text"
              id="nomField"
              onChange={(e) => setNom(e.target.value)}
              className="form-control"
              placeholder="Nom de l'avenant"
              name="nom"
              value={nom}
              required
            />
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="codeField">
              Code
            </label>
            <input
              type="text"
              id="codeField"
              onChange={(e) => setCode(e.target.value)}
              className="form-control"
              placeholder="Code de l'avenant"
              name="code"
              value={code}
              required
            />
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="descriptionField">
              Description
            </label>
            <textarea
              id="descriptionField"
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              placeholder="Description de l'avenant"
              name="description"
              value={description}
              required
            ></textarea>
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="penaliteField">
              Pénalité
            </label>
            <input
              type="text"
              id="penaliteField"
              onChange={(e) => setPenalite(e.target.value)}
              className="form-control"
              placeholder="Montant ou description de la pénalité"
              name="penalite"
              value={penalite}
              required
            />
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="accessoiresField">
              Accessoires
            </label>
            <input
              type="text"
              id="accessoiresField"
              onChange={(e) => setAccessoires(e.target.value)}
              className="form-control"
              placeholder="Accessoires liés à l'avenant"
              name="accessoires"
              value={accessoires}
            />
          </div>
          <div className="col-12 text-center mt-4">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAvenant;
