import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import image from "../../assets/img/image.png";
import poolins from "../../assets/img/poolins.jpg";
import "../../assets/styles/auth.css";

const AfterRegistrationPage = () => {
    const navigate = useNavigate();
    return (
        <div class="authentication-wrapper authentication-cover">
            {/* <!-- Logo --> */}
            <a href="/" class="app-brand auth-cover-brand gap-2">

                <span class="app-brand-text demo text-heading fw-bold">
                    <img src={poolins} alt="multi-steps" width="180" />
                </span>
            </a>
            {/* <!-- /Logo --> */}
            <div class="authentication-inner row m-0">
                {/* <!-- Left Text --> */}
                <div class="d-none d-lg-flex col-lg-4 align-items-center justify-content-end p-5 pe-0">
                    <div class="w-px-400">
                        <img src={image} class="img-fluid" alt="multi-steps" width="600" />
                    </div>
                </div>
                {/* <!-- /Left Text --> */}

                {/* <!--  Multi Steps Registration --> */}
                <div class="d-flex col-lg-8 align-items-center justify-content-center authentication-bg p-5">
                    <div class="w-px-700">
                        <div id="multiStepsValidation" class="bs-stepper border-none shadow-none mt-5">
                            <Result
                                status="success"
                                title="Enregistrement de votre agence avec succès!"
                                subTitle="Numéro d'enregistrement: 2017182818828182881. La validation peut prendre 1-5 jours ouvrés. Veuillez patientez tout en vérifiant fréquemment vos mails pour recevoir la notification de validation."
                                extra={[
                                    <Button type="primary" key="console" onClick={() => navigate("/connexion")}>
                                        Se connecter
                                    </Button>
                                ]}
                            />
                        </div>
                    </div>
                </div>
                {/* <!-- / Multi Steps Registration --> */}
            </div>
        </div >
    )
}

export default AfterRegistrationPage