import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { API_URL, TOKEN } from "../../variables/constants";
import AddGarantie from "./AddGarantie";

const { confirm } = Modal;

const Garanties = () => {
  const [garanties, setGaranties] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [codeId, setCodeId] = useState("");
  const [selectedGarantie, setSelectedGarantie] = useState(null);
  const navigate = useNavigate();

  const handleShow = (edit = false, codeId = "") => {
    setEditMode(edit);
    setCodeId(codeId);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setCodeId("");
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/garantie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res", res.data);
        setGaranties(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors de la récupération des garanties");
      });
  }, [showModal]);

  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
    },
    {
      title: "Obligatoire",
      dataIndex: "obligatoire",
      key: "obligatoire",
      render: (ob) => (
        <button type="button" className={ob ? "btn btn-success" : "btn btn-secondary"}>
          {ob ? "Oui" : "Non"}
        </button>
      ),
    },
    {
      title: "Catégorie",
      dataIndex: "categorie",
      key: "categorie",
      render: (cat) => (
        <button type="button" className={`btn rounded-pill ${cat?.nom === "MOTO" ? "btn-info" : "btn-warning"}`}>
          {cat?.nom}
        </button>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-icon rounded-pill"
            onClick={() => {
              setSelectedGarantie(record);
              handleShow(true, record.codeId);
            }}
          >
            <i className="bx bx-edit-alt"></i>
          </button>
          <button
            type="button"
            className="btn btn-primary btn-icon rounded-pill"
            onClick={() => navigate(`/sous-garanties/${record.codeId}`)}
          >
            <i className="bx bx-dots-vertical-rounded"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon rounded-pill"
            onClick={() => showDeleteConfirm(record.codeId)}
          >
            <i className="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];

  const showDeleteConfirm = (garantieId) => {
    confirm({
      title: "Etes-vous sûr de vouloir supprimer cette garantie?",
      icon: <ExclamationCircleFilled />,
      content: "Cela impliquera que toutes les données soient corrompues",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteGarantie(garantieId);
      },
    });
  };

  const deleteGarantie = (garantieId) => {
    axios
      .delete(`${API_URL}/api/garantie/${garantieId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        toast("Suppression effectuée avec succès");
        axios.get(`${API_URL}/api/garantie`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }).then((res) => setGaranties(res.data));
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors de la suppression");
      });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow()}
              >
                Ajouter une garantie
              </Button>
              <div className="card">
                <Table
                  columns={columns}
                  dataSource={garanties}
                  rowKey="codeId"
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.commentaire}</p>
                    ),
                  }}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>

            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>

      <AddGarantie
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        codeId={codeId}
        selectedGarantie={selectedGarantie}
      />

      <div className="layout-overlay layout-menu-toggle"></div>
      <div className="drag-target"></div>
    </div>
  );
};

export default Garanties;
