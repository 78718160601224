import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { searchProduction } from "../hooks/useSearch";
import { rightsService } from "../services/rightsService";
import { LOGGED_USER } from "../variables/constants";
import { frenchDate } from "../variables/functions";

const NavBar = () => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [query, setQuery] = useState(""); // Stocke la valeur de recherche
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const searchInputRef = useRef(null);
  const [whereAmI, setWhereAmI] = useState("");

  const toggleMenu = () => {
    const htmlElement = document.documentElement;
    if (htmlElement.classList.contains("layout-menu-expanded")) {
      htmlElement.classList.remove("layout-menu-expanded");
    } else {
      htmlElement.classList.add("layout-menu-expanded");
    }
    setMenuExpanded(!menuExpanded);
  };

  const mockData = {
    pages: [
      { name: "Home", url: "/", icon: "bx-home" },
      { name: "About", url: "/about", icon: "bx-info-circle" },
      { name: "Contact", url: "/contact", icon: "bx-phone" },
    ],
    files: [
      {
        name: "Document1",
        src: "/path/to/doc1",
        meta: "PDF File",
        subtitle: "Sample doc",
      },
      {
        name: "Image1",
        src: "/path/to/image1",
        meta: "Image File",
        subtitle: "Sample image",
      },
    ],
    members: [
      { name: "John Doe", src: "/path/to/john.jpg", subtitle: "Admin" },
      { name: "Jane Smith", src: "/path/to/jane.jpg", subtitle: "User" },
    ],
  };

  const handleInputChange = async (e) => {
    const baseUrl = window.location.origin;
    const wai = window.location.href.replace(baseUrl, "");
    setWhereAmI(window.location.href.replace(baseUrl, ""));
    // console.log("target: " + window.location.href.replace(baseUrl, ''));
    const inputValue = e.target.value.toLowerCase();
    setQuery(inputValue);

    let results = [];
    if (wai === "/") {
      Object.keys(mockData).forEach((key) => {
        const filtered = mockData[key].filter((item) =>
          item.name.toLowerCase().includes(inputValue),
        );
        if (filtered.length) {
          results.push({ category: key, items: filtered });
        }
      });
    } else {
      if (wai === "/production") {
        let r = await searchProduction(inputValue);
        // console.log("SIV: " + Array.isArray(r));
        // console.log("R: " + JSON.stringify(r.data));
        results = r.data.content;
        if (r && r.length) {
          // results = r;
        } else {
          console.error("go away and try again");
        }
      }
    }

    console.log("Resultttts", results);
    setFilteredSuggestions(results);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault();
        setClicked(!clicked);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [clicked]);

  const logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("t");
    sessionStorage.removeItem("k");
    sessionStorage.removeItem("n");
    sessionStorage.removeItem("q");
    toast("Vous serez déconnecté");
    navigate("/connexion");
    // window.location.reload();
  };

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme z-5"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
        <li
          className="nav-item nav-link px-0 me-xl-6"
          onClick={(e) => {
            // e.preventDefault();
            toggleMenu();
          }}
        >
          <i className="bx bx-menu bx-md"></i>
        </li>
      </div>

      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        {/* <!-- Search --> */}
        <div className="navbar-nav align-items-center">
          <div className="nav-item d-flex navbar-search-wrapper mb-0">
            {clicked ? null : (
              <a
                class="nav-item nav-link search-toggler px-0"
                onClick={() => setClicked(true)}
                href="javascript:void(0);"
              >
                <i class="bx bx-search bx-md"></i>
                <span class="d-none d-md-inline-block text-muted fw-normal ms-4">
                  Rechercher (Ctrl+S)
                </span>
              </a>
            )}
          </div>
        </div>
        {/* <!-- /Search --> */}

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* <!-- Place this tag where you want the button to render. --> */}

          {/* <!-- User --> */}
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <a
              className="nav-link dropdown-toggle hide-arrow p-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <div className="avatar avatar-sm me-2 avatar-online">
                <span className="avatar-initial rounded-circle bg-label-primary">
                  {LOGGED_USER?.personne?.prenom?.charAt(0)}
                  {LOGGED_USER?.personne?.nom?.charAt(0)}
                </span>
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-sm me-2">
                        <span className="avatar-initial rounded-circle bg-label-secondary">
                          {LOGGED_USER?.personne?.prenom?.charAt(0)}
                          {LOGGED_USER?.personne?.nom?.charAt(0)}
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-0">
                        {LOGGED_USER?.personne?.prenom}{" "}
                        {LOGGED_USER?.personne?.nom}
                      </h6>
                      <small className="text-muted">
                        {LOGGED_USER?.username}
                      </small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div className="dropdown-divider my-1"></div>
              </li>
              <li>
                <a className="dropdown-item" href="/mon-profil">
                  <i className="bx bx-user bx-md me-3"></i>
                  <span>Mon profil</span>
                </a>
              </li>
              {rightsService("HME051") ||
                (rightsService("HME050") && (
                  <li>
                    <a className="dropdown-item" href="/ma-compagnie">
                      <i className="bx bx-home bx-md me-3"></i>
                      <span>{LOGGED_USER.profil.organisme.raisonSociale}</span>
                    </a>
                  </li>
                ))}
              {LOGGED_USER?.agence && (
                <li>
                  <a className="dropdown-item" href="/mon-agence">
                    <i className="bx bxs-home bx-md me-3"></i>
                    <span>{LOGGED_USER?.agence?.nom}</span>
                  </a>
                </li>
              )}
              <li>
                <a className="dropdown-item" href="/#">
                  {" "}
                  <i className="bx bx-cog bx-md me-3"></i>
                  <span>Paramètres</span>{" "}
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/#">
                  <span className="d-flex align-items-center align-middle">
                    <i className="flex-shrink-0 bx bx-bell bx-md me-3"></i>
                    <span className="flex-grow-1 align-middle">
                      Notifications
                    </span>
                    <span className="flex-shrink-0 badge rounded-pill bg-danger">
                      0
                    </span>
                  </span>
                </a>
              </li>
              <li>
                <div className="dropdown-divider my-1"></div>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => logout(e)}
                >
                  <i className="bx bx-power-off bx-md me-3"></i>
                  <span>Déconnexion</span>
                </a>
              </li>
            </ul>
          </li>
          {/* <!--/ User --> */}
        </ul>
      </div>
      <div
        class={`navbar-search-wrapper search-input-wrapper ${clicked ? "" : "d-none"}`}
      >
        <span
          class="twitter-typeahead"
          style={{ position: "relative", display: "inline-block" }}
        >
          <input
            type="text"
            class="form-control search-input container-xxl border-0 tt-input"
            placeholder="Rechercher..."
            aria-label="Rechecher..."
            autocomplete="off"
            spellcheck="false"
            dir="auto"
            style={{ position: "relative", verticalAlign: "top" }}
            value={query}
            onChange={handleInputChange}
            onBlur={() => setClicked(!clicked)}
          />
          <div className="dropdown-menu show w-100 shadow">
            {filteredSuggestions.length > 0 ? (
              whereAmI === "/" ? (
                filteredSuggestions.map(({ category, items }) => (
                  <div key={category} className="px-3 py-2">
                    {/* Category title */}
                    <h6 className="text-muted text-uppercase mb-2">
                      {category}
                    </h6>
                    {items
                      .filter((item) =>
                        item.name.toLowerCase().includes(query.toLowerCase()),
                      )
                      .map((item, index) => (
                        <a
                          key={index}
                          href={item.url}
                          className="dropdown-item d-flex align-items-center gap-2 py-1"
                        >
                          <i className={`bx ${item.icon} text-muted`}></i>
                          <span className="text-truncate">{item.name}</span>
                        </a>
                      ))}
                  </div>
                ))
              ) : (
                <div className="px-3 py-2">
                  {/* Category title */}
                  <h6 className="text-muted text-uppercase mb-2"></h6>
                  {filteredSuggestions.map((item, index) => (
                    <a
                      key={index}
                      href={`/details-contrat/${item.codeId}`}
                      className="dropdown-item d-flex align-items-center gap-2 py-1"
                    >
                      <span className="text-truncate">
                        {item?.numeroPoliceM
                          ? item?.numeroPoliceM
                          : item?.numeroPoliceP}{" "}
                        - {frenchDate(item?.dateEffet)} -{" "}
                        {item.souscripteur.nom}
                      </span>
                    </a>
                  ))}
                </div>
              )
            ) : (
              <div className="dropdown-item text-center py-3">
                <i className="bx bx-search text-muted fs-5"></i>
                <p className="mb-0 text-muted">Aucun résultat trouvé</p>
              </div>
            )}
          </div>

          <pre
            aria-hidden="true"
            style={{
              position: "absolute",
              visibility: "hidden",
              whiteSpace: "pre",
              fontFamily:
                "Public Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
              fontSize: "15px",
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              wordSpacing: "0px",
              letterSpacing: "0px",
              textIndent: "0px",
              textRendering: "auto",
              textTransform: "none",
            }}
          >
            y
          </pre>
          <div
            class="tt-menu navbar-search-suggestion ps tt-empty"
            style={{
              position: "absolute",
              top: "100%",
              left: "0px",
              zIndex: "100",
              display: "none",
            }}
          >
            <div class="tt-dataset tt-dataset-pages"></div>
            <div class="tt-dataset tt-dataset-files"></div>
            <div class="tt-dataset tt-dataset-members"></div>
            <div class="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
              <div
                class="ps__thumb-x"
                tabindex="0"
                style={{ left: "0px", width: "0px" }}
              ></div>
            </div>
            <div
              class="ps__rail-y"
              style={{ top: "0px", right: "0px", height: "448px" }}
            >
              <div
                class="ps__thumb-y"
                tabindex="0"
                style={{ top: "0px", height: "0px" }}
              ></div>
            </div>
          </div>
        </span>
        <i
          class="bx bx-x bx-md search-toggler cursor-pointer"
          onClick={() => setClicked(false)}
        ></i>
      </div>
    </nav>
  );
};

export default NavBar;
