import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddEnergie from "./AddEnergie";

const { confirm } = Modal;

const Energies = () => {
  const [energies, setEnergies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedEnergie, setSelectedEnergie] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_URL}/api/convert-energie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setEnergies(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors du chargement des énergies");
      });
  }, []);

  const handleShow = (edit, energie = null) => {
    setEditMode(edit);
    setSelectedEnergie(energie);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const showDeleteConfirm = (convertEnergieId) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette conversion d'énergie?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteEnergie(convertEnergieId);
      },
    });
  };

  const deleteEnergie = (convertEnergieId) => {
    axios
      .delete(`${API_URL}/api/convert-energie/${convertEnergieId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setEnergies(energies.filter((en) => en.convertEnergieId !== convertEnergieId)); // Mettre à jour la liste
        toast.success("Conversion d'énergie supprimée avec succès");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors de la suppression");
      });
  };

  const columns = [
    {
      title: "Essence",
      dataIndex: "essence",
      key: "essence",
    },
    {
      title: "Diesel",
      dataIndex: "diesel",
      key: "diesel",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-icon rounded-pill"
            onClick={() => handleShow(true, record)} // Passe l'objet `record` complet
          >
            <i className="bx bx-edit-alt"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon rounded-pill"
            onClick={() => showDeleteConfirm(record.convertEnergieId)}
          >
            <i className="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false)}
              >
                Ajouter une conversion d'énergie
              </Button>

              <div className="card">
                <Table
                  columns={columns}
                  dataSource={energies}
                  rowKey="convertEnergieId"
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>

      <AddEnergie
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        convertEnergieId={selectedEnergie?.convertEnergieId} 
        selectedEnergie={selectedEnergie} 
      />
    </div>
  );
};

export default Energies;
