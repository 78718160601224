import { Table } from "antd";
import PropTypes from "prop-types";
import React from "react";
import entete from "../../../assets/img/entete.jpg";
import footer from "../../../assets/img/footer.png";
import { LOGGED_USER } from "../../../variables/constants";
import { frenchDateOnly } from "../../../variables/functions";
import "./styles/MICStyles.css";

const RecuDeVersementDePrimeEnCompagnie = React.forwardRef((props, ref) => {
    const primeNette = props.contrats.reduce((pn, contrat) => pn + contrat.reglement.montant, 0);

    const columns = [
        {
            title: "Police + Avenant",
            dataIndex: "numeroPoliceP",
            key: "numeroPoliceP",
            render: (_, record) => (
                <>{record.contrat.numeroPoliceP} - {record.reglement.avenant.code} </>
            ),
        },
        {
            title: "Assuré",
            dataIndex: "assure",
            key: "assure",
            render: (_, record) => (
                <>{record.contrat.souscripteur.nom} {record.contrat.souscripteur.prenom}</>
            ),
        },
        {
            title: "Date effet",
            dataIndex: "dateEffet",
            key: "dateEffet",
            render: (_, record) => (
                <>{frenchDateOnly(record.contrat.dateEffet)} </>
            ),
        },

        {
            title: "Date échéance",
            dataIndex: "dateEcheance",
            key: "dateEcheance",
            render: (_, record) => (
                <>{frenchDateOnly(record.contrat.dateEcheance)} </>
            ),
        },
        {
            title: "Prime payée",
            dataIndex: "primeNette",
            key: "primeNette",
            render: (_, record) => (
                <>{new Intl.NumberFormat("en-DE").format(record.reglement.montant)} </>
            ),
        },
        {
            title: "Compagnie",
            dataIndex: "compagnie",
            key: "compagnie",
            render: (_, record) => (
                <>{record.contrat.organisme.raisonSociale} </>
            ),
        },
        {
            title: "Versée le",
            dataIndex: "commissionPayeLe",
            key: "commissionPayeLe",
            render: (_, record) => (
                <>{frenchDateOnly(record.reglement.dateVersementCompagnie)} </>
            ),
        },
    ];

    const MyFooter = () => {
        return (
            <>
                <div>
                    <b>Total primes</b>:{" "}
                    {new Intl.NumberFormat("en-DE").format(primeNette)} F CFA
                </div>
            </>
        );
    };

    return (
        <div class="container d-flex justify-content-center" style={{ width: "100%!important", height: "45vh", marginBottom: "5px", padding: "10px", boxSizing: "border-box", pageBreakInside: "avoid" }}>
            <div class="row w-100">
                <div class="col-12" ref={ref}>
                    <div className="page-1">
                        <img src={entete} alt="Header" class="img-fluid" />
                        <div class="card testimonial-card">
                            <br />
                            <div class="text-center">
                                <h5>Reçu de versement de prime en compagnie - ASSURANCE AUTOMOBILE</h5>
                            </div>
                            <br />
                            <div class="">
                                <div class="row">
                                    <div class="col-12">
                                        <table class="table" border={0}>
                                            <tr>
                                                <td class="text-start">
                                                    <h7>
                                                        <b>Courtier: </b>
                                                        {props.contrats[0]?.contrat?.courtier?.nom}
                                                    </h7>
                                                </td>
                                                <td>
                                                    {/* <div class="border-end"></div> */}
                                                </td>
                                                <td class="text-end">
                                                    <h7>
                                                        <b>Téléphone : </b>
                                                        {props.contrats[0]?.contrat?.courtier?.contact ? props.contrats[0]?.contrat?.courtier?.contact[0] : ""}
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>
                                        <hr class="my-2" />
                                        <table class="table">
                                            <tr>
                                                <td class="text-start">
                                                    <h7>
                                                        <b>Code courtier: </b>
                                                        {props.contrats[0]?.contrat?.courtier?.codeAgence}
                                                    </h7>
                                                </td>
                                                <td>
                                                    {/* <div class="border-end"></div> */}
                                                </td>
                                                <td class="text-end">
                                                    <h7>
                                                        <b>Email courtier : </b>
                                                        {props?.contrats[0]?.contrat?.courtier?.email}
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <hr class="my-2" />
                                <br />
                                <br />
                                <div class="row justify-content-center">
                                    <div class="col-7">
                                        <table>

                                            <tr>
                                                <td class="text-start">
                                                    <h7>
                                                        <b>Montant total des primes : </b>
                                                    </h7>
                                                </td>
                                                <td class="text-end">
                                                    <h7>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(primeNette)
                                                        )} FCFA
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-10 text-end">
                                        <table class="table">
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>CAISSIER : </b>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>{LOGGED_USER.personne.nom}{" "}
                                                            {LOGGED_USER.personne.prenom}</b></h7>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-2 text-end"></div>
                                </div>
                            </div>
                        </div>
                        <img src={footer} alt="Footer" class="img-fluid" />
                        <br />
                        <hr className="divider" style={{ borderTop: "1px dashed #000", margin: "10px 0" }} />

                        <img src={entete} alt="Header" class="img-fluid" />
                        <div class="card testimonial-card">
                            <br />
                            <div class="text-center">
                                <h5>Reçu de versement de prime en compagnie - ASSURANCE AUTOMOBILE</h5>
                            </div>
                            <br />
                            <div class="">
                                <div class="row">
                                    <div class="col-12">
                                        <table class="table" border={0}>
                                            <tr>
                                                <td class="text-start">
                                                    <h7>
                                                        <b>Courtier: </b>
                                                        {props.contrats[0]?.contrat?.courtier?.nom}
                                                    </h7>
                                                </td>
                                                <td>
                                                    {/* <div class="border-end"></div> */}
                                                </td>
                                                <td class="text-end">
                                                    <h7>
                                                        <b>Téléphone : </b>
                                                        {props.contrats[0]?.contrat?.courtier?.contact ? props.contrats[0]?.contrat?.courtier?.contact[0] : ""}
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>
                                        <hr class="my-2" />
                                        <table class="table">
                                            <tr>
                                                <td class="text-start">
                                                    <h7>
                                                        <b>Code courtier: </b>
                                                        {props.contrats[0]?.contrat?.courtier?.codeAgence}
                                                    </h7>
                                                </td>
                                                <td>
                                                    {/* <div class="border-end"></div> */}
                                                </td>
                                                <td class="text-end">
                                                    <h7>
                                                        <b>Email courtier : </b>
                                                        {props?.contrats[0]?.contrat?.courtier?.email}
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <hr class="my-2" />
                                <br />
                                <br />
                                <div class="row justify-content-center">
                                    <div class="col-7">
                                        <table>

                                            <tr>
                                                <td class="text-start">
                                                    <h7>
                                                        <b>Montant total des primes : </b>
                                                    </h7>
                                                </td>
                                                <td class="text-end">
                                                    <h7>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(primeNette)
                                                        )} FCFA
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-12 text-end">
                                        <table class="table">
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>CAISSIER : </b>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h7>
                                                        <b>{LOGGED_USER.personne.nom}{" "}
                                                            {LOGGED_USER.personne.prenom}</b><br /></h7>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src={footer} alt="Footer" class="img-fluid" />
                        <br />
                    </div>
                    <hr className="divider" style={{ borderTop: "1px dashed #000", margin: "10px 0" }} />
                    <div className="page-2">
                        <img src={entete} alt="Header" class="" style={{
                            width: "1155px",
                            height: "125px"
                        }} />
                        <div class="card testimonial-card">
                            <div class="text-center">
                                <h5><u>Bordereau de versements des commissions</u></h5>
                            </div>
                            <div class="row">
                                <div className="col-lg-12">
                                    {/* <div className="container"> */}
                                    <Table
                                        tableHeaderColor="primary"
                                        coloredColls={[5]}
                                        colorsColls={["primary"]}
                                        columns={columns}
                                        dataSource={props.contrats}
                                        pagination={false}
                                        footer={() => <MyFooter />}
                                    />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <img src={footer} alt="Footer" class="img-fluid" />
                    </div>

                </div>
            </div>
        </div>
    )
});
RecuDeVersementDePrimeEnCompagnie.propTypes = {
    contrats: PropTypes.array,
};
export default RecuDeVersementDePrimeEnCompagnie