import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, Progress } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import { rightsService } from '../../../services/rightsService';
import { API_URL, LOGGED_USER, TOKEN } from '../../../variables/constants';
import { frenchDate } from '../../../variables/functions';
const { confirm } = Modal;

const DetailsLotAttestations = () => {
    const [lots, setLots] = useState([]);
    const [categories, setCategories] = useState([]);
    const [compagnie, setCompagnie] = useState("");
    const [affectationModalOpen, setAffectationModalOpen] = useState(false);
    const [orderModalOpen, setOrderModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstAttestation, setFirstAttestation] = useState(0);
    const [lastAttestation, setLastAttestation] = useState(0);
    const [nombre, setNombre] = useState(0);
    const [reload, setReload] = useState(false);
    const [selectedCategorie, setSelectedCategorie] = useState("M");
    const [selectedTypeAttestation, setSelectedTypeAttestation] = useState("SIMPLE");
    const [commentaire, setCommentaire] = useState("");

    const param = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const code = param.codeCompagnie ? param.codeCompagnie : LOGGED_USER.profil.organisme.code;
        axios
            .get(`${API_URL}/api/lot-attestation/compagnie/${code}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setLots(res.data);
                setReload(false);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/organisme/code/${code}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setCompagnie(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setCategories(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [reload, param.codeCompagnie]);

    const onSubmit = () => {
        if (nombre === lastAttestation - firstAttestation + 1) {
            const catg = categories.find(element => element.codeCategorie === selectedCategorie);
            let lot = {
                compagnie: compagnie,
                lot: [firstAttestation, lastAttestation],
                updatedLot: [firstAttestation, lastAttestation],
                categorie: catg,
                typeAttestation: selectedTypeAttestation
            }
            console.log("Loooot: ", lot);
            axios
                .post(`${API_URL}/api/lot-attestation`, JSON.stringify(lot), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log("Data Ressst", res);
                    if (res.status === 226) {
                        toast("Cet numéro d'attestation existe déjà!");
                    } else {
                        setAffectationModalOpen(false);
                        setReload(true);
                        toast("Lot d'attestation ajoutée avec succès");
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 226) {
                        toast("Cet numéro d'attestation existe déjà!");
                    } else {
                        toast("Erreur lors de l'ajout du lot d'attestation");
                    }
                    setLoading(false);
                });
        } else {
            toast("Veuillez vérifier les valeurs que vous avez saisi!");
        }
    }

    const onSubmitCommandeAttestation = () => {
        if (nombre) {
            const catg = categories.find(element => element.codeCategorie === selectedCategorie);
            let bonCommande = {
                commentaire: commentaire,
                nombre: nombre,
                categorie: catg,
                typeAttestation: selectedTypeAttestation,
                statutCommande: "INITIE"
            }

            axios
                .post(`${API_URL}/api/bon-commande`, JSON.stringify(bonCommande), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log("Data Ressst", res);
                    if (res.status === 226) {
                        toast("Cet numéro d'attestation existe déjà!");
                    } else {
                        setAffectationModalOpen(false);
                        setReload(true);
                        toast("Lot d'attestation ajoutée avec succès");
                    }
                    setLoading(false);
                    setOrderModalOpen(false);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 226) {
                        toast("Cet numéro d'attestation existe déjà!");
                    } else {
                        toast("Erreur lors de l'ajout du lot d'attestation");
                    }
                    setLoading(false);
                });
        } else {
            toast("Veuillez renseigner le nombre d'attestation que vous voulez commander!");
        }
    }

    const showReceptionAttestationConfirm = (lotA) => {
        confirm({
            title: "Etes-vous sûr de vouloir valider la réception du lot?",
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                validationReception(lotA);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const validationReception = (lotA) => {
        axios
            .get(`${API_URL}/api/lot-attestation/reception/${lotA}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row g-6 mb-6">
                                <div class="col-lg-12 col-xxl-12">
                                    <div class="card h-100">
                                        <div class="card-header d-flex align-items-center justify-content-between">
                                            <div class="card-title mb-0">
                                                <h5 class="m-0 me-2">Les lots d'attestations de {compagnie.raisonSociale}</h5>
                                            </div>
                                            {rightsService("HLA061") ? <div class="dropdown">
                                                <button
                                                    type="button" class="btn btn-primary"
                                                    onClick={() => setAffectationModalOpen(true)}
                                                >
                                                    Affecter un lot
                                                </button>
                                            </div> : null}
                                            {rightsService("HLA065") && LOGGED_USER.profil.organisme.typeOrganisme !== "POOL" ?
                                                <button
                                                    type="button" class="btn btn-primary"
                                                    onClick={() => setOrderModalOpen(true)}
                                                >
                                                    Effectuer une commande
                                                </button> : null
                                            }

                                        </div>
                                        <div class="card-body">
                                            <ul class="p-0 m-0">
                                                {lots.length ? lots.map((l, id) => (
                                                    <li class="d-flex mb-4 pb-1" key={id}>
                                                        <div class="chart-progress me-4" data-color="success" data-series="83" data-progress_variant="true">
                                                            <Progress type="circle" percent={(100 * ((l.lot.length) - (l.updatedLot.length)) / (l.lot.length)).toFixed(2)} />
                                                        </div>
                                                        <div class="row w-100 align-items-center">
                                                            <div class="col-8">
                                                                <div class="me-2">
                                                                    <h6 class="mb-1 text-success">{l.lot[0]}-{l.lot[l.lot.length - 1]}</h6>
                                                                    <p class="mb-0">  {l.dateReception ? `Livré le ${frenchDate(l.createdAt)}` : "Pas encore livré"} </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 text-end">
                                                                {l.dateReception ?
                                                                    <>
                                                                        <div class="badge bg-label-secondary">{(l.lot.length) - (l.updatedLot.length)}/{l.lot.length}</div>
                                                                        {rightsService("HLA063") ? <button
                                                                            type="button" class="btn btn-warning"
                                                                            onClick={() => navigate(`/dispatching-lot-attestation/${l.codeId}`)}
                                                                        >
                                                                            Dispatcher <i class="bx bx-chevron-right"></i>
                                                                        </button> : null}
                                                                    </> : rightsService("HLA064") ? <button
                                                                        type="button" class="btn btn-secondary"
                                                                        onClick={() => showReceptionAttestationConfirm(l.codeId)}
                                                                    >
                                                                        Valider réception
                                                                    </button> : null}
                                                            </div>
                                                        </div>
                                                    </li>

                                                )) : "Aucun lot assigné à la compagnie pour l'instant"}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <Modal open={affectationModalOpen} loading={loading} onOk={onSubmit} onCancel={() => setAffectationModalOpen(false)} zIndex={1900} centered>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Nouveau lot d'attestation </h4>
                    <p className="address-subtitle">
                        Affecter un nouveau lot d'attestation pour {compagnie?.raisonSociale}
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-5" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="firstAttestation">
                            Veuillez entrer le numéro de la première attestation
                        </label>
                        <input
                            className="form-control" id="firstAttestation" value={firstAttestation} name="firstAttestation"
                            onChange={(e) => {
                                setFirstAttestation(parseInt(e.target.value));
                                setLastAttestation(parseInt(e.target.value));
                            }}
                            placeholder="Entrez le numéro de la première attestation" type="number" />

                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="lastAttestation">
                            Le nombre d'attestation
                        </label>
                        <input className="form-control" id="lastAttestation" value={nombre} name="lastAttestation" onChange={(e) => setNombre(parseInt(e.target.value))} placeholder="Entrez le nombre d'attestation" type="number" />
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="lastAttestation">
                            Veuillez entrer le numéro de la première attestation si incorrect
                        </label>
                        <input className="form-control" id="lastAttestation" value={lastAttestation} name="lastAttestation" onChange={(e) => setLastAttestation(parseInt(e.target.value))} placeholder="Entrez le numéro de la dernière attestation" type="number" />
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="agence">
                            Veuillez sélectionner le type d'attestation
                        </label>
                        <select className="form-control" id="typeAttestation" value={selectedTypeAttestation} name="typeAttestation" onChange={(e) => setSelectedTypeAttestation(e.target.value)} placeholder="Sélectionner un type d'attestation">
                            <option value="SIMPLE"> SIMPLE </option>
                            <option value="CEDEAO"> CEDEAO </option>
                        </select>
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="agence">
                            Veuillez sélectionner la catégorie
                        </label>
                        <select className="form-control" id="agence" value={selectedCategorie} name="agence" onChange={(e) => setSelectedCategorie(e.target.value)} placeholder="Sélectionner une agence">
                            {categories?.map((cat, id) => (
                                <option key={id} value={cat.codeCategorie}> {cat.nom} </option>
                            ))}
                        </select>
                    </div>
                </form>
            </Modal>

            <Modal open={orderModalOpen} loading={loading} onOk={onSubmitCommandeAttestation} onCancel={() => setOrderModalOpen(false)} zIndex={1900} centered>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Commande de lot d'attestations </h4>
                    <p className="address-subtitle">
                        Effectuer une commande de lot(s) d'attestations
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-5" onSubmit={onSubmitCommandeAttestation}>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="firstAttestation">
                            Veuillez entrer le nombre d'attestation
                        </label>
                        <input
                            className="form-control" id="nombre" value={nombre} name="nombre"
                            onChange={(e) => {
                                setNombre(parseInt(e.target.value));
                            }}
                            placeholder="Entrez le nombre d'attestations que vous voulez commander" type="number" />
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="agence">
                            Veuillez sélectionner le type d'attestation
                        </label>
                        <select className="form-control" id="typeAttestation" value={selectedTypeAttestation} name="typeAttestation" onChange={(e) => setSelectedTypeAttestation(e.target.value)} placeholder="Sélectionner un type d'attestation">
                            <option value="SIMPLE"> SIMPLE </option>
                            <option value="CEDEAO"> CEDEAO </option>
                        </select>
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="agence">
                            Veuillez sélectionner la catégorie
                        </label>
                        <select className="form-control" id="agence" value={selectedCategorie} name="agence" onChange={(e) => setSelectedCategorie(e.target.value)} placeholder="Sélectionner une agence">
                            {categories?.map((cat, id) => (
                                <option key={id} value={cat.codeCategorie}> {cat.nom} </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="commentaire">
                            Un commentaire?
                        </label>
                        <textarea
                            className="form-control" id="commentaire" value={commentaire} name="commentaire"
                            onChange={(e) => {
                                setCommentaire(e.target.value);
                            }}
                            placeholder="Un commentaire pour des précisions sur la commande"
                            rows={3}
                        >
                        </textarea>
                    </div>
                </form>
            </Modal>
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    );
}

export default DetailsLotAttestations