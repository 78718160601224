import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Footer from '../../../components/Footer'
import NavBar from '../../../components/NavBar'
import SideBar from '../../../components/SideBar'
import { API_URL, TOKEN } from '../../../variables/constants'
import AddTauxCommission from './AddTauxCommission'
import EditTauxCommission from './EditTauxCommission'
const { confirm } = Modal;

const TauxCommissions = () => {
    const [tauxCommissions, setTauxCommissions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [codeId, setCodeId] = useState("");
    const [selectedTauxCommission, setSelectedTauxCommission] = useState(null);
    const navigate = useNavigate();

    const handleShow = (edit = false, codeId = "") => {
        setEditMode(edit);
        setCodeId(codeId);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setEditMode(false);
        setCodeId("");
    };

    const handleEditClose = () => setShowEditModal(false);

    const handleEditShow = (tc) => {
        setSelectedTauxCommission(tc);
        setShowEditModal(true);
    }

    const columns = [
        {
            title: "Type de commission",
            dataIndex: "libelleParametre",
            key: "libelleParametre",
        },
        {
            title: "Taux",
            dataIndex: "valeur",
            key: "valeur",
        },
        {
            title: "Catégorie",
            dataIndex: "categorie",
            key: "categorie",
            render: (cat) => (
                <button type="button" className={`btn rounded-pill ${cat?.nom === "MOTO" ? "btn-info" : "btn-warning"}`}>
                    {cat?.nom}
                </button>
            ),
        },
        {
            title: "Actions",
            key: "action",
            render: (_, record) => (
                <>
                    <button
                        type="button"
                        className="btn btn-secondary btn-icon rounded-pill"
                        onClick={() => {
                            handleEditShow(record);
                        }}
                    >
                        <i className="bx bx-edit-alt"></i>
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger btn-icon rounded-pill"
                        onClick={() => showDeleteConfirm(record.codeId)}
                    >
                        <i className="bx bx-trash"></i>
                    </button>
                </>
            ),
        },
    ];

    const showDeleteConfirm = (tauxCommissionId) => {
        confirm({
            title: "Etes-vous sûr de vouloir supprimer ce taux de commission?",
            icon: <ExclamationCircleFilled />,
            content: "Cela impliquera que toutes les données soient corrompues",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                deleteTauxCommission(tauxCommissionId);
            },
        });
    };

    const deleteTauxCommission = (tauxCommissionId) => {
        axios
            .delete(`${API_URL}/api/taux-commission/${tauxCommissionId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                toast("Suppression effectuée avec succès");
                window.location.reload();
            })
            .catch((err) => {
                console.error(err);
                toast.error("Erreur lors de la suppression");
            });
    };

    useEffect(() => {
        axios
            .get(`${API_URL}/api/parametre/taux-commissions`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                console.log("res", res.data);
                setTauxCommissions(res.data);
            })
            .catch((err) => {
                console.error(err);
                toast.error("Erreur lors de la récupération des taux de commission");
            });
    }, [showModal]);

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <SideBar currentMenu={"TARIFICATION"} />
                <div className="layout-page">
                    <NavBar />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                style={{ marginBottom: 16 }}
                                onClick={() => handleShow()}
                            >
                                Ajouter un taux de commission
                            </Button>
                            <div className="card">
                                <Table
                                    columns={columns}
                                    dataSource={tauxCommissions}
                                    rowKey="codeId"
                                    expandable={{
                                        expandedRowRender: (record) => (
                                            <p style={{ margin: 0 }}>{record.commentaire}</p>
                                        ),
                                    }}
                                    pagination={{ pageSize: 10 }}
                                />
                            </div>
                        </div>

                        <Footer />
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>

            <AddTauxCommission
                show={showModal}
                handleClose={handleClose}
                editMode={editMode}
                codeId={codeId}
                selectedTauxCommission={selectedTauxCommission}
            />

            <EditTauxCommission
                show={showEditModal}
                handleClose={handleEditClose}
                selectedTauxCommission={selectedTauxCommission}
            />

            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>
    )
}

export default TauxCommissions