// export const API_URL = "http://localhost:8088";
//export const API_URL = "http://130.61.148.139:8088";
export const API_URL = "https://poolinsapptest.ddns.net";

export const TOKEN =
  sessionStorage.getItem("n") +
  "." +
  sessionStorage.getItem("t") +
  "." +
  sessionStorage.getItem("k");
export const LOGGED_USER = JSON.parse(sessionStorage.getItem("user"));