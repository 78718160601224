import React from "react";

const VerifyEmail = () => {
  return (
    <div class="authentication-wrapper authentication-cover">
      {/* <!-- Logo --> */}
      {/* <a href="/#" class="app-brand auth-cover-brand gap-2">
        <span class="app-brand-text demo text-heading fw-bold">PoolIns</span>
      </a> */}
      {/* <!-- /Logo --> */}
      <div class="authentication-inner row m-0">
        {/* <!-- /Left Text -->  */}
        <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
          <div class="w-100 d-flex justify-content-center">
            <img
              src="assets/img/login.jpg"
              class="img-fluid scaleX-n1-rtl"
              alt="LoginImage"
              width="10000"
              data-app-dark-img="login.jpg"
              data-app-light-img="login.jpg"
            />
          </div>
        </div>
        {/* <!-- /Left Text --> */}

        {/* <!--  Verify email --> */}
        <div class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-6 p-sm-12">
          <div class="w-px-400 mx-auto mt-12 mt-5">
            <h4 class="mb-1">Vérifier votre boîte mail ✉️</h4>
            <p class="text-start mb-0">
              Lien d'activation de compte envoyé à votre adresse e-mail :
              hello@example.com Veuillez suivre le lien à l'intérieur pour
              continuer.
            </p>
            <a class="btn btn-primary w-100 my-6" href="/#">
              Passer pour l'instant{" "}
            </a>
            <p class="text-center mb-0">
              Vous n'avez pas reçu le mail?
              <a href="/#">Renvoyer</a>
            </p>
          </div>
        </div>
        {/* <!-- / Verify email --> */}
      </div>
    </div>
  );
};

export default VerifyEmail;
