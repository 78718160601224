import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_URL, TOKEN } from "../../variables/constants";
import axios from "axios";
import { toast } from "react-toastify";
// import { Select } from "antd";
// import Select from "react-select";

const AddHabilitation = ({ show, handleClose }) => {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);

  const [libelleHabilitation, setLibelleHabilitation] = useState("");
  const [descriptionHabilitation, setDescriptionHabilitation] = useState("");
  const [selectedModule, setSelectedModule] = useState(0);

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     console.log(name);
  //     console.log(value);
  //     console.log("habili: " + JSON.stringify(habilitation));
  //     setHabilitation({ ...habilitation, [name]: value });
  //   };

  const handleSelect = (selected) => {
    console.log("selec: " + selected);
    setSelectedModule(selected);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("type: ", typeof selectedModule);
    const mod = modules.find((e) => e.moduleId === parseInt(selectedModule));
    console.log(mod);
    const hab = {
      libelle: libelleHabilitation,
      description: descriptionHabilitation,
      module: mod,
    };
    console.log("hab; ", hab);
    axios
      .post(`${API_URL}/api/habilitation`, JSON.stringify(hab), {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        toast("Habilitation ajoutée avec succès");

        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors de l'ajout de l'habilitation");
        setLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/module`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        // let ms = [];
        // res.data.forEach((element) => {
        //   ms.push({
        //     label: element.nom,
        //     value: JSON.stringify(element),
        //   });
        // });
        // console.log(ms);
        setModules(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // setDatas(d);
  }, [TOKEN]);

  //   const modules = [
  //     { value: "Australia", label: "Australia" },
  //     { value: "Bangladesh", label: "Bangladesh" },
  //     { value: "Belarus", label: "Belarus" },
  //     { value: "Brazil", label: "Brazil" },
  //     // ... other countries
  //   ];

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">Ajouter une habilitation</h4>
          <p className="address-subtitle">
            Ajouter une nouvelle habilitation à un module
          </p>
        </div>
        <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalAddressFirstName">
              Libellé
            </label>
            <input
              type="text"
              id="modalAddressFirstName"
              onChange={(e) => setLibelleHabilitation(e.target.value)}
              className={`form-control`}
              placeholder="Libellé de l'habilitation"
              name="libelle"
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="modalAddressCountry">
              Module
            </label>
            <select
              id="modalEditUserLanguage"
              name="modalEditUserLanguage"
              class="select2 form-select"
              onChange={(e) => setSelectedModule(e.target.value)}
            >
              <option value="">Sélectionner le module</option>
              {modules?.map((mod, id) => (
                <option key={id} value={mod.moduleId}>
                  {mod.nom}
                </option>
              ))}
            </select>
            {/* <br />
            <Select
              id="modalAddressCountry"
              name="module"
              options={modules}
              //   className="select2 form-select"
              placeholder="Selectionner le module"
              onChange={handleSelect}
            /> */}
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalAddressFirstName">
              Description
            </label>
            <textarea
              type="text"
              id="modalAddressFirstName"
              onChange={(e) => setDescriptionHabilitation(e.target.value)}
              className={`form-control`}
              placeholder="Description de l'habilitation"
              name="description"
            ></textarea>
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  class="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? `Enregistrement...` : `Enregistrer`}
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddHabilitation;
