import React from "react";

const Logo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 897 512"
      enable-background="new 0 0 897 512"
    //   xml:space="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M522.000000,513.000000 
	C348.000000,513.000000 174.500000,513.000000 1.000000,513.000000 
	C1.000000,342.333344 1.000000,171.666672 1.000000,1.000000 
	C300.000000,1.000000 599.000000,1.000000 898.000000,1.000000 
	C898.000000,171.666672 898.000000,342.333344 898.000000,513.000000 
	C772.833313,513.000000 647.666687,513.000000 522.000000,513.000000 
M488.500000,326.009094 
	C506.497192,326.009064 524.501831,326.300873 542.487244,325.840698 
	C548.283936,325.692352 551.203125,328.031311 553.614136,333.104309 
	C412.607056,333.104309 272.021362,333.104309 131.235291,333.104309 
	C131.235291,338.491394 131.235291,343.554657 131.235291,349.005157 
	C133.102402,349.005157 134.729691,349.005157 136.356995,349.005157 
	C279.175598,349.004486 421.994293,349.060913 564.812500,348.824707 
	C571.533508,348.813599 575.661499,352.040009 581.422363,356.124756 
	C430.528717,356.124756 280.955597,356.124756 131.246964,356.124756 
	C131.246964,361.537689 131.246964,366.602448 131.246964,372.013031 
	C132.807312,372.013031 134.106476,372.013062 135.405640,372.013031 
	C303.388275,372.010040 471.370941,372.029602 639.353516,371.923950 
	C646.305298,371.919586 653.337646,371.216187 660.194824,370.037567 
	C720.701050,359.637451 762.900879,300.230591 752.748840,239.982849 
	C741.813416,175.086166 683.072815,133.608734 618.330933,145.068771 
	C558.691650,155.625595 516.218628,216.917709 527.736206,276.404602 
	C529.921814,287.692719 533.920288,298.629883 537.171082,310.040710 
	C402.321320,310.040710 266.803650,310.040710 131.293579,310.040710 
	C131.293579,315.628326 131.293579,320.693726 131.293579,326.009094 
	C250.237427,326.009094 368.868713,326.009094 488.500000,326.009094 
M161.809311,269.510223 
	C161.809311,265.440552 161.809311,261.370850 161.809311,257.530792 
	C171.491852,256.997650 180.373383,257.184479 189.039871,255.909592 
	C219.816818,251.382141 236.938766,229.935883 231.305237,196.645828 
	C228.728577,181.419556 219.422546,171.500717 204.907700,166.989349 
	C198.007629,164.844742 190.639511,163.446136 183.432816,163.201691 
	C166.960693,162.642914 150.457382,162.993591 133.966782,163.008575 
	C132.709579,163.009720 131.452606,163.276810 130.303101,163.407990 
	C130.303101,210.065720 130.303101,256.301697 130.303101,302.683838 
	C140.835526,302.683838 151.058167,302.683838 161.809052,302.683838 
	C161.809052,291.746613 161.809052,281.127258 161.809311,269.510223 
M242.144348,223.706055 
	C239.568481,231.006958 235.678665,238.130585 234.665268,245.642273 
	C231.724792,267.437866 238.294876,287.154816 258.378418,297.943420 
	C276.284607,307.562408 295.268829,308.018616 313.705688,299.620300 
	C336.728424,289.133057 345.743988,267.492828 342.494232,243.546143 
	C339.726990,223.154724 328.214813,209.193970 308.335693,203.619080 
	C300.192535,201.335403 291.203522,200.877228 282.694855,201.341095 
	C266.157043,202.242691 252.165558,208.908188 242.144348,223.706055 
M378.542511,297.026367 
	C393.108673,305.483154 408.784271,306.991699 424.859100,303.403687 
	C452.628448,297.205353 469.517090,271.151855 464.496796,243.075287 
	C460.592865,221.242188 447.466553,206.544617 426.531769,202.657089 
	C417.714355,201.019684 408.259674,201.015762 399.329712,202.138580 
	C377.484894,204.885239 361.916687,219.470749 357.336578,239.927521 
	C352.128418,263.189636 359.713531,284.072174 378.542511,297.026367 
M482.994354,169.505188 
	C482.994354,213.905838 482.994354,258.306488 482.994354,302.705505 
	C493.683441,302.705505 503.595306,302.705505 513.597412,302.705505 
	C513.597412,253.356812 513.597412,204.338425 513.597412,155.309204 
	C503.280029,155.309204 493.366821,155.309204 482.994354,155.309204 
	C482.994354,159.918365 482.994354,164.213547 482.994354,169.505188 
z"
      />
      <path
        fill="#252266"
        opacity="1.000000"
        stroke="none"
        d="
M488.000000,326.009094 
	C368.868713,326.009094 250.237427,326.009094 131.293579,326.009094 
	C131.293579,320.693726 131.293579,315.628326 131.293579,310.040710 
	C266.803650,310.040710 402.321320,310.040710 537.171082,310.040710 
	C533.920288,298.629883 529.921814,287.692719 527.736206,276.404602 
	C516.218628,216.917709 558.691650,155.625595 618.330933,145.068771 
	C683.072815,133.608734 741.813416,175.086166 752.748840,239.982849 
	C762.900879,300.230591 720.701050,359.637451 660.194824,370.037567 
	C653.337646,371.216187 646.305298,371.919586 639.353516,371.923950 
	C471.370941,372.029602 303.388275,372.010040 135.405640,372.013031 
	C134.106476,372.013062 132.807312,372.013031 131.246964,372.013031 
	C131.246964,366.602448 131.246964,361.537689 131.246964,356.124756 
	C280.955597,356.124756 430.528717,356.124756 581.422363,356.124756 
	C575.661499,352.040009 571.533508,348.813599 564.812500,348.824707 
	C421.994293,349.060913 279.175598,349.004486 136.356995,349.005157 
	C134.729691,349.005157 133.102402,349.005157 131.235291,349.005157 
	C131.235291,343.554657 131.235291,338.491394 131.235291,333.104309 
	C272.021362,333.104309 412.607056,333.104309 553.614136,333.104309 
	C551.203125,328.031311 548.283936,325.692352 542.487244,325.840698 
	C524.501831,326.300873 506.497192,326.009064 488.000000,326.009094 
M595.011230,230.612091 
	C595.011230,254.019363 595.011230,277.426636 595.011230,300.674622 
	C603.225769,300.674622 610.775574,300.674622 618.592896,300.674622 
	C618.592896,292.148407 618.566895,284.007782 618.600647,275.867401 
	C618.630371,268.709839 618.406616,261.531281 618.880371,254.400894 
	C619.395325,246.649765 625.427795,241.076645 632.469666,241.065369 
	C639.237549,241.054535 643.390686,245.410141 644.351501,253.866257 
	C644.725830,257.160553 644.706848,260.509125 644.717468,263.833466 
	C644.756470,276.100800 644.732971,288.368317 644.732971,300.686554 
	C652.777344,300.686554 660.307373,300.686554 667.917847,300.686554 
	C667.917847,283.174713 668.339417,266.020325 667.801086,248.896103 
	C667.245361,231.221252 655.707458,221.303085 638.783813,223.116791 
	C631.129395,223.937119 624.739136,227.626068 618.857178,233.637421 
	C618.659058,230.258194 618.510437,227.722549 618.360352,225.162689 
	C610.455933,225.162689 603.003479,225.162689 595.226257,225.162689 
	C595.149902,226.752274 595.080566,228.195709 595.011230,230.612091 
M721.722473,281.929932 
	C718.669434,287.694641 713.107727,287.873230 708.249939,286.421783 
	C702.116394,284.589203 696.461670,281.154205 690.124512,278.187683 
	C687.232422,281.947021 683.700806,286.537628 680.202759,291.084595 
	C692.394348,305.301636 721.294189,307.143585 735.065369,295.090790 
	C745.512756,285.947113 746.849792,262.420776 728.078796,255.639664 
	C724.824524,254.464035 721.386475,253.776840 718.007507,252.972290 
	C714.461853,252.128036 710.687195,251.871750 707.404480,250.452286 
	C705.731995,249.729065 703.838318,246.912979 704.001709,245.233261 
	C704.189758,243.299438 706.189819,240.771027 708.039917,239.967316 
	C710.288696,238.990433 713.383789,238.899353 715.789062,239.576004 
	C720.185242,240.812759 724.343445,242.895355 728.953796,244.768524 
	C732.278259,240.810776 735.880066,236.522919 739.749512,231.916428 
	C729.442749,223.463104 717.799072,221.716507 705.664734,223.507095 
	C692.241638,225.487854 683.675171,235.225128 683.197754,247.655563 
	C682.745239,259.439117 689.490601,267.359558 703.395447,271.101440 
	C707.713928,272.263580 712.190857,272.840454 716.502625,274.022919 
	C719.940186,274.965668 722.555847,276.932770 721.722473,281.929932 
M575.184021,244.500046 
	C575.184021,228.104187 575.184021,211.708328 575.184021,195.335724 
	C566.842468,195.335724 559.128113,195.335724 551.460449,195.335724 
	C551.460449,230.689667 551.460449,265.699707 551.460449,300.682220 
	C559.514587,300.682220 567.247314,300.682220 575.184082,300.682220 
	C575.184082,282.072052 575.184082,263.786072 575.184021,244.500046 
z"
      />
      <path
        fill="#A959F7"
        opacity="1.000000"
        stroke="none"
        d="
M161.809174,270.009094 
	C161.809052,281.127258 161.809052,291.746613 161.809052,302.683838 
	C151.058167,302.683838 140.835526,302.683838 130.303101,302.683838 
	C130.303101,256.301697 130.303101,210.065720 130.303101,163.407990 
	C131.452606,163.276810 132.709579,163.009720 133.966782,163.008575 
	C150.457382,162.993591 166.960693,162.642914 183.432816,163.201691 
	C190.639511,163.446136 198.007629,164.844742 204.907700,166.989349 
	C219.422546,171.500717 228.728577,181.419556 231.305237,196.645828 
	C236.938766,229.935883 219.816818,251.382141 189.039871,255.909592 
	C180.373383,257.184479 171.491852,256.997650 161.809311,257.530792 
	C161.809311,261.370850 161.809311,265.440552 161.809174,270.009094 
M169.539642,187.028625 
	C167.104797,187.028625 164.669952,187.028625 162.241302,187.028625 
	C162.241302,202.707092 162.241302,217.707962 162.241302,232.916473 
	C167.824005,232.916473 172.983978,233.211411 178.099152,232.857986 
	C190.568787,231.996429 198.567184,223.763885 199.007111,211.738968 
	C199.544052,197.062027 193.591690,189.310318 179.969986,187.300018 
	C176.870316,186.842560 173.665039,187.100845 169.539642,187.028625 
z"
      />
      <path
        fill="#AA59F6"
        opacity="1.000000"
        stroke="none"
        d="
M242.349045,223.407104 
	C252.165558,208.908188 266.157043,202.242691 282.694855,201.341095 
	C291.203522,200.877228 300.192535,201.335403 308.335693,203.619080 
	C328.214813,209.193970 339.726990,223.154724 342.494232,243.546143 
	C345.743988,267.492828 336.728424,289.133057 313.705688,299.620300 
	C295.268829,308.018616 276.284607,307.562408 258.378418,297.943420 
	C238.294876,287.154816 231.724792,267.437866 234.665268,245.642273 
	C235.678665,238.130585 239.568481,231.006958 242.349045,223.407104 
M271.865784,273.587982 
	C282.628204,285.583710 301.472290,283.063080 308.224670,268.575104 
	C312.146301,260.160889 312.557037,251.296005 310.350128,242.321777 
	C307.701538,231.551331 301.286255,225.810760 291.201019,225.066635 
	C280.228088,224.257050 271.769928,229.043930 268.009552,239.287384 
	C263.743225,250.909119 264.231140,262.398193 271.865784,273.587982 
z"
      />
      <path
        fill="#AA59F6"
        opacity="1.000000"
        stroke="none"
        d="
M378.237976,296.829407 
	C359.713531,284.072174 352.128418,263.189636 357.336578,239.927521 
	C361.916687,219.470749 377.484894,204.885239 399.329712,202.138580 
	C408.259674,201.015762 417.714355,201.019684 426.531769,202.657089 
	C447.466553,206.544617 460.592865,221.242188 464.496796,243.075287 
	C469.517090,271.151855 452.628448,297.205353 424.859100,303.403687 
	C408.784271,306.991699 393.108673,305.483154 378.237976,296.829407 
M433.040436,245.620270 
	C432.805511,244.484406 432.619873,243.335739 432.328064,242.214691 
	C429.554138,231.557770 423.387421,225.943527 413.535339,225.078125 
	C402.578491,224.115692 394.098999,228.869385 390.094482,239.041016 
	C386.157898,249.040100 386.355499,259.196320 390.955139,269.062653 
	C394.953522,277.639221 402.151489,280.968597 411.184357,280.947296 
	C420.003357,280.926544 426.505646,277.082581 430.159485,268.869232 
	C433.350800,261.695618 434.316620,254.224640 433.040436,245.620270 
z"
      />
      <path
        fill="#A958F7"
        opacity="1.000000"
        stroke="none"
        d="
M482.994354,169.006958 
	C482.994354,164.213547 482.994354,159.918365 482.994354,155.309204 
	C493.366821,155.309204 503.280029,155.309204 513.597412,155.309204 
	C513.597412,204.338425 513.597412,253.356812 513.597412,302.705505 
	C503.595306,302.705505 493.683441,302.705505 482.994354,302.705505 
	C482.994354,258.306488 482.994354,213.905838 482.994354,169.006958 
z"
      />
      <path
        fill="#F8F9F9"
        opacity="1.000000"
        stroke="none"
        d="
M595.011230,230.125610 
	C595.080566,228.195709 595.149902,226.752274 595.226257,225.162689 
	C603.003479,225.162689 610.455933,225.162689 618.360352,225.162689 
	C618.510437,227.722549 618.659058,230.258194 618.857178,233.637421 
	C624.739136,227.626068 631.129395,223.937119 638.783813,223.116791 
	C655.707458,221.303085 667.245361,231.221252 667.801086,248.896103 
	C668.339417,266.020325 667.917847,283.174713 667.917847,300.686554 
	C660.307373,300.686554 652.777344,300.686554 644.732971,300.686554 
	C644.732971,288.368317 644.756470,276.100800 644.717468,263.833466 
	C644.706848,260.509125 644.725830,257.160553 644.351501,253.866257 
	C643.390686,245.410141 639.237549,241.054535 632.469666,241.065369 
	C625.427795,241.076645 619.395325,246.649765 618.880371,254.400894 
	C618.406616,261.531281 618.630371,268.709839 618.600647,275.867401 
	C618.566895,284.007782 618.592896,292.148407 618.592896,300.674622 
	C610.775574,300.674622 603.225769,300.674622 595.011230,300.674622 
	C595.011230,277.426636 595.011230,254.019363 595.011230,230.125610 
z"
      />
      <path
        fill="#F8F8F9"
        opacity="1.000000"
        stroke="none"
        d="
M721.820679,281.545166 
	C722.555847,276.932770 719.940186,274.965668 716.502625,274.022919 
	C712.190857,272.840454 707.713928,272.263580 703.395447,271.101440 
	C689.490601,267.359558 682.745239,259.439117 683.197754,247.655563 
	C683.675171,235.225128 692.241638,225.487854 705.664734,223.507095 
	C717.799072,221.716507 729.442749,223.463104 739.749512,231.916428 
	C735.880066,236.522919 732.278259,240.810776 728.953796,244.768524 
	C724.343445,242.895355 720.185242,240.812759 715.789062,239.576004 
	C713.383789,238.899353 710.288696,238.990433 708.039917,239.967316 
	C706.189819,240.771027 704.189758,243.299438 704.001709,245.233261 
	C703.838318,246.912979 705.731995,249.729065 707.404480,250.452286 
	C710.687195,251.871750 714.461853,252.128036 718.007507,252.972290 
	C721.386475,253.776840 724.824524,254.464035 728.078796,255.639664 
	C746.849792,262.420776 745.512756,285.947113 735.065369,295.090790 
	C721.294189,307.143585 692.394348,305.301636 680.202759,291.084595 
	C683.700806,286.537628 687.232422,281.947021 690.124512,278.187683 
	C696.461670,281.154205 702.116394,284.589203 708.249939,286.421783 
	C713.107727,287.873230 718.669434,287.694641 721.820679,281.545166 
z"
      />
      <path
        fill="#F9F9FA"
        opacity="1.000000"
        stroke="none"
        d="
M575.184082,245.000061 
	C575.184082,263.786072 575.184082,282.072052 575.184082,300.682220 
	C567.247314,300.682220 559.514587,300.682220 551.460449,300.682220 
	C551.460449,265.699707 551.460449,230.689667 551.460449,195.335724 
	C559.128113,195.335724 566.842468,195.335724 575.184021,195.335724 
	C575.184021,211.708328 575.184021,228.104187 575.184082,245.000061 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M170.024002,187.029175 
	C173.665039,187.100845 176.870316,186.842560 179.969986,187.300018 
	C193.591690,189.310318 199.544052,197.062027 199.007111,211.738968 
	C198.567184,223.763885 190.568787,231.996429 178.099152,232.857986 
	C172.983978,233.211411 167.824005,232.916473 162.241302,232.916473 
	C162.241302,217.707962 162.241302,202.707092 162.241302,187.028625 
	C164.669952,187.028625 167.104797,187.028625 170.024002,187.029175 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M271.623535,273.318298 
	C264.231140,262.398193 263.743225,250.909119 268.009552,239.287384 
	C271.769928,229.043930 280.228088,224.257050 291.201019,225.066635 
	C301.286255,225.810760 307.701538,231.551331 310.350128,242.321777 
	C312.557037,251.296005 312.146301,260.160889 308.224670,268.575104 
	C301.472290,283.063080 282.628204,285.583710 271.623535,273.318298 
z"
      />
      <path
        fill="#FEFDFE"
        opacity="1.000000"
        stroke="none"
        d="
M433.068207,246.045044 
	C434.316620,254.224640 433.350800,261.695618 430.159485,268.869232 
	C426.505646,277.082581 420.003357,280.926544 411.184357,280.947296 
	C402.151489,280.968597 394.953522,277.639221 390.955139,269.062653 
	C386.355499,259.196320 386.157898,249.040100 390.094482,239.041016 
	C394.098999,228.869385 402.578491,224.115692 413.535339,225.078125 
	C423.387421,225.943527 429.554138,231.557770 432.328064,242.214691 
	C432.619873,243.335739 432.805511,244.484406 433.068207,246.045044 
z"
      />
    </svg>
  );
};

export default Logo;
