import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {rightsService} from "../services/rightsService";
import {LOGGED_USER} from "../variables/constants";
import Logo from "./Logo";

const SideBar = ({currentMenu}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
    const [isCashierMenuOpen, setIsCashierMenuOpen] = useState(false);
    const [isProductionMenuOpen, setIsProductionMenuOpen] = useState(false);
    const [isAttestationMenuOpen, setIsAttestationMenuOpen] = useState(false);
    const [isSinisterMenuOpen, setIsSinisterMenuOpen] = useState(false)
    const [activeMenu, setActiveMenu] = useState(0);
    const [openedMenu, setOpenedMenu] = useState(true);
    const [isCourtiersMenuOpen, setIsCourtiersMenuOpen] = useState(false);

    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleRightMenu = () => {
        setIsRightMenuOpen(!isRightMenuOpen);
    };

    const toggleAccountMenu = () => {
        setIsAccountMenuOpen(!isAccountMenuOpen);
    };

    const toggleCashierMenu = () => {
        setIsCashierMenuOpen(!isCashierMenuOpen);
    };

    const toggleProductionMenu = () => {
        setIsProductionMenuOpen(!isProductionMenuOpen);
    };

    const toggleAttestationMenu = () => {
        setIsAttestationMenuOpen(!isAttestationMenuOpen);
    };
    const toggleSinisterMenu = () => {
        setIsSinisterMenuOpen(!isSinisterMenuOpen);
    }

    const openMyMenu = () => {
        setOpenedMenu(!openedMenu);
    }

    const handleActiveMenu = (value) => {
        sessionStorage.setItem("menu", value);
        setActiveMenu(value);
    }

    const leftStyle = {
        position: 'absolute',
        top: '10px',
        left: '60px',
        transition: 'left 0.3s ease',
    };

    const rightStyle = {
        position: 'absolute',
        top: '10px',
        right: '-30px', // Adjust position based on the sidebar state
        transition: 'right 0.3s ease',
    };

    const toggleCourtiersMenu = () => {
        setIsCourtiersMenuOpen(!isCourtiersMenuOpen);
    };

    return (
        <aside
            id="layout-menu"
            className={`layout-menu menu-vertical menu bg-menu-theme ${openedMenu ? 'expanded' : 'collapsed'}`}
            style={{width: openedMenu ? '250px' : '80px', transition: 'width 0.3s ease'}}
        >
            <div className="app-brand demo">
                <a href="/" className="app-brand-link" style={{display: openedMenu ? "block" : "none"}}>
                    <Logo/>
                </a>
                <a href="javascript:void(0);"
                   tabIndex="0"
                   role="button" onClick={openMyMenu} className="layout-menu-toggle menu-link text-large ms-auto"
                   style={openedMenu ? rightStyle : leftStyle}
                >
                    <i className={`bx bx-chevron-${openedMenu ? 'left' : 'right'} bx-sm d-flex align-items-center justify-content-center`}></i>
                </a>
            </div>

            <div className="menu-inner-shadow"></div>

            <ul className="menu-inner py-1" style={{overflow: "scroll", scrollbarWidth: "none"}}>
                {/* <!-- Dashboards --> */}
                <li className={currentMenu === "DASHBOARD" ? "menu-item active" : "menu-item"}>
                    <a href="/#" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-home-smile"></i>
                        <div className="text-truncate" data-i18n="Dashboards"
                             style={{display: openedMenu ? "block" : "none"}}>
                            Tableau de bord
                        </div>
                    </a>
                </li>

                {/* <!-- Layouts --> */}
                {rightsService("HET023") ?
                    <li className={`menu-item ${currentMenu === "STATS" ? " active" : ""} ${isMenuOpen ? " open" : ""}`}>
                        <a
                            href="#"
                            className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                            onClick={(e) => {
                                e.preventDefault(); // Prevents the default anchor behavior
                                toggleMenu();
                            }}
                            tabIndex="0"
                            role="button"
                        >
                            <i className="menu-icon tf-icons bx bx-stats"></i>
                            <div className="text-truncate" data-i18n="Layouts"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Statistiques
                            </div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="/" className="menu-link">
                                    <div className="text-truncate" data-i18n="Etats Prod">
                                        Etats Production
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="/" className="menu-link">
                                    <div className="text-truncate" data-i18n="Etats Sin">
                                        Etats Sinistres
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li> : null}

                {/* <!-- Apps & Pages --> */}
                <li className="menu-header small text-uppercase" style={{display: openedMenu ? "block" : "none"}}>
                    <span className="menu-header-text">Production</span>
                </li>
                {rightsService("HCL024") ? <li className={currentMenu === "ASSURES" ? "menu-item active" : "menu-item"}>
                    <a href="/assures" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-user-pin"></i>
                        <div className="text-truncate" data-i18n="Email"
                             style={{display: openedMenu ? "block" : "none"}}>
                            Assurés
                        </div>
                    </a>
                </li> : null}
                {rightsService("HLA025") ?
                    <li className={`menu-item ${currentMenu === "PRODUCTION" ? " active" : ""} ${isProductionMenuOpen ? " open" : ""}`}>
                        <a href="/production" className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                           onClick={(e) => {
                               e.preventDefault(); // Prevents the default anchor behavior
                               toggleProductionMenu();
                           }}
                           tabIndex="0"
                           role="button"
                        >
                            <i className="menu-icon tf-icons bx bx-package"></i>
                            <div className="text-truncate" data-i18n="Production"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Production
                            </div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="/production" className="menu-link">
                                    <div className="text-truncate" data-i18n="Account">
                                        Tous les contrats
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="/contrats"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Notifications">
                                        Contrats en cours
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="/contrats-imprimes"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Connections">
                                        Contrats imprimés
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href={LOGGED_USER?.profil?.organisme?.typeOrganisme === "POOL" ? "/gestion-des-attestations" : "/mes-attestations"}
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Attestations">
                                        Gestion des attestations
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li> : null}
                {rightsService("HLA025") ?
                    <li className={`menu-item ${currentMenu === "ATTESTATION" ? " active" : ""} ${isAttestationMenuOpen ? " open" : ""}`}>
                        <a href={LOGGED_USER?.profil?.organisme?.typeOrganisme === "POOL" ? "/gestion-des-attestations" : "/mes-attestations"}
                           className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                           onClick={(e) => {
                               e.preventDefault(); // Prevents the default anchor behavior
                               toggleAttestationMenu();
                           }}
                           tabIndex="0"
                           role="button"
                        >
                            <i className="menu-icon tf-icons bx bx-layer"></i>
                            <div className="text-truncate" data-i18n="Production"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Attestations
                            </div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href={LOGGED_USER?.profil?.organisme?.typeOrganisme === "POOL" ? "/gestion-des-attestations" : "/mes-attestations"}
                                   className="menu-link">
                                    <div className="text-truncate" data-i18n="Account">
                                        Toutes les attestations
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="/commandes"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Notifications">
                                        Commandes
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li> : null}
                {rightsService("HLA026") || rightsService("HLA059") ?
                    <li className={`menu-item ${currentMenu === "COURTIERS" ? " active" : ""} ${isCourtiersMenuOpen ? " open" : ""}`}>
                        <a href="/production" className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                           onClick={(e) => {
                               e.preventDefault(); // Prevents the default anchor behavior
                               toggleCourtiersMenu();
                           }}
                           tabIndex="0"
                           role="button"
                        >
                            <i className="menu-icon tf-icons bx bx-folder"></i>
                            <div className="text-truncate" data-i18n="Production"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Apporteurs
                            </div>
                        </a>
                        <ul className="menu-sub">
                            {rightsService("HLA059") ? <li className="menu-item">
                                <a href="/intermediaires" className="menu-link">
                                    <div className="text-truncate" data-i18n="Account">
                                        Intermédiaires
                                    </div>
                                </a>
                            </li> : null}
                            {rightsService("HLA026") ? <li className="menu-item">
                                <a
                                    href="/courtiers"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Notifications">
                                        Courtiers
                                    </div>
                                </a>
                            </li> : null}
                        </ul>
                    </li> : null}
                {rightsService("HOP028") ?
                    <li className={currentMenu === "OPERATIONS" ? "menu-item active" : "menu-item"}>
                        <a href="/operations" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-calculator"></i>
                            <div className="text-truncate" data-i18n="Kanban"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Opérations
                            </div>
                        </a>
                    </li> : null}
                {rightsService("HLA029") ?
                    <li className={`menu-item ${currentMenu === "CAISSE" ? " active" : ""} ${isCashierMenuOpen ? " open" : ""}`}>
                        <a href="#" className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                           onClick={(e) => {
                               e.preventDefault(); // Prevents the default anchor behavior
                               toggleCashierMenu();
                           }}
                           tabIndex="0"
                           role="button"
                        >
                            <i className="menu-icon tf-icons bx bx-money"></i>
                            <div className="text-truncate" data-i18n="Cashier"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Caisse
                            </div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="/encaissements" className="menu-link">
                                    <div className="text-truncate" data-i18n="Account">
                                        Encaissements
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="pages-account-settings-notifications.html"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Notifications">
                                        Décaissements
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li> : null}
                {/* <!-- Pages --> */}
                {rightsService("HLA029") ?
                    <li className={`menu-item ${currentMenu === "COMPTABILITE" ? " active" : ""} ${isAccountMenuOpen ? " open" : ""}`}>
                        <a href="#" className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                           onClick={(e) => {
                               e.preventDefault(); // Prevents the default anchor behavior
                               toggleAccountMenu();
                           }}
                           tabIndex="0"
                           role="button">
                            <i className="menu-icon tf-icons bx bx-credit-card"></i>
                            <div className="text-truncate" data-i18n="Account Settings"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Comptabilité
                            </div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="pages-account-settings-account.html" className="menu-link">
                                    <div className="text-truncate" data-i18n="Account">
                                        Comptes
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="pages-account-settings-notifications.html"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Notifications">
                                        Versements-Compagnie
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="pages-account-settings-connections.html"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Connections">
                                        Cession au Pool
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="pages-account-settings-connections.html"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Connections">
                                        Mouvements
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="pages-account-settings-connections.html"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Connections">
                                        Décaissements
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="pages-account-settings-connections.html"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Connections">
                                        Grand Livre
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="pages-account-settings-connections.html"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Connections">
                                        Balance
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="pages-account-settings-connections.html"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Connections">
                                        Etats
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li> : null}
                <li className="menu-header small text-uppercase" style={{display: openedMenu ? "block" : "none"}}>
                    <span className="menu-header-text">Sinistres</span>
                </li>
                {rightsService("HSI027") ?
                    <li className={`menu-item ${currentMenu === "SINISTRES" ? " active" : ""} ${isSinisterMenuOpen ? " open" : ""}`}>
                        <a href="#" className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                           onClick={(e) => {
                               e.preventDefault();
                               toggleSinisterMenu();
                           }}
                           tabIndex="0"
                           role="button">
                            <i className="menu-icon tf-icons bx  bx-message-alt-x"></i>
                            <div className="text-truncate" data-i18n="Sinistres"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Dossiers sinistres
                            </div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="/liste-des-sinistres" className="menu-link">
                                    <div className="text-truncate" data-i18n="Account">
                                        Liste des sinistres
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="/evaluation-des-sinistres"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Notifications">
                                        Evaluation des sinistres
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a
                                    href="/declaration-sinistre"
                                    className="menu-link"
                                >
                                    <div className="text-truncate" data-i18n="Notifications">
                                        Déclaration de sinistres
                                    </div>
                                </a>
                            </li>
                        </ul>

                    </li> : null}
                {/* <!-- Components --> */}
                <li className="menu-header small text-uppercase" style={{display: openedMenu ? "block" : "none"}}>
                    <span className="menu-header-text">Paramètres</span>
                </li>
                {/* <!-- Cards --> */}
                {rightsService("HME030") ? <li className={currentMenu === "MEMBRES" ? "menu-item active" : "menu-item"}>
                    <a href="/membres" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-collection"></i>
                        <div className="text-truncate" data-i18n="Basic"
                             style={{display: openedMenu ? "block" : "none"}}>
                            Membres
                        </div>
                    </a>
                </li> : null}
                {/* <!-- User interface --> */}
                {rightsService("HPA031") ?
                    <li className={currentMenu === "TARIFICATION" ? "menu-item active" : "menu-item"}>
                        <a href="/tarification" className={openedMenu ? "menu-link menu-toggle" : "menu-link"}>
                            <i className="menu-icon tf-icons bx bx-slider-alt"></i>
                            <div className="text-truncate" data-i18n="User interface"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Tarification
                            </div>
                        </a>
                    </li> : null}

                {/* <!-- Extended components --> */}
                {rightsService("HPA032") ?
                    <li className={currentMenu === "AUTRES_PARAMETRES" ? "menu-item active" : "menu-item"}>
                        <a href="/autres-parametres" className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                           tabIndex="0"
                           role="button">
                            <i className="menu-icon tf-icons bx bx-cog"></i>
                            <div className="text-truncate" data-i18n="Extended UI"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Autres paramètres
                            </div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="extended-ui-perfect-scrollbar.html" className="menu-link">
                                    <div className="text-truncate" data-i18n="Perfect Scrollbar">
                                        Perfect Scrollbar
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="extended-ui-text-divider.html" className="menu-link">
                                    <div className="text-truncate" data-i18n="Text Divider">
                                        Text Divider
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li> : null}

                {/* <!-- Forms & Tables --> */}
                <li className="menu-header small text-uppercase" style={{display: openedMenu ? "block" : "none"}}>
                    <span className="menu-header-text">Droits &amp; Accès</span>
                </li>
                {/* <!-- Forms --> */}
                {rightsService("HPE033") ?
                    <li className={currentMenu === "UTILISATEURS" ? "menu-item active" : "menu-item"}>
                        <a href="/utilisateurs" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-user"></i>
                            <div className="text-truncate" data-i18n="Form Elements"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Utilisateurs
                            </div>
                        </a>
                    </li> : null}
                {rightsService("HAC034") ? <li className={currentMenu === "PROFILS" ? "menu-item active" : "menu-item"}>
                    <a href="/profils" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-check-shield"></i>
                        <div className="text-truncate" data-i18n="Form Layouts"
                             style={{display: openedMenu ? "block" : "none"}}>
                            Profils
                        </div>
                    </a>
                </li> : null}
                {rightsService("HAC035") ?
                    <li className={`menu-item ${currentMenu === "DROITS" ? " active" : ""} ${isRightMenuOpen ? " open" : ""}`}>
                        <a
                            href="#"
                            className={openedMenu ? "menu-link menu-toggle" : "menu-link"}
                            onClick={(e) => {
                                e.preventDefault(); // Prevents the default anchor behavior
                                toggleRightMenu();
                            }}
                            tabIndex="0"
                            role="button"
                        >
                            <i className="menu-icon tf-icons bx bx-ruler"></i>
                            <div className="text-truncate" data-i18n="Form Layouts"
                                 style={{display: openedMenu ? "block" : "none"}}>
                                Droits
                            </div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="/habilitations" className="menu-link">
                                    <div className="text-truncate" data-i18n="Vertical Form">
                                        Habilitations
                                    </div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="/modules" className="menu-link">
                                    <div className="text-truncate" data-i18n="Horizontal Form">
                                        Modules
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li> : null}

                <li className="menu-item">
                    <a href="https://ossanasur.com/support" className="menu-link">
                        <i className="menu-icon tf-icons bx bx-support"></i>
                        <div className="text-truncate" data-i18n="Support"
                             style={{display: openedMenu ? "block" : "none"}}>
                            Support
                        </div>
                    </a>
                </li>
                <li className="menu-item">
                    <a
                        href="https://ossanasur.com/poolins/documentation/"
                        className="menu-link"
                    >
                        <i className="menu-icon tf-icons bx bx-file"></i>
                        <div className="text-truncate" data-i18n="Documentation"
                             style={{display: openedMenu ? "block" : "none"}}>
                            Documentation
                        </div>
                    </a>
                </li>
            </ul>
        </aside>
    );
};

export default SideBar;
