import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";

const AddVille = ({ show, handleClose, editMode, selectedVille }) => {
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState("");

  // Populate form fields when in edit mode
  useEffect(() => {
    if (editMode && selectedVille) {
      setNom(selectedVille.libelleParametre || "");
    } else {
      setNom("");
    }
  }, [editMode, selectedVille]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const villeData = {
      ...selectedVille,
      libelleParametre: nom,
      typeParametre: "VILLE",
    };

    const request = editMode
      ? axios.put(`${API_URL}/api/parametre`, JSON.stringify(villeData), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
      : axios.post(`${API_URL}/api/parametre`, JSON.stringify(villeData), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });

    request
      .then((res) => {
        toast.success(editMode ? "Ville modifiée avec succès" : "Ville ajoutée avec succès");
        setNom("");
        handleClose();
      })
      .catch((err) => {
        console.error("Error:", err);
        toast.error(editMode ? "Erreur lors de la modification" : "Erreur lors de l'ajout");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la" : "Ajouter une"} ville
          </h4>
          <p className="address-subtitle">
            {editMode ? "Modifier la" : "Ajouter une nouvelle"} ville
          </p>
        </div>
        <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalAddressFirstName">
              Nom
            </label>
            <input
              type="text"
              id="modalAddressFirstName"
              onChange={(e) => setNom(e.target.value)}
              className="form-control"
              placeholder="Nom de la ville"
              name="nom"
              value={nom}
            />
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? `Enregistrement...` : `Enregistrer`}
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddVille;
