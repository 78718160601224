// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table .ant-table-cell {
    border-color: #000000 !important;
    /* Couleur bleue pour les bordures des cellules */
}

.custom-table .ant-table-thead>tr>th {
    border-color: #000000 !important;
    /* Couleur bleue pour les en-têtes */
}

.custom-table .ant-table-row>td {
    border-color: #000000 !important;
    /* Couleur bleue pour les lignes */
}`, "",{"version":3,"sources":["webpack://./src/pages/production/components/styles/BordereauStyles.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,iDAAiD;AACrD;;AAEA;IACI,gCAAgC;IAChC,oCAAoC;AACxC;;AAEA;IACI,gCAAgC;IAChC,kCAAkC;AACtC","sourcesContent":[".custom-table .ant-table-cell {\n    border-color: #000000 !important;\n    /* Couleur bleue pour les bordures des cellules */\n}\n\n.custom-table .ant-table-thead>tr>th {\n    border-color: #000000 !important;\n    /* Couleur bleue pour les en-têtes */\n}\n\n.custom-table .ant-table-row>td {\n    border-color: #000000 !important;\n    /* Couleur bleue pour les lignes */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
