import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { API_URL, TOKEN } from '../../variables/constants';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddMembres = ({ show, handleClose, editMode, selectedMembre }) => {
  const [raisonSociale, setRaisonSociale] = useState('');
  const [siegeSocial, setSiegeSocial] = useState('');
  const [code, setCode] = useState('');
  const [dateCreation, setDateCreation] = useState('');
  const [email, setEmail] = useState('');
  const [telephone1, setTelephone1] = useState('');
  const [telephone2, setTelephone2] = useState('');
  const [typeMembre, setTypeMembre] = useState('');
  const [responsable, setResponsable] = useState('');
  const [otpConnexion, setOtpConnexion] = useState('NONE');
  const [domaine, setDomaine] = useState('');
  const [website, setWebsite] = useState('');
  const [fileImage, setFileImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null); // Pour prévisualiser l'image téléversée
  const [loading, setLoading] = useState(false);

  // Charger les détails d'un organisme existant si nous sommes en mode édition
  useEffect(() => {
    if (editMode && selectedMembre) {
      axios
        .get(`${API_URL}/api/organisme/${selectedMembre.organismeId}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        })
        .then((response) => {
          const organisme = response.data;
          setRaisonSociale(organisme.raisonSociale);
          setSiegeSocial(organisme.siegeSocial);
          setCode(organisme.code);
          setDateCreation(organisme.dateCreation);
          setEmail(organisme.email);
          setTelephone1(organisme.contact[0]);
          setTelephone2(organisme.contact[1]);
          setTypeMembre(organisme.typeOrganisme);
          setResponsable(organisme.responsable);
          setOtpConnexion(organisme.otpConnexion);
          setDomaine(organisme.domaine);
          setWebsite(organisme.website);

          if (organisme.logo) {
            const logoUrl = `${API_URL}/api/organisme/logo/${organisme.logo.split('//')[2]}`;
            setImagePreviewUrl(logoUrl);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erreur lors du chargement des détails de l'organisme.");
        });
    }
  }, [editMode, selectedMembre]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileImage(file);

    // Générer l'aperçu de l'image sélectionnée
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!raisonSociale || !siegeSocial || !code || !dateCreation || !email || !telephone1 || !typeMembre) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      setLoading(false);
      return;
    }

    const organisme = {
      raisonSociale,
      siegeSocial,
      code,
      dateCreation,
      contact: [telephone1, telephone2].filter(Boolean),
      email,
      responsable,
      typeOrganisme: typeMembre,
      otpConnexion,
      domaine,
      website,
      active: true,
    };
    selectedMembre.raisonSociale = raisonSociale;
    selectedMembre.siegeSocial = siegeSocial;
    selectedMembre.code = code;
    selectedMembre.dateCreation = dateCreation;
    selectedMembre.email = email;
    selectedMembre.responsable = responsable;
    selectedMembre.typeOrganisme = typeMembre;
    selectedMembre.otpConnexion = otpConnexion;
    selectedMembre.domaine = domaine;
    selectedMembre.website = website;
    selectedMembre.active = true;
    selectedMembre.contact = [telephone1, telephone2];
    console.log("es ce que le select goon", selectedMembre);




    try {
      let response;
      if (editMode) {
        // Mettre à jour l'organisme (mode édition)
        response = await axios.put(`${API_URL}/api/organisme`, selectedMembre, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
        });
        toast.success("Organisme mis à jour avec succès !");
      } else {
        // Créer un nouvel organisme
        response = await axios.post(`${API_URL}/api/organisme`, organisme, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
        });
        toast.success("Organisme enregistré avec succès !");
      }

      const id = response.data.organismeId || selectedMembre?.organismeId;

      if (fileImage) {
        const formData = new FormData();
        const extension = fileImage.name.split('.').pop();
        const filename = `Logo_${raisonSociale.split(' ').join('-')}.${extension}`;
        formData.append('fichier', fileImage, filename);

        await axios.post(`${API_URL}/api/organisme/logo`, formData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      handleClose(); // Fermer le modal après soumission
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de l'enregistrement de l'organisme.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{editMode ? 'Modifier un organisme' : 'Ajouter un organisme'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Raison sociale</label>
              <input
                type="text"
                className="form-control"
                value={raisonSociale}
                onChange={(e) => setRaisonSociale(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Siège social</label>
              <input
                type="text"
                className="form-control"
                value={siegeSocial}
                onChange={(e) => setSiegeSocial(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Code du membre</label>
              <input
                type="text"
                className="form-control"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Date de création</label>
              <input
                type="date"
                className="form-control"
                value={dateCreation}
                onChange={(e) => setDateCreation(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Adresse email</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Numéro de téléphone 1</label>
              <input
                type="tel"
                className="form-control"
                value={telephone1}
                onChange={(e) => setTelephone1(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Numéro de téléphone 2</label>
              <input
                type="tel"
                className="form-control"
                value={telephone2}
                onChange={(e) => setTelephone2(e.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Type membre</label>
              <select
                className="form-select"
                value={typeMembre}
                onChange={(e) => setTypeMembre(e.target.value)}
                required
              >
                <option value="ASSURANCE">ASSURANCE</option>
                <option value="PARTENAIRE">PARTENAIRE</option>
                <option value="PARTENAIRE">CONCESSIONNAIRE</option>

              </select>
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Responsable</label>
              <input
                type="text"
                className="form-control"
                value={responsable}
                onChange={(e) => setResponsable(e.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">OTP Connexion</label>
              <select
                className="form-select"
                value={otpConnexion}
                onChange={(e) => setOtpConnexion(e.target.value)}
              >
                <option value="NONE">Désactiver</option>
                <option value="SMS">SMS</option>
                <option value="EMAIL">EMAIL</option>
                <option value="WHATSAPP">WHATSAPP</option>
              </select>
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Nom de domaine</label>
              <input
                type="text"
                className="form-control"
                value={domaine}
                onChange={(e) => setDomaine(e.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Site web</label>
              <input
                type="text"
                className="form-control"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div className="col-12 mb-3">
              {imagePreviewUrl && <img src={imagePreviewUrl} alt="Pevisualiser" className="img-fluid mb-3" />}
              <label className="form-label">Logo</label>
              <input
                type="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={handleImageChange}
              />
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? 'Enregistrement...' : editMode ? 'Mettre à jour' : 'Enregistrer'}
            </button>
            <button type="button" className="btn btn-secondary ms-3" onClick={handleClose}>
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddMembres;
