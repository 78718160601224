import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Space, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddCarosserie from "./AddCarosserie";

const Carosseries = () => {
  const [carosseries, setCarosseries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCarosserie, setSelectedCarosserie] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleShow = (edit = false, carosserie = null) => {
    setEditMode(edit);
    setSelectedCarosserie(carosserie);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedCarosserie(null);
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/parametre/type/CAROSSERIE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        setCarosseries(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Erreur lors de la récupération des carosseries.");
      });
  }, [showModal]);

  const handleDelete = (parametreId) => {
    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette carosserie ?",
      content: "Cette action est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk: () => {
        axios
          .delete(`${API_URL}/api/parametre/${parametreId}`, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          })
          .then(() => {
            setCarosseries((prev) =>
              prev.filter((carosserie) => carosserie.parametreId !== parametreId)
            );
            message.success("Carosserie supprimée avec succès.");
          })
          .catch((err) => {
            console.log(err);
            message.error("Erreur lors de la suppression de la carosserie.");
          });
      },
    });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu="TARIFICATION" />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow()}
              >
                Ajouter une nouvelle carosserie
              </Button>
              <div className="card">
                <h5 className="card-header">Paramétrage des carosseries</h5>
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Catégorie</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {carosseries.map((car) => (
                        <tr key={car.parametreId}>
                          <td>{car.libelleParametre}</td>
                          <td>{car.categorie?.nom}</td>
                          <td>
                            <Space size="middle">
                              <Button
                                type="link"
                                onClick={() => handleShow(true, car)}
                              >
                                <i className="bx bx-edit-alt me-1"></i> Modifier
                              </Button>
                              <Button
                                type="link"
                                danger
                                onClick={() => handleDelete(car.parametreId)}
                              >
                                <i className="bx bx-trash me-1"></i> Supprimer
                              </Button>
                            </Space>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <AddCarosserie
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedCarrosserie={selectedCarosserie}
      />
    </div>
  );
};

export default Carosseries;
