import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API_URL, LOGGED_USER, TOKEN } from '../../../variables/constants';

const AddTauxCommission = ({ show, handleClose, editMode = false, codeId, selectedTauxCommission }) => {
    const [categories, setCategories] = useState([]);
    const [nom, setNom] = useState("");
    const [taux, setTaux] = useState(0);
    const [selectedCategorie, setSelectedCategorie] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setCategories(res.data);
            })
            .catch((err) => {
                console.error(err);
                toast.error("Erreur lors de la récupération des catégories");
            });
    }, [TOKEN]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (!nom || !selectedCategorie) {
            toast.error("Veuillez remplir tous les champs requis.");
            return;
        }
        setLoading(true);

        const cat = categories.find(
            (e) => e.categorieId === parseInt(selectedCategorie)
        );

        const gar = {};
        gar.libelleParametre = nom;
        gar.categorie = cat;
        gar.valeur = taux;
        gar.typeParametre = "TAUX_COMMISSION";
        gar.organisme = LOGGED_USER.profil.organisme;


        // Log the data being sent before the request
        console.log("Data to be sent:", gar);
        console.log("Edit mode:", editMode);

        const apiMethod = editMode ? axios.put : axios.post;
        const apiUrl = `${API_URL}/api/parametre`;

        apiMethod(apiUrl, JSON.stringify(gar), {
            headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                console.log("Response from API:", res.data); // Log the response from the API
                setLoading(false);
                toast(editMode ? "Taux de commission modifié avec succès" : "Taux de commission ajouté avec succès");
                handleClose();
            })
            .catch((err) => {
                console.error("Error during submission:", err);
                toast.error(
                    editMode ? "Erreur lors de la modification du taux de commission" : "Erreur lors de l'ajout du taux de commission"
                );
                setLoading(false);
            });
    };


    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Body>
                <button
                    type="button"
                    className="btn-close btn-primary"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">
                        Ajouter un taux de commission
                    </h4>
                    <p className="add-subtitle">
                        {editMode ? "Modifier le" : "Ajouter un nouveau"} taux de commission suivant le type d'intermédiaire
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <label className="form-label" htmlFor="modalAddressFirstName">
                            Type d'intermédiaire
                        </label>
                        <input
                            type="text"
                            id="modalAddressFirstName"
                            onChange={(e) => setNom(e.target.value)}
                            className="form-control"
                            placeholder="Type d'intermédiaire"
                            name="nom"
                            value={nom}
                        />
                    </div>
                    <div className="col-12 col-md-12">
                        <label className="form-label" htmlFor="taux">
                            Taux
                        </label>
                        <input
                            type="number"
                            id="taux"
                            onChange={(e) => setTaux(e.target.value)}
                            className="form-control"
                            placeholder="Valeur du taux"
                            name="taux"
                            value={taux}
                        />
                    </div>
                    <div className="col-12">
                        <label className="form-label" htmlFor="modalAddressCountry">
                            Catégorie
                        </label>
                        <select
                            id="modalEditUserLanguage"
                            name="modalEditUserLanguage"
                            className="select2 form-select"
                            onChange={(e) => setSelectedCategorie(e.target.value)}
                            value={selectedCategorie}
                        >
                            <option value="">Sélectionner la catégorie</option>
                            {categories.map((cat) => (
                                <option key={cat.categorieId} value={cat.categorieId}>
                                    {cat.nom}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12 text-center">
                        <button
                            type="submit"
                            className="btn btn-primary me-3"
                            disabled={loading}
                        >
                            {loading && (
                                <span
                                    className="spinner-grow me-1"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            {loading ? `Enregistrement...` : `Enregistrer`}
                        </button>
                        <button
                            type="reset"
                            className="btn btn-label-secondary"
                            onClick={handleClose}
                            disabled={loading}
                        >
                            Annuler
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddTauxCommission