import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";

const ProfileNotifications = () => {
  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row">
                <div class="col-md-12">
                  <div class="nav-align-top">
                    <ul class="nav nav-pills flex-column flex-md-row mb-6">
                      <li class="nav-item">
                        <a class="nav-link " href="/mon-profil">
                          <i class="bx bx-sm bx-user me-1_5"></i> Compte
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="/mon-profil-securite">
                          <i class="bx bx-sm bx-lock-alt me-1_5"></i> Sécurité
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="/mon-profil-notifications"
                        >
                          <i class="bx bx-sm bx-bell me-1_5"></i> Notifications
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="card mb-6">
                    <h5 class="card-header">Mes notifications</h5>
                    <div class="card-body pt-1"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default ProfileNotifications;
