export const verifyNumber = (value) => {
    const numberRex = new RegExp("^[0-9]+$");
    return numberRex.test(value);
};

export const verifyDouble = (value) => {
    console.log("Data type: ", typeof value);
    const numberRex = new RegExp("^(?:\\d{0,5}\\.\\d{1,5})$|^\\d{0,5}$");
    return !numberRex.test(value);
};

export const customVerifyDouble = (value) => {
    if (isNaN(parseFloat(value))) {
        return false;
    }
    if (value.split(".")[1]?.length > 5 || parseFloat(value) > 100) {
        return false;
    }
    return true;
};

export const isToday = (dateString) => {
    const inputDate = new Date(dateString);
    const today = new Date();

    return (
        inputDate.getDate() === today.getDate() &&
        inputDate.getMonth() === today.getMonth() &&
        inputDate.getFullYear() === today.getFullYear()
    );
}

export const isEqualDate = (dateString, date) => {
    const inputDate = new Date(dateString);
    const day = new Date(date);

    return (
        inputDate.getDate() === day.getDate() &&
        inputDate.getMonth() === day.getMonth() &&
        inputDate.getFullYear() === day.getFullYear()
    );
}

export const isBetweenOrEqualDate = (date, startDate, endDate) => {
    const targetDate = new Date(date).getTime();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    return targetDate >= start && targetDate <= end;
}

export const isTomonth = (dateString) => {
    const inputDate = new Date(dateString);
    const today = new Date();

    return (
        inputDate.getMonth() === today.getMonth() &&
        inputDate.getFullYear() === today.getFullYear()
    );
}

export const customVerifyPhoneNumber = (value) => {
    if (isNaN(parseFloat(value))) {
        return false;
    }
    return parseFloat(value) > 0;
};

export const customVerifyDoubleWithMin = (value, min) => {
  if (isNaN(parseFloat(value))) {
    return false;
  } else {
    if (value < min) {
      return false;
    } else {
      return true;
    }
  }
};

export const verifyLength = (value, length) => value?.length >= length;

export const verifyEmpty = (value, length) => value.length >= length;

export const verifyEmail = (value) => {
    const emailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRex.test(value);
};

export const dateView = (date) => {
    const [datePart, timePart] = date.split("T");
    return `${datePart} - ${timePart?.split(".")[0] || timePart}`;
};

export const frenchDate = (date) => {
    if (date) {
        const [datePart, timePart] = date?.split("T");
        const [year, month, day] = datePart?.split("-");
        return `${day}/${month}/${year} - ${timePart?.split(".")[0] || ""}`;
    } else {
        return "";
    }

};

export const frenchDateOnly = (date) => {
    const [year, month, day] = date?.split("T")[0]?.split("-");
    return `${day}/${month}/${year}`;
};

export const frenchDateSimple = (date) => {
    const [datePart, timePart] = date.split("T");
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year} - ${timePart || ""}`;
};

export const frenchMonth = (month) => {
    const months = {
        "01": "Janvier", "02": "Février", "03": "Mars", "04": "Avril",
        "05": "Mai", "06": "Juin", "07": "Juillet", "08": "Août",
        "09": "Septembre", "10": "Octobre", "11": "Novembre", "12": "Décembre"
    };
    return months[month] || "";
};

const ones = ["", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
const tens = ["", "dix", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingt", "quatre-vingt-dix"];

export const numberToWordsFrench = (num) => {
    if (num === 0) return "zéro";

    let words = "";
    if (num < 0) {
        words += "moins ";
        num = Math.abs(num);
    }

    if (num >= 1_000_000_000) {
        words += numberToWordsFrench(Math.floor(num / 1_000_000_000)) + " milliard ";
        num %= 1_000_000_000;
    }
    if (num >= 1_000_000) {
        words += numberToWordsFrench(Math.floor(num / 1_000_000)) + " million ";
        num %= 1_000_000;
    }
    if (num >= 1_000) {
        words += numberToWordsFrench(Math.floor(num / 1_000)) + " mille ";
        num %= 1_000;
    }
    if (num >= 100) {
        words += ones[Math.floor(num / 100)] + " cent ";
        num %= 100;
    }

    if (num >= 10) {
        words += tens[Math.floor(num / 10)];
        if (num % 10 !== 0) words += "-";
        num %= 10;
    }
    if (num >= 1) words += ones[num];

    return words.trim().toUpperCase();
};

export const getvalue = (event) => {
    return event.target.value;
};

export const getIdentificateur = (event) => {
    if (event.target.id) {
        return event.target.id;
    }
    if (event.target.name) {
        return event.target.name;
    }
};

export const setValue = (
    valeur,
    identificateur,
    stateSetter,
    errorStateSetter,
    typeOfControl
) => {
    stateSetter((lastState) => {
        return {
            ...lastState,
            [identificateur]: valeur,
        };
    });
    errorStateChecker(identificateur, valeur, errorStateSetter, typeOfControl);
};

export const checkString = (input) => {
    if (input === null) {
        return true;
    }
    if (input?.trim().length < 1 || !isNaN(input)) {
        return true;
    }
    return false;
};

export const checkDate = (input) => {
    if (input === null) {
        return true;
    }
    if (input?.trim().length < 1 || !isNaN(input)) {
        return true;
    }
    const date = new Date(input);
    const now = new Date();
    if (date <= now) {
        return true;
    }

    return false;
};

export const checkNumber = (input) => {
    if (input === null) {
        return true;
    }
    if (input.length < 1 || isNaN(input)) {
        return true;
    }
    return false;
};

export const errorStateChecker = (
    identificateur,
    valeur,
    errorStateSetter,
    typeOfControl
) => {
    console.log("Valeurrrr: ", valeur);
    if (typeOfControl === "string") {
        errorStateSetter((lastState) => {
            // console.log({ ...lastState, [identificateur]: checkString(valeur) });
            return {...lastState, [identificateur]: checkString(valeur)};
        });
    }
    if (typeOfControl === "number") {
        errorStateSetter((lastState) => {
            // console.log({ ...lastState, [identificateur]: checkNumber(valeur) });
            return {...lastState, [identificateur]: checkNumber(valeur)};
        });
    }
    if (typeOfControl === "date") {
        errorStateSetter((lastState) => {
            return {...lastState, [identificateur]: checkString(valeur)};
        });
    }
};

export const toutesLesValeursSontValides = (objet) => {
    for (let key in objet) {
        if (objet[key] !== false) {
            return false;
        }
    }
    return true;
};

export const formaterLesClients = (listeDesClients) => {
    return listeDesClients
        .map((client, index) => {
            if (client.numeroAssureM !== null && client.nom !== null) {
                const label =
                    client.prenom !== null
                        ? `${client.nom} ${client.prenom}-${client.numeroAssureM}-${index}`
                        : `${client.nom}-${client.numeroAssureM}-${index}`;
                return {
                    value: client.personneId,
                    label: label.trim(),
                    numeroAssure: client.numeroAssureM,
                };
            }
            return null;
        })
        .filter((value) => value !== null);
};

export const formaterLesContrats = (listeDesContrats) => {
    return listeDesContrats
        .map((contrat) => {
            if (contrat.numeroPoliceM !== null) {
                const label =
                    contrat.souscripteur.prenom !== null
                        ? `${contrat.numeroPoliceM} - ${contrat.souscripteur.nom} ${contrat.souscripteur.prenom}`
                        : `${contrat.numeroPoliceM} - ${contrat.souscripteur.nom}`;
                return {
                    value: contrat.codeId,
                    label: label.trim(),
                    numeroPolice: contrat.codeId,
                    contrat: contrat,
                };
            } else if (
                contrat.numeroPoliceP !== null &&
                contrat.souscripteur !== null
            ) {
                const label =
                    contrat?.souscripteur?.prenom !== null
                        ? `${contrat.numeroPoliceP} - ${contrat.souscripteur.nom} ${contrat.souscripteur.prenom}`
                        : `${contrat.numeroPoliceP} - ${contrat.souscripteur.nom}`;
                return {
                    value: contrat.codeId,
                    label: label.trim(),
                    numeroPolice: contrat.codeId,
                    contrat: contrat,
                };
            }
            return null;
        })
        .filter((value) => value !== null);
};

// Get engins from contrat
export const getEnginsFromContrat = (contrat) => {
    if (contrat.engins !== null) {
        return contrat.engins.map((engin) => {
            return {
                value: engin.codeId,
                label: engin.immatriculation,
                engin: engin,
            };
        });
    }
    return [];
};

// Formater les entites

export const formaterLesEntites = (listeDesEntites) => {
    return listeDesEntites.map((entite) => {
        return {
            value: entite.entiteConstatId,
            label: `${entite.nom} - ${entite.adresse}`,
            entite: entite,
        };
    });
};
