import { DownOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Menu,
  Space,
  Table,
  Tooltip,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { API_URL, TOKEN } from "../../variables/constants";
import AddHabilitation from "./AddHabilitation";

const Habilitations = () => {
  const [habilitations, setHabilitations] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/habilitation`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        setHabilitations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showModal]);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Réinitialiser
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
  });

  const columns = [
    {
      title: "Code",
      dataIndex: "codeHabilitation",
      key: "codeHabilitation",
    },
    {
      title: "Intitulé",
      dataIndex: "libelle",
      key: "libelle",
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
      filters: [
        { text: "Gestion du personnel", value: "Gestion du personnel" },
        { text: "Gestion des sinistres", value: "Gestion des sinistres" },
        { text: "Gestion de la production", value: "Gestion de la production" },
        {
          text: "Gestion de la comptabilité",
          value: "Gestion de la comptabilité",
        },
        { text: "Gestion des membres", value: "Gestion des membres" },
        { text: "Gestion des paramètres", value: "Gestion des paramètres" },
        { text: "Gestion des accès", value: "Gestion des accès" },
        { text: "Statistiques et Etats", value: "Statistiques et Etats" },
        { text: "Gestion des opérations", value: "Gestion des opérations" },
        { text: "Gestion des clients", value: "Gestion des clients" },
      ],
      filteredValue: filteredInfo.module?.nom || null,
      onFilter: (value, record) => record.module?.nom?.includes(value),
      render: (module) => {
        let color;
        switch (module?.nom) {
          case "Gestion du personnel":
            color = "grey";
            break;
          case "Gestion de la production":
            color = "blue";
            break;
          case "Gestion des sinistres":
            color = "red";
            break;
          case "Gestion de la comptabilité":
            color = "green";
            break;
          case "Statistiques et Etats":
            color = "yellow";
            break;
          case "Gestion des paramètres":
            color = "purple";
            break;
          case "Gestion des clients":
            color = "cyan";
            break;
          case "Gestion des accès":
            color = "orange";
            break;
          default:
            color = "grey";
        }
        return (
          <Tooltip title={module?.nom}>
            <Badge color={color} text={module?.nom} />
          </Tooltip>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">Modifier</Menu.Item>
              <Menu.Item key="2">Supprimer</Menu.Item>
            </Menu>
          }
        >
          <Button icon={<DownOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"DROITS"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => {
                  handleShow();
                }}
              >
                Ajouter une habilitation
              </Button>
              <div class="card">
                <Table
                  columns={columns}
                  dataSource={habilitations}
                  onChange={handleChange}
                  rowKey="habilitationId"
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.description}</p>
                    ),
                  }}
                  pagination={{ pageSize: 10 }}
                />
              </div>
              <Footer />
            </div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>
      <AddHabilitation show={showModal} handleClose={handleClose} />
      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default Habilitations;
