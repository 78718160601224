import PropTypes from "prop-types";
import React from "react";
import entete from "../../../assets/img/entete.jpg";
import footer from "../../../assets/img/footer.png";
import { LOGGED_USER } from "../../../variables/constants";
import { frenchDate, frenchDateSimple } from "../../../variables/functions";

const InvoiceCommissionPayment = React.forwardRef((props, ref) => {
    const datas = [
        {
            libelle: "Montant total de la prime:",
            valeur: `${new Intl.NumberFormat("en-DE").format(
                Math.trunc(props?.contrat?.data?.montant)
            )} FCFA`,
        },
        {
            libelle: "Montant de la commission :",
            valeur: `${new Intl.NumberFormat("en-DE").format(
                Math.trunc(props?.contrat?.data?.commission)
            )} FCFA`,
        },
        {
            libelle: "Payé le :",
            valeur: `${frenchDate(props?.contrat?.data?.commissionPayeLe)} FCFA`,
        },
    ];
    return (
        <div class="container d-flex justify-content-center" style={{ width: "100%!important", height: "45vh", marginBottom: "5px", padding: "10px", boxSizing: "border-box", pageBreakInside: "avoid" }}>
            <div class="row w-100">
                <div class="col-12" ref={ref}>
                    <img src={entete} alt="Header" class="" style={{
                        width: "905px",
                        height: "125px"
                    }} />
                    <div class="card testimonial-card">
                        <div class="text-center">
                            <h5>Reçu {props.contrat?.data?.montant < 0 ? "d'encaissement" : "de paiement"} de commission - ASSURANCE AUTOMOBILE</h5>
                        </div>
                        <div class="">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table" border={0}>
                                        <tr>
                                            <td class="text-start">
                                                <h7>
                                                    <b>Courtier/Intermédiaire: </b>
                                                    {props?.contrat?.contrat?.courtier?.nom}
                                                </h7>
                                            </td>
                                            <td>
                                                {/* <div class="border-end"></div> */}
                                            </td>
                                            <td class="text-end">
                                                <h7>
                                                    <b>Avenant : </b>
                                                    {props?.contrat?.contrat?.avenant?.nom}
                                                </h7>
                                            </td>
                                        </tr>
                                    </table>
                                    <hr class="my-2" />
                                    <table class="table">
                                        <tr>
                                            <td class="text-start">
                                                <h7>
                                                    <b>Code courtier/intermédiaire: </b>
                                                    {props.contrat?.contrat?.courtier?.codeAgence}
                                                </h7>
                                            </td>
                                            <td>
                                                {/* <div class="border-end"></div> */}
                                            </td>
                                            <td class="text-end">
                                                <h7>
                                                    <b>N° de Police : </b>
                                                    {props.contrat?.contrat?.numeroPoliceM
                                                        ? props.contrat?.contrat?.numeroPoliceM
                                                        : props.contrat?.contrat?.numeroPoliceP}
                                                </h7>
                                            </td>
                                        </tr>
                                    </table>
                                    <hr class="my-2" />
                                    <table class="table">
                                        <tr>
                                            <td class="text-start">
                                                <h7>
                                                    <b>Date effet: </b>
                                                    {frenchDate(props.contrat?.contrat?.dateEffet)}
                                                </h7>
                                            </td>
                                            <td>
                                                {/* <div class="border-end"></div> */}
                                            </td>
                                            <td class="text-end">
                                                <h7>
                                                    <b>Date échéance : </b>
                                                    {frenchDateSimple(props.contrat?.contrat?.dateEcheance)}
                                                </h7>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <hr class="my-2" />
                            <br />
                            <br />
                            <div class="row justify-content-center">
                                <div class="col-7">
                                    <table>
                                        {datas?.map((d, id) => (
                                            <tr key={id}>
                                                <td class="text-start">
                                                    <h7>
                                                        <b>{d.libelle} </b>
                                                    </h7>
                                                </td>
                                                <td class="text-end">
                                                    <h7>
                                                        {d.valeur}
                                                    </h7>
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-12 text-end">
                                    <table class="table">
                                        <tr>
                                            <td>
                                                <h7>
                                                    <b>CAISSIER : </b>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </h7>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h7>
                                                    <b>{LOGGED_USER.personne.nom}{" "}
                                                        {LOGGED_USER.personne.prenom}</b><br /></h7>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={footer} alt="Footer" class="img-fluid" />
                    <hr className="divider" style={{ borderTop: "1px dashed #000", margin: "10px 0" }} />
                    <img src={entete} alt="Header" class="" style={{
                        width: "905px",
                        height: "125px"
                    }} />
                    <div class="card testimonial-card">
                        <div class="text-center">
                            <h5>Reçu {props.contrat?.data?.montant < 0 ? "d'encaissement" : "de paiement"} de commission - ASSURANCE AUTOMOBILE</h5>
                        </div>
                        <div class="">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table" border={0}>
                                        <tr>
                                            <td class="text-start">
                                                <h7>
                                                    <b>Courtier/Intermédiaire: </b>
                                                    {props?.contrat?.contrat?.courtier?.nom}
                                                </h7>
                                            </td>
                                            <td>
                                                {/* <div class="border-end"></div> */}
                                            </td>
                                            <td class="text-end">
                                                <h7>
                                                    <b>Avenant : </b>
                                                    {props?.contrat?.contrat?.avenant?.nom}
                                                </h7>
                                            </td>
                                        </tr>
                                    </table>
                                    <hr class="my-2" />
                                    <table class="table">
                                        <tr>
                                            <td class="text-start">
                                                <h7>
                                                    <b>Code courtier/intermédiaire: </b>
                                                    {props.contrat?.contrat?.courtier?.codeAgence}
                                                </h7>
                                            </td>
                                            <td>
                                                {/* <div class="border-end"></div> */}
                                            </td>
                                            <td class="text-end">
                                                <h7>
                                                    <b>N° de Police : </b>
                                                    {props.contrat?.contrat?.numeroPoliceM
                                                        ? props.contrat?.contrat?.numeroPoliceM
                                                        : props.contrat?.contrat?.numeroPoliceP}
                                                </h7>
                                            </td>
                                        </tr>
                                    </table>
                                    <hr class="my-2" />
                                    <table class="table">
                                        <tr>
                                            <td class="text-start">
                                                <h7>
                                                    <b>Date effet: </b>
                                                    {frenchDate(props.contrat?.contrat?.dateEffet)}
                                                </h7>
                                            </td>
                                            <td>
                                                {/* <div class="border-end"></div> */}
                                            </td>
                                            <td class="text-end">
                                                <h7>
                                                    <b>Date échéance : </b>
                                                    {frenchDateSimple(props.contrat?.contrat?.dateEcheance)}
                                                </h7>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <hr class="my-2" />
                            <br />
                            <br />
                            <div class="row justify-content-center">
                                <div class="col-7">
                                    <table>
                                        {datas?.map((d, id) => (
                                            <tr key={id}>
                                                <td class="text-start">
                                                    <h7>
                                                        <b>{d.libelle} </b>
                                                    </h7>
                                                </td>
                                                <td class="text-end">
                                                    <h7>
                                                        {d.valeur}
                                                    </h7>
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-12 text-end">
                                    <table class="table">
                                        <tr>
                                            <td>
                                                <h7>
                                                    <b>CAISSIER : </b>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </h7>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h7>
                                                    <b>{LOGGED_USER.personne.nom}{" "}
                                                        {LOGGED_USER.personne.prenom}</b><br /></h7>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={footer} alt="Footer" class="img-fluid" />
                </div>
            </div>
        </div>
    )
});

InvoiceCommissionPayment.propTypes = {
    contrat: PropTypes.object,
};
export default InvoiceCommissionPayment