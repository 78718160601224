import axios from "axios";

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

// import { Button as AntdButton } from "antd";<s
import PropTypes from "prop-types";
// import selectStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import {DatePicker, Modal, Select} from "antd";

// import PictureUpload from "components/CustomUpload/PictureUpload";
// import { LOGGED_USER } from "variables/constant";
import {toast} from "react-toastify";
import {API_URL, TOKEN} from "../../../variables/constants";
import MenuItem from "antd/es/menu/MenuItem";
import CustomInput from "./CustomInput";
import {verifyLength, verifyNumber} from "../../../variables/functions";
// import { UserAddOutlined } from "@ant-design/icons";
// const useSelectStyles = makeStyles(selectStyles);

const styles = {
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center",
    },
    cardCategory: {
        margin: "0",
        color: "#999999",
    },
};

const initReglement = {
    montantGlobal: "",
    montantPaye: "",
    dateReglement: "",
    beneficiaire: "",
    sinistre: "",
};


const ReglementSinistre = (props) => {
    // const selectClasses = useSelectStyles();
    const [reglement, setReglement] = useState(initReglement);
    // const [loading, setLoading] = useState();
    const [montantGlobal, setMontantGlobal] = useState("");
    const [montantGlobalState, setMontantGlobalState] = useState("");
    const [montantRetenu, setMontantRetenu] = useState("");
    const [montantRetenuState, setMontantRetenuState] = useState("");
    const [victimeSelect, setvictimeSelect] = useState("");
    const [victimeSelectState, setvictimeSelectState] = useState("");
    const [taux, settaux] = useState(0);
    const [tauxState, settauxState] = useState("");
    const [autresFrais, setAutresFrais] = useState(0);
    const [autresFraisState, setAutresFraisState] = useState("");
    const [dateReglementSinistre, setDateReglementSinistre] = useState("");
    const [modalReglementSinistre, setModalReglementSinistre] = useState(
        props.open
    );
    const [sinistre, setSinistre] = useState("");
    const [libelleDecaissement, setLibelleDecaissement] = useState("");
    const [libelleDecaissementState, setLibelleDecaissementState] = useState("");
    const [victimes, setVictimes] = useState([]);
    const [experts, setExperts] = useState([]);
    const [formValues, setFormValues] = useState([
        {
            taux: 0,
            victime: "",
            montantGlobal: "",
            montantRetenu: "",
            dateReglementSinistre: "",
        },
    ]);

    const handledateReglementSinistre = (value, index) => {
        let newFormValues = [...formValues];
        newFormValues[index]["dateReglementSinistre"] = value.toISOString();
        sessionStorage.setItem("formValues", JSON.stringify(newFormValues));
        setFormValues(newFormValues);
    };


    useEffect(() => {
        setModalReglementSinistre(props.open);
    }, [props.open]);

    const param = useParams();

    useEffect(() => {
        if (!modalReglementSinistre) {
            props.setOpenReglement(false);
        }
    }, [modalReglementSinistre]);

    useEffect(() => {
        if (param.id) {
            axios
                .get(`${API_URL}/api/sinistre/codeId/${param.id}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    // console.log(res.data);
                    setSinistre(res.data);
                    setVictimes(res.data.victimes);
                    setExperts(res.data.experts);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [TOKEN]);

    const isValidated = () => {
        if (
            montantGlobalState === "success" &&
            victimeSelectState === "success" &&
            libelleDecaissementState === "success"
        ) {
            return true;
        } else {
            if (montantGlobalState !== "success") {
                setMontantGlobalState("error");
            }
            if (montantRetenuState !== "success") {
                setMontantRetenuState("error");
            }
            if (victimeSelectState !== "success") {
                setvictimeSelectState("error");
            }
            if (libelleDecaissementState !== "success") {
                setLibelleDecaissementState("error");
            }
            return false;
        }
    };

    const handleReglementSinistreSubmit = () => {
        const validated = isValidated();
        if (validated) {
            // setLoading(true);
            reglement.montantGlobal =
                parseFloat(montantGlobal) + parseFloat(autresFrais);
            reglement.montantPaye =
                parseFloat(montantGlobal) - parseFloat(montantRetenu);
            reglement.sinistre = sinistre;
            reglement.beneficiaire =
                victimeSelect && JSON.parse(victimeSelect)?.fonction === "EXPERT"
                    ? JSON.parse(victimeSelect)
                    : JSON.parse(victimeSelect).personne;
            reglement.dateReglement = dateReglementSinistre;
            reglement.libelleDecaissement = libelleDecaissement;
            reglement.taux = taux;
            // console.log("Reglement: ", reglement);
            reglement.expertise = reglement.beneficiaire.fonction === "EXPERT";
            axios
                .post(`${API_URL}/api/decaissement`, JSON.stringify(reglement), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    toast("Règlement enregistré avec succès");
                    setReglement(initReglement);
                    setModalReglementSinistre(false);
                })
                .catch((err) => {
                    console.log(err);
                    toast("Erreur lors de l'enregistrement du règlement");
                });
        } else {
            toast("Entrez correctement les informations du règlement");
        }
    };

    return (
        <Modal
            title="Règlement du sinistre"
            centered
            open={modalReglementSinistre}
            onOk={() => handleReglementSinistreSubmit()}
            onCancel={() => setModalReglementSinistre(false)}
        >
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <div className={'form-control'} style={{width: '100%'}}>
                        <label
                            htmlFor="victime"
                            className={'input-mini'}
                            /* success={victimeSelectState === "success"}
                             error={victimeSelectState === "error"}*/
                        >
                            Bénéficiaire <small>(Obligatoire)</small>
                        </label>
                        <Select
                            MenuProps={{
                                className: styles.selectMenu,
                            }}
                            styles={{
                                width: '100%'
                            }}
                            id="victime"
                            value={victimeSelect}
                            onChange={(event) => {
                                // handleFlotteChange(0, event);
                                if (!verifyLength(event.target.value, 0)) {
                                    setvictimeSelectState("error");
                                } else {
                                    setvictimeSelectState("success");
                                }
                                setvictimeSelect(event.target.value);
                                setvictimeSelectState("success");
                            }}
                            inputProps={{
                                name: "victime",
                                id: "victimeState",
                            }}
                        >
                            <MenuItem
                                disabled
                                styles={{
                                    root: styles.selectMenuItem,
                                }}
                            >
                                Sélectionner un bénéficiaire<small> (Obligatoire)</small>
                            </MenuItem>
                            {victimes?.map((victime) => (
                                <MenuItem
                                    styles={{
                                        root: styles.selectMenuItem,
                                        selected: styles.selectMenuItemSelected,
                                    }}
                                    value={JSON.stringify(victime)}
                                    key={victime?.codeId}
                                >
                                    {victime?.personne?.prenom
                                        ? `${victime?.personne?.nom} ${victime?.personne?.prenom} `
                                        : `${victime?.personne?.nom} `}
                                </MenuItem>
                            ))}
                            {experts?.map((expert) => (
                                <MenuItem
                                    styles={{
                                        root: styles.selectMenuItem,
                                        selected: styles.selectMenuItemSelected,
                                    }}
                                    value={JSON.stringify(expert)}
                                    key={expert?.codeId}
                                >
                                    {expert?.prenom
                                        ? `${expert?.nom} ${expert?.prenom}`
                                        : `${expert?.nom} `}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
            <br/>
            <div className={'container'} style={{justifyContent: 'center', width: '100%'}}>
                <div className={'grid-item'} style={{justifyContent: 'center', width: '100%'}}>
                    <DatePicker
                        value={dateReglementSinistre}
                        id="dateReglementSinistre"
                        name="dateReglementSinistre"
                        picker={'datetime'}
                        onChange={(event) => {
                            handledateReglementSinistre(event, 0);
                            console.log("Date de reglement: ", event.toISOString());
                            setDateReglementSinistre(event.toISOString());
                            sessionStorage.setItem("effet", event.toISOString());
                        }}
                        timeFormat={false}
                        placeholder="Date de règlement"
                        inputProps={{
                            placeholder: "Date de règlement",

                        }}
                    />
                    <br/>
                </div>
            </div>
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <CustomInput
                        success={montantGlobalState === "success"}
                        error={montantGlobalState === "error"}
                        labelText={
                            <span>
                Montant TTC <small>(Obligatoire)</small>
              </span>
                        }
                        id="montantGlobal"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={montantGlobal}
                        onChange={(event) => {
                            if (verifyLength(event.target.value, 5)) {
                                // setmembreRaisonState("success");
                                if (verifyNumber(event.target.value)) {
                                    setMontantGlobalState("success");
                                } else {
                                    setMontantGlobalState("error");
                                }
                            } else {
                                setMontantGlobalState("error");
                            }
                            setMontantGlobal(event.target.value);
                        }}
                        inputProps={{
                            endAdornment: (
                                <div style={{
                                    position: "end"
                                }}

                                     className={styles.inputAdornment}
                                ></div>
                            ),
                            name: "montantGlobal",
                            id: "montantGlobalState",
                        }}
                    />
                </div>
            </div>
            {victimeSelect &&
            JSON.parse(victimeSelect)?.fonction &&
            JSON.parse(victimeSelect)?.fonction === "EXPERT" ? (
                <>
                    <div className={'container'} style={{justifyContent: 'center'}}>
                        <div className={'grid-item'}>
                            <CustomInput
                                success={tauxState === "success"}
                                error={tauxState === "error"}
                                labelText={<span>Taux retenu (en %)</span>}
                                id="taux"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                value={taux}
                                onChange={(event) => {
                                    // handleFlotteChange(0, event);
                                    if (verifyLength(event.target.value, 1)) {
                                        // setmembreRaisonState("success");
                                        if (verifyNumber(event.target.value)) {
                                            settauxState("success");
                                        } else {
                                            settauxState("error");
                                        }
                                    } else {
                                        settauxState("error");
                                    }
                                    settaux(event.target.value);
                                    setMontantRetenu((montantGlobal * event.target.value) / 100);
                                }}
                                inputProps={{
                                    endAdornment: (
                                        <div style={{
                                            position: "end"
                                        }}

                                             className={styles.inputAdornment}
                                        ></div>
                                    ),
                                    name: "taux",
                                    id: "tauxState",
                                }}
                            />
                        </div>
                    </div>
                    <div className={'container'} style={{justifyContent: 'center'}}>
                        <div className={'grid-item'}>
                            <CustomInput
                                success={montantRetenuState === "success"}
                                error={montantRetenuState === "error"}
                                labelText={
                                    <span>
                    Retenue sur Honoraire <small>(Obligatoire)</small>
                  </span>
                                }
                                id="montantRetenu"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                value={montantRetenu}
                                onChange={(event) => {
                                    // handleFlotteChange(0, event);
                                    if (verifyLength(event.target.value, 5)) {
                                        // setmembreRaisonState("success");
                                        if (verifyNumber(event.target.value)) {
                                            setMontantRetenuState("success");
                                        } else {
                                            setMontantRetenuState("error");
                                        }
                                    } else {
                                        setMontantRetenuState("error");
                                    }
                                    setMontantRetenu(event.target.value);
                                }}
                                inputProps={{
                                    endAdornment: (
                                        <div style={{
                                            position: "end"
                                        }}

                                             className={styles.inputAdornment}
                                        ></div>
                                    ),
                                    name: "montantRetenu",
                                    id: "montantRetenuState",
                                }}
                            />
                        </div>
                    </div>
                    <div className={'container'} style={{justifyContent: 'center'}}>
                        <div className={'grid-item'}>
                            <CustomInput
                                success={autresFraisState === "success"}
                                error={autresFraisState === "error"}
                                labelText={<span>Autres frais</span>}
                                id="autresFrais"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                value={autresFrais}
                                onChange={(event) => {
                                    if (verifyLength(event.target.value, 3)) {
                                        setAutresFraisState("success");
                                    } else {
                                        setAutresFraisState("error");
                                    }
                                    setAutresFrais(event.target.value);
                                }}
                                inputProps={{
                                    endAdornment: (
                                        <div
                                            style={{position: "end"}}
                                            className={styles.inputAdornment}
                                        ></div>
                                    ),
                                    name: "autresFrais",
                                    id: "autresFraisState",
                                    type: "number",
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : null}
            <div className={'container'} style={{justifyContent: 'center'}}>
                <div className={'grid-item'}>
                    <CustomInput
                        success={libelleDecaissementState === "success"}
                        error={libelleDecaissementState === "error"}
                        labelText={
                            <span>
                Libellé du décaissement <small>(Obligatoire)</small>
              </span>
                        }
                        id="libelleDecaissement"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={libelleDecaissement}
                        onChange={(event) => {
                            if (verifyLength(event.target.value, 5)) {
                                setLibelleDecaissementState("success");
                            } else {
                                setLibelleDecaissementState("error");
                            }
                            setLibelleDecaissement(event.target.value);
                        }}
                        inputProps={{
                            endAdornment: (
                                <div
                                    style={{
                                        position: "end"
                                    }}

                                    className={styles.inputAdornment}
                                ></div>
                            ),
                            name: "libelleDecaissement",
                            id: "libelleDecaissementState",
                            rows: 3,
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

ReglementSinistre.propTypes = {
    open: PropTypes.bool,
    setOpenReglement: PropTypes.func,
};

export default ReglementSinistre;
