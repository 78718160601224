import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import { rightsService } from '../../../services/rightsService';
import { API_URL, TOKEN } from '../../../variables/constants';
import AddIntermediaire from '../AddIntermediaire';
import EditIntermediaire from '../EditIntermediaire';

const { confirm } = Modal;

const Intermediaires = () => {
    const [intermediaires, setIntermediaires] = useState([]);
    const [selectedIntermediaire, setSelectedIntermediaire] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleEditClose = () => setShowEditModal(false);

    const activateIntermediaireConfirm = (inter) => {
        confirm({
            title: `Etes-vous sûr de vouloir ${inter.active ? "désactiver" : "activer"} cet intermédiaire?`,
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées!",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                activateIntermediaire(inter);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const activateIntermediaire = (inter) => {
        axios
            .get(`${API_URL}/api/intermediaire/activity/${inter.codeIntermediaire}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            }).then((res) => {
                console.log(res.data);
                inter.active ? toast("Intermédiaire désactivé avec succès!") : toast("Intermédiaire activé avec succès!");
                setReload(true);
            }).catch(err => {
                console.log(err);
                inter.active ? toast("Erreur lors de la désactivation de l'intermédiaire") : toast("Erreur lors de l'activation de l'intermédiaire");
            });
    }

    const handleEditShow = (inter) => {
        setSelectedIntermediaire(inter);
        setShowEditModal(true);
    }

    useEffect(() => {
        axios
            .get(
                `${API_URL}/api/agence/intermediaires`,
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            )
            .then((res) => {
                console.log("intermédiaires: ", res.data);
                setIntermediaires(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [TOKEN, showModal, reload]);

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"COURTIERS"} />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">

                            <div>
                                {/* Agency Tab content */}
                                {/* Add the actual content for this tab */}
                                {rightsService("HLA052") && <button
                                    class="btn btn-primary w-35"
                                    onClick={() => handleShow()}
                                >
                                    <i class="bx bx-folder-plus bx-lg"></i> Ajouter un
                                    intermédiaire
                                </button>}
                                <div class="card mb-6">
                                    <div class="table-responsive mb-4">
                                        <table class="table datatables-customer-order border-top">
                                            <thead>
                                                <tr>
                                                    <th>Code intermédiaire</th>
                                                    <th>Type intermédiaire</th>
                                                    <th>Nom</th>
                                                    <th>Contacts</th>
                                                    <th>Statut</th>
                                                    <th class="text-md-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {intermediaires?.length ? (
                                                    intermediaires?.map((ag, id) => (
                                                        <tr key={id}>
                                                            <td>{ag.codeIntermediaire}</td>
                                                            <td><span class="badge bg-label-primary">{ag.type}</span></td>
                                                            <td>{ag.personne.prenom} {ag.personne.nom}</td>
                                                            <td>
                                                                {ag.personne.contact
                                                                    ? ag.personne.contact[0]
                                                                        ? ag.personne.contact[0]
                                                                        : ""
                                                                    : ""}
                                                            </td>
                                                            <td>
                                                                {!ag.active ? (
                                                                    <span class="badge bg-label-danger">
                                                                        Inactif
                                                                    </span>
                                                                ) : (
                                                                    <span class="badge bg-label-success">
                                                                        Actif
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td class="text-md-center">
                                                                <div class="dropdown">
                                                                    <button
                                                                        type="button"
                                                                        class="btn p-0 dropdown-toggle hide-arrow"
                                                                        data-bs-toggle="dropdown"
                                                                    >
                                                                        <i class="bx bx-dots-vertical-rounded"></i>
                                                                    </button>
                                                                    <div class="dropdown-menu">
                                                                        <a
                                                                            class="dropdown-item"
                                                                            href={`/intermediaire/${ag?.codeIntermediaire}`}
                                                                        >
                                                                            <i class="bx bx-detail me-1"></i>{" "}
                                                                            Détails
                                                                        </a>
                                                                        {rightsService("HLA054") && <a
                                                                            class="dropdown-item"
                                                                            href="#"
                                                                            onClick={() => activateIntermediaireConfirm(ag)}
                                                                        >
                                                                            <i class="bx bx-expand-horizontal me-1"></i>{" "}
                                                                            {ag?.active
                                                                                ? "Désactiver"
                                                                                : "Activer"}
                                                                        </a>}
                                                                        {rightsService("HLA053") && <a
                                                                            class="dropdown-item"
                                                                            href="javascript:void(0);"
                                                                            onClick={() => handleEditShow(ag)}
                                                                        >
                                                                            <i class="bx bx-edit-alt me-1"></i>{" "}
                                                                            Modifier
                                                                        </a>}
                                                                        {rightsService("HLA055") && <a
                                                                            class="dropdown-item"
                                                                            href="javascript:void(0);"
                                                                            onClick={() => { }}
                                                                        >
                                                                            <i class="bx bx-trash me-1"></i>{" "}
                                                                            Supprimer
                                                                        </a>}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={6}>
                                                            Aucun intermédiaire enregistré pour le moment
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- / Content --> */}

                            {/* <!-- Footer --> */}
                            <Footer />
                            {/* <!-- / Footer --> */}

                            <div class="content-backdrop fade"></div>
                        </div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <AddIntermediaire
                show={showModal}
                handleClose={handleClose}
            />
            <EditIntermediaire
                show={showEditModal}
                handleClose={handleEditClose}
                intermediaire={selectedIntermediaire}
            />
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    );
}

export default Intermediaires