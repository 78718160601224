import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { rightsService } from "../../services/rightsService";
import { API_URL, LOGGED_USER, TOKEN } from "../../variables/constants";
import { isTomonth } from "../../variables/functions";
const { confirm } = Modal;

const DetailsMembre = () => {
  const [membre, setMembre] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [raisonSociale, setRaisonSociale] = useState("");
  const [responsable, setResponsable] = useState("");
  const [siegeSocial, setSiegeSocial] = useState("");
  const [code, setCode] = useState("");
  const [telephone1, setTelephone1] = useState("");
  const [telephone2, setTelephone2] = useState("");
  const [email, setEmail] = useState("");
  const [typeMembre, setTypeMembre] = useState("ASSURANCE");
  const [otpConnexion, setOtpConnexion] = useState("");
  const [domaine, setDomaine] = useState("");
  const [website, setWebsite] = useState("");
  const [defaultExercice, setDefaultExercice] = useState(0);
  const [dateCreation, setDateCreation] = useState("");
  const [changeLogo, setChangeLogo] = useState(false);
  const [fileImage, setFileImage] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [agences, setAgences] = useState([]);
  const [active, setActive] = useState(true);
  const [villes, setVilles] = useState([]);
  const [exercices, setExercices] = useState([]);
  const [codeAgence, setCodeAgence] = useState("");
  const [nomAgence, setNomAgence] = useState("");
  const [villeAgence, setVilleAgence] = useState("");
  const [responsableAgence, setResponsableAgence] = useState("");
  const [telephone1Agence, setTelephone1Agence] = useState("");
  const [telephone2Agence, setTelephone2Agence] = useState("");
  const [activeAgency, setActiveAgency] = useState(false);
  const [typeAgence, setTypeAgence] = useState("");
  const [montantPrimes, setMontantPrimes] = useState(0);
  const [montantPrimesMensuelles, setMontantPrimesMensuelles] = useState(0);
  const [contrats, setContrats] = useState([]);

  const param = useParams();
  const navigate = useNavigate();

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    let code = "";
    code = param.organismeCode ? param.organismeCode : LOGGED_USER.profil.organisme.code;

    axios
      .get(`${API_URL}/api/organisme/code/${code}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        setMembre(res.data);
        setRaisonSociale(res.data.raisonSociale);
        setSiegeSocial(res.data.siegeSocial);
        setCode(res.data.code);
        setEmail(res.data.email);
        setResponsable(res.data.responsable);
        res.data.contact && setTelephone1(res.data.contact[0]);
        res.data.contact && res.data.contact[1] && setTelephone2(res.data.contact[1]);
        setTypeMembre(res.data.typeOrganisme);
        setDateCreation(res.data.dateCreation);
        setActive(res.data.active);
        setDomaine(res.data.domaine);
        setWebsite(res.data.website);
        setOtpConnexion(res.data.otpConnexion);
        setDefaultExercice(res.data?.defaultExercice?.exerciceId);
        if (res.data.logo) {
          fetch(
            `${API_URL}/api/organisme/logo/${res.data.logo.split("//")[2]}`,
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            }
          )
            .then(async (response) => {
              console.log("The response", response);

              // convert byte to file
              const imageBytes = await response.arrayBuffer();
              console.log("images byte: ", imageBytes);
              var blob = new Blob([imageBytes], { type: "image/png" });
              console.log("Blob: ", blob);
              var imageUrl = URL.createObjectURL(blob);
              console.log("Image URL: ", imageUrl);
              setImagePreviewUrl(imageUrl);
              var file = new File([blob], res.data.logo.split("//")[2], {
                type: "image/png",
              });
              console.log("File: ", file);

              setTimeout(() => {
                console.log("Preview: ", { imagePreviewUrl });
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${API_URL}/api/exercice`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setExercices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        `${API_URL}/api/contrat/organisme/code/${code}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        console.log("contrats: ", res.data);
        setContrats(res.data);
        let pns = 0;
        let monthPns = 0;
        res.data.forEach((cont) => {
          pns += cont.reglements.reduce((pn, reg) => reg.modePaiement && (pn + reg.montant), 0);
          monthPns += cont.reglements.reduce((pn, reg) => reg.modePaiement && isTomonth(reg.dateReglement) && (pn + reg.montant), 0);
        });
        setMontantPrimes(pns);
        setMontantPrimesMensuelles(monthPns);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [TOKEN]);

  // Function to handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result); // Set the image preview URL
      };
      reader.readAsDataURL(file); // Convert the file to a Data URL
    }
    setChangeLogo(true);
  };

  // Function to reset the image
  const resetImage = () => {
    setImagePreviewUrl(null);
    setChangeLogo(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    var contacts = [];
    telephone2
      ? contacts.push(telephone1, telephone2)
      : contacts.push(telephone1);
    let logoFile = "";

    console.log("dataaaaaaaaa: ", fileImage);
    let myExercice = exercices.find(
      (ex) => ex.exerciceId === parseInt(defaultExercice)
    );
    try {
      if (changeLogo) {
        let extension = fileImage?.name?.substring(
          fileImage?.name?.lastIndexOf(".") + 1,
          fileImage?.name?.length
        );
        let filename = `Logo_${raisonSociale
          .split(" ")
          .join("-")}.${extension}`;
        formData.append(`fichier`, fileImage, filename);
        axios
          .post(`${API_URL}/api/organisme/logo`, formData, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          })
          .then((response) => {
            console.log(response.data);
            logoFile = response.data;

            const organisme = membre;
            organisme.typeOrganisme = typeMembre;
            organisme.raisonSociale = raisonSociale;
            organisme.code = code;
            organisme.contact = contacts;
            organisme.email = email;
            organisme.dateCreation = dateCreation;
            organisme.responsable = responsable;
            organisme.logo = response.data;
            organisme.siegeSocial = siegeSocial;
            organisme.active = active;
            organisme.defaultExercice = myExercice;
            organisme.otpConnexion = otpConnexion;
            organisme.domaine = domaine;
            organisme.website = website;

            axios
              .put(`${API_URL}/api/organisme`, JSON.stringify(organisme), {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, PUT, POST, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
              })
              .then((res) => {
                console.log(res.data);
                toast("Membre mis à jour avec succès!");
                // navigate(`/membre/${res.data.organismeId}`, { replace: true });
                window.location.reload();
              })
              .catch((err) => {
                console.log(err);
                toast("Erreur lors de la création d'un membre");
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const organisme = membre;
        organisme.typeOrganisme = typeMembre;
        organisme.raisonSociale = raisonSociale;
        organisme.code = code;
        organisme.contact = contacts;
        organisme.email = email;
        organisme.dateCreation = dateCreation;
        organisme.responsable = responsable;
        organisme.siegeSocial = siegeSocial;
        organisme.active = active;
        organisme.defaultExercice = myExercice;
        organisme.otpConnexion = otpConnexion;
        organisme.domaine = domaine;
        organisme.website = website;
        console.log("L'organismeeeeee: ", organisme);
        axios
          .put(`${API_URL}/api/organisme`, JSON.stringify(organisme), {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          })
          .then((res) => {
            console.log(res.data);
            toast("Membre mis à jour avec succès!");
            navigate(`/membre/${res.data.organismeId}`, { replace: true });
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            toast("Erreur lors de la mise à jour des informations d'un membre");
          });
      }
    } catch (e) {
      console.log(e);
    }

    // setTimeout(() => {

    //    organisme = {
    //     typeOrganisme: typeMembre,
    //     raisonSociale: raisonSociale,
    //     code: code,
    //     contact: contacts,
    //     email: email,
    //     dateCreation: dateCreation,
    //     logo: logoFile,
    //     responsable: responsable,
    //     siegeSocial: siegeSocial,
    //     updatedBy: LOGGED_USER.codeId,
    //   };
    // }, 500);
  };

  useEffect(() => {
    let code = "";
    code = param.organismeCode ? param.organismeCode : LOGGED_USER.profil.organisme.code;

    axios
      .get(`${API_URL}/api/agence/organisme/code/${code}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setAgences(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        `${API_URL}/api/organisme/utilisateurs/code/${param.organismeCode}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        setUtilisateurs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${API_URL}/api/parametre/type/VILLE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setVilles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [TOKEN]);

  const handleAgencySubmit = (e) => {
    e.preventDefault();

    var contactsAgence = [];
    telephone2Agence
      ? contactsAgence.push(telephone1Agence, telephone2Agence)
      : contactsAgence.push(telephone1Agence);

    const agence = {
      codeAgence: codeAgence,
      contact: contactsAgence,
      responsable: responsableAgence,
      ville: villeAgence,
      restrictionProduction: activeAgency,
      nom: nomAgence,
      organisme: membre,
      typeAgence: typeAgence,
    };

    console.log("Agencyyyyyyyyyy: ", agence);

    axios
      .post(`${API_URL}/api/agence`, JSON.stringify(agence), {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        toast("Agence ajoutée avec succès!");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors de l'ajout de l'agence");
      });
  };

  const showActivateConfirm = (agence) => {
    confirm({
      title: `Etes-vous sûr de vouloir ${agence?.restrictionProduction ? "désactiver" : "activer"} cette agence?`,
      icon: <ExclamationCircleFilled />,
      content: "Cela impliquera que toutes les données soient corrompues",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        console.log("OK");
        activateAgency(agence);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const activateAgency = (agence) => {
    axios
      .get(`${API_URL}/api/agence/activate/${agence.codeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
        toast(agence?.restrictionProduction ? "Agence désactivée effectuée avec succès" : "Agence activée avec succès");
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors de la mise à jour");
      });
  };

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"MEMBRES"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between mb-6 text-center text-sm-start gap-2">
                <div class="mb-2 mb-sm-0">
                  <h4 class="mb-1">Code membre #{membre?.code}</h4>
                  <p class="mb-0">Date de création : {membre?.dateCreation}</p>
                </div>
                <button
                  type="button"
                  class="btn btn-label-danger delete-customer"
                >
                  Supprimer le membre
                </button>
              </div>

              <div class="row">
                {/* <!-- Customer-detail Sidebar --> */}
                <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                  <div class="card mb-6">
                    <div class="card-body pt-12">
                      <div class="customer-avatar-section">
                        <div class="d-flex align-items-center flex-column">
                          <img
                            class="img-fluid rounded mb-4"
                            src={imagePreviewUrl}
                            height="120"
                            width="120"
                            alt="User avatar"
                          />
                          <div class="customer-info text-center mb-6">
                            <h5 class="mb-0">{membre?.raisonSociale}</h5>
                            <span>Code #{membre?.code}</span>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-around flex-wrap mb-6 gap-0 gap-md-3 gap-lg-4">
                        <div class="d-flex align-items-center gap-4 me-5">
                          <div class="avatar">
                            <div class="avatar-initial rounded bg-label-primary">
                              <i class="bx bx-user bx-lg"></i>
                            </div>
                          </div>
                          <div>
                            <h5 class="mb-0">{contrats?.length}</h5>
                            <span>Contrats</span>
                          </div>
                        </div>
                        <div class="d-flex align-items-center gap-4">
                          <div class="avatar">
                            <div class="avatar-initial rounded bg-label-primary">
                              <i class="bx bx-dollar bx-lg"></i>
                            </div>
                          </div>
                          <div>
                            <h5 class="mb-0">{new Intl.NumberFormat("en-DE").format(montantPrimesMensuelles)} FCFA</h5>
                            <span>Production du mois</span>
                          </div>
                        </div>
                      </div>

                      <div class="info-container">
                        <h5 class="pb-4 border-bottom text-capitalize mt-6 mb-4">
                          Détails
                        </h5>
                        <ul class="list-unstyled mb-6">
                          <li class="mb-2">
                            <span class="h6 me-1">Nom du responsable:</span>
                            <span>{membre?.responsable}</span>
                          </li>
                          <li class="mb-2">
                            <span class="h6 me-1">Email:</span>
                            <span>{membre?.email}</span>
                          </li>
                          <li class="mb-2">
                            <span class="h6 me-1">Statut:</span>
                            {membre?.active ? (
                              <span class="badge bg-label-success">Actif</span>
                            ) : (
                              <span class="badge bg-label-primary">
                                Inactif
                              </span>
                            )}
                          </li>
                          <li class="mb-2">
                            <span class="h6 me-1">Contacts:</span>
                            <span>
                              {membre?.contact ? membre?.contact[0] : ""}
                            </span>
                            <span>
                              {membre?.contact && membre?.contact[1]
                                ? `; ${membre?.contact[1]}`
                                : ""}
                            </span>
                          </li>

                          <li class="mb-2">
                            <span class="h6 me-1">Siège:</span>
                            <span>{membre?.siegeSocial}</span>
                          </li>
                          <li class="mb-2">
                            <span class="h6 me-1">Site web:</span>
                            <span>{membre?.website}</span>
                          </li>
                          <li class="mb-2">
                            <span class="h6 me-1">Domaine:</span>
                            <span>{membre?.domaine}</span>
                          </li>
                          <li class="mb-2">
                            <span class="h6 me-1">
                              Double Authentification:
                            </span>
                            <span>
                              {membre?.otpConnexion === "NONE"
                                ? "Non"
                                : membre?.otpConnexion}
                            </span>
                          </li>
                          <li class="mb-2">
                            <span class="h6 me-1">Exercice par défaut:</span>
                            <span>
                              {membre?.defaultExercice?.libelleExercice}
                            </span>
                          </li>
                        </ul>
                        {rightsService("HME050") && <div class="d-flex justify-content-center">
                          <a
                            href="javascript:;"
                            class="btn btn-primary w-100"
                            data-bs-target="#editUser"
                            data-bs-toggle="modal"
                          >
                            Editer les informations
                          </a>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--/ Customer Sidebar --> */}

                {/* <!-- Customer Content --> */}
                <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                  {/* <!-- Customer Pills --> */}
                  <div class="nav-align-top">
                    <ul class="nav nav-pills flex-column flex-md-row mb-6">
                      <li class="nav-item">
                        <a
                          className={
                            activeTab === 0 ? "nav-link active" : "nav-link"
                          }
                          href="#apercu"
                          onClick={() => handleTabChange(0)}
                        >
                          <i class="bx bx-detail bx-sm me-1_5"></i>Aperçu
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          className={
                            activeTab === 1 ? "nav-link active" : "nav-link"
                          }
                          href="#user"
                          onClick={() => handleTabChange(1)}
                        >
                          <i class="bx bx-group bx-sm me-1_5"></i>Utilisateurs
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          className={
                            activeTab === 2 ? "nav-link active" : "nav-link"
                          }
                          href="#agency"
                          onClick={() => handleTabChange(2)}
                        >
                          <i class="bx bx-home-smile bx-sm me-1_5"></i>Agences
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          className={
                            activeTab === 3 ? "nav-link active" : "nav-link"
                          }
                          href="#notif"
                          onClick={() => handleTabChange(3)}
                        >
                          <i class="bx bx-bell bx-sm me-1_5"></i>Notifications
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <!--/ Customer Pills --> */}

                  {/* <!-- / Customer cards --> */}
                  {activeTab === 0 && (
                    <div class="row text-nowrap">
                      <div class="col-md-6 mb-6">
                        <div class="card h-100">
                          <div class="card-body">
                            <div class="card-icon mb-2">
                              <div class="avatar">
                                <div class="avatar-initial rounded bg-label-primary">
                                  <i class="bx bx-file bx-lg"></i>
                                </div>
                              </div>
                            </div>
                            <div class="card-info">
                              <h5 class="card-title mb-2">Production</h5>
                              <div class="d-flex align-items-baseline gap-1">
                                <h5 class="text-primary mb-0">{new Intl.NumberFormat("en-DE").format(montantPrimesMensuelles)} FCFA</h5>
                                <p class="mb-0"> Primes encaissées</p>
                              </div>
                              <p class="mb-0 text-truncate">
                                Etat des primes encaissées pour le mois en cours
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 mb-6">
                        <div class="card">
                          <div class="card-body">
                            <div class="card-icon mb-2">
                              <div class="avatar">
                                <div class="avatar-initial rounded bg-label-primary">
                                  <i class="bx bx-first-aid bx-lg"></i>
                                </div>
                              </div>
                            </div>
                            <div class="card-info">
                              <h5 class="card-title mb-2">Sinistres payés</h5>
                              <p class="mb-0">0 FCFA</p>
                            </div>
                            <p class="mb-0 text-truncate">
                              Etat des sinistres payés pour le mois en cours
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 mb-6">
                        <div class="card">
                          <div class="card-body">
                            <div class="card-icon mb-2">
                              <div class="avatar">
                                <div class="avatar-initial rounded bg-label-warning">
                                  <i class="bx bx-file bx-lg"></i>
                                </div>
                              </div>
                            </div>
                            <div class="card-info">
                              <h5 class="card-title mb-2">Production</h5>
                              <div class="d-flex align-items-baseline gap-1">
                                <h5 class="text-primary mb-0">{new Intl.NumberFormat("en-DE").format(montantPrimes)}FCFA</h5>
                                <p class="mb-0"> Primes encaissées</p>
                              </div>
                              <p class="mb-0 text-truncate">
                                Etat des primes encaissées depuis la création
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 mb-6">
                        <div class="card">
                          <div class="card-body">
                            <div class="card-icon mb-2">
                              <div class="avatar">
                                <div class="avatar-initial rounded bg-label-warning">
                                  <i class="bx bx-first-aid bx-lg"></i>
                                </div>
                              </div>
                            </div>
                            <div class="card-info">
                              <h5 class="card-title mb-2">Sinistres payés</h5>
                              <p class="mb-0">0 FCFA</p>
                            </div>
                            <p class="mb-0 text-truncate">
                              Etat des sinistres payés pour le mois passé
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === 1 && (
                    <div>
                      {/* User Tab content */}
                      {/* Add the actual content for this tab */}
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{ marginBottom: 16 }}
                        onClick={() => { }}
                      >
                        Ajouter un nouvel utilisateur
                      </Button>
                      <div class="card mb-6">
                        <div class="table-responsive mb-4">
                          <table class="table datatables-customer-order border-top">
                            <thead>
                              <tr>
                                <th>Nom d'utilisateur</th>
                                <th>Nom & Prénom(s)</th>
                                <th>Email</th>
                                <th>Profil</th>
                                <th>Agence</th>
                                <th>Statut</th>
                                <th class="text-md-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {utilisateurs?.length ? (
                                utilisateurs?.map((user, id) => (
                                  <tr key={id}>
                                    <td>{user.username}</td>
                                    <td>
                                      {user.personne.nom} {user.personne.prenom}
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.profil.nom}</td>
                                    <td>{user.agence.nom}</td>
                                    <td>
                                      {user.active ? (
                                        <span class="badge bg-label-success">
                                          Actif
                                        </span>
                                      ) : (
                                        <span class="badge bg-label-danger">
                                          Inactif
                                        </span>
                                      )}
                                    </td>
                                    <td class="text-md-center">
                                      <div class="dropdown">
                                        <button
                                          type="button"
                                          class="btn p-0 dropdown-toggle hide-arrow"
                                          data-bs-toggle="dropdown"
                                        >
                                          <i class="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                          <a
                                            class="dropdown-item"
                                            href={`/details-utilisateur/${user.codeId}`}
                                          >
                                            <i class="bx bx-detail me-1"></i>{" "}
                                            Détails
                                          </a>
                                          <a
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i class="bx bx-edit-alt me-1"></i>{" "}
                                            Modifier
                                          </a>
                                          <a
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            onClick={() => { }}
                                          >
                                            <i class="bx bx-trash me-1"></i>{" "}
                                            Supprimer
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>
                                    Aucun utilisateur enregistré pour le moment
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div>
                      {/* Agency Tab content */}
                      {/* Add the actual content for this tab */}
                      <a
                        href="javascript:;"
                        class="btn btn-primary w-35"
                        data-bs-target="#upgradePlanModal"
                        data-bs-toggle="modal"
                      >
                        <i class="bx bx-folder-plus bx-lg"></i> Ajouter une
                        nouvelle agence
                      </a>
                      <div class="card mb-6">
                        <div class="table-responsive mb-4">
                          <table class="table datatables-customer-order border-top">
                            <thead>
                              <tr>
                                <th>Code agence</th>
                                <th>Nom</th>
                                <th>Ville</th>
                                <th>Responsable</th>
                                <th>Contacts</th>
                                <th>Statut</th>
                                <th class="text-md-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agences?.length ? (
                                agences?.map((ag, id) => (
                                  <tr key={id}>
                                    <td>{ag.codeAgence}</td>
                                    <td>{ag.nom}</td>
                                    <td>{ag.ville}</td>
                                    <td>{ag.responsable}</td>
                                    <td>
                                      {ag.contact
                                        ? ag.contact[0]
                                          ? ag.contact[0]
                                          : ""
                                        : ""}
                                    </td>
                                    <td>
                                      {ag.restrictionProduction ? (
                                        <span class="badge bg-label-danger">
                                          Inactif
                                        </span>
                                      ) : (<span class="badge bg-label-success">
                                        Actif
                                      </span>
                                      )}
                                    </td>
                                    <td class="text-md-center">
                                      <div class="dropdown">
                                        <button
                                          type="button"
                                          class="btn p-0 dropdown-toggle hide-arrow"
                                          data-bs-toggle="dropdown"
                                        >
                                          <i class="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                          <a
                                            class="dropdown-item"
                                            href={`/agence/${ag.codeAgence}`}
                                          >
                                            <i class="bx bx-detail me-1"></i>{" "}
                                            Détails
                                          </a>
                                          <a
                                            class="dropdown-item"
                                            href="#"
                                            onClick={() =>
                                              showActivateConfirm(ag)
                                            }
                                          >
                                            <i class="bx bx-expand-horizontal me-1"></i>{" "}
                                            {ag?.restrictionProduction
                                              ? "Activer"
                                              : "Désactiver"}
                                          </a>
                                          <a
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i class="bx bx-edit-alt me-1"></i>{" "}
                                            Modifier
                                          </a>
                                          <a
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            onClick={() => { }}
                                          >
                                            <i class="bx bx-trash me-1"></i>{" "}
                                            Supprimer
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>
                                    Aucun utilisateur enregistré pour le moment
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 3 && (
                    <div>
                      {/* Notification Tab content */}
                      {/* Add the actual content for this tab */}
                    </div>
                  )}

                </div>
                {/* <!--/ Customer Content --> */}
              </div>

              {/* <!-- Modal --> */}
              {/* <!-- Edit User Modal --> */}
              <div
                class="modal fade"
                id="editUser"
                tabindex="-1"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg modal-simple modal-edit-user">
                  <div class="modal-content">
                    <div class="modal-body">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div class="text-center mb-6">
                        <h4 class="mb-2">Editer les informations du membre</h4>
                        <p>Mettez à jour les informations du membres</p>
                      </div>
                      <form class="row g-6" onsubmit={handleSubmit}>
                        <div class="col-12">
                          <label
                            class="form-label"
                            for="modalEditUserFirstName"
                          >
                            Raison sociale
                          </label>
                          <input
                            type="text"
                            id="modalEditUserFirstName"
                            name="modalEditUserFirstName"
                            class="form-control"
                            placeholder="Veuillez renseigner la raison sociale"
                            value={raisonSociale}
                            onChange={(e) => setRaisonSociale(e.target.value)}
                          />
                        </div>
                        <div class="col-12">
                          <label class="form-label" for="modalEditUserLastName">
                            Siège social
                          </label>
                          <input
                            type="text"
                            id="modalEditUserLastName"
                            name="modalEditUserLastName"
                            class="form-control"
                            placeholder="Entrez le siège social"
                            value={siegeSocial}
                            onChange={(e) => setSiegeSocial(e.target.value)}
                          />
                        </div>
                        <div class="l-md-6col-12 col-md-6">
                          <label class="form-label" for="modalEditUserName">
                            Code du membre
                          </label>
                          <input
                            type="text"
                            id="modalEditUserName"
                            name="modalEditUserName"
                            class="form-control"
                            placeholder="Entrez le code du membre"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="modalEditUserName">
                            Date de création
                          </label>
                          <input
                            type="date"
                            id="modalEditUserName"
                            name="modalEditUserName"
                            class="form-control"
                            placeholder=""
                            value={dateCreation}
                            onChange={(e) => setDateCreation(e.target.value)}
                          />
                        </div>
                        <div class="col-12">
                          <label class="form-label" for="modalEditUserEmail">
                            Adresse mail
                          </label>
                          <input
                            type="text"
                            id="modalEditUserEmail"
                            name="modalEditUserEmail"
                            class="form-control"
                            placeholder="Entrez l'adresse mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        {/* <div class="col-12 col-md-6">
                          <label class="form-label" for="modalEditUserStatus">
                            Status
                          </label>
                          <select
                            id="modalEditUserStatus"
                            name="modalEditUserStatus"
                            class="select2 form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Status</option>
                            <option value="1">Active</option>
                            <option value="2">Inactive</option>
                            <option value="3">Suspended</option>
                          </select>
                        </div> */}
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="modalEditUserPhone1">
                            Numéro de téléphone 1
                          </label>
                          <div class="input-group">
                            <span class="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="modalEditUserPhone1"
                              name="modalEditUserPhone"
                              class="form-control phone-number-mask"
                              placeholder="00 00 00 00"
                              value={telephone1}
                              onChange={(e) => setTelephone1(e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="modalEditUserPhone2">
                            Numéro de téléphone 2
                          </label>
                          <div class="input-group">
                            <span class="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="modalEditUserPhone2"
                              name="modalEditUserPhone"
                              class="form-control phone-number-mask"
                              placeholder="00 00 00 00"
                              value={telephone2}
                              onChange={(e) => setTelephone2(e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="modalEditUserLanguage">
                            Type membre
                          </label>
                          <select
                            id="modalEditUserLanguage"
                            name="modalEditUserLanguage"
                            class="select2 form-select"
                            value={typeMembre}
                            placeholder="Sélectionnez le type de membre"
                            onChange={(e) => setTypeMembre(e.target.value)}
                          >
                            <option value="ASSURANCE">ASSURANCE</option>
                            <option value="PARTENAIRE">PARTENAIRE</option>
                          </select>
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="modalEditUserCountry">
                            Responsable
                          </label>
                          <input
                            type="text"
                            id="modalEditUserPhone2"
                            name="modalEditUserPhone"
                            class="form-control phone-number-mask"
                            placeholder="Le nom du responsable du membre"
                            value={responsable}
                            onChange={(e) => setResponsable(e.target.value)}
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="otpConnexion">
                            OTP Connexion ?
                          </label>
                          <select
                            id="otpConnexion"
                            name="otpConnexion"
                            class="form-control"
                            value={otpConnexion}
                            placeholder="Sélectionnez"
                            onChange={(e) => setOtpConnexion(e.target.value)}
                          >
                            <option value="NONE">Désactiver</option>
                            <option value="SMS">SMS</option>
                            <option value="EMAIL">EMAIL</option>
                            <option value="WHATSAPP">WHATSAPP</option>
                          </select>
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="domaine">
                            Nom de domaine
                          </label>
                          <input
                            type="text"
                            id="domaine"
                            name="domaine"
                            class="form-control"
                            placeholder="Le nom de domaine de l'organisme"
                            value={domaine}
                            onChange={(e) => setDomaine(e.target.value)}
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="website">
                            Site web de l'organisme
                          </label>
                          <input
                            type="text"
                            id="website"
                            name="website"
                            class="form-control"
                            placeholder="Le site web de la compagnie / du partenaire"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="defaultExerice">
                            Exercice par défaut
                          </label>
                          <select
                            id="defaultExercice"
                            name="defaultExercice"
                            class="form-control"
                            value={defaultExercice}
                            placeholder="Sélectionnez l'exercice par défaut"
                            onChange={(e) => {
                              console.log("Exo: ", e.target.value);
                              setDefaultExercice(e.target.value);
                            }}
                          >
                            {exercices.map((exo, id) => (
                              <option key={id} value={exo.exerciceId}>
                                {exo.libelleExercice}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="col-12">
                          <div class="card-body">
                            <div class="d-flex align-items-start align-items-sm-center gap-6 pb-4 border-bottom">
                              <img
                                src={imagePreviewUrl}
                                alt="logo-avatar"
                                class="d-block w-px-300 h-px-100 rounded"
                                id="uploadedAvatar"
                              />
                              <div class="button-wrapper">
                                <label
                                  for="upload"
                                  class="btn btn-primary me-3 mb-4"
                                  tabindex="0"
                                >
                                  <span class="d-none d-sm-block">
                                    Téléverser une nouvelle image
                                  </span>
                                  <i class="bx bx-upload d-block d-sm-none"></i>
                                  <input
                                    type="file"
                                    id="upload"
                                    class="account-file-input"
                                    hidden
                                    accept="image/png, image/jpeg"
                                    onChange={handleImageChange}
                                  />
                                </label>
                                <button
                                  type="button"
                                  class="btn btn-label-secondary account-image-reset mb-4"
                                  onClick={resetImage} // Reset the image
                                >
                                  <i class="bx bx-reset d-block d-sm-none"></i>
                                  <span class="d-none d-sm-block">
                                    Réinitialiser
                                  </span>
                                </button>

                                <div>
                                  JPG, GIF ou PNG autorisés. Taille maximale de
                                  2 Mo
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-check form-switch my-2 ms-2">
                            {active ? (
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="editBillingAddress"
                                checked
                                onChange={() => setActive(false)}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="editBillingAddress"
                                onChange={() => setActive(true)}
                              />
                            )}
                            <label
                              for="editBillingAddress"
                              class="switch-label"
                            >
                              Actif?
                            </label>
                          </div>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            onClick={(e) => handleSubmit(e)}
                            class="btn btn-primary me-3"
                          >
                            Enregistrer
                          </button>
                          <button
                            type="reset"
                            class="btn btn-label-secondary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Annuler
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/ Edit User Modal --> */}

              {/* <!-- Add New Credit Card Modal --> */}
              <div
                class="modal fade"
                id="upgradePlanModal"
                tabindex="-1"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg modal-dialog-centered modal-simple modal-upgrade-plan">
                  <div class="modal-content">
                    <div class="modal-body">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div class="text-center mb-6">
                        <h4 class="mb-2">
                          Ajouter une agence de <u>{raisonSociale}</u>
                        </h4>
                        <p>
                          Renseigner les informations pour créer une nouvelle
                          agence.
                        </p>
                      </div>
                      <form
                        id="upgradePlanForm"
                        class="row g-4"
                        onsubmit="return false"
                      >
                        <div class="col-12">
                          <label class="form-label" for="codeAgence">
                            Code de l'agence
                          </label>
                          <input
                            type="text"
                            id="codeAgence"
                            name="codeAgence"
                            class="form-control"
                            placeholder="Veuillez renseigner le code de l'agence"
                            value={codeAgence}
                            onChange={(e) => setCodeAgence(e.target.value)}
                          />
                        </div>
                        <div class="col-12">
                          <label class="form-label" for="nomAgence">
                            Nom de l'agence{" "}
                          </label>
                          <input
                            type="text"
                            id="nomAgence"
                            name="nomAgence"
                            class="form-control"
                            placeholder="Veuillez renseigner le nom de l'agence"
                            value={nomAgence}
                            onChange={(e) => setNomAgence(e.target.value)}
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="villeAgence">
                            Ville{" "}
                          </label>
                          <select
                            id="villeAgence"
                            name="villeAgence"
                            class="form-control form-select"
                            value={villeAgence}
                            placeholder="Sélectionnez la ville de l'agence"
                            onChange={(e) => setVilleAgence(e.target.value)}
                          >
                            {villes?.map((vil, id) => (
                              <option key={id} value={vil.libelleParametre}>
                                {vil.libelleParametre}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div class="col-12 col-md-6">
                          <label class="form-label" for="typeAgence">
                            Type agence
                          </label>
                          <select
                            id="typeAgence"
                            name="typeAgence"
                            class="form-control form-select"
                            value={typeAgence}
                            placeholder="Sélectionnez le type d'agence"
                            onChange={(e) => {
                              console.log("TA: ", e.target.value);
                              setTypeAgence(e.target.value);
                            }}
                          >
                            <option value="AGENCE">AGENCE</option>
                            <option value="BUREAU_DIRECT">BUREAU DIRECT</option>
                            <option value="AGENCE_GENERALE">
                              AGENCE GENERALE
                            </option>
                            <option value="COURTIER">COURTIER</option>
                            <option value="ETABLISSEMENT_FINANCIER">
                              ETABLISSEMENT FINANCIER
                            </option>
                            <option value="AUTRE">AUTRE</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <label class="form-label" for="responsableAgence">
                            Nom du responsable{" "}
                          </label>
                          <input
                            type="text"
                            id="responsableAgence"
                            name="responsableAgence"
                            class="form-control"
                            placeholder="Veuillez renseigner le nom du responsable"
                            value={responsableAgence}
                            onChange={(e) =>
                              setResponsableAgence(e.target.value)
                            }
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="telephone1Agence">
                            Numéro de téléphone 1
                          </label>
                          <div class="input-group">
                            <span class="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="telephone1Agence"
                              name="telephone1Agence"
                              class="form-control phone-number-mask"
                              placeholder="00 00 00 00"
                              value={telephone1Agence}
                              onChange={(e) =>
                                setTelephone1Agence(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="telephone2Agence">
                            Numéro de téléphone 2
                          </label>
                          <div class="input-group">
                            <span class="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="telephone2Agence"
                              name="telephone2Agence"
                              class="form-control phone-number-mask"
                              placeholder="00 00 00 00"
                              value={telephone2Agence}
                              onChange={(e) =>
                                setTelephone2Agence(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-check form-switch my-2 ms-2">
                            {activeAgency ? (
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="activeAgency"
                                checked
                                onChange={() => setActiveAgency(false)}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="activeAgency"
                                onChange={() => setActiveAgency(true)}
                              />
                            )}
                            <label for="activeAgency" class="switch-label">
                              Actif?
                            </label>
                          </div>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            onClick={(e) => handleAgencySubmit(e)}
                            class="btn btn-primary me-3"
                          >
                            Enregistrer
                          </button>
                          <button
                            type="reset"
                            class="btn btn-label-secondary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Annuler
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/ Add New Credit Card Modal --> */}

              {/* <!-- /Modal --> */}
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default DetailsMembre;
