import React, { useEffect, useState } from "react";
import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { API_URL, LOGGED_USER, TOKEN } from "../../variables/constants";
import axios from "axios";

const Profile = () => {
  const [user, setUser] = useState("");
  const [professions, setProfessions] = useState([]);

  useEffect(() => {
    setUser(LOGGED_USER);
    axios
      .get(`${API_URL}/api/parametre/type/PROFESSION`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setProfessions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LOGGED_USER, TOKEN]);
  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row">
                <div class="col-md-12">
                  <div class="nav-align-top">
                    <ul class="nav nav-pills flex-column flex-md-row mb-6">
                      <li class="nav-item">
                        <a class="nav-link active" href="/mon-profil">
                          <i class="bx bx-sm bx-user me-1_5"></i> Compte
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="/mon-profil-securite">
                          <i class="bx bx-sm bx-lock-alt me-1_5"></i> Sécurité
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="/mon-profil-notifications">
                          <i class="bx bx-sm bx-bell me-1_5"></i> Notifications
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="card mb-6">
                    {/* <!-- Account --> */}
                    <div class="card-body">
                      <div class="d-flex align-items-start align-items-sm-center gap-6 pb-4 border-bottom">
                        <img
                          src="../assets/img/avatars/uploadAvatar.png"
                          alt="user-avatar"
                          class="d-block w-px-100 h-px-100 rounded"
                          id="uploadedAvatar"
                        />
                        <div class="button-wrapper">
                          <label
                            for="upload"
                            class="btn btn-primary me-3 mb-4"
                            tabindex="0"
                          >
                            <span class="d-none d-sm-block">
                              Téléverser une nouvelle photo
                            </span>
                            <i class="bx bx-upload d-block d-sm-none"></i>
                            <input
                              type="file"
                              id="upload"
                              class="account-file-input"
                              hidden
                              accept="image/png, image/jpeg"
                            />
                          </label>
                          <button
                            type="button"
                            class="btn btn-outline-secondary account-image-reset mb-4"
                          >
                            <i class="bx bx-reset d-block d-sm-none"></i>
                            <span class="d-none d-sm-block">Réinitialiser</span>
                          </button>

                          <div>
                            JPG, GIF ou PNG autorisés. Taille max de 800 Ko
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body pt-4">
                      <form id="formAccountSettings" onsubmit="return false">
                        <div class="row g-6">
                          <div class="col-md-6">
                            <label for="firstName" class="form-label">
                              Prénom(s)
                            </label>
                            <input
                              class="form-control"
                              type="text"
                              id="prenom"
                              name="prenom"
                              value={user?.personne?.prenom}
                              autofocus
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="lastName" class="form-label">
                              Nom
                            </label>
                            <input
                              class="form-control"
                              type="text"
                              name="nom"
                              id="nom"
                              value={user?.personne?.nom}
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="email" class="form-label">
                              E-mail
                            </label>
                            <input
                              class="form-control"
                              type="text"
                              id="email"
                              name="email"
                              value={user?.email}
                              placeholder="john.doe@example.com"
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="organization" class="form-label">
                              Organisme
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="organization"
                              name="organization"
                              value={user?.profil?.organisme.raisonSociale}
                              disabled
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="form-label" for="phoneNumber">
                              Numéro de téléphone
                            </label>
                            <div class="input-group input-group-merge">
                              <span class="input-group-text">TG (+228)</span>
                              <input
                                type="text"
                                id="telephone"
                                name="telephone"
                                class="form-control"
                                placeholder="90 00 00 00"
                                value={
                                  user?.personne?.contact &&
                                  user?.personne?.contact[0]
                                }
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label for="address" class="form-label">
                              Adresse de résidence
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="adresseResidence"
                              name="adresseResidence"
                              placeholder="Adresse de résidence"
                              value={user?.personne?.adresse}
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="form-label" for="ville">
                              Ville
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="ville"
                              name="ville"
                              value={user?.agence?.ville}
                              disabled
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="language" class="form-label">
                              Profession
                            </label>
                            <select
                              id="language"
                              class="select2 form-select"
                              value={user?.personne?.profession}
                            >
                              <option value="">
                                Selectionnez la profession
                              </option>
                              {professions.map((prof, id) => (
                                <option key={id} value={prof.libelleParametre}>
                                  {prof.libelleParametre}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label for="cheminAcces" class="form-label">
                              Chemin d'accès
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="cheminAcces"
                              name="cheminAcces"
                              placeholder="Chemin d'accès aux fichiers de GPX"
                              value={user?.agence?.cheminAcces}
                            />
                          </div>
                        </div>
                        <div class="mt-6">
                          <button type="submit" class="btn btn-primary me-3">
                            Sauvegarder les changements
                          </button>
                          <button
                            type="reset"
                            class="btn btn-outline-secondary"
                          >
                            Annuler
                          </button>
                        </div>
                      </form>
                    </div>
                    {/* <!-- /Account --> */}
                  </div>
                  {/* <div class="card">
                    <h5 class="card-header">Delete Account</h5>
                    <div class="card-body">
                      <div class="mb-6 col-12 mb-0">
                        <div class="alert alert-warning">
                          <h5 class="alert-heading mb-1">
                            Are you sure you want to delete your account?
                          </h5>
                          <p class="mb-0">
                            Once you delete your account, there is no going
                            back. Please be certain.
                          </p>
                        </div>
                      </div>
                      <form
                        id="formAccountDeactivation"
                        onsubmit="return false"
                      >
                        <div class="form-check my-8 ms-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="accountActivation"
                            id="accountActivation"
                          />
                          <label
                            class="form-check-label"
                            for="accountActivation"
                          >
                            I confirm my account deactivation
                          </label>
                        </div>
                        <button
                          type="submit"
                          class="btn btn-danger deactivate-account"
                          disabled
                        >
                          Deactivate Account
                        </button>
                      </form>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default Profile;
