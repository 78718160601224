import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, DatePicker, message, Modal, Select, Upload } from "antd";

import { CreditCardOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { API_URL, LOGGED_USER, TOKEN } from "../../../../variables/constants";

const initPersonne = {
    nom: "",
    prenom: "",
    genre: "",
    profession: "",
    contact: [],
    typePersonne: "",
    adresse: "",
    pieces: [],
};

const ChangementDeVehicule = (props) => {
    const [loading, setLoading] = useState();
    const [contrat, setContrat] = useState();
    const [typePieces, setTypePieces] = useState([]);
    const [selectedEngin, setSelectedEngin] = useState();
    const [modalAssureOpen, setModalAssureOpen] = useState(false);
    const [modalIndex, setModalIndex] = useState(0);
    const [errors, setErrors] = useState([{}]);
    const [existChassis, setExistChassis] = useState([false]);
    const [existImmatriculation, setExistImmatriculation] = useState([false]);
    const [marques, setMarques] = useState([]);
    const [concessionnaires, setConcessionnaires] = useState([]);
    const [carosseries, setCarosseries] = useState([]);
    const [garanties, setGaranties] = useState([[]]);
    const [ncGuaranties, setNcGuaranties] = useState([]);
    const [carteGrise, setCarteGrise] = useState("");
    const [showAddPersonModal, setShowAddPersonModal] = useState(false);
    const [personneId, setPersonneId] = useState(0); // id de l'assuré suivant le numéro de l'engin dans la flotte
    const [professions, setProfessions] = useState([]);

    const [modalChangementVehicule, setModalChangementVehicule] = useState(
        props.open
    );

    const [formValues, setFormValues] = useState([
        {
            categorie: contrat?.engins[0]?.categorie,
            marque: "",
            numeroSerie: "",
            matricule: "",
            typeSerie: "TG",
            immatriculation: "",
            puissance: "",
            volume: "",
            nombrePlace: 0,
            energie: "ESSENCE",
            numeroChassis: "",
            valeurDeclaree: "",
            valeurVenale: "",
            valeurANeuf: "",
            dateMiseEnCirculation: "",
            puissanceType: [],
            sousGaranties: [],
            garanties: [],
            usage: "TAXI",
            carosserie: null,
            moteur: "",
            type: "",
            genre: "VELOMOTEUR",
            concessionnaire: null,
            rc: 0,
            carteGrise: "",
            personne: {
                personneId: 0,
                nom: "",
                prenom: "",
                contact: [],
                profession: "",
                genre: "",
                adresse: "",
                email: "",
                pieces: [],
                piece: {
                    numIdentite: "",
                    lieuDelivrance: "",
                    dateDelivrance: "",
                    typePiece: "",
                },
            },
            codeId: "0",
        },
    ]);

    const handleDateMiseEnCirculation = (value, index) => {
        let newFormValues = [...formValues];
        newFormValues[index]["dateMiseEnCirculation"] = value.toISOString();
        // sessionStorage.setItem("formValues", JSON.stringify(newFormValues));
        setFormValues(newFormValues);
    };

    useEffect(() => {
        setModalChangementVehicule(props.open);
        setContrat(props.contrat);
    }, [props.open]);

    const param = useParams();
    const navigate = useNavigate();

    const prop = (index) => {
        return {
            beforeUpload: (file) => {
                console.log("Arranger foufou: ", file);
                if (file.type !== "application/pdf") {
                    message.error("Vous ne pouvez uploader que des fichiers PDF!");
                    return false;
                } else {
                    var cg = [];
                    cg.push(file);
                    setCarteGrise(cg);
                    uploadGrayCard(file, index);
                    return false;
                }
            },
            showUploadList: true,
            maxCount: 1,
            data: carteGrise,
            onChange(info) {
                if (info.file.status === "done") {
                    message.success(`${info.file.name} carte grise uploadée avec succès`);
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name} carte grise non uploadée!`);
                }
                console.log("Info: ", info);
            },
        };
    };

    const uploadGrayCard = async (e, index) => {
        const formData = new FormData();

        // console.log("Fillllllllllles: ", e);

        let extension = e.name.substring(
            e.name.lastIndexOf(".") + 1,
            e.name.length
        );

        let d = new Date();
        let filename = `carteGrise_${d?.toISOString()?.split(" ").join("-").split(":").join("-").split(".")[0]
            }.${extension}`;
        console.log("d: ", d, " fn: ", filename);
        formData.append(`fichier`, e, filename);

        axios
            .post(`${API_URL}/api/engin/carte-grise`, formData, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((response) => {
                console.log(response.data);
                // let newFormValues = [...formData];
                // // newFormValues[index]["carteGrise"] = response.data;
                // setFormData(newFormValues);
                handleChange(index, "carteGrise", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        axios
            .get(`${API_URL}/api/type-piece`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                setTypePieces(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/parametre/type/PROFESSION`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setProfessions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/parametre/type/MARQUE`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setMarques(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/organisme/concessionnaire`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let dt = [];
                res.data.forEach((org) => {
                    dt.push({
                        label: org.raisonSociale,
                        value: JSON.stringify(org),
                    });
                });
                setConcessionnaires(dt);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/parametre/type/CAROSSERIE`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setCarosseries(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [TOKEN]);

    const someEffects = (codeId) => {
        var dt = [[]];
        dt[codeId] = [];
        let newFormValues = [...formValues];
        var categorieId = contrat?.engins[0]?.categorie?.categorieId;

        axios
            .get(`${API_URL}/api/garantie/categorie/compulsory/${categorieId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("Guaranties", res.data);
                res.data?.forEach((guaranty, index) => {
                    axios
                        .get(`${API_URL}/api/sous-garantie/capital/${guaranty.codeId}`, {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods":
                                    "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        })
                        .then((response) => {
                            // console.log("Responses...", response.data);
                            dt[codeId].push({
                                key: index + 1,
                                nom: `${guaranty.nom} - ${guaranty.categorie.nom}`,
                                capital: guaranty.nom.toLowerCase().startsWith("rc")
                                    ? "-"
                                    : response.data[0],
                                primeNette: guaranty.nom.toLowerCase().startsWith("rc")
                                    ? newFormValues[codeId].rc
                                    : response.data[1],
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                });

                setTimeout(() => {
                    // console.log("SubGuaranties", dt);
                    var dts = [];
                    dt.forEach((sg) => {
                        sg.forEach((g) => {
                            dts.push(g);
                        });
                    });
                    sessionStorage.setItem("subGaranties", JSON.stringify(dts));

                    // console.log("DTS: ", dts);
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/garantie/categorie/non-compulsory/${categorieId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                sessionStorage.setItem("ncGuaranties", JSON.stringify(res.data));
            })
            .catch((err) => {
                console.log(err);
            });
        //
    };

    const handlePuissance = (value, codeId) => {
        var puissanceRC = value;
        var categorieId = contrat?.engins[0]?.categorie?.categorieId;
        axios
            .get(`${API_URL}/api/sous-garantie/rc/${puissanceRC}/${categorieId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((resp) => {
                // console.log(resp);
                let newFormValues = [...formValues];
                newFormValues[codeId]["rc"] = resp.data.primeNette;
                // sessionStorage.setItem("rcPN", resp.data.primeNette);
                sessionStorage.setItem("formValues", JSON.stringify(newFormValues));
                setFormValues(newFormValues);
                someEffects(codeId);
            })
            .catch((errp) => {
                console.log(errp);
            });
    };

    const handleChangementVehiculeSubmit = () => {
        if (selectedEngin) {
            const validated = validateForm();
            if (validated) {
                setLoading(true);
                // console.log("Current contrat: ", contrat);
                // console.log("FormValues: ", formValues);
                var allEngins = [];
                formValues[0].categorie = contrat.engins[0].categorie;
                if (formValues[0].personne.piece.numIdentite) {
                    var pieces = [];
                    pieces.push(formValues[0].piece);

                    var personne = initPersonne;
                    personne.nom = formValues[0].personne.nom;
                    personne.prenom = formValues[0].personne.prenom;
                    personne.genre = formValues[0].personne.genre;
                    personne.adresse = formValues[0].personne.adresse;
                    personne.profession = formValues[0].personne.profession;
                    personne.contact = formValues[0].personne.contact;
                    personne.typePersonne = "PHYSIQUE";

                    axios
                        .post(`${API_URL}/api/piece-identite/all`, JSON.stringify(pieces), {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods":
                                    "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        })
                        .then((res) => {
                            personne.pieces = res.data;
                            axios
                                .get(
                                    `${API_URL}/api/personne/code-assure-p/${LOGGED_USER.profil.organisme.organismeId}`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${TOKEN}`,
                                            "Content-Type": "application/json",
                                            "Access-Control-Allow-Origin": "*",
                                            "Access-Control-Allow-Methods":
                                                "GET, PUT, POST, DELETE, OPTIONS",
                                            "Access-Control-Allow-Headers":
                                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                        },
                                    }
                                )
                                .then((resCode) => {
                                    personne.numeroAssureP = resCode.data;
                                    axios
                                        .post(`${API_URL}/api/personne`, JSON.stringify(personne), {
                                            headers: {
                                                Authorization: `Bearer ${TOKEN}`,
                                                "Content-Type": "application/json",
                                                "Access-Control-Allow-Origin": "*",
                                                "Access-Control-Allow-Methods":
                                                    "GET, PUT, POST, DELETE, OPTIONS",
                                                "Access-Control-Allow-Headers":
                                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                            },
                                        })
                                        .then((resPersonne) => {
                                            formValues[0].personne = resPersonne.data;
                                            allEngins.push(formValues[0]);
                                        })
                                        .catch((errPersonne) => {
                                            console.log(errPersonne);
                                        });
                                })
                                .catch((errCode) => {
                                    console.log(errCode);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    formValues[0].personne = contrat.souscripteur;
                    allEngins.push(formValues[0]);
                }

                axios
                    .put(
                        `${API_URL}/api/engin/changement-vehicule/codeId/${contrat.codeId
                        }/${JSON.parse(selectedEngin).codeId}`,
                        JSON.stringify(allEngins[0]),
                        {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods":
                                    "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        }
                    )
                    .then((resEngin) => {
                        // console.log(resEngin.data);
                        resEngin.data.codeId = contrat.codeId;
                        toast("Changement de vehicule effectué!");
                        setLoading(false);
                        setModalChangementVehicule(false);
                        props.update(false);
                        navigate(`/details-contrat/${resEngin.data.codeId}`);
                    })
                    .catch((errEngin) => {
                        console.log(errEngin);
                        toast("Erreur lors du changement du véhicule!");
                    });
            } else {
                toast("Entrez correctement les informations du vehicule");
            }
        } else {
            toast("Veuillez selectionner l'engin a changer!");
        }
    };

    const loadModal = (index) => {
        setModalIndex(index);
        setModalAssureOpen(true);
    };

    const handleShowAddPersonModal = (id) => {
        setPersonneId(id);
        setShowAddPersonModal(true);
    };

    const handleCancel = () => {
        setModalChangementVehicule(false);
        props.update(false);
    };

    const handleChange = (e, name, index) => {
        let newFormValues = [...formValues];

        if (name === "carteGrise") {
            newFormValues[index].carteGrise = e;
        } else {
            const { id, value } = e.target;
            newFormValues[index][id] = value;
            if (e.target.name === "valeurVenale") {
                newFormValues[index].valeurANeuf = value;
                newFormValues[index].valeurDeclaree = value;
            }

            newFormValues[index].immatriculation =
                newFormValues[index].numeroSerie +
                " " +
                newFormValues[index].matricule +
                " " +
                newFormValues[index].typeSerie;

            if (id === "numeroSerie" || id === "matricule" || id === "typeSerie") {
                verifyImmatriculation(newFormValues[index].immatriculation, index);
            }
            // console.log("Here out", id);
            if (id === "numeroChassis") {
                // console.log("Here");
                verifyChassisNumber(value, index);
            }
        }

        setFormValues(newFormValues);
    };

    const verifyChassisNumber = (numChassis, index) => {
        // console.log("Verifying...");
        axios
            .get(`${API_URL}/api/engin/chassis/${numChassis}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let ec = [...existChassis];
                ec[index] = res.data;
                // console.log("chasss: ", numChassis, res.data);
                if (res.data) {
                    toast("Un moteur est déjà enregistré avec ce numéro de châssis");
                }
                setExistChassis(ec);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const verifyImmatriculation = (immatriculation, index) => {
        axios
            .get(`${API_URL}/api/engin/immatriculation/${immatriculation}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log("Immatriculation: ", immatriculation);
                console.log(res.data, index);
                let ei = [...existImmatriculation];
                ei[index] = res.data;
                if (res.data) {
                    toast("Immatriculation déjà existante!");
                }
                setExistImmatriculation(ei);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const validateForm = () => {
        const newErrors = [];

        formValues.forEach((fd, index) => {
            newErrors.push({});
            console.log("eiiim", existImmatriculation[index], index);
            if (!fd.numeroSerie)
                newErrors[index].numeroSerie = "Le numéro de série est requis";
            if (fd.numeroSerie?.length !== 2)
                newErrors[index].numeroSerie =
                    "Le numéro de série doit avoir 2 caractères";
            if (!fd.matricule)
                newErrors[index].matricule = "Le numéro d'immatriculation est requis";
            if (fd.matricule?.length !== 4)
                newErrors[index].matricule =
                    "Le numéro d'immatriculation doit avoir 4 caractères";
            if (existImmatriculation[index])
                newErrors[index].matricule =
                    "Un engin est déjà enregistré avec cette immatriculation";
            if (!fd.marque) newErrors[index].marque = "La marque est requise";
            if (!fd.nombrePlace)
                newErrors[index].nombrePlace = "Le nombre de places est requis";
            if (!fd.carosserie)
                newErrors[index].carosserie = "La carosserie est requise";
            if (!fd.moteur) newErrors[index].moteur = "Le moteur est requis";
            if (!fd.genre) newErrors[index].genre = "Le genre du moteur est requis";
            if (!fd.type) newErrors[index].type = "Le type de moteur est requis";
            if (!fd.dateMiseEnCirculation)
                newErrors[index].dateMiseEnCirculation =
                    "La date de mise en circulation est requise";
            if (!fd.numeroChassis)
                newErrors[index].numeroChassis = "Le numéro de châssis est requis";
            if (fd.numeroChassis?.length !== 17)
                newErrors[index].numeroChassis =
                    "Le numéro de châssis doit comporter 17 caractères";
            if (existChassis[index])
                newErrors[index].numeroChassis =
                    "Un moteur est déjà enregistré avec ce numéro de châssis!";
        });

        // Add more validation rules if needed
        setErrors(newErrors);

        let valid = true;
        newErrors.forEach((ne) => {
            if (Object.keys(ne).length !== 0) {
                valid = false;
            }
        });

        return valid;
    };

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const disabledCirculationDate = (current) => {
        // Can not select years after this year
        return current && current.year() > dayjs().year();
    };

    const handleGarantiesChange = (value, index) => {
        let newGaranties = [...garanties];
        newGaranties[index] = value;
        setGaranties(newGaranties);
    };

    const handleClose = () => setShowAddPersonModal(false);

    const handleSousGarantie = (index, event) => {
        let newFormValues = [...formValues];
        // console.log("EVENT: ", event.target.value);
        var gtys = [];
        event.forEach((e) => {
            let g = ncGuaranties.find((el) => el.codeId === e);
            gtys.push(g);
        });
        // console.log("GTY: ", gtys);
        axios
            .post(`${API_URL}/api/sous-garantie/garanties`, JSON.stringify(gtys), {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((ress) => {
                newFormValues[index]["sousGaranties"] = ress.data;
                setFormValues(newFormValues);
            })
            .catch((errr) => {
                console.log(errr);
            });
    };

    const handleAssureChange = (e, index) => {
        let newFormValues = [...formValues];

        const { name, value } = e.target;

        newFormValues[index].personne[name] = value;

        setFormValues(newFormValues);
    };

    return (
        <Modal
            title="CHANGEMENT DE VEHICULE"
            confirmLoading={loading}
            centered
            open={modalChangementVehicule}
            onOk={() => handleChangementVehiculeSubmit()}
            onCancel={() => handleCancel()}
            width={900}
            zIndex={1500}
        >
            <div className="row" justify="center">
                <form>
                    <div class="col-sm-12">
                        <label class="form-label" for="vehiculeAChanger">Véhicule à changer</label>
                        <Select
                            value={selectedEngin}
                            onChange={(value) => {
                                setSelectedEngin(value);
                            }}
                            options={contrat?.engins?.map((engin, index) => (
                                {
                                    libelle: `${engin.marque} - ${engin.numeroChassis}`,
                                    value: JSON.stringify(engin),
                                    key: index
                                }

                            ))}
                            style={{ width: "100%" }}
                        />
                    </div>

                </form>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="last-name-modern-vertical"
                    >
                        Immatriculation
                    </label>
                    <div class="input-group">
                        <input
                            type="text"
                            aria-label="N° série"
                            placeholder="N° série"
                            class={`form-control ${errors[0]?.numeroSerie
                                ? "is-invalid"
                                : ""
                                }`}
                            name="numeroSerie"
                            id="numeroSerie"
                            value={formValues[0].numeroSerie}
                            onChange={(e) =>
                                handleChange(e, "numeroSerie", 0)
                            }
                        />
                        {errors[0]?.numeroSerie && (
                            <div className="invalid-feedback">
                                {errors[0]?.numeroSerie}
                            </div>
                        )}
                        <input
                            type="text"
                            aria-label="Immatriculation"
                            placeholder="Immatriculation"
                            class={`form-control ${errors[0]?.matricule ? "is-invalid" : ""
                                }`}
                            id="matricule"
                            name="matricule"
                            value={formValues[0].matricule}
                            onChange={(e) =>
                                handleChange(e, "matricule", 0)
                            }
                        />
                        {errors[0]?.matricule && (
                            <div className="invalid-feedback">
                                {errors[0]?.matricule}
                            </div>
                        )}
                        <select
                            id="typeSerie"
                            name="typeSerie"
                            class={`form-control ${errors[0]?.typeSerie ? "is-invalid" : ""
                                }`}
                            data-style="btn-default"
                            value={formValues[0].typeSerie}
                            onChange={(e) =>
                                handleChange(e, "typeSerie", 0)
                            }
                        >
                            <option value="TG">TG</option>
                            <option value="OI">OI</option>
                            <option value="G/A">G/A</option>
                        </select>
                        {errors[0]?.typeSerie && (
                            <div className="invalid-feedback">
                                {errors[0]?.typeSerie}
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="marque"
                    >
                        Marque
                    </label>
                    <select
                        id="marque"
                        name="marque"
                        value={formValues[0].marque}
                        onChange={(e) =>
                            handleChange(e, "marque", 0)
                        }
                        class={`form-control w-100 ${errors[0]?.marque ? "is-invalid" : ""
                            }`}
                        data-style="btn-default"
                    >
                        <option value="">
                            Sélectionner la marque
                        </option>
                        {marques?.map((mar, id) => (
                            <option
                                key={id}
                                value={mar.libelleParametre}
                            >
                                {mar.libelleParametre}
                            </option>
                        ))}
                    </select>
                    {errors[0]?.marque && (
                        <div className="invalid-feedback">
                            {errors[0]?.marque}
                        </div>
                    )}
                </div>
                <div className="col-sm-6">
                    <label class="form-label" for="energieId">
                        Energie
                    </label>
                    <select
                        class="form-control w-100"
                        data-style="btn-default"
                        id="energie"
                        name="energie"
                        value={formValues[0].energie}
                        onChange={(e) =>
                            handleChange(e, "energie", 0)
                        }
                    >
                        <option value="ESSENCE">ESSENCE</option>
                        <option value="DIESEL">DIESEL</option>
                        <option value="ELECTRIQUE">
                            ELECTRIQUE
                        </option>
                    </select>
                </div>
                {contrat?.engins[0]?.categorie?.nom !== "MOTO" ? (
                    <div className="col-sm-6">
                        <label
                            className="form-label"
                            htmlFor="puissanceId"
                        >
                            Puissance (en CV)
                        </label>
                        <input
                            type="number"
                            id="puissance"
                            name="puissance"
                            value={formValues[0].puissance}
                            onChange={(e) => {
                                handleChange(e, "puissance", 0);
                                handlePuissance(e.target.value, 0);
                            }}
                            className="form-control"
                            placeholder="Puissance"
                        />
                    </div>
                ) : (
                    <div className="col-sm-6">
                        <label
                            className="form-label"
                            htmlFor="volumeId"
                        >
                            Volume (en cm^3)
                        </label>
                        <input
                            type="number"
                            id="volume"
                            name="volume"
                            value={formValues[0].volume}
                            onChange={(e) => {
                                handleChange(e, "volume", 0);
                                handlePuissance(e.target.value, 0);
                            }}
                            className="form-control"
                            placeholder="Volume"
                        />
                    </div>
                )}

                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="nbrePlaceId"
                    >
                        Nombre de places
                    </label>
                    <input
                        type="number"
                        id="nombrePlace"
                        name="nombrePlace"
                        value={formValues[0].nombrePlace}
                        onChange={(e) =>
                            handleChange(e, "nombrePlace", 0)
                        }
                        className={`form-control ${errors[0]?.nombrePlace ? "is-invalid" : ""
                            }`}
                        placeholder="Nbre de places"
                    />
                    {errors[0]?.nombrePlace && (
                        <div className="invalid-feedback">
                            {errors[0]?.nombrePlace}
                        </div>
                    )}
                </div>
                <div className="col-sm-6">
                    <label className="form-label" htmlFor="usage">
                        Usage
                    </label>
                    <select
                        id="usage"
                        name="usage"
                        value={formValues[0].usage}
                        onChange={(e) =>
                            handleChange(e, "usage", 0)
                        }
                        class="form-control w-100"
                        data-style="btn-default"
                    >
                        <option value="TAXI">Taxi</option>
                        <option value="PRIVE">Privé</option>
                    </select>
                </div>
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="carosserie"
                    >
                        Carosserie
                    </label>
                    <select
                        id="carosserie"
                        name="carosserie"
                        value={formValues[0].carosserie}
                        onChange={(e) =>
                            handleChange(e, "carosserie", 0)
                        }
                        class={`form-control w-100 ${errors[0]?.carosserie ? "is-invalid" : ""
                            }`}
                        data-style="btn-default"
                    >
                        <option value="">
                            Sélectionner une carosserie
                        </option>
                        {carosseries?.map((car, id) => (
                            <option
                                key={id}
                                value={car.libelleParametre}
                            >
                                {car.libelleParametre}
                            </option>
                        ))}
                    </select>
                    {errors[0]?.carosserie && (
                        <div className="invalid-feedback">
                            {errors[0]?.carosserie}
                        </div>
                    )}
                </div>
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="moteur"
                    >
                        Moteur
                    </label>
                    <input
                        type="text"
                        id="moteur"
                        name="moteur"
                        value={formValues[0].moteur}
                        onChange={(e) =>
                            handleChange(e, "moteur", 0)
                        }
                        className={`form-control ${errors[0]?.moteur ? "is-invalid" : ""
                            }`}
                        placeholder="Moteur"
                    />
                    {errors[0]?.moteur && (
                        <div className="invalid-feedback">
                            {errors[0]?.moteur}
                        </div>
                    )}
                </div>
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="typeMoteurId"
                    >
                        Type de moteur
                    </label>
                    <input
                        type="text"
                        id="type"
                        name="type"
                        value={formValues[0].type}
                        onChange={(e) => handleChange(e, "type", 0)}
                        className={`form-control ${errors[0]?.type ? "is-invalid" : ""
                            }`}
                        placeholder="Moteur"
                    />
                    {errors[0]?.type && (
                        <div className="invalid-feedback">
                            {errors[0]?.type}
                        </div>
                    )}
                </div>
                {contrat?.engins[0]?.categorie?.nom === "MOTO" ? (
                    <div className="col-sm-6">
                        <label
                            className="form-label"
                            htmlFor="genreMoteurId"
                        >
                            Genre du moteur
                        </label>
                        <select
                            id="genre"
                            name="genre"
                            value={formValues[0].genre}
                            onChange={(e) =>
                                handleChange(e, "genre", 0)
                            }
                            class={`form-control w-100 ${errors[0]?.genre ? "is-invalid" : ""
                                }`}
                            data-style="btn-default"
                        >
                            <option value="VELOMOTEUR">
                                Vélomoteur
                            </option>
                            <option value="TRICYCLE">Tricycle</option>
                            <option value="MOTOCYCLETTE">
                                Motocyclette
                            </option>
                            <option value="CYCLOMOTEUR">
                                Cyclomoteur
                            </option>
                        </select>
                    </div>
                ) : null}
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="concessionnaire"
                    >
                        Concessionnaire / Intermédiaire
                    </label>
                    <select
                        class="form-control w-100"
                        data-style="btn-default"
                        id="concessionnaire"
                        name="concessionnaire"
                        value={formValues[0].concessionnaire}
                        onChange={(e) =>
                            handleChange(e, "concessionnaire", 0)
                        }
                    >
                        <option value="">
                            Veuillez sélectionner le concessionnaire
                        </option>
                        {concessionnaires.map((con, id) => (
                            <option key={id} value={con.value}>
                                {con.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="numeroChassis"
                    >
                        Numéro de châssis
                    </label>
                    <input
                        type="text"
                        id="numeroChassis"
                        name="numeroChassis"
                        value={formValues[0].numeroChassis}
                        onChange={(e) =>
                            handleChange(e, "numeroChassis", 0)
                        }
                        className={`form-control ${errors[0]?.numeroChassis
                            ? "is-invalid"
                            : ""
                            }`}
                        placeholder="Numéro de châssis"
                    />
                    {errors[0]?.numeroChassis && (
                        <div className="invalid-feedback">
                            {errors[0]?.numeroChassis}
                        </div>
                    )}
                </div>
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="valeurVenale"
                    >
                        Valeur vénale
                    </label>
                    <input
                        type="number"
                        id="valeurVenale"
                        name="valeurVenale"
                        value={formValues[0].valeurVenale}
                        onChange={(e) =>
                            handleChange(e, "valeurVenale", 0)
                        }
                        className="form-control"
                        placeholder="Valeur vénale"
                    />
                </div>
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="valeurANeuf"
                    >
                        Valeur à neuf
                    </label>
                    <input
                        type="number"
                        id="valeurANeuf"
                        name="valeurANeuf"
                        value={formValues[0].valeurANeuf}
                        onChange={(e) =>
                            handleChange(e, "valeurANeuf", 0)
                        }
                        className="form-control"
                        placeholder="Valeur à neuf"
                    />
                </div>

                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="valeurDeclaree"
                    >
                        Valeur déclarée
                    </label>
                    <input
                        type="number"
                        id="valeurDeclaree"
                        name="valeurDeclaree"
                        value={formValues[0].valeurDeclaree}
                        onChange={(e) =>
                            handleChange(e, "valeurDeclaree", 0)
                        }
                        className="form-control"
                        placeholder="Valeur déclarée"
                    />
                </div>
                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="miseEnCirculationId"
                    >
                        Date de mise en circulation
                    </label>
                    <div class="col-md-12">
                        <DatePicker
                            onChange={(e) =>
                                handleDateMiseEnCirculation(e, 0)
                            }
                            picker="year"
                            placeholder="Veuillez sélectionner l'année"
                            disabledDate={disabledCirculationDate}
                            showNow={true}
                            style={{
                                width: "100%",
                                borderColor: `${errors[0]?.dateMiseEnCirculation
                                    ? "red"
                                    : "none"
                                    }`,
                            }}
                        />
                        {errors[0]?.dateMiseEnCirculation && (
                            <div className="invalid-feedback">
                                {errors[0]?.dateMiseEnCirculation}
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-sm-6">
                    <label
                        className="form-label"
                        htmlFor="username-modern-vertical"
                    >
                        Garanties supplémentaires
                    </label>
                    <Select
                        mode="multiple"
                        style={{
                            width: "100%",
                        }}
                        placeholder="Sélectionnez les garanties"
                        onChange={(e) => {
                            handleGarantiesChange(e, 0);
                            handleSousGarantie(0, e);
                        }}
                        options={ncGuaranties?.map((ng, id) => ({
                            label: ng.nom,
                            value: ng.codeId,
                            key: id,
                        }))}
                    />
                </div>
                <div className="row mt-4">
                    <div class="d-grid gap-2 col-lg-6 mx-auto">
                        <Upload {...prop(0)}>
                            <br />
                            <Button
                                style={{
                                    background: "gray",
                                    color: "white",
                                }}
                                icon={<CreditCardOutlined />}
                            >
                                Joindre la carte grise
                            </Button>
                        </Upload>
                    </div>
                </div>
                <div className="col-sm-3">
                    <button
                        className="btn btn-info"
                        onClick={() => {
                            console.log("Reallly?");
                            handleShowAddPersonModal(0);
                        }}
                    >
                        <i className="bx bx-user-plus bx-sm ms-sm-n2 me-sm-2"></i>
                        <span className="align-middle d-sm-inline-block d-none">
                            Préciser l'assuré
                        </span>
                    </button>
                </div>
            </div>
            <Modal
                title="Ajouter un assuré"
                centered
                open={modalAssureOpen}
                onOk={() => setModalAssureOpen(false)}
                onCancel={() => setModalAssureOpen(false)}
            >
                <div className="container" justify="center">
                    <div className="text-center mb-6">
                        <h4 className="address-title mb-2">Ajouter une personne</h4>
                        <p className="address-subtitle">
                            Préciser l'assuré, si différent du souscripteur
                        </p>
                    </div>
                    <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                        <div className="col-12 col-md-12">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="nom">
                                        Nom
                                    </label>
                                    <input
                                        type="text"
                                        id="nom"
                                        name="nom"
                                        class="form-control"
                                        placeholder="Entrez le nom de l'assuré"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                    />
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="prenom">
                                        Prénom(s)
                                    </label>
                                    <input
                                        type="text"
                                        id="prenom"
                                        name="prenom"
                                        class="form-control"
                                        placeholder="Entrez le prénom de l'assuré"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                    />
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="genre">
                                        Genre
                                    </label>
                                    <select
                                        id="genre"
                                        name="genre"
                                        class="form-control"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                    >
                                        <option value="MASCULIN">MASCULIN</option>
                                        <option value="FEMININ">FEMININ</option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="profession">
                                        Profession
                                    </label>
                                    <select
                                        id="profession"
                                        name="profession"
                                        class="form-control"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                    >
                                        <option value="">Selectionnez la profession</option>
                                        {professions.map((prof, id) => (
                                            <option key={id} value={prof.libelleParametre}>
                                                {prof.libelleParametre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="email">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        class="form-control"
                                        placeholder="Entrez l'adresse mail"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                    />
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="adresse">
                                        Adresse de résidence
                                    </label>
                                    <input
                                        type="text"
                                        id="adresse"
                                        name="adresse"
                                        class="form-control"
                                        placeholder="Entrez l'adresse de résidence"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                    />
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="phone">
                                        Numéro de téléphone
                                    </label>
                                    <div class="input-group">
                                        <span class="input-group-text">TG (+228)</span>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            class="form-control phone-number-mask"
                                            placeholder="00000000"
                                            onChange={(e) => handleAssureChange(e, personneId)}
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="typePiece">
                                        Type de pièce
                                    </label>
                                    <select
                                        id="typePiece"
                                        name="typePiece"
                                        class="form-control"
                                        data-allow-clear="true"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                    >
                                        <option value="CNI">CNI</option>
                                        <option value="PASSEPORT">PASSEPORT</option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="numIdentite">
                                        Numéro de la pièce d'identité
                                    </label>
                                    <input
                                        type="text"
                                        id="numIdentite"
                                        name="numIdentite"
                                        class="form-control"
                                        placeholder="Entrez le numéro de la pièce"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label" htmlFor="dateDelivrance">
                                        Date de délivrance
                                    </label>
                                    <div class="col-md-12">
                                        <input
                                            class="form-control"
                                            type="month"
                                            id="dateDelivrance"
                                            name="dateDelivrance"
                                            onChange={(e) => handleAssureChange(e, personneId)}
                                        />
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <br />
                                    <button type="submit" class="btn btn-primary me-3">
                                        Enregistrer
                                    </button>
                                    <button
                                        type="reset"
                                        class="btn btn-label-secondary"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => handleClose()}
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </Modal>
    );
};

ChangementDeVehicule.propTypes = {
    open: PropTypes.bool,
    update: PropTypes.func,
    contrat: PropTypes.object
};

export default ChangementDeVehicule;