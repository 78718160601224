import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddFractionnement from "./AddFractionnement";
const { confirm } = Modal;

const Fractionnements = () => {
  const [fractionnements, setFractionnements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedFractionnement, setSelectedFractionnement] = useState(null);

  const handleShow = (edit, fractionnement = null) => {
    setEditMode(edit);
    setSelectedFractionnement(fractionnement);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/fractionnement`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFractionnements(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors du chargement des fractionnements");
      });
  }, []);

  const columns = [
    {
      title: "Libellé",
      dataIndex: "libelleFractionnement",
      key: "libelleFractionnement",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Pér. min",
      dataIndex: "moisMin",
      key: "moisMin",
      render: (_, record) => (
        <>
          {record.periode ? `${record.moisMin} ${record.periode === "JOUR" ? "JOURS" : record.periode}` : `${record.moisMin} MOIS`}
        </>
      ),
    },
    {
      title: "Pér. max",
      dataIndex: "moisMax",
      key: "moisMax",
      render: (_, record) => (
        <>
          {record.periode ? `${record.moisMax} ${record.periode === "JOUR" ? "JOURS" : record.periode}` : `${record.moisMax} MOIS`}
        </>
      ),
    },
    {
      title: "Catégorie",
      dataIndex: "categorie",
      key: "categorie",
      render: (cat) => (cat ? cat.nom : "N/A"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-icon rounded-pill"
            onClick={() => handleShow(true, record)}
          >
            <i className="bx bx-edit-alt"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon rounded-pill"
            onClick={() => showDeleteConfirm(record.fractionnementId)}
          >
            <i className="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];

  const showDeleteConfirm = (fractionnementId) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer ce fractionnement?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteFractionnement(fractionnementId);
      },
    });
  };

  const deleteFractionnement = (fractionnementId) => {
    axios
      .delete(`${API_URL}/api/fractionnement/${fractionnementId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setFractionnements(fractionnements.filter(fra => fra.fractionnementId !== fractionnementId));
        toast.success("Fractionnement supprimé avec succès");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de la suppression");
      });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />

        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false)}
              >
                Ajouter un nouveau fractionnement
              </Button>
              <div className="card">
                <Table
                  columns={columns}
                  dataSource={fractionnements}
                  rowKey="fractionnementId"
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>

      <AddFractionnement
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedFractionnement={selectedFractionnement}
      />
    </div>
  );
};

export default Fractionnements;
