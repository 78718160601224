import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../variables/constants";

const FirstConnexion = () => {
  const [loading, setLoading] = useState(false);
  const [termsAndConditionsCheck, setTermsAndConditionsCheck] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}$/;

    if (!formData.email) {
      newErrors.email = "Adresse mail requise";
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "L'adresse email n'est pas valide.";
    }

    if (!formData.password) {
      newErrors.password = "Mot de passe requis";
    }

    if (!formData.newPassword) {
      newErrors.newPassword = "Nouveau mot de passe requis";
    } else if (!passwordPattern.test(formData.newPassword)) {
      newErrors.newPassword =
        "Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.";
    } else if (formData.password === formData.newPassword) {
      newErrors.newPassword =
        "Votre nouveau mot de passe ne doit pas être le même que l'ancien";
    }

    if (formData.newPassword !== formData.confirmNewPassword) {
      newErrors.confirmNewPassword = "Les mots de passe ne correspondent pas";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Handling", errors);
    if (validateForm()) {
      // console.log("Validating");
      if (!termsAndConditionsCheck) {
        // console.log("termsAndconditions");
        toast("Veuillez accepter les termes et conditions de PoolIns");
      } else {
        setLoading(true);
        const user = {
          email: formData.email,
          password: formData.password,
          newPassword: formData.newPassword,
        };
        // console.log("FormDataUser: ", user);
        const utilisateur = {
          userName: formData.email,
          password: formData.password,
        };

        axios
          .post(`${API_URL}/api/authenticate`, JSON.stringify(utilisateur), {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          })
          .then((res) => {
            if (res.status === 200) {
              console.log("Userrrrrrrrr: ", JSON.stringify(user));
              axios
                .get(
                  `${API_URL}/api/utilisateur/first-connexion/${formData.email}/${formData.newPassword.trim()}`,
                  {
                    headers: {
                      Authorization: `Bearer ${res.data}`,
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods":
                        "GET, PUT, POST, DELETE, OPTIONS",
                      "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data);
                  setLoading(false);
                  navigate("/connexion", { replace: true });
                  toast("Mot de passe défini avec succès");
                })
                .catch((err) => {
                  console.log(err);
                  toast("Erreur lors de la définition du mot de passe");
                  setLoading(false);
                });
            } else {
              toast(
                "Veuillez vérifier votre adresse mail et le mot de passe renseigné"
              );
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("Error: ", err);
            toast(
              "Veuillez vérifier votre adresse mail et le mot de passe renseignés"
            );
            setLoading(false);
          });
      }
    }
  };

  return (
    <div class="authentication-wrapper authentication-cover">
      <div class="authentication-inner row m-0">
        {/* <!-- /Left Text -->  */}
        <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
          <div class="w-100 d-flex justify-content-center">
            <img
              src="assets/img/login.jpg"
              class="img-fluid scaleX-n1-rtl"
              alt="LoginImage"
              width="10000"
              data-app-dark-img="login.jpg"
              data-app-light-img="login.jpg"
            />
          </div>
        </div>
        {/* <!-- /Left Text --> */}

        {/* <!-- Forgot Password --> */}
        <div class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-12 p-6">
          <div class="w-px-400 mx-auto mt-12 mt-5">
            <h4 class="mb-1">Bienvenue sur PoolIns 🚀</h4>
            <p class="mb-6">
              Veuillez renseigner les informations et définir votre mot de passe
              afin de vous connecter
            </p>
            <form id="formAuthentication" class="mb-6">
              <div class="mb-6">
                <label for="email" class="form-label">
                  Adresse mail
                </label>
                <input
                  type="text"
                  class={`form-control ${errors.email ? "is-invalid" : ""}`}
                  id="email"
                  name="email"
                  placeholder="Entrez votre adresse mail"
                  value={formData.email}
                  onChange={handleInputChange}
                  autofocus
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              <div class="mb-6">
                <label class="form-label" for="password">
                  Mot de passe
                </label>
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    id="password"
                    class={`form-control ${errors.password ? "is-invalid" : ""
                      }`}
                    name="password"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <span class="input-group-text cursor-pointer">
                    <i class="bx bx-hide"></i>
                  </span>
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
              </div>
              <div class="mb-6">
                <label for="newPassword" class="form-label">
                  Nouveau mot de passe
                </label>
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    class={`form-control ${errors.newPassword ? "is-invalid" : ""
                      }`}
                    id="newPassword"
                    name="newPassword"
                    placeholder="Entrez votre nouveau mot de passe"
                    value={formData.newPassword}
                    onChange={handleInputChange}
                    autofocus
                  />
                  <span class="input-group-text cursor-pointer">
                    <i class="bx bx-hide"></i>
                  </span>
                  {errors.newPassword && (
                    <div className="invalid-feedback">{errors.newPassword}</div>
                  )}
                </div>
              </div>
              <div class="mb-6 form-password-toggle">
                <label class="form-label" for="confirmNewPassword">
                  Confirmer votre nouveau mot de passe
                </label>
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    id="confirmNewPassword"
                    class={`form-control ${errors.confirmNewPassword ? "is-invalid" : ""
                      }`}
                    name="confirmNewPassword"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="confirmNewPassword"
                    value={formData.confirmNewPassword}
                    onChange={handleInputChange}
                  />
                  <span class="input-group-text cursor-pointer">
                    <i class="bx bx-hide"></i>
                  </span>
                  {errors.confirmNewPassword && (
                    <div className="invalid-feedback">
                      {errors.confirmNewPassword}
                    </div>
                  )}
                </div>
              </div>
              <div class="mb-6 mt-8">
                <div class="form-check mb-8 ms-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="terms-conditions"
                    name="terms"
                    onChange={() =>
                      setTermsAndConditionsCheck(!termsAndConditionsCheck)
                    }
                    checked={termsAndConditionsCheck}
                  />
                  <label class="form-check-label" for="terms-conditions">
                    J'accepte{" "}
                    <a href="javascript:void(0);">
                      les termes et conditions de confidentialité
                    </a>
                  </label>
                </div>
              </div>
              <button
                class="btn btn-primary d-grid w-100"
                disabled={loading}
                onClick={(e) => handleSubmit(e)}
              >
                {loading && (
                  <span
                    class="spinner-grow me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}{" "}
                {loading ? `Enregistrement...` : `Définir le mot de passe`}
              </button>
            </form>
            <div class="text-center">
              <a
                href="/connexion"
                class="d-flex align-items-center justify-content-center"
              >
                <i class="bx bx-chevron-left scaleX-n1-rtl me-1_5 align-top"></i>
                Etape déjà effectuée? Connectez-vous!
              </a>
            </div>
          </div>
        </div>
        {/* <!-- /Forgot Password --> */}
      </div>
    </div>
  );
};

export default FirstConnexion;
