import React from "react";
import Footer from "../../components/Footer";
import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";

const ProfileSecurity = () => {
  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row">
                <div class="col-md-12">
                  <div class="nav-align-top">
                    <ul class="nav nav-pills flex-column flex-md-row mb-6">
                      <li class="nav-item">
                        <a class="nav-link " href="/mon-profil">
                          <i class="bx bx-sm bx-user me-1_5"></i> Compte
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link active" href="/mon-profil-securite">
                          <i class="bx bx-sm bx-lock-alt me-1_5"></i> Sécurité
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="/mon-profil-notifications">
                          <i class="bx bx-sm bx-bell me-1_5"></i> Notifications
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- Change Password --> */}
                  <div class="card mb-6">
                    <h5 class="card-header">Changer de mot de passe</h5>
                    <div class="card-body pt-1">
                      <form id="formAccountSettings" onsubmit="return false">
                        <div class="row">
                          <div class="mb-6 col-md-6 form-password-toggle">
                            <label class="form-label" for="currentPassword">
                              Mot de passe actuel
                            </label>
                            <div class="input-group input-group-merge">
                              <input
                                class="form-control"
                                type="password"
                                name="currentPassword"
                                id="currentPassword"
                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                              />
                              <span class="input-group-text cursor-pointer">
                                <i class="bx bx-hide"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-6 col-md-6 form-password-toggle">
                            <label class="form-label" for="newPassword">
                              Nouveau mot de passe
                            </label>
                            <div class="input-group input-group-merge">
                              <input
                                class="form-control"
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                              />
                              <span class="input-group-text cursor-pointer">
                                <i class="bx bx-hide"></i>
                              </span>
                            </div>
                          </div>

                          <div class="mb-6 col-md-6 form-password-toggle">
                            <label class="form-label" for="confirmPassword">
                              Confirmer le nouveau mot de passe
                            </label>
                            <div class="input-group input-group-merge">
                              <input
                                class="form-control"
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                              />
                              <span class="input-group-text cursor-pointer">
                                <i class="bx bx-hide"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <h6 class="text-body">
                          Exigences relatives au mot de passe:
                        </h6>
                        <ul class="ps-4 mb-0">
                          <li class="mb-4">
                            Minimum de 8 caractères de long - plus il y en a,
                            mieux c'est
                          </li>
                          <li class="mb-4">Au moins un caractère minuscule</li>
                          <li>
                            Au moins un chiffre, un symbole ou un caractère
                            d'espacement
                          </li>
                        </ul>
                        <div class="mt-6">
                          <button type="submit" class="btn btn-primary me-3">
                            Sauvegarder les changements
                          </button>
                          <button type="reset" class="btn btn-label-secondary">
                            Réinitialiser
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* <!--/ Change Password --> */}

                  {/* <!-- Two-steps verification --> */}
                  <div class="card mb-6">
                    <div class="card-body">
                      <h5 class="mb-6">Vérification en deux étapes</h5>
                      <h5 class="mb-4 text-body">
                        L'authentification à deux facteurs est par défaut
                        activée.
                      </h5>
                      <p class="w-75">
                        L'authentification à deux facteurs ajoute une couche de
                        sécurité supplémentaire à votre compte en exigeant plus
                        qu'un simple mot de passe pour vous connecter.
                        <a href="javascript:void(0);" class="text-nowrap">
                          Apprendre encore plus.
                        </a>
                      </p>
                      <button
                        class="btn btn-primary mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#enableOTP"
                      >
                        Désactiver l'authentification à deux facteurs
                      </button>
                    </div>
                  </div>
                  {/* <!-- Modal --> */}
                  {/* <!-- Enable OTP Modal --> */}
                  <div
                    class="modal fade"
                    id="enableOTP"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body">
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                          <div class="text-center mb-6">
                            <h4 class="mb-2">Enable One Time Password</h4>
                            <p>Verify Your Mobile Number for SMS</p>
                          </div>
                          <p>
                            Enter your mobile phone number with country code and
                            we will send you a verification code.
                          </p>
                          <form
                            id="enableOTPForm"
                            class="row g-6"
                            onsubmit="return false"
                          >
                            <div class="col-12">
                              <label
                                class="form-label"
                                for="modalEnableOTPPhone"
                              >
                                Phone Number
                              </label>
                              <div class="input-group">
                                <span class="input-group-text">US (+1)</span>
                                <input
                                  type="text"
                                  id="modalEnableOTPPhone"
                                  name="modalEnableOTPPhone"
                                  class="form-control phone-number-otp-mask"
                                  placeholder="202 555 0111"
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <button
                                type="submit"
                                class="btn btn-primary me-3"
                              >
                                Submit
                              </button>
                              <button
                                type="reset"
                                class="btn btn-label-secondary"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--/ Enable OTP Modal --> */}

                  {/* <!-- /Modal --> */}

                  {/* <!--/ Two-steps verification --> */}

                  {/* <!-- Recent Devices --> */}
                  <div class="card mb-6">
                    <h5 class="card-header">Appareils récents connectés</h5>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="text-truncate">Navigateur</th>
                            <th class="text-truncate">Appareil</th>
                            <th class="text-truncate">Localisation</th>
                            <th class="text-truncate">Récente Activité</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-truncate text-heading fw-medium">
                              <i class="bx bxl-windows bx-md align-top text-info me-4"></i>
                              Chrome on Windows
                            </td>
                            <td class="text-truncate">HP Spectre 360</td>
                            <td class="text-truncate">Switzerland</td>
                            <td class="text-truncate">10, July 2021 20:07</td>
                          </tr>
                          {/* <tr>
                            <td class="text-truncate text-heading fw-medium">
                              <i class="bx bxl-android  bx-md align-top text-success me-4"></i>
                              Chrome on iPhone
                            </td>
                            <td class="text-truncate">iPhone 12x</td>
                            <td class="text-truncate">Australia</td>
                            <td class="text-truncate">13, July 2021 10:10</td>
                          </tr>
                          <tr>
                            <td class="text-truncate text-heading fw-medium">
                              <i class="bx bxl-apple bx-md align-top text-secondary me-4"></i>
                              Chrome on Android
                            </td>
                            <td class="text-truncate">Oneplus 9 Pro</td>
                            <td class="text-truncate">Dubai</td>
                            <td class="text-truncate">14, July 2021 15:15</td>
                          </tr>
                          <tr>
                            <td class="text-truncate text-heading fw-medium">
                              <i class="bx bx-mobile-alt bx-md align-top text-danger me-4"></i>
                              Chrome on MacOS
                            </td>
                            <td class="text-truncate">Apple iMac</td>
                            <td class="text-truncate">India</td>
                            <td class="text-truncate">16, July 2021 16:17</td>
                          </tr>
                          <tr>
                            <td class="text-truncate text-heading fw-medium">
                              <i class="bx bxl-apple bx-md align-top text-warning me-4"></i>
                              Chrome on Windows
                            </td>
                            <td class="text-truncate">HP Spectre 360</td>
                            <td class="text-truncate">Switzerland</td>
                            <td class="text-truncate">20, July 2021 21:01</td>
                          </tr>
                          <tr class="border-transparent">
                            <td class="text-truncate text-heading fw-medium">
                              <i class="bx bxl-android bx-md align-top text-success me-4"></i>
                              Chrome on Android
                            </td>
                            <td class="text-truncate">Oneplus 9 Pro</td>
                            <td class="text-truncate">Dubai</td>
                            <td class="text-truncate">21, July 2021 12:22</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <!--/ Recent Devices --> */}
                </div>
              </div>
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default ProfileSecurity;
