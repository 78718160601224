import React from "react";

const ForgotPassword = () => {
  return (
    <div class="authentication-wrapper authentication-cover">
      {/* <!-- Logo --> */}
      {/* <a href="/#" class="app-brand auth-cover-brand gap-2">
      <span class="app-brand-text demo text-heading fw-bold">PoolIns</span>
    </a> */}
      {/* <!-- /Logo --> */}
      <div class="authentication-inner row m-0">
        {/* <!-- /Left Text -->  */}
        <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
          <div class="w-100 d-flex justify-content-center">
            <img
              src="assets/img/login.jpg"
              class="img-fluid scaleX-n1-rtl"
              alt="LoginImage"
              width="10000"
              data-app-dark-img="login.jpg"
              data-app-light-img="login.jpg"
            />
          </div>
        </div>
        {/* <!-- /Left Text --> */}

        {/* <!-- Forgot Password --> */}
        <div class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-12 p-6">
          <div class="w-px-400 mx-auto mt-12 mt-5">
            <h4 class="mb-1">Mot de passe oublié? 🔒</h4>
            <p class="mb-6">
              Saisissez votre e-mail et nous vous enverrons des instructions
              pour réinitialiser votre mot de passe
            </p>
            <form
              id="formAuthentication"
              class="mb-6"
              action="auth-reset-password-cover.html"
              method="GET"
            >
              <div class="mb-6">
                <label for="email" class="form-label">
                  Adresse mail
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="Entrez votre adresse mail"
                  autofocus
                />
              </div>
              <button class="btn btn-primary d-grid w-100">
                Envoyer le lien de réinitialisation
              </button>
            </form>
            <div class="text-center">
              <a
                href="/connexion"
                class="d-flex align-items-center justify-content-center"
              >
                <i class="bx bx-chevron-left scaleX-n1-rtl me-1_5 align-top"></i>
                Retour à la connexion
              </a>
            </div>
          </div>
        </div>
        {/* <!-- /Forgot Password --> */}
      </div>
    </div>
  );
};

export default ForgotPassword;
