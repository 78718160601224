import {CardBody} from "react-bootstrap";
import {Button, Button as AntdButton, Card, Divider, Input, Modal, Select, Space, Tag} from "antd";
import {FilePdfOutlined, PlusOutlined} from "@ant-design/icons";

import {useReactToPrint} from "react-to-print";
import {useRef} from "react";
import {API_URL, TOKEN} from "../../../../variables/constants";
import axios from "axios";
import {addPersonne, ajouterUneVictime, editerUneVictime, updatePersonne} from "../../../../hooks/useHooks";
import {toast} from "react-toastify";
import OrdreDeDepense from "../../docs/OrdreDeDepense";
import QuittanceIndemnite from "../../docs/QuittanceIndemnite";
import FicheDemandeReglementSinistre from "../../docs/FicheDemandeReglementSinistre";
import AssignCheque from "../../components/AssignCheque";
import TextArea from "antd/es/input/TextArea";
import AjouterExpert from "../../components/AjouterExpert";
import ReglementSinistre from "../../components/ReglementSinistre";

const gridStyle = {
    width: "33%", textAlign: "center", color: "#3C4858",
};


const Traitements = ({
                         loadingSuspension,
                         modalCloture,
                         handleSuspensionDossier,
                         modalSuspension,
                         loadingCloture,
                         handleClotureDossier,
                         setModalReglementSinistre,
                         setModalSuspension,
                         modalVictime,
                         modalReevaluation,
                         ficheDemandeReglement,
                         setAdresseState,
                         adresseState,
                         modalFicheDemandeReglement,
                         sinistre,
                         quittance,
                         modalAddExpert,
                         setOpen,
                         expert,
                         setVictime,
                         setModalReevaluation,
                         modalReglementSinistre,
                         setOpenReglement,
                         setGarantieState,
                         setMotifRevisionState,
                         setValueSelected,
                         setSelectedGaranties,
                         setProvisionGarantie,
                         setEvaluation,
                         setModalVictime,
                         setDescriptionDegatsVictimeState,
                         setProfessionState,
                         setPrenomState,
                         nomState,
                         setNomState,
                         setModalFicheDemandeReglement,
                         setModalQuittance,
                         setModalOrdreDepense,
                         setModalCloture,
                         prenomState,
                         descriptionDegatsVictimeState,
                         evaluation,
                         editMode,
                         selectedGaranties,
                         ordreDepense,
                         decaissement,
                         setEditMode,
                         provisionGaranties,
                         isVictimeValidated,
                         victime,
                         professionState,
                         setName,
                         setItems,
                         initVictime,
                         modalQuittance,
                         modalAssignCheque,
                         name,
                         removeProvisionFormFields,
                         addProvisionFormFields,
                         valueSelected,
                         garanties,
                         modalOrdreDepense,
                         initEvaluation,
                         setModalAddExpert,
                         handleChange,
                         items,
                         inputRef,
                         handleSubmit,
                     }) => {

    const printReference = useRef();
    const printODReference = useRef();
    const printFDReference = useRef();
    const printQReference = useRef();

    const handleVictimeSubmit = (e) => {
        e.preventDefault();
        const validate = isVictimeValidated();
        if (validate) {
            console.log("editMode: ", editMode);
            if (editMode) {
                victime.sinistre = sinistre;
                updatePersonne(JSON.stringify(victime.personne))
                    .then((res) => {
                        victime.personne = res.data;
                        editerUneVictime(JSON.stringify(victime))
                            .then((response) => {
                                console.log(response.data);
                                setEditMode(false);
                                setModalVictime(false);
                                setVictime(initVictime);
                                toast("mise à jour");
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                victime.sinistre = sinistre;
                console.log("Victime", victime);
                addPersonne(JSON.stringify(victime.personne))
                    .then((res) => {
                        victime.personne = res.data;
                        ajouterUneVictime(JSON.stringify(victime))
                            .then((res) => {
                                console.log(res.data);
                                setModalVictime(false);
                                setVictime(initVictime);
                                toast("créée");
                            })
                            .catch((err) => {
                                console.log(err);

                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            toast("Vérifier vos entrées");
        }
    };
    const handleVictimeChange = (e) => {
        const {name, value} = e.target;
        let currentVictime = {...victime};
        if (name.split(".").length > 1) {
            // console.log(name, value);
            let targetName = name.split(".")[1];
            currentVictime["personne"][targetName] = value;
            setVictime(currentVictime);
        } else {
            setVictime({...victime, [name]: value});
        }
    };
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, name || `New item ${items.length + 1} `]);
        setName("");
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    useReactToPrint({
        content: () => printReference.current,
    });
    const exportODToPdf = useReactToPrint({
        content: () => printODReference.current,
    });

    const exportFDRToPdf = useReactToPrint({
        content: () => printFDReference.current,
    });

    const handleQPrint = useReactToPrint({
        content: () => printQReference.current,
    });

    const exportQToPdf = () => {
        handleQPrint();
        setTimeout(() => {
            axios
                .put(`${API_URL}/api/decaissement/print`, JSON.stringify(quittance), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    // setModalQuittance(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }, [500]);
    };

    return (<>
        <Card>
            <CardBody>
                <Card>
                    <Card.Grid style={gridStyle}>
                        <Button
                            onClick={() => {
                                setModalReevaluation(true);
                            }}
                        >
                            Reévaluation
                        </Button>
                    </Card.Grid>
                    <Card.Grid style={gridStyle}>
                        <Button
                            onClick={() => {
                                setModalReglementSinistre(true);
                            }}
                        >
                            Décaissement
                        </Button>
                    </Card.Grid>

                    <Card.Grid style={gridStyle}>
                        <Button
                            onClick={() => {
                                setModalVictime(true);
                            }}
                        >
                            Ajouter une victime
                        </Button>
                    </Card.Grid>

                    <Card.Grid style={gridStyle}>
                        <Button
                            onClick={() => {
                                setModalAddExpert(true);
                            }}
                        >
                            Ajouter un expert
                        </Button>
                    </Card.Grid>
                    <Card.Grid style={gridStyle}>
                        <Button
                            onClick={() => {
                                setModalCloture(true);
                            }}
                        >
                            Clôturer le dossier
                        </Button>
                    </Card.Grid>
                    <Card.Grid style={gridStyle}>
                        <Button
                            onClick={() => {
                                setModalSuspension(true);
                            }}
                        >
                            Suspendre le dossier
                        </Button>
                    </Card.Grid>
                </Card>
            </CardBody>
            <Modal
                title={editMode ? "MISE À JOUR RÉEVALUATION" : "REÉVALUATION"}
                centered
                open={modalReevaluation}
                onOk={(e) => handleSubmit(e, false)}
                onCancel={() => {
                    setEvaluation(initEvaluation);
                    setModalReevaluation(false);
                }}
            >
                <Card>
                    <div className="card-body">
                        <div className="grid" style={{
                            justifyContent: "center"
                        }}>
                            <div className={'grid-item'}>
                                <div>
                                    <b>Ancienne provision :</b>{" "}
                                    <Tag color="blue">
                                        <b>
                                            {sinistre?.evaluation ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation) : "0"}{" "}
                                            FCFA
                                        </b>
                                    </Tag>
                                </div>
                                <br/>

                                <div className={'grid'} style={{
                                    justifyContent: "center"
                                }}>
                                    <div className={'grid-item'} lg={12}>
                                        <div
                                            className={'form-control'}
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%'
                                                }}
                                                value={valueSelected}
                                                onChange={(value) => {
                                                    let vs = valueSelected;
                                                    if (value.length > vs.length) {
                                                        addProvisionFormFields();
                                                    } else {
                                                        let outGarantie = value.filter((v) => !garanties.includes(v));
                                                        outGarantie && removeProvisionFormFields(outGarantie[0]);
                                                    }
                                                    const gs = [];
                                                    value.forEach((v) => {
                                                        let g = garanties.find((gar) => gar.value === v);
                                                        g && gs.push(g.garantie);
                                                    });
                                                    setValueSelected(value);
                                                    setSelectedGaranties(gs);
                                                    value.length ? setGarantieState("success") : setGarantieState("error");
                                                }}
                                                options={garanties}
                                                placeholder="Sélectionnez des garanties"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {selectedGaranties.length ? (<div className={'grid'} style={{
                                    justifyContent: "center"
                                }}>
                                    {selectedGaranties.map((sg, index) => (<div

                                        key={index}
                                    >
                                        <label
                                            htmlFor="capital"
                                            style={{
                                                margin: "30px 0 10px 0",
                                            }}
                                        >
                                            Provision Garantie {sg.nom} :
                                        </label>
                                        <Input
                                            id="capital"
                                            name="capital"
                                            placeholder="Veuillez entrer la valeur de la provision"
                                            value={provisionGaranties[index].provision}
                                            onChange={(event) => {
                                                let newProvisionFormFields = [...provisionGaranties,];
                                                newProvisionFormFields[index].garantie = sg;
                                                newProvisionFormFields[index].provision = event.target.value;
                                                setProvisionGarantie(newProvisionFormFields);
                                            }}
                                        />
                                    </div>))}
                                </div>) : null}
                                <br/>
                                <div>
                                    <b>Nouvelle provision :</b>{" "}
                                    <Tag color="green">
                                        <b>
                                            {evaluation?.nouvelleProvision ? new Intl.NumberFormat("en-DE").format(evaluation?.nouvelleProvision) : "0"}{" "}
                                            FCFA
                                        </b>
                                    </Tag>
                                </div>

                                <br/>
                                <div className={'form-control'} style={{
                                    width: '100%'
                                }}>
                                    <Select
                                        id="motifRevision"
                                        style={{
                                            width: '100%'
                                        }}
                                        name="motifRevision"
                                        placeholder="Motif de la révision"
                                        dropdownRender={(menu) => (<>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: "8px 0",
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: "0 8px 4px",
                                                }}
                                            >
                                                <Input
                                                    placeholder="Ajouter un motif"
                                                    ref={inputRef}
                                                    value={name}
                                                    onChange={onNameChange}
                                                />
                                                <Button
                                                    type="text"
                                                    icon={<PlusOutlined/>}
                                                    onClick={addItem}
                                                >
                                                    Ajouter un motif
                                                </Button>
                                            </Space>
                                        </>)}
                                        options={items.map((item) => ({
                                            label: item, value: item,
                                        }))}
                                        onChange={(value) => {
                                            handleChange(value, "motifRevision");
                                            console.log(value);
                                            if (value.trim().length > 3) {
                                                setMotifRevisionState("success");
                                            } else {
                                                setMotifRevisionState("error");
                                            }
                                        }}
                                        value={evaluation.motifRevision}
                                    />
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </Card>
            </Modal>
            <Modal
                title={editMode ? "MISE À JOUR DE LA VICTIME" : "AJOUT DE LA VICTIME"}
                centered
                open={modalVictime}
                onOk={(e) => handleVictimeSubmit(e)}
                onCancel={() => {
                    setEvaluation(initEvaluation);
                    setModalVictime(false);
                }}
            >
                <div className={'grid'} style={{
                    justifyContent: "center"
                }}>
                    <div className="grid-item">

                        <label
                            htmlFor="personne.nom"
                            style={{
                                margin: "30px 0 10px 0", color: nomState === "error" ? "red" : null,
                            }}
                        >
                            Nom de la victime:
                        </label>
                        <Input
                            id="personne.nom"
                            name="personne.nom"
                            placeholder="Nom de la victime"
                            value={victime?.personne?.nom}
                            status={nomState === "error" ? "error" : null}
                            onChange={(event) => {
                                handleVictimeChange(event);
                                if (event.target.value.length > 3) {
                                    setNomState("success");
                                } else {
                                    setNomState("error");
                                }
                            }}
                        />
                    </div>
                    <div className="grid-item">

                        <label
                            htmlFor="personne.prenom"
                            style={{
                                margin: "30px 0 10px 0", color: prenomState === "error" ? "red" : null,
                            }}
                        >
                            Prénoms de la victime:
                        </label>
                        <Input
                            id="personne.prenom"
                            name="personne.prenom"
                            value={victime?.personne?.prenom}
                            placeholder="Prénoms de la victime"
                            status={prenomState === "error" ? "error" : null}
                            onChange={(event) => {
                                handleVictimeChange(event);
                                if (event.target.value.length > 3) {
                                    setPrenomState("success");
                                } else {
                                    setPrenomState("error");
                                }
                            }}
                        />
                    </div>
                    <div className="grid-item">
                        <label
                            htmlFor="personne.profession"
                            style={{
                                margin: "30px 0 10px 0", color: professionState === "error" ? "red" : null,
                            }}
                        >
                            Profession de la victime:
                        </label>
                        <Input
                            id="personne.profession"
                            name="personne.profession"
                            value={victime?.personne?.profession}
                            placeholder="Profession de la victime"
                            status={professionState === "error" ? "error" : null}
                            onChange={(event) => {
                                handleVictimeChange(event);
                                if (event.target.value.length > 3) {
                                    setProfessionState("success");
                                } else {
                                    setProfessionState("error");
                                }
                            }}
                        />
                    </div>
                    <div className="grid-item">

                        <label
                            htmlFor="personne.adresse"
                            style={{
                                margin: "30px 0 10px 0", color: adresseState === "error" ? "red" : null,
                            }}
                        >
                            Adresse de la victime:
                        </label>
                        <Input
                            id="personne.adresse"
                            name="personne.adresse"
                            value={victime?.personne?.adresse}
                            placeholder="Adresse de la victime"
                            status={adresseState === "error" ? "error" : null}
                            onChange={(event) => {
                                handleVictimeChange(event);
                                if (event.target.value.length > 3) {
                                    setAdresseState("success");
                                } else {
                                    setAdresseState("error");
                                }
                            }}
                        />
                    </div>
                    <div className="grid-item">

                        <label
                            htmlFor="descriptionDegatsVictime"
                            style={{
                                margin: "30px 0 10px 0",
                                color: descriptionDegatsVictimeState === "error" ? "red" : null,
                            }}
                        >
                            Description des dégâts sur la victime:
                        </label>
                        <TextArea
                            id="descriptionDegatsVictime"
                            name="descriptionDegatsVictime"
                            value={victime?.descriptionDegatsVictime}
                            placeholder="Description des dommages de la victime"
                            showCount
                            maxLength={300}
                            style={{height: 120, resize: "none"}}
                            onChange={(event) => {
                                handleVictimeChange(event);
                                if (event.target.value.length > 3) {
                                    setDescriptionDegatsVictimeState("success");
                                } else {
                                    setDescriptionDegatsVictimeState("error");
                                }
                            }}
                        />
                        <br/>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </Modal>
            <ReglementSinistre
                open={modalReglementSinistre}
                setOpenReglement={setOpenReglement}
            />
            <AjouterExpert
                open={modalAddExpert}
                setOpen={setOpen}
                edit={editMode}
                expert={expert}
            />
            <Modal
                title={`ORDRE DE DÉPENSE - SINISTRE N° ${sinistre?.numSinistre}`}
                centered
                open={modalOrdreDepense}
                onOk={() => setModalOrdreDepense(false)}
                onCancel={() => {
                    setModalOrdreDepense(false);
                }}
                width={850}
            >
                <div id="myODSinistre" ref={printODReference}>
                    <OrdreDeDepense
                        decaissement={ordreDepense}
                        sinistre={sinistre}
                    />
                </div>
                <AntdButton
                    onClick={() => {
                        exportODToPdf();
                    }}
                    type="primary"
                    style={{
                        background: "blue",
                    }}
                    icon={<FilePdfOutlined/>}
                >
                    {" "}
                    Générer PDF
                    <br/>
                    <br/>
                    <br/>
                </AntdButton>
            </Modal>
            <Modal
                title={`QUITTANCE D'INDEMNITE - SINISTRE N° ${sinistre?.numSinistre}`}
                centered
                open={modalQuittance}
                onOk={() => setModalQuittance(false)}
                onCancel={() => {
                    setModalQuittance(false);
                }}
                width={850}
            >
                <div id="myQSinistre" ref={printQReference}>
                    <QuittanceIndemnite
                        decaissement={quittance}
                        sinistre={sinistre}
                    />
                </div>
                <AntdButton
                    onClick={() => {
                        exportQToPdf();
                    }}
                    type="primary"
                    style={{
                        background: "blue",
                    }}
                    icon={<FilePdfOutlined/>}
                >
                    {" "}
                    Générer PDF
                    <br/>
                    <br/>
                    <br/>
                </AntdButton>
            </Modal>

            <Modal
                title={`FICHE DE DEMANDE DE REGLEMENT - SINISTRE N° ${sinistre?.numSinistre}`}
                centered
                open={modalFicheDemandeReglement}
                onOk={() => setModalFicheDemandeReglement(false)}
                onCancel={() => {
                    setModalFicheDemandeReglement(false);
                }}
                width={850}
            >
                <div id="myFDRSinistre" ref={printFDReference}>
                    <FicheDemandeReglementSinistre
                        decaissement={ficheDemandeReglement}
                        sinistre={sinistre}
                    />
                </div>
                <AntdButton
                    onClick={() => {
                        exportFDRToPdf();
                    }}
                    type="primary"
                    style={{
                        background: "gray",
                    }}
                    icon={<FilePdfOutlined/>}
                >
                    {" "}
                    Générer PDF
                    <br/>
                    <br/>
                    <br/>
                </AntdButton>
            </Modal>
            <Modal
                title="Clôture du dossier sinistre"
                open={modalCloture}
                onOk={() => handleClotureDossier()}
                confirmLoading={loadingCloture}
                onCancel={() => setModalCloture(false)}
            >
                <p>Voulez-vous vraiment clôturer ce dossier sinistre?</p>
            </Modal>
            <Modal
                title="Suspension du dossier sinistre"
                open={modalSuspension}
                onOk={() => handleSuspensionDossier()}
                confirmLoading={loadingSuspension}
                onCancel={() => setModalSuspension(false)}
            >
                <p>
                    Voulez-vous vraiment classer le dossier {"'"}Sans suite
                    {"'"}?
                </p>
            </Modal>
            {decaissement && (<AssignCheque
                open={modalAssignCheque}
                //  setOpen={setOpenModalAssignCheque}
                decaissement={decaissement}
                sinistre={sinistre}
            />)}
        </Card>

    </>)
}

export default Traitements