import {Input, Modal, Select} from "antd";

import React, {useEffect, useState} from "react";

import PropTypes from "prop-types";
import {toast} from "react-toastify";
import axios from "axios";
import {useParams} from "react-router-dom";
import {API_URL, TOKEN} from "../../../variables/constants";

const initExpert = {
    nom: "",
    prenom: "",
    adresse: "",
    fonction: "",
    profession: "",
    email: "",
    telephone: "",
    contact: [],

};

const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornment: {
        position: "relative",
    },
};


const AjouterExpert = (props) => {
    const [modalExpert, setModalExpert] = useState(false);
    const [expert, setExpert] = useState(initExpert);

    const [nomState, setNomState] = useState("");
    const [prenomState, setPrenomState] = useState("");
    const [adresseState, setAdresseState] = useState("");
    const [professionState, setProfessionState] = useState("");
    const [fonctionState, setFonctionState] = useState("");
    const [telephoneState, setTelephoneState] = useState("");

    const param = useParams();

    const isValidated = () => {
        if (
            nomState === "success" &&
            prenomState === "success" &&
            adresseState === "success" &&
            professionState === "success" &&
            fonctionState === "success" &&
            telephoneState === "success"
        ) {
            return true;
        } else {
            if (nomState !== "success") {
                setNomState("error");
            }
            if (prenomState !== "success") {
                setPrenomState("error");
            }
            if (adresseState !== "success") {
                setAdresseState("error");
            }
            if (professionState !== "success") {
                setProfessionState("error");
            }
            if (fonctionState !== "success") {
                setFonctionState("error");
            }
            if (telephoneState !== "success") {
                setTelephoneState("error");
            }
            return false;
        }
    };

    const handleSubmit = () => {
        setModalExpert(false);
        const validated = isValidated();
        if (props.edit) {
            expert.contact[0] = expert.telephone;
            // if (param.key) {
            axios
                .put(
                    API_URL + "/api/sinistre/expert/codeId/" + param.key,
                    JSON.stringify(expert),
                    {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    }
                )
                .then((res) => {
                    console.log(res.data);
                    toast("Expert modifié avec succès");
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    toast("Erreur lors de la modification de l'expert");
                });
            // } else {
            //   axios
            //     .put(API_URL + "/api/personne", JSON.stringify(expert), {
            //       headers: {
            //         Authorization: `Bearer ${TOKEN}`,
            //         "Content-Type": "application/json",
            //         "Access-Control-Allow-Origin": "*",
            //         "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            //         "Access-Control-Allow-Headers":
            //           "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            //       },
            //     })
            //     .then((res) => {
            //       console.log(res.data);
            //       toast("Expert modifié avec succès");
            //       window.location.reload();
            //     })
            //     .catch((error) => {
            //       console.log(error);
            //       toast("Erreur lors de la modification de l'expert");
            //     });
            // }
        } else {
            expert.fonction = "EXPERT";
            expert.contact[0] = expert.telephone;

            // if (param.key) {
            validated &&
            axios
                .post(
                    API_URL + "/api/sinistre/expert/codeId/" + param.key,
                    JSON.stringify(expert),
                    {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                                "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    }
                )
                .then((res) => {
                    console.log(res.data);
                    toast("Expert ajouté avec succès");
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    toast("Erreur lors de l'ajout de l'expert");
                });
            // } else {
            //   validated &&
            //     axios
            //       .post(API_URL + "/api/personne", JSON.stringify(expert), {
            //         headers: {
            //           Authorization: `Bearer ${TOKEN}`,
            //           "Content-Type": "application/json",
            //           "Access-Control-Allow-Origin": "*",
            //           "Access-Control-Allow-Methods":
            //             "GET, PUT, POST, DELETE, OPTIONS",
            //           "Access-Control-Allow-Headers":
            //             "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            //         },
            //       })
            //       .then((res) => {
            //         console.log(res.data);
            //         toast("Expert ajouté avec succès");
            //         window.location.reload();
            //       })
            //       .catch((error) => {
            //         console.log(error);
            //         toast("Erreur lors de l'ajout de l'expert");
            //       });
            // }
        }
    };
    const handleChange = (e, nom) => {
        if (nom) {
            setExpert({...expert, [nom]: e});
        } else {
            const {name, value} = e.target;
            setExpert({...expert, [name]: value});
        }
    };

    useEffect(() => {
        setModalExpert(props.open);
    }, [props.open]);

    useEffect(() => {
        if (props.edit) {
            props.expert.telephone = props.expert?.contact[0];
            setExpert(props.expert);
        }
    }, [props.expert]);

    useEffect(() => {
        if (!modalExpert) {
            props.setOpen(false);
        }
    }, [modalExpert]);

    return (
        <Modal
            title={props.edit ? "Modifier un expert" : "Ajouter un expert"}
            centered
            open={modalExpert}
            onOk={() => handleSubmit()}
            onCancel={() => setModalExpert(false)}
        >
            <div className={' container'} style={{
                justify: "center"
            }}>
                <div className={'grid-item'}>
                    <label
                        htmlFor="nom"
                        style={{
                            margin: "30px 0 10px 0",
                            color: nomState === "error" ? "red" : null,
                        }}
                    >

                        Nom de l{"'"}expert:
                    </label>
                    <Input
                        id="nom"
                        name="nom"
                        placeholder="Nom de l'expert"
                        value={expert?.nom}
                        status={nomState === "error" ? "error" : null}
                        onChange={(event) => {
                            handleChange(event);
                            if (event.target.value.length > 3) {
                                setNomState("success");
                            } else {
                                setNomState("error");
                            }
                        }}
                    />
                </div>
                <div className={'grid-item'} >
                    <label
                        htmlFor="prenom"
                        style={{
                            margin: "30px 0 10px 0",
                            color: prenomState === "error" ? "red" : null,
                        }}
                    >
                        Prénoms de l{"'"}expert:
                    </label>
                    <Input
                        id="prenom"
                        name="prenom"
                        value={expert?.prenom}
                        placeholder="Prénoms de l'expert"
                        status={prenomState === "error" ? "error" : null}
                        onChange={(event) => {
                            handleChange(event);
                            if (event.target.value.length > 3) {
                                setPrenomState("success");
                            } else {
                                setPrenomState("error");
                            }
                        }}
                    />
                </div>
                <div className={'grid-item'} >
                    <label
                        htmlFor="profession"
                        style={{
                            margin: "30px 0 10px 0",
                            color: professionState === "error" ? "red" : null,
                        }}
                    >
                        Profession de l{"'"}expert:
                    </label>
                    <Input
                        id="profession"
                        name="profession"
                        value={expert?.profession}
                        placeholder="Profession de l'expert"
                        status={professionState === "error" ? "error" : null}
                        onChange={(event) => {
                            handleChange(event);
                            if (event.target.value.length > 3) {
                                setProfessionState("success");
                            } else {
                                setProfessionState("error");
                            }
                        }}
                    />
                </div>
                <div className={'grid-item'} >
                    <label
                        htmlFor="adresse"
                        style={{
                            margin: "30px 0 10px 0",
                            color: adresseState === "error" ? "red" : null,
                        }}
                    >
                        Adresse de l{"'"}expert:
                    </label>
                    <Input
                        id="adresse"
                        name="adresse"
                        value={expert?.adresse}
                        placeholder="Adresse de résidence de l'expert"
                        status={adresseState === "error" ? "error" : null}
                        onChange={(event) => {
                            handleChange(event);
                            if (event.target.value.length > 3) {
                                setAdresseState("success");
                            } else {
                                setAdresseState("error");
                            }
                        }}
                    />
                </div>
                <div className={'grid-item'} >
                    <label
                        htmlFor="profession"
                        style={{
                            margin: "30px 0 10px 0",
                            color: fonctionState === "error" ? "red" : null,
                        }}
                    >
                        Type d{"'"}expert:
                    </label>
                    <div fullWidth className={'form-control'}>
                        <Select
                            id="profession"
                            name="profession"
                            placeholder="Sélectionnez le type d'expert"
                            options={["EXPERT MÉDICAL", "EXPERT MÉCANIQUE"].map((item) => ({
                                value: item,
                                label: item,
                            }))}
                            onChange={(value) => {
                                console.log(value);
                                handleChange(value, "profession");
                                value && setFonctionState("success");
                            }}
                        />
                    </div>
                    <br/>
                </div>

                <div className={'grid-item'}>
                    <label
                        htmlFor="telephone"
                        style={{
                            margin: "30px 0 10px 0",
                            color: telephoneState === "error" ? "red" : null,
                        }}
                    >
                        Numéro de téléphone de l{"'"}expert:
                    </label>
                    <Input
                        id="telephone"
                        name="telephone"
                        value={expert?.telephone}
                        type="tel"
                        placeholder="Numéro de téléphone de l'expert"
                        status={telephoneState === "error" ? "error" : null}
                        onChange={(event) => {
                            handleChange(event);
                            if (event.target.value.length > 3) {
                                setTelephoneState("success");
                            } else {
                                setTelephoneState("error");
                            }
                        }}
                    />
                </div>

                <div className={'grid-item'}>
                    <label
                        htmlFor="email"
                        style={{
                            margin: "30px 0 10px 0",
                            color: adresseState === "error" ? "red" : null,
                        }}
                    >
                        Adresse mail de l{"'"}expert:
                    </label>
                    <Input
                        id="email"
                        name="email"
                        value={expert?.email}
                        placeholder="Adresse mail de résidence de l'expert"
                        type="email"
                        onChange={(event) => {
                            handleChange(event);
                        }}
                    />
                </div>
                <br/>
                <br/>
                <br/>

            </div>
        </Modal>
    );
};

AjouterExpert.propTypes = {
    open: PropTypes.bool,
    expert: PropTypes.object,
    edit: PropTypes.bool,
    setOpen: PropTypes.func,
};

export default AjouterExpert;
