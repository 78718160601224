import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";


// ANT.DESIGN
import {ConfigProvider, Input, Modal, Radio, Select, Typography, Upload} from "antd";
import frFR from "antd/locale/fr_FR";
import {PlusOutlined} from "@ant-design/icons";
import {getSinistreById, recupererLesEntitesDeconstat} from "../../../../hooks/useHooks";
import {
    errorStateChecker,
    formaterLesEntites,
    getIdentificateur,
    getvalue,
    setValue
} from "../../../../variables/functions";
import {useParams} from "react-router-dom";

const {Title} = Typography

const {TextArea} = Input;

const initSinistre = {
    lieu: null,
    provenanceAssure: null,
    destinationAssure: null,
    vitesseAssure: null,
    temoignage: "non",
    temoin: {
        nom: null,
        prenom: null,
        adresse: null,
        fonction: null,
    },
    circonstance: null,
    croquis: null,
    observationAgent: null,
    entiteConstat: "",
};

const InformationsSurAccident = forwardRef((props, ref) => {

    const {activeTab, setActiveTab} = props


    useImperativeHandle(ref, () => ({
        isValidated: () => {
            setActiveTab(activeTab + 1);
        },
        sendState: () => {
            return {
                sinistre,
                fileList,
            };
        },
        state: {
            sinistre,
            fileList,
        },
    }));

    const [sinistre, setSinistre] = useState(initSinistre);

    const [entites, setEntites] = useState([]);
    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [entiteConstat, setEntiteConstat] = useState("");
    const [circonstance, setCirconstance] = useState("");

    useEffect(() => {
        recupererLesEntitesDeconstat()
            .then((response) => {
                // console.log(formaterLesEntites(response.data));
                setEntites(formaterLesEntites(response.data));
            })
            .catch((error) => {
                console.log(error);
                setEntites([]);
            });
    }, []);

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const handleChangeFile = ({fileList: newFileList}) =>
        setFileList(newFileList);

    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Sélectionnez un fichier
            </div>
        </div>
    );

    const [informationsAccident, setInformationsAccident] = useState({
        lieu: null,
        heure: null,
        provenanceAssure: null,
        destinationAssure: null,
        vitesseAssure: null,
        temoin: "non",
        nomTemoin: null,
        prenomTemoin: null,
        adresseTemoin: null,
        qualiteTemoin: null,
        circonstance: null,
        croquis: null,
        observationAgent: null,
        entiteConstat: null,
    });

    const [errorState, setErrorState] = useState({
        lieu: null,
        heure: null,
        provenanceAssure: null,
        destinationAssure: null,
        vitesseAssure: null,
        nomTemoin: null,
        prenomTemoin: null,
        adresseTemoin: null,
        qualiteTemoin: null,
        circonstance: null,
        croquis: null,
        observationAgent: null,
        entiteConstat: null,
    });

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            // console.log(position.coords.latitude, position.coords.longitude);
        });
        console.log(latitude, longitude);
    }, []);

    const handleChange = (e, name) => {
        if (e.target) {
            if (e.target.name.split(".").length > 1) {
                const a = e.target.name.split(".")[1];
                setSinistre({
                    ...sinistre.temoin,
                    [a]: e.target.value,
                });
            } else {
                setSinistre({
                    ...sinistre,
                    [e.target.name]: e.target.value,
                });
            }
        } else {
            setSinistre({
                ...sinistre,
                [name]: e,
            });
        }
    };

    const param2 = useParams();

    useEffect(() => {
        if (param2.id) {
            getSinistreById(param2.id)
                .then((res) => {
                    setSinistre(res.data);
                    setEntiteConstat(res.data.entiteConstat);
                    setCirconstance(res.data.circonstance);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [param2.id]);

    return (
        <div className={'grid-container grid container'} style={{
            justifyContent: 'center'
        }}>
            <Title style={{
                fontSize: '26px'
            }}> Informations sur l'accident </Title>
            <div className={'grid-item'}>
                <label
                    htmlFor="lieu"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["lieu"] === true ? "red" : null,
                    }}
                >
                    Lieu de l&apos;accident:
                </label>
                <Input
                    id="lieu"
                    name="lieu"
                    value={sinistre?.lieu}
                    placeholder="Lieu de l'accident"
                    status={errorState["lieu"] === true ? "error" : null}
                    onBlur={() => {
                        errorStateChecker(
                            "lieu",
                            informationsAccident["lieu"],
                            setErrorState,
                            "string"
                        );
                    }}
                    onChange={(event) => {
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setInformationsAccident,
                            setErrorState,
                            "string"
                        );
                        handleChange(event, "lieu");
                    }}
                />
            </div>
            <div className={'grid-item'}>
                <label
                    htmlFor="provenanceAssure"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["provenanceAssure"] === true ? "red" : null,
                    }}
                >
                    L&apos;engin de l&apos;assuré venant de:
                </label>
                <Input
                    id="provenanceAssure"
                    name="provenanceAssure"
                    value={sinistre?.provenanceAssure}
                    placeholder="Lieu de provenance de la voiture de l'assuré"
                    status={errorState["provenanceAssure"] === true ? "error" : null}
                    onBlur={() => {
                        errorStateChecker(
                            "provenanceAssure",
                            informationsAccident["provenanceAssure"],
                            setErrorState,
                            "string"
                        );
                    }}
                    onChange={(event) => {
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setInformationsAccident,
                            setErrorState,
                            "string"
                        );
                        handleChange(event, "provenanceAssure");
                    }}
                />
            </div>
            <div className={'grid-item'}>
                <label
                    htmlFor="destinationAssure"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["destinationAssure"] === true ? "red" : null,
                    }}
                >
                    L&apos;engin de l&apos;assuré se dirigeait vers:
                </label>
                <Input
                    id="destinationAssure"
                    name="destinationAssure"
                    value={sinistre?.destinationAssure}
                    placeholder="Destination de la voiture de l'assuré"
                    status={errorState["destinationAssure"] === true ? "error" : null}
                    onBlur={() => {
                        errorStateChecker(
                            "destinationAssure",
                            informationsAccident["destinationAssure"],
                            setErrorState,
                            "string"
                        );
                    }}
                    onChange={(event) => {
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setInformationsAccident,
                            setErrorState,
                            "string"
                        );
                        handleChange(event, "destinationAssure");
                    }}
                />
            </div>
            <div className={'grid-item'}>
                <label
                    htmlFor="vitesseAssure"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["vitesseAssure"] === true ? "red" : null,
                    }}
                >
                    A quelle vitesse allait l&apos;engin de de l&apos;assuré?:
                </label>
                <Input
                    id="vitesseAssure"
                    name="vitesseAssure"
                    value={sinistre?.vitesseAssure}
                    placeholder="Entrez la vitesse en km/h"
                    type="number"
                    status={errorState["vitesseAssure"] === true ? "error" : null}
                    onBlur={() => {
                        errorStateChecker(
                            "vitesseAssure",
                            informationsAccident["vitesseAssure"],
                            setErrorState,
                            "number"
                        );
                    }}
                    onChange={(event) => {
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setInformationsAccident,
                            setErrorState,
                            "number"
                        );
                        handleChange(event, "vitesseAssure");
                    }}
                />
            </div>
            <div className={'grid-item'}>
                <label htmlFor="temoignage" style={{margin: "30px 0 10px 0"}}>
                    Souhaitez vous enregistrer un témoin du sinistre?:
                </label>
                <Radio.Group
                    id="temoignage"
                    name="temoignage"
                    value={
                        param2.id ? (sinistre.temoin ? "oui" : "non") : sinistre?.temoignage
                    }
                    defaultValue={"non"}
                    onChange={(event) => {
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setInformationsAccident
                        );
                        handleChange(event, "temoignage");
                    }}
                >
                    <Radio value={"non"}>Non</Radio>
                    <Radio value={"oui"}>Oui</Radio>
                </Radio.Group>
            </div>

            {informationsAccident["temoignage"] === "oui" && (
                <>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="temoin.nom"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["temoin.nom"] === true ? "red" : null,
                            }}
                        >
                            Nom du témoin:
                        </label>
                        <Input
                            id="temoin.nom"
                            name="temoin.nom"
                            value={sinistre?.temoin?.nom}
                            placeholder="Nom du témoin"
                            status={errorState["temoin.nom"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "temoin.nom",
                                    informationsAccident["temoin.nom"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setInformationsAccident,
                                    setErrorState,
                                    "string"
                                );
                                handleChange(event, "temoin.nom");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="temoin.prenom"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["temoin.prenom"] === true ? "red" : null,
                            }}
                        >
                            Prénoms du témoin:
                        </label>
                        <Input
                            id="temoin.prenom"
                            name="temoin.prenom"
                            value={sinistre?.temoin?.prenom}
                            placeholder="Prénoms du témoin"
                            status={errorState["temoin.prenom"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "temoin.prenom",
                                    informationsAccident["temoin.prenom"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setInformationsAccident,
                                    setErrorState,
                                    "string"
                                );
                                handleChange(event, "temoin.prenom");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="temoin.adresse"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["temoin.adresse"] === true ? "red" : null,
                            }}
                        >
                            Adresse du témoin:
                        </label>
                        <Input
                            id="temoin.adresse"
                            name="temoin.adresse"
                            value={sinistre?.temoin?.adresse}
                            placeholder="Adresse du témoin"
                            status={errorState["temoin.adresse"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "temoin.adresse",
                                    informationsAccident["temoin.adresse"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setInformationsAccident,
                                    setErrorState,
                                    "string"
                                );
                                handleChange(event, "temoin.adresse");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="temoin.fonction"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["temoin.fonction"] === true ? "red" : null,
                            }}
                        >
                            Qualité du témoin:
                        </label>
                        <Input
                            id="temoin.fonction"
                            name="temoin.fonction"
                            value={sinistre?.temoin?.fonction}
                            placeholder="Qualité du témoin"
                            status={errorState["temoin.fonction"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "temoin.fonction",
                                    informationsAccident["temoin.fonction"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setInformationsAccident,
                                    setErrorState,
                                    "string"
                                );
                                handleChange(event, "temoin.fonction");
                            }}
                        />
                    </div>
                </>
            )}
            {/* FIN DU FORMULAIRE LORSQUE TEMOIN */}
            <div className={'grid-item'}>
                <label
                    htmlFor="entiteConstat"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["entiteConstat"] === true ? "red" : null,
                    }}
                >
                    Sélectionnez l&apos;entité ayant effectué le constat:
                </label>
                <Select
                    name="entiteConstat"
                    id="entiteConstat"
                    placeholder="Cherchez et sélectionnez"
                    options={entites}
                    value={
                        param2.id
                            ? entiteConstat?.entiteConstatId
                            : sinistre?.entiteConstat?.entiteConstatId
                    }
                    showSearch
                    status={errorState["entiteConstat"] === true ? "error" : null}
                    onBlur={() => {
                        errorStateChecker(
                            "entiteConstat",
                            informationsAccident["entiteConstat"],
                            setErrorState,
                            "entiteConstat"
                        );
                    }}
                    onChange={(valeur) => {
                        setValue(
                            valeur,
                            "entiteConstat",
                            setInformationsAccident,
                            setErrorState,
                            "entiteConstat"
                        );
                        // Find entiteConstat by id
                        var entite = entites.find(
                            (ent) => ent.entite.entiteConstatId === valeur
                        );
                        handleChange(entite.entite, "entiteConstat");
                    }}
                    style={{width: "100%"}}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                />
            </div>
            <div className={'grid-item'}>
                <label
                    htmlFor="circonstance"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["circonstance"] === true ? "red" : null,
                    }}
                >
                    Circonstance de l&apos;accident:
                </label>
                <TextArea
                    id="circonstance"
                    name="circonstance"
                    placeholder="Circonstance de l'accident"
                    showCount
                    maxLength={300}
                    status={errorState["circonstance"] === true ? "error" : null}
                    style={{height: 120, resize: "none"}}
                    value={circonstance}
                    onBlur={() => {
                        errorStateChecker(
                            "circonstance",
                            informationsAccident["circonstance"],
                            setErrorState,
                            "string"
                        );
                    }}
                    onChange={(event) => {
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setInformationsAccident,
                            setErrorState,
                            "string"
                        );
                        setCirconstance(event.target.value);
                        handleChange(event, "circonstance");
                    }}
                />
            </div>
            <div className={'grid-item'}>
                <label
                    htmlFor="croquis"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["croquis"] === true ? "red" : null,
                    }}
                >
                    Croquis de l&apos;accident:
                </label>
                <Input
                    id="croquis"
                    type="file"
                    status={errorState["croquis"] === true ? "error" : null}
                    onBlur={() => {
                        errorStateChecker(
                            "croquis",
                            informationsAccident["croquis"],
                            setErrorState,
                            "string"
                        );
                    }}
                    onChange={(event) => {
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setInformationsAccident,
                            setErrorState,
                            "string"
                        );
                    }}
                />
            </div>
            <br/>
            <br/>
            <br/>
            <div className={'grid-item'}>
                <ConfigProvider locale={frFR}>
                    <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChangeFile}
                    >
                        {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                    >
                        <img
                            alt="example"
                            style={{
                                width: "100%",
                            }}
                            src={previewImage}
                        />
                    </Modal>
                </ConfigProvider>
            </div>
            <div className={'grid-item'}>
                <label
                    htmlFor="observationAgent"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["observationAgent"] === true ? "red" : null,
                    }}
                >
                    Observations de l&apos;agent:
                </label>
                <TextArea
                    id="observationAgent"
                    name="observationAgent"
                    placeholder="Observations de l'agent"
                    showCount
                    maxLength={300}
                    value={sinistre?.observationAgent}
                    status={errorState["observationAgent"] === true ? "error" : null}
                    style={{height: 120, resize: "none"}}
                    onBlur={() => {
                        errorStateChecker(
                            "observationAgent",
                            informationsAccident["observationAgent"],
                            setErrorState,
                            "string"
                        );
                    }}
                    onChange={(event) => {
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setInformationsAccident,
                            setErrorState,
                            "string"
                        );
                        handleChange(event, "observationAgent");
                    }}
                />
            </div>
        </div>
    );
});

InformationsSurAccident.displayName = "InformationsSurAccident";

export default InformationsSurAccident;
