import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";

const AutresParametres = () => {
  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"AUTRES_PARAMETRES"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row mb-12 g-6">
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de profession</h5>
                      <p class="card-text">Paramétrer les professions</p>
                      <a
                        href="/autre-parametres/professions"
                        class="btn btn-primary"
                      >
                        Définir une nouvelle profession
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des villes</h5>
                      <p class="card-text">Paramétrer les villes</p>
                      <a
                        href="/autre-parametres/villes"
                        class="btn btn-primary"
                      >
                        Définir une nouvelle villes
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de banques</h5>
                      <p class="card-text">Paramétrer les banques</p>
                      <a
                        href="/autre-parametres/banques"
                        class="btn btn-primary"
                      >
                        Définir une nouvelle banque
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de cause sinistre</h5>
                      <p class="card-text">
                        Paramétrer les différentes causes de sinistre
                        <br />
                      </p>
                      <a
                        href="/autre-parametres/cause-sinistre"
                        class="btn btn-primary"
                      >
                        Définir une nouvelle cause
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de préjudices</h5>
                      <p class="card-text">
                        Paramétrer les différents préjudices
                        <br />
                      </p>
                      <a
                        href="/autre-parametres/prejudices"
                        class="btn btn-primary"
                      >
                        Définir une nouvelle préjudice
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">
                        Paramétrage des natures d'actes
                      </h5>
                      <p class="card-text">
                        Paramétrer les différentes natures d'actes
                        <br />
                      </p>
                      <a
                        href="/autre-parametres/nature-acte"
                        class="btn btn-primary"
                      >
                        Définir une nouvelle nature d'acte
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des types profils</h5>
                      <p class="card-text">
                        Paramétrer les différents types de profils
                        <br />
                      </p>
                      <a
                        href="/autre-parametres/type-profils"
                        class="btn btn-primary"
                      >
                        Définir un type de profil
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des exercices</h5>
                      <p class="card-text">
                        Paramétrer les différents exercices
                        <br />
                      </p>
                      <a
                        href="/autre-parametres/exercices"
                        class="btn btn-primary"
                      >
                        Définir un nouvel exercice
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default AutresParametres;
