import PropTypes from "prop-types";
import {Card, Image} from "antd";


import React from "react";

import entete from "../../../assets/img/entete.jpg";
import entfoot from "../../../assets/img/footer.png";
import {frenchDate, numberToWordsFrench} from "../../../variables/functions";
import {CardBody} from "react-bootstrap";

const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },

    epais: {
        border: "1px solid #063970",
        margin: "0px 0px 0px 0px",
    },

    textLeft: {
        textAlign: "left",
    },

    textRight: {
        textAlign: "right",
    },

    textCenter: {
        textAlign: "center",
    },

    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornment: {
        position: "relative",
    },
};


const OrdreDeDepense = (props) => {

    return (
        <div className = {'container'} justify="center">
            <div className = {'container'}>
                <div className = {'grid-item'}  md={12}>
                    <Image src={entete} />
                    <Card testimonial>
                        <CardBody>
                            <div className = {'grid-item'}  md={12} lg={12}>
                                <hr></hr>
                                <div className = {'container'} justify="center">
                                    <div className = {'grid-item'}
                                    >
                                        <h5>
                                            <b>ORDRE DE DEPENSE</b>
                                        </h5>
                                    </div>
                                </div>
                                <hr ></hr>
                                <br />
                                <br />
                                <div className = {'container'}>
                                    <div className = {'grid-item'}
                                        // align={'right'}
                                        
                                       
                                        md={7}
                                    ></div>
                                    <div className = {'grid-item'}
                                        align={'right'}
                                        
                                       
                                        md={5}
                                    >
                                        <table>
                                            <tr>
                                                <td align={'right'}>
                                                    <h7>
                                                        <b>Catégorie: </b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td align={'right'}>
                                                    <h7>
                                                        {props?.sinistre?.engin?.categorie?.codeCategorie}
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align={'right'}>
                                                    <h7>
                                                        <b>Sinistre N° :</b> &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td align={'right'}>
                                                    <h7>{props?.sinistre?.numSinistre}</h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align={'right'}>
                                                    <h7>
                                                        <b>Survenu le : </b> &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td align={'right'}>
                                                    <h7>{frenchDate(props?.sinistre?.dateSinistre)}</h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align={'right'}>
                                                    <h7>
                                                        <b>Montant (FCFA) :</b> &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td align={'right'}>
                                                    <h7>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(props?.decaissement?.montantGlobal)
                                                        )}
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align={'right'}>
                                                    <h7>
                                                        <b>Retenue: </b> &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td align={'right'}>
                                                    <h7>
                                                        {props?.decaissement?.montantPaye
                                                            ? new Intl.NumberFormat("en-DE").format(
                                                                parseFloat(
                                                                    props?.decaissement?.montantGlobal
                                                                ) -
                                                                parseFloat(props?.decaissement?.montantPaye)
                                                            )
                                                            : "-"}
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <br />
                                <br />
                                {/* <div className = {'container'} > */}
                                <div className = {'container'} justify="left">
                                    <div className = {'grid-item'}  md={2}></div>
                                    <div className = {'grid-item'}  md={8}>
                                        <h7   >
                                            <p>
                                                Veuillez payer à l&apos;ordre de{" "}
                                                <b>
                                                    {props?.sinistre?.contrat?.souscripteur?.prenom
                                                        ? `${props?.sinistre?.contrat?.souscripteur?.nom} ${props?.sinistre?.contrat?.souscripteur?.prenom}`
                                                        : `${props?.sinistre?.contrat?.souscripteur?.nom}`}
                                                </b>
                                            </p>
                                            <p>
                                                La somme de{" "}
                                                <b>
                                                    {numberToWordsFrench(
                                                        props?.decaissement?.montantGlobal
                                                    )}{" "}
                                                    (
                                                    {new Intl.NumberFormat("en-DE").format(
                                                        Math.trunc(props?.decaissement?.montantGlobal)
                                                    )}
                                                    ) Francs CFA
                                                </b>
                                            </p>
                                            <p>
                                                Représentant le remboursement des frais médicaux
                                                justifiés.
                                            </p>
                                        </h7>
                                    </div>
                                </div>
                                <br />

                                <div className = {'container'} justify="right">
                                    <div className = {'grid-item'}  ></div>
                                    <div className = {'grid-item'}  >
                                        <h7>
                                            Fait à lomé le{" "}
                                            {frenchDate(props?.decaissement?.createdAt)}
                                        </h7>{" "}
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className = {'container'} justify="center">
                                    <div className = {'grid-item'}  >
                                        <h7>
                                            <u>Koffi G. WARBUTIN</u> <br />
                                            Directeur
                                        </h7>
                                    </div>
                                    <div className = {'grid-item'}  >
                                        <h7>
                                            <u>Jean-Marie Koffi E. TESSI</u> <br />
                                            Président du conseil d&apos;encadrement
                                        </h7>{" "}
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Image src={entfoot} />
                </div>
            </div>
        </div>
    );
};

OrdreDeDepense.propTypes = {
    decaissement: PropTypes.object,
    sinistre: PropTypes.object,
};

export default OrdreDeDepense;
