import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

// ANT.DESIGN
import {DatePicker, Input, Radio, Select, Spin, Typography} from "antd";
import dayjs from "dayjs";
import {
    errorStateChecker,
    formaterLesContrats,
    getEnginsFromContrat,
    getIdentificateur,
    getvalue,
    setValue
} from "../../../../variables/functions";
import {useParams} from "react-router-dom";
import {getSinistreById, recupererLesContrats} from "../../../../hooks/useHooks";
import {toast} from "react-toastify";

//import {assureValidationSchema} from "./form-validations/schemaValidations";

const {Title} = Typography

const initInfosAssure = {
    contrat: "",
    engin: "",
    dateSinistre: "",
    conduisait: "oui",
    conducteur: {
        nom: "",
        prenom: "",
        numeroPermis: "",
        dateNaissance: "",
        categoriePermis: "B",
        lieuDelivrance: "",
        dateDelivrance: "",
        qualite: "",
    },
    typeSinistre: "",
};


const assureValidationSchema = Yup.object().shape({
    contrat: Yup.object().required("Le champ 'Contrat' est obligatoire."),
    engin: Yup.object().required("Le champ 'Engin' est obligatoire."),
    dateSinistre: Yup.date()
        .required("Le champ 'Date de sinistre' est obligatoire.")
        .typeError("Veuillez entrer une date valide pour 'Date de sinistre'."),
    conduisait: Yup.string()
        .oneOf(["oui", "non"], "Veuillez sélectionner une option valide pour 'Conduisait'.")
        .required("Le champ 'Conduisait' est obligatoire."),
    conducteur: Yup.object().shape({
        nom: Yup.string().required("Le champ 'Nom du conducteur' est obligatoire."),
        prenom: Yup.string().required("Le champ 'Prénom du conducteur' est obligatoire."),
        numeroPermis: Yup.string().required("Le 'Numéro de permis du conducteur' est obligatoire."),
        dateNaissance: Yup.date().required("Veuillez entrer une date valide pour 'Date de naissance'."),
        categoriePermis: Yup.string().required("Le champ 'Catégorie de permis' est obligatoire."),
        lieuDelivrance: Yup.string().required("Le champ 'Lieu de délivrance du permis' est obligatoire."),
        dateDelivrance: Yup.date().required("Veuillez entrer une date valide pour 'Date de délivrance'."),
        /*  qualite: Yup.date().required("Le champ 'Qualité du conducteur' est obligatoire."),*/
    }),
    typeSinistre: Yup.string().required("Le champ 'Type de sinistre' est obligatoire."),
});


const InformationsSurAssure = forwardRef((props, ref) => {
    const [infosAssure, setInfosAssure] = useState(initInfosAssure);
    const [contratLoading, setContratLoading] = useState(false)
    const [selectedContratObj, setSelectedContratObj] = useState({})
    const [wasContractantConducteur, setWasContractantConducteur] = useState(true)

    const param = useParams();
    const {activeTab, setActiveTab} = props
    const initialValues = {
        contrat: "",
        engin: "",
        dateSinistre: "",
        conduisait: "oui",
        wasConducteur: wasContractantConducteur,
        conducteur: {
            nom: "",
            prenom: "",
            numeroPermis: "",
            dateNaissance: "",
            categoriePermis: "B",
            lieuDelivrance: "",
            dateDelivrance: "",
            qualite: "",
        },
        typeSinistre: "",
    };

    useImperativeHandle(ref, () => ({
        isValidated: async () => {
            // Conditionally exclude the 'conducteur' object based on the value of 'wasContractantConducteur'
            const fieldsToValidate = wasContractantConducteur
                ? Object.keys(formik.values).filter(field => !field.startsWith('conducteur')) // Exclude 'conducteur' fields
                : Object.keys(formik.values); // Validate everything, including 'conducteur'

            // Perform validation for the selected fields
            try {
                // Construct a new object with the selected fields and their values
                const valuesToValidate = fieldsToValidate.reduce((acc, field) => {
                    acc[field] = formik.values[field];
                    return acc;
                }, {});
                console.log(valuesToValidate)
                // Trigger validation for the selected fields
                const errors = await formik.validateForm(valuesToValidate);

                // If there are errors, don't proceed with tab change
                if (Object.keys(errors).length > 0) {
                    // Optionally show an error message (e.g., a toast)
                    console.log('Validation failed for fields:', fieldsToValidate);
                    console.log('Errors:', errors);
                    return; // Exit early if validation fails
                }

                // If validation passes, proceed to the next tab
                setActiveTab(activeTab + 1);
            } catch (error) {
                console.error('Validation error:', error);
            }
        },
        sendState: () => {
            return {
                infosAssure,
                contrat: selectedContrat,
                selectedEngin,
            };
        },
        state: {
            infosAssure,
            contrat: selectedContrat,
            selectedEngin,
        },
    }));

    const [informationsAssure, setinformationsAssure] = useState({
        contrat: null,
        engin: null,
        conduisait: "oui",
        "conducteur.nom": null,
        "conducteur.prenom": null,
        "conducteur.dateNaissance": null,
        "conducteur.numeroPermis": null,
        "conducteur.categoriePermis": "B",
        "conducteur.lieuDelivrance": null,
        "conducteur.dateDelivrance": null,
        "conducteur.fonction": null,
        typeSinistre: null,
        dateSinistre: null,
    });


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: assureValidationSchema,

    })


    const handleChange = (e, name) => {
        if (name === "dateSinistre") {
            setInfosAssure({...infosAssure, [name]: e});
        } else {
            if (e.target) {
                const {id, value} = e.target;
                if (e.target.name.split(".").length > 1) {
                    let targetId = e.target.name.split(".")[1];
                    setInfosAssure({
                        ...infosAssure.conducteur,
                        [targetId]: value,
                    });
                } else {
                    setInfosAssure({...infosAssure, [id]: value});
                }
            } else {
                if (name.split(".").length > 1) {
                    let targetId = name.split(".")[1];
                    setInfosAssure({...infosAssure.conducteur, [targetId]: e});
                } else {
                    setInfosAssure({...infosAssure, [name]: e});
                }
            }
        }
    };
    console.log(infosAssure)

    useEffect(() => {
        if (param.id) {
            getSinistreById(param.id)
                .then((res) => {
                    setSelectedContrat(res.data?.contrat?.codeId);
                    setEngins(getEnginsFromContrat(res.data?.contrat));
                    setSelectedEngin(
                        getEnginsFromContrat(res.data?.contrat).length === 1
                            ? getEnginsFromContrat(res.data?.contrat)[0].value
                            : res.data?.engin?.codeId
                    );
                    console.log("infosAssure", res.data);
                    setDateSinistre(dayjs(res.data?.dateSinistre));
                    setTypeSinistre(res.data?.typeSinistre);
                    setInfosAssure(res.data);
                    setInfosAssure({
                        ...infosAssure,
                        ["dateSinistre"]: dayjs(res.data?.dateSinistre),
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [param.id]);

    const [errorState, setErrorState] = useState({
        contrat: null,
        engin: null,
        "conducteur.nom": null,
        "conducteur.prenom": null,
        "conducteur.dateNaissance": null,
        "conducteur.numeroPermis": null,
        "conducteur.lieuDelivrance": null,
        "conducteur.dateDelivrance": null,
        "conducteur.fonction": null,
        typeSinistre: null,
        dateSinistre: null,
    });
    const [contrats, setContrats] = useState([]);
    const [selectedContrat, setSelectedContrat] = useState(null);
    const [engins, setEngins] = useState([]);
    const [valideDate, setValideDate] = useState(false);
    const [selectedEngin, setSelectedEngin] = useState(null);
    const [dateSinistre, setDateSinistre] = useState(dayjs());
    const [typeSinistre, setTypeSinistre] = useState("");

    useEffect(() => {
        setContratLoading(true)
        recupererLesContrats()
            .then((response) => {
                console.log(formaterLesContrats(response.data));
                setContrats(formaterLesContrats(response.data))
                setContratLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setContrats([]);
                setContratLoading(false)

            });
    }, []);
    console.log(contrats)

    const listeDesCategoriesDePermis = [
        {value: "A1", label: "A1"},
        {value: "B", label: "B"},
        {value: "C", label: "C"},
        {value: "D", label: "D"},
    ];

    const listeDesQualitesDuConducteur = [
        {value: "propriétaire", label: "Propriétaire"},
        {value: "locataire", label: "Locataire"},
        {value: "emprunteur", label: "Emprunteur"},
        {value: "salarié", label: "Salarié"},
    ];

    const listeDesTypesDeDommagesPossibles = [
        {value: "MATERIEL", label: "MATÉRIEL"},
        {value: "CORPOREL", label: "CORPOREL"},
        {value: "MIXTE", label: "MIXTE"},
    ];

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current > dayjs().endOf("day");
    };
    console.log(formik.values.contrat)

    const handleFieldErrorToast = (fieldName) => {
        const fieldMeta = formik.getFieldMeta(fieldName);

        if (fieldMeta.touched && fieldMeta.error) {
            toast(fieldMeta.error);
        }
    };
    console.log(formik.values.engin)

    console.log(formik.getFieldMeta('conducteur.nom').touched)
    console.log(formik.errors['conducteur.nom'])
    console.log(formik.errors)
    console.log(wasContractantConducteur)
    return (
        <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
            <Title style={{
                fontSize: '26px'
            }}> Informations sur l'assuré </Title>
            <div className={'grid-item'}>
                <label
                    htmlFor="contrat"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["contrat"] === true ? "red" : null,
                    }}
                >
                    Sélectionnez le contrat l&apos;assuré sinistré:
                </label>
                <Spin spinning={contratLoading}>
                    <Select
                        name="contrat"
                        placeholder="Cherchez et sélectionnez"
                        options={contrats}
                        value={selectedContrat}
                        showSearch
                        status={formik.errors.contrat && formik.touched.contrat ? "error" : null}
                        onBlur={(e) => {
                            formik.handleBlur(e); // Ensure Formik handles the blur event
                            handleFieldErrorToast("contrat"); // Show error toast if needed
                        }}
                        onChange={(valeur, obj) => {
                            // Update Formik field value
                            formik.setFieldValue("contrat", obj.contrat);

                            // Update state with selected contrat and related engins
                            setSelectedContratObj(obj.contrat);
                            setSelectedContrat(valeur);

                            // Perform additional updates
                            const contrat = contrats.find((ctrt) => ctrt.contrat.codeId === valeur);
                            setEngins(getEnginsFromContrat(obj.contrat));
                            handleChange(obj.contrat, "contrat");

                            // Update information state
                            setValue(valeur, "contrat", setinformationsAssure, setErrorState, "string");
                        }}
                        style={{width: "100%"}}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? "").includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                    />
                </Spin>

            </div>
            {selectedContrat ? (
                <>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="engin"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["engin"] === true ? "red" : null,
                            }}
                        >
                            Sélectionnez l&apos;engin concerné par le sinistre:
                        </label>
                        <Select
                            name="engin"
                            id="engin"
                            placeholder="Cherchez et sélectionnez"
                            options={engins}
                            value={formik.values.engin.immatriculation}
                            showSearch
                            status={errorState["engin"] === true ? "error" : null}
                            onBlur={(e) => {
                                formik.handleBlur(e)
                                handleFieldErrorToast('engin')
                                errorStateChecker(
                                    "engin",
                                    informationsAssure["engin"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(valeur, obj) => {
                                formik.setFieldValue("engin", obj.engin);
                                setValue(
                                    valeur,
                                    "engin",
                                    setinformationsAssure,
                                    setErrorState,
                                    "string"
                                );
                                setSelectedEngin(valeur);
                                // Find engin by codeId
                                const engin = engins.find((eng) => eng.engin.codeId === valeur);
                                handleChange(engin.engin, "engin");
                            }}
                            style={{width: "100%"}}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare((optionB?.label ?? "").toLowerCase())
                            }
                        />
                    </div>

                    <div className={'grid-item'}>
                        <label
                            htmlFor="dateSinistre"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["dateSinistre"] === true ? "red" : null,
                            }}
                        >
                            Date et heure de l&apos;accident:
                        </label>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={disabledDate}
                            value={dateSinistre}
                            showTime={{
                                defaultValue: dayjs("00:00:00", "HH:mm"),
                            }}
                            placeholder="Selectionnez la date et l'heure de survenance du sinistre"
                            onChange={(event) => {
                                const date1 = new Date(
                                    `${event?.year()}-${
                                        event?.month() + 1
                                    }-${event?.date()} ${event?.hour()}:${event?.minute()}:${event?.second()}`
                                );
                                const date2 = new Date(selectedContrat.dateEcheance?.split("T")[0]);

                                const timeDiff = Math.sign(date1.getTime() - date2.getTime());

                                if (timeDiff === 1) {
                                    toast("Le sinistre survenu doit être avant la date d'échéance du contrat");
                                    setValideDate(false);
                                } else {
                                    setValideDate(true);
                                }

                                // Format the date correctly (using ISO 8601 format)
                                const formattedDate = `${event?.year()}-${
                                        (event?.month() + 1).toString().padStart(2, '0')
                                    }-${event?.date().toString().padStart(2, '0')}` +
                                    `T${event?.hour().toString().padStart(2, '0')}:${event?.minute().toString().padStart(2, '0')}:${event?.second().toString().padStart(2, '0')}`;

                                // Save the selected date to Formik using setFieldValue
                                formik.setFieldValue("dateSinistre", formattedDate);
                            }}
                        />

                    </div>
                </>
            ) : null}

            <div className={'grid-item'}>
                <label htmlFor="conduisait" style={{margin: "30px 0 10px 0"}}>
                    L&apos;assuré conduisait-il?:
                </label>
                <Radio.Group
                    name="conduisait"
                    id="conduisait"
                    defaultValue={"oui"}
                    onChange={(event) => {
                        formik.setFieldValue('wasConducteur', event.target.value === 'oui');
                        setWasContractantConducteur(event.target.value === 'oui');
                        setValue(
                            getvalue(event),
                            getIdentificateur(event),
                            setinformationsAssure
                        );
                        handleChange(event, "conduisait");
                    }}
                >
                    <Radio value={"oui"}>Oui</Radio>
                    <Radio value={"non"}>Non</Radio>
                </Radio.Group>
            </div>
            {/* FORMULAIRE LORSQUE LE CONDUCTEUR EST DIFFERENT DE L'ASSURE */}
            {informationsAssure["conduisait"] == "non" && (
                <>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="conducteur.nom"
                            style={{
                                margin: "30px 0 10px 0",
                                color: formik.errors.conducteur?.nom && formik.touched.conducteur?.nom ? "red" : null,
                            }}
                        >
                            Nom du conducteur:
                        </label>
                        <Input
                            id="conducteur.nom"
                            placeholder="Nom du conducteur"
                            status={formik.errors.conducteur?.nom && formik.touched.conducteur?.nom ? "error" : null}
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                handleFieldErrorToast('conducteur.nom'); // Toast on blur
                                errorStateChecker(
                                    "conducteur.nom",
                                    informationsAssure["conducteur.nom"],
                                    setErrorState,
                                    "string"
                                ); // Check error state (if this is custom logic)
                            }}
                            name="conducteur.nom"
                            value={formik.values.conducteur.nom}
                            onChange={(event) => {
                                formik.handleChange(event);
                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setinformationsAssure,
                                    setErrorState,
                                    "string"
                                ); // Custom value and error state setter
                                handleChange(event, "conducteur.nom"); // Handle additional logic here
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="conducteur.prenom"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["conducteur.prenom"] === true ? "red" : null,
                            }}
                        >
                            Prénoms du conducteur:
                        </label>
                        <Input
                            id="conducteur.prenom"
                            placeholder="Prénoms du conducteur"
                            status={errorState["conducteur.prenom"] === true ? "error" : null}
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                handleFieldErrorToast('conducteur.prenom');
                                errorStateChecker(
                                    "conducteur.prenom",
                                    informationsAssure["conducteur.prenom"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            value={infosAssure?.conducteur?.prenom}
                            onChange={(event) => {
                                formik.handleChange(event);

                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setinformationsAssure,
                                    setErrorState,
                                    "string"
                                );

                                handleChange(event, "conducteur.prenom");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="conducteur.dateNaissance"
                            style={{
                                margin: "30px 0 10px 0",
                                color:
                                    errorState["conducteur.dateNaissance"] === true
                                        ? "red"
                                        : null,
                            }}
                        >
                            Date de naissance du conducteur:
                        </label>
                        <Input
                            id="conducteur.dateNaissance"
                            name="conducteur.dateNaissance"
                            type="date"
                            value={infosAssure?.conducteur?.dateNaissance}
                            status={
                                errorState["conducteur.dateNaissance"] === true ? "error" : null
                            }
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                handleFieldErrorToast('conducteur.dateNaissance');
                                errorStateChecker(
                                    "conducteur.dateNaissance",
                                    informationsAssure["conducteur.dateNaissance"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                formik.handleChange(event);

                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setinformationsAssure,
                                    setErrorState,
                                    "string"
                                );
                                handleChange(event, "conducteur.dateNaissance");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="conducteur.numeroPermis"
                            style={{
                                margin: "30px 0 10px 0",
                                color:
                                    errorState["conducteur.numeroPermis"] === true ? "red" : null,
                            }}
                        >
                            Numéro du permis du conducteur:
                        </label>
                        <Input
                            id="conducteur.numeroPermis"
                            name="conducteur.numeroPermis"
                            type="number"
                            value={infosAssure?.conducteur?.numeroPermis}
                            placeholder="Numéro du permis du conducteur"
                            status={
                                errorState["conducteur.numeroPermis"] === true ? "error" : null
                            }
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                handleFieldErrorToast('conducteur.numeroPermis');
                                errorStateChecker(
                                    "conducteur.numeroPermis",
                                    informationsAssure["conducteur.numeroPermis"],
                                    setErrorState,
                                    "number"
                                );
                            }}
                            onChange={(event) => {
                                formik.handleChange(event);

                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setinformationsAssure,
                                    setErrorState,
                                    "number"
                                );

                                handleChange(event, "numeroPermis");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="conducteur.categoriePermis"
                            style={{margin: "30px 0 10px 0"}}
                        >
                            Catégorie du permis du conducteur:
                        </label>
                        <Select
                            name="conducteur.categoriePermis"
                            id="conducteur.categoriePermis"
                            defaultValue="B"
                            options={listeDesCategoriesDePermis}
                            style={{width: "100%"}}
                            value={infosAssure?.conducteur?.categoriePermis}
                            onChange={(valeur) => {
                                setValue(
                                    valeur,
                                    "conducteur.categoriePermis",
                                    setinformationsAssure
                                );

                                handleChange(valeur, "conducteur.categoriePermis");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="conducteur.lieuDelivrance"
                            style={{
                                margin: "30px 0 10px 0",
                                color:
                                    errorState["conducteur.lieuDelivrance"] === true
                                        ? "red"
                                        : null,
                            }}
                        >
                            Lieu de délivrance du permis du conducteur:
                        </label>
                        <Input
                            id="conducteur.lieuDelivrance"
                            name="conducteur.lieuDelivrance"
                            placeholder="Lieu de délivrance du permis du conducteur"
                            status={
                                errorState["conducteur.lieuDelivrance"] === true
                                    ? "error"
                                    : null
                            }
                            value={infosAssure?.conducteur?.lieuDelivrance}
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                handleFieldErrorToast('conducteur.lieuDelivrance');
                                errorStateChecker(
                                    "conducteur.lieuDelivrance",
                                    informationsAssure["conducteur.lieuDelivrance"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                formik.handleChange(event);

                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setinformationsAssure,
                                    setErrorState,
                                    "string"
                                );
                                handleChange(event, "conducteur.lieuDelivrance");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="conducteur.dateDelivrance"
                            style={{
                                margin: "30px 0 10px 0",
                                color:
                                    errorState["conducteur.dateDelivrance"] === true
                                        ? "red"
                                        : null,
                            }}
                        >
                            Date de délivrance du permis du conducteur:
                        </label>
                        <Input
                            id="conducteur.dateDelivrance"
                            name="conducteur.dateDelivrance"
                            type="date"
                            value={infosAssure?.conducteur?.dateDelivrance}
                            status={
                                errorState["conducteur.dateDelivrance"] === true
                                    ? "error"
                                    : null
                            }
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                handleFieldErrorToast('conducteur.dateDelivrance');
                                errorStateChecker(
                                    "conducteur.dateDelivrance",
                                    informationsAssure["conducteur.dateDelivrance"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                formik.handleChange(event);

                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setinformationsAssure,
                                    setErrorState,
                                    "string"
                                );

                                handleChange(event, "conducteur.dateDelivrance");
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="conducteur.qualite"
                            style={{
                                margin: "30px 0 10px 0",
                                color:
                                    errorState["conducteur.fonction"] === true ? "red" : null,
                            }}
                        >
                            Qualité du conducteur:
                        </label>
                        <Select
                            name="conducteur.qualite"
                            options={listeDesQualitesDuConducteur}
                            style={{width: "100%"}}
                            placeholder="Sélectionnez"
                            status={
                                errorState["conducteur.fonction"] === true ? "error" : null
                            }
                            onBlur={(e) => {
                                formik.handleBlur(e)
                                handleFieldErrorToast('conducteur.qualite')
                                errorStateChecker(
                                    "conducteur.fonction",
                                    informationsAssure["conducteur.fonction"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(valeur) => {

                                formik.setFieldValue('conducteur.qualite', valeur)
                                setValue(
                                    valeur,
                                    "conducteur.fonction",
                                    setinformationsAssure,
                                    setErrorState,
                                    "string"
                                );

                                handleChange(valeur, "conducteur.qualite");
                            }}
                        />
                    </div>
                </>
            )}
            {/* FIN DU FORMULAIRE LORSQUE LE CONDUCTEUR EST DIFFERENT DE L'ASSURE */}
            <div className={'grid-item'}>
                <label
                    htmlFor="typeSinistre"
                    style={{
                        margin: "30px 0 10px 0",
                        color: errorState["typeSinistre"] === true ? "red" : null,
                    }}
                >
                    Type de dommages chez l&apos;assuré:
                </label>
                <Select
                    name="typeSinistre"
                    id="typeSinistre"
                    placeholder="Sélectionnez"
                    options={listeDesTypesDeDommagesPossibles}
                    value={typeSinistre}
                    style={{width: "100%"}}
                    status={errorState["typeSinistre"] === true ? "error" : null}
                    onBlur={(e) => {
                        formik.handleBlur(e)
                        handleFieldErrorToast('typeSinistre')
                        errorStateChecker(
                            "typeSinistre",
                            informationsAssure["typeSinistre"],
                            setErrorState,
                            "string"
                        );
                    }}
                    onChange={(valeur) => {
                        //  formik.handleChange
                        formik.setFieldValue('typeSinistre', valeur)
                        setValue(
                            valeur,
                            "typeSinistre",
                            setinformationsAssure,
                            setErrorState,
                            "string"
                        );
                        setTypeSinistre(valeur);
                        handleChange(valeur, "typeSinistre");
                    }}
                />
            </div>
        </div>
    );
});

InformationsSurAssure.displayName = "InformationsSurAssure";

export default InformationsSurAssure;
