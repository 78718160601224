import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Menu, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { rightsService } from "../../services/rightsService";
import { API_URL, TOKEN } from "../../variables/constants";

const Profils = () => {
  const [profils, setProfils] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_URL}/api/profil`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log(res.data);
        setProfils(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Intitulé",
      dataIndex: "nom",
      key: "nom",
    },
    {
      title: "Organisme",
      dataIndex: "organisme",
      key: "organisme",
      render: (org) => <Badge color="blue" text={org?.raisonSociale} />,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              {rightsService("HAC056") && <Menu.Item key="1">
                <a href={`/modifier-profil/${record.codeId}`}></a> Modifier
              </Menu.Item>}
              {rightsService("HAC058") && <Menu.Item key="2">Supprimer</Menu.Item>}
            </Menu>
          }
        >
          <Button icon={<DownOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"PROFILS"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
              {rightsService("HAC057") && <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => {
                  navigate("/ajouter-profil");
                }}
              >
                Créer un nouveau profil
              </Button>}
              <div class="card">
                <Table
                  columns={columns}
                  dataSource={profils}
                  onChange={handleChange}
                  rowKey="codeId"
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.commentaire}</p>
                    ),
                  }}
                  pagination={{ pageSize: 10 }}
                />
              </div>
              <Footer />
            </div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>
      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default Profils;
