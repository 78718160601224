import { LOGGED_USER } from "../variables/constants";

export const rightsService = (codeHabilitation) => {
    var d = false;
    let allHabs = LOGGED_USER.profil.habilitations;
    if (LOGGED_USER?.derogation !== null && LOGGED_USER?.derogation?.length && LOGGED_USER?.dateFinDerogation) {
        const derogationDate = new Date(LOGGED_USER?.dateFinDerogation);
        const today = new Date();
        if (derogationDate > today) {
            LOGGED_USER.derogation.forEach((d) => {
                allHabs.push(d);
            });
        }
    }

    allHabs?.forEach(hab => {
        if (hab.codeHabilitation === codeHabilitation) {
            d = true;
            return d;
        }
    });
    return d;
}