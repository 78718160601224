import {Card, Image} from "antd";
import React, {useEffect, useState} from "react";

import PropTypes from "prop-types";
import entete from "../../../assets/img/entete.jpg";
import entfoot from "../../../assets/img/footer.png";
import {CardBody} from "react-bootstrap";
import {frenchDate} from "../../../variables/functions";


const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },

    epais: {
        border: "1px solid #063970",
        margin: "0px 0px 0px 0px",
    },

    textLeft: {
        textAlign: "left",
    },

    tabTextStyleLeft: {
        border: "1px solid #063970",
        textAlign: "left",
    },

    tabTextStyleRight: {
        border: "1px solid #063970",
        textAlign: "right",
    },

    textRight: {
        textAlign: "right",
    },

    textCenter: {
        textAlign: "center",
    },

    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornment: {
        position: "relative",
    },
};


const FicheDemandeReglementSinistre = (props) => {
    const [cumulPaye, setCumulPaye] = useState(0);
    useEffect(() => {
        if (props?.sinistre?.decaissements) {
            let cumul = 0.0;
            props?.sinistre?.decaissements.map((decaissement) => {
                cumul += parseFloat(decaissement.montantGlobal);
            });
            setCumulPaye(cumul);
        }
    }, [props?.decaissement?.sinistre?.decaissements]);
    return (
        <div className={'container'} style={{justifyContent: 'center'}}>
            <div className={'container'}>>
                <div className={'grid-item'}>
                    <Image src={entete}/>
                    <Card testimonial>
                        <CardBody>
                            <div className={'grid-item'}>
                                <hr className={styles.epais}></hr>
                                <div className={'container'} style={{justifyContent: 'center'}}>
                                    <div className={'grid-item'}
                                    >
                                        <h5>
                                            <b>FICHE DE DEMANDE DE REGLEMENT SINISTRE</b>
                                        </h5>
                                    </div>
                                </div>
                                <hr className={styles.epais}></hr>
                                <br/>
                                <br/>
                                <div className={'container'} style={{justifyContent: 'center'}}>
                                    <table>
                                        <tr>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Sinistre N°</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>{props?.sinistre?.numSinistre}</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Police N°</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>
                                                        {props?.sinistre?.contrat?.numeroPoliceM
                                                            ? props?.sinistre?.contrat?.numeroPoliceM
                                                            : props?.sinistre?.contrat?.numeroPoliceP}
                                                    </b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Date de survenance</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>{frenchDate(props?.sinistre?.dateSinistre)}</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Effet</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>
                                                        {frenchDate(props?.sinistre?.contrat?.dateEffet)}
                                                    </b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Date de Déclaration</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>{frenchDate(props?.sinistre?.createdAt)}</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Echéance</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>
                                                        {frenchDate(props?.sinistre?.contrat?.dateEcheance)}
                                                    </b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Catégorie</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>{props?.sinistre?.engin?.categorie?.nom}</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Assuré</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>
                                                        {props?.sinistre?.contrat?.souscripteur?.prenom
                                                            ? `${props?.sinistre?.contrat?.souscripteur?.nom} ${props?.sinistre?.contrat?.souscripteur?.prenom}`
                                                            : `${props?.sinistre?.contrat?.souscripteur?.nom}`}
                                                    </b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br/>
                                <div className={'container'} style={{justifyContent: 'center'}}>
                                    <div className={'grid-item'}
                                    >
                                        <h5>
                                            <b>
                                                <u>SITUATION FINANCIERE DU DOSSIER</u>
                                            </b>
                                        </h5>
                                    </div>
                                </div>
                                <br/>
                                <div className={'container'} style={{justifyContent: 'center'}}>
                                    <table>
                                        <tr>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Coût total prévisible</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(props?.sinistre?.evaluation)
                                                        )}
                                                    </b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Prévision de recours</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>0</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Cumul des paiements</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(cumulPaye)
                                                        )}
                                                    </b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>&nbsp;</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>&nbsp;</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>SAP</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(
                                                                props?.sinistre?.evaluation - cumulPaye
                                                            )
                                                        )}
                                                    </b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleLeft}>
                                                <h7>
                                                    <b>Sort du dossier</b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                            <td className={styles.tabTextStyleRight}>
                                                <h7>
                                                    <b>
                                                        {props?.decaissement?.sinistre?.sortDossier === "I"
                                                            ? "EN INSTANCE"
                                                            : props?.decaissement?.sinistre?.sortDossier ===
                                                            "T"
                                                                ? "TERMINE"
                                                                : props?.decaissement?.sinistre?.sortDossier ===
                                                                "S"
                                                                    ? "SANS SUITE"
                                                                    : "EN INSTANCE"}
                                                    </b>
                                                    &nbsp; &nbsp;
                                                </h7>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br/>
                                <div className={'container'} style={{justifyContent: 'center'}}>
                                    <div className={'grid-item'}
                                        // md={12}
                                    >
                                        <h5>
                                            <b>
                                                <u>
                                                    {" "}
                                                    DEMANDE DE REGLEMENT DU{" "}
                                                    {frenchDate(props?.decaissement?.dateReglement)}
                                                </u>
                                            </b>
                                        </h5>
                                    </div>
                                </div>
                                {/* <div className={'container'}  > > */}
                                <div className={'container'}> justify="left">
                                    <div className={'grid-item'}></div>
                                    <div className={'grid-item'}></div>
                                </div>
                                {/* <br /> */}
                                <div className={'container'}>>
                                    <div className={'grid-item'} md={3}></div>

                                    <div className={'grid-item'} md={8}>
                                        <table>
                                            <tr>
                                                <td className={styles.textLeft}>
                                                    <h7>Bénéficiaire :</h7>
                                                </td>
                                                <td className={styles.textLeft}>
                                                    <h7>
                                                        {props?.decaissement?.beneficiaire?.prenom
                                                            ? `${props?.decaissement?.beneficiaire?.nom} ${props?.decaissement?.beneficiaire?.prenom}`
                                                            : `${props?.decaissement?.beneficiaire?.prenom}`}
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.textLeft}>
                                                    <h7>Nature :</h7>
                                                </td>
                                                <td className={styles.textLeft}>
                                                    <h7>
                                                        {props?.decaissement?.sinistre?.typeSinistre}{" "}
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.textLeft}>
                                                    <h7>Motif :</h7>
                                                </td>
                                                <td className={styles.textLeft}>
                                                    <h7>Le remboursement des frais médicaux justifiés</h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.textLeft}>
                                                    <h7>Montant :</h7>
                                                </td>
                                                <td className={styles.textLeft}>
                                                    <h7>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(props?.decaissement?.montantGlobal)
                                                        )}{" "}
                                                        FCFA
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.textLeft}>
                                                    <h7>Après une retenue de :</h7>
                                                </td>
                                                <td className={styles.textLeft}>
                                                    <h7>
                                                        {props?.decaissement?.montantPaye
                                                            ? new Intl.NumberFormat("en-DE").format(
                                                                Math.trunc(
                                                                    props?.decaissement?.montantGlobal -
                                                                    props?.decaissement?.montantPaye
                                                                )
                                                            )
                                                            : "0"}{" "}
                                                        FCFA
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className={'grid-item'} md={1}></div>
                                </div>

                                <br/>
                                <div className={'container'} style={{justifyContent: 'center'}}>
                                    <div className={'grid-item'}
                                        // md={12}
                                    >
                                        <h5>
                                            <b>
                                                <u> Visa et Autorisation</u>
                                            </b>
                                        </h5>
                                    </div>
                                </div>
                                <br/>

                                <div className={'container'} style={{justifyContent: 'center'}}>
                                    <div className={'grid-item'} md={4}>
                                        <h7>
                                            <u>Rédacteur</u>
                                        </h7>
                                    </div>
                                    <div className={'grid-item'} md={4}>
                                        <h7>
                                            <u>D.O</u>
                                        </h7>
                                    </div>
                                    <div className={'grid-item'} md={4}>
                                        <h7>
                                            <u>Directeur du Pool</u>
                                        </h7>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Image src={entfoot}/>
                </div>
            </div>
        </div>
    );
};

FicheDemandeReglementSinistre.propTypes = {
    decaissement: PropTypes.object,
    sinistre: PropTypes.object,
};

export default FicheDemandeReglementSinistre;
