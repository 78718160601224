import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL, LOGGED_USER, TOKEN } from "../../variables/constants";
import { Modal } from "react-bootstrap";
import { Switch } from "antd";

const AddGarantie = ({ show, handleClose, editMode = false, codeId, selectedGarantie }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [obligatoire, setObligatoire] = useState(false);
  const [organisme, setOrganisme] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);

  // Fetch garantie data when in edit mode
  useEffect(() => {
    if (show) {
      console.log("garanteee", selectedGarantie);
      const data = selectedGarantie;
      setNom(data.nom);
      setCommentaire(data.commentaire);
      setObligatoire(data.obligatoire);
      setSelectedCategorie(data.categories ? categories.toString() : "");

    }
    if (editMode && selectedGarantie?.codeId) {
      setIsDataLoading(true);
      axios
        .get(`${API_URL}/api/garantie/codeId/${selectedGarantie.codeId}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const { nom, commentaire, obligatoire, categorieId } = res.data;
          setNom(nom);
          setCommentaire(commentaire);
          setObligatoire(obligatoire);
          setSelectedCategorie(categorieId ? categorieId.toString() : "");
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Erreur lors de la récupération des données de la garantie");
          setIsDataLoading(false);
        });
    }
  }, [editMode, selectedGarantie]);

  // Fetch categories
  useEffect(() => {
    axios
      .get(`${API_URL}/api/categorie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors de la récupération des catégories");
      });
  }, [TOKEN]);

  // Handle form submission
  const onSubmit = (e) => {
    e.preventDefault();
    if (!nom || !selectedCategorie) {
      toast.error("Veuillez remplir tous les champs requis.");
      return;
    }
    setLoading(true);

    const cat = categories.find(
      (e) => e.categorieId === parseInt(selectedCategorie)
    );
    
    const gar = selectedGarantie;
    gar.nom = nom;
    gar.commentaire = commentaire;
    gar.categorie = cat;
    // gar.organisme = organisme
    gar.obligatoire = obligatoire;




    // Log the data being sent before the request
    console.log("Data to be sent:", gar);
    console.log("Edit mode:", editMode);

    const apiMethod = editMode ? axios.put : axios.post;
    const apiUrl = `${API_URL}/api/garantie`;

    apiMethod(apiUrl, JSON.stringify(gar), {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("Response from API:", res.data); // Log the response from the API
        setLoading(false);
        toast(editMode ? "Garantie modifiée avec succès" : "Garantie ajoutée avec succès");
        handleClose();
      })
      .catch((err) => {
        console.error("Error during submission:", err);
        toast.error(
          editMode ? "Erreur lors de la modification de la garantie" : "Erreur lors de l'ajout de la garantie"
        );
        setLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la" : "Ajouter une"} garantie
          </h4>
          <p className="add-subtitle">
            {editMode ? "Modifier la" : "Ajouter une nouvelle"} garantie à une catégorie
          </p>
        </div>
        <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalAddressFirstName">
              Nom
            </label>
            <input
              type="text"
              id="modalAddressFirstName"
              onChange={(e) => setNom(e.target.value)}
              className="form-control"
              placeholder="Nom de la garantie"
              name="nom"
              value={nom}
              disabled={isDataLoading}
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="modalAddressCountry">
              Catégorie
            </label>
            <select
              id="modalEditUserLanguage"
              name="modalEditUserLanguage"
              className="select2 form-select"
              onChange={(e) => setSelectedCategorie(e.target.value)}
              value={selectedCategorie}
              disabled={isDataLoading}
            >
              <option value="">Sélectionner la catégorie</option>
              {categories.map((cat) => (
                <option key={cat.categorieId} value={cat.categorieId}>
                  {cat.nom}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalAddressFirstName">
              Description
            </label>
            <textarea
              type="text"
              id="modalAddressFirstName"
              onChange={(e) => setCommentaire(e.target.value)}
              className="form-control"
              placeholder="Description de la garantie"
              name="description"
              value={commentaire}
              disabled={isDataLoading}
            ></textarea>
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="obligatoire">
              Garantie obligatoire ?
            </label>
            <br />
            <Switch
              checkedChildren="OUI"
              unCheckedChildren="NON"
              onChange={(checked) => setObligatoire(checked)}
              checked={obligatoire}
              disabled={isDataLoading}
            />
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading || isDataLoading}
            >
              {loading && (
                <span
                  className="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? `Enregistrement...` : `Enregistrer`}
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={handleClose}
              disabled={isDataLoading}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddGarantie;
