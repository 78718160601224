import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddCategorie from "./AddCategorie";

const { confirm } = Modal;

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState(null);

  // Charger la liste des catégories
  useEffect(() => {
    axios
      .get(`${API_URL}/api/categorie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors du chargement des catégories");
      });
  }, [showModal]);

  // Confirmation de suppression
  const showDeleteConfirm = (categorieId) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette catégorie?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteCategorie(categorieId);
      },
    });
  };

  // Suppression d'une catégorie
  const deleteCategorie = (categorieId) => {
    axios
      .delete(`${API_URL}/api/categorie/${categorieId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setCategories(categories.filter((cat) => cat.categorieId !== categorieId));
        toast.success("Catégorie supprimée avec succès");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de la suppression");
      });
  };

  // Ouvrir le modal pour ajouter ou modifier une catégorie
  const handleShow = (edit, categorieId = null) => {
    setEditMode(edit);
    if (edit) {
      const categorie = categories.find((cat) => cat.categorieId === categorieId);
      setSelectedCategorie(categorie); // Envoyer l'objet complet de la catégorie
    } else {
      setSelectedCategorie(null); // Réinitialiser pour un ajout
    }
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
    },
    {
      title: "Code",
      dataIndex: "codeCategorie",
      key: "codeCategorie",
    },
    {
      title: "Description",
      dataIndex: "commentaire",
      key: "commentaire",
    },
    {
      title: "Valeur vénale min",
      dataIndex: "valeurVenaleMinimale",
      key: "valeurVenaleMinimale",
      render: (valeur) => new Intl.NumberFormat("en-DE").format(valeur),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-icon rounded-pill"
            onClick={() => handleShow(true, record.categorieId)}  // Modifier la catégorie
          >
            <i className="bx bx-edit-alt"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon rounded-pill"
            onClick={() => showDeleteConfirm(record.categorieId)}  // Supprimer la catégorie
          >
            <i className="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false)}  // Ajouter une nouvelle catégorie
              >
                Ajouter une nouvelle catégorie
              </Button>
              <div className="card">
                <Table
                  columns={columns}
                  dataSource={categories}
                  rowKey="categorieId"
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {/* Modal pour ajouter ou modifier une catégorie */}
      <AddCategorie
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedCategorie={selectedCategorie}  // Passer l'objet complet de la catégorie
      />
    </div>
  );
};

export default Categories;
