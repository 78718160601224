import axiosInstance from "../variables/axiosInstance";

export const recupererLesClients = () => {
    return axiosInstance.get(`/api/personne/client`);
};

export const recupererLesContrats = () => {
    return axiosInstance.get(`/api/contrat`);
};

export const recupererLesEntitesDeconstat = () => {
    return axiosInstance.get(`/api/entite-constat`);
};

export const recupererLeContratDunClient = (numeroAssure) => {
    return axiosInstance.get(`/api/contrat/souscripteur/${numeroAssure}`);
};

export const ajouterUnTemoin = (temoin) => {
    return axiosInstance.post(`/api/personne`, temoin);
};

export const addPersonne = (personne) => {
    return axiosInstance.post(`/api/personne`, personne);
};

export const updatePersonne = (personne) => {
    return axiosInstance.put(`/api/personne`, personne);
};

export const ajouterUneVictime = (victime) => {
    return axiosInstance.post(`/api/victime`, victime);
};

export const editerUneVictime = (victime) => {
    return axiosInstance.put(`/api/victime`, victime);
};

export const ajouterUnEngin = (engin) => {
    return axiosInstance.post(`/api/engin`, engin);
};

export const ajouterSinistre = (sinistre) => {
    return axiosInstance.post(`/api/sinistre/sinister`, sinistre);
};

export const getSinistreById = (codeId) => {
    return axiosInstance.get(`/api/sinistre/codeId/${codeId}`);
};
