import { Table } from 'antd';
import PropTypes from "prop-types";
import React from 'react';
import entete from "../../../assets/img/entete.jpg";
import footer from "../../../assets/img/footer.png";
import { frenchDateOnly } from '../../../variables/functions';
import "./styles/BordereauStyles.css";

const PdfBordereau = React.forwardRef((props, ref) => {
    // const getCommissionsByIntermediaire = (codeIntermediaire) => {
    //     let inter = props.allIntermediaires.find(el => el.codeIntermediaire === codeIntermediaire);
    //     let filRegs = [];
    //     if (inter) {
    //         filRegs = props.allCommissions.filter(el => el?.contrat?.intermediaire?.codeIntermediaire === codeIntermediaire);
    //     } else {
    //         filRegs = props.allCommissions.filter(el => el?.contrat?.courtier?.codeAgence === codeIntermediaire);
    //     }
    //     return filRegs;
    // }

    // const getCommissionsByCategorie = (codeCategorie) => {
    //     let filRegs = [];
    //     if (codeCategorie) {
    //         filRegs = props.allCommissions.filter(el => el?.contrat?.engins[0]?.categorie?.codeCategorie === codeCategorie);
    //     }
    //     return filRegs;
    // }

    const getCommissionsByIntermediaireAndCategorie = (codeIntermediaire, codeCategorie) => {
        let inter = props?.allIntermediaires?.find(el => el.codeIntermediaire === codeIntermediaire);
        let filRegs = [];
        if (inter) {
            filRegs = props?.allCommissions?.filter(el => el?.contrat?.intermediaire?.codeIntermediaire === codeIntermediaire && el?.contrat?.engins[0]?.categorie?.codeCategorie === codeCategorie);
        } else {
            // let ag = props?.allCourtiers.find(el => el.codeAgence === codeIntermediaire);
            filRegs = props?.allCommissions?.filter(el => el?.contrat?.courtier?.codeAgence === codeIntermediaire && el?.contrat?.engins[0]?.categorie?.codeCategorie === codeCategorie);
        }
        return filRegs;
    }

    const columns = [
        {
            title: "Police + Avenant",
            dataIndex: "numeroPoliceP",
            key: "numeroPoliceP",
            render: (_, record) => (
                <p style={{ width: "115%" }}>{record.contrat.numeroPoliceP} - {record.data?.avenant?.code} </p>
            ),
            width: 150
        },
        {
            title: "Assuré",
            dataIndex: "assure",
            key: "assure",
            render: (_, record) => (
                <p style={{ width: "100%" }}>{record.contrat.souscripteur.nom} {record.contrat.souscripteur.prenom}</p>
            ),
            width: 150
        },
        {
            title: "Effet",
            dataIndex: "dateEffet",
            key: "dateEffet",
            render: (_, record) => (
                <p style={{ width: "100%" }}>{frenchDateOnly(record?.contrat?.dateEffet)} </p>
            ),
            width: 10
        },

        {
            title: "Echéance",
            dataIndex: "dateEcheance",
            key: "dateEcheance",
            render: (_, record) => (
                <p style={{ width: "100%" }}>{frenchDateOnly(record?.contrat?.dateEcheance)} </p>
            ),
            width: 10
        },
        {
            title: "PN payée",
            dataIndex: "primeNette",
            key: "primeNette",
            render: (_, record) => (
                <p style={{ width: "100%" }}>{new Intl.NumberFormat("en-DE").format(record.data.montant)} </p>
            ),
            width: 80
        },
        {
            title: "Com. brute",
            dataIndex: "commission",
            key: "commission",
            render: (_, record) => (
                <p style={{ width: "100%" }}>{new Intl.NumberFormat("en-DE").format(record.data.commission)} </p>
            ),
            width: 90
        },
        {
            title: "Retenue/C",
            dataIndex: "commission",
            key: "commission",
            render: (_, record) => (
                <p style={{ width: "100%" }}>{new Intl.NumberFormat("en-DE").format(record.contrat.courtier.nif ? record.data.commission * 0.05 : record.data.commission * 0.1)} </p>
            ),
            width: 10
        },
        {
            title: "Com. Nette",
            dataIndex: "commission",
            key: "commission",
            render: (_, record) => (
                <p style={{ width: "100%" }}>{new Intl.NumberFormat("en-DE").format(record.contrat.courtier.nif ? record.data.commission * 0.95 : record.data.commission * 0.90)} </p>
            ),
            width: 90
        },
        {
            title: "Compagnie",
            dataIndex: "compagnie",
            key: "compagnie",
            render: (_, record) => (
                <p style={{ width: "140%" }}>{record.contrat.organisme.raisonSociale} </p>
            ),
            width: 200
        },
        {
            title: "Payée le",
            dataIndex: "commissionPayeLe",
            key: "commissionPayeLe",
            render: (_, record) => (
                <p style={{ width: "100%" }}>{record?.data?.commissionPayeLe ? frenchDateOnly(record?.data?.commissionPayeLe) : "-"} </p>
            ),
        },
    ];

    const MyFooter = (properties) => {
        const primeNette = properties.commissions.reduce((pn, commission) => pn + commission.data.montant, 0);
        const commission = properties.commissions.reduce((com, commission) => com + commission.data.commission, 0);

        return (
            <>
                <div>
                    <b>Total primes</b>:{" "}
                    {new Intl.NumberFormat("en-DE").format(primeNette)} F CFA
                </div>
                <div>
                    <b>Total commissions</b>:{" "}
                    {new Intl.NumberFormat("en-DE").format(commission)} F CFA
                </div>
            </>
        );
    };

    return (
        <div ref={ref}>
            {
                props?.allIntermediaires?.map((inter, id) => (
                    props.allCategories.map((cat, index) => (
                        getCommissionsByIntermediaireAndCategorie(inter, cat) && getCommissionsByIntermediaireAndCategorie(inter, cat).length ?
                            <div class="row" key={`${index}_${id}`}>
                                <div class="col-12">
                                    <div className="page-2">
                                        <img
                                            src={entete}
                                            alt="Header"
                                            style={{
                                                width: "1155px",
                                                height: "125px"
                                            }}
                                        />
                                        <div class="card testimonial-card" style={{ width: "1155px" }}>
                                            <div class="text-center">
                                                <h5><u>Bordereau de paiement des commissions - {getCommissionsByIntermediaireAndCategorie(inter, cat)[0]?.contrat?.engins[0]?.categorie?.nom} - {getCommissionsByIntermediaireAndCategorie(inter, cat)[0]?.contrat?.intermediaire ? getCommissionsByIntermediaireAndCategorie(inter, cat)[0]?.contrat?.intermediaire?.personne?.nom : getCommissionsByIntermediaireAndCategorie(inter, cat)[0]?.contrat?.courtier?.nom} </u></h5>
                                            </div>
                                            <div class="row">
                                                <div className="col-lg-12">
                                                    {/* <div className="container"> */}
                                                    <Table
                                                        style={{ width: "1155px" }}
                                                        columns={columns}
                                                        dataSource={getCommissionsByIntermediaireAndCategorie(inter, cat)}
                                                        pagination={false}
                                                        size='small'
                                                        bordered
                                                        className="custom-table"
                                                        footer={() => <MyFooter commissions={getCommissionsByIntermediaireAndCategorie(inter, cat)} />}
                                                    />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <img src={footer} alt="Footer"
                                            style={{
                                                width: "1155px",
                                                height: "90px"
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                            : null
                    ))

                ))
            }
        </div>
    );
});
PdfBordereau.propTypes = {
    allIntermediaires: PropTypes.array,
    allCategories: PropTypes.array,
    allCommissions: PropTypes.array
}
export default PdfBordereau