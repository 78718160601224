import React, {useEffect, useRef, useState} from "react";
import NavBar from "../../../components/NavBar";
import {Footer} from "antd/es/layout/layout";
import {useLocation} from "react-router-dom";
import SideBar from "../../../components/SideBar";
import {Button, Card, Descriptions, Divider, Input, Select, Space, Spin, Tag} from "antd";
import axios from "axios";
import {API_URL, TOKEN} from "../../../variables/constants";
import {CardBody} from "react-bootstrap";
import {PlusOutlined} from "@ant-design/icons";
import ProvisionsModal from "../components/ProvisionsModal";
import GarantyBasedProvisionsDetails from "../components/GarantyBasedProvisionsDetails";

const {Option} = Select;


const EvaluationSinistre = () => {
    const location = useLocation();
    const data = location.state;
    const codeId = data.iz
    const [sinistre, setSinistre] = useState({})
    const [montant, setMontant] = useState(0.0)
    const [loadingSinistre, setLoadingSinistre] = useState(false)

    const [provisionGaranties, setProvisionGaranties] = useState([])
    const [valueSelected, setValueSelected] = useState([])
    const [garanties, setGaranties] = useState([])
    const [selectedGaranties, setSelectedGaranties] = useState([])
    const [evaluation, setEvaluation] = useState([])
    const [garantieState, setGarantieState] = useState()
    const [name, setName] = useState('')
    const [items, setItems] = useState([])
    const [motifRevisionState, setMotifRevisionState] = useState()
    const [provisionsGarantiesModal, setProvisionsGarantiesModal] = useState(false)
    const [chosenGarantieForPrejudice, setChosenGarantieForPrejudice] = useState({})


    /*PREJUDICE DATA TEST*/
    const [selectedPrejudice, setSelectedPrejudice] = useState(null);
    const [cost, setCost] = useState("");

    const prejudices = [
        {label: "Préjudice matériel (dommages aux biens)", value: "Préjudice matériel"},
        {label: "Préjudice corporel (dommages physiques)", value: "Préjudice corporel"},
        {label: "Préjudice moral (souffrance psychologique)", value: "Préjudice moral"},
        {label: "Préjudice économique (pertes financières)", value: "Préjudice économique"},
    ];

    /*PREJUDICE DATA TEST END*/


    const inputRef = useRef(null);
    const addProvisionFormFields = () => {
        setProvisionGaranties([
            ...provisionGaranties,
        ]);
    };
    const removeProvisionFormFields = (index) => {
        let newProvisionFormFields = [...provisionGaranties];
        let id = valueSelected.findIndex((value) => value === index);
        if (id !== -1) {
            newProvisionFormFields.splice(id, 1);
        }

        setProvisionGaranties(newProvisionFormFields);
    };

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (name?.trim() === '') {
            return
        }
        setItems([...items, name]);
        setName("");
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const handleChange = (e, nom) => {
        if (nom) {
            setEvaluation({...evaluation, [nom]: e});
        } else {
            const {name, value} = e.target;
            setEvaluation({...evaluation, [name]: value});
        }
    };

    console.log(provisionGaranties)

    useEffect(() => {
        if (codeId) {
            setLoadingSinistre(true)
            axios
                .get(`${API_URL}/api/sinistre/codeId/${codeId}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    setSinistre(res.data);
                    let m = 0.0;
                    res.data.decaissements.forEach((d) => {
                        if (!d.encaissement) {
                            m += parseFloat(d.montantGlobal);
                        }
                    });
                    setMontant(m);
                    setLoadingSinistre(false)

                    if (res.data?.engin) {
                        axios
                            .get(`${API_URL}/api/garantie/categorie/compulsory/code/${res.data?.engin?.categorie?.codeCategorie}`, {
                                headers: {
                                    Authorization: `Bearer ${TOKEN}`,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                },
                            })
                            .then((response) => {
                                let gs = [];
                                gs = response.data;
                                let g = [];
                                res.data.engin.sousGaranties && res.data.engin.sousGaranties.forEach((sg) => {
                                    gs.push(sg.garantie);
                                    g.push({
                                        value: sg.garantie.codeId, label: sg.nom, garantie: sg.garantie,
                                    });
                                });

                                response.data && response.data.forEach((ga) => {
                                    g.push({
                                        value: ga.codeId, label: ga.nom, garantie: ga,
                                    });
                                });

                                console.log("garantiiiiiiiiiiiies:", g);
                                setGaranties(g);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }

                })
                .catch((err) => {
                    setLoadingSinistre(false)
                    console.log(err);
                });
        }
    }, [codeId]);

    const calculateTotalProvision = (garantieId) => {
        if (!Array.isArray(provisionGaranties) || !garantieId) {
            throw new Error("Invalid input: data must be an array and garantieId must be provided");
        }

        return provisionGaranties
            .filter(item => item.garantie?.codeId === garantieId)
            .reduce((total, item) => {
                const provision = parseFloat(item.provision);
                return total + (isNaN(provision) ? 0 : provision);
            }, 0);
    };

    const garantyBasedProvision = (garantieId) => {
        if (!Array.isArray(provisionGaranties) || !garantieId) {
            throw new Error("Invalid input: data must be an array and garantieId must be provided");
        }

        return provisionGaranties
            .filter(item => item.garantie?.codeId === garantieId)
    }

    const getStateOfSinistre = () => {
        const states = {
            I: {color: "green", label: "EN INSTANCE"},
            T: {color: "red", label: "TERMINÉ"},
            S: {color: "blue", label: "SANS SUITE"},
        };

        const {color, label} = states[sinistre?.sortDossier] || states["I"];

        return <Tag color={color}>{label}</Tag>;
    };

    return (
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                <SideBar currentMenu={"SINISTRES"}/>

                {/* <!-- Layout container --> */}
                <div className="layout-page">
                    <NavBar/>

                    {/* <!-- Content wrapper --> */}
                    <div className="content-wrapper">
                        {/* <!-- Content --> */}
                        <Spin spinning={loadingSinistre}>
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className={'card'}>
                                    <div className="card-body">
                                        <div
                                            className="container"
                                        >
                                            <div className="grid-item">
                                                <>
                                                    {sinistre?.sortDossier === "I" ? (<Tag color="green">EN
                                                        INSTANCE</Tag>) : sinistre?.sortDossier === "T" ? (
                                                        <Tag
                                                            color="red">TERMINÉ</Tag>) : sinistre?.sortDossier === "S" ? (
                                                        <Tag color="blue">SANS SUITE</Tag>) : (
                                                        <Tag color="green">EN INSTANCE</Tag>)}
                                                </>
                                                <br/>
                                                <br/>
                                                <Descriptions
                                                    title={`Sinistre N° - ${sinistre?.numSinistre ? sinistre?.numSinistre : "-"} `}
                                                >
                                                    <Descriptions.Item label="Numéro de Police">
                                                        {sinistre?.contrat?.souscripteur?.numeroPoliceM ? `${sinistre?.contrat?.numeroPoliceM}` : sinistre?.contrat?.numeroPoliceP ? `${sinistre?.contrat?.numeroPoliceP}` : "-"}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Assuré">
                                                        {
                                                            sinistre?.contrat?.souscripteur?.prenom || sinistre?.contrat?.souscripteur?.nom
                                                                ? `${sinistre?.contrat?.souscripteur?.prenom || ''} ${sinistre?.contrat?.souscripteur?.nom || ''}`.trim()
                                                                : '-'
                                                        }

                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Immatriculation engin">
                                                        {sinistre?.engin?.immatriculation}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                        label="Provision actuelle">
                                                        {sinistre?.evaluation ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation) : "0"}{" "}
                                                        FCFA
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Sinistre à payer">
                                                        {sinistre?.sap ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation - montant) : "0"}{" "}
                                                        FCFA
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Montant réglé">
                                                        {sinistre?.sap ? new Intl.NumberFormat("en-DE").format(montant) : "0"}{" "}
                                                        FCFA
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Card style={{
                                    marginTop: '10px'
                                }}>
                                    <CardBody>
                                        <div className={' grid container'}>
                                            <div className={'grid-item'}>
                                                <div>
                                                    <b>Ancienne provision :</b>{" "}
                                                    <Tag color="blue">
                                                        <b>
                                                            {sinistre?.evaluation
                                                                ? new Intl.NumberFormat("en-DE").format(
                                                                    sinistre?.evaluation
                                                                )
                                                                : "0"}{" "}
                                                            FCFA
                                                        </b>
                                                    </Tag>
                                                </div>
                                                <br/>
                                                <div>
                                                    <b>Nouvelle provision :</b>{"  "}
                                                    <Tag color="green">
                                                        <b>
                                                            {evaluation?.nouvelleProvision
                                                                ? new Intl.NumberFormat("en-DE").format(
                                                                    evaluation?.nouvelleProvision
                                                                )
                                                                : "0"}{" "}
                                                            FCFA
                                                        </b>
                                                    </Tag>
                                                    <br/>
                                                    <div className={' grid container'}>
                                                        <div className={'grid-item'}>
                                                            <div className={'form-control'} style={{
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'right',
                                                                border: 'none',
                                                                marginTop: '10px'
                                                            }}>
                                                                <Select
                                                                    value={valueSelected}
                                                                    mode={'multiple'}
                                                                    placeholder={'Selectionner des garanties'}
                                                                    options={garanties}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '50px',

                                                                    }}
                                                                    onChange={(value) => {
                                                                        let vs = valueSelected;
                                                                        if (value?.length > vs?.length) {
                                                                            addProvisionFormFields();
                                                                        } else {
                                                                            let outGarantie = value.filter(
                                                                                (v) => !garanties.includes(v)
                                                                            );

                                                                            outGarantie &&
                                                                            removeProvisionFormFields(
                                                                                outGarantie[0]
                                                                            );
                                                                        }
                                                                        var gs = [];
                                                                        value.forEach((v) => {
                                                                            let g = garanties.find(
                                                                                (gar) => gar.value === v
                                                                            );
                                                                            g && gs.push(g.garantie);
                                                                        });
                                                                        setValueSelected(value);
                                                                        setSelectedGaranties(gs);
                                                                        // console.log("Target...", value);
                                                                        value?.length
                                                                            ? setGarantieState("success")
                                                                            : setGarantieState("error");
                                                                    }}

                                                                />
                                                            </div>
                                                        </div>
                                                        {selectedGaranties.length ? (
                                                            <div className={' grid container'} style={{
                                                                justifyContent: 'center'
                                                            }}>
                                                                {selectedGaranties.map((sg, index) => (
                                                                    <div className={'grid-item'}
                                                                         key={index}
                                                                    >
                                                                        <label
                                                                            htmlFor="capital"
                                                                            style={{
                                                                                margin: "30px 0 10px 0",
                                                                            }}
                                                                        >
                                                                            Provision Garantie {sg.nom} :
                                                                        </label>{"  "}
                                                                        <Tag color={'geekblue'}>
                                                                            <b> {calculateTotalProvision(sg.codeId) || 0} FCFA</b>
                                                                        </Tag>
                                                                        <br/>
                                                                        <div>
                                                                            <Button type={'primary'}
                                                                                    icon={<PlusOutlined/>}
                                                                                    style={{marginBottom: 16}}
                                                                                    onClick={() => {
                                                                                        setChosenGarantieForPrejudice(sg)
                                                                                        setProvisionsGarantiesModal(true)
                                                                                    }}>
                                                                                Nouvelle provision
                                                                            </Button>
                                                                        </div>
                                                                        <GarantyBasedProvisionsDetails
                                                                            provisions={garantyBasedProvision(sg.codeId)}/>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <br/>
                                                </div>

                                                <br/>
                                                <div className={'form-control'} style={{
                                                    border: 'none'
                                                }}>
                                                    <Select
                                                        id="motifRevision"
                                                        name="motifRevision"
                                                        placeholder="Motif de la révision"
                                                        style={{
                                                            width: '100%',
                                                            height: '50px'
                                                        }}
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Divider
                                                                    style={{
                                                                        margin: "8px 0",
                                                                    }}
                                                                />
                                                                <Space
                                                                    style={{
                                                                        padding: "0 8px 4px",
                                                                    }}
                                                                >
                                                                    <Input
                                                                        placeholder="Ajouter un motif"
                                                                        ref={inputRef}
                                                                        value={name}
                                                                        onChange={onNameChange}
                                                                    />
                                                                    <Button
                                                                        type="text"
                                                                        icon={<PlusOutlined/>}
                                                                        onClick={addItem}
                                                                    >
                                                                        Ajouter un motif
                                                                    </Button>
                                                                </Space>
                                                            </>
                                                        )}
                                                        options={items.map((item) => ({
                                                            label: item,
                                                            value: item,
                                                        }))}
                                                        onChange={(value) => {
                                                            handleChange(value, "motifRevision");
                                                            console.log(value);
                                                            if (value.trim().length > 3) {
                                                                setMotifRevisionState("success");
                                                            } else {
                                                                setMotifRevisionState("error");
                                                            }
                                                        }}
                                                        value={evaluation.motifRevision}
                                                    />
                                                </div>
                                                <br/>
                                                <Button type={'primary'} onClick={
                                                    ()=> console.log(provisionGaranties)
                                                }> Enregistrer l&apos;evaluation </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <ProvisionsModal provisionGaranties={provisionGaranties} prejudices={prejudices}
                                                 setProvisionGaranties={setProvisionGaranties}
                                                 setProvisionsGarantiesModal={setProvisionsGarantiesModal}
                                                 provisionsGarantiesModal={provisionsGarantiesModal}
                                                 chosenGarantieForPrejudice={chosenGarantieForPrejudice}/>
                            </div>

                        </Spin>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div className="content-backdrop fade">
                        </div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div className="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div className="drag-target"></div>
        </div>
    );
};

export default EvaluationSinistre;
