
import { List, Modal, Select } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL, TOKEN } from "../../../../variables/constants";


const AugmentationDeGarantie = (props) => {

    const [modalAugmentationGarantie, setModalAugmentationGarantie] = useState(
        props.open
    );

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [contrat, setContrat] = useState();
    const [garanties, setGaranties] = useState([[]]);
    const [sousGaranties, setSousGaranties] = useState([[]]);
    const [compulsoryGuaranties, setCompulsoryGuaranties] = useState([]);
    const [engins, setEngins] = useState([]);

    const getMultipleSelect = (index) => {
        return garanties[index] ? garanties[index] : new Array(0).fill([]);
    };

    useEffect(() => {
        setModalAugmentationGarantie(props.open);
        if (props.open) {
            setContrat(props.contrat);
            setEngins(props?.contrat?.engins);

            var dt = [];
            var dtg = [];
            var dtgJ = [];

            var newGaranties = [...garanties];

            props?.contrat?.engins?.forEach((engin, index) => {
                dt.push({
                    id: index,
                    energie: engin.energie,
                    puissance: engin.puissance,
                    volume: engin.volume,
                    nombrePlace: engin.nombrePlace,
                    categorie: engin.categorie,
                    valeurVenale: engin.valeurVenale,
                    valeurANeuf: engin.valeurANeuf,
                    valeurDeclaree: engin.valeurDeclaree,
                    numeroChassis: engin.numeroChassis,
                    marque: engin.marque,
                });
                var ng = [];
                var ngJ = [];
                engin.sousGaranties.forEach((sg) => {
                    ng.push(JSON.stringify(sg.garantie));
                    ngJ.push(sg.garantie);
                });

                dtg.push(ng);
                dtgJ.push(ngJ);
                newGaranties[index] = ng;
            });
            console.log("Dttt: ", dt);
            setGaranties(dtg);
            setData(dt);

            dtg.forEach((el, index) => {
                handleSousGarantiesWithValues(index, el);
            });

            axios
                .get(
                    `${API_URL}/api/garantie/categorie/non-compulsory/${props?.contrat?.engins[0]?.categorie?.categorieId}/${props?.contrat?.organisme?.code}`,
                    {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                                "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    }
                )
                .then((response) => {
                    // console.log(response.data);
                    setCompulsoryGuaranties(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }, [props.open]);

    const navigate = useNavigate();

    const handleSousGarantie = (index, value) => {
        var gtys = [];
        if (value.length === 1) {
            gtys.push(JSON.parse(value));
        } else {
            value.forEach((element) => {
                try {
                    gtys.push(JSON.parse(element));
                } catch (err) {
                    gtys.push(element);
                }
            });
        }

        // console.log("GTYSG: ", gtys);
        axios
            .post(`${API_URL}/api/sous-garantie/garanties`, JSON.stringify(gtys), {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((ress) => {
                // console.log("Resssss: ", ress.data);
                var newSousGaranties = [...sousGaranties];
                newSousGaranties[index] = ress.data;
                setSousGaranties(newSousGaranties);
                console.log("NSG: ", newSousGaranties);
            })
            .catch((errr) => {
                console.log(errr);
            });
    };

    const handleSousGarantiesWithValues = (index, value) => {
        var gtys = [];
        if (value.length === 1) {
            gtys.push(JSON.parse(value));
        } else {
            value.forEach((element) => {
                try {
                    gtys.push(JSON.parse(element));
                } catch (err) {
                    gtys.push(element);
                }
            });
        }
        // console.log("GTYS: ", gtys);
        axios
            .post(`${API_URL}/api/sous-garantie/garanties`, JSON.stringify(gtys), {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((ress) => {
                var newSousGaranties = [...sousGaranties];
                newSousGaranties[index] = ress.data;
                setSousGaranties(newSousGaranties);
                // console.log("NSG: ", newSousGaranties);
            })
            .catch((errr) => {
                console.log(errr);
            });
    };

    const handleGarantiesChange = (index, e) => {
        let newGaranties = [...garanties];
        newGaranties[index] = e;
        setGaranties(newGaranties);
        handleSousGarantie(index, e);
        // console.log("NG: ", newGaranties);
    };

    const enterLoading = () => {
        setLoading(true);
    };

    const outLoading = () => {
        setLoading(false);
    };

    const handleAugmentationSubmit = () => {
        enterLoading();
        var allEngins = [];
        engins.forEach((engin, index) => {
            engin.sousGaranties = sousGaranties[index];
            allEngins.push(engin);
        });

        axios
            .put(
                `${API_URL}/api/engin/augmentation-garantie/codeId/${contrat.codeId}`,
                JSON.stringify(allEngins),
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            )
            .then((resEngin) => {
                console.log(resEngin.data);
                toast("Augmentation de garanties effectuée!");
                outLoading();
                setModalAugmentationGarantie(false);
                props.update(false);
                navigate(`/details-contrat/${resEngin.data.codeId}`);
                window.location.reload();
            })
            .catch((errEngin) => {
                console.log(errEngin);
                toast("Erreur lors de l'augmentation des garanties");
            });
    };

    const handleCancel = () => {
        setModalAugmentationGarantie(false);
        props.update(false);
    };

    return (
        <Modal
            title="AUGMENTATION DE GARANTIES"
            centered
            confirmLoading={loading}
            open={modalAugmentationGarantie}
            onOk={() => handleAugmentationSubmit()}
            onCancel={() => handleCancel()}
        >
            <List
                itemLayout="vertical"
                size="large"
                dataSource={data}
                footer={
                    <div>
                        <b>Engins</b>
                    </div>
                }
                renderItem={(item) => (
                    <List.Item key={item.codeId}>
                        <List.Item.Meta
                            title={<a href={item.href}>{item.immatriculation}</a>}
                            description={`${item.marque} - Moteur: ${item.numeroChassis}`}
                        />

                        <form>
                            <div class="col-sm-12">
                                <label class="form-label" for="garanties">Garanties</label>

                                <Select
                                    mode="multiple"
                                    value={getMultipleSelect(item.id)}
                                    onChange={(event) => {
                                        handleGarantiesChange(item.id, event);
                                        handleSousGarantie(item.id, event);
                                    }}
                                    options={compulsoryGuaranties?.map((guaranty, index) => (
                                        {
                                            label: guaranty.nom,
                                            value: JSON.stringify(guaranty),
                                            key: index
                                        }
                                    ))}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </form>
                    </List.Item>
                )}
            />
        </Modal>
    );
};

AugmentationDeGarantie.propTypes = {
    open: PropTypes.bool,
    update: PropTypes.func,
    contrat: PropTypes.object
};

export default AugmentationDeGarantie;