import { InboxOutlined } from "@ant-design/icons";
import { Card, DatePicker, Table, theme } from "antd";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import defaultPreview from "../../assets/img/Excel-logo.png";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { API_URL, TOKEN } from "../../variables/constants";
import { verifyLength } from "../../variables/functions";

const STACFileConsumption = () => {
  const [exercice, setExercice] = useState("");
  const [dateEmission, setDateEmission] = useState(new Date());
  const [results, setResults] = useState([]);
  const [dataToSave, setDataToSave] = useState([]);
  const [badDatas, setBadDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existingPolices, setExistingPolices] = useState([]);
  const [existingContracts, setExistingContracts] = useState([]);

  const handlePreview = (file) => {
    console.log("file: ", file);
    this.setState({
      previewImage: defaultPreview,
      previewVisible: true,
    });
  };

  const navigate = useNavigate();

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log("submit");
    if (badDatas.length) {
      toast("Corriger les erreurs dans le fichier et téléverser à nouveau");
    } else {
      if (exercice && dataToSave.length) {
        const data = {
          exercice: exercice,
          datas: dataToSave,
          dateEmission: dateEmission,
        };
        axios
          .post(`${API_URL}/api/cession/port`, JSON.stringify(data), {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          })
          .then((res) => {
            // console.log("res: ", res.data);
            res.data = null;
            setLoading(false);
            toast("Chargement effectué avec succès");
            navigate("/production", { replace: true });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        toast("Veuillez renseigner l'exercice");
      }
    }
  };
  const { token } = theme.useToken();

  const columns = [
    {
      title: "N° Police",
      dataIndex: "numeroPolice",
      key: "numeroPolice",
      width: 50,
      render: (_, { effet, categorie, numeroAssureP }) => (
        <>
          {categorie}-{numeroAssureP}/{effet}{" "}
        </>
      ),
    },
    {
      title: "Immatriculation",
      dataIndex: "immatriculation",
      key: "immatriculation",
      width: 50,
      render: (_, { immatriculation, frontiere }) => (
        <>
          TGWZ {immatriculation} {frontiere}{" "}
        </>
      ),
    },
    {
      title: "Catégorie",
      dataIndex: "categorie",
      key: "categorie",
      render: (_, { categorie }) => <>{categorie === "1" ? "VL" : "C"}</>,
      width: 35,
    },
    {
      title: "N° Assuré",
      dataIndex: "numeroAssureP",
      key: "numeroAssureP",
      width: 50,
    },
    {
      title: "Assuré",
      dataIndex: "nom",
      key: "nom",
      width: 70,
    },
    {
      title: "Date Effet",
      dataIndex: "dateEffet",
      key: "dateEffet",
      width: 50,
    },
    {
      title: "Date Echéance",
      dataIndex: "dateEcheance",
      key: "dateEcheance",
      width: 50,
    },
    {
      title: "Marque",
      dataIndex: "marque",
      key: "marque",
      width: 90,
    },
    {
      title: "Numéro Châssis",
      dataIndex: "numeroChassis",
      key: "numeroChassis",
      width: 90,
    },
    {
      title: "Prime RC",
      dataIndex: "primeRC",
      key: "primeRC",
      width: 40,
      render: (primeRc) => (
        <>{new Intl.NumberFormat("en-DE").format(primeRc)}</>
      ),
    },
    {
      title: "Prime CEDEAO",
      dataIndex: "primeCEDEAO",
      key: "primeCEDEAO",
      width: 40,
      render: (primeCEDEAO) => (
        <>{new Intl.NumberFormat("en-DE").format(primeCEDEAO)}</>
      ),
    },
    {
      title: "Prime Individuel Conducteur",
      dataIndex: "primeIndC",
      key: "primeIndC",
      width: 60,
      render: (primeIndC) => (
        <>{new Intl.NumberFormat("en-DE").format(primeIndC)}</>
      ),
    },
    {
      title: "Accessoires",
      dataIndex: "accessoires",
      key: "accessoires",
      width: 50,
      render: (accessoires) => (
        <>{new Intl.NumberFormat("en-DE").format(accessoires)}</>
      ),
    },
    {
      title: "Taxes",
      dataIndex: "taxes",
      key: "taxes",
      width: 50,
      render: (taxes) => <>{new Intl.NumberFormat("en-DE").format(taxes)}</>,
    },
    {
      title: "Prime TTC",
      dataIndex: "primeTotale",
      key: "primeTotale",
      width: 50,
      render: (primeTotale) => (
        <>{new Intl.NumberFormat("en-DE").format(primeTotale)}</>
      ),
    },
  ];

  const processCSVContent = (csvContent) => {
    // Parse CSV content (assuming comma-separated values)
    const rows = csvContent.split("\n");
    const data = rows.map((row) => row.split(","));

    // Do something with the parsed data
    // console.log("Data: ", data);
    let dt = [];
    let dts = [];
    let bdt = [];
    data.forEach((r, index) => {
      let row = r[0].split(";");
      // if (index === 22 || index === 23) {
      //   console.log("Row: ", index, " ", row);
      //   console.log("Ro: ", r);
      //   console.log("validate: ", validateData(row));
      // }
      if (!validateData(row)[0]) {
        console.log("validate: ", validateData(row));
      }

      row[4] === "2" && validateData(row)[0] &&
        dt.push({
          key: index,
          numeroAssureP: row[0],
          numeroPoliceM: `${row[4]}-${row[0]}/${row[2]}`,
          nom: row[1],
          effet: row[2],
          dateEffet: moment(row[2]).format("dd/MM/YYYY"),
          dateEcheance: moment(row[3]).format("dd/MM/YYYY"),
          categorie: row[4],
          immatriculation: row[5],
          marque: row[6],
          numeroChassis: row[7],
          frontiere: row[8],
          primeRC: row[9],
          primeCEDEAO: row[10],
          primeIndC: row[11],
          accessoires: row[12],
          taxes: row[13],
          primeTotale: row[14],
        });

      row[4] === "2" && !validateData(row)[0] &&
        bdt.push({
          key: index,
          numeroAssureP: row[0],
          numeroPoliceM: `${row[4]}-${row[0]}/${row[2]}`,
          nom: row[1],
          effet: row[2],
          dateEffet: moment(row[2]).format("dd/MM/YYYY"),
          dateEcheance: moment(row[3]).format("dd/MM/YYYY"),
          categorie: row[4],
          immatriculation: row[5],
          marque: row[6],
          numeroChassis: row[7],
          frontiere: row[8],
          primeRC: row[9],
          primeCEDEAO: row[10],
          primeIndC: row[11],
          accessoires: row[12],
          taxes: row[13],
          primeTotale: row[14],
        });

      row[4] === "1" && validateData(row)[0] &&
        dt.push({
          key: index,
          numeroAssureP: row[0],
          numeroPoliceM: `${row[4]}-${row[0]}/${row[2]}`,
          nom: row[1],
          effet: row[2],
          dateEffet: moment(row[2]).format("dd/MM/YYYY"),
          dateEcheance: moment(row[3]).format("dd/MM/YYYY"),
          categorie: row[4],
          immatriculation: row[5],
          marque: row[6],
          numeroChassis: row[7],
          frontiere: row[8],
          primeRC: row[9],
          primeCEDEAO: row[10],
          primeIndC: row[11],
          accessoires: row[12],
          taxes: row[13],
          primeTotale: row[14],
        });

      row[4] === "1" && !validateData(row)[0] &&
        bdt.push({
          key: index,
          numeroAssureP: row[0],
          numeroPoliceM: `${row[4]}-${row[0]}/${row[2]}`,
          nom: row[1],
          effet: row[2],
          dateEffet: moment(row[2]).format("dd/MM/YYYY"),
          dateEcheance: moment(row[3]).format("dd/MM/YYYY"),
          categorie: row[4],
          immatriculation: row[5],
          marque: row[6],
          numeroChassis: row[7],
          frontiere: row[8],
          primeRC: row[9],
          primeCEDEAO: row[10],
          primeIndC: row[11],
          accessoires: row[12],
          taxes: row[13],
          primeTotale: row[14],
        });

      row[4] === "2" && validateData(row)[0] &&
        dts.push({
          key: index,
          numeroAssureP: row[0],
          numeroPoliceM: `${row[4]}-${row[0]}/${row[2]}`,
          nom: row[1],
          effet: row[2],
          dateEffet: moment(row[2]).toISOString(),
          dateEcheance: moment(row[3]).toISOString(),
          categorie: row[4] === "1" ? "VL" : "C",
          immatriculation: `TGWZ ${row[5]} ${row[8]}`,
          marque: row[6],
          numeroChassis: row[7],
          frontiere: row[8],
          primeRC: row[9],
          primeCEDEAO: row[10],
          primeIndC: row[11],
          accessoires: row[12],
          taxes: row[13],
          primeTotale: row[14],
        });

      row[4] === "1" && validateData(row)[0] &&
        dts.push({
          key: index,
          numeroAssureP: row[0],
          numeroPoliceM: `${row[4]}-${row[0]}/${row[2]}`,
          nom: row[1],
          effet: row[2],
          dateEffet: moment(row[2]).toISOString(),
          dateEcheance: moment(row[3]).toISOString(),
          categorie: row[4] === "1" ? "VL" : "C",
          immatriculation: `TGWZ ${row[5]} ${row[8]}`,
          marque: row[6],
          numeroChassis: row[7],
          frontiere: row[8],
          primeRC: row[9],
          primeCEDEAO: row[10],
          primeIndC: row[11],
          accessoires: row[12],
          taxes: row[13],
          primeTotale: row[14],
        });
    });
    checkExistingContracts(dt);
    // console.log("DTTTT: ", dt);
    setResults(dt);

    setDataToSave(dts);
    setBadDatas(bdt);
  };

  const checkExistingContracts = (contracts) => {
    setLoading(true)
    // console.log("LogContracts...", contracts);
    let polices = [];
    contracts.forEach(element => {
      polices.push(element.numeroPoliceM);
    });

    // console.log("Log...", polices);
    axios
      .post(`${API_URL}/api/cession/port/check`, JSON.stringify(polices), {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log("res: ", res.data);
        setExistingPolices(res.data);
        let policesContracts = [];
        res.data.forEach(element => {
          policesContracts.push(contracts.find(el => el.numeroPoliceM === element));
        });
        setExistingContracts(policesContracts);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const boolValidate = (previous, next) => {
    if (previous !== next) {
      return false;
    } else if (previous === next) {
      return previous;
    }
  };

  const validateData = (row) => {
    var observations = [];
    var validated = true;
    if (!verifyLength(row[0], 6)) {
      observations.push("Numero de l'assuré est invalide");
      validated = boolValidate(validated, false);
    }
    if (!verifyLength(row[1], 3)) {
      observations.push("Nom de l'assuré invalide");
      validated = boolValidate(validated, false);
    }
    if (!row[2]) {
      observations.push("Date effet invalide");
      validated = boolValidate(validated, false);
    }
    if (!row[3]) {
      observations.push("Date échéance invalide");
      validated = boolValidate(validated, false);
    }
    if (!verifyLength(row[5], 5)) {
      observations.push("Numéro d'immatriculation invalide");
      validated = boolValidate(validated, false);
    }
    // if (!verifyLength(row[5], 3)) {
    //   observations.push("Nom de l'assure invalide");
    //   validated = boolValidate(validated, false);
    // }
    if (!verifyLength(row[6], 3)) {
      observations.push("Marque de l'engin invalide");
      validated = boolValidate(validated, false);
    }
    if (!verifyLength(row[7], 10)) {
      observations.push("Numéro de châssis invalide");
      validated = boolValidate(validated, false);
    }
    if (isNaN(row[9])) {
      observations.push("La prime RC est invalide");
      validated = boolValidate(validated, false);
    }
    if (isNaN(row[10])) {
      observations.push("La prime CEDEAO est invalide");
      validated = boolValidate(validated, false);
    }
    if (isNaN(row[11])) {
      observations.push("La prime IC est invalide");
      validated = boolValidate(validated, false);
    }
    if (isNaN(row[12])) {
      observations.push("Les frais d'accessoires sont invalides");
      validated = boolValidate(validated, false);
    }
    if (isNaN(row[13])) {
      observations.push("La taxe est invalide");
      validated = boolValidate(validated, false);
    }
    if (isNaN(row[14])) {
      observations.push("Prime totale invalide");
      validated = boolValidate(validated, false);
    }
    // console.log("Observations: ", observations);
    return [validated, observations];
  };

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const uploadSubmit = (e) => {
    e.fileList.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const csvContent = event.target.result; // CSV content
        // Process the CSV content
        processCSVContent(csvContent);
      };

      reader.readAsText(file.originFileObj);
    });
  };

  const removeFile = (e) => {
    console.log("removeFile: ", e);
  };

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"PRODUCTION"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <form onSubmit={onSubmit}>
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-header bg-primary text-white">
                          <div class="card-text">
                            <h4 className="text-white">
                              Charger les bordereaux d'émission
                            </h4>
                          </div>
                        </div>

                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              {/* <!-- Date Picker for Exercice --> */}
                              <br />
                              <label className="form-label" htmlFor="exercice">
                                Exercice
                              </label>
                              <br />
                              <DatePicker
                                onChange={(date, dateString) => {
                                  console.log(
                                    "date: ",
                                    dateString,
                                    "ts: ",
                                    date.toISOString()
                                  );
                                  setExercice(date);
                                }}
                                style={{ width: "50%" }}
                                picker="year"
                                placeholder="Exercice"
                                defaultValue={dayjs()}
                              // value={exercice}
                              />
                            </div>
                            <div className="col-12">
                              <br />
                              <label className="form-label" htmlFor="exercice">
                                Date d'émission des contrats
                              </label>
                              <br />
                              {/* <!-- Date Picker for Date d'émission --> */}
                              <DatePicker
                                onChange={(date, dateString) => {
                                  console.log(
                                    "date: ",
                                    dateString,
                                    "ts: ",
                                    date
                                  );
                                  setDateEmission(date.toISOString());
                                }}
                                style={{ width: "50%" }}
                                placeholder="Date d'émission"
                                defaultValue={dayjs()}
                              // value={dateEmission}
                              />
                              <br />
                            </div>
                            <div className="col-12">
                              <br />
                              {/* <!-- Dragger Section for File Upload --> */}
                              <div
                                class="p-3 border"
                                style={{ textAlign: "center" }}
                              >
                                <div class="custom-file">
                                  <Dragger
                                    onChange={(e) => uploadSubmit(e)}
                                    beforeUpload={() => false}
                                    onRemove={(e) => removeFile(e)}
                                    accept={".xlsx,.csv"}
                                    onPreview={handlePreview}
                                    maxCount={1}
                                  >
                                    <p className="ant-upload-drag-icon">
                                      <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                      Cliquez ou faites glisser le fichier dans
                                      cette zone pour le téléverser
                                    </p>
                                  </Dragger>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card-footer d-flex justify-content-center">
                          <button
                            class="btn btn-primary"
                            disabled={loading}
                            onClick={(e) => onSubmit(e)}
                          >
                            {loading && (
                              <span
                                class="spinner-grow me-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {loading ? `Enregistrement...` : `Enregistrer`}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="container">
                <div class="row">
                  <div>
                    <br />
                    {existingPolices.length ? (
                      <Card
                        title="Données déjà existantes"
                        bordered={false}
                      >
                        <Table
                          scroll={{ x: 4500, y: 700 }}
                          columns={columns}
                          dataSource={existingContracts}
                        />
                      </Card>
                    ) : null}
                    <br />
                    {badDatas.length ?
                      <Card
                        title="Données non conformes"
                        bordered={false}
                      >
                        <Table
                          scroll={{ x: 4500, y: 700 }}
                          columns={columns}
                          dataSource={badDatas}
                        />
                      </Card> : results.length ? (
                        <Card
                          title="Prévisualisation des données chargées"
                          bordered={false}
                        >
                          <Table
                            scroll={{ x: 4500, y: 700 }}
                            columns={columns}
                            dataSource={results}
                          />
                        </Card>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default STACFileConsumption;
