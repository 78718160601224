import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../variables/constants";

const Login = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${API_URL}/api/authenticate`, JSON.stringify(user), {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        sessionStorage.setItem("t", res.data.split(".")[1]);
        sessionStorage.setItem("k", res.data.split(".")[2]);
        sessionStorage.setItem("n", res.data.split(".")[0]);

        axios
          .get(`${API_URL}/api/utilisateur/session/${user.userName}`, {
            headers: {
              Authorization: `Bearer ${res.data}`,
              "Content-Type": "application/json",
            },
          })
          .then((resp) => {
            if (resp.data?.dateExpiration === null) {
              if (resp.data.active) {
                sessionStorage.setItem("user", JSON.stringify(resp.data));
                if (resp.data.profil.organisme.otpConnexion) {
                  toast.success("Veuillez valider la seconde étape de connexion!");
                  navigate("/confirm-otp");
                  window.location.reload();
                } else {
                  toast.success("Connexion avec succès!");
                  navigate("/");
                  window.location.reload();
                }
              } else {
                toast.error("Votre compte n'est pas actif.");
              }
            } else {
              const expirationDate = new Date(resp.data?.dateExpiration);
              const today = new Date();
              if (expirationDate && expirationDate > today) {
                if (resp.data.active) {
                  sessionStorage.setItem("user", JSON.stringify(resp.data));
                  if (resp.data.profil.organisme.otpConnexion) {
                    toast.success("Veuillez valider la seconde étape de connexion!");
                    navigate("/confirm-otp");
                    window.location.reload();
                  } else {
                    toast.success("Connexion avec succès!");
                    navigate("/");
                    window.location.reload();
                  }
                } else {
                  toast.error("Votre compte n'est pas actif.");
                }
              } else {
                navigate("/first-connexion");
                toast.info("Veuillez changer de mot de passe.");
              }
            }

            setLoading(false);
          })
          .catch((err) => {
            console.error("Erreur lors de la récupération de la session:", err);
            toast.error("Erreur lors de la récupération de la session.");
            setLoading(false);
          });
      })
      .catch((err) => {
        console.error("Erreur lors de la tentative de connexion:", err);

        if (err.response) {
          const { status } = err.response;

          if (status === 423) {
            // Erreur 423: Compte verrouillé
            toast.error("Votre compte est verrouillé après 3 tentatives échouées.");
          } else if (status === 400 || status === 401) {
            toast.error("Identifiants incorrects. Veuillez vérifier votre nom d'utilisateur et mot de passe.");
          } else {
            toast.error("Une erreur est survenue lors de la connexion. Veuillez réessayer.");
          }
        } else {
          toast.error("Une erreur réseau est survenue. Veuillez vérifier votre connexion.");
        }

        setLoading(false);
      });
  };

  return (
    <div className="authentication-wrapper authentication-cover">
      <div className="authentication-inner row m-0">
        <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
          <div className="w-100 d-flex justify-content-center">
            <img
              src="assets/img/login.jpg"
              className="img-fluid"
              alt="LoginImage"
              width="100000"
            />
          </div>
        </div>
        <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-12 p-6">
          <div className="w-px-400 mx-auto mt-12 pt-5">
            <h4 className="mb-1">Bienvenue sur PoolIns! 👋</h4>
            <p className="mb-6">
              Veuillez entrer vos identifiants pour vous connecter
            </p>

            <form id="formAuthentication" className="mb-6" onSubmit={handleSubmit}>
              <div className="mb-6">
                <label htmlFor="email" className="form-label">
                  Adresse mail
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="userName"
                  placeholder="Entrez votre adresse mail"
                  autoFocus
                  onChange={handleChange}
                />
              </div>
              <div className="mb-6 form-password-toggle">
                <label className="form-label" htmlFor="password">
                  Mot de passe
                </label>
                <div className="input-group input-group-merge">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    name="password"
                    placeholder="Entrez votre mot de passe"
                    aria-describedby="password"
                    onChange={handleChange}
                  />
                  <span className="input-group-text cursor-pointer">
                    <i className="bx bx-hide"></i>
                  </span>
                </div>
              </div>
              <button className="btn btn-primary w-100" disabled={loading}>
                {loading && (
                  <span
                    className="spinner-grow me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {loading ? `Connexion...` : `Se connecter`}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
