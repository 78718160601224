import React from "react";

const ResetPassword = () => {
  return (
    <div class="authentication-wrapper authentication-cover">
      {/* <!-- Logo --> */}
      {/* <a href="/#" class="app-brand auth-cover-brand gap-2">
        <span class="app-brand-text demo text-heading fw-bold">PoolIns</span>
      </a> */}
      {/* <!-- /Logo --> */}
      <div class="authentication-inner row m-0">
        {/* <!-- /Left Text -->  */}
        <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
          <div class="w-100 d-flex justify-content-center">
            <img
              src="assets/img/login.jpg"
              class="img-fluid scaleX-n1-rtl"
              alt="LoginImage"
              width="10000"
              data-app-dark-img="login.jpg"
              data-app-light-img="login.jpg"
            />
          </div>
        </div>
        {/* <!-- /Left Text --> */}

        {/* <!-- Reset Password --> */}
        <div class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-6 p-sm-12">
          <div class="w-px-400 mx-auto mt-12 pt-5">
            <h4 class="mb-1">Réinitialisation du mot de passe 🔒</h4>
            <p class="mb-6">
              <span class="fw-medium">
                Votre nouveau mot de passe doit être différent des mots de passe
                précedemment utilisés{" "}
              </span>
            </p>
            <form id="formAuthentication" class="mb-6">
              <div class="mb-6 form-password-toggle">
                <label class="form-label" for="password">
                  Nouveau mot de passe
                </label>
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    id="password"
                    class="form-control"
                    name="password"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="password"
                  />
                  <span class="input-group-text cursor-pointer">
                    <i class="bx bx-hide"></i>
                  </span>
                </div>
              </div>
              <div class="mb-6 form-password-toggle">
                <label class="form-label" for="confirm-password">
                  Confirmer le mot de passe
                </label>
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    id="confirm-password"
                    class="form-control"
                    name="confirm-password"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    aria-describedby="password"
                  />
                  <span class="input-group-text cursor-pointer">
                    <i class="bx bx-hide"></i>
                  </span>
                </div>
              </div>
              <button class="btn btn-primary d-grid w-100 mb-6">
                Configurer un nouveau mot de passe
              </button>
              <div class="text-center">
                <a href="auth-login-cover.html">
                  <i class="bx bx-chevron-left scaleX-n1-rtl me-1_5 align-top"></i>
                  Retour à la connexion
                </a>
              </div>
            </form>
          </div>
        </div>
        {/* <!-- /Reset Password --> */}
      </div>
    </div>
  );
};

export default ResetPassword;
