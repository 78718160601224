import { PlusOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Space, Spin, Switch, Table, Tag } from 'antd';
import frFR from "antd/locale/fr_FR";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import { rightsService } from '../../../services/rightsService';
import { API_URL, LOGGED_USER, TOKEN } from '../../../variables/constants';

const Courtiers = () => {
    const [courtiers, setCourtiers] = useState([]);
    const [contrats, setContrats] = useState(0);
    const [loadingCourtiers, setLoadingCourtiers] = useState(true);
    const [selecting, setSelecting] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const navigate = useNavigate();

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const columns = [
        {
            title: "Code intermédiaire",
            dataIndex: "codeAgence",
            key: "1",
        },
        {
            title: "Nom complet",
            dataIndex: "nom",
            key: "2",
        },
        {
            title: "Agréements",
            dataIndex: "agreements",
            key: "3",
            render: (_, record) => (
                <>
                    {LOGGED_USER?.profil?.organisme?.typeOrganisme === "POOL" ? record?.validAgrements?.length ? <> {record.validAgrements.map((va, id) => (<Tag color="blue" key={id}>
                        {va.compagnie.raisonSociale}
                    </Tag>))}  </> : "Aucun agréement validé" : validatedAgency(record) ? "Agréé" : "Non agréé"}
                </>
            )
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "4",
            render: (_, record) => (
                <>
                    <button
                        type="button"
                        class="btn btn-primary btn-icon rounded-pill"
                        onClick={() => { navigate(`/courtier/${record.codeId}`) }}
                    >
                        <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <button type="button" class="btn btn-danger btn-icon rounded-pill">
                        <i class="bx bxs-file-pdf"></i>
                    </button>
                </>
            ),
        },
    ];

    const onSelectChange = (newSelectedRowKeys, rows) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        console.log("SelectedRows", rows);
        setSelectedRows(rows);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const validatedAgency = (agency) => {
        let v = 0;
        v = agency?.validAgrements.findIndex(ag => ag.compagnie.code === LOGGED_USER?.profil?.organisme?.code);
        return v !== -1;
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        axios
            .get(`${API_URL}/api/agence/courtiers`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setCourtiers(res.data);
                setLoadingCourtiers(false);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/contrat`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let cons = [];
                cons = res.data.filter(e => e.courtier != null && e.statutContrat === "VALIDE");
                setContrats(cons.length);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [TOKEN]);


    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"COURTIERS"} />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row g-6 mb-6">
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Courtiers</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h4 class="mb-0 me-2">{courtiers?.length}</h4>
                                                        {/* <p class="text-success mb-0">(+29%)</p> */}
                                                    </div>
                                                    <small class="mb-0">Total courtiers</small>
                                                </div>
                                                <div class="avatar">
                                                    <span class="avatar-initial rounded bg-label-primary">
                                                        <i class="bx bx-group bx-lg"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Contrats</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h4 class="mb-0 me-2">{contrats}</h4>
                                                        {/* <p class="text-success mb-0">(+18%)</p> */}
                                                    </div>
                                                    <small class="mb-0">
                                                        Contrats rapportés par les courtiers
                                                    </small>
                                                </div>
                                                <div class="avatar">
                                                    <span class="avatar-initial rounded bg-label-danger">
                                                        <i class="bx bx-user-plus bx-lg"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">Contrats actifs</span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{activeContract}</h4>
                          </div>
                          <small class="mb-0">
                            Analyses de la semaine dernière
                          </small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-success">
                            <i class="bx bx-user-check bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                                {/* <div class="col-sm-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between">
                        <div class="content-left">
                          <span class="text-heading">Contrats en attente</span>
                          <div class="d-flex align-items-center my-1">
                            <h4 class="mb-0 me-2">{devis}</h4>
                          </div>
                          <small class="mb-0">
                            Analyses de la semaine dernière
                          </small>
                        </div>
                        <div class="avatar">
                          <span class="avatar-initial rounded bg-label-warning">
                            <i class="bx bx-user-voice bx-lg"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                            </div>
                            {/* <!-- Users List Table --> */}
                            <div class="row">
                                <div class="col-sm-4">
                                    {rightsService("HLA043") ? <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        style={{ marginBottom: 16 }}
                                        onClick={() => navigate("/ajouter-courtier")}
                                    >
                                        Nouveau courtier
                                    </Button> : null}
                                </div>
                            </div>

                            <div class="card">
                                <div class="container">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6">
                                                    <Space align="center" style={{ marginBottom: 16 }}>
                                                        Mode sélection:{" "}
                                                        <Switch
                                                            checked={selecting}
                                                            onChange={setSelecting}
                                                        />
                                                    </Space>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ConfigProvider locale={frFR}>
                                    <Spin spinning={loadingCourtiers}>
                                        {selecting ? (
                                            <Table
                                                rowSelection={rowSelection}
                                                columns={columns}
                                                dataSource={courtiers}
                                                onChange={handleChange}
                                            />
                                        ) : (
                                            <Table
                                                columns={columns}
                                                dataSource={courtiers}
                                                onChange={handleChange}
                                            />
                                        )}
                                    </Spin>
                                </ConfigProvider>
                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    )
}

export default Courtiers