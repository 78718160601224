import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import { LOGGED_USER } from "../variables/constants";
const { confirm } = Modal;

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!LOGGED_USER.lastPasswordChangeDate) {
      confirmPasswordChange();
    }
  }, [LOGGED_USER]);

  const confirmPasswordChange = () => {
    confirm({
      title: "Veuillez modifier votre passe avant votre prochaine connexion",
      icon: <ExclamationCircleFilled />,
      content: "Vous ne pourrez plus vous connecter en utilisant le même mot de passe prochainement. Veuillez le modifier en même temps.",
      okText: "Je change mon mot de passe!",
      okType: "danger",
      cancelText: "Je le fais après",
      onOk() {
        navigate("/first-connexion");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
        <SideBar currentMenu={"DASHBOARD"} />
        <div class="layout-page">
          <NavBar />
          <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row">
                <div class="col-xxl-8 mb-6 order-0">
                  <div class="card">
                    <div class="d-flex align-items-start row">
                      <div class="col-sm-7">
                        <div class="card-body">
                          <h5 class="card-title text-primary mb-3">
                            Bienvenu {LOGGED_USER?.personne?.prenom}{" "}
                            {LOGGED_USER?.personne?.nom}! 🎉
                          </h5>
                          <p class="mb-6">
                            PoolIns facilite toutes vos opérations et vous rends
                            plus productif.
                            <br />
                            Reprenez où vous vous étiez arrêter à la dernière
                            connexion si vous le souhaitez.
                          </p>
                          <a href="/#" class="btn btn-sm btn-outline-primary">
                            Reprendre le dernier dossier
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-5 text-center text-sm-left">
                        <div class="card-body pb-0 px-0 px-md-6">
                          <img
                            src="../assets/img/illustrations/man-with-laptop.png"
                            height="175"
                            class="scaleX-n1-rtl"
                            alt="View Badge User"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>
    </div>
  );
};

export default Home;
