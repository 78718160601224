import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import {getSinistreById} from "../../../../hooks/useHooks";
import {frenchDate} from "../../../../variables/functions";

const ResumeInformations = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        isValidated: () => {
            return;
        },
        sendState: () => {
            return;
        },
        state: {},
    }));

    const [sinistre, setSinistre] = useState({});

    let numeroPolice = "";
    props?.allStates?.assure &&
    (numeroPolice = props?.allStates?.assure?.infosAssure?.contrat.numeroPoliceM
        ? props?.allStates?.assure?.infosAssure?.contrat.numeroPoliceM
        : props?.allStates?.assure?.infosAssure?.contrat.numeroPoliceP);

    let nomAssure = "";
    props?.allStates?.assure &&
    (nomAssure = props?.allStates?.assure?.infosAssure?.contrat?.souscripteur
        ?.prenom
        ? `${props?.allStates?.assure?.infosAssure?.contrat?.souscripteur?.nom} ${props?.allStates?.assure?.infosAssure?.contrat?.souscripteur?.prenom}`
        : props?.allStates?.assure?.infosAssure?.contrat?.souscripteur?.nom);

    let dateSinistre = "";
    props?.allStates?.assure &&
    (dateSinistre = frenchDate(
        props?.allStates?.assure?.infosAssure?.dateSinistre
    ));

    let typeSinistre = "";
    props?.allStates?.assure &&
    (typeSinistre = props?.allStates?.assure?.infosAssure?.typeSinistre);

    const param = useParams();

    useEffect(() => {
        if (param.id) {
            getSinistreById(param.id)
                .then((res) => {
                    setSinistre(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [param.id]);

    return (
        <div className = {'grid-container grid container'} style={{
            justifyContent:'center'
        }}>
            <div className={'grid-item'} >
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td>Numéro Police : </td>
                            <td>
                                <b>
                                    {param.id ? sinistre?.contrat?.numeroPoliceP : numeroPolice}
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td>Nom et prénom(s) de l{"'"}assuré : </td>
                            <td>
                                <b>
                                    {param.id
                                        ? `${sinistre?.contrat?.souscripteur?.nom} ${sinistre?.contrat?.souscripteur?.prenom}`
                                        : nomAssure}
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td>Date de survenance du sinistre : </td>
                            <td>
                                <b>{param.id ? sinistre?.dateSinistre : dateSinistre}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Types de dommages : </td>
                            <td>
                                <b>{param.id ? sinistre?.typeSinistre : typeSinistre}</b>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

ResumeInformations.displayName = "ResumeInformations";

ResumeInformations.propTypes = {
    allStates: PropTypes.object,
};

export default ResumeInformations;
