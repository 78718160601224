import React, {useRef, useState} from "react";
import {CreditCardOutlined, EditOutlined, FilePdfOutlined, PlusOutlined} from "@ant-design/icons";
import {useReactToPrint} from "react-to-print";
import {frenchDate, frenchDateOnly} from "../../../../variables/functions";
import AttestationDeclarationSinistre from "../../docs/AttestationDeclarationSinistre";
import {CardBody} from "react-bootstrap";

import {
    Avatar,
    Button,
    Button as AntdButton,
    Card,
    Descriptions,
    Divider,
    Image,
    Input,
    List,
    Modal,
    Select,
    Space,
    Spin,
    Tabs,
    Tag
} from "antd";
import {useNavigate} from "react-router-dom";

const Details = ({
                     sinistre,
                     victimes,
                     captures,
                     setVictime,
                     setModalVictime,
                     setDescriptionDegatsVictimeState,
                     setProfessionState,
                     setAdresseState,
                     setPrenomState, name,
                     setNomState, addItem, onNameChange,
                     setModalFicheDemandeReglement,
                     setFicheDemandeReglement,
                     setModalQuittance,
                     setQuittance, setSelectedGaranties,
                     setModalOrdreDepense, setGarantieState,
                     setOrdreDepense, provisionGaranties, inputRef,
                     evaluations, setModalReevaluation, items, setMotifRevisionState,
                     montant, setEditMode, removeProvisionFormFields, setProvisionGarantie,
                     decaissements, setExpert, valueSelected, editMode, addProvisionFormFields,
                     setDecaissement, setModalAddExpert, initEvaluation, setValueSelected,
                     setModalAssignCheque, loadingSinistre, setEvaluation, handleChange,
                     experts, captured, editEvaluation, handleSubmit, garanties,
                     selectedGaranties, evaluation, modalReevaluation,
                 }) => {
    const navigate = useNavigate()
    const [tabId, setTabId] = useState('1')
    const onTabsChange = (key) => {
        setTabId(key);
    };

    const printReference = useRef();
    const printQReference = useRef();

    const exportToPdf = useReactToPrint({
        content: () => printReference.current,
    });
    useReactToPrint({
        content: () => printQReference.current,
    });


    const editVictime = (item) => {
        setEditMode(true);
        setVictime(item);
        setNomState("success");
        setPrenomState("success");
        setAdresseState("success");
        setProfessionState("success");
        setDescriptionDegatsVictimeState("success");
        setModalVictime(true);
    };

    const editExpert = (item) => {
        setEditMode(true);
        setExpert(item);
        setModalAddExpert(true);
    };

    const showOrdreDepense = (item) => {
        setOrdreDepense(item);
        setModalOrdreDepense(true);
    };

    const showQuittance = (item) => {
        setQuittance(item);
        setModalQuittance(true);
    };

    const showFicheDemandeReglement = (item) => {
        setFicheDemandeReglement(item);
        setModalFicheDemandeReglement(true);
    };


    return (<>
        <div className={'card'}>
            <Spin spinning={loadingSinistre}>
                <Tabs
                    onChange={onTabsChange}
                    type="card"
                    items={new Array(7).fill(null).map((_, i) => {
                        const id = String(i + 1);
                        const lb = i === 0 ? `Détails` : i === 1 ? `Déclaration` : i === 2 ? `Victimes` : i === 3 ? `Évaluations` : i === 4 ? `Reglements` : i === 5 ? `Experts` : `Documents et captures`;
                        const cd = i === 0 ? (<>
                            <div className="card-body">
                                <div
                                    className="container"
                                >
                                    <div className="grid-item">
                                        <>
                                            {sinistre?.sortDossier === "I" ? (<Tag color="green">EN
                                                INSTANCE</Tag>) : sinistre?.sortDossier === "T" ? (
                                                <Tag color="red">TERMINÉ</Tag>) : sinistre?.sortDossier === "S" ? (
                                                <Tag color="blue">SANS SUITE</Tag>) : (
                                                <Tag color="green">EN INSTANCE</Tag>)}
                                        </>
                                        <br/>
                                        <br/>
                                        <Descriptions
                                            title={`Sinistre N° - ${sinistre?.numSinistre ? sinistre?.numSinistre : "-"}`}
                                        >
                                            <Descriptions.Item label="Numéro de Police">
                                                {sinistre?.contrat?.souscripteur?.numeroPoliceM ? `${sinistre?.contrat?.numeroPoliceM}` : sinistre?.contrat?.numeroPoliceP ? `${sinistre?.contrat?.numeroPoliceP}` : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Assuré">
                                                {
                                                    sinistre?.contrat?.souscripteur?.prenom || sinistre?.contrat?.souscripteur?.nom
                                                        ? `${sinistre?.contrat?.souscripteur?.prenom || ''} ${sinistre?.contrat?.souscripteur?.nom || ''}`.trim()
                                                        : '-'
                                                }

                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Immatriculation engin">
                                                {sinistre?.engin?.immatriculation}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Provision actuelle">
                                                {sinistre?.evaluation ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation) : "0"}{" "}
                                                FCFA
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Sinistre à payer">
                                                {sinistre?.sap ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation - montant) : "0"}{" "}
                                                FCFA
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Montant réglé">
                                                {sinistre?.sap ? new Intl.NumberFormat("en-DE").format(montant) : "0"}{" "}
                                                FCFA
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                </div>
                            </div>
                        </>) : i === 1 ? (<>
                            <div className="card-body">
                                <div
                                    className="container"
                                >
                                    <div className="grid-item">
                                        <div id="mySinistre" ref={printReference}>
                                            {sinistre && (<AttestationDeclarationSinistre
                                                sinistre={sinistre}
                                            />)}
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <AntdButton
                                    onClick={() => {
                                        exportToPdf();
                                    }}
                                    type="primary"
                                    style={{
                                        background: "green",
                                    }}
                                    icon={<FilePdfOutlined/>}
                                >
                                    Générer PDF
                                    <br/>
                                    <br/>
                                    <br/>
                                </AntdButton>
                            </div>
                        </>) : i === 2 ? (<div className="card-body">
                            <div
                                className="container"
                                justify="center"
                            >
                                <div className="grid-item">
                                    {victimes.length ? (<List
                                        itemLayout="horizontal"
                                        dataSource={victimes}
                                        renderItem={(item, index) => (<List.Item
                                            actions={[<AntdButton
                                                type="primary"
                                                icon={<EditOutlined/>}
                                                onClick={() => editVictime(item)}
                                                key={item.evaluationId}
                                            ></AntdButton>,]}
                                        >
                                            <List.Item.Meta
                                                avatar={<Avatar
                                                    src={`https://ui-avatars.com/api/?name=${item?.personne?.prenom ? item?.personne?.nom[0] + item?.personne?.prenom[0] : item?.personne?.nom[0]}`}
                                                />}
                                                title={<a href="/#">
                                                    {`Victime N° ${index + 1}`}{" "}
                                                    {item?.adversaire ? "- Adversaire" : ""}
                                                </a>}
                                                description={<>
                                                    <p>
                                                        Nom :{" "}
                                                        {item?.personne?.prenom ? item?.personne?.nom + " " + item?.personne?.prenom : item?.personne?.nom}
                                                    </p>
                                                    <p>
                                                        Profession :{" "}
                                                        {item?.personne?.profession ? item?.personne?.profession : "-"}
                                                    </p>
                                                    <p>
                                                        Adresse :{" "}
                                                        {item?.personne?.adresse ? item?.personne?.adresse : "-"}
                                                    </p>
                                                    <p>
                                                        Description dommages
                                                        :{" "}
                                                        {item?.descriptionDegatsVictime ? item?.descriptionDegatsVictime : "-"}
                                                    </p>
                                                    <p>
                                                        Enregistrée le:{" "}
                                                        {frenchDate(item?.createdAt)}
                                                    </p>
                                                </>}
                                            />
                                        </List.Item>)}
                                    />) : ("Aucune victime engistrée sur ce dossier sinistre...")}
                                </div>
                            </div>
                        </div>) : i === 3 ? (
                            <div className="card-body">
                                <div className="col-sm-4">
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined/>}
                                        style={{marginBottom: 16}}
                                        onClick={() => navigate(`/evaluation-sinistre`, {
                                            state: {
                                                iz: sinistre.codeId
                                            }
                                        })}
                                    >
                                        Nouvelle evaluation
                                    </Button>
                                </div>
                                <div
                                    className="container"
                                    style={{
                                        justifyContent: 'center'
                                    }}>
                                    <div className="grid-item">
                                        {evaluations.length ? (<List
                                            itemLayout="horizontal"
                                            dataSource={evaluations}
                                            renderItem={(item) => (<List.Item
                                                actions={[<AntdButton
                                                    type="primary"
                                                    icon={<EditOutlined/>}
                                                    onClick={() => editEvaluation(item)}
                                                    key={item.evaluationId}
                                                ></AntdButton>,]}
                                            >
                                                <List.Item.Meta
                                                    avatar={<Avatar
                                                        src={`https://ui-avatars.com/api/?name=${item?.motifRevision.split(" ").length > 1 ? item?.motifRevision.split(" ")[0][0] + item?.motifRevision.split(" ")[1][0] : item?.motifRevision.split(" ")[0][0]}`}
                                                    />}
                                                    title={<a href="/#">
                                                        {item?.motifRevision ? item?.motifRevision : ""}
                                                    </a>}
                                                    description={<>
                                                        <p>
                                                            Ancienne
                                                            provision:{" "}
                                                            <b>
                                                                {`${new Intl.NumberFormat("en-DE").format(Math.trunc(item?.ancienneProvision))}`}{" "}
                                                                FCFA
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Nouvelle
                                                            provision:{" "}
                                                            <b>
                                                                {`${new Intl.NumberFormat("en-DE").format(Math.trunc(item?.nouvelleProvision))}`}{" "}
                                                                FCFA
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Reévaluée le:{" "}
                                                            <b>
                                                                {frenchDate(item?.createdAt)}
                                                            </b>
                                                        </p>
                                                    </>}
                                                />
                                            </List.Item>)}
                                        />) : ("Aucune reévaluation effectuée sur ce dossier sinistre...")}
                                    </div>
                                </div>
                            </div>) : i === 4 ? (<div className="card-body">
                            <div
                                className="container"
                                justify="center"
                            >
                                <div className="grid-item">
                                    {decaissements.length ? (<List
                                        itemLayout="horizontal"
                                        dataSource={decaissements}
                                        renderItem={(item) => (<List.Item
                                            actions={[item.numeroCheque ? (<AntdButton
                                                type="primary"
                                                onClick={() => showQuittance(item)}
                                                key={item.evaluationId}
                                            >
                                                Q
                                            </AntdButton>) : (<Button
                                                justIcon
                                                round
                                                simple
                                                key={item.decaissementId}
                                                onClick={() => {
                                                    setDecaissement(item);
                                                    setModalAssignCheque(true);
                                                    console.log("Item", item);
                                                }}
                                                color="success"
                                                className="like"
                                            >
                                                <CreditCardOutlined/>
                                            </Button>), <AntdButton
                                                type="primary"
                                                onClick={() => showOrdreDepense(item)}
                                                key={item.evaluationId}
                                            >
                                                OD
                                            </AntdButton>, <AntdButton
                                                type="primary"
                                                onClick={() => showFicheDemandeReglement(item)}
                                                key={item.evaluationId}
                                            >
                                                FDR
                                            </AntdButton>,]}
                                        >
                                            <List.Item.Meta
                                                avatar={<Avatar
                                                    src={`https://ui-avatars.com/api/?name=D`}
                                                />}
                                                title={<a href="/#">Décaissement</a>}
                                                description={<>
                                                    <p>
                                                        Montant TTC :{" "}
                                                        {`${new Intl.NumberFormat("en-DE").format(Math.trunc(item?.montantGlobal))}`}{" "}
                                                        FCFA
                                                    </p>
                                                    <p>
                                                        Montant retenu:{" "}
                                                        {item?.montantPaye ? `${new Intl.NumberFormat("en-DE").format(Math.trunc(item?.montantGlobal - item?.montantPaye))}` : "0"}{" "}
                                                        FCFA
                                                    </p>
                                                    <p>
                                                        Date de
                                                        règlement:{" "}
                                                        {frenchDateOnly(item?.dateReglement)}
                                                    </p>
                                                </>}
                                            />
                                        </List.Item>)}
                                    />) : ("Aucun reglement effectué sur ce dossier sinistre...")}
                                </div>
                            </div>
                        </div>) : i === 5 ? (<div className="card-body">
                            <div
                                className="container"
                                justify="center"
                            >
                                <div className="grid-item">
                                    {experts.length ? (<List
                                        itemLayout="horizontal"
                                        dataSource={experts}
                                        renderItem={(item, index) => (<List.Item
                                            actions={[<AntdButton
                                                type="primary"
                                                icon={<EditOutlined/>}
                                                onClick={() => editExpert(item)}
                                                key={item.evaluationId}
                                            ></AntdButton>,]}
                                        >
                                            <List.Item.Meta
                                                avatar={<Avatar
                                                    src={`https://ui-avatars.com/api/?name=${item?.prenom ? item?.nom[0] + item?.prenom[0] : item?.nom[0]}`}
                                                />}
                                                title={<a href="/#">
                                                    {`Expert N° ${index + 1}`}
                                                </a>}
                                                description={<>
                                                    <p>
                                                        Nom :{" "}
                                                        {item?.prenom ? item?.nom + " " + item?.prenom : item?.nom}
                                                    </p>
                                                    <p>
                                                        Profession :{" "}
                                                        {item.profession ? item?.profession : "-"}
                                                    </p>
                                                    <p>
                                                        Adresse :{" "}
                                                        {item?.adresse ? item?.adresse : "-"}
                                                    </p>
                                                    <p>
                                                        Enregistré le:{" "}
                                                        {frenchDate(item?.createdAt)}
                                                    </p>
                                                </>}
                                            />
                                        </List.Item>)}
                                    />) : ("Aucun expert enregistré sur ce dossier sinistre...")}
                                </div>
                            </div>
                        </div>) : (<div className="card-body">
                            <div
                                className="container"
                                justify="center"
                            >
                                <div className="grid-item">
                                    <div className="card-body">
                                        <div>
                                            {Array.isArray(captures) && captures?.length ? captures?.map((imageUrl, index) => (
                                                <div className={'grid'}
                                                     key={index}
                                                >
                                                    <Image
                                                        key={index}
                                                        src={captured[index]?.url}
                                                        alt={`Capture ${index}`}
                                                        style={{margin: "8px"}}
                                                    />
                                                </div>)) : "Aucun document enregistré sur ce dossier sinistre..."}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>);
                        return {
                            label: lb, key: id, children: cd,
                        };
                    })}
                    activeKey={tabId}
                />
            </Spin>

        </div>
        <Modal
            title={
                editMode ? "MISE À JOUR RÉEVALUATION" : "REÉVALUATION"
            }
            centered
            open={modalReevaluation}
            onOk={(e) => handleSubmit(e, false)}
            onCancel={() => {
                setEvaluation(initEvaluation);
                setModalReevaluation(false);
            }}
        >
            <Card>
                <CardBody>
                    <div className={' grid container'}>
                        <div className={'grid-item'}>
                            <div>
                                <b>Ancienne provision :</b>{" "}
                                <Tag color="blue">
                                    <b>
                                        {sinistre?.evaluation
                                            ? new Intl.NumberFormat("en-DE").format(
                                                sinistre?.evaluation
                                            )
                                            : "0"}{" "}
                                        FCFA
                                    </b>
                                </Tag>
                            </div>
                            <br/>

                            <div className={' grid container'} justify="center">
                                <div className={'grid-item'} lg={12}>
                                    <div className={'form-control'}


                                    >
                                        <select
                                            className="select2 form-select"
                                            value={valueSelected}
                                            onChange={(value) => {
                                                let vs = valueSelected;
                                                if (value.length > vs.length) {
                                                    addProvisionFormFields();
                                                } else {
                                                    let outGarantie = value.filter(
                                                        (v) => !garanties.includes(v)
                                                    );
                                                    // console.log(outGarantie);

                                                    outGarantie &&
                                                    removeProvisionFormFields(
                                                        outGarantie[0]
                                                    );
                                                }
                                                var gs = [];
                                                value.forEach((v) => {
                                                    let g = garanties.find(
                                                        (gar) => gar.value === v
                                                    );
                                                    g && gs.push(g.garantie);
                                                });
                                                setValueSelected(value);
                                                setSelectedGaranties(gs);
                                                // console.log("Target...", value);
                                                value.length
                                                    ? setGarantieState("success")
                                                    : setGarantieState("error");
                                            }}
                                            /* options={garanties}
                                             placeholder="Sélectionnez des garanties"*/
                                        />
                                    </div>
                                </div>
                            </div>
                            {selectedGaranties.length ? (
                                <div className={' grid container'} sx={{
                                    justifyContent: 'center'
                                }}>
                                    {selectedGaranties.map((sg, index) => (
                                        <div className={'grid-item'}
                                             key={index}
                                        >
                                            <label
                                                htmlFor="capital"
                                                style={{
                                                    margin: "30px 0 10px 0",
                                                }}
                                            >
                                                Provision Garantie {sg.nom} :
                                            </label>
                                            <Input
                                                id="capital"
                                                name="capital"
                                                placeholder="Veuillez entrer la valeur de la provision"
                                                value={
                                                    provisionGaranties[index].provision
                                                }
                                                onChange={(event) => {
                                                    let newProvisionFormFields = [
                                                        ...provisionGaranties,
                                                    ];
                                                    newProvisionFormFields[
                                                        index
                                                        ].garantie = sg;
                                                    newProvisionFormFields[
                                                        index
                                                        ].provision = event.target.value;
                                                    setProvisionGarantie(
                                                        newProvisionFormFields
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            <br/>
                            <div>
                                <b>Nouvelle provision :</b>{" "}
                                <Tag color="green">
                                    <b>
                                        {evaluation?.nouvelleProvision
                                            ? new Intl.NumberFormat("en-DE").format(
                                                evaluation?.nouvelleProvision
                                            )
                                            : "0"}{" "}
                                        FCFA
                                    </b>
                                </Tag>
                            </div>

                            <br/>
                            <div className={'form-control'}
                            >
                                <Select
                                    id="motifRevision"
                                    name="motifRevision"
                                    placeholder="Motif de la révision"
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: "8px 0",
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: "0 8px 4px",
                                                }}
                                            >
                                                <Input
                                                    placeholder="Ajouter un motif"
                                                    ref={inputRef}
                                                    value={name}
                                                    onChange={onNameChange}
                                                />
                                                <Button
                                                    type="text"
                                                    icon={<PlusOutlined/>}
                                                    onClick={addItem}
                                                >
                                                    Ajouter un motif
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                    options={items.map((item) => ({
                                        label: item,
                                        value: item,
                                    }))}
                                    onChange={(value) => {
                                        handleChange(value, "motifRevision");
                                        console.log(value);
                                        if (value.trim().length > 3) {
                                            setMotifRevisionState("success");
                                        } else {
                                            setMotifRevisionState("error");
                                        }
                                    }}
                                    value={evaluation.motifRevision}
                                />
                            </div>
                            <br/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Modal>

    </>)
}

export default Details