import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Modal, Spin, Table, Tag } from 'antd';
import frFR from "antd/locale/fr_FR";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import { API_URL, TOKEN } from '../../../variables/constants';

const Commandes = () => {
    const [commandes, setCommandes] = useState([]);
    const [loadingOrders, setLoadingOrders] = useState(true);
    const [orderModalOpen, setOrderModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState(0);
    const [selectedCategorie, setSelectedCategorie] = useState("M");
    const [selectedTypeAttestation, setSelectedTypeAttestation] = useState("SIMPLE");
    const [commentaire, setCommentaire] = useState("");
    const [reload, setReload] = useState(false);

    const columns = [
        {
            title: "Compagnie",
            dataIndex: "compagnie",
            key: "1",
            render: (_, record) => (
                <>{record?.emetteur?.profil?.organisme?.raisonSociale} </>
            ),
        },
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "2",
        },
        {
            title: "Type d'attestation",
            dataIndex: "typeAttestation",
            key: "3",
            render: (_, record) => (
                <>{record.typeAttestation} - {record?.categorie?.nom}</>
            ),
        },

        {
            title: "Statut commande",
            dataIndex: "statutCommande",
            key: "4",
            render: (_, record) => (
                <Tag color="blue">{record.statutCommande}</Tag>
            ),
        },
        {
            title: "Action",
            dataIndex: "actions",
            key: "actions",
            render: (_, record) => (
                <>
                    <button
                        type="button"
                        class="btn btn-success btn-icon rounded-pill"
                    // onClick={() => encaisserContrat(record.codeId)}
                    >
                        <i class="bx bx-edit-alt"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary btn-icon rounded-pill"
                        onClick={() => showValidationAttestationConfirm(record.codeId)}
                    >
                        <i class="bx bx-check-circle"></i>
                    </button>
                </>
            ),
        },
    ];

    useEffect(() => {
        axios
            .get(`${API_URL}/api/bon-commande`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log("Res: ", res.data);
                res.data.reverse();
                setCommandes(res.data);
                setLoadingOrders(false);
            })
            .catch((err) => {
                console.log(err);
                setLoadingOrders(false);
            });
        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                setCategories(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [TOKEN, reload]);

    const onSubmitCommandeAttestation = () => {
        if (nombre) {
            const catg = categories.find(element => element.codeCategorie === selectedCategorie);
            let bonCommande = {
                commentaire: commentaire,
                nombre: nombre,
                categorie: catg,
                typeAttestation: selectedTypeAttestation,
                statutCommande: "INITIE"
            }
            console.log("Nombre: ", bonCommande);
            axios
                .post(`${API_URL}/api/bon-commande`, JSON.stringify(bonCommande), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log("Data Ressst", res);
                    if (res.status === 226) {
                        toast("Cet numéro d'attestation existe déjà!");
                    } else {
                        setReload(true);
                        toast("Lot d'attestation ajoutée avec succès");
                    }
                    setLoading(false);
                    setOrderModalOpen(false);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 226) {
                        toast("Cet numéro d'attestation existe déjà!");
                    } else {
                        toast("Erreur lors de l'ajout du lot d'attestation");
                    }
                    setLoading(false);
                });
        } else {
            toast("Veuillez renseigner le nombre d'attestation que vous voulez commander!");
        }
    }

    const showValidationAttestationConfirm = (lotA) => {
        Modal.confirm({
            title: "Etes-vous sûr de vouloir valider la commande?",
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                validation(lotA);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const validation = (lotA) => {
        axios
            .get(`${API_URL}/api/bon-commande/validate/${lotA}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            {/* <!-- Users List Table --> */}
                            <div class="row">
                                <div class="col-sm-4">
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        style={{ marginBottom: 16 }}
                                        onClick={() => setOrderModalOpen(true)}
                                    >
                                        Nouvelle commande
                                    </Button>
                                </div>
                            </div>
                            <div class="card">
                                <ConfigProvider locale={frFR}>
                                    <Spin spinning={loadingOrders}>
                                        <Table
                                            columns={columns}
                                            dataSource={commandes}
                                        />
                                    </Spin>
                                </ConfigProvider>
                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <Modal open={orderModalOpen} loading={loading} onOk={onSubmitCommandeAttestation} onCancel={() => setOrderModalOpen(false)} zIndex={1900} centered>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Commande de lot d'attestations </h4>
                    <p className="address-subtitle">
                        Effectuer une commande de lot(s) d'attestations
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-5" onSubmit={onSubmitCommandeAttestation}>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="firstAttestation">
                            Veuillez entrer le nombre d'attestation
                        </label>
                        <input
                            className="form-control" id="nombre" value={nombre} name="nombre"
                            onChange={(e) => {
                                setNombre(parseInt(e.target.value));
                            }}
                            placeholder="Entrez le nombre d'attestations que vous voulez commander" type="number" />
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="agence">
                            Veuillez sélectionner le type d'attestation
                        </label>
                        <select className="form-control" id="typeAttestation" value={selectedTypeAttestation} name="typeAttestation" onChange={(e) => setSelectedTypeAttestation(e.target.value)} placeholder="Sélectionner un type d'attestation">
                            <option value="SIMPLE"> SIMPLE </option>
                            <option value="CEDEAO"> CEDEAO </option>
                        </select>
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="agence">
                            Veuillez sélectionner la catégorie
                        </label>
                        <select className="form-control" id="agence" value={selectedCategorie} name="agence" onChange={(e) => setSelectedCategorie(e.target.value)} placeholder="Sélectionner une agence">
                            {categories?.map((cat, id) => (
                                <option key={id} value={cat.codeCategorie}> {cat.nom} </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="commentaire">
                            Un commentaire?
                        </label>
                        <textarea
                            className="form-control" id="commentaire" value={commentaire} name="commentaire"
                            onChange={(e) => {
                                setCommentaire(e.target.value);
                            }}
                            placeholder="Un commentaire pour des précisions sur la commande"
                            rows={3}
                        >
                        </textarea>
                    </div>
                </form>
            </Modal>
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    );
}

export default Commandes