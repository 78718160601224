import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";

const ParametresTarification = () => {
  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row mb-12 g-6">
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de garanties</h5>
                      <p class="card-text">
                        Paramétrer les garanties en définissant pour chacune
                        d'elle la valeur de la prime en fonction des
                        caractéristiques de l'engin et le capital
                        d'indemnisation
                      </p>
                      <a href="/garanties" class="btn btn-primary">
                        Définir une nouvelle garantie
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de réductions</h5>
                      <p class="card-text">
                        Paramétrer les réductions en définissant pour chacune
                        d'elle le taux de réduction, les garanties sur
                        lesquelles elle s'applique
                      </p>
                      <a href="/reductions" class="btn btn-primary">
                        Définir une nouvelle réduction
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de conversions</h5>
                      <p class="card-text">
                        Paramétrer les conversions d'énergie afin de permettre
                        le calcul de la Garantie Responsabilité civile quelque
                        soit la source d'énergie de l'engin
                      </p>
                      <a href="/parametres/conversion" class="btn btn-primary">
                        Définir une nouvelle conversion
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de catégories</h5>
                      <p class="card-text">
                        Paramétrer les différentes catégories d'engins pris en
                        charge par le POOL TPVM-VT GIE
                        <br />
                      </p>
                      <a href="/categories" class="btn btn-primary">
                        Définir une nouvelle catégorie
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage de fractionnement</h5>
                      <p class="card-text">
                        Paramétrer les différents fractionnements applicables
                        sur un contrat
                        <br />
                      </p>
                      <a
                        href="/parametres/fractionnements"
                        class="btn btn-primary"
                      >
                        Définir un nouveau fractionnement
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des avenants</h5>
                      <p class="card-text">
                        Paramétrer les différents avenants applicables sur un
                        contrat avec les codes.
                        <br />
                      </p>
                      <a href="/parametres/avenants" class="btn btn-primary">
                        Définir un nouvel avenant
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des carosseries</h5>
                      <p class="card-text">
                        Paramétrer les différents carosseries d'engins suivant
                        leur catégorie
                        <br />
                      </p>
                      <a href="/parametres/carosseries" class="btn btn-primary">
                        Définir une nouvelle carosserie
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des usages</h5>
                      <p class="card-text">
                        Paramétrer les différents usages d'engins suivant leur
                        catégorie
                        <br />
                      </p>
                      <a href="/parametres/usages" class="btn btn-primary">
                        Définir un nouvel usage
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des marques</h5>
                      <p class="card-text">
                        Paramétrer les différentes marques d'engins suivant leur
                        catégorie
                        <br />
                      </p>
                      <a href="/parametres/marques" class="btn btn-primary">
                        Définir une nouvelle marque
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des accessoires</h5>
                      <p class="card-text">
                        Paramétrer les différents accessoires d'engins suivant
                        leur catégorie
                        <br />
                      </p>
                      <a href="/parametres/accessoires" class="btn btn-primary">
                        Définir des frais d'accessoires
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des taux de commissions</h5>
                      <p class="card-text">
                        Paramétrer les taux de commissions sur les contrats suivant les catégories et les types d'apporteurs
                        <br />
                      </p>
                      <a href="/parametres/taux-commissions" class="btn btn-primary">
                        Définir un taux de commission
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Paramétrage des taxes</h5>
                      <p class="card-text">
                        Paramétrer les différentes taxes suivant les garanties
                        sur lesquells ils sont applicables
                        <br />
                      </p>
                      <a href="/parametres/taxes" class="btn btn-primary">
                        Définir une taxe
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default ParametresTarification;