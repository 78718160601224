import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Dropdown, Menu } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import axios from "axios";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddProfession from "./AddProfession";
import { toast } from "react-toastify";

const Professions = () => {
  const [professions, setProfessions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedProfession, setSelectedProfession] = useState(null);

  const fetchProfessions = () => {
    axios
      .get(`${API_URL}/api/parametre/type/PROFESSION`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => setProfessions(res.data))
      .catch((err) => console.error("Error fetching professions:", err));
  };

  useEffect(() => {
    fetchProfessions();
  }, [showModal]);

  const handleShowModal = (edit = false, profession = null) => {
    setShowModal(true);
    setEditMode(edit);
    setSelectedProfession(profession);
  };

  const handleDeleteProfession = (parametreId) => {
    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette profession ?",
      content: "Cette action est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk: () => {
        axios
          .delete(`${API_URL}/api/parametre/${parametreId}`, {
            headers: { Authorization: `Bearer ${TOKEN}` },
          })
          .then(() => {
            setProfessions((prev) =>
              prev.filter((profession) => profession.parametreId !== parametreId)
            );
            toast.success("Profession supprimée avec succès.");
          })
          .catch((err) => {
            console.error("Error deleting profession:", err);
            toast.error("Erreur lors de la suppression de la profession.");
          });
      },
    });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu="AUTRES_PARAMETRES" />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShowModal()}
              >
                Ajouter une nouvelle profession
              </Button>
              <div className="card">
                <h5 className="card-header">Paramétrage des professions</h5>
                <Table
                  rowKey="parametreId"
                  columns={[
                    {
                      title: "Nom",
                      dataIndex: "libelleParametre",
                      key: "libelleParametre",
                    },
                    {
                      title: "Actions",
                      key: "actions",
                      render: (_, profession) => (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                icon={<EditOutlined />}
                                onClick={() => handleShowModal(true, profession)}
                              >
                                Modifier
                              </Menu.Item>
                              <Menu.Item
                                icon={<DeleteOutlined />}
                                onClick={() => handleDeleteProfession(profession.parametreId)}
                                danger
                              >
                                Supprimer
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['click']}
                        >
                          <Button type="text" icon={<EllipsisOutlined />} />
                        </Dropdown>
                      ),
                    },
                  ]}
                  dataSource={professions}
                  pagination={false}
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <AddProfession
        show={showModal}
        handleClose={() => setShowModal(false)}
        editMode={editMode}
        selectedProfession={selectedProfession}
      />
    </div>
  );
};

export default Professions;
