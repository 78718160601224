import { Image, Tag, Watermark } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import entete from "../../../assets/img/entete.jpg";
import footer from "../../../assets/img/footer.png";
import { API_URL, TOKEN } from "../../../variables/constants";
import { frenchDate, frenchDateSimple } from "../../../variables/functions";

// const styles = {
//   fontFamily: "Arial, sans-serif",
//   fontSize: "11px",
//   color: "black",
// };

const DTRFFormatProposition = React.forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [flotteData, setFlotteData] = useState([]);
  const [date, setDate] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  useEffect(() => {
    // Get all sous garanties by categorie and puissance

    // console.log("Props: ", props);
    var dt = [];
    if (props?.contrat?.engins?.length > 1) {
      props?.contrat?.engins?.forEach((engin, codeId) => {
        var risk = "";
        var pn = 0.0;
        engin.sousGaranties.forEach((eng) => {
          let pNette = eng.primeNette
            ? eng.primeNette
            : eng.tauxPCent
              ? (eng.tauxPCent * engin.valeurDeclaree) / 100
              : eng.tauxPMille
                ? (eng.tauxPMille * engin.valeurDeclaree) / 1000
                : 0.0;
          risk += `${eng.garantie.nom === "Individuel Conducteur"
            ? eng.nom
            : eng.garantie.nom
            } | ${pNette};`;
          pn += parseFloat(pNette);
        });

        var puissance = engin?.puissance
          ? engin?.puissance
          : engin?.volume
            ? engin?.volume
            : 0;

        axios
          .get(
            `${API_URL}/api/sous-garantie/compulsory-guaranties/${puissance}/${props?.contrat?.engins[0]?.categorie?.categorieId}`,
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            }
          )
          .then((res) => {
            // console.log("Dataaaaa: ", res.data);

            res.data.forEach((eng) => {
              let psNette =
                eng && eng?.primeNette
                  ? eng?.primeNette
                  : eng?.tauxPCent
                    ? (eng?.tauxPCent * engin.valeurDeclaree) / 100
                    : eng?.tauxPMille
                      ? (eng?.tauxPMille * engin.valeurDeclaree) / 1000
                      : 0.0;
              eng &&
                (risk += `${eng?.nom === "Individuel Conducteur" ? eng?.nom : eng?.nom
                  } | ${psNette};`);
              eng && (pn += parseFloat(psNette));
            });
            // console.log("rn: ", risk, "pn: ", pn, codeId);
            dt.push({
              key: `${codeId}`,
              numero: `${codeId + 1}`,
              immatriculation: `${engin?.immatriculation}`,
              risques: `${risk.substring(0, risk.length - 1)}`,
              primeNette: `${new Intl.NumberFormat("en-DE").format(pn)} F CFA`,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
      setTimeout(() => {
        setFlotteData(dt);
      }, 300);
    } else {
      var dts = [];
      var puissance = 0;
      props.contrat?.engins &&
        (puissance = props?.contrat?.engins[0].puissance
          ? props?.contrat?.engins[0].puissance
          : props?.contrat?.engins[0].volume
            ? props?.contrat?.engins[0].volume
            : 0);

      var categorieId = 0;
      props.contrat?.engins &&
        (categorieId = props?.contrat?.engins[0]?.categorie?.categorieId);
      axios
        .get(
          `${API_URL}/api/sous-garantie/compulsory-guaranties/${puissance}/${categorieId}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((res) => {
          // console.log("Dataaaaa: ", res.data);
          res.data.forEach((cg, cgi) => {
            dts.push({
              key: `${cgi + dts.length}`,
              garantie: `${cg?.nom}`,
              capital: cg?.capital
                ? cg?.nom !== "RC"
                  ? `${new Intl.NumberFormat("en-DE").format(cg?.capital)}`
                  : "-"
                : "-",
              franchise: 0,
              bns: 0,
              reduc_comm: 0,
              reduc_flotte: 0,
              prime_nette: `${new Intl.NumberFormat("en-DE").format(
                cg?.primeNette
              )}`,
            });
          });

          props?.contrat?.engins?.forEach((eng) => {
            eng.sousGaranties.forEach((sousGarantie, idx) => {
              if (
                sousGarantie?.garantie?.nom === "Incendie" ||
                sousGarantie?.garantie?.nom === "Vol"
              ) {
                var pNette = sousGarantie?.primeNette
                  ? sousGarantie?.primeNette
                  : sousGarantie?.tauxPCent
                    ? (sousGarantie?.tauxPCent * eng.valeurDeclaree) / 100
                    : eng.tauxPMille
                      ? (sousGarantie?.tauxPMille * eng.valeurDeclaree) / 1000
                      : 0.0;

                dts.push({
                  key: `${idx + dts.length}`,
                  garantie: `${sousGarantie?.garantie?.nom === "Individuel Conducteur"
                    ? sousGarantie?.nom
                    : sousGarantie?.garantie?.nom
                    }`,
                  capital: sousGarantie.tauxPCent
                    ? `${new Intl.NumberFormat("en-DE").format(
                      (sousGarantie.primeNette * 100) / sousGarantie.tauxPCent
                    )}`
                    : sousGarantie.tauxPMille
                      ? `${new Intl.NumberFormat("en-DE").format(
                        (sousGarantie.primeNette * 1000) /
                        sousGarantie.tauxPMille
                      )}`
                      : "-",
                  franchise: 0,
                  bns: 0,
                  reduc_comm: 0,
                  reduc_flotte: 0,
                  prime_nette: `${new Intl.NumberFormat("en-DE").format(
                    pNette
                  )}`,
                });
              } else {
                dts.push({
                  key: `${idx + dts.length}`,
                  garantie: `${sousGarantie?.garantie?.nom === "Individuel Conducteur"
                    ? sousGarantie?.nom
                    : sousGarantie?.garantie?.nom
                    }`,
                  capital: sousGarantie.tauxPCent
                    ? `${new Intl.NumberFormat("en-DE").format(
                      (sousGarantie.primeNette * 100) / sousGarantie.tauxPCent
                    )}`
                    : sousGarantie.tauxPMille
                      ? `${new Intl.NumberFormat("en-DE").format(
                        (sousGarantie.primeNette * 1000) /
                        sousGarantie.tauxPMille
                      )}`
                      : "-",
                  franchise: 0,
                  bns: 0,
                  reduc_comm: 0,
                  reduc_flotte: 0,
                  prime_nette: `${new Intl.NumberFormat("en-DE").format(
                    sousGarantie.primeNette
                  )}`,
                });
              }
            });
          });
          setData(dts);
          console.log("DTS: ", dts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    let dte = new Date();
    setDate(dte.toISOString());
    if (props?.contrat?.intermediaire) {
      if (props?.contrat?.intermediaire?.agence?.signature) {
        fetch(
          `${API_URL}/api/agence/signature/${props?.contrat?.intermediaire?.agence?.signature.split("//")[2]}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
          .then(async (response) => {
            console.log("The response", response);
            // convert byte to file
            const imageBytes = await response.arrayBuffer();
            console.log("images byte: ", imageBytes);
            var blob = new Blob([imageBytes], { type: "image/png" });
            console.log("Blob: ", blob);
            var imageUrl = URL.createObjectURL(blob);
            console.log("Image URL: ", imageUrl);
            setImagePreviewUrl(imageUrl);
            var file = new File([blob], props?.contrat?.intermediaire?.agence?.signature.split("//")[2], {
              type: "image/png",
            });
            console.log("File: ", file);

            setTimeout(() => {
              console.log("Preview: ", { imagePreviewUrl });
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (props?.contrat?.producteur != null && props?.contrat?.producteur?.agence?.signature) {
      fetch(
        `${API_URL}/api/agence/signature/${props?.contrat?.producteur?.agence?.signature.split("//")[2]}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
        .then(async (response) => {
          console.log("The response", response);
          // convert byte to file
          const imageBytes = await response.arrayBuffer();
          console.log("images byte: ", imageBytes);
          var blob = new Blob([imageBytes], { type: "image/png" });
          console.log("Blob: ", blob);
          var imageUrl = URL.createObjectURL(blob);
          console.log("Image URL: ", imageUrl);
          setImagePreviewUrl(imageUrl);
          var file = new File([blob], props?.contrat?.producteur?.agence?.signature.split("//")[2], {
            type: "image/png",
          });
          console.log("File: ", file);

          setTimeout(() => {
            console.log("Preview: ", { imagePreviewUrl });
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props?.contrat, imagePreviewUrl]);

  var bareme = "";
  props.contrat?.fractionnement &&
    (bareme =
      props.contrat.fractionnement?.moisMin === 12
        ? "ANNUEL"
        : props.contrat.fractionnement?.moisMin === 6
          ? "SEMESTRIEL"
          : props.contrat.fractionnement?.moisMin === 3
            ? "TRIMESTRIEL"
            : "BIMENSUEL");

  var duree =
    props.contrat.fractionnement &&
    `${props.contrat.fractionnement.moisMin} Mois`;

  // const pbStyles = {
  //   pageBreak: {
  //     pageBreakBefore: "always",
  //   },
  // };

  // const columns = [
  //   {
  //     title: "Garanties",
  //     dataIndex: "garantie",
  //     render: (_, { garantie }) => (
  //       <>
  //         {garantie === "Individuel Conducteur" &&
  //           props?.contrat?.engins[0].categorie.codeCategorie === "M" ? (
  //           <table>
  //             <tr>
  //               <td> Individuel Conducteur</td>
  //               <td>
  //                 {" "}
  //                 <tr>
  //                   <td>* Décès :</td>
  //                   <td></td>
  //                 </tr>
  //                 <tr>
  //                   <td>* Invalidité :</td>
  //                   <td></td>
  //                 </tr>
  //                 <tr>
  //                   <td>* Frais Médicaux :</td>
  //                   <td></td>
  //                 </tr>
  //               </td>
  //             </tr>
  //           </table>
  //         ) : (
  //           garantie
  //         )}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Capitaux",
  //     dataIndex: "capital",
  //     render: (_, { garantie, capital }) => (
  //       <>
  //         {garantie === "Individuel Conducteur" &&
  //           props?.contrat?.engins[0].categorie.codeCategorie === "M" ? (
  //           <table>
  //             <tr>
  //               <td></td>
  //               <td>
  //                 {" "}
  //                 <tr>
  //                   <td></td>
  //                   <td> 250.000</td>
  //                 </tr>
  //                 <tr>
  //                   <td></td>
  //                   <td> 500.000</td>
  //                 </tr>
  //                 <tr>
  //                   <td></td>
  //                   <td> 50.000</td>
  //                 </tr>
  //               </td>
  //             </tr>
  //           </table>
  //         ) : (
  //           capital
  //         )}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Franchise",
  //     dataIndex: "franchise",
  //   },
  //   {
  //     title: "Prime",
  //     dataIndex: "prime_nette",
  //     render: (_, { prime_nette }) => (
  //       <div class="container">
  //         <div class="row">
  //           <div class="col-12 col-md-12">{/* <!-- Empty column --> */}</div>
  //           <div class="text-end">{prime_nette}</div>
  //         </div>
  //       </div>
  //     ),
  //   },
  // ];

  const reduData = [
    {
      key: "1",
      libelle: "Fractionnement :",
      montant: props.contrat?.fractionnement ? props.contrat?.fractionnement?.nombre : 0,
    },
    {
      key: "2",
      libelle: "Réduction flotte",
      montant: "0",
    },
    {
      key: "3",
      libelle: "BNS",
      montant: "0",
    },
    {
      key: "4",
      libelle: "Reduction Exeptionnelle",
      montant: "0",
    },
  ];

  const primeData = [
    {
      key: "1",
      libelle: "Prime Nette",
      montant: `${new Intl.NumberFormat("en-DE").format(
        Math.trunc(props?.contrat.primeNette)
      )}`,
    },
    {
      key: "2",
      libelle: "Coût de pièces",
      montant: `${new Intl.NumberFormat("en-DE").format(
        props?.contrat.accessoires
      )}`,
    },
    {
      key: "3",
      libelle: "Taxes",
      montant: `6% (${new Intl.NumberFormat("en-DE").format(
        Math.trunc((props?.contrat.primeTotale * 0.06) / 1.06)
      )})`,
    },
    {
      key: "4",
      libelle: "Prime Totale",
      montant: `${new Intl.NumberFormat("en-DE").format(
        Math.trunc(props?.contrat.primeTotale)
      )}`,
    },
  ];

  return (
    <>
      {props?.contrat.engins && props?.contrat.engins.length === 1 ? (
        <div className="col-12" ref={ref}>
          <Watermark content="PROPOSITION" >
            <div className="card">
              <Image src={entete} width={"100%"} height={"105px"} />
              <div className="" style={{ padding: "1rem 1rem", }}>
                <div className="">
                  <div className="row">
                    <div className="col-12">
                      <table
                        className=""
                        style={{ width: "100%" }}
                      >
                        <tbody>
                          <tr>
                            <td className="text-left">
                              <strong>Site :</strong>
                            </td>
                            <td className="text-right">
                              {props.contrat.producteur?.agence
                                ? `${props.contrat.producteur?.agence?.nom}`
                                : `Siège ${props.contrat.producteur?.profil?.organisme?.raisonSociale}`}
                            </td>
                            <td className="text-right"></td>
                            <td className="text-right"></td>
                            <td>
                              <div className=""></div>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              <div className=""></div>
                            </td>
                            <td className="text-left">
                              <strong>N° Devis :</strong>
                            </td>
                            <td className="text-right" colSpan={2}>
                              {props.contrat.numeroDevis}
                            </td>
                            <td className="text-right"></td>
                            <td>
                              <div className=""></div>
                            </td>
                            <td className="text-left">
                              <strong>Effet :</strong>
                            </td>
                            <td className="text-right">
                              {frenchDate(props.contrat.dateEffet)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              <strong>N° Assuré</strong>
                            </td>
                            <td className="text-right">
                              {props.contrat.souscripteur.numeroAssureP}
                            </td>
                            <td className="text-right"></td>
                            <td>
                              <div className=""></div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <div className=""></div>
                            </td>
                            <td className="text-left">
                              <strong>Apporteur :</strong>
                            </td>
                            <td className="text-right" colSpan={2}>
                              {
                                props.contrat.engins[0]?.concessionnaire
                                  ? props.contrat.engins[0]?.concessionnaire
                                    ?.raisonSociale : props.contrat?.intermediaire
                                    ? `${props.contrat?.intermediaire?.personne?.nom} ${props.contrat?.intermediaire?.personne?.prenom}`
                                    : props.contrat?.courtier?.nom
                              }
                            </td>
                            <td className="text-right"></td>
                            <td>
                              <div className=""></div>
                            </td>
                            <td className="text-left">
                              <strong>Echéance :</strong>
                            </td>
                            <td className="text-right">
                              {frenchDateSimple(props.contrat.dateEcheance)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr className="my-2" />
                  <div className="row">
                    <div className="col-12 text-left">
                      {`Aux Conditions Générales de la Police qui précèdent et à celles particulières qui suivent, les Compagnies d'Assurances IARDT assurent le véhicule ci-dessous désigné suivant les garanties accordées. Ainsi, sont
                           nulles toutes adjonctions ou modifications matérielles
                           non revêtues du visa de la direction ou du
                           représentant.`}
                    </div>
                  </div>
                  <hr className="my-2" />
                  <div className="text-center">
                    <h6>Conditions Particulières - ASSURANCE AUTOMOBILE</h6>
                    <h7>
                      <strong>
                        <u>CONTRAT A DUREE FERME</u>
                      </strong>
                    </h7>
                  </div>
                </div>

                {/* Replace more GridContainers and GridItems with Bootstrap layout */}

                <div className="row">
                  <div className="col-12">
                    <table
                      // className="table table-borderless"
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <td className="text-start">
                            <strong>SOUSCRIPTEUR : </strong>{" "}
                            {props.contrat.souscripteur.prenom
                              ? `${props.contrat.souscripteur.nom} ${props.contrat.souscripteur.prenom}`
                              : `${props.contrat.souscripteur.nom}`} (<strong>ASSURE</strong>: {props.contrat.assure.prenom
                                ? `${props.contrat.assure.nom} ${props.contrat.assure.prenom}`
                                : `${props.contrat.assure.nom}`})
                          </td>
                          <td className=""></td>
                          <td>
                            {/* <div className="w-100"></div> */}
                          </td>
                          <td className=""></td>
                          <td className="text-end">
                            <strong>PRODUCTEUR : </strong>
                            {props?.contrat?.producteur?.personne?.prenom}{" "}
                            {props?.contrat?.producteur?.personne?.nom}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr className="my-2" />
                    <table
                      // className="table table-borderless"
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <td className="text-start">
                            <strong>Profession : </strong>
                            {props.contrat.souscripteur.profession}
                          </td>
                          <td className=""></td>
                          <td>
                            {/* <div className="w-100"></div> */}
                          </td>
                          <td className=""></td>
                          <td className="text-end">
                            <strong>Adresse : </strong>
                            {props.contrat.souscripteur.adresse}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr className="my-2" />
                    <div className="text-center">
                      <h6>
                        IDENTIFICATION VEHICULE - N° Immatriculation : {" "}
                        <strong>{props.contrat.engins[0].immatriculation}</strong>
                      </h6>
                      <hr className="my-2" />
                    </div>
                    <div class="">
                      <div class="row">
                        <div class="col-12">
                          <table style={{ width: "100%" }}>
                            <tr>
                              <td class="text-left">
                                {/* <h6> */}
                                <strong style={{ color: "black" }}>Marque :</strong>
                                {/* </h6> */}
                              </td>
                              <td class="text-right">
                                {props.contrat.engins[0].marque}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td></td>
                              <td></td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>
                                  Carosserie :
                                </strong>
                              </td>
                              <td colspan="2" class="text-right">
                                {props.contrat.engins[0].carosserie}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>Année :</strong>
                              </td>
                              <td class="text-right">
                                {
                                  props.contrat.engins[0].dateMiseEnCirculation?.split(
                                    "-"
                                  )[0]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>
                                  Puissance :
                                </strong>
                              </td>
                              <td class="text-right">
                                {props.contrat.engins[0].puissance ? `${props.contrat.engins[0].puissance} CV` : `${props.contrat.engins[0].volume} cm³`}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>Energie :</strong>
                              </td>
                              <td class="text-right">
                                {props.contrat.engins[0].energie}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>
                                  N° Châssis :
                                </strong>
                              </td>
                              <td class="text-right" colspan="2">
                                {props.contrat.engins[0].numeroChassis}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <h6>
                                  <b>Valeur vénale :</b>
                                </h6>
                              </td>
                              <td class="text-right">
                                {new Intl.NumberFormat("en-DE").format(
                                  props.contrat.engins[0].valeurVenale
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>Places :</strong>
                              </td>
                              <td class="text-right">
                                {props.contrat.engins[0].nombrePlace}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>Genre :</strong>
                              </td>
                              <td class="text-right">
                                {props.contrat.engins[0].genre}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>
                                  N° Moteur :
                                </strong>
                              </td>
                              <td class="text-right" colspan="2">
                                {props.contrat.engins[0].moteur}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>
                                  Valeur à neuf :
                                </strong>
                              </td>
                              <td class="text-right">
                                {new Intl.NumberFormat("en-DE").format(
                                  props.contrat.engins[0].valeurANeuf
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>
                                  Catégorie :
                                </strong>
                              </td>
                              <td class="text-right">
                                {props.contrat.engins[0].categorie.nom}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td></td>
                              <td></td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>Usage :</strong>
                              </td>
                              <td class="text-right" colspan="2">
                                {props.contrat.engins[0].usage}
                              </td>
                              <td>
                                <div class=""></div>
                              </td>
                              <td class="text-left">
                                <strong style={{ color: "black" }}>
                                  Lieu garage :
                                </strong>
                              </td>
                              <td>...</td>
                            </tr>
                          </table>
                          <hr className="my-2" />
                          <div className="">
                            <table className="table table-bordered"
                              style={{
                                border: "2px solid black",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ border: "2px solid black", textAlign: "center" }}>
                                    <strong style={{ color: "black" }}>Garanties</strong>
                                  </th>
                                  <th style={{ border: "2px solid black", textAlign: "center" }}>
                                    <strong style={{ color: "black" }}>Capitaux</strong>
                                  </th>
                                  <th
                                    style={{ border: "2px solid black", textAlign: "center" }}
                                  >
                                    <strong style={{ color: "black" }}>Franchise</strong>
                                  </th>
                                  <th style={{ border: "2px solid black", textAlign: "center" }}><strong style={{ color: "black" }}>Prime</strong></th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.map((dt, id) => (
                                  <tr key={id} style={{ border: "2px solid black" }}>
                                    <td style={{ border: "2px solid black" }}>{dt.garantie === "Individuel Conducteur" &&
                                      props?.contrat?.engins[0].categorie.codeCategorie === "M" ? (
                                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                        <tr>
                                          <td> Individuel Conducteur</td>
                                          <td>
                                            {" "}
                                            <tr>
                                              <td>* Décès :</td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>* Invalidité :</td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>* Frais Médicaux :</td>
                                              <td></td>
                                            </tr>
                                          </td>
                                        </tr>
                                      </table>
                                    ) : (
                                      <p>{dt.garantie}</p>
                                    )}
                                    </td>
                                    <td
                                      style={{
                                        border: "2px solid black",
                                        textAlign: "right",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {dt.garantie === "Individuel Conducteur" &&
                                        props?.contrat?.engins[0].categorie.codeCategorie === "M" ? (
                                        <table>
                                          <tr>
                                            <td></td>
                                            <td>
                                              <tr>
                                                <td></td>
                                                <td>250.000</td>
                                              </tr>
                                              <tr>
                                                <td></td>
                                                <td>500.000</td>
                                              </tr>
                                              <tr>
                                                <td></td>
                                                <td>50.000</td>
                                              </tr>
                                            </td>
                                          </tr>
                                        </table>
                                      ) : (
                                        <p>{dt.capital}</p>
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        border: "2px solid black",
                                        textAlign: "right",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <p>{dt.franchise}</p>
                                    </td>
                                    <td
                                      style={{
                                        border: "2px solid black",
                                        textAlign: "right",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <p>{dt.prime_nette}</p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div class="row">
                              <div class="col-12">
                                <table class="table table-bordered">
                                  <tr>
                                    <td class="text-right">
                                      <strong style={{ color: "black" }}>
                                        Durée : {props.contrat.fractionnement ? props.contrat.fractionnement.moisMin : props?.contrat?.engins[0]?.categorie?.codeCategorie?.startsWith("VT") ? "15 JOURS" : "12 MOIS"}
                                      </strong>
                                    </td>
                                    <td></td>
                                    <td>
                                      <div class=""></div>
                                    </td>
                                    <td class="text-left">Fractionnement :</td>
                                    <td>{props.contrat.fractionnement ? props.contrat.fractionnement?.nombre : "0"}</td>
                                    <td>
                                      <div class=""></div>
                                    </td>
                                    <td class="text-left">Prime Nette :</td>
                                    <td colspan="2" style={{ marginLeft: "30px" }}>
                                      {new Intl.NumberFormat("en-DE").format(
                                        Math.trunc(props?.contrat.primeNette)
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div class=""></div>
                                    </td>
                                    <td class="text-left">Réduction flotte :</td>
                                    <td>0</td>
                                    <td>
                                      <div class=""></div>
                                    </td>
                                    <td class="text-left">Coût de pièces :</td>
                                    <td colspan="2" style={{ position: "absolute", marginLeft: "10px" }}>
                                      {new Intl.NumberFormat("en-DE").format(
                                        props?.contrat.accessoires
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div class=""></div>
                                    </td>
                                    <td class="text-left">BNS :</td>
                                    <td>0</td>
                                    <td>
                                      <div class=""></div>
                                    </td>
                                    <td class="text-left">Taxes(6%) :</td>
                                    <td colspan="2" style={{ position: "absolute", marginLeft: "10px" }}>
                                      {new Intl.NumberFormat("en-DE").format(
                                        Math.trunc(
                                          (props?.contrat.primeTotale * 0.06) / 1.06
                                        )
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div class=""></div>
                                    </td>
                                    <td class="text-left">
                                      Réduction Exceptionnelle :
                                    </td>
                                    <td>0</td>
                                    <td>
                                      <div class=""></div>
                                    </td>
                                    <td class="text-left">Prime Totale:</td>
                                    <td colspan="2" style={{ position: "absolute", marginLeft: "2px" }}>
                                      {new Intl.NumberFormat("en-DE").format(
                                        Math.trunc(props?.contrat.primeTotale)
                                      )}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <table class="table w-90">
                                  <tr>
                                    <td class="text-right">
                                      <strong style={{ color: "black" }}>
                                        LE SOUSCRIPTEUR
                                      </strong>
                                    </td>
                                    <td class="w-30"></td>
                                    <td>
                                      <div class="w-40"></div>
                                    </td>
                                    <td class="text-right">
                                      <strong style={{ color: "black" }}>
                                        DATE :
                                      </strong>
                                    </td>
                                    <td class="w-40"></td>
                                    <td>
                                      <div class="w-100"></div>
                                    </td>
                                    <td class="text-right">
                                      <strong style={{ color: "black" }}>
                                        POUR LA COMPAGNIE :
                                      </strong>
                                    </td>
                                    <td colspan="2" class="text-right"></td>
                                  </tr>
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div class=""> */}
                    {/* <div class="row">
                 <div class="col-12"> */}
                    {/* <div class="card"> */}

                    {/* </div> */}
                    {/* </div>
               </div> */}
                    {/* </div> */}
                  </div>
                </div>

                {/* Continue with more Bootstrap code, replacing Grid and Material UI components */}
              </div>
              <Image src={footer} fluid />
            </div>
          </Watermark>
        </div>
      ) : (
        <div class="col-12" ref={ref}>
          <Watermark content="PROPOSITION" >
            <div style={{ padding: "0.5rem 0.5rem", }}>
              <Image src={entete} width={"100%"} height={"105px"} />
              <div class="card">
                <div class="text-center">
                  <hr className="my-2" style={{ color: "black" }} />
                  <h5 className="my-0">
                    Conditions Particulières{" "}
                    {props.contrat?.engins[0]?.categorie?.nom} (Flotte)
                  </h5>
                  <hr className="my-0" style={{ color: "black" }} />
                </div>
                <div class="row">
                  <div class="col-12">
                    <table style={{ width: "100%", marginBottom: "-30px" }}>
                      <tr>
                        <th>
                          <h5 style={{ marginLeft: "25px" }}>
                            <b>Référence Souscripteur</b>
                          </h5>
                        </th>
                        <th>
                          <h5>
                            <b>Référence de la Police</b>
                          </h5>
                        </th>
                      </tr>
                    </table>
                    <hr style={{ color: "black" }} />
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td colspan="2">
                          <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                              <h6>
                                <b>Code Assuré :</b>{" "}
                                {props.contrat?.souscripteur?.numeroAssureP}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <h6>
                            <b>N° Quittance :</b> -
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <b>Emission :</b> -
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                              <h6>
                                <b>Titre :</b> Etat
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <h6>
                            <b>N° Police :</b> {props.contrat.numeroPoliceP}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <b>Avenant :</b> {props.contrat.avenant.nom}
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                              <h6>
                                <b>Nom :</b> {props.contrat.souscripteur?.nom} {props.contrat.souscripteur?.prenom ? props.contrat.souscripteur?.prenom : ""}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <h6>
                            <b>Effet :</b> {frenchDateSimple(props.contrat.dateEffet)}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <b>Echéance :</b> {frenchDateSimple(props.contrat.dateEcheance)}
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                              <h6>
                                <b>Adresse :</b> {props.contrat.souscripteur?.adresse}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <h6>
                            <b>Catégorie :</b> {props.contrat?.engins &&
                              props.contrat?.engins[0]?.categorie?.nom}
                            (
                            {props.contrat?.engins &&
                              props.contrat?.engins[0]?.categorie?.commentaire}
                            )
                          </h6>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                              <h6>
                                <b>Tel :</b> {props.contrat.souscripteur?.contact[0]}
                                {props.contrat.souscripteur?.contact[1] ? `/ ${props.contrat.souscripteur?.contact[1]}` : null}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td colSpan={2}>
                          <h6>
                            <b>Mouvement :</b>  {props.contrat?.avenant?.nom}
                          </h6>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                              <h6>
                                <b>Email :</b> {props.contrat.souscripteur?.email ? props.contrat.souscripteur?.email : "-"}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <h6>
                            <b>Ecriture: </b> {bareme}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <b>Durée :</b>  {duree}
                          </h6>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                              <h6>
                                <b>Site de production :</b> {props.contrat.producteur?.agence
                                  ? `${props.contrat.producteur?.agence?.nom}`
                                  : `Siège ${props.contrat.producteur?.profil?.organisme?.raisonSociale}`}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td colSpan={2}>
                          <h6>
                            <b>Type emission: </b> SANS TACITE RECONDUCTION
                          </h6>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                              <h6>
                                <b>Intermédiaire :</b> {props.contrat.intermediaire ? `${props.contrat.intermediaire?.personne?.nom} ${props.contrat.intermediaire?.personne?.prenom}` : props.contrat.courtier ? props.contrat.courtier?.nom : "-"}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td colSpan={2}>
                          <h6>
                            <b>Producteur: </b>{props?.contrat?.producteur?.personne?.nom} {props?.contrat?.producteur?.personne?.prenom}
                          </h6>
                        </td>
                        <td></td>
                      </tr>
                      {/* <!-- Add other table rows as needed --> */}
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="row">
                        <div class="col-md-12">
                          {/* <!-- Render reduCol and reduData here --> */}
                          <table className="table table-bordered" style={{ border: "1px solid black" }}>
                            {reduData?.map((rd, id) => (
                              <tr key={id}>
                                <td style={{
                                  border: "1px solid black",
                                  padding: "0px"
                                }}>
                                  <h5>
                                    <b>{rd.libelle}</b>
                                  </h5>
                                </td>
                                <td style={{
                                  border: "1px solid black",
                                  textAlign: "right",
                                  verticalAlign: "middle",
                                  padding: "0px"
                                }}>
                                  <h5>
                                    <b>{rd.montant}</b>
                                  </h5>
                                </td>
                                <td style={{
                                  border: "1px solid black",
                                  padding: "0px"
                                }}>
                                  <h5>
                                    <b>{primeData[id].libelle}</b>
                                  </h5>
                                </td>
                                <td style={{
                                  border: "1px solid black",
                                  textAlign: "right",
                                  verticalAlign: "middle",
                                  padding: "0px"
                                }}>
                                  <h5>
                                    <b>{primeData[id].montant}</b>
                                  </h5>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                        {/* <div class="col-md-5 text-right"> */}
                        {/* <!-- Render primeCol and primeData here -->  */}
                        {/* <table className="table table-bordered" style={{ width: "100%", border: "1px solid black" }}>
                            {primeData?.map((rd, id) => (
                              <tr key={id}>
                                <td style={{
                                  border: "1px solid black"
                                }}>
                                  <h5>
                                    <b>{rd.libelle}</b>
                                  </h5>
                                </td>
                                <td style={{
                                  border: "1px solid black",
                                }}>
                                  <h5>
                                    <b>{rd.montant}</b>
                                  </h5>
                                </td>
                              </tr>
                            ))}
                          </table> */}
                        {/* </div> */}
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-12">
                          <table class="table w-90">
                            <tr>
                              <td class="text-right">
                                <strong style={{ color: "black" }}>
                                  LE SOUSCRIPTEUR
                                </strong>
                              </td>
                              <td class="w-30"></td>
                              <td>
                                <div class="w-40"></div>
                              </td>
                              <td class="text-right">
                                <strong style={{ color: "black" }}>
                                  DATE :
                                </strong>
                              </td>
                              <td class="w-40"></td>
                              <td>
                                <div class="w-100"></div>
                              </td>
                              <td class="text-right">
                                <strong style={{ color: "black" }}>
                                  POUR LA COMPAGNIE :
                                </strong>
                              </td>
                              <td colspan="2" class="text-right"></td>
                            </tr>
                            <tr>
                              <td class="text-right">
                                <strong style={{ color: "black" }}>

                                </strong>
                              </td>
                              <td class="w-30"></td>
                              <td>
                                <div class="w-40"></div>
                              </td>
                              <td class="text-right">
                                <strong style={{ color: "black" }}>
                                  {frenchDate(date)}
                                </strong>
                              </td>
                              <td class="w-40"></td>
                              <td>
                                <div class="w-100"></div>
                              </td>
                              <td class="text-right">
                                {imagePreviewUrl ?
                                  <img
                                    class="img-fluid rounded mb-4"
                                    src={imagePreviewUrl}
                                    height="220"
                                    width="220"
                                    alt="User avatar"
                                  /> : null}
                              </td>
                              <td colspan="2" class="text-right"></td>
                            </tr>
                            <br />
                          </table>
                        </div>
                      </div>
                    </div>
                    <Image src={footer} alt="Footer Image" />
                  </div>
                </div>
              </div>

              <div class="row pageBreak">
                <div class="col-12">
                  <Image src={entete} alt="Header Image" />
                  <div class="card">
                    <div class="text-right">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>N°</th>
                            <th>Immatriculation</th>
                            <th>Risques</th>
                            <th>Prime nette</th>
                          </tr>
                        </thead>
                        <tbody>
                          {flotteData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.numero}</td>
                              <td>{item.immatriculation}</td>
                              <td>{item.risques.split(";").map((risque, ind) => (
                                <div>
                                  <Tag color="blue">
                                    {risque?.split("|")[0]}
                                  </Tag>
                                  <Tag color="green">
                                    {new Intl.NumberFormat(
                                      "en-DE"
                                    ).format(risque?.split("|")[1])} FCFA
                                  </Tag>
                                </div>
                              ))}
                              </td>
                              <td>{item.primeNette}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Image src={footer} alt="Footer Image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Watermark>

        </div>
      )}
    </>
  );
});

DTRFFormatProposition.propTypes = {
  contrat: PropTypes.object,
};
export default DTRFFormatProposition;
