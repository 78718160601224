import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';
import { rightsService } from '../../services/rightsService';
import { API_URL, LOGGED_USER, TOKEN } from '../../variables/constants';
import { isTomonth } from '../../variables/functions';
import AddIntermediaire from './AddIntermediaire';
import EditIntermediaire from './EditIntermediaire';
const { confirm } = Modal;

const DetailsAgence = () => {
    const [agence, setAgence] = useState("");
    const [nom, setNom] = useState("");
    const [siegeSocial, setSiegeSocial] = useState("");
    const [code, setCode] = useState("");
    const [dateCreation, setDateCreation] = useState("");
    const [telephone1, setTelephone1] = useState("");
    const [telephone2, setTelephone2] = useState("");
    const [email, setEmail] = useState("");
    const [typeAgence, setTypeAgence] = useState("AGENCE");
    const [responsable, setResponsable] = useState("");
    const [active, setActive] = useState(true);
    const [signatureFile, setSignatureFile] = useState("");
    const [changeSignature, setChangeSignature] = useState(false);
    const [selectedIntermediaire, setSelectedIntermediaire] = useState("");
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [logoImagePreviewUrl, setLogoImagePreviewUrl] = useState(null);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [intermediaires, setIntermediaires] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [reload, setReload] = useState(false);
    const [montantPrimes, setMontantPrimes] = useState(0);
    const [montantPrimesMensuelles, setMontantPrimesMensuelles] = useState(0);
    const [contrats, setContrats] = useState([]);

    const [activeTab, setActiveTab] = useState(0);

    const param = useParams();
    const navigate = useNavigate();

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    const handleShow = () => setShowModal(true);

    const handleEditShow = (inter) => {
        setSelectedIntermediaire(inter);
        setShowEditModal(true);
    }

    const handleClose = () => setShowModal(false);
    const handleEditClose = () => setShowEditModal(false);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSignatureFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result); // Set the image preview URL
            };
            reader.readAsDataURL(file); // Convert the file to a Data URL
        }
        setChangeSignature(true);
    };

    useEffect(() => {
        let code = "";
        code = param.codeAgence ? param.codeAgence : LOGGED_USER.agence.codeAgence;
        axios
            .get(`${API_URL}/api/agence/code/${code}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            }).then((res) => {
                console.log(res.data);
                setNom(res.data.nom);
                setCode(res.data.codeAgence);
                setDateCreation(res.data.dateCreation);
                setEmail(res.data.email);
                setTypeAgence(res.data.typeAgence);
                // setTelephone1(res.data.contact[0]);
                // setTelephone2(res.data.contact[1]);
                setSiegeSocial(res.data.siege);
                setResponsable(res.data.responsable);

                if (res.data?.signature) {
                    fetch(
                        `${API_URL}/api/agence/signature/${res.data?.signature.split("//")[2]}`,
                        {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods":
                                    "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        }
                    )
                        .then(async (response) => {
                            console.log("The response", response);

                            // convert byte to file
                            const imageBytes = await response.arrayBuffer();
                            console.log("images byte: ", imageBytes);
                            var blob = new Blob([imageBytes], { type: "image/png" });
                            console.log("Blob: ", blob);
                            var imageUrl = URL.createObjectURL(blob);
                            console.log("Image URL: ", imageUrl);
                            setImagePreviewUrl(imageUrl);
                            var file = new File([blob], res.data?.signature.split("//")[2], {
                                type: "image/png",
                            });
                            console.log("File: ", file);

                            setTimeout(() => {
                                console.log("Preview: ", { imagePreviewUrl });
                            }, 1000);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

                if (res.data?.organisme?.logo) {
                    fetch(
                        `${API_URL}/api/organisme/logo/${res.data?.organisme?.logo.split("//")[2]}`,
                        {
                            headers: {
                                Authorization: `Bearer ${TOKEN}`,
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods":
                                    "GET, PUT, POST, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers":
                                    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                            },
                        }
                    )
                        .then(async (response) => {
                            console.log("The response", response);

                            // convert byte to file
                            const imageBytes = await response.arrayBuffer();
                            console.log("images byte: ", imageBytes);
                            var blob = new Blob([imageBytes], { type: "image/png" });
                            console.log("Blob: ", blob);
                            var imageUrl = URL.createObjectURL(blob);
                            console.log("Image URL: ", imageUrl);
                            setLogoImagePreviewUrl(imageUrl);
                            var file = new File([blob], res.data?.organisme?.logo.split("//")[2], {
                                type: "image/png",
                            });
                            console.log("File: ", file);

                            setTimeout(() => {
                                console.log("Preview: ", { logoImagePreviewUrl });
                            }, 1000);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                setAgence(res.data);
            }).catch(err => {
                console.log(err);
            });
        axios
            .get(
                `${API_URL}/api/agence/utilisateurs/code/${code}`,
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            )
            .then((res) => {
                setUtilisateurs(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(
                `${API_URL}/api/agence/intermediaires/code/${code}`,
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            )
            .then((res) => {
                console.log("intermédiaires: ", res.data);
                setIntermediaires(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(
                `${API_URL}/api/contrat/agence/${code}`,
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            )
            .then((res) => {
                console.log("contrats: ", res.data);
                setContrats(res.data);
                let pns = 0;
                let monthPns = 0;
                res.data.forEach((cont) => {
                    pns += cont.reglements.reduce((pn, reg) => reg.modePaiement && (pn + reg.montant), 0);
                    monthPns += cont.reglements.reduce((pn, reg) => reg.modePaiement && isTomonth(reg.dateReglement) && (pn + reg.montant), 0);
                });
                setMontantPrimes(pns);
                setMontantPrimesMensuelles(monthPns);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [TOKEN, param.codeAgence, showModal, reload]);

    const activateIntermediaireConfirm = (inter) => {
        confirm({
            title: `Etes-vous sûr de vouloir ${inter.active ? "désactiver" : "activer"} cet intermédiaire?`,
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées!",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                activateIntermediaire(inter);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const activateIntermediaire = (inter) => {
        axios
            .get(`${API_URL}/api/intermediaire/activity/${inter.codeIntermediaire}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            }).then((res) => {
                console.log(res.data);
                inter.codeIntermediaire ? toast("Intermédiaire désactivé avec succès!") : toast("Intermédiaire activé avec succès!");
                setReload(true);
            }).catch(err => {
                console.log(err);
                inter.codeIntermediaire ? toast("Erreur lors de la désactivation de l'intermédiaire") : toast("Erreur lors de l'activation de l'intermédiaire");
            });
    }

    const showUserActivateConfirm = (utilisateurCodeId, status) => {
        confirm({
            title: status
                ? "Etes-vous sûr de vouloir désactiver cet utilisateur?"
                : "Etes-vous sûr de vouloir activer cet utilisateur?",
            icon: <ExclamationCircleFilled />,
            content: "Cette action sera historisée et donc est irréversible",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                activateUtilisateur(utilisateurCodeId, status);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const activateUtilisateur = (utilisateurCodeId, status) => {
        axios
            .get(`${API_URL}/api/utilisateur/activate/${utilisateurCodeId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                window.location.reload();
                toast(
                    status
                        ? "Utilisateur désactivé avec succès"
                        : "Utilisateur activé avec succès"
                );
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors de l'activation");
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        var contacts = [];
        telephone2
            ? contacts.push(telephone1, telephone2)
            : contacts.push(telephone1);
        let logoFile = "";

        console.log("dataaaaaaaaa: ", signatureFile);
        try {
            if (changeSignature) {
                let extension = signatureFile?.name?.substring(
                    signatureFile?.name?.lastIndexOf(".") + 1,
                    signatureFile?.name?.length
                );
                let filename = `Logo_${nom
                    .split(" ")
                    .join("-")}.${extension}`;
                formData.append(`fichier`, signatureFile, filename);
                axios
                    .post(`${API_URL}/api/agence/signature`, formData, {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "multipart/form-data",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        logoFile = response.data;

                        const agency = agence;
                        agency.typeAgence = typeAgence;
                        agency.nom = nom;
                        agency.codeAgence = code;
                        agency.contact = contacts;
                        agency.email = email;
                        agency.dateCreation = dateCreation;
                        agency.responsable = responsable;
                        agency.siege = siegeSocial;
                        agency.restrictionProduction = active;
                        agency.signature = response.data;
                        console.log("L'agencyeeeee: ", agency);

                        axios
                            .put(`${API_URL}/api/agence`, JSON.stringify(agency), {
                                headers: {
                                    Authorization: `Bearer ${TOKEN}`,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods":
                                        "GET, PUT, POST, DELETE, OPTIONS",
                                    "Access-Control-Allow-Headers":
                                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                },
                            })
                            .then((res) => {
                                console.log(res.data);
                                toast("Agency à jour avec succès!");
                                // navigate(`/membre/${res.data.organismeId}`, { replace: true });
                                window.location.reload();
                            })
                            .catch((err) => {
                                console.log(err);
                                toast("Erreur lors de la création d'un membre");
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                const agency = agence;
                agency.typeAgence = typeAgence;
                agency.nom = nom;
                agency.codeAgence = code;
                agency.contact = contacts;
                agency.email = email;
                agency.dateCreation = dateCreation;
                agency.responsable = responsable;
                agency.siege = siegeSocial;
                agency.restrictionProduction = active;
                console.log("L'agencyeeeee: ", agency);
                axios
                    .put(`${API_URL}/api/agence`, JSON.stringify(agency), {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    .then((res) => {
                        console.log(res.data);
                        toast("Agence mise à jour avec succès!");
                        navigate(`/agence/${res.data.codeId}`, { replace: true });
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                        toast("Erreur lors de la mise à jour des informations d'un membre");
                    });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const resetImage = () => {
        setImagePreviewUrl(null);
        setChangeSignature(false);
    };


    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"MEMBRES"} />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between mb-6 text-center text-sm-start gap-2">
                                <div class="mb-2 mb-sm-0">
                                    <h4 class="mb-1">Code agence #{agence?.codeAgence}</h4>
                                    <p class="mb-0">Date de création : {agence?.dateCreation}</p>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-label-danger delete-customer"
                                >
                                    Supprimer l'agence
                                </button>
                            </div>

                            <div class="row">
                                {/* <!-- Customer-detail Sidebar --> */}
                                <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                                    {/* <!-- Customer-detail Card --> */}
                                    <div class="card mb-6">
                                        <div class="card-body pt-12">
                                            <div class="customer-avatar-section">
                                                <div class="d-flex align-items-center flex-column">
                                                    <img
                                                        class="img-fluid rounded mb-4"
                                                        src={logoImagePreviewUrl}
                                                        height="120"
                                                        width="120"
                                                        alt="User avatar"
                                                    />
                                                    <div class="customer-info text-center mb-6">
                                                        <h5 class="mb-0">{agence?.nom} - {agence?.nom} |  {agence?.organisme?.raisonSociale}</h5>
                                                        <span>Code #{agence?.codeAgence}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-around flex-wrap mb-6 gap-0 gap-md-3 gap-lg-4">
                                                <div class="d-flex align-items-center gap-4 me-5">
                                                    <div class="avatar">
                                                        <div class="avatar-initial rounded bg-label-primary">
                                                            <i class="bx bx-user bx-lg"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5 class="mb-0">{contrats.length}</h5>
                                                        <span>Contrats</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center gap-4">
                                                    <div class="avatar">
                                                        <div class="avatar-initial rounded bg-label-primary">
                                                            <i class="bx bx-dollar bx-lg"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5 class="mb-0">{new Intl.NumberFormat("en-DE").format(montantPrimesMensuelles)} FCFA</h5>
                                                        <span>Production du mois</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="info-container">
                                                <h5 class="pb-4 border-bottom text-capitalize mt-6 mb-4">
                                                    Détails
                                                </h5>
                                                <ul class="list-unstyled mb-6">
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Nom du responsable:</span>
                                                        <span>{agence?.responsable}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Email:</span>
                                                        <span>{agence?.email}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Statut:</span>
                                                        {agence?.active ? (
                                                            <span class="badge bg-label-success">Actif</span>
                                                        ) : (
                                                            <span class="badge bg-label-primary">
                                                                Inactif
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Contacts:</span>
                                                        <span>
                                                            {agence?.contact ? agence?.contact[0] : ""}
                                                        </span>
                                                        <span>
                                                            {agence?.contact && agence?.contact[1]
                                                                ? `; ${agence?.contact[1]}`
                                                                : ""}
                                                        </span>
                                                    </li>

                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Siège:</span>
                                                        <span>{agence?.ville}</span>
                                                    </li>
                                                </ul>
                                                <div class="d-flex justify-content-center">
                                                    <a
                                                        href="javascript:;"
                                                        class="btn btn-primary w-100"
                                                        data-bs-target="#editUser"
                                                        data-bs-toggle="modal"
                                                    >
                                                        Editer les informations
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--/ Customer Sidebar --> */}

                                {/* <!-- Customer Content --> */}
                                <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                                    {/* <!-- Customer Pills --> */}
                                    <div class="nav-align-top">
                                        <ul class="nav nav-pills flex-column flex-md-row mb-6">
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 0 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#apercu"
                                                    onClick={() => handleTabChange(0)}
                                                >
                                                    <i class="bx bx-detail bx-sm me-1_5"></i>Aperçu
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 1 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#user"
                                                    onClick={() => handleTabChange(1)}
                                                >
                                                    <i class="bx bx-group bx-sm me-1_5"></i>Utilisateurs
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 2 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#agency"
                                                    onClick={() => handleTabChange(2)}
                                                >
                                                    <i class="bx bx-home-smile bx-sm me-1_5"></i>Intermédiaires
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 3 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#notif"
                                                    onClick={() => handleTabChange(3)}
                                                >
                                                    <i class="bx bx-bell bx-sm me-1_5"></i>Notifications
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <!--/ Customer Pills --> */}

                                    {/* <!-- / Customer cards --> */}
                                    {activeTab === 0 && (
                                        <div class="row text-nowrap">
                                            <div class="col-md-6 mb-6">
                                                <div class="card h-100">
                                                    <div class="card-body">
                                                        <div class="card-icon mb-2">
                                                            <div class="avatar">
                                                                <div class="avatar-initial rounded bg-label-primary">
                                                                    <i class="bx bx-file bx-lg"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-info">
                                                            <h5 class="card-title mb-2">Production</h5>
                                                            <div class="d-flex align-items-baseline gap-1">
                                                                <h5 class="text-primary mb-0">{new Intl.NumberFormat("en-DE").format(montantPrimesMensuelles)} FCFA</h5>
                                                                <p class="mb-0"> Primes encaissées</p>
                                                            </div>
                                                            <p class="mb-0 text-truncate">
                                                                Etat des primes encaissées pour le mois en cours
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-6">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="card-icon mb-2">
                                                            <div class="avatar">
                                                                <div class="avatar-initial rounded bg-label-primary">
                                                                    <i class="bx bx-first-aid bx-lg"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-info">
                                                            <h5 class="card-title mb-2">Sinistres payés</h5>
                                                            <p class="mb-0">0 FCFA</p>
                                                        </div>
                                                        <p class="mb-0 text-truncate">
                                                            Etat des sinistres payés pour le mois en cours
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-6">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="card-icon mb-2">
                                                            <div class="avatar">
                                                                <div class="avatar-initial rounded bg-label-warning">
                                                                    <i class="bx bx-file bx-lg"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-info">
                                                            <h5 class="card-title mb-2">Production</h5>
                                                            <div class="d-flex align-items-baseline gap-1">
                                                                <h5 class="text-primary mb-0">{new Intl.NumberFormat("en-DE").format(montantPrimes)} FCFA</h5>
                                                                <p class="mb-0"> Primes encaissées</p>
                                                            </div>
                                                            <p class="mb-0 text-truncate">
                                                                Etat des primes encaissées depuis la création
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-6">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="card-icon mb-2">
                                                            <div class="avatar">
                                                                <div class="avatar-initial rounded bg-label-warning">
                                                                    <i class="bx bx-first-aid bx-lg"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-info">
                                                            <h5 class="card-title mb-2">Sinistres payés</h5>
                                                            <p class="mb-0">3000 FCFA</p>
                                                        </div>
                                                        <p class="mb-0 text-truncate">
                                                            Etat des sinistres payés pour le mois passé
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {activeTab === 1 && (
                                        <div>
                                            {/* User Tab content */}
                                            {/* Add the actual content for this tab */}
                                            {rightsService("HPE038") && <Button
                                                type="primary"
                                                icon={<PlusOutlined />}
                                                style={{ marginBottom: 16 }}
                                                onClick={() => { navigate(`/ajouter-utilisateur-agence/${param.codeAgence ? param.codeAgence : LOGGED_USER.agence.codeAgence}`) }}
                                            >
                                                Ajouter un nouvel utilisateur
                                            </Button>}
                                            <div class="card mb-6">
                                                <div class="table-responsive mb-4">
                                                    <table class="table datatables-customer-order border-top">
                                                        <thead>
                                                            <tr>
                                                                <th>Nom & Prénom(s)</th>
                                                                <th>Email</th>
                                                                <th>Profil</th>
                                                                <th>Agence</th>
                                                                <th>Statut</th>
                                                                <th class="text-md-center">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {utilisateurs?.length ? (
                                                                utilisateurs?.map((user, id) => (
                                                                    <tr key={id}>
                                                                        <td>
                                                                            {user.personne.nom} {user.personne.prenom}
                                                                        </td>
                                                                        <td>{user.email}</td>
                                                                        <td>{user.profil.nom}</td>
                                                                        <td>{user.agence.nom}</td>
                                                                        <td>
                                                                            {user.active ? (
                                                                                <span class="badge bg-label-success">
                                                                                    Actif
                                                                                </span>
                                                                            ) : (
                                                                                <span class="badge bg-label-danger">
                                                                                    Inactif
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td class="text-md-center">
                                                                            <div class="dropdown">
                                                                                <button
                                                                                    type="button"
                                                                                    class="btn p-0 dropdown-toggle hide-arrow"
                                                                                    data-bs-toggle="dropdown"
                                                                                >
                                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                    <a
                                                                                        class="dropdown-item"
                                                                                        href={`/details-utilisateur/${user.codeId}`}
                                                                                    >
                                                                                        <i class="bx bx-detail me-1"></i>{" "}
                                                                                        Détails
                                                                                    </a>
                                                                                    {rightsService("HPE039") &&
                                                                                        <a
                                                                                            class="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                            onClick={() =>
                                                                                                showUserActivateConfirm(user.codeId, user.active)
                                                                                            }
                                                                                        >
                                                                                            <i class={user.active ? "bx bxs-hide me-1" : "bx bxs-show me-1"}></i>{" "}
                                                                                            {user.active ? "Désactiver" : "Activer"}
                                                                                        </a>
                                                                                    }
                                                                                    {rightsService("HPE041") && <a
                                                                                        class="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                    >
                                                                                        <i class="bx bx-edit-alt me-1"></i>{" "}
                                                                                        Modifier
                                                                                    </a>}
                                                                                    {rightsService("HPE042") && <a
                                                                                        class="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                        onClick={() => { }}
                                                                                    >
                                                                                        <i class="bx bx-trash me-1"></i>{" "}
                                                                                        Supprimer
                                                                                    </a>}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Aucun utilisateur enregistré pour le moment
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === 2 && (
                                        <div>
                                            {/* Agency Tab content */}
                                            {/* Add the actual content for this tab */}
                                            {rightsService("HLA052") && <button
                                                class="btn btn-primary w-35"
                                                onClick={() => handleShow()}
                                            >
                                                <i class="bx bx-folder-plus bx-lg"></i> Ajouter un
                                                intermédiaire
                                            </button>}
                                            <div class="card mb-6">
                                                <div class="table-responsive mb-4">
                                                    <table class="table datatables-customer-order border-top">
                                                        <thead>
                                                            <tr>
                                                                <th>Code intermédiaire</th>
                                                                <th>Type intermédiaire</th>
                                                                <th>Nom</th>
                                                                <th>Contacts</th>
                                                                <th>Statut</th>
                                                                <th class="text-md-center">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {intermediaires?.length ? (
                                                                intermediaires?.map((ag, id) => (
                                                                    <tr key={id}>
                                                                        <td>{ag.codeIntermediaire}</td>
                                                                        <td><span class="badge bg-label-primary">{ag.type}</span></td>
                                                                        <td>{ag.personne.prenom} {ag.personne.nom}</td>
                                                                        <td>
                                                                            {ag.personne.contact
                                                                                ? ag.personne.contact[0]
                                                                                    ? ag.personne.contact[0]
                                                                                    : ""
                                                                                : ""}
                                                                        </td>
                                                                        <td>
                                                                            {!ag.active ? (
                                                                                <span class="badge bg-label-danger">
                                                                                    Inactif
                                                                                </span>
                                                                            ) : (
                                                                                <span class="badge bg-label-success">
                                                                                    Actif
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td class="text-md-center">
                                                                            <div class="dropdown">
                                                                                <button
                                                                                    type="button"
                                                                                    class="btn p-0 dropdown-toggle hide-arrow"
                                                                                    data-bs-toggle="dropdown"
                                                                                >
                                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                    <a
                                                                                        class="dropdown-item"
                                                                                        href={`/intermediaire/${ag?.codeIntermediaire}`}
                                                                                    >
                                                                                        <i class="bx bx-detail me-1"></i>{" "}
                                                                                        Détails
                                                                                    </a>
                                                                                    {rightsService("HLA054") && <a
                                                                                        class="dropdown-item"
                                                                                        href="#"
                                                                                        onClick={() => activateIntermediaireConfirm(ag)}
                                                                                    >
                                                                                        <i class="bx bx-expand-horizontal me-1"></i>{" "}
                                                                                        {ag?.active
                                                                                            ? "Désactiver"
                                                                                            : "Activer"}
                                                                                    </a>}
                                                                                    {rightsService("HLA053") && <a
                                                                                        class="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                        onClick={() => handleEditShow(ag)}
                                                                                    >
                                                                                        <i class="bx bx-edit-alt me-1"></i>{" "}
                                                                                        Modifier
                                                                                    </a>}
                                                                                    {rightsService("HLA055") && <a
                                                                                        class="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                        onClick={() => { }}
                                                                                    >
                                                                                        <i class="bx bx-trash me-1"></i>{" "}
                                                                                        Supprimer
                                                                                    </a>}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        Aucun intermédiaire enregistré pour le moment
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === 3 && (
                                        <div>
                                            {/* Notification Tab content */}
                                            {/* Add the actual content for this tab */}
                                        </div>
                                    )}

                                </div>
                                {/* <!--/ Customer Content --> */}
                            </div>
                            <div
                                class="modal fade"
                                id="editUser"
                                tabindex="-1"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-lg modal-simple modal-edit-user">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <button
                                                type="button"
                                                class="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                            <div class="text-center mb-6">
                                                <h4 class="mb-2">Editer les informations de l'agence</h4>
                                                <p>Mettez à jour les informations de l'agence</p>
                                            </div>
                                            <form class="row g-6" onsubmit={handleSubmit}>
                                                <div class="col-12">
                                                    <label
                                                        class="form-label"
                                                        for="modalEditUserFirstName"
                                                    >
                                                        Nom
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditUserFirstName"
                                                        name="modalEditUserFirstName"
                                                        class="form-control"
                                                        placeholder="Veuillez renseigner la raison sociale"
                                                        value={nom}
                                                        onChange={(e) => setNom(e.target.value)}
                                                    />
                                                </div>
                                                <div class="col-12">
                                                    <label class="form-label" for="modalEditUserLastName">
                                                        Siège social
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditUserLastName"
                                                        name="modalEditUserLastName"
                                                        class="form-control"
                                                        placeholder="Entrez le siège social"
                                                        value={siegeSocial}
                                                        onChange={(e) => setSiegeSocial(e.target.value)}
                                                    />
                                                </div>
                                                <div class="l-md-6col-12 col-md-6">
                                                    <label class="form-label" for="modalEditUserName">
                                                        Code de l'agence
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditUserName"
                                                        name="modalEditUserName"
                                                        class="form-control"
                                                        placeholder="Entrez le code du membre"
                                                        value={code}
                                                        onChange={(e) => setCode(e.target.value)}
                                                    />
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="modalEditUserName">
                                                        Date de création
                                                    </label>
                                                    <input
                                                        type="date"
                                                        id="modalEditUserName"
                                                        name="modalEditUserName"
                                                        class="form-control"
                                                        placeholder=""
                                                        value={dateCreation}
                                                        onChange={(e) => setDateCreation(e.target.value)}
                                                    />
                                                </div>
                                                <div class="col-12">
                                                    <label class="form-label" for="modalEditUserEmail">
                                                        Adresse mail
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditUserEmail"
                                                        name="modalEditUserEmail"
                                                        class="form-control"
                                                        placeholder="Entrez l'adresse mail"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                                {/* <div class="col-12 col-md-6">
                                                    <label class="form-label" for="modalEditUserPhone1">
                                                        Numéro de téléphone 1
                                                    </label>
                                                    <div class="input-group">
                                                        <span class="input-group-text">TG (+228)</span>
                                                        <input
                                                            type="tel"
                                                            id="modalEditUserPhone1"
                                                            name="modalEditUserPhone"
                                                            class="form-control phone-number-mask"
                                                            placeholder="00 00 00 00"
                                                            value={telephone1}
                                                            onChange={(e) => setTelephone1(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="modalEditUserPhone2">
                                                        Numéro de téléphone 2
                                                    </label>
                                                    <div class="input-group">
                                                        <span class="input-group-text">TG (+228)</span>
                                                        <input
                                                            type="tel"
                                                            id="modalEditUserPhone2"
                                                            name="modalEditUserPhone"
                                                            class="form-control phone-number-mask"
                                                            placeholder="00 00 00 00"
                                                            value={telephone2}
                                                            onChange={(e) => setTelephone2(e.target.value)}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="typeAgence">
                                                        Type agence
                                                    </label>
                                                    <select
                                                        id="typeAgence"
                                                        name="typeAgence"
                                                        class="select2 form-select"
                                                        value={typeAgence}
                                                        placeholder="Sélectionnez le type d'agence"
                                                        onChange={(e) => setTypeAgence(e.target.value)}
                                                    >
                                                        <option value="AGENCE">AGENCE</option>
                                                        <option value="SIEGE">SIEGE</option>
                                                        <option value="BUREAU_DIRECT">BUREAU DIRECT</option>
                                                        <option value="AGENCE_GENERALE">AGENCE GENERALE</option>
                                                        <option value="ETABLISSEMENT_FINANCIER">ETABLISSEMENT FINANCIER</option>
                                                        <option value="COURTIER">COURTIER</option>
                                                    </select>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="responsable">
                                                        Responsable
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="responsable"
                                                        name="responsable"
                                                        class="form-control phone-number-mask"
                                                        placeholder="Le nom du responsable du membre"
                                                        value={responsable}
                                                        onChange={(e) => setResponsable(e.target.value)}
                                                    />
                                                </div>

                                                <div class="col-12">
                                                    <div class="card-body">
                                                        <div class="d-flex align-items-start align-items-sm-center gap-6 pb-4 border-bottom">
                                                            <img
                                                                src={imagePreviewUrl}
                                                                alt="logo-avatar"
                                                                class="d-block w-px-300 h-px-100 rounded"
                                                                id="uploadedAvatar"
                                                            />
                                                            <div class="button-wrapper">
                                                                <label
                                                                    for="upload"
                                                                    class="btn btn-primary me-3 mb-4"
                                                                    tabindex="0"
                                                                >
                                                                    <span class="d-none d-sm-block">
                                                                        Téléverser une nouvelle image
                                                                    </span>
                                                                    <i class="bx bx-upload d-block d-sm-none"></i>
                                                                    <input
                                                                        type="file"
                                                                        id="upload"
                                                                        class="account-file-input"
                                                                        hidden
                                                                        accept="image/png, image/jpeg"
                                                                        onChange={handleImageChange}
                                                                    />
                                                                </label>
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-label-secondary account-image-reset mb-4"
                                                                    onClick={resetImage} // Reset the image
                                                                >
                                                                    <i class="bx bx-reset d-block d-sm-none"></i>
                                                                    <span class="d-none d-sm-block">
                                                                        Réinitialiser
                                                                    </span>
                                                                </button>

                                                                <div>
                                                                    JPG ou PNG autorisés. Taille maximale de
                                                                    2 Mo
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-check form-switch my-2 ms-2">
                                                        {active ? (
                                                            <input
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                id="editBillingAddress"
                                                                checked
                                                                onChange={() => setActive(false)}
                                                            />
                                                        ) : (
                                                            <input
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                id="editBillingAddress"
                                                                onChange={() => setActive(true)}
                                                            />
                                                        )}
                                                        <label
                                                            for="editBillingAddress"
                                                            class="switch-label"
                                                        >
                                                            Actif?
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 text-center">
                                                    <button
                                                        onClick={(e) => handleSubmit(e)}
                                                        class="btn btn-primary me-3"
                                                    >
                                                        Enregistrer
                                                    </button>
                                                    <button
                                                        type="reset"
                                                        class="btn btn-label-secondary"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- /Modal --> */}
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <AddIntermediaire
                show={showModal}
                handleClose={handleClose}
                edit={editMode}
                agence={agence}
            />
            <EditIntermediaire
                show={showEditModal}
                handleClose={handleEditClose}
                intermediaire={selectedIntermediaire}
                agence={agence}
            />
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div >
    )
}

export default DetailsAgence