import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddReduction from "./AddReduction"; // Composant pour gérer l'ajout/modification

const { confirm } = Modal;

const Reductions = () => {
  const [reductions, setReductions] = useState([]); // Liste des réductions
  const [showModal, setShowModal] = useState(false); // Pour afficher ou non le modal
  const [editMode, setEditMode] = useState(false); // Mode édition ou ajout
  const [selectedReduction, setSelectedReduction] = useState(null); // Stocker la réduction à modifier

  const navigate = useNavigate();

  const fetchReductions = () => {
    axios
      .get(`${API_URL}/api/reduction`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("Reductions chargées :", res.data);
        setReductions(res.data);
      })
      .catch((err) => {
        console.error("Erreur lors du chargement des réductions :", err);
        toast.error("Erreur lors du chargement des réductions");
      });
  };

  useEffect(() => {
    fetchReductions();
  }, []);

  const handleShow = (edit, reductionId = "") => {
    setEditMode(edit);
    if (edit) {
      const reduction = reductions.find((red) => red.reductionId === reductionId);
      if (reduction) {
        console.log("Reduction trouvée :", reduction);
        setSelectedReduction(reduction);
      } else {
        console.warn("Aucune réduction trouvée pour l'ID :", reductionId);
        toast.error("Réduction non trouvée !");
        return;
      }
    } else {
      setSelectedReduction(null);
    }
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedReduction(null);
  };

  const handleAddOrUpdateSuccess = () => {
    fetchReductions();
    handleClose();
    toast.success(editMode ? "Réduction modifiée avec succès" : "Réduction ajoutée avec succès");
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
    },
    {
      title: "Description",
      dataIndex: "commentaire",
      key: "commentaire",
    },
    {
      title: "Catégorie",
      dataIndex: "categorie",
      key: "categorie",
      render: (cat) => (
        <button
          type="button"
          className={`btn rounded-pill ${cat?.nom === "MOTO" ? "btn-info" : "btn-warning"}`}
        >
          {cat?.nom}
        </button>
      ),
    },
    {
      title: "Garanties",
      dataIndex: "garanties",
      key: "garanties",
      render: (garanties) => (
        <>
          {garanties && garanties.length > 0 ? (
            garanties.map((gar) => (
              <Tag color="blue" key={gar.codeId}>
                {gar.nom}
              </Tag>
            ))
          ) : (
            <span>Aucune garantie</span>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-icon rounded-pill"
            onClick={() => handleShow(true, record.reductionId)}
          >
            <i className="bx bx-edit-alt"></i>
          </button>
          <button
            type="button"
            className="btn btn-primary btn-icon rounded-pill"
            onClick={() => navigate(`/details-reduction/${record.reductionId}`)}
          >
            <i className="bx bx-dots-vertical-rounded"></i>
          </button>

          <button
            type="button"
            className="btn btn-danger btn-icon rounded-pill"
            onClick={() => showDeleteConfirm(record.reductionId)}
          >
            <i className="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];

  const showDeleteConfirm = (reductionId) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette réduction?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteReduction(reductionId);
      },
    });
  };

  const deleteReduction = (reductionId) => {
    axios
      .delete(`${API_URL}/api/reduction/${reductionId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setReductions(reductions.filter((red) => red.reductionId !== reductionId));
        toast.success("Suppression effectuée avec succès");
      })
      .catch((err) => {
        console.log("Erreur lors de la suppression :", err);
        toast.error("Erreur lors de la suppression");
      });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false)}
              >
                Ajouter une réduction
              </Button>
              <div className="card">
                <Table
                  columns={columns}
                  dataSource={reductions}
                  rowKey="reductionId"
                  expandable={{
                    expandedRowRender: (record) => (
                      <p style={{ margin: 0 }}>{record.commentaire}</p>
                    ),
                  }}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <AddReduction
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedReduction={selectedReduction}
        onSuccess={handleAddOrUpdateSuccess}
      />
    </div>
  );
};

export default Reductions;
