import axios from 'axios';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API_URL, TOKEN } from '../../variables/constants';

const SetupIntermediaireUserAccount = ({ show, handleClose, intermediaire, profils }) => {
    const [selectedProfil, setSelectedProfil] = useState("");
    const [loading, setLoading] = useState(false);

    const handleProfil = (codeId) => {
        // console.log("CodeId", codeId, "Profils: ", profils);
        // let prof = profils.find((e) => e.codeId === codeId);
        console.log("Prof: " + codeId);
        setSelectedProfil(codeId);
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const user = {
            personne: intermediaire?.personne,
            agence: intermediaire?.agence,
            profilCodeId: selectedProfil,
            username: intermediaire?.personne?.email,
            email: intermediaire?.personne?.email
        };
        console.log("userrrrrr", user);
        axios
            .post(`${API_URL}/api/utilisateur/intermediaire`, JSON.stringify(user), {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setLoading(false);
                toast("Utilisateur ajouté avec succès");

                handleClose();
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors de l'ajout de l'utilisateur");
                setLoading(false);
            });
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Body>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Associer un compte utilisateur</h4>
                    <p className="address-subtitle">
                        Associcier un compte utilisateur à {intermediaire?.personne?.nom}{" "}
                        {intermediaire?.personne?.prenom}
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={handleSubmit}>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label
                                    for="selectpickerBasic1"
                                    class="form-label"
                                >
                                    Profil
                                </label>
                                <select
                                    id="selectpickerBasic1"
                                    class="form-control w-100"
                                    data-style="btn-default"
                                    onChange={(e) => handleProfil(e.target.value)}
                                >
                                    <option value="">
                                        Sélectionner un profil
                                    </option>
                                    {profils?.map((pro, id) => (
                                        <option key={id} value={pro.codeId}>
                                            {pro.nom} - {pro.organisme.raisonSociale}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <button
                            type="submit"
                            className="btn btn-primary me-3"
                            disabled={loading}
                        >
                            {loading && (
                                <span
                                    class="spinner-grow me-1"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            {loading ? `Enregistrement...` : `Enregistrer`}
                        </button>
                        <button
                            type="reset"
                            className="btn btn-label-secondary"
                            onClick={handleClose}
                        >
                            Annuler
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default SetupIntermediaireUserAccount