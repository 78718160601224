import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddAccessoires = ({ show, handleClose, editMode, selectedAccesoire }) => {
  const [loading, setLoading] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [categories, setCategories] = useState([]);
  const [montant, setMontant] = useState("");

  useEffect(() => {
    if (editMode && selectedAccesoire) {
      setMontant(selectedAccesoire.libelleParametre || "");
      setSelectedCategorie(selectedAccesoire.categorie?.categorieId || "");
    } else {
      setMontant("");
      setSelectedCategorie("");
    }

    // Charger les catégories
    axios
      .get(`${API_URL}/api/categorie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de la récupération des catégories");
      });
  }, [editMode, selectedAccesoire, show]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const selectedCat = categories.find((cat) => cat.categorieId === parseInt(selectedCategorie));
    const accessoireData = {
      ...selectedAccesoire,
      libelleParametre: montant,
      typeParametre: "ACCESSOIRES",
      categorie: selectedCat,
    };
    console.log("TEST",selectedAccesoire);
    

    const request = editMode
      ? axios.put(`${API_URL}/api/parametre`, accessoireData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
      : axios.post(`${API_URL}/api/parametre`, accessoireData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });

    request
      .then((res) => {
        setMontant("");
        setSelectedCategorie("");
        toast.success(editMode ? "Frais accessoires modifié avec succès" : "Frais accessoires ajouté avec succès");
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors de l'enregistrement des frais accessoires");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button type="button" className="btn-close btn-primary" onClick={handleClose} aria-label="Close"></button>
        <div className="text-center mb-4">
          <h4 className="address-title mb-2">{editMode ? "Modifier les" : "Ajouter des"} accessoires</h4>
          <p className="address-subtitle">{editMode ? "Modifier les" : "Ajouter de nouveaux"} accessoires</p>
        </div>
        <form className="row g-3" onSubmit={onSubmit}>
          <div className="col-12">
            <label className="form-label" htmlFor="modalAddressFirstName">
              Montant
            </label>
            <input
              type="number"
              id="modalAddressFirstName"
              onChange={(e) => setMontant(e.target.value)}
              className="form-control"
              placeholder="Montant des frais accessoires"
              name="montant"
              value={montant}
              required
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="modalAddressCountry">
              Catégorie
            </label>
            <select
              id="modalEditUserLanguage"
              name="modalEditUserLanguage"
              className="select2 form-select"
              onChange={(e) => setSelectedCategorie(e.target.value)}
              value={selectedCategorie}
              required
            >
              <option value="">Sélectionner la catégorie</option>
              {categories.map((cat) => (
                <option key={cat.categorieId} value={cat.categorieId}>
                  {cat.nom}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-primary me-3" disabled={loading}>
              {loading && <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true"></span>}
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button type="button" className="btn btn-label-secondary" onClick={handleClose}>
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAccessoires;
