import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";

import PropTypes from "prop-types";


// ANT.DESIGN
import {Button, Collapse, Input, Radio, Select, Typography} from "antd";
import {useParams} from "react-router-dom";
import {getSinistreById} from "../../../../hooks/useHooks";
import {errorStateChecker, getIdentificateur, getvalue, setValue} from "../../../../variables/functions";
import {CloseOutlined, PlusCircleOutlined} from "@ant-design/icons";
const {Title} = Typography

// import { toutesLesValeursSontValides } from "../FONCTIONS-UTILITAIRES/FonctionsUtilitaires";


const InformationsSurAdversaire = forwardRef((props, ref) => {

    const {activeTab, setActiveTab} = props


    useImperativeHandle(ref, () => ({
        isValidated: () => {
            setActiveTab(activeTab + 1);
        },
        sendState: () => {
            return {
                adversaires,
            };
        },
        state: {
            adversaires,
        },
    }));

    const param1 = useParams();

    const [adversaires, setAdversaires] = useState([
        {
            pieton: "oui",
            nom: "",
            prenom: "",
            profession: "",
            adresse: "",
            engin: {
                marque: "",
                puissance: "",
                immatriculation: "",
                usage: "PRIVE",
            },
            nombrePersonneTransportees: "",
            conducteur: {
                nom: "",
                prenom: "",
                numeroPermis: "",
                categoriePermis: "",
                dateDelivrance: "",
                lieuDelivrance: "",
                dateExpiration: "",
                qualite: "",
            },
            societeAssurance: "",
            typeSinistre: "",
            conduisait: "oui",
            provenance: "",
            destination: "",
            vitesse: 0,
        },
    ]);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (param1.id) {
            getSinistreById(param1.id)
                .then((res) => {
                    let advs = [];
                    res.data.victimes.forEach((victime) => {
                        if (victime.adversaire) {
                            advs.push({
                                pieton: victime?.engin?.immatriculation ? "non" : "oui",
                                nom: victime?.personne?.nom,
                                prenom: victime?.personne?.prenom,
                                profession: victime?.personne?.profession,
                                adresse: victime?.personne?.adresse,
                                engin: {
                                    marque: victime?.engin?.marque,
                                    puissance: victime?.engin?.puissance,
                                    immatriculation: victime?.engin?.immatriculation,
                                    usage: victime?.engin?.usage,
                                },
                                nombrePersonneTransportees: victime?.nombrePersonneTransportees,
                                provenance: victime?.provenance,
                                destination: victime?.destination,
                                vitesse: victime?.vitesse,
                                typeSinistre: victime?.typeSinistre,
                            });
                        }
                    });
                    setAdversaires(advs);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [param1.id]);

    const [informationsAdversaire, setInformationsAdversaire] = useState({
        pieton: true,
        nom: null,
        prenom: null,
        profession: null,
        "engin.marque": null,
        "engin.puissance": null,
        "engin.immatriculation": null,
        "engin.usage": null,
        nombrePersonneTransportees: null,
        conduisait: "oui",
        "conducteur.nom": null,
        "conducteur.prenom": null,
        "conducteur.numeroPermis": null,
        "conducteur.categoriePermis": "B",
        "conducteur.lieuDelivrance": null,
        "conducteur.dateDelivrance": null,
        "conducteur.qualite": null,
        "societe-d-assurance-de-l-adversaire": null,
        provenance: null,
        destination: null,
        vitesse: 0,
        typeSinistre: null,
    });

    const [errorState, setErrorState] = useState({
        pieton: true,
        nom: null,
        prenom: null,
        profession: null,
        "engin.marque": null,
        "engin.puissance": null,
        "engin.immatriculation": null,
        "engin.usage": null,
        nombrePersonneTransportees: null,
        "conducteur.nom": null,
        "conducteur.prenom": null,
        "conducteur.numeroPermis": null,
        "conducteur.lieuDelivrance": null,
        "conducteur.dateDelivrance": null,
        "conducteur.qualite": null,
        "societe-d-assurance-de-l-adversaire": null,
        provenance: null,
        destination: null,
        vitesse: null,
        typeSinistre: 0,
    });

    const listeDesCategoriesDePermis = [
        {value: "A1", label: "A1"},
        {value: "B", label: "B"},
        {value: "C", label: "C"},
        {value: "D", label: "D"},
    ];

    const listeUsage = [
        {value: "PRIVE", label: "PRIVÉ"},
        {value: "TAXI", label: "TAXI"},
    ];

    const listeDesQualitesDuConducteur = [
        {value: "propriétaire", label: "Propriétaire"},
        {value: "locataire", label: "Locataire"},
        {value: "emprunteur", label: "Emprunteur"},
        {value: "salarié", label: "Salarié"},
    ];

    const listeDesTypesDeDommagesPossibles = [
        {value: "MATERIEL", label: "Matériel"},
        {value: "CORPOREL", label: "Corporel"},
        {value: "MIXTE", label: "Mixte"},
    ];

    const addFormFields = () => {
        setAdversaires([
            ...adversaires,
            {
                pieton: "oui",
                nom: "",
                prenom: "",
                profession: "",
                adresse: "",
                engin: {
                    marque: "",
                    puissance: "",
                    immatriculation: "",
                    usage: "PRIVE",
                },
                nombrePersonneTransportees: "",
                conducteur: {
                    nom: "",
                    prenom: "",
                    numeroPermis: "",
                    categoriePermis: "",
                    dateDelivrance: "",
                    lieuDelivrance: "",
                    dateExpiration: "",
                    qualite: "",
                },
                societeAssurance: "",
                typeSinistre: "",
                conduisait: "oui",
                provenance: "",
                destination: "",
                vitesse: "",
            },
        ]);
    };
    console.log(errorState)

    const removeFormFields = (index) => {
        const newFormFields = [...adversaires];
        newFormFields.splice(index, 1);
        setAdversaires(newFormFields);
    };

    const handleChange = (e, name, index) => {
        let newAdversaires = [...adversaires];
        if (e.target) {
            const {value} = e.target;
            if (e.target.name.split(".").length > 1) {
                let targetId = e.target.name.split(".")[1];
                if (e.target.name.split(".")[0] === "engin") {
                    newAdversaires[index]["engin"][targetId] = value;
                } else if (e.target.name.split(".")[0] === "conducteur") {
                    newAdversaires[index]["conducteur"][targetId] = value;
                }
            } else {
                // console.log("id", id);
                newAdversaires[index][e.target.name] = value;
            }
        } else {
            if (name.split(".").length > 1) {
                let targetId = name.split(".")[1];
                if (name.split(".")[0] === "engin") {
                    newAdversaires[index]["engin"][targetId] = e;
                } else if (name.split(".")[0] === "conducteur") {
                    newAdversaires[index]["conducteur"][targetId] = e;
                }
            } else {
                newAdversaires[index][name] = e;
            }
        }
        setAdversaires(newAdversaires);
    };

    useEffect(() => {
        // console.log("ad", adversaires);
        const dts = [];
        adversaires?.forEach((ad, index) => {
            dts.push({
                key: index + 1,
                label: `Adversaire ${index + 1}`,
                children: (
                    <>
                        <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="pieton"
                                    style={{margin: "30px 0 10px 0"}}
                                >
                                    Piéton?:
                                </label>
                                <Radio.Group
                                    name="pieton"
                                    id="pieton"
                                    defaultValue={"oui"}
                                    value={ad.pieton}
                                    onChange={(event) => {
                                        handleChange(event, "pieton", index);
                                    }}
                                >
                                    <Radio value={"oui"}>Oui</Radio>
                                    <Radio value={"non"}>Non</Radio>
                                </Radio.Group>
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="nom"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["nom"] === true ? "red" : null,
                                    }}
                                >
                                    Nom de l&apos;adversaire:
                                </label>
                                <Input
                                    id="nom"
                                    name="nom"
                                    placeholder="Nom de l'adversaire"
                                    status={errorState["nom"] === true ? "error" : null}
                                    value={ad.nom}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "nom",
                                            informationsAdversaire["nom"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        handleChange(event, "nom", index);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="prenom"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["prenom"] === true ? "red" : null,
                                    }}
                                >
                                    Prénoms de l&apos;adversaire:
                                </label>
                                <Input
                                    id="prenom"
                                    name="prenom"
                                    placeholder="Prénoms de l'adversaire"
                                    value={ad.prenom}
                                    status={errorState["prenom"] === true ? "error" : null}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "prenom",
                                            informationsAdversaire["prenom"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        handleChange(event, "prenom", index);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="profession"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["profession"] === true ? "red" : null,
                                    }}
                                >
                                    Profession de l&apos;adversaire:
                                </label>
                                <Input
                                    id="profession"
                                    name="profession"
                                    placeholder="Profession de l'adversaire"
                                    value={ad.profession}
                                    status={errorState["profession"] === true ? "error" : null}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "profession",
                                            informationsAdversaire["profession"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(event, "profession", index);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="adresse"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["adresse-de-l-adversaire"] === true
                                                ? "red"
                                                : null,
                                    }}
                                >
                                    Adresse de l&apos;adversaire:
                                </label>
                                <Input
                                    id="adresse"
                                    name="adresse"
                                    placeholder="Adresse de l'adversaire"
                                    value={ad.adresse}
                                    status={
                                        errorState["adresse-de-l-adversaire"] === true
                                            ? "error"
                                            : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "adresse-de-l-adversaire",
                                            informationsAdversaire["adresse-de-l-adversaire"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(event, "adresse", index);
                                    }}
                                />
                            </div>
                            {ad.pieton === "oui" ? null : (
                                <>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="engin.marque"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["engin.marque"] === true ? "red" : null,
                                            }}
                                        >
                                            Marque du véhicule de l&apos;adversaire:
                                        </label>
                                        <Input
                                            id="engin.marque"
                                            name="engin.marque"
                                            placeholder="Marque du véhicule de l'adversaire"
                                            value={ad.engin.marque}
                                            status={
                                                errorState["engin.marque"] === true ? "error" : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "engin.marque",
                                                    informationsAdversaire["engin.marque"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "string"
                                                );

                                                handleChange(event, "engin.marque", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="engin.puissance"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["engin.puissance"] === true ? "red" : null,
                                            }}
                                        >
                                            Force du véhicule de l&apos;adversaire:
                                        </label>
                                        <Input
                                            id="engin.puissance"
                                            name="engin.puissance"
                                            placeholder="Force du véhicule de l'adversaire"
                                            type="number"
                                            value={ad.engin.puissance}
                                            status={
                                                errorState["engin.puissance"] === true ? "error" : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "engin.puissance",
                                                    informationsAdversaire["engin.puissance"],
                                                    setErrorState,
                                                    "number"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "number"
                                                );

                                                handleChange(event, "engin.puissance", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="engin.immatriculation"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["engin.immatriculation"] === true
                                                        ? "red"
                                                        : null,
                                            }}
                                        >
                                            Numéro de la plaque du véhicule de l&apos;adversaire:
                                        </label>
                                        <Input
                                            id="engin.immatriculation"
                                            name="engin.immatriculation"
                                            placeholder="Numéro de la plaque du véhicule de l'adversaire"
                                            value={ad.engin.immatriculation}
                                            status={
                                                errorState["engin.immatriculation"] === true
                                                    ? "error"
                                                    : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "engin.immatriculation",
                                                    informationsAdversaire["engin.immatriculation"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "string"
                                                );

                                                handleChange(event, "engin.immatriculation", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="usage"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color: errorState["usage"] === true ? "red" : null,
                                            }}
                                        >
                                            Usage du véhicule de l&apos;adversaire:
                                        </label>
                                        <Select
                                            name="usage"
                                            id="usage"
                                            defaultValue="PRIVE"
                                            value={ad.engin.usage}
                                            options={listeUsage}
                                            style={{width: "100%"}}
                                            onChange={(valeur) => {
                                                handleChange(valeur, "engin.usage", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="nombrePersonneTransportees"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["nombrePersonneTransportees"] === true
                                                        ? "red"
                                                        : null,
                                            }}
                                        >
                                            Nombre de personnes transportées:
                                        </label>
                                        <Input
                                            id="nombrePersonneTransportees"
                                            name="nombrePersonneTransportees"
                                            placeholder="Nombre de personnes transportées"
                                            value={ad.nombrePersonneTransportees}
                                            type="number"
                                            status={
                                                errorState["nombrePersonneTransportees"] === true
                                                    ? "error"
                                                    : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "nombrePersonneTransportees",
                                                    informationsAdversaire["nombrePersonneTransportees"],
                                                    setErrorState,
                                                    "number"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "number"
                                                );

                                                handleChange(
                                                    event,
                                                    "nombrePersonneTransportees",
                                                    index
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="conduisait"
                                            style={{margin: "30px 0 10px 0"}}
                                        >
                                            L&apos;adversaire conduisait-il?:
                                        </label>
                                        <Radio.Group
                                            name="conduisait"
                                            id="conduisait"
                                            defaultValue={"oui"}
                                            value={ad.conduisait}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire
                                                );

                                                handleChange(event, "conduisait", index);
                                            }}
                                        >
                                            <Radio value={"oui"}>Oui</Radio>
                                            <Radio value={"non"}>Non</Radio>
                                        </Radio.Group>
                                    </div>
                                </>
                            )}

                            {/* FORMULAIRE LORSQUE LE CONDUCTEUR EST DIFFERENT DE L'ADVERSAIRE */}
                            {ad.conduisait === "non" && (
                                <>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="conducteur.nom"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["conducteur.nom"] === true ? "red" : null,
                                            }}
                                        >
                                            Nom du conducteur:
                                        </label>
                                        <Input
                                            id="conducteur.nom"
                                            name="conducteur.nom"
                                            placeholder="Nom du conducteur"
                                            value={ad.conducteur.nom}
                                            status={
                                                errorState["conducteur.nom"] === true ? "error" : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "conducteur.nom",
                                                    informationsAdversaire["conducteur.nom"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "string"
                                                );

                                                handleChange(event, "conducteur.nom", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="conducteur.prenom"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["conducteur.prenom"] === true
                                                        ? "red"
                                                        : null,
                                            }}
                                        >
                                            Prénoms du conducteur:
                                        </label>
                                        <Input
                                            id="conducteur.prenom"
                                            name="conducteur.prenom"
                                            placeholder="Prénoms du conducteur"
                                            value={ad.conducteur.prenom}
                                            status={
                                                errorState["conducteur.prenom"] === true
                                                    ? "error"
                                                    : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "conducteur.prenom",
                                                    informationsAdversaire["conducteur.prenom"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "string"
                                                );

                                                handleChange(event, "conducteur.prenom", index);
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            {/* FIN DU FORMULAIRE LORSQUE LE CONDUCTEUR EST DIFFERENT DE L'ADVERSAIRE */}
                            {ad.pieton === "oui" ? null : (
                                <>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="conducteur.numeroPermis"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["conducteur.numeroPermis"] === true
                                                        ? "red"
                                                        : null,
                                            }}
                                        >
                                            Numéro du permis du conducteur:
                                        </label>
                                        <Input
                                            id="conducteur.numeroPermis"
                                            name="conducteur.numeroPermis"
                                            type="number"
                                            placeholder="Numéro du permis du conducteur"
                                            value={ad.conducteur.numeroPermis}
                                            status={
                                                errorState["conducteur.numeroPermis"] === true
                                                    ? "error"
                                                    : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "conducteur.numeroPermis",
                                                    informationsAdversaire["conducteur.numeroPermis"],
                                                    setErrorState,
                                                    "number"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "number"
                                                );

                                                handleChange(event, "conducteur.numeroPermis", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="conducteur.categoriePermis"
                                            style={{margin: "30px 0 10px 0"}}
                                        >
                                            Catégorie du permis du conducteur:
                                        </label>
                                        <Select
                                            name="conducteur.categoriePermis"
                                            id="conducteur.categoriePermis"
                                            defaultValue="B"
                                            value={ad.conducteur.categoriePermis}
                                            options={listeDesCategoriesDePermis}
                                            style={{width: "100%"}}
                                            onChange={(valeur) => {
                                                setValue(
                                                    valeur,
                                                    "conducteur.categoriePermis",
                                                    setInformationsAdversaire
                                                );

                                                handleChange(
                                                    valeur,
                                                    "conducteur.categoriePermis",
                                                    index
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="conducteur.lieuDelivrance"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["conducteur.lieuDelivrance"] === true
                                                        ? "red"
                                                        : null,
                                            }}
                                        >
                                            Lieu de délivrance du permis du conducteur:
                                        </label>
                                        <Input
                                            id="conducteur.lieuDelivrance"
                                            name="conducteur.lieuDelivrance"
                                            placeholder="Lieu de délivrance du permis du conducteur"
                                            value={ad.conducteur.lieuDelivrance}
                                            status={
                                                errorState["conducteur.lieuDelivrance"] === true
                                                    ? "error"
                                                    : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "conducteur.lieuDelivrance",
                                                    informationsAdversaire["conducteur.lieuDelivrance"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "string"
                                                );

                                                handleChange(
                                                    event,
                                                    "conducteur.lieuDelivrancve",
                                                    index
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="conducteur.dateDelivrance"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["conducteur.dateDelivrance"] === true
                                                        ? "red"
                                                        : null,
                                            }}
                                        >
                                            Date de délivrance du permis du conducteur:
                                        </label>
                                        <Input
                                            id="conducteur.dateDelivrance"
                                            name="conducteur.dateDelivrance"
                                            type="date"
                                            value={ad.conducteur.dateDelivrance}
                                            status={
                                                errorState["conducteur.dateDelivrance"] === true
                                                    ? "error"
                                                    : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "conducteur.dateDelivrance",
                                                    informationsAdversaire["conducteur.dateDelivrance"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "string"
                                                );

                                                handleChange(event, "conducteur.dateDelivrance", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="conducteur.qualite"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["conducteur.qualite"] === true
                                                        ? "red"
                                                        : null,
                                            }}
                                        >
                                            Qualité du conducteur:
                                        </label>
                                        <Select
                                            name="conducteur.qualite"
                                            id="conducteur.qualite"
                                            options={listeDesQualitesDuConducteur}
                                            value={ad.conducteur.qualite}
                                            style={{width: "100%"}}
                                            placeholder="Sélectionnez"
                                            status={
                                                errorState["conducteur.qualite"] === true
                                                    ? "error"
                                                    : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "conducteur.qualite",
                                                    informationsAdversaire["conducteur.qualite"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(valeur) => {
                                                setValue(
                                                    valeur,
                                                    "conducteur.qualite",
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "string"
                                                );

                                                handleChange(valeur, "conducteur.qualite", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="societeAssurance"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["societe-d-assurance-de-l-adversaire"] ===
                                                    true
                                                        ? "red"
                                                        : null,
                                            }}
                                        >
                                            Société d&apos;assurance de l&apos;adversaire:
                                        </label>
                                        <Input
                                            id="societeAssurance"
                                            name="societeAssurance"
                                            placeholder="Société d'assurance de l'adversaire"
                                            value={ad.societeAssurance}
                                            status={
                                                errorState["societe-d-assurance-de-l-adversaire"] ===
                                                true
                                                    ? "error"
                                                    : null
                                            }
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "societe-d-assurance-de-l-adversaire",
                                                    informationsAdversaire[
                                                        "societe-d-assurance-de-l-adversaire"
                                                        ],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                setValue(
                                                    getvalue(event),
                                                    getIdentificateur(event),
                                                    setInformationsAdversaire,
                                                    setErrorState,
                                                    "string"
                                                );

                                                handleChange(event, "societeAssurance", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="provenance"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color: errorState["provenance"] === true ? "red" : null,
                                            }}
                                        >
                                            L&apos;engin de l&apos;adversaire venant de:
                                        </label>
                                        <Input
                                            id="provenance"
                                            name="provenance"
                                            placeholder="Lieu de provenance de la voiture de l'adversaire"
                                            status={
                                                errorState["provenance"] === true ? "error" : null
                                            }
                                            value={ad.provenance}
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "provenance",
                                                    informationsAdversaire["provenance"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "provenance", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="destination"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color:
                                                    errorState["destination"] === true ? "red" : null,
                                            }}
                                        >
                                            L&apos;engin de l&apos;adversaire se dirigeait vers:
                                        </label>
                                        <Input
                                            id="destination"
                                            name="destination"
                                            placeholder="Destination de la voiture de l'adversaire"
                                            status={
                                                errorState["destination"] === true ? "error" : null
                                            }
                                            value={ad.destination}
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "destination",
                                                    informationsAdversaire["destination"],
                                                    setErrorState,
                                                    "string"
                                                );
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "destination", index);
                                            }}
                                        />
                                    </div>
                                    <div className={'grid-item'}>
                                        <label
                                            htmlFor="vitesse"
                                            style={{
                                                margin: "30px 0 10px 0",
                                                color: errorState["vitesse"] === true ? "red" : null,
                                            }}
                                        >
                                            A quelle vitesse allait l&apos;engin de
                                            l&apos;adversaire?:
                                        </label>
                                        <Input
                                            id="vitesse"
                                            name="vitesse"
                                            placeholder="Entrez la vitesse en km/h"
                                            type="number"
                                            value={ad.vitesse}
                                            status={errorState["vitesse"] === true ? "error" : null}
                                            onBlur={() => {
                                                errorStateChecker(
                                                    "vitesse",
                                                    informationsAdversaire["vitesse"],
                                                    setErrorState,
                                                    "number"
                                                );
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "vitesse", 0);
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="typeSinistre"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["typeSinistre"] === true ? "red" : null,
                                    }}
                                >
                                    Type de dommages chez l&apos;adversaire:
                                </label>
                                <Select
                                    name="typeSinistre"
                                    id="typeSinistre"
                                    placeholder="Sélectionnez"
                                    value={ad.typeDommage}
                                    options={listeDesTypesDeDommagesPossibles}
                                    style={{width: "100%"}}
                                    status={errorState["typeSinistre"] === true ? "error" : null}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "typeSinistre",
                                            informationsAdversaire["typeSinistre"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(valeur) => {
                                        setValue(
                                            valeur,
                                            "typeSinistre",
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(valeur, "typeSinistre", index);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
                            <div className={'grid-item'}></div>
                            <div className={'grid-item'}></div>
                            <div className={'grid-item'}>
                                {index ? (
                                    <Button
                                        onClick={() => removeFormFields(index)}
                                        color="danger"
                                        type="button"
                                    >
                                        <CloseOutlined/> {""}
                                        {""} Supprimer
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </>
                ),
            });
        });
        setData(dts);
    }, [adversaires]);

    return (
        <>
            {adversaires?.length === 1 ? (
                <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
                    <Title style={{
                        fontSize: '26px'
                    }}> Informations sur l'adversaire </Title>
                    <div className={'grid-item'}>
                        <label htmlFor="pieton" style={{margin: "30px 0 10px 0"}}>
                            Piéton?:
                        </label>
                        <Radio.Group
                            name="pieton"
                            id="pieton"
                            defaultValue={"oui"}
                            value={adversaires[0].pieton}
                            onChange={(event) => {
                                // setPieton(event.target.value === "oui" ? true : false);

                                handleChange(event, "pieton", 0);
                            }}
                        >
                            <Radio value={"oui"}>Oui</Radio>
                            <Radio value={"non"}>Non</Radio>
                        </Radio.Group>
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="nom"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["nom"] === true ? "red" : null,
                            }}
                        >
                            Nom de l&apos;adversaire:
                        </label>
                        <Input
                            id="nom"
                            name="nom"
                            placeholder="Nom de l'adversaire"
                            status={errorState["nom"] === true ? "error" : null}
                            value={adversaires[0].nom}
                            onBlur={() => {
                                errorStateChecker(
                                    "nom",
                                    informationsAdversaire["nom"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                handleChange(event, "nom", 0);
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="prenom"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["prenom"] === true ? "red" : null,
                            }}
                        >
                            Prénoms de l&apos;adversaire:
                        </label>
                        <Input
                            id="prenom"
                            name="prenom"
                            placeholder="Prénoms de l'adversaire"
                            value={adversaires[0].prenom}
                            status={errorState["prenom"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "prenom",
                                    informationsAdversaire["prenom"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                handleChange(event, "prenom", 0);
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="profession"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["profession"] === true ? "red" : null,
                            }}
                        >
                            Profession de l&apos;adversaire:
                        </label>
                        <Input
                            id="profession"
                            name="profession"
                            placeholder="Profession de l'adversaire"
                            value={adversaires[0].profession}
                            status={errorState["profession"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "profession",
                                    informationsAdversaire["profession"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setInformationsAdversaire,
                                    setErrorState,
                                    "string"
                                );

                                handleChange(event, "profession", 0);
                            }}
                        />
                    </div>
                    <div className={'grid-item'}>
                        <label
                            htmlFor="adresse"
                            style={{
                                margin: "30px 0 10px 0",
                                color:
                                    errorState["adresse-de-l-adversaire"] === true ? "red" : null,
                            }}
                        >
                            Adresse de l&apos;adversaire:
                        </label>
                        <Input
                            id="adresse"
                            name="adresse"
                            placeholder="Adresse de l'adversaire"
                            value={adversaires[0].adresse}
                            status={
                                errorState["adresse-de-l-adversaire"] === true ? "error" : null
                            }
                            onBlur={() => {
                                errorStateChecker(
                                    "adresse-de-l-adversaire",
                                    informationsAdversaire["adresse-de-l-adversaire"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(event) => {
                                setValue(
                                    getvalue(event),
                                    getIdentificateur(event),
                                    setInformationsAdversaire,
                                    setErrorState,
                                    "string"
                                );

                                handleChange(event, "adresse", 0);
                            }}
                        />
                    </div>
                    {adversaires[0].pieton === "oui" ? null : (
                        <>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="engin.marque"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["engin.marque"] === true ? "red" : null,
                                    }}
                                >
                                    Marque du véhicule de l&apos;adversaire:
                                </label>
                                <Input
                                    id="engin.marque"
                                    name="engin.marque"
                                    placeholder="Marque du véhicule de l'adversaire"
                                    value={adversaires[0].engin.marque}
                                    status={errorState["engin.marque"] === true ? "error" : null}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "engin.marque",
                                            informationsAdversaire["engin.marque"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(event, "engin.marque", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="engin.puissance"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["engin.puissance"] === true ? "red" : null,
                                    }}
                                >
                                    Force du véhicule de l&apos;adversaire:
                                </label>
                                <Input
                                    id="engin.puissance"
                                    name="engin.puissance"
                                    placeholder="Force du véhicule de l'adversaire"
                                    type="number"
                                    value={adversaires[0].engin.puissance}
                                    status={
                                        errorState["engin.puissance"] === true ? "error" : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "engin.puissance",
                                            informationsAdversaire["engin.puissance"],
                                            setErrorState,
                                            "number"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "number"
                                        );

                                        handleChange(event, "engin.puissance", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="engin.immatriculation"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["engin.immatriculation"] === true
                                                ? "red"
                                                : null,
                                    }}
                                >
                                    Numéro de la plaque du véhicule de l&apos;adversaire:
                                </label>
                                <Input
                                    id="engin.immatriculation"
                                    name="engin.immatriculation"
                                    placeholder="Numéro de la plaque du véhicule de l'adversaire"
                                    value={adversaires[0].engin.immatriculation}
                                    status={
                                        errorState["engin.immatriculation"] === true
                                            ? "error"
                                            : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "engin.immatriculation",
                                            informationsAdversaire["engin.immatriculation"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );
                                        handleChange(event, "engin.immatriculation", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="usage"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["usage"] === true ? "red" : null,
                                    }}
                                >
                                    Usage du véhicule de l&apos;adversaire:
                                </label>
                                <Select
                                    name="usage"
                                    id="usage"
                                    defaultValue="PRIVE"
                                    value={adversaires[0].engin.usage}
                                    options={listeUsage}
                                    style={{width: "100%"}}
                                    onChange={(valeur) => {
                                        setValue(valeur, "usage", setInformationsAdversaire);
                                        handleChange(valeur, "engin.usage", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="nombrePersonneTransportees"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["nombrePersonneTransportees"] === true
                                                ? "red"
                                                : null,
                                    }}
                                >
                                    Nombre de personnes transportées:
                                </label>
                                <Input
                                    id="nombrePersonneTransportees"
                                    name="nombrePersonneTransportees"
                                    placeholder="Nombre de personnes transportées"
                                    value={adversaires[0].nombrePersonneTransportees}
                                    type="number"
                                    status={
                                        errorState["nombrePersonneTransportees"] === true
                                            ? "error"
                                            : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "nombrePersonneTransportees",
                                            informationsAdversaire["nombrePersonneTransportees"],
                                            setErrorState,
                                            "number"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "number"
                                        );

                                        handleChange(event, "nombrePersonneTransportees", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="conduisait"
                                    style={{margin: "30px 0 10px 0"}}
                                >
                                    L&apos;adversaire conduisait-il?:
                                </label>
                                <Radio.Group
                                    name="conduisait"
                                    id="conduisait"
                                    defaultValue={"oui"}
                                    value={adversaires[0].conduisait}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire
                                        );

                                        handleChange(event, "conduisait", 0);
                                    }}
                                >
                                    <Radio value={"oui"}>Oui</Radio>
                                    <Radio value={"non"}>Non</Radio>
                                </Radio.Group>
                            </div>
                        </>
                    )}

                    {adversaires[0].conduisait == "non" && (
                        <>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="conducteur.nom"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["conducteur.nom"] === true ? "red" : null,
                                    }}
                                >
                                    Nom du conducteur:
                                </label>
                                <Input
                                    id="conducteur.nom"
                                    name="conducteur.nom"
                                    placeholder="Nom du conducteur"
                                    value={adversaires[0].conducteur.nom}
                                    status={
                                        errorState["conducteur.nom"] === true ? "error" : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "conducteur.nom",
                                            informationsAdversaire["conducteur.nom"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(event, "conducteur.nom", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="conducteur.prenom"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["conducteur.prenom"] === true ? "red" : null,
                                    }}
                                >
                                    Prénoms du conducteur:
                                </label>
                                <Input
                                    id="conducteur.prenom"
                                    name="conducteur.prenom"
                                    placeholder="Prénoms du conducteur"
                                    value={adversaires[0].conducteur.prenom}
                                    status={
                                        errorState["conducteur.prenom"] === true ? "error" : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "conducteur.prenom",
                                            informationsAdversaire["conducteur.prenom"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(event, "conducteur.prenom", 0);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {/* FIN DU FORMULAIRE LORSQUE LE CONDUCTEUR EST DIFFERENT DE L'ADVERSAIRE */}
                    {adversaires[0].pieton === "oui" ? null : (
                        <>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="conducteur.numeroPermis"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["conducteur.numeroPermis"] === true
                                                ? "red"
                                                : null,
                                    }}
                                >
                                    Numéro du permis du conducteur:
                                </label>
                                <Input
                                    id="conducteur.numeroPermis"
                                    name="conducteur.numeroPermis"
                                    type="number"
                                    placeholder="Numéro du permis du conducteur"
                                    value={adversaires[0].conducteur.numeroPermis}
                                    status={
                                        errorState["conducteur.numeroPermis"] === true
                                            ? "error"
                                            : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "conducteur.numeroPermis",
                                            informationsAdversaire["conducteur.numeroPermis"],
                                            setErrorState,
                                            "number"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "number"
                                        );

                                        handleChange(event, "conducteur.numeroPermis", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="conducteur.categoriePermis"
                                    style={{margin: "30px 0 10px 0"}}
                                >
                                    Catégorie du permis du conducteur:
                                </label>
                                <Select
                                    name="conducteur.categoriePermis"
                                    id="conducteur.categoriePermis"
                                    defaultValue="B"
                                    value={adversaires[0].conducteur.categoriePermis}
                                    options={listeDesCategoriesDePermis}
                                    style={{width: "100%"}}
                                    onChange={(valeur) => {
                                        setValue(
                                            valeur,
                                            "conducteur.categoriePermis",
                                            setInformationsAdversaire
                                        );

                                        handleChange(valeur, "conducteur.categoriePermis", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="conducteur.lieuDelivrance"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["conducteur.lieuDelivrance"] === true
                                                ? "red"
                                                : null,
                                    }}
                                >
                                    Lieu de délivrance du permis du conducteur:
                                </label>
                                <Input
                                    id="conducteur.lieuDelivrance"
                                    name="conducteur.lieuDelivrance"
                                    placeholder="Lieu de délivrance du permis du conducteur"
                                    value={adversaires[0].conducteur.lieuDelivrance}
                                    status={
                                        errorState["conducteur.lieuDelivrance"] === true
                                            ? "error"
                                            : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "conducteur.lieuDelivrance",
                                            informationsAdversaire["conducteur.lieuDelivrance"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(event, "conducteur.lieuDelivrancve", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="conducteur.dateDelivrance"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["conducteur.dateDelivrance"] === true
                                                ? "red"
                                                : null,
                                    }}
                                >
                                    Date de délivrance du permis du conducteur:
                                </label>
                                <Input
                                    id="conducteur.dateDelivrance"
                                    name="conducteur.dateDelivrance"
                                    type="date"
                                    value={adversaires[0].conducteur.dateDelivrance}
                                    status={
                                        errorState["conducteur.dateDelivrance"] === true
                                            ? "error"
                                            : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "conducteur.dateDelivrance",
                                            informationsAdversaire["conducteur.dateDelivrance"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(event, "conducteur.dateDelivrance", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="conducteur.qualite"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["conducteur.qualite"] === true ? "red" : null,
                                    }}
                                >
                                    Qualité du conducteur:
                                </label>
                                <Select
                                    name="conducteur.qualite"
                                    id="conducteur.qualite"
                                    options={listeDesQualitesDuConducteur}
                                    value={adversaires[0].conducteur.qualite}
                                    style={{width: "100%"}}
                                    placeholder="Sélectionnez"
                                    status={
                                        errorState["conducteur.qualite"] === true ? "error" : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "conducteur.qualite",
                                            informationsAdversaire["conducteur.qualite"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(valeur) => {
                                        setValue(
                                            valeur,
                                            "conducteur.qualite",
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(valeur, "conducteur.qualite", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="societeAssurance"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color:
                                            errorState["societe-d-assurance-de-l-adversaire"] === true
                                                ? "red"
                                                : null,
                                    }}
                                >
                                    Société d&apos;assurance de l&apos;adversaire:
                                </label>
                                <Input
                                    id="societeAssurance"
                                    name="societeAssurance"
                                    placeholder="Société d'assurance de l'adversaire"
                                    value={adversaires[0].societeAssurance}
                                    status={
                                        errorState["societe-d-assurance-de-l-adversaire"] === true
                                            ? "error"
                                            : null
                                    }
                                    onBlur={() => {
                                        errorStateChecker(
                                            "societe-d-assurance-de-l-adversaire",
                                            informationsAdversaire[
                                                "societe-d-assurance-de-l-adversaire"
                                                ],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        setValue(
                                            getvalue(event),
                                            getIdentificateur(event),
                                            setInformationsAdversaire,
                                            setErrorState,
                                            "string"
                                        );

                                        handleChange(event, "societeAssurance", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="provenance"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["provenance"] === true ? "red" : null,
                                    }}
                                >
                                    La voiture l&apos;adversaire venant de:
                                </label>
                                <Input
                                    id="provenance"
                                    name="provenance"
                                    placeholder="Lieu de provenance de la voiture de l'adversaire"
                                    status={errorState["provenance"] === true ? "error" : null}
                                    value={adversaires[0].provenance}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "provenance",
                                            informationsAdversaire["provenance"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        handleChange(event, "provenance", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="destination"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["destination"] === true ? "red" : null,
                                    }}
                                >
                                    La voiture l&apos;adversaire se dirigeait vers:
                                </label>
                                <Input
                                    id="destination"
                                    name="destination"
                                    placeholder="Destination de la voiture de l'adversaire"
                                    status={errorState["destination"] === true ? "error" : null}
                                    value={adversaires[0].destination}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "destination",
                                            informationsAdversaire["destination"],
                                            setErrorState,
                                            "string"
                                        );
                                    }}
                                    onChange={(event) => {
                                        handleChange(event, "destination", 0);
                                    }}
                                />
                            </div>
                            <div className={'grid-item'}>
                                <label
                                    htmlFor="vitesse"
                                    style={{
                                        margin: "30px 0 10px 0",
                                        color: errorState["vitesse"] === true ? "red" : null,
                                    }}
                                >
                                    À quelle vitesse allait la voiture de l&apos;adversaire?:
                                </label>
                                <Input
                                    id="vitesse"
                                    name="vitesse"
                                    placeholder="Entrez la vitesse en km/h"
                                    type="number"
                                    value={adversaires[0].vitesse}
                                    status={errorState["vitesse"] === true ? "error" : null}
                                    onBlur={() => {
                                        errorStateChecker(
                                            "vitesse",
                                            informationsAdversaire["vitesse"],
                                            setErrorState,
                                            "number"
                                        );
                                    }}
                                    onChange={(event) => {
                                        handleChange(event, "vitesse", 0);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <div className={'grid-item'}>
                        <label
                            htmlFor="typeSinistre"
                            style={{
                                margin: "30px 0 10px 0",
                                color: errorState["typeSinistre"] === true ? "red" : null,
                            }}
                        >
                            Type de dommages chez l&apos;adversaire:
                        </label>
                        <Select
                            name="typeSinistre"
                            id="typeSinistre"
                            placeholder="Sélectionnez"
                            value={adversaires[0].typeSinistre}
                            options={listeDesTypesDeDommagesPossibles}
                            style={{width: "100%"}}
                            status={errorState["typeSinistre"] === true ? "error" : null}
                            onBlur={() => {
                                errorStateChecker(
                                    "typeSinistre",
                                    informationsAdversaire["typeSinistre"],
                                    setErrorState,
                                    "string"
                                );
                            }}
                            onChange={(valeur) => {
                                setValue(
                                    valeur,
                                    "typeSinistre",
                                    setInformationsAdversaire,
                                    setErrorState,
                                    "string"
                                );

                                handleChange(valeur, "typeSinistre", 0);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <Collapse items={data} defaultActiveKey={1}/>
                </>
            )}

            <div className={'grid-container grid container'} style={{justifyContent: 'center'}}>
                <br/>
                <br/>
                <Button onClick={() => addFormFields()} color="primary" type="button">
                    <PlusCircleOutlined/> {""}
                    {""} Ajouter un Adversaire
                </Button>
            </div>
        </>
    );
});

InformationsSurAdversaire.displayName = "InformationsSurAdversaire";

InformationsSurAdversaire.propTypes = {
    data: PropTypes.object,
    updateData: PropTypes.func,
};

export default InformationsSurAdversaire;
