import { Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { API_URL, TOKEN } from "../../variables/constants";

const AddProfil = () => {
  const [organismes, setOrganismes] = useState([]);
  const [modules, setModules] = useState([]);
  const [selectedOrganisme, setSelectedOrganisme] = useState({});
  const [selectedTypeProfil, setSelectedTypeProfil] = useState({});
  const [habilitations, setHabilitations] = useState([]);
  const [selectedHabilitations, setSelectedHabilitations] = useState([]);
  const [typeProfile, setTypeProfile] = useState(false);
  const [types, setTypes] = useState([]);
  const [profil, setProfil] = useState();
  const [formatedSelectedHabilitations, setFormatedSelectedHabilitations] =
    useState([]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    libelle: "",
    description: "",
    organisme: "",
    typeProfil: "",
  });

  const [choices, setChoices] = useState([]);

  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.libelle) newErrors.username = "Libellé requis";
    if (!formData.description) newErrors.email = "Description requise";
    if (!formData.organisme && !typeProfile)
      newErrors.organisme = "Organisme requis";
    if (typeProfile && !formData.typeProfil)
      newErrors.typeProfil = "Type de profil requis";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const param = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_URL}/api/organisme`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setOrganismes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${API_URL}/api/habilitation`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        setHabilitations(res.data);
        let habs = [];
        res.data.forEach((hab, id) => {
          habs.push({
            key: id,
            label: hab.libelle,
            value: hab.habilitationId,
          });
        });
        setFormatedSelectedHabilitations(habs);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${API_URL}/api/parametre/type/TYPE_PROFIL`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        // console.log("typeParam: ", res.data);
        setTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [TOKEN]);

  useEffect(() => {
    if (param.profilId) {
      axios
        .get(`${API_URL}/api/profil/codeId/${param.profilId}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        })
        .then((res) => {
          console.log(res.data);
          res.data?.organisme &&
            setSelectedOrganisme(res.data?.organisme?.organismeId);
          setProfil(res.data);
          setSelectedHabilitations(res.data.habilitations);
          // let c = [];
          // res.data.habilitations?.forEach(h => {
          //   c.push(h.habilitationId);
          // });
          // setChoices(c);
          setFormData({
            libelle: res.data.nom,
            description: res.data.commentaire,
            organisme: res.data?.organisme && res.data?.organisme.organismeId,
            typeProfil: "",
          });

          axios
            .get(`${API_URL}/api/module`, {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            })
            .then((response) => {
              let c = [];
              response.data.forEach((md, id) => {
                let habs = res.data.habilitations.filter(
                  (e) => e.module.moduleId === md.moduleId
                );
                let hs = [];
                if (habs) {
                  habs.forEach((h) => {
                    hs.push(h.habilitationId);
                  })
                }
                c.push(hs);
              });
              setChoices(c);
              setModules(response.data);
            })
            .catch((err) => {
              console.log(err);
            });

        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${API_URL}/api/module`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        })
        .then((res) => {
          let c = [];
          res.data.forEach((md) => {
            c.push([]);
          });
          setChoices(c);
          setModules(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [TOKEN, param.profilId]);

  const handleSelectChange = (value, id) => {
    // console.log("Selected habilitations: ", value);
    let c = [...choices];
    c[id] = value;
    let selectedHabs = [];
    c.forEach((habs) => {
      habs.forEach((el) => {
        let h = habilitations.find((e) => e.habilitationId === parseInt(el));
        selectedHabs.push(h);
      });
    });
    setChoices(c);
    setSelectedHabilitations(selectedHabs);
  };

  // Fonction qui filtre les habilitations afin de ressortir celles d'un module spécifique défini par son moduleId
  const getHabilitationsOfModule = (moduleId) => {
    let habs = habilitations.filter((e) => e.module.moduleId === moduleId);
    let opt = [];
    habs.forEach((hab, id) => {
      opt.push({
        key: id,
        label: hab.libelle,
        value: hab.habilitationId,
      });
    });
    return opt;
  };

  const getUpdateHabilitationsOfModule = (moduleId) => {
    // console.log("The choice: ", choices);
    let habs = selectedHabilitations.filter(
      (e) => e.module.moduleId === moduleId
    );
    // console.log("habits...: ", habs);
    let opt = [];
    habs.forEach((hab, id) => {
      opt.push({
        key: id,
        label: hab.libelle,
        value: hab.habilitationId,
      });
    });
    return opt;
  };

  const handleTypeProfilChange = (event) => {
    // console.log("vall:", event.target.value);
    setTypeProfile(event.target.value === "true" ? false : true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let org = organismes.find(
      (e) => e.organismeId === parseInt(formData.organisme)
    );
    if (param.profilId) {
      profil.nom = formData.libelle;
      profil.commentaire = formData.description;
      profil.habilitations = selectedHabilitations;
      profil.typeProfile = typeProfile && "PARAMETRE";
      profil.organisme = org;

      // console.log("Hable submit: ", profil);
      axios
        .put(`${API_URL}/api/profil`, JSON.stringify(profil), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          navigate("/profils");
          toast("Profil modifié avec succès");
        })
        .catch((err) => {
          console.log(err);
          toast("Erreur lors de l'ajout du profil");
          setLoading(false);
        });
    } else {
      let habit = {
        nom: formData.libelle,
        commentaire: formData.description,
        typeProfile: typeProfile && "PARAMETRE",
        habilitations: selectedHabilitations,
        organisme: org,
      };

      // console.log("Hable submit: ", habit);

      axios
        .post(`${API_URL}/api/profil`, JSON.stringify(habit), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        })
        .then((res) => {
          // console.log(res.data);
          setLoading(false);
          navigate("/profils");
          toast("Profil ajouté avec succès");
        })
        .catch((err) => {
          console.log(err);
          toast("Erreur lors de l'ajout du profil");
          setLoading(false);
        });
    }
  };

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"PROFILS"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row">
                <div class="row my-6">
                  <div class="col">
                    <h6>
                      {" "}
                      {param.profilId
                        ? "Modifier le"
                        : "Ajouter un nouveau"}{" "}
                      profil{" "}
                    </h6>
                    <div class="accordion" id="collapsibleSection">
                      <form onSubmit={handleSubmit}>
                        <div class="card accordion-item">
                          <h2
                            class="accordion-header"
                            id="headingDeliveryAddress"
                          >
                            <button
                              type="button"
                              class="accordion-button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseDeliveryAddress"
                              aria-expanded="true"
                              aria-controls="collapseDeliveryAddress"
                            >
                              {" "}
                              Informations sur le profil{" "}
                            </button>
                          </h2>
                          <div
                            id="collapseDeliveryAddress"
                            class="accordion-collapse collapse show"
                            data-bs-parent="#collapsibleSection"
                          >
                            <div class="accordion-body">
                              <div class="row g-6">
                                <div class="mb-6">
                                  <label
                                    class="form-label"
                                    for="basic-default-fullname"
                                  >
                                    Libellé
                                  </label>
                                  <input
                                    type="text"
                                    class={`form-control ${errors.libelle ? "is-invalid" : ""
                                      }`}
                                    id="libelle"
                                    placeholder="Libellé du profil"
                                    name="libelle"
                                    value={formData.libelle}
                                    onChange={handleInputChange}
                                  />
                                  {errors.libelle && (
                                    <div className="invalid-feedback">
                                      {errors.libelle}
                                    </div>
                                  )}
                                </div>
                                <div class="col mt-2">
                                  <div class="form-check form-check-inline">
                                    <input
                                      name="collapsible-address-type"
                                      class="form-check-input"
                                      type="radio"
                                      value={typeProfile}
                                      id="collapsible-address-type-home"
                                      checked={typeProfile}
                                      onChange={handleTypeProfilChange}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="collapsible-address-type-home"
                                    >
                                      Profil paramètre
                                    </label>
                                  </div>
                                </div>
                                {typeProfile ? (
                                  <div class="mb-6">
                                    <label class="form-label" for="typeProfil">
                                      Type de profil
                                    </label>
                                    <select
                                      id="typeProfil"
                                      name="typeProfil"
                                      class={`form-control form-select ${errors.typeProfil ? "is-invalid" : ""
                                        }`}
                                      value={formData.typeProfil}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">
                                        Sélectionner le type de profil
                                      </option>
                                      {types?.map((type, id) => (
                                        <option
                                          key={id}
                                          value={type.parametreId}
                                        >
                                          {type.libelleParametre}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ) : (
                                  <div class="mb-6">
                                    <label class="form-label" for="organisme">
                                      Organisme
                                    </label>
                                    <select
                                      id="organisme"
                                      name="organisme"
                                      class={`form-control form-select ${errors.organisme ? "is-invalid" : ""
                                        }`}
                                      value={formData.organisme}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">
                                        Sélectionner l'organisme
                                      </option>
                                      {organismes?.map((org, id) => (
                                        <option
                                          key={id}
                                          value={org.organismeId}
                                        >
                                          {org.raisonSociale}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                                <div class="mb-6">
                                  <label class="form-label" for="commentaire">
                                    Description
                                  </label>
                                  <textarea
                                    type="text"
                                    class={`form-control ${errors.description ? "is-invalid" : ""
                                      }`}
                                    id="description"
                                    name="description"
                                    placeholder="Une brève description du profil"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card accordion-item">
                          <h2
                            class="accordion-header"
                            id="headingDeliveryOptions"
                          >
                            <button
                              type="button"
                              class="accordion-button collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseDeliveryOptions"
                              aria-expanded="false"
                              aria-controls="collapseDeliveryOptions"
                            >
                              {" "}
                              Habilitations{" "}
                            </button>
                          </h2>
                          <div
                            id="collapseDeliveryOptions"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingDeliveryOptions"
                            data-bs-parent="#collapsibleSection"
                          >
                            <div class="accordion-body">
                              <div class="row">
                                {modules?.map((mod, id) => (
                                  <div
                                    class="col-md-12 select2-primary"
                                    key={id}
                                  >
                                    <label
                                      class="form-label"
                                      for="formtabs-language"
                                    >
                                      {mod?.nom}
                                    </label>
                                    <Select
                                      mode="multiple"
                                      style={{
                                        width: "100%",
                                      }}
                                      placeholder="Veuillez sélectionner les habilitations sur le module"
                                      onChange={(value) => handleSelectChange(value, id)}
                                      options={getHabilitationsOfModule(
                                        mod.moduleId
                                      )}
                                      defaultValue={
                                        param.profilId
                                          ? getUpdateHabilitationsOfModule(
                                            mod.moduleId
                                          )
                                          : []
                                      }
                                      value={getUpdateHabilitationsOfModule(
                                        mod.moduleId
                                      )}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <button
                          type="submit"
                          class="btn btn-primary"
                          disabled={loading}
                        >
                          {loading && (
                            <span
                              class="spinner-grow me-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          {param.profilId
                            ? loading
                              ? "Mise à jour en cours ..."
                              : "Mettre à jour"
                            : loading
                              ? "Enregistrement..."
                              : "Ajouter"}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default AddProfil;
