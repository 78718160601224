import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Modal, Select } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, TOKEN } from "../../../../variables/constants";

const TransfertGarantie = (props) => {
    const [loading, setLoading] = useState();
    const [contrat, setContrat] = useState();
    const [selectedEngin, setSelectedEngin] = useState();
    const [selectedEnginTransfert, setSelectedEnginTransfert] = useState();
    const [selectedEnginGuaranties, setSelectedEnginGuaranties] = useState([]);
    const [souscripteurEngins, setSouscripteurEngins] = useState();
    const [sousGaranties, setSousGaranties] = useState([]);
    const [garanties, setGaranties] = useState([]);

    const [modalTransfertGarantie, setModalTransfertGarantie] = useState(
        props.open
    );
    useEffect(() => {
        setModalTransfertGarantie(props.open);
        // console.log("PropContrat: ", props.contrat);
        setContrat(props.contrat);
    }, [props.open, props.contrat]);

    const getMultipleSelect = () => {
        return garanties ? garanties : [];
    };

    const param = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (param.contratId) {
            // console.log("PropContrat: ", props.contrat);
            // setContrat(props.contrat);
            axios
                .get(
                    `${API_URL}/api/engin/client/codeId/${props.contrat?.souscripteur?.codeId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                                "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    }
                )
                .then((res) => {
                    console.log("Souscriptions engins:", res.data);
                    setSouscripteurEngins(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [props.contrat?.souscripteur?.codeId, param.contratId]);

    const getEnginNonCompulsoryGuaranties = (engin) => {
        var dt = [];
        engin.sousGaranties.forEach((sg) => {
            dt.push(sg.garantie);
        });
        setSelectedEnginGuaranties(dt);
    };

    const handleGarantiesChange = (e) => {
        setGaranties(e.target.value);
    };

    const handleSousGarantie = (event) => {
        // console.log("EVENT: ", event.target.value);
        var gtys = [];
        if (event.target.value.length === 1) {
            gtys.push(JSON.parse(event.target.value));
        } else {
            event.target.value.forEach((element) => {
                try {
                    gtys.push(JSON.parse(element));
                } catch (err) {
                    gtys.push(element);
                }
            });
        }

        // console.log("GTY: ", gtys);
        axios
            .post(`${API_URL}/api/sous-garantie/garanties`, JSON.stringify(gtys), {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((ress) => {
                setSousGaranties(ress.data);
            })
            .catch((errr) => {
                console.log(errr);
            });
    };

    const handleTransfertGarantieSubmit = () => {
        setLoading(true);
        if (selectedEngin) {
            if (garanties) {
                if (selectedEnginTransfert) {
                    var engin = JSON.parse(selectedEnginTransfert);
                    engin.sousGaranties = sousGaranties;
                    axios
                        .put(
                            `${API_URL}/api/engin/transfert-garantie/codeId/${contrat?.codeId
                            }/${JSON.parse(selectedEngin)?.codeId}`,
                            JSON.stringify(engin),
                            {
                                headers: {
                                    Authorization: `Bearer ${TOKEN}`,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods":
                                        "GET, PUT, POST, DELETE, OPTIONS",
                                    "Access-Control-Allow-Headers":
                                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                },
                            }
                        )
                        .then((resEngin) => {
                            // console.log(resEngin.data);
                            contrat.codeId = resEngin.data.codeId;
                            toast("Transfert de garantie effectué!");
                            setLoading(false);
                            setModalTransfertGarantie(false);
                            props.update(false);
                            navigate(`/details-contrat/${resEngin.data.codeId}`);
                        })
                        .catch((errEngin) => {
                            console.log(errEngin);
                        });
                } else {
                    toast("Sélectionnez l'engin récepteur de garanties");
                }
            } else {
                toast("Veuillez sélectionner les garanties à transférer!");
            }
        } else {
            toast("Veuillez selectionner l'engin");
        }
    };

    const handleCancel = () => {
        setModalTransfertGarantie(false);
        props.update(false);
    };

    return (
        <Modal
            title="TRANSFERT DE GARANTIE(S)"
            confirmLoading={loading}
            centered
            open={modalTransfertGarantie}
            onOk={() => handleTransfertGarantieSubmit()}
            onCancel={() => handleCancel()}
        >
            <div className="row" justify="center">
                <div className="col-12">
                    <form>
                        <label
                            htmlFor="selectedEngin"
                            className="form-label"
                        >
                            Véhicule à changer
                        </label>
                        <Select
                            value={selectedEngin}
                            onChange={(event) => {
                                setSelectedEngin(event);
                                getEnginNonCompulsoryGuaranties(JSON.parse(event));
                            }}
                            options={contrat?.engins?.map((engin, index) => (
                                {
                                    label: `${engin.marque} - ${engin.numeroChassis}`,
                                    value: JSON.stringify(engin),
                                    key: index
                                }
                            ))}
                            style={{ width: "100%" }}
                        />
                    </form>
                </div>
                <div className="col-12">
                    <form >
                        <label
                            htmlFor="garantiesState"
                            className="form-label"
                        >
                            Garanties
                        </label>
                        <Select
                            mode="multiple"
                            value={getMultipleSelect()}
                            onChange={(event) => {
                                handleGarantiesChange(event);
                                handleSousGarantie(event);
                            }}
                            options={selectedEnginGuaranties?.map((guaranty, index) => (
                                {
                                    label: `${guaranty.nom}`,
                                    value: JSON.stringify(guaranty),
                                    key: index
                                }
                            ))}
                            style={{ width: "100%" }}
                        />
                    </form>
                </div>
                <div className="col-12" xs={12} sm={12} md={12} lg={10}>
                    <form>
                        <label
                            htmlFor="selectedEnginTransfert"
                            className="form-label"
                        >
                            Vehicule de transfert
                        </label>
                        <Select
                            value={selectedEnginTransfert}
                            onChange={(event) => {
                                setSelectedEnginTransfert(event.target.value);
                            }}
                            options={souscripteurEngins?.map((engin, index) => (
                                {
                                    label: `${engin.marque} - ${engin.numeroChassis}`,
                                    value: JSON.stringify(engin),
                                    key: index
                                }
                            ))}
                            style={{ width: "100%" }}
                        />
                    </form>
                </div>
            </div>
        </Modal>
    );
};

TransfertGarantie.propTypes = {
    open: PropTypes.bool,
    update: PropTypes.func,
    contrat: PropTypes.object
};

export default TransfertGarantie;