import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddAccessoires from "./AddAccessoires";

const Accessoires = () => {
  const [accessoires, setAccessoires] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAccessoire, setSelectedAccessoire] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleShow = (edit = false, accessoire = null) => {
    setEditMode(edit);
    setSelectedAccessoire(accessoire);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedAccessoire(null);
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/parametre/type/ACCESSOIRES`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        setAccessoires(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Erreur lors de la récupération des accessoires.");
      });
  }, [showModal]);

  const handleDelete = (parametreId) => {
    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer cet accessoire ?",
      icon: <ExclamationCircleOutlined />,
      content: "Cette action est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk: () => {
        axios
          .delete(`${API_URL}/api/parametre/${parametreId}`, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          })
          .then(() => {
            setAccessoires((prev) =>
              prev.filter((accessoire) => accessoire.parametreId !== parametreId)
            );
            message.success("Accessoire supprimé avec succès.");
          })
          .catch((err) => {
            console.log(err);
            message.error("Erreur lors de la suppression de l'accessoire.");
          });
      },
    });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu="TARIFICATION" />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow()}
              >
                Ajouter de nouveaux frais accessoires
              </Button>
              <div className="card">
                <h5 className="card-header">Paramétrage de frais accessoires</h5>
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Montant</th>
                        <th>Catégorie</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {accessoires.map((acc) => (
                        <tr key={acc.parametreId}>
                          <td>
                            {new Intl.NumberFormat("en-DE").format(
                              acc.libelleParametre
                            )}
                          </td>
                          <td>{acc?.categorie?.nom}</td>
                          <td>
                            <Space size="middle">
                              <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={() => handleShow(true, acc)}
                              >
                                Modifier
                              </Button>
                              <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => handleDelete(acc.parametreId)}
                              >
                                Supprimer
                              </Button>
                            </Space>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <AddAccessoires
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedAccesoire={selectedAccessoire}
      />

      <div className="layout-overlay layout-menu-toggle"></div>
      <div className="drag-target"></div>
    </div>
  );
};

export default Accessoires;
