import {ExclamationCircleFilled} from "@ant-design/icons";
import {Modal, Select, Spin} from "antd";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import {API_URL, TOKEN} from "../../variables/constants";
import axiosInstance from "../../variables/api/axiosInstance";

const {confirm} = Modal;

const DetailsUtilisateur = () => {
    const [utilisateur, setUtilisateur] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showChangeProfilModal, setShowChangeProfilModal] = useState(false);
    const [modules, setModules] = useState([]);
    const [habilitations, setHabilitations] = useState([]);
    const [selectedHabilitations, setSelectedHabilitations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false)
    const [dateFinDerogation, setDateFinDerogation] = useState("");
    const [profils, setProfils] = useState([]);
    const [selectedProfil, setSelectedProfil] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [email, setEmail] = useState("");
    const [fonction, setFonction] = useState("");
    const [adresse, setAdresse] = useState("");
    const [genre, setGenre] = useState("");
    const [contact, setContact] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false)

    const handleShow = () => setShowModal(true);
    const handleChangeProfilShow = () => setShowChangeProfilModal(true);

    const handleClose = () => setShowModal(false);
    const handleChangeProfilClose = () => setShowChangeProfilModal(false);

    const param = useParams();
    const navigate = useNavigate();
    console.log(utilisateur)
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (param.utilisateurId) {
                    const {data} = await axiosInstance.get(`/api/utilisateur/codeId/${param.utilisateurId}`);
                    setUtilisateur(data);
                    setSelectedProfil(data.profil?.codeId || ""); // Evite une erreur si `profil` est undefined
                }

                const [modulesRes, habilitationsRes, profilsRes] = await Promise.all([
                    axiosInstance.get(`/api/module`),
                    axiosInstance.get(`/api/habilitation`),
                    axiosInstance.get(`/api/profil/all`),
                ]);

                setModules(modulesRes.data);
                setHabilitations(habilitationsRes.data);
                setProfils(profilsRes.data);
            } catch (err) {
                console.error("Erreur lors de la récupération des données :", err);
            }
        };

        fetchData();
    }, [TOKEN, param.utilisateurId]);
    console.log(genre)
    useEffect(() => {
        if (utilisateur) {
            setNom(utilisateur?.personne?.nom || "");
            setPrenom(utilisateur?.personne?.prenom || "");
            setEmail(utilisateur?.email || "");
            setGenre(utilisateur?.personne?.genre || "");
            setAdresse(utilisateur?.personne?.adresse || "");
            setFonction(utilisateur?.personne?.fonction || "");
        }
    }, [utilisateur]);

    const handleSelectChange = (value) => {
        let selectedHabs = [];
        value.forEach((el) => {
            let h = habilitations.find((e) => e.habilitationId === parseInt(el));
            selectedHabs.push(h);
        });
        setSelectedHabilitations(selectedHabs);
    };

    // Fonction qui filtre les habilitations afin de ressortir celles d'un module spécifique défini par son moduleId
    const getHabilitationsOfModule = (moduleId) => {
        let habs = habilitations.filter((e) => e.module.moduleId === moduleId);
        let opt = [];
        let habbs = habs.filter(
            (e) =>
                !utilisateur?.profil?.habilitations.some(
                    (f) => f.habilitationId === e.habilitationId
                )
        );
        habbs.forEach((hab, id) => {
            opt.push({
                key: id,
                label: hab.libelle,
                value: hab.habilitationId,
            });
        });
        return opt;
    };

    const getUpdateHabilitationsOfModule = (moduleId) => {
        let habs = selectedHabilitations.filter(
            (e) => e.module.moduleId === moduleId
        );
        // console.log("habits...: ", habs);
        let opt = [];
        habs.forEach((hab, id) => {
            opt.push({
                key: id,
                label: hab.libelle,
                value: hab.habilitationId,
            });
        });
        return opt;
    };

    const handleDateChange = (e) => {
        // console.log(e.target.value);
        setDateFinDerogation(e.target.value);
    };

    const onSubmit = () => {
        setLoading(true);
        utilisateur.derogation = selectedHabilitations;
        utilisateur.dateFinDerogation = `${dateFinDerogation}T23:59:59.0`;
        console.log("Utilisateur: ", utilisateur);
        axios
            .put(
                `${API_URL}/api/utilisateur/derogation`,
                JSON.stringify(utilisateur),
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                setLoading(false);
                handleClose();
                navigate(`/details-utilisateur/${res.data.codeId}`, {replace: true});
                toast("Dérogation octroyée avec succès");
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors de l'octroi de la dérogation");
                setLoading(false);
            });
    };

    const onSubmitChangeProfil = () => {
        setLoading(true);
        const profil = profils.find((p) => p.codeId === selectedProfil);
        utilisateur.profil = profil;
        axios
            .put(
                `${API_URL}/api/utilisateur/update-profil`,
                JSON.stringify(utilisateur),
                {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                setLoading(false);
                handleChangeProfilClose();
                navigate(`/details-utilisateur/${res.data.codeId}`, {replace: true});
                toast("Profil modifié avec succès");
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors de la modification du profil");
                setLoading(false);
            });
    };

    const showUserActivateConfirm = (utilisateurCodeId, status) => {
        confirm({
            title: status
                ? "Etes-vous sûr de vouloir désactiver cet utilisateur?"
                : "Etes-vous sûr de vouloir activer cet utilisateur?",
            icon: <ExclamationCircleFilled/>,
            content: "Cette action sera historisée et donc est irréversible",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                activateUtilisateur(utilisateurCodeId, status);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const activateUtilisateur = (utilisateurCodeId, status) => {
        axios
            .get(`${API_URL}/api/utilisateur/activate/${utilisateurCodeId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                navigate(`/details-utilisateur/${res.data.codeId}`);
                toast(
                    status
                        ? "Utilisateur désactivé avec succès"
                        : "Utilisateur activé avec succès"
                );
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors de l'activation");
            });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setEditLoading(true);

        const updatedData = {
            ...utilisateur,
            personne: {
                ...utilisateur.personne,
                nom,
                prenom,
                genre,
                adresse,
                fonction,
            },
            email,
        };
        console.log(updatedData)
        axiosInstance
            .put(`/api/utilisateur`, updatedData)
            .then((res) => {
                toast.success("Utilisateur modifié avec succès");
                setUtilisateur(res.data);
                handleClose();
                setEditLoading(false)
                setEditModalOpen(false)
                navigate(`/details-utilisateur/${res.data.codeId}`)
            })
            .catch((err) => {
                console.error(err);
                setEditLoading(false)

                toast.error("Erreur lors de la modification des informations");
            })
            .finally(() => setLoading(false));
    };

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"UTILISATEURS"}/>

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar/>

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row">
                                {/* <!-- User Sidebar --> */}
                                <div class="col-xl-4 col-lg-5 order-1 order-md-0">
                                    {/* <!-- User Card --> */}
                                    <div class="card mb-6">
                                        <div class="card-body pt-12">
                                            <div class="user-avatar-section">
                                                <div class=" d-flex align-items-center flex-column">
                                                    {utilisateur?.personne?.photo_url ? (
                                                        <img
                                                            class="img-fluid rounded mb-4"
                                                            src="../../assets/img/avatars/1.png"
                                                            height="120"
                                                            width="120"
                                                            alt="User avatar"
                                                        />
                                                    ) : (
                                                        <div class="avatar avatar-lg me-2">
                              <span class="avatar-initial rounded-circle bg-label-secondary">
                                {utilisateur?.personne?.prenom?.charAt(0)}
                                  {utilisateur?.personne?.nom?.charAt(0)}
                              </span>
                                                        </div>
                                                    )}
                                                    <div class="user-info text-center">
                                                        <h5>
                                                            {utilisateur?.personne?.prenom}{" "}
                                                            {utilisateur?.personne?.nom}
                                                        </h5>
                                                        <span class="badge bg-label-secondary">
                              {utilisateur?.profil?.nom}
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 class="pb-4 border-bottom mb-4">Détails</h5>
                                            <div class="info-container">
                                                <ul class="list-unstyled mb-6">
                                                    <li class="mb-2">
                                                        <span class="h6">Username: </span>
                                                        <span>@{utilisateur?.username}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Email: </span>
                                                        <span>{utilisateur?.email}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Statut: </span>
                                                        <span>
                              {utilisateur?.active ? "Actif" : "Inactif"}
                            </span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Profil: </span>
                                                        <span>{utilisateur?.profil?.nom}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Contact: </span>
                                                        <span>
                              (+228){" "}
                                                            {utilisateur?.personne?.contact
                                                                ? utilisateur?.personne?.contact[0]
                                                                : "-"}
                            </span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Langages: </span>
                                                        <span>Français</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6">Pays: </span>
                                                        <span>Togo</span>
                                                    </li>
                                                </ul>
                                                <div class="d-flex justify-content-center">
                                                    <a
                                                        href="#"
                                                        onClick={(e) => setEditModalOpen(true)}
                                                        class="btn btn-label-primary me-4"

                                                    >
                                                        Editer
                                                    </a>
                                                    {utilisateur.active ? (
                                                        <a
                                                            href="#"
                                                            class="btn btn-label-danger me-4"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                showUserActivateConfirm(
                                                                    utilisateur.codeId,
                                                                    utilisateur.active
                                                                );
                                                            }
                                                            }
                                                        >
                                                            Suspendre
                                                        </a>
                                                    ) : (
                                                        <a
                                                            href="#"
                                                            class="btn btn-label-warning me-4"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                showUserActivateConfirm(
                                                                    utilisateur.codeId,
                                                                    utilisateur.active
                                                                )
                                                            }
                                                            }
                                                        >
                                                            Activer
                                                        </a>
                                                    )}
                                                    <a
                                                        href="#"
                                                        class="btn btn-label-info me-4"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleShow();
                                                        }}
                                                    >
                                                        Dérogation
                                                    </a>
                                                </div>
                                                <br/>
                                                <div class="d-flex justify-content-center">
                                                    <a
                                                        href="#"
                                                        class="btn btn-lg btn-label-success me-4"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleChangeProfilShow();
                                                        }}
                                                    >
                                                        Changer le profil
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--/ User Sidebar --> */}

                                {/* <!-- User Content --> */}
                                <div class="col-xl-8 col-lg-7 order-0 order-md-1">
                                    {/* <!-- User Pills --> */}
                                    <div class="nav-align-top">
                                        <ul class="nav nav-pills flex-column flex-md-row mb-6">
                                            <li class="nav-item">
                                                <button onClick={(e) => e.preventDefault()} class="nav-link active"
                                                        href="#">
                                                    <i class="bx bx-user bx-sm me-1_5"></i>Compte
                                                </button>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#">
                                                    <i class="bx bx-lock-alt bx-sm me-1_5"></i>Sécurité
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#"
                                                >
                                                    <i class="bx bx-bell bx-sm me-1_5"></i>Notifications
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    {/* <!-- Invoice table --> */}
                                    <div class="card mb-4">
                                        <div class="card-datatable table-responsive">
                                            <table class="table datatable-invoice">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>#</th>
                                                    <th>Status</th>
                                                    <th>Total</th>
                                                    <th>Issued Date</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <!-- /Invoice table --> */}
                                </div>
                                {/* <!--/ User Content --> */}
                            </div>

                            {/* <!-- Modal --> */}
                            {/* <!-- Edit User Modal --> */}
                            <Modal open={editModalOpen} onCancel={() => setEditModalOpen(false)} footer={null}>
                                <div>
                                    <div>
                                        <div>
                                            <div class="text-center mb-6">
                                                <h4 class="mb-2">
                                                    Editer les informations de l'utilisateur
                                                </h4>
                                                <p>Mise à jour des informations de l'utilisateur.</p>
                                            </div>
                                            <form class="row g-6" onsubmit="false">
                                                <div class="col-12 col-md-6">
                                                    <label
                                                        class="form-label"
                                                        for="modalEditUserFirstName"
                                                    >
                                                        Nom
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditUserFirstName"
                                                        name="modalEditUserFirstName"
                                                        class="form-control"
                                                        value={nom}
                                                        onChange={(e) => setNom(e.target.value)}
                                                    />
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="modalEditUserLastName">
                                                        Prénom
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditUserLastName"
                                                        name="modalEditUserLastName"
                                                        class="form-control"
                                                        value={prenom}
                                                        onChange={(e) => setPrenom(e.target.value)}
                                                    />
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="modalEditUserStatus">
                                                        Genre
                                                    </label>
                                                    <br/>
                                                    <Select
                                                        size="large"
                                                        value={genre}
                                                        onChange={(value) => setGenre(value)}
                                                        options={[
                                                            {value: "FEMININ", label: "Féminin"},
                                                            {value: "MASCULIN", label: "Masculin"},
                                                        ]}
                                                        placeholder="Sélectionner un genre"
                                                        style={{width: '100%'}}
                                                        getPopupContainer={(triggerNode) => triggerNode.parentNode} // Ensures dropdown renders inside the parent
                                                    />

                                                </div>

                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="modalEditUserEmail">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditUserEmail"
                                                        name="modalEditUserEmail"
                                                        class="form-control"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="modalEditUserStatus">
                                                        Fonction
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditUserEmail"
                                                        name="modalEditUserEmail"
                                                        class="form-control"
                                                        value={fonction}
                                                        onChange={(e) => setFonction(e.target.value)}
                                                    />
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <label class="form-label" for="modalEditTaxID">
                                                        Adresse de residence
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="modalEditTaxID"
                                                        name="modalEditTaxID"
                                                        class="form-control modal-edit-tax-id"
                                                        value={adresse}
                                                        onChange={(e) => setAdresse(e.target.value)}
                                                    />
                                                </div>
                                                <Spin spinning={editLoading}>
                                                    <div className="col-12 text-center">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary me-3"
                                                            onClick={(e) => handleSubmit(e)}
                                                            disabled={editLoading}
                                                        >
                                                            Enregistrer
                                                        </button>
                                                        <button
                                                            type="reset"
                                                            className="btn btn-label-secondary"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            disabled={editLoading}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </Spin>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            {/* <!--/ Edit User Modal --> */}

                            {/* <!-- Add New Credit Card Modal --> */}
                            {/* <div
                class="modal fade"
                id="upgradePlanModal"
                tabindex="-1"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered modal-simple modal-upgrade-plan">
                  <div class="modal-content">
                    <div class="modal-body">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div class="text-center mb-6">
                        <h4 class="mb-2">Upgrade Plan</h4>
                        <p>Choose the best plan for user.</p>
                      </div>
                      <form
                        id="upgradePlanForm"
                        class="row g-4"
                        onsubmit="return false"
                      >
                        <div class="col-sm-9">
                          <label class="form-label" for="choosePlan">
                            Choose Plan
                          </label>
                          <select
                            id="choosePlan"
                            name="choosePlan"
                            class="form-select"
                            aria-label="Choose Plan"
                          >
                            <option selected>Choose Plan</option>
                            <option value="standard">
                              Standard - $99/month
                            </option>
                            <option value="exclusive">
                              Exclusive - $249/month
                            </option>
                            <option value="Enterprise">
                              Enterprise - $499/month
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-3 d-flex align-items-end">
                          <button type="submit" class="btn btn-primary">
                            Upgrade
                          </button>
                        </div>
                      </form>
                    </div>
                    <hr class="mx-5 my-2" />
                    <div class="modal-body">
                      <p class="mb-0">User current plan is standard plan</p>
                      <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div class="d-flex justify-content-center me-2 mt-1">
                          <sup class="h6 pricing-currency pt-1 mt-2 mb-0 me-1 text-primary fw-normal">
                            $
                          </sup>
                          <h1 class="mb-0 text-primary">99</h1>
                          <sub class="pricing-duration mt-auto mb-5 pb-1 small text-body">
                            /month
                          </sub>
                        </div>
                        <button class="btn btn-label-danger cancel-subscription">
                          Cancel Subscription
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                            {/* <!--/ Add New Credit Card Modal --> */}

                            {/* <!-- /Modal --> */}
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <Modal
                open={showModal}
                onCancel={handleClose}
                zIndex={10000}
                centered
                onOk={onSubmit}
                loading={loading}
            >
                {/* <Modal.Body> */}
                {/* <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button> */}
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <div className="text-center">
                            {/* <h4 className="address-title mb-2">Octroyer une dérogation</h4> */}
                            <p className="address-subtitle">
                                Octroyer une dérogation à{" "}
                                <b>
                                    {utilisateur?.personne?.nom} {utilisateur?.personne?.prenom}
                                </b>
                            </p>
                        </div>
                        {/* <br /> */}
                        <div class="row">
                            {modules?.map((mod, id) => (
                                <div class="col-md-12 select2-primary" key={id}>
                                    <label class="form-label" for="formtabs-language">
                                        {mod?.nom}
                                    </label>
                                    <Select
                                        mode="multiple"
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Veuillez sélectionner les habilitations spéciales sur le module"
                                        onChange={handleSelectChange}
                                        options={getHabilitationsOfModule(mod.moduleId)}
                                        defaultValue={
                                            param.profilId
                                                ? getUpdateHabilitationsOfModule(mod.moduleId)
                                                : []
                                        }
                                        value={getUpdateHabilitationsOfModule(mod.moduleId)}
                                    />
                                </div>
                            ))}
                        </div>
                        <hr/>
                        <div class="col-md-12 col-12">
                            <label for="flatpickr-datetime" class="form-label">
                                Date de fin de dérogation
                            </label>
                            <input
                                type="date"
                                class="form-control"
                                placeholder="YYYY-MM-DD HH:MM"
                                id="flatpickr-datetime"
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                    {/* <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  class="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? `Enregistrement...` : `Enregistrer`}
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div> */}
                </form>
                {/* </Modal.Body> */}
            </Modal>

            <Modal
                open={showChangeProfilModal}
                onCancel={handleChangeProfilClose}
                zIndex={10000}
                centered
                onOk={onSubmitChangeProfil}
                loading={loading}
            >
                {/* <Modal.Body> */}
                {/* <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button> */}
                <form
                    id="addNewAddressForm"
                    className="row g-12"
                    onSubmit={onSubmitChangeProfil}
                >
                    <div className="col-12 col-md-12">
                        <div className="text-center">
                            {/* <h4 className="address-title mb-2">Octroyer une dérogation</h4> */}
                            <p className="address-subtitle">
                                Modifier le profil de votre utilisateur{" "}
                                <b>
                                    {utilisateur?.personne?.nom} {utilisateur?.personne?.prenom}
                                </b>
                            </p>
                        </div>
                        {/* <br /> */}
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="formtabs-language">
                                    Veuillez sélectionner le nouveau profil
                                </label>
                                <Select
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Veuillez sélectionner les habilitations spéciales sur le module"
                                    onChange={(value) => setSelectedProfil(value)}
                                    options={profils.map((prof, id) => ({
                                        label: prof.nom,
                                        value: prof.codeId,
                                        key: id,
                                    }))}
                                    defaultValue={param.profilId}
                                    value={selectedProfil}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                {/* </Modal.Body> */}
            </Modal>

            {/* <!-- Overlay --> */
            }
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */
            }
            <div class="drag-target"></div>
        </div>
    )
        ;
};

export default DetailsUtilisateur;
